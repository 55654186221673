import * as actionTypes from '../actions/autocompletesActions.js';

const initialState = {
    carStatuses: [],
    usersAutocomplete: [],
    companiesAutocomplete: [],
    locations: [],
    simpleLocations: [],
    families: [],
    structures: [],
    fuelMethods: [],
};

const autocompletesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CAR_STATUSES_LIST:
            return {
                ...state,
                carStatuses: action.carStatuses,
            };
        case actionTypes.SET_USERS_AUTOCOMPLETE:
            return {
                ...state,
                usersAutocomplete: action.users,
            };
        case actionTypes.SET_COMPANIES_AUTOCOMPLETE:
            return {
                ...state,
                companiesAutocomplete: action.businesses,
            };
        case actionTypes.SET_LOCATIONS_AUTOCOMPLETE:
            return {
                ...state,
                locations: action.locations,
            };
        case actionTypes.SET_LOCATIONS_SIMPLE_AUTOCOMPLETE:
            return {
                ...state,
                simpleLocations: action.locations,
            };
        case actionTypes.SET_FAMILIES_AUTOCOMPLETE:
            return {
                ...state,
                families: action.families,
            };
        case actionTypes.SET_STRUCTURES_AUTOCOMPLETE:
            return {
                ...state,
                structures: action.structures,
            };
        case actionTypes.SET_FUEL_METHODS_AUTOCOMPLETE:
            return {
                ...state,
                fuelMethods: action.fuelMethods,
            };
        default:
            return state;
    }
};

export default autocompletesReducer;
