import axios from 'axios';

/**
 * get campaigns
 * @param {Object} filters
 */
export const httpGetCampaigns = (filters = null) => {
    return axios.get('/campaigns', { params: { ...filters } })
}

/**
 * create campaign
 * @param data
 */
export const httpAddCampaign = (data) => {
    return axios.post('/campaigns', data)
}

/**
 * suspend campaign
 * @param status
 * @param id
 */
export const httpSuspendCampaign = (id,status) => {
    return axios.put(`/campaigns/${id}`, {
        active : status
    })
}

/**
 * edit campaign details
 * @param id
 * @param campaignDetails
 */
export const httpEditCampaign = (id,campaignDetails) => {
    return axios.put(`/campaigns/${id}`,campaignDetails)
}

/**
 * get campaign details
 * @param id
 */
export const httpGetCampaign = (id) => {
    return axios.put(`/campaigns/${id}`)
}

/**
 * delete campaign
 * @param status
 * @param id
 */
export const httpDeleteCampaign = (id) => {
    return axios.delete(`campaigns/${id}`)
}

/**
 * get cars from plates
 * @param plates
 * @param id
 */
export const httpGetCarsFromPlates = (id,plates)=>{
    return axios.put(`/campaigns/${id}`, {plates:plates})
}

/**
 * get cars from plates
 * @param file
 * @param id
 */
export const httpGetCarsFromPlatesCsv = (id,file)=>{
    let formData = new FormData();
    formData.append("plates_file", file);
    return axios.post(`/campaigns/${id}?_method=PUT`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

/**
 * reprice campaign
 * @param id
 * @param repriceInfos
 * 
 */
export const httpModifyAmount = (id,repriceInfos) => { 
    return axios.put(`campaigns/${id}`,repriceInfos)
}

/**
 * save campaign selected cars
 * @param id
 * @param cars
 * 
 */
export const httpSaveCampaignCars = (id,cars) => { 
    return axios.put(`campaigns/${id}`,{cars:cars})
}
