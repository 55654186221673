const SET_MESSAGE_ACTION = 'SET_MESSAGE_ACTION';


export const setMessageAction = (channel, messageTimetoken) => {
    return {
        type: SET_MESSAGE_ACTION,
        payload: {
            channel,
            messageTimetoken
        }
    };
};