import React from "react";
import {createUseStyles} from "react-jss";
import {useDispatch, useSelector} from "react-redux";
import {MembersIcon, OchIcon} from "../../../../assets/icons";
import {
    getCurrentConversationDescription,
    getCurrentConversationMembers,
    getCurrentConversationType
} from "../../../../store/reducers/chat/chats";
import UserInitialsAvatar from "../UserInitialsAvatar/UserInitialsAvatar";
import {getLoggedInUserUuid} from "../../../../store/reducers/user";
import {showMembers} from "../../../../store/actions/chat/currentConversationActions";
import {DIRECT_CHANNEL_TYPE} from "../../../../helpers/constants/chat";
import Spinner from "../../../../Commons/Spinner";
import PresenceIndicator from "../../Menu/MyUserDetails/PresenceIndicator/PresenceIndicator";
import {getCurrentConversationId} from "../../../../store/reducers/chat/currentConversation";

const useStyles = createUseStyles(theme => ({

    wrapper: {
        width: '100%',
        height: '90px',
        display: 'flex',
        backgroundColor: '#fff'
    },
    body: {
        position: "relative",
        width: '100%',
        padding: [16, 32]
    },
    header: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr auto',
        gridColumnGap: 8,

    },
    headerWithPresence: {
        extend: 'header',
        alignItems: 'center'
    },
    userInfo: {
        display: 'flex',
        flexDirection: 'column'
    },
    ochIcon: {
        width: 40,
        height: 40,
        borderRadius: '12px',
        padding: 6,
        fontSize: '12px',
        backgroundColor: '#3A3A3A',

    },
    userName: {
        display: "grid",
        gridColumnGap: 8,
        gridTemplateColumns: "auto 1fr",
        alignItems: 'center',
    },
    name: {
        textTransform: 'capitalize',
        fontSize: 14,
        fontWeight: 700,
        color: '#000'
    },
    status: {
        fontSize: 12,
        color: '#4E4E4E'
    },
    description: {
        marginTop: 8,
        textTransform: 'capitalize',
        fontSize: 12,
        color: '#4E4E4E'
    },
    backIcon: {
        cursor: 'pointer',
        display: 'flex',
        marginRight: '25px',
        [theme.mUp]: {
            display: 'none'
        }
    },
    occupancy: {
        cursor: 'pointer'
    },

}))

const ConversationHeader = () => {

    const classes = useStyles();
    const members =  useSelector(getCurrentConversationMembers);
    const userUuid = useSelector(getLoggedInUserUuid);
    const currentConversationDesc = useSelector(getCurrentConversationDescription);
    const currentConversationType = useSelector(getCurrentConversationType);
    const isDirect = currentConversationType === DIRECT_CHANNEL_TYPE;
    const dispatch = useDispatch();


    const getConversationInfo = () => {
        if(isDirect && (members && members.length > 1)){
            const targetMember = members.filter(u => u.id !== userUuid);
            const {name, email, presence, id} = targetMember.length > 0 && targetMember[0];
            return (
                <div className={classes.header}>
                    <div>
                        <UserInitialsAvatar size={40} name={name} userId={id}/>
                    </div>
                    <div>
                        <div className={classes.userInfo}>
                            <span className={classes.userName}>
                                <span className={classes.name}>{name}</span>
                                <PresenceIndicator title={presence ? "connesso" : "inattivo"}
                                                   active={presence}/>
                            </span>
                            <span className={classes.status}>
                                    {presence ? 'Online' : 'Inattivo'}
                            </span>
                        </div>
                        <span className={classes.description}>
                            {email}
                        </span>
                    </div>
                </div>)
        }else if(!isDirect){
            return (
                <div className={classes.headerWithPresence}>
                    <span className={classes.ochIcon}>
                        <OchIcon width={28} height={28}/>
                    </span>
                    <div>
                        <div className={classes.name}># {currentConversationDesc || 'Canale di gruppo'}</div>
                        <div className={classes.description}>Canale in sola lettura</div>
                    </div>
                    {/*<span className={classes.occupancy} onClick={() => dispatch(showMembers(true))}>
                        <MembersIcon width={16} height={16}/>
                    </span>*/}
                </div>
            )
        }else{
            return <Spinner overlayFullscreen={false} overlay={false}/>
        }
    }

    return <section className={classes.wrapper}>
        <div className={classes.body}>
            {getConversationInfo()}
        </div>
    </section>
}

export default ConversationHeader;