import React, {useState} from 'react';
import cx from 'classnames';
import {createUseStyles} from 'react-jss';
import {Link, NavLink} from "react-router-dom";
import logoDark from '../assets/images/black.svg'
import Avatar from "./Avatar";
import UserActionPopover from "./UserActionPopover.js";
import {useSelector} from "react-redux";

const useStyles = createUseStyles(theme => ({
	root: {
		gridArea: "header",
		backgroundColor: theme.colors.white,
		...theme.paper,
		...theme.border,
		height: theme.headerHeight,
		padding: [12, theme.spacing * 4],
		display: 'grid',
		gridAutoFlow: 'column',
		gridTemplateColumns: 'max-content',
		gridRowGap: '15px',
		alignItems: 'center',
		position: 'relative',
		'grid-template-columns': '1fr',
	},
	links: {
		display: "grid",
		alignItems: "center",
		justifyItems: "center",
		gridTemplateColumns: "repeat(3, auto)",
		gridColumnGap: "32px",
	},
	link: {
		textDecoration: "none",
		fontWeight: 'bold',
		color: theme.colors.black,
		[theme.s]: {
			display: 'none',
		},
		[theme.mUp]: {
			'justify-self': 'right',
		},
	},
	active: {
		color: 'red!important',
		textDecoration: "none",
	},
	vDivisor: {
		height: '100%',
		width: 1,
		backgroundColor: theme.colors.black,
		[theme.s]: {
			display: 'none',
		},
	},
}));

const HeaderForExternals = ({className}) => {
	const classes = useStyles();
	const user = useSelector(state => state.users.user);
	const [showPopover, setShowPopover] = useState(false);

	return <>
		{showPopover && <UserActionPopover popoverToggle={() => setShowPopover(!showPopover)}/>}
		<header className={cx(classes.root, className)}>
			<Link to={"/external-workers"}><img src={logoDark} alt='logo'/></Link>
			<div className={classes.links}>
				<NavLink to={'/external-workers'} exact={true} activeClassName={classes.active} className={classes.link}>
					Home
				</NavLink>
				<span className={classes.vDivisor}/>
				<Avatar img={user?.photo_link} size={'small'} onClickEvent={() => setShowPopover(!showPopover)}/>
			</div>
		</header>
	</>
};

export default HeaderForExternals;
