import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useDispatch, useSelector} from "react-redux";
import {Pointer} from "../../assets/icons.js";
import {httpGetAllCarLocations, httpSetQuickShifts} from "../../HttpRequests/shifts.http.js";
import {errorHandler} from "../../helpers/utils.js";
import Select from "react-select";
import theme from '../../theme';
import {useParams} from "react-router-dom";
import {alertToggle} from "../../store/actions/alertActions.js";
import {setCurrentCar} from "../../store/actions/carsActions.js";
import Spinner from "../../Commons/Spinner.js";
import Button from "../../Commons/Button.js";

const useStyles = createUseStyles((theme) => ({
    root: {
        maxHeight: 130,
        display: "grid",
        alignItems: "start",
        gridTemplateRows: '1fr auto',
        borderRadius: 20,
        padding: [theme.spacing * 2, theme.spacing * 2, theme.spacing],
        background: theme.colors.white,
        position: 'relative',
        ...theme.shadow,
    },
    positionAction: {
        display: 'grid',
        justifySelf: 'start',
        marginLeft: 28
    },
    mainInfos: {
        display: 'grid',
        alignItems: "start",
        '& b': {
            lineHeight: `14px`
        },
        '& label': theme.control.label
    },
    positionStyles: {
        display: 'grid',
        gridTemplateColumns: '16px 1fr',
        gridColumnGap: theme.spacing + 4,
    },
    icon: {
        width: `16px!important`,
        fill: theme.colors.black,
    },
    unsetPosition: {
        display: 'grid',
        justifyItems: "center",
        alignItems: 'center',
        gridGap: theme.spacing,
        background: theme.colors.violet,
        border: '2px solid rgba(95, 139, 255, 1)',
        boxShadow: '0px 2px 17px 0px rgba(198, 217, 255, 1)',
        boxSizing: 'border-box',
        maxHeight: 130,
        gridTemplaterows: 'max-content',
        borderRadius: 20,
        padding: [theme.spacing * 2, theme.spacing * 2, theme.spacing],
        position: 'relative',
        '& p':{
            fontWeight: 600,
            fontsize: 14
        },
        '& button':{
            color: `${theme.colors.black}!important`
        }
    }
}));

const CarToRetrievePositionInfo = ({canGoToStockCb,locations}) => {
    const {id} = useParams(); //get id of car from url params react-router-dom
    const [positionStatus, setPositionStatus] = useState('pending'); //unset - pending - confirmed
    const currentCar = useSelector(state => state.cars.currentCar);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [loading, setLoading] = useState(false);
    const classes = useStyles({positionStatus});
    const dispatch = useDispatch();

    useEffect(() => {
        if (currentCar?.id) {
            setCurrentLocation(currentCar?.location_id);
            if(positionStatus !== 'confirmed'){
                setPositionStatus(currentCar?.location_id ? 'pending' : 'unset');
            }
        }
    }, [currentCar]);

    useEffect(() => {
        if (positionStatus !== 'confirmed') {
            canGoToStockCb(false)
        } else {
            canGoToStockCb(true)
        }
    }, [positionStatus])

    const onChangePosition = async (location) => {
        setLoading(true)
        try {
            await httpSetQuickShifts(id, location);
            setCurrentLocation(location.value)
            dispatch(setCurrentCar({
                ...currentCar,
                location_id: location?.value,
                location: {...currentCar?.location, name: location?.location_name.split('-')[0], ubication_name: location.label, id: location?.value}
            }))
            dispatch(alertToggle('Posizione modificata con successo.', 'success'));
        } catch (e) {
            errorHandler(e, dispatch);
        } finally {
            setLoading(false)
        }
    }

    return <>
        {loading && <Spinner size={24}/>}

        {positionStatus === 'unset' && <div className={classes.unsetPosition}>
            <Pointer className={classes.icon}/>
            <p>Dove si trova questo veicolo?</p>
            <Button data-secondary data-xs-small width={160} style={{background: 'transparent'}} onClick={() => setPositionStatus('pending')}>
                Inserisci ubicazione
            </Button>
        </div>}

        {positionStatus !== 'unset' && <div className={classes.root}>
            <div className={classes.positionStyles}>
                <Pointer className={classes.icon}/>

                {positionStatus === 'confirmed' && <div className={classes.mainInfos}>
                    <small style={{marginBottom: 8}}>Posizione attuale vettura:</small>
                    <label>{currentCar?.location?.name}</label>
                    <small>{currentCar?.location?.ubication_name}</small>
                </div>}

                {positionStatus === 'pending' && <div className={classes.mainInfos}>
                    <small style={{marginBottom: 8}}>Posizione attuale vettura:</small>
                    <Select options={locations} styles={theme.reactSelectsStyleXs} isSearchable={true}
                            onChange={(item) => {
                                onChangePosition(item)
                            }}
                            value={locations.filter(obj => {
                                return obj.value === currentLocation
                            })}
                    />
                </div>}
            </div>

            <div className={classes.positionAction}>
                {positionStatus === 'pending' &&
                <Button data-primary data-xs-small width={160} onClick={() => setPositionStatus('confirmed')}> Conferma
                    posizione </Button>}
                {positionStatus === 'confirmed' && <Button data-secondary data-xs-small width={160}
                                                           onClick={() => setPositionStatus('pending')}> Modifica </Button>}
            </div>
        </div>}
    </>
};

export default CarToRetrievePositionInfo;