import React, {useEffect, useState} from 'react';
import {createUseStyles} from "react-jss";
import {formattedDate} from "../../helpers/utils.js";
import {StarIcon} from '../../assets/icons'
import {useHistory} from "react-router-dom";
import ContextMenu from "../../Commons/ContextMenu.js";

const useStyles = createUseStyles(theme => ({
    overlay: {
        'z-index': 9,
        'position': 'fixed',
        'width': '100%',
        'height': '100%',
        'top': 0,
        'left': 0,
        'bottom': 0,
        'right': 0,
        cursor: "pointer"
    },
    root: {
        width: "100%",
        display: "grid",
        padding: "16px",
        position: "relative",
        background: "rgb(255, 255, 255)",
        rowGap: "24px",
        gridTemplateColumns: "1fr auto",
        borderRadius: "8px",
        marginBottom: "8px",
        opacity: ({standby}) => standby ? '0.6' : 1,
        '& button': {
            marginTop: theme.spacing * 3
        },
    },
    name: {
        gridColumnGap: theme.spacing,
        alignItems: 'center',
        gridTemplateColumns: "auto auto auto",
        display: "inline-grid"
    },
    edit:{
        width: '16px!important',
        fill: theme.colors.darkGrey
    },
    popup: {
        ...theme.shadow,
        position: 'absolute',
        'z-index': 11,
        right: 0,
        top: 30,
        cursor: 'pointer',
        width: "20%",
        display: 'grid',
        gridRowGap: theme.spacing,
        textAlign: "left",
        backgroundColor: theme.colors.white,
        ...theme.shadow,
        overflow: 'hidden',
        borderRadius: 8,
        '& span': {
            padding: theme.spacing,
            '&:hover': {
                backgroundColor: theme.colors.smokeWhite
            },
            '&:last-child': {
                color: theme.colors.red,
            }
        },
        '& a': {
            textDecoration: 'none',
            padding: theme.spacing,
            '&:hover': {
                backgroundColor: theme.colors.smokeWhite
            },
        }
    },
    date: {
        display: "grid",
        alignItems: "start",
        gridAutoFlow: "column",
        gridColumnGap: "4px",
    },
    details: {
        color: ({noAmount}) => noAmount ? theme.colors.green : theme.colors.grey,
        fontWeight: 'bold',
        marginTop: theme.spacing * 2,
        fontSize: 20,
    },
    status: {
        display: "inline-block",
        width: "90%",
        textAlign: "right",
        color: theme.colors.yellow,
        position: "absolute", top: theme.spacing * 6, right: theme.spacing * 3
    }
}));


const CampaignBody = ({campaign, deletePopoverCb, suspendCampaignCb}) => {
    const [standby, setStandby] = useState(false);
    const history = useHistory()
    const classes = useStyles({noAmount: campaign?.amount, standby});

    useEffect(() => {
        if (!campaign.active) {
            setStandby(!standby)
        }
    }, []);

    const campaignDiscount = () => {
        if (campaign?.type === 'fixed') {
            return `${campaign?.amount}€ sul prezzo base`
        } else if (campaign?.type === 'percentage') {
            return `${campaign?.amount}% sul prezzo base`
        } else {
            return `Ammontare dello sconto non ancora settato`
        }
    }

    const actions = [
        {to: null, text: 'Modifica', callback: () => history.push(`/stock/trade-policies/${campaign.id}`), type: 'normal'},
        {to: null, text: standby ? 'Riattiva' : 'Sospendi', callback: () => suspendCampaignCb(campaign.id, campaign.active), type: 'normal'},
        {to: null, text: 'Elimina', callback: () => deletePopoverCb(campaign.id), type: 'danger'}
    ];

    return <>
        <div className={classes.root}>
            <div>
                <p className={classes.name}><StarIcon data-color/> <strong>{campaign.name}</strong> </p>
                <h4 className={classes.details}>
                    {campaignDiscount()}
                </h4>
            </div>
            <p className={classes.date}>
                Dal <strong>{formattedDate(campaign.start_campaign)} </strong> al <strong> {formattedDate(campaign.end_campaign)}</strong>
                <ContextMenu actions={actions} id={`${campaign?.id}-task`}/>
            </p>
            {standby && <strong className={classes.status}>Sospesa</strong>}
        </div>
    </>;
};

export default CampaignBody