import {createSelector} from "reselect";
import {combineReducers} from "redux";
import {
    createChannelMembersReducer,
    createNetworkStatusReducer,
    createPresenceReducer,
    createUserDataReducer,
    createSignalReducer,
    createUsersListReducer,
    createChannelDataReducer,
    createChannelsListReducer,
    createChannelMembersCountReducer,
    sendMessage as sendPubnubMessage,
    MessageActionType,
    errorFetchingMessageHistory
} from "pubnub-redux";
import {currentConversationStateReducer, getCurrentConversationId} from "./currentConversation";
import {getLoggedInUserUuid} from "../user";
import {conversationDraftStateReducer, getMessageDrafts} from "./draft";
import {PaginationStateReducer} from "./pagination";
import {createMembershipReducer, getJoinedConversationsByUserId} from "./joinedConversations";
import {getTypingIndicatorsById, TypingIndicatorStateReducer} from "./typingIndicator";
import {TYPING_INDICATOR_DURATION_SECONDS} from "../../../helpers/constants/chat";
import {getTargetUserUuid} from "../../../Pages/messageCenter/chatUtils";
import extendedMessageReducer from "./message";
import {chatStatusReducer} from "./status";
import {unreadMessageStateReducer} from "./unreadMessage";
import excludedChatUserReducer from "./excludedUsers";

// Reducers from pubnub-redux
const SignalReducer =  createSignalReducer()
const UsersListReducer =  createUsersListReducer()
const ChannelDataReducer =  createChannelDataReducer()
const ChannelsListReducer =  createChannelsListReducer()
const ChannelMembersCountReducer =  createChannelMembersCountReducer()
const UserDataReducer = createUserDataReducer();
const MembershipReducer = createMembershipReducer(); // customized
const ConversationMembersStateReducer = createChannelMembersReducer();
const NetworkStatusReducer = createNetworkStatusReducer(false);
const MemberPresenceReducer = createPresenceReducer();
const conversationStateReducer = combineReducers({
    conversations: createChannelDataReducer,
    allConversations: createChannelsListReducer
});


export const chatsReducer = combineReducers({
    signal: SignalReducer,
    userList: UsersListReducer,
    channelMembersCount: ChannelMembersCountReducer,
    memberPresence: MemberPresenceReducer,
    conversationMembers: ConversationMembersStateReducer,
    messages: extendedMessageReducer,
    networkStatus: NetworkStatusReducer,
    userData: UserDataReducer,
    conversations: conversationStateReducer,
    joinedConversations: MembershipReducer,
    currentConversation: currentConversationStateReducer,
    drafts: conversationDraftStateReducer,
    pagination: PaginationStateReducer,
    typingIndicators: TypingIndicatorStateReducer,
    chatStatus: chatStatusReducer,
    unreadMessages: unreadMessageStateReducer,
    excludedUsers: excludedChatUserReducer
});



/**
 * Selectors
 */

const getChatsSlice = state => state.chats;

const getConversationsSlice = state => state.chats.conversations;




/**
 * Users
 */

const getUsersSlice = state => state.chats.userData;

export const getUsersById = createSelector(
    [getUsersSlice],
    users => {
        return users.byId;
    }
);



/**
 * Current Conversation
 */

export const getCurrentConversationDescription = createSelector(
    [
        getJoinedConversationsByUserId,
        getCurrentConversationId,
        getLoggedInUserUuid
    ],
    (
        conversations,
        currentConversationId,
        userUuid
    ) => conversations[userUuid] ?
        conversations[userUuid].find( conv => conv.id === currentConversationId)?.channelMeta?.name
        : ''
);

export const getCurrentConversationType = createSelector(
    [
        getJoinedConversationsByUserId,
        getCurrentConversationId,
        getLoggedInUserUuid
    ],
    (
        conversations,
        currentConversationId,
        userUuid
    ) => conversations[userUuid] ?
        conversations[userUuid].find( conv => conv.id === currentConversationId)?.channelMeta.custom?.type
        : ''
);


export const getConversationMessageDraft = createSelector(
    [getMessageDrafts, getCurrentConversationId],
    (drafts, conversationId) => {
        return drafts[conversationId];
    }
);

export const getCurrentConversationTypingIndicators = createSelector(
    [getTypingIndicatorsById, getCurrentConversationId, getUsersById],
    (typingIndicators, conversationId, users) => {
        return typingIndicators[conversationId]
            ? Object.values(
                Object.values(typingIndicators[conversationId] || [])
                    .filter(
                        typingIndicator => typingIndicator.channel === conversationId
                    )
                    .reduce(
                        (
                            grouped,
                            typingIndicator
                        ) => {
                            grouped[typingIndicator.publisher] = typingIndicator;
                            return grouped;
                        },
                        {}
                    )
            )
                .filter(
                    typingIndicator =>
                        Date.now() - typingIndicator.timetoken / 10000 <
                        TYPING_INDICATOR_DURATION_SECONDS * 1000
                )
                .map(typingIndicator => {
                    return {
                        ...typingIndicator,
                        timetoken: String(typingIndicator.timetoken),
                        sender:
                            users[typingIndicator.publisher || ""] ||
                            (typingIndicator.publisher
                                ? {
                                    id: typingIndicator.publisher,
                                    name: typingIndicator.publisher
                                }
                                : {
                                    id: "unknown",
                                    name: "unknown"
                                })
                    };
                })
            : [];
    }
);


/**
 * Messages
 */

const getMessagesSlice = state => state.chats.messages;

export const getMessagesById = createSelector(
    [getMessagesSlice],
    (messages) => {
        return messages.byId;
    }
);

export const getCurrentConversationMessages = createSelector(
    [getMessagesById, getCurrentConversationId, getUsersById],
    (messages, conversationId, users) => {
        return messages[conversationId]
            ? Object.values(messages[conversationId])
                .filter(message => message.channel === conversationId)
                .map(
                    message => {
                        // if the user is unknown queue up a request for the missing data
                        return {
                            ...message,
                            timetoken: String(message.timetoken),
                            sender: users[message.message.senderId] ||
                                (users[message.message.senderId]
                                    ? {
                                        id: message.message.senderId,
                                        name: message.message.senderId
                                    }
                                    : {
                                        id: "unknown",
                                        name: "unknown"
                                    })
                        };
                    }
                )
                .sort((messageA, messageB) => {
                    if (messageA.timetoken === messageB.timetoken) {
                        return 0;
                    } else if (messageA.timetoken > messageB.timetoken) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
            : [];
    }
);


export const getLatestMessageTimetokenByChannelId = createSelector(
    [getMessagesById],
    (messages) => {
        const channels = Object.keys(messages);
        let messagesTimetoken = {};
        channels.forEach(channel => {
            messagesTimetoken[channel] = messages[channel].length > 0 ? messages[channel].sort((a,b) => b.timetoken - a.timetoken )[0].timetoken : null
        })
        return messagesTimetoken;
    }
);


/**
 * Member Presence
 */

const getByPresenceSlice = state => state.chats.memberPresence;

export const getPresenceByConversationId = createSelector(
    [getByPresenceSlice],
    presence => {
        return presence.byId;
    }
);

/**
 * Channel Membership
 */

const getByConversationIdSlice = state => state.chats.conversationMembers;

export const getUsersByConversationId = createSelector(
    [getByConversationIdSlice],
    users => {
        return users.byId;
    }
);

export const getCurrentConversationMembers = createSelector(
    [
        getUsersById,
        getCurrentConversationId,
        getUsersByConversationId,
        getPresenceByConversationId
    ],
    (
        users,
        conversationId,
        conversationMemberships,
        conversationPresence
    ) => {
        const presence = conversationPresence[conversationId];
        return conversationMemberships[conversationId]
            ? conversationMemberships[conversationId].map(user => {
                const userData = {
                    ...users[user.id],
                    name: users[user.id]?.name || 'unknown' // TODO: need this to handle user without data
                }
                return {
                    ...userData,
                    presence: presence
                        ? presence.occupants.filter(occupant => {
                        return occupant.uuid === user.id;
                    }).length > 0
                        : false
                };
            })
            : [];
    }
);



/**
 *
 * Extra
 */

// find users who have messages in the channel but are not loaded yet
export const getUnknownUsers = createSelector(
    [getMessagesById, getCurrentConversationId, getUsersById],
    (messages, conversationId, users) => {
    return messages[conversationId]
        ? Object.values(messages[conversationId])
            .filter(message => message.channel === conversationId)
            .filter(
                message =>
                    // if the user is unknown queue up a request for the missing data
                    !users[message.message.senderId]
            )
            .map(message => message.message.senderId)
        : [];
    }
);
