import React from 'react';
import {createUseStyles} from "react-jss";
import Button from "../../Commons/Button.js";
import done from '../../assets/images/done.svg'

const useStyles = createUseStyles(theme => ({
    root: {
        display: 'grid',
        width: '100%',
        gridRowGap: theme.spacing * 3,
        justifyItems: 'center'
    },
    title:{
        fontWeight: 600,
        margin: [theme.spacing * 2, 0],
        display: 'grid',
        alignItems: 'center',
        gridColumnGap: theme.spacing,
        gridTemplateColumns: '1fr auto',
        textAlign: 'center',
        '& img':{
            marginBottom: theme.spacing,
            width: 72
        }
    },
}));

const CompletedBarterModal = ({onClose}) => {
    const classes = useStyles();

    return <div className={classes.root}>
        <img src={done} alt={'done'}/>
        <h4 className={classes.title}> {'La permuta è stata accettata e completata.'} </h4>
        <Button type={'button'} data-primary data-small onClick={onClose}> ok </Button>
    </div>
};

export default CompletedBarterModal;