import React, {useCallback} from 'react';
import {createUseStyles} from "react-jss";
import Popover from "../../../Commons/Popover";
import ConversationDescription from "./ConversationDescription/ConversationDescription";
import {useDispatch, useSelector} from "react-redux";
import {usePagination} from "../../../hooks/usePagination";
import {fetchAllUserData} from "pubnub-redux";
import {setUsersPagination} from "../../../store/actions/chat/pagnationActions";
import {getUsersPaginationState} from "../../../store/reducers/chat/pagination";
import {getUsersById} from "../../../store/reducers/chat/chats";
import {getLoggedInUserUuid} from "../../../store/reducers/user";
import {joinConversation} from "../../../store/actions/chat/joinedConversationsActions";
import {getJoinedConversationsByUserId} from "../../../store/reducers/chat/joinedConversations";



const useStyles = createUseStyles(theme => ({
    popoverRoot:{
      minWidth: 600
    },
    root: {
        textAlign: 'left',
        overflowY: 'scroll',

        '& > div': {
            paddingBottom: '8px',
            borderTop: '1px solid #f2f5f8',
            borderBottom: '1px solid #f2f5f8',
            '&:last-child': { borderBottom: 'none' },
        }
    },

}))


const JoinConversationDialog = ({closeCb}) => {

    const joinedChannels = useSelector(getJoinedConversationsByUserId);
    const currentUserUuid = useSelector(getLoggedInUserUuid);
    const dispatch = useDispatch();
    const classes = useStyles();
    const users = useSelector(getUsersById);

    const storedPaginationState = useSelector(getUsersPaginationState);

    const restorePaginationState = useCallback(() => {
        return storedPaginationState;
    }, [storedPaginationState]);

    const savePaginationState = useCallback(
        (_, pagination, count, pagesRemain) => {
            dispatch(setUsersPagination({ pagination, count, pagesRemain }));
        },
        [dispatch]
    );

    const getNextPage = useCallback(
        async (next, total) => {
            const pageSize = 20;
            const action = await dispatch(
                fetchAllUserData({
                    limit: pageSize,
                    include: {
                        totalCount: true
                    },
                    page: {
                        next: next || undefined
                    }
                })
            );
            const response = action.payload.response;
            return {
                results: response.data,
                pagination: response.next,
                pagesRemain:
                    response.totalCount && total
                        ? total + response.data.length < response.totalCount
                        : response.data.length === pageSize
            };
        },
        [dispatch]
    );

    const { containerRef, endRef } = usePagination(
        getNextPage,
        undefined,
        savePaginationState,
        restorePaginationState
    );

    return <Popover
        withHeader
        className={classes.popoverRoot}
        onClose={closeCb}
        title={'Inizia una conversazione'}
    >
        <section className={classes.root} ref={containerRef}>
            {/* TODO: add search */}
            {Object.keys(users).filter(user => user !== currentUserUuid).map(targetUserUuid => {
                const targetName = users[targetUserUuid]?.name || 'Utente sconosciuto';
                return (
                    <ConversationDescription
                        key={`conversationDescription-${targetUserUuid}`}
                        onClick={() => {
                            dispatch(joinConversation(targetUserUuid, joinedChannels[currentUserUuid]));
                            closeCb()
                        }}
                        name={targetName}
                        conversation={targetUserUuid}
                    />
                )
            })}
            <div ref={endRef} />
        </section>
    </Popover>
}

export default JoinConversationDialog