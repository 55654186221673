import React, {useState} from 'react';
import {createUseStyles} from 'react-jss';
import SubHeading from "../../Commons/SubHeading.js";
import {useDispatch} from "react-redux";
import {useForm} from "react-hook-form";
import {errorHandler,} from "../../helpers/utils.js";
import {alertToggle} from "../../store/actions/alertActions.js";
import Spinner from "../../Commons/Spinner.js";
import Text from "../../Commons/Text.js";
import Button from "../../Commons/Button.js";
import * as Yup from "yup";
import {httpResetPasswordLogged} from "../../HttpRequests/user.http.js";

const useStyles = createUseStyles(theme => ({
    gridContainer: {
        'position': 'relative',
        // with big/small subheader like permute page
        height: ({bigSubHeader}) => `calc(100vh - ${bigSubHeader ? theme.bigSubHeader + theme.headerHeight : theme.smallSubHeader + theme.headerHeight}px)`,
        // without subheader
        //height: `calc(100vh - ${theme.headerHeight}px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        [theme.mUp]: {
            padding: [theme.spacing * 4],
        },
        [theme.s]: {
            padding: [theme.spacing],
        }
    },
    formRoot: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridColumnGap: theme.spacing * 5,
        borderRadius: 20,
        maxWidth: "480px",
        margin: "0 auto",
        padding: [theme.spacing * 3, theme.spacing * 2],
        gridRowGap: theme.spacing * 2,
        ...theme.paper
    },
    btns: {
        marginTop: theme.spacing * 4,
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '1fr 1fr',
        width: 300,
        gridColumnGap: theme.spacing * 2,
        justifySelf: 'center',
    },
    card:{
        display:'grid',
        gridRowGap: theme.spacing * 2,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        background: theme.colors.white,
        ...theme.shadow,
        borderRadius: theme.spacing * 2,
        padding: theme.spacing * 3,
        maxWidth: 500,
        margin: "0 auto",
        '& svg':{
            justifySelf: 'center',
            width: 40,
            fill: theme.colors.green,
        }
    }
}));

const validationSchema = Yup.object().shape({
    current_password: Yup.string()
        .required('La password attuale è richiesta'),
    new_password: Yup.string()
        .required('La nuova password è richiesta')
        .min(8, 'La password deve contenere almeno 8 caratteri'),
    new_password_confirmation: Yup.string()
        .oneOf([Yup.ref('new_password'), null], 'Le password non coincidono')
        .required('La nuova password è richiesta')
});

const EditPassword = () => {
    const classes = useStyles({bigSubHeader: false});
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const {register, handleSubmit, errors, reset} = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            current_password: '',
            new_password: '',
            new_password_confirmation: '',
        },
        validationSchema
    });

    const onSubmit = async (values) => {
         try {
             setLoading(true);
            await httpResetPasswordLogged(values);
             dispatch(alertToggle('Password correttamente aggiornata'), 'success');
             reset();
         } catch (e) {
             errorHandler(e, dispatch)
         } finally {
             setLoading(false);
         }
    };

    return <>
        <SubHeading title={'Modifica password'} type={'small'}/>
        <div className={classes.gridContainer}>
            {loading && <Spinner overlayFullscreen={true}/>}
            <form onSubmit={handleSubmit(onSubmit)} className={classes.formRoot}>
                <Text type={'password'} label={'Password attuale'} name={'current_password'} ref={register} errors={errors.current_password}/>
                {/*<small onClick={() => resetRequestEmail()}>Password dimenticata ? Richiedila di reimpostarla </small>*/}
                <Text type={'password'} label={'Nuova password'} name={'new_password'} ref={register} errors={errors.new_password}/>
                <Text type={'password'} label={'Conferma nuova password'} name={'new_password_confirmation'} ref={register} errors={errors.new_password_confirmation}/>
                <div className={classes.btns}>
                    <Button type="button" onClick={() => reset()} data-small>Annulla</Button>
                    <Button type={'submit'} data-primary data-small>Salva</Button>
                </div>
            </form>
        </div>
    </>
};

export default EditPassword;
