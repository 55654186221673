import React, {useEffect, useState} from 'react';
import {createUseStyles} from "react-jss";
import Spinner from "../../Commons/Spinner.js";
import Button from "../../Commons/Button.js";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from 'react-router-dom';
import {useFieldArray, useForm} from "react-hook-form";
import {errorHandler} from "../../helpers/utils.js";
import * as Yup from "yup";
import Text from "../../Commons/Text.js";
import {alertToggle} from "../../store/actions/alertActions.js";
import Checkbox from "../../Commons/Checkbox.js";
import {AddIcon, RemoveIcon} from "../../assets/icons.js";
import {httpCarCreateEstimate} from "../../HttpRequests/reconditioning.http.js";
import {SetCarEstimates, SetCarRepairs} from "../../store/actions/repairsActions.js";

const useStyles = createUseStyles(theme => ({
    root: {
        display: 'grid',
        width: '100%',
        gridRowGap: theme.spacing * 3,
        justifyItems: 'center',
        maxWidth: 700
    },
    title: {
        fontWeight: 600,
        margin: [0,theme.spacing * 2,theme.spacing * 2, 0],
        display: 'grid',
        alignItems: 'center',
        gridColumnGap: theme.spacing,
        gridTemplateColumns: '1fr auto',
        '& img': {
            width: 72
        }
    },
    body: {
        width: '100%',
        padding: [0, theme.spacing * 2],
        maxHeight: "600px", overflow: "scroll"
    },
    rows: {
        display: 'grid',
        gridTemplateColumns: "1.2fr 0.8fr 1fr 0.4fr",
        gridColumnGap: theme.spacing * 2,
        width: '100%',
        alignItems: 'center',
        marginBottom: theme.spacing * 2
    },
    full:{gridColumn: '1 / span 5'},
    btns:{
        margin: "48px auto 0",
        display: 'grid',
        alignItems: 'center',
        gridColumn: '1/5',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: theme.spacing,
        width: 300,
        justifySelf: 'end'
    }

}));


const RestoreCreateModal = ({onClose,getCarRepairsCb}) => {
    const dispatch = useDispatch();
    const {id} = useParams();
    const classes = useStyles();
    const estimates = useSelector(state => state.repairs.estimates);
    const [isLoading, setIsLoading] = useState(false);
    const {register, handleSubmit, control} = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
    });
    const {fields, append, remove} = useFieldArray({control, name: "repair_rows"});

    const onSubmit = async (values) => {
        setIsLoading(true);
        try {
            const {data: newEstimate} = await httpCarCreateEstimate(values.repair_rows,id);
            dispatch(SetCarEstimates([...estimates, newEstimate]));
            await getCarRepairsCb();
            onClose()
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        append({ operation: '', price: '' , material: false });
    }, []);

    return <div className={classes.root}>
        {isLoading && <Spinner/>}
        <h4 className={classes.title}> Inserimento perizia manuale </h4>

        <form className={classes.body} onSubmit={handleSubmit(onSubmit)}>
            {fields.map((item, index) => <div key={item.bId ? item.bId : item.id}
                                              className={classes.rows}>
                <Text type={'text'} label={'Operazione'} name={`repair_rows[${index}].operation`} required={true}
                      ref={register}/>
                <Text type={'number'} label={'Prezzo'} name={`repair_rows[${index}].price`} required={true} min={0}
                      ref={register}/>
                <Checkbox key={index} label={'Materia prima'} labelOn={null} isSmall={true} name={`repair_rows[${index}].material`}
                          ref={register} />
                <Button type="button" onClick={() => remove(index)} data-primary>
                    <RemoveIcon data-color/>
                </Button>
            </div>)}
            <Button className={classes.full} type="button" onClick={() => append({
                id: 'new',
                name: "",
                bId: `new${Math.random().toString(36).substring(7)}`
            })} data-primary icon={<AddIcon data-color/>}> Aggiungi Riparazione </Button>

            <div className={classes.btns}>
                <Button type="button" onClick={() => onClose()} data-secondary>Annulla</Button>
                <Button type={'submit'} disabled={!(!!fields.length)} data-primary>Salva</Button>
            </div>
        </form>
    </div>
};

export default RestoreCreateModal;