export const REFRESH_CHAT_STATUS = "REFRESH_CHAT_STATUS";
export const SET_CHAT_OPENED = "SET_CHAT_OPENED";

export const refreshStatus = (status) => {
    return {
        type: REFRESH_CHAT_STATUS,
        payload: status
    }
}

export const setChatOpened = (isOpened) => {
    return {
        type: SET_CHAT_OPENED,
        payload: isOpened
    }
}



