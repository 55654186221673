import {focusOnConversation} from "./currentConversationActions";
import {httpInitChat, httpStartDirectChat} from "../../../HttpRequests/chat.http";
import {getCurrentConversationMessages} from "../../reducers/chat/chats";
import {isEmptyObj} from "../../../Pages/messageCenter/chatUtils";
import {errorHandler} from "../../../helpers/utils";
import {addUnreadMessages, setUnreadMessageCounter} from "./unreadMessageActions";
import {getCurrentConversationId} from "../../reducers/chat/currentConversation";
import {getLoggedInUserUuid} from "../../reducers/user";
import {getChatStatusOpened} from "../../reducers/chat/status";
import {fetchMemberships} from "pubnub-redux";
import {getLoggedInUserJoinedConversations} from "../../reducers/chat/joinedConversations";

export const FETCH_MEMBERSHIPS_COMMAND = 'pubnub/FETCH_MEMBERSHIPS_COMMAND';
export const FETCHING_MEMBERSHIPS = 'pubnub/FETCHING_MEMBERSHIPS';
export const MEMBERSHIPS_RETRIEVED = 'pubnub/MEMBERSHIPS_RETRIEVED';
export const ERROR_FETCHING_MEMBERSHIPS = 'pubnub/ERROR_FETCHING_MEMBERSHIPS';
// setMemberships()
export const SET_MEMBERSHIPS_COMMAND = 'pubnub/SET_MEMBERSHIPS_COMMAND';
export const SETTING_MEMBERSHIPS = 'pubnub/SETTING_MEMBERSHIPS';
export const MEMBERSHIPS_SET = 'pubnub/MEMBERSHIPS_SET';
export const ERROR_SETTING_MEMBERSHIPS = 'pubnub/ERROR_SETTING_MEMBERSHIPS';
// removeMemberships()
export const REMOVE_MEMBERSHIPS_COMMAND = 'pubnub/REMOVE_MEMBERSHIPS_COMMAND';
export const REMOVING_MEMBERSHIPS = 'pubnub/REMOVING_MEMBERSHIPS';
export const MEMBERSHIPS_REMOVED = 'pubnub/MEMBERSHIPS_REMOVED';
export const ERROR_REMOVING_MEMBERSHIPS = 'pubnub/ERROR_REMOVING_MEMBERSHIPS';
// objects events
export const MEMBERSHIP_REMOVED_EVENT = 'pubnub/MEMBERSHIP_REMOVED_EVENT';
export const MEMBERSHIP_SET_EVENT = 'pubnub/MEMBERSHIP_SET_EVENT';




const membershipSetEventReceived = (
    payload
)=> ({
    type: MEMBERSHIP_SET_EVENT,
    payload,
});

const membershipRemovedEventReceived = (
    payload
)=> ({
    type: MEMBERSHIP_REMOVED_EVENT,
    payload,
});


/**
 * Join a new conversation
 */

// Search inside already joined conversation
// if channel doesn't exists, call httpStartDirectChat(targetUuid)
export const joinConversation = (targetUserUuid, joinedChannel) => {
    return (dispatch, getState, context) => {
        const state = getState();
        // check if channel with user is already opened
        const {id: channelId} = joinedChannel &&
            !isEmptyObj(joinedChannel) &&
            joinedChannel.find(channel => channel.id.includes(targetUserUuid)) || {};
        const currentUserId = getLoggedInUserUuid(state)
        return channelId ?
            dispatch(focusOnConversation(channelId))
            :
            httpStartDirectChat(targetUserUuid)
                .then(response => {
                    const {name} = response.data;
                    console.log('start#', name)
                    context.pubnub.api.subscribe({
                        channels: [name],
                        withPresence: true
                    });
                    dispatch(fetchMemberships({
                        uuid: currentUserId,
                        include: {
                            channelFields: true,
                            customChannelFields: true,
                            customFields: true,
                            totalCount: true
                        }
                    })).then(r => dispatch(focusOnConversation(name)))
                })
                .catch(error => {
                    dispatch(errorHandler, error)
                });
    };
};


// this has to be done with custom fields inside the channel membership
export const updateUnreadMessageCounter = (channel, {timetoken, publisher} = {}) => {
    return (dispatch, getState, {pubnub : {api}}) => {
        const state = getState();
        const currentConversationId = getCurrentConversationId(state);
        const userUuid = getLoggedInUserUuid(state);
        const isChatOpened = getChatStatusOpened(state);
        // Add the unread message when needed
        if(publisher !== userUuid && currentConversationId !== channel || !isChatOpened ){
            return dispatch(addUnreadMessages(channel, 1))
        }
        // Retrieve the token from the channel if not passed
        let lastRead = timetoken || null;
        if(!timetoken){
            const messages = getCurrentConversationMessages(state);
            if(messages.length === 0) return;
            lastRead = messages[messages.length - 1].timetoken
        }
        //TODO add check on store if lastRead is equal to current stored lastRead
        return api.objects.setMemberships({
            channels: [
                {
                    id: channel,
                    custom: {
                        lastReadTimetoken: lastRead
                    },
                }
            ]
        }, (status, results) => {
            dispatch(setUnreadMessageCounter(channel, 0))
            console.log('marked as read', results, status)
        })
    }
};


export const refreshConversations = (channel) => {
    return (dispatch, getState, {pubnub : {api}}) => {
        const state = getState();
        const userUuid = getLoggedInUserUuid(state);
        const joinedConversations = getLoggedInUserJoinedConversations(state);
        const exists = joinedConversations.find(ch => ch.id === channel)
        if(!exists){
            httpInitChat()
                .then(res => {
                    dispatch(fetchMemberships({
                        uuid: userUuid,
                        include: {
                            channelFields: true,
                            customChannelFields: true,
                            customFields: true,
                            totalCount: true
                        }
                    }))
                })
                .catch(err => {
                    console.log('re-init failed', err)
                })
        }
    }
};


export const createMembershipListener = (dispatch) => ({
    objects: (payload) => {
        if (payload.message.type === 'membership') {
            switch (payload.message.event) {
                case 'set':
                    console.log('payload', payload)
                    dispatch(refreshConversations(payload.channel));  // payload.message.channel
                    break;
                case 'delete':
                    dispatch(membershipRemovedEventReceived(payload.message));
                    break;
                default:
                    break;
            }
        }
    },
});