import React, {useState} from 'react';
import {createUseStyles} from "react-jss";
import {useForm} from "react-hook-form";
import Text from "../../Commons/Text.js";
import Button from "../../Commons/Button.js";
import * as Yup from "yup";
import {useDispatch} from "react-redux";
import {httpAddRowsToRepair} from "../../HttpRequests/reconditioning.http.js";
import {addUnexpectedRepair} from "../../store/actions/repairsActions.js";
import {errorHandler, repairTypeResolver} from "../../helpers/utils.js";
import {alertToggle} from "../../store/actions/alertActions.js";
import Spinner from "../../Commons/Spinner.js";

const useStyles = createUseStyles(theme => ({
    root: {
        display: 'grid',
        width: '100%',
        gridRowGap: theme.spacing * 3,
        justifyItems: 'center',
        '& button':{
            marginTop: theme.spacing * 3
        }
    },
    title:{
        fontWeight: 600,
        margin: [theme.spacing * 2, 0]
    },
    btns: {
        width: "300px",
        display: "grid",
        alignItems: "center",
        gridColumnGap: theme.spacing,
        gridTemplateColumns: "1fr 1fr"
    }
}));

const validationSchema = Yup.object().shape({
    price_per_hour: Yup.number().integer('Sono accettati solo interi.')
        .typeError('Sono accettati solo valori numerici.')
        .min(1,'Inserisci un valore maggiore di 0')
        .transform((value, originalValue) => originalValue.trim() === "" ? null: value).required(`Inserisci il costo all'ora.`),
    operation:  Yup.string().required(`Inserisci l'operazione.`),
    work_hours:  Yup.number().integer('Sono accettati solo interi.')
        .typeError('Sono accettati solo valori numerici.')
        .min(0,'Inserisci le ore necessarie')
        .transform((value, originalValue) => originalValue.trim() === "" ? null: value)
        .required(`Inserisci le ore lavorative.`),
});

const AddRepairRowModal = ({onCloseCb,repair, carId,}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const {register, handleSubmit, formState, errors} = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        nativeValidation: false,
        validationSchema
    });

    const onSubmit = async (values) => {
        const dataToSend = {
            ...values,
            price: values.price_per_hour * values.work_hours,
            estimate_id: repair.estimate_id
        };
        setLoading(true);
        try{
            const {data: unexpectedRow} = await httpAddRowsToRepair(carId,repair.id,dataToSend);
            dispatch(addUnexpectedRepair(repair, repairTypeResolver(repair.type), unexpectedRow));
            dispatch(alertToggle( 'Deroga riparazione correttamente aggiunta', 'success'));
            onCloseCb();
        }catch (e) {
            errorHandler(e,dispatch);
        }finally {
            setLoading(false)
        }
    };

    return <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        {loading && <Spinner/>}
        <Text type={'text'} label={'Operazione*'} name={'operation'} ref={register} errors={errors.operation}/>
        <Text type={'number'} label={'Prezzo per ora*'} name={'price_per_hour'} ref={register} errors={errors.price}/>
        <Text type={'number'} label={'Ore da lavorare*'} name={'work_hours'} ref={register} errors={errors.work_hours}/>

        <div className={classes.btns}>
            <Button type="reset" onClick={onCloseCb} data-secondary data-small>Annulla</Button>
            <Button type={'submit'} data-primary data-small disabled={!formState.isValid || formState.isSubmitting}>Salva</Button>
        </div>
    </form>
};

export default AddRepairRowModal;