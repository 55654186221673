import React, {forwardRef} from 'react';
import {createUseStyles} from 'react-jss';
import cx from 'classnames';
import PropTypes from "prop-types";

const useStyles = createUseStyles(theme => ({
    root: {
        'width': '100%',
        /* 'display': 'grid', */
        'grid-auto-flow': 'row',
        position:'relative',
        '& svg':{
            position: 'absolute',
            right: 8,
            bottom: 10,
            fill: theme.colors.grey,
            width: '20px!important',
            transition: 'ease-in 300ms',
            '&:hover':{
                fill: theme.colors.black,
            }
        }
    },
    label: theme.control.label,
    input: theme.control.input,
    inputError: theme.control.inputError,
    error: {
        color: theme.colors.red,
        position: 'absolute',
        top: ({label}) => label ? 64 : 44,
        left: 0,
    },
}));

const Text = forwardRef(({className, inputClassName, label, errors, rootProps,icon, type = 'text', ...props}, ref) => {
    const classes = useStyles({errors: !!errors,label});

    return (
        <label data-control id={props.name} className={cx(classes.root, className)}>
            {!!label && <span className={classes.label}>{label}</span>}
            <input type={type} className={cx(classes.input, !!errors ? classes.inputError : '', inputClassName)} ref={ref} {...props} {...rootProps}/>
            {icon}
            {(!!errors && !props?.disabled) && <small className={classes.error}>{errors.message}</small>}
        </label>
    );
});

export default Text;

Text.propTypes = {
    rootProps: PropTypes.any,
    className: PropTypes.any,
    inputClassName: PropTypes.any,
    label: PropTypes.string,
    type: PropTypes.oneOf(["email","password","text","number"]),
    disabled: PropTypes.bool,
    errors: PropTypes.object,
    isSmall: PropTypes.bool,
};