import React from 'react';
import {createUseStyles} from 'react-jss';
import {toMoney} from "../../helpers/utils.js";

const useStyles = createUseStyles(theme => ({
    root: {
        'grid-row-gap': theme.spacing,
        display: "grid",
        position: "relative",
        fontSize: "14px",
        alignItems: "center",
        gridRowGap: "8px",
        gridTemplateColumns: "1fr 1fr auto",
        padding: "12px 0",
        borderBottom: `1px solid ${theme.colors.lightGrey}`,
        [theme.s]: {

        },
        [theme.mUp]: {

        },
    },

    total:{
        display: 'grid',
        'grid-row-gap': theme.spacing,
        gridTemplateColumns: '1fr auto',
        alignItems: 'center',
        fontWeight: '600',
        fontSize: 16,
        marginTop: 16,
        '& span':{
            textTransform: 'uppercase',
            color: theme.colors.black
        }
    }
}));

const DamagesRecap = ({damages}) => {
    const classes = useStyles();
    let damagesTotal = 0;

    return <>
        {damages.map((damage, index) => {
            damagesTotal = damage.price + damagesTotal;
            return <div className={classes.root} key={index}>
                <span> {damage.title} </span>
                <span> {damage.label} </span>
                <span> {toMoney(damage.price)} </span>
            </div>
        })}
        <div className={classes.total}>
            <span> Totale </span>
            <span> {toMoney(damagesTotal)} </span>
        </div>
    </>
};

export default DamagesRecap;
