import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {CarIcon} from "../../assets/icons.js";
import RangePicker from "../../Commons/RangePicker.js";
import BarChart from "./BarChart.js";
import {useDispatch} from "react-redux";
import {acquisitionChannels, errorHandler, formattedDateIso, salesChannels, toMoney} from "../../helpers/utils.js";
import moment from "moment";
import {httpGetProfitChartData} from "../../HttpRequests/dashboard.http.js";
import Select from "react-select";

const useStyles = createUseStyles(theme => ({
    root: {
        position:'relative',
        overflow: 'hidden'
    },
    heading:{
        display: "grid",
        gridTemplateColumns: "auto 1fr 120px auto",
        height: 48,
        alignItems: "center",
        padding: theme.spacing,
        gridColumnGap: theme.spacing,
        '& svg':{
            fill: theme.colors.black
        },
        '& b':{
            fontSize: 14
        }
    },
    chart:{
        height: 280,
    },
    chartLoader:{
        height: 280,
        display: "grid",
        alignItems: "center",
        justifyItems: "center",
        fontSize: 14
    },
    stats:{
        top: 60,
        right: 8,
        position: "absolute",
        minWidth: 180,
        '& p':{
            marginBottom: 8,
            display: "grid",
            alignItems: "center",
            gridTemplateColumns: "auto 1fr auto",
            gridColumnGap: 8,
            fontSize: 13,
            '& span':{
                width: 10, height: 10, borderRadius: 10
            },
            '& strong':{

            },
        }
    }
}));

const style = {
    control: (base, state) => ({
        ...base,
        height: 32,
        fontSize: 13,
        borderColor: '#E3E3E3',
        borderRadius: 8,
        color: '#737373',
        fontWeight: 500,
        boxShadow: '0 !important',
        padding: '0',
        minHeight: 'auto',
        cursor: 'pointer',
        flexWrap: 'unset',
        '& hover':{
            borderColor: '#E3E3E3',
        }
    }),
    indicatorsContainer: (defaults) => ({
        ...defaults,
        '& svg': {
            fill: '#C6C6C6'
        }
    }),
    indicatorSeparator: (defaults) => ({
        ...defaults,
        width: 0,
        padding: '0'
    }),
    clearIndicator: (defaults) => ({
        ...defaults,
        borderRadius: '50px',
        marginRight: 4,
        padding: 0,
        backgroundColor: '#E3E3E3',
        '& svg': {
            fill: '#737373'
        }
    }),
    option: (base, state) => ({
        ...base,
    })
};


const MoneyFlows = ({selected}) => {
    const classes = useStyles();
    const [loading,setLoading] = useState(true);
    const [filters,setFilters] = useState({
        startDate: formattedDateIso(new Date(moment().startOf('year'))),
        endDate: formattedDateIso(new Date()),
    });
    const [chartData, setChartData] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if(selected){
            getProfitsChart();
        }
    },[selected,filters]);

    const getProfitsChart = async () => {
        const dataToSend = {
            date_start: filters?.startDate,
            date_end: filters?.endDate,
            acquisition_channel: filters?.acquisition_channel?.value,
        };
        setLoading(true)
        try {
            const {data} = await httpGetProfitChartData(selected,dataToSend);
            setChartData({chart:  data?.data,total_price: data?.total_price,total_withdrawal_price: data?.total_withdrawal_price })
        }catch (e){
            errorHandler(e, dispatch);
        }finally {
            setLoading(false)
        }
    }

    const onAcquisitionType = (item) => {
        setFilters({...filters, acquisition_channel: item });
    }

    return <div className={classes.root}>
        <div className={classes.heading}>
            <CarIcon data-color/>
            <b>Entrate e uscite</b>
            <Select options={acquisitionChannels} isClearable={true} placeholder={'Provenienza'} styles={style} onChange={onAcquisitionType} value={filters?.acquisition_channel}/>
            <div className={classes.datePicker}>
                <RangePicker inForm={false} label={''} isSmall={true} openTo={'bottom'} alignRight={true}
                             filters={filters} setFilters={setFilters} startDate={new Date(moment().startOf('year'))}/>
            </div>
        </div>
        <div className={classes.body}>
            <div className={classes.stats}>
                <p><span style={{background: '#91DF91'}}/> Entrate <strong> {toMoney(chartData?.total_price)}</strong></p>
                <p><span style={{background: '#ED746F'}}/> Uscite <strong>{toMoney(chartData?.total_withdrawal_price)}</strong></p>
            </div>
            {loading && <span className={classes.chartLoader}> Caricamento dati... </span>}
            {!loading && <div className={classes.chart}>
                <BarChart data={chartData?.chart} />
            </div>}
        </div>
    </div>
};

export default MoneyFlows;
