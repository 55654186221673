import React from 'react';
import StepProgress from './StepProgress';
import ProgressBar from './ProgressBar';
import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles((theme) => ({
    StepInfoContainer: {
        maxHeight: 130,
        display: "grid",
        alignItems: "center",
        borderRadius: 20,
        padding: theme.spacing * 2,
        gridRowGap: theme.spacing * 2,
        background: theme.colors.white,
        ...theme.shadow,
    },
    ProgressContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gridColumnGap: theme.spacing * 2,
        alignItems: 'center',
        '& strong':{
            fontSize: 12,
        }
    },
}));

const StepInfo = ({percentCompleted}) => {
    const classes = useStyles();

    return <div className={classes.StepInfoContainer}>
            <div className={classes.ProgressContainer}>
                <strong>Completamento vettura</strong>
                <ProgressBar percentCompleted={percentCompleted} barSize="small" c/>
            </div>
        <StepProgress/>
        </div>
};

export default StepInfo;
