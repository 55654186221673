import React from 'react';
import {createUseStyles} from 'react-jss';
import {useHistory} from "react-router-dom";
import {matchPath} from "react-router";
import SidebarNavLink from "../../Commons/SidebarNavLink";

const useStyles = createUseStyles((theme) => ({
    voices: {
        zIndex:2,
        display: ({collapsedNav}) => collapsedNav ? "none" : "grid",
        color: theme.colors.white,
        fontWeight: 500,
        '& h4':{
            display: 'block',
            marginBottom: theme.spacing * 2,
            textTransform: 'uppercase',
            fontSize: 18,
        }
    },
}));

const CarRevisionNavigation = ({collapsedNav}) => {
    const classes = useStyles({collapsedNav});
    const {location: {pathname}} = useHistory();
    const {params: {id}} = matchPath(pathname, {
        path: "/stock/pricing/:id",
        exact: false,
        strict: false
    });

    return <>
        <div className={classes.voices}>
            <h4>Scheda veicolo</h4>
            <SidebarNavLink to={`/stock/pricing/${id}`}>Info Vettura</SidebarNavLink>
            <SidebarNavLink to={`/stock/pricing/${id}/economics`}>Dati economici</SidebarNavLink>
        </div>
    </>
};

export default CarRevisionNavigation;
