export const convertTimestampToTime = (ts) => {
    const date = new Date(parseInt(ts) / 10000);
    const minutes = date.getMinutes();
    return `${date.getHours()}:${minutes > 9 ? minutes : "0" + minutes}`;
};


export const getUniqueColor = (string, colorSet) => {

    const getNumericHash = (string = 'unknown') => {
        let hash = 0;
        if (string.length === 0) return hash;
        for (let i = 0; i < string.length; i++) {
            let char = string.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash = hash & hash; // Convert to 32bit integer
        }
        return hash;
    };

    const modulo = colorSet.length;
    const colorIndex = ((getNumericHash(string) % modulo) + modulo) % modulo;
    return colorSet[colorIndex];
};


export const getTargetUserUuid = (originUuid, channelName) => {
    const uuidRegex = /([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/gi;
    let matchArray, uuids = [];
    while ((matchArray = uuidRegex.exec(channelName)) !== null) {
        uuids.push(matchArray[0])
    }
    uuids = uuids.filter( uuid => uuid !== originUuid);
    return uuids.pop();
}

export const getExtension = url => {
    return url.split('.').pop();
}

export const isEmptyObj = (empty) => empty && Object.keys(empty).length === 0 && empty.constructor === Object

export const getInitials = (name) => {
    return name.match(/\b\w/g) || [];
};

export const convertTimestampToDate = (ts) => {
    const date = new Date(parseInt(ts) / 10000);
    return `${date.getDate()}/${date.getMonth() +1}/${date.getFullYear()}`;
};
