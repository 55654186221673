import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import axios from 'axios';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'react-jss';
import theme from '../src/theme.js';
import App from './App';
//reducers
import alertsReducer from './store/reducers/alerts.js';
import usersReducer from './store/reducers/user';
import settingsReducer from './store/reducers/settings.js';
import bartersReducer from './store/reducers/barters.js';
import carsReducer from './store/reducers/cars.js';
import campaignsReducer from './store/reducers/campaigns';
import autocompletesReducer from "./store/reducers/autocompletes.js";
import repairsReducer from "./store/reducers/repairs.js";
import shiftsReducer from "./store/reducers/shifts.js";
import communicationsReducer from "./store/reducers/communications.js";
//token api
import applyAppTokenRefreshInterceptor from './axiosTokenRefresher/axiosTokenRefresher.js';
// Chat
import {chatsReducer} from "./store/reducers/chat/chats";
import PubNub from "pubnub";
import pubnubConfig from "./Pages/messageCenter/chatConfig";
import {PubNubProvider} from "pubnub-react";


const appReducer = combineReducers({
	users: usersReducer,
	alerts: alertsReducer,
	settings: settingsReducer,
	barters: bartersReducer,
	cars: carsReducer,
	campaigns:campaignsReducer,
	autocompletes: autocompletesReducer,
	repairs: repairsReducer,
	shifts: shiftsReducer,
	chats: chatsReducer,
	communications: communicationsReducer
});


const rootReducer = (state, action) => {
	if (action.type === 'RESET_APP') {
		state = undefined;
	}
	return appReducer(state, action);
}

// for chrome ext
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// Chat API context
const pubnub = new PubNub(pubnubConfig);
let thunkArgument = {
	pubnub: {
		api: pubnub
	}
};
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk.withExtraArgument(thunkArgument))));

//all axios setup here
axios.defaults.baseURL = process.env.REACT_APP_ENDPOINT;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.timeout = 30000;
axios.interceptors.request.use((request) => {
	const token = localStorage.getItem('access_token');
	if (token) request.headers['Authorization'] = `Bearer ${token}`;
	if (request.url.includes('amazonaws')) {
		delete request.headers;
	}
	return request;
});
applyAppTokenRefreshInterceptor(axios);

ReactDOM.render(
	<ThemeProvider theme={theme}>
		<Provider store={store}>
			<PubNubProvider client={pubnub} >
				<BrowserRouter basename={'/'}>
					<App />
				</BrowserRouter>
			</PubNubProvider>
		</Provider>
	</ThemeProvider>,
	document.getElementById('root')
);

serviceWorker.unregister();
