import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {createUseStyles} from "react-jss";
import {MESSAGE_TYPE} from "../../messageModel";
import MessageEditor from "./MessageEditor/MessageEditor";
import {
    getConversationMessageDraft,
    getCurrentConversationType,
} from "../../../../store/reducers/chat/chats";
import {getCurrentConversationId} from "../../../../store/reducers/chat/currentConversation";
import {discardMessageDraft, updateMessageDraft} from "../../../../store/reducers/chat/draft";
import {getLoggedInUserUuid} from "../../../../store/reducers/user";
import {sendTypingIndicator} from "../../../../store/reducers/chat/typingIndicator";
import {
    COMMUNICATIONS_CHANNEL_TYPE,
    HIDE_TYPING_INDICATOR,
    SHOW_TYPING_INDICATOR,
    TYPING_INDICATOR_DURATION_SECONDS
} from "../../../../helpers/constants/chat";
import {getLoggedInUserJoinedConversations} from "../../../../store/reducers/chat/joinedConversations";
import {sendMessage} from "../../../../store/actions/chat/chatActions";


const useStyles = createUseStyles(theme => ({
    onlyRead: {
        backgroundColor: '#fff',
        textAlign: 'center',
        padding: [16, 32],
        fontSize: 14,
        color: '#000'
    }
}))


const typingIndicators = {};

const MessageInput = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const conversationId= useSelector(getCurrentConversationId);
    const uuid = useSelector(getLoggedInUserUuid);
    const joinedConversations = useSelector(getLoggedInUserJoinedConversations);
    const currentConversationType = useSelector(getCurrentConversationType);
    const currentConversationName = joinedConversations.find( conv => conv.id === conversationId)?.name;
    const isAdmin = useSelector(state => state.users.operations.admin);

    const defaultDraft = {
        type: MESSAGE_TYPE.text,
        senderId: uuid, // Default sender it's me
        text: ""
    };
    const storedDraft = useSelector(getConversationMessageDraft);
    const message = storedDraft ? storedDraft : defaultDraft;

    const notifyTyping = () => {
        if (!typingIndicators[conversationId]) {
            typingIndicators[conversationId] = true;
            dispatch(sendTypingIndicator(SHOW_TYPING_INDICATOR));

            // allow sending additional typing indicators 1 seconds before display duration ends
            setTimeout(() => {
                typingIndicators[conversationId] = false;
            }, (TYPING_INDICATOR_DURATION_SECONDS - 1) * 1000);
        }
    };

    const notifyStopTyping = () => {
        if (typingIndicators[conversationId]) {
            typingIndicators[conversationId] = false;
            dispatch(sendTypingIndicator(HIDE_TYPING_INDICATOR));
        }
    };

    /* */
    const send = (appMessage) => {
        dispatch(sendMessage(appMessage));
        dispatch(discardMessageDraft(conversationId));
        typingIndicators[conversationId] = false;
    };

    /* */
    const update = (appMessage) => {
        dispatch(updateMessageDraft(conversationId, appMessage));
        if (appMessage.text.length > 0) {
            notifyTyping();
        } else {
            notifyStopTyping();
        }
    };

    switch(currentConversationType){
        case COMMUNICATIONS_CHANNEL_TYPE:
            return <div className={classes.onlyRead}>Canale in sola lettura</div>
        default:
            return <MessageEditor
                message={message}
                sendDraft={send}
                updateDraft={update}
            />
    }
}

export default MessageInput;