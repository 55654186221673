import React from 'react';
import {createUseStyles} from 'react-jss';
import {toMoney} from "../../helpers/utils";

const useStyles = createUseStyles(theme => ({
    row: {
        'grid-row-gap': theme.spacing,
        display: "grid",
        position: "relative",
        fontSize: 12,
        alignItems: "center",
        gridRowGap: "8px",
        gridTemplateColumns: "1fr auto",
        padding: "12px 0 6px 0",
        borderBottom: `1px solid ${theme.colors.lightGrey}`,
        [theme.s]: {},
        [theme.mUp]: {},
    },
    total: {
        display: 'grid',
        'grid-row-gap': theme.spacing,
        gridTemplateColumns: '1fr auto',
        alignItems: 'center',
        fontWeight: '600',
        fontSize: 14,
        marginTop: 16,
        '& span': {
            textTransform: 'uppercase',
            color: theme.colors.black
        }
    },
    secondary:{
        color: theme.colors.darkGrey,
    },
    danger:{
      color: theme.colors.red, textDecoration: 'underline'
    }
}));

const EconomicsSustainedCosts = ({car}) => {
    const classes = useStyles();

    return <>
        <div className={classes.row}>
            <span> Prezzo di acquisto </span>
            <span> {car?.withdrawal_price ? toMoney(car.withdrawal_price) : '-'} </span>
        </div>
        <div className={classes.row}>
            <span> Minivoltura </span>
            <span> 150,00 €  </span>
        </div>
        <div className={classes.row}>
            <span className={classes.secondary}> Ripristini stimati </span>
            <span className={classes.secondary} style={{textAlign: 'end'}}> {car?.estimate_total ? toMoney(car.estimate_total) : '-'} </span>
            <span className={classes.danger}> Totale costi di ripristino sostenuti </span>
            <span> {car?.total_restore_price ? toMoney(car.total_restore_price) : '-'} </span>
        </div>

        <div className={classes.row}>
            <span> Costi di giacenza </span>
            <span> {car?.stock_price ? toMoney(car.stock_price) : '-'} </span>
        </div>
        <div className={classes.total}>
            <span> TOTALE </span>
            <span> {toMoney(car?.withdrawal_price + car?.estimate_total + car?.total_restore_price + car?.stock_price +150)} </span>
        </div>
    </>
};

export default EconomicsSustainedCosts;
