import React from 'react';
import {createUseStyles} from "react-jss";



const useStyles = createUseStyles(theme => ({
    root: {
        color: '#565656',
        padding: '10px 20px 10px 20px',
        fontSize: 12,
        textTransform: 'capitalize',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: '#f0f3f7',
        }
    },
    body: {
        display: 'flex',
    },
    icon: {
        width: '36px',
        height: '36px',
        borderRadius: '10px',
        padding: '11px 13px',
        fontWeight: 500,
        backgroundColor: '#f0f3f7',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
    },
    name: {
        margin: '5px 20px',
        /*width: '123px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',*/
    },
    description: {
        margin: '5px 20px',
        color: '#aeafae',
    }
}))

const ConversationDescription = ({
                                     name,
                                     conversation,
                                     onClick
                                 }) => {
    const classes = useStyles();

    return <div className={classes.root} onClick={onClick}>
        <div className={classes.body}>
            <div className={classes.icon}>
                #
            </div>
            <div className={classes.content}>
                <div className={classes.name}>
                    {name}
                </div>
            </div>
        </div>
    </div>
}

export default ConversationDescription