import axios from 'axios';

/**
 *
 * @param data
 * @param {String} data.email
 * @param {String} data.password
 */
export const httpLoginUser = (data) => {
	const url = '/auth/login';
	//const url = 'https://api-stage.och.it/api/auth/login';
	return axios.post(url, data);
};
//todo remove this one
/*export const httpLoginUser = () => {
    return axios.get('http://www.mocky.io/v2/5ec3ebbf300000f8c339c564');
};*/

/**
 * simple logout
 */
export const httpLogoutUser = () => {
	const url = '/auth/logout';
	return axios.get(url);
};

/**
 * get user info
 */
export const httpGetUserMe = () => {
	return axios.get('/me');
};

/**
 *
 * @param token
 */
export const httpConfirmEmail = (token) => {
	const url = `/auth/signup/activate/${token}`;
	return axios.get(url);
};

/**
 *
 * edit logged user info
 */
export const httpUpdateUser = (id,data) => {
	return axios.put(`/users/${id}`, {...data});
};

/**
 *
 * request of password change
 */
export const httpResetPasswordRequest = (data) => {
	const url = '/password_recovery/create';
	return axios.post(url, data);
};

/**
 *
 * request of password change logged
 */
export const httpResetPasswordLogged = (data) => {
	const url = '/auth/password';
	return axios.post(url, data);
};

/**
 *
 * request of password change
 */
export const httpResetPasswordPermission = (token) => {
	const url = `/password_recovery/find/${token}`;
	return axios.get(url);
};

/**
 *
 * confirm and set new password
 */
export const httpResetPasswordConfirmation = (data) => {
	const url = '/password_recovery/reset';
	return axios.post(url, data);
};

