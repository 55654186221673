export const SET_USERS_LIST = 'SET_USERS_LIST';
export const EDIT_USER = 'EDIT_USER';
export const SET_DAMAGES_LIST = 'SET_DAMAGES_LIST';
export const EDIT_SINGLE_DAMAGE = 'EDIT_SINGLE_DAMAGE';
export const EDIT_UBICATION = 'EDIT_UBICATION';
export const SET_UBICATIONS_LIST = 'SET_UBICATIONS_LIST';
export const SET_BUSINESS_LIST = 'SET_BUSINESS_LIST';
export const EDIT_BUSINESS = 'EDIT_BUSINESS';

/**
 * How to use setUsersList:
 * dispatch(setUsersList(usersList));
 **/
export const setUsersList = (usersList) => {
	return {
		type: SET_USERS_LIST,
		usersList: usersList
	};
};

/**
 * How to use setUsersList:
 * dispatch(editUser(user));
 **/
export const editUser = (user) => {
	return {
		type: EDIT_USER,
		user: user
	};
};

/**
 * How to use setUsersList:
 * dispatch(setDamagesList(damagesFlatList));
 **/
export const setDamagesList = (damagesFlatList) => {
	return {
		type: SET_DAMAGES_LIST,
		damagesFlatList: damagesFlatList
	};
};

/**
 * How to use setUsersList:
 * dispatch(editDamageInList({key:key, price:price}));
 **/
export const editDamageInList = (damage) => {
	return {
		type: EDIT_SINGLE_DAMAGE,
		damage: damage
	};
};

/**
 * How to use setUbicationsList:
 * dispatch(setUbicationsList(ubicationsList));
 **/
export const setUbicationsList = (ubicationsList) => {
	return {
		type: SET_UBICATIONS_LIST,
		ubicationsList: ubicationsList
	};
};

/**
 * How to use editUbication:
 * dispatch(editUbication(ubication));
 **/
export const editUbication = (ubication) => {
	return {
		type: EDIT_UBICATION,
		ubication: ubication
	};
};

/**
 * How to use setBusinessList:
 * dispatch(setBusinessList(businessList));
 **/
export const setBusinessList = (businessList) => {
	return {
		type: SET_BUSINESS_LIST,
		businessList: businessList
	};
};

/**
 * How to use editBusiness:
 * dispatch(editBusiness(business));
 **/
export const editBusiness = (business) => {
	return {
		type: EDIT_BUSINESS,
		business: business
	};
};
