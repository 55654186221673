export const SET_REPAIRS = 'SET_REPAIRS';
export const SET_ESTIMATES = 'SET_ESTIMATES';
export const RESTORE_DISCARDED_REPAIR = 'RESTORE_DISCARDED_REPAIR';
export const UPDATE_REPAIR = 'UPDATE_REPAIR';
export const DELETE_REPAIR = 'DELETE_REPAIR';
export const SET_ESTIMATES_ATTACHMENT = 'SET_ESTIMATES_ATTACHMENT';
export const SET_REPAIR_ATTACHMENT = 'SET_REPAIR_ATTACHMENT';
export const ADD_UNEXPECTED_REPAIR = 'ADD_UNEXPECTED_REPAIR';

/**
 * How to use SetCarRepairs:
 * dispatch(SetCarRepairs(repairs));
 **/
export const SetCarRepairs = (repairs) => {
    return {
        type: SET_REPAIRS,
        repairs: repairs
    };
};

/**
 * How to use SetCarRepairs:
 * dispatch(SetCarRepairs(repairs));
 **/
export const SetCarEstimates = (estimates) => {
    return {
        type: SET_ESTIMATES,
        estimates: estimates
    };
};

/**
 * How to use setEstimateAttachment:
 * dispatch(setEstimateAttachment(newAttachment));
 **/
export const setEstimateAttachment = (attachment) =>{
    return {
        type: SET_ESTIMATES_ATTACHMENT,
        attachment: attachment
    };
};

/**
 * How to use setRepairAttachment:
 * dispatch(setRepairAttachment(newAttachment));
 **/
export const setRepairAttachment = (attachment) =>{
    return {
        type: SET_REPAIR_ATTACHMENT,
        attachment: attachment
    };
};

/**
 * How to use restoreDiscardedRepair:
 * dispatch(restoreDiscardedRepair(repairs));
 **/
export const restoreDiscardedRepair = (repair) => {
    return {
        type: RESTORE_DISCARDED_REPAIR,
        repair: repair
    };
};

/**
 * How to use updateRepair:
 * dispatch(updateRepair(repairType,repairs));
 * * repairType should be one of :  tireCenter, bodyShop, workshop
 **/
export const updateRepair = (repair, repairType) => {
    return {
        type: UPDATE_REPAIR,
        repair: repair,
        repairType: repairType
    };
};

/**
 * How to use addUnexpectedRepair:
 * dispatch(addUnexpectedRepair(repairType,repairs,unexpectedRow));
 * * repairType should be one of :  tireCenter, bodyShop, workshop
 **/
export const addUnexpectedRepair = (repair, repairType, unexpectedRow) => {
    return {
        type: ADD_UNEXPECTED_REPAIR,
        repair: repair,
        repairType: repairType,
        unexpectedRow: unexpectedRow
    };
};

/**
 * How to use deleteRepair:
 * dispatch(deleteRepair(repairType,repairs));
 * * repairType should be one of :  tireCenter, bodyShop, workshop
 **/
export const deleteRepairAction = (repair,repairType) => {
    return {
        type: DELETE_REPAIR,
        repair: repair,
        repairType: repairType
    };
};
