import {
    REFRESH_CHAT_STATUS,
    SET_CHAT_OPENED
} from "../../actions/chat/statusActions";
import {createSelector} from "reselect";

const initialState = {
    chatReady: false,
    chatOpened: false
};

export const chatStatusReducer = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case REFRESH_CHAT_STATUS: {
            console.log('chat ready:', action.payload)
            return {
                ...state,
                chatReady: action.payload
            };
        }
        case SET_CHAT_OPENED: {
            console.log('chat layout open:', action.payload)
            return {
                ...state,
                chatOpened: action.payload
            };
        }
        default:
            return state;
    }
};


const getChatStatusSlice = state => state.chats.chatStatus;

export const getChatStatusOpened = createSelector(
    [getChatStatusSlice],
    chatStatus => {
        return chatStatus.chatOpened;
    }
);