import React from 'react';
import PropTypes from 'prop-types';
import {createUseStyles} from 'react-jss'
import Snackbar from "../Commons/Snackbar.js";
import {useSelector} from "react-redux";
import publickBg from "../assets/images/public_bg.jpg";
import bigMark from "../assets/images/bigmark.png";
import FloatingBtns from "../Commons/FloatingBtns.js";
import publicLogo from "../assets/images/publicLogo.svg"

const useStyles = createUseStyles(theme => ({
    root: {
        position:'relative',
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "1fr",
        height: '100vh',
        width: '100%',
    },
    main: {
        display: "grid",
        alignItems: "center",
        background: theme.colors.white
    },
    sideImg: {
        position: 'relative',
        'background-image': `url(${publickBg})`,
        backgroundOrigin: "unset",
        backgroundPosition: "right",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        '& img':{
            position: "absolute", right: "40px", bottom: "40px"
        }
    },
    bigMark:{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1,
        maxHeight: '100vh'
    }
}));

const PublicLayout = ({children}) => {
    const alert = useSelector(state => state.alerts);
    const classes = useStyles({});

    return <main className={classes.root}>
        <img src={bigMark} alt={'mark'} className={classes.bigMark}/>
        <Snackbar content={alert?.message} level={alert?.level}/>
        {/* <Header authenticated={false}/>*/}
        <div className={classes.main}>
            {children}
        </div>
        <div className={classes.sideImg}>
            <img src={publicLogo} alt={'logo'}/>
        </div>
        <FloatingBtns/>
    </main>
};

PublicLayout.propTypes = {
    children: PropTypes.any,
};

export default PublicLayout;
