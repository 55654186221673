import React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import BreadCrumbsRender from './BreadCrumbsRender.js';

const useStyles = createUseStyles((theme) => ({
	root: {
		gridArea: 'header',
		...theme.paper,
		...theme.shadow,
		height: ({ type }) => (type === 'barter' ? theme.barterSubHeader : type === 'big' ? theme.bigSubHeader : theme.smallSubHeader),
		display: 'grid',
		gridTemplateColumns: '1fr',
		gridRowGap: ({ type,rowGap }) => (type === 'big' ? rowGap : theme.spacing),
		padding: ({ type }) => (type === 'big') ? `${theme.spacing}px ${theme.spacing * 2}px ${theme.spacing * 2}px` : `0px ${theme.spacing * 2}px`,
		alignItems: 'center',
		position: 'relative',
	},
	titleAndSearch: {
		display: 'grid',
		gridTemplateColumns: '1fr auto',
		alignItems: ({ titleAndSearchAlign }) => titleAndSearchAlign ? titleAndSearchAlign : 'end',
		'& span': {
			'& h3': {
				marginTop: theme.spacing,
				fontWeight: 600,
				[theme.s]: {
					fontSize: 20
				},
				[theme.mUp]: {
					fontSize: 28
				},
			},
			'& small':{
				fontSize: 13,
				fontWeight: 400,
				color: theme.spacing.grey
			}
		}
	},
	rightSearch: {
		display: 'grid',
		gridTemplateColumns: '1fr auto',
		gridColumnGap: theme.spacing * 2
	},
	filters: {
		justifySelf: ({ justifySelf }) => justifySelf
	}
}));


const SubHeading = ({className, title, breadcrumbs, rightSearchComponent, filtersComponent,
						justifySelf = 'start', type, subTitleData,rowGap= 24,
						titleAndSearchAlign,salesforceError }) => {
	const classes = useStyles({ type, justifySelf,rowGap,titleAndSearchAlign });
	return (
		<main className={cx(classes.root, className)}>
			<div className={classes.titleAndSearch}>
				<span>
					<BreadCrumbsRender/>
					<h3>{title}</h3>
					{subTitleData && <small>{subTitleData}</small>}
				</span>
				<div className={classes.rightSearch}>{rightSearchComponent}</div>
			</div>
			{filtersComponent && <div className={classes.filters}>{filtersComponent}</div>}
			{salesforceError}
		</main>
	);
};

export default SubHeading;
