import React, {useState,useEffect} from 'react';
import {createUseStyles} from "react-jss";
import {useForm} from "react-hook-form";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {errorHandler, formattedDateIso} from "../../helpers/utils.js";
import {alertToggle} from "../../store/actions/alertActions.js";
import Spinner from "../../Commons/Spinner.js";
import DayPickerInput from "../../Commons/DayPickerInput.js";
import {httpEditCarInfo} from "../../HttpRequests/barters.http.js";

const useStyles = createUseStyles(theme => ({
    root: {
        position: "absolute",
        display: "grid",
        padding: "16px 32px",
        top: "0",
        background: theme.colors.violet,
        left: "0px",
        right: "0",
        '& h4': {
            width: '100%',
            marginTop: theme.spacing * 3,
            padding: [theme.spacing * 2, 0],
            fontSize: 24,
            fontWeight: 600
        },
    },
    form: {
        display: "grid",
        gridTemplateColumns: "200px 200px",
        gridColumnGap: "16px",
        alignItems: "center",
        justifySelf: "baseline"
    }
}));

const validationSchema = Yup.object().shape({
    deliver_date: Yup.string().required(`Inserisci la data di consegna stimata`).nullable(),
});

const RestoreChangeDelivery = ({carId}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const car = useSelector(state => state.cars.currentCar);
    const [loading, setLoading] = useState(false);
    const {register, control, setValue, watch} = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        nativeValidation: false,
        validationSchema
    });

    useEffect(() => {
        if (car?.id) {
            setValue('deliver_date', car?.deliver_date ? new Date(car?.deliver_date.toString().replace(/-/g, "/")) : '');
            setValue('disharge_date', car?.disharge_date ? new Date(car?.disharge_date.toString().replace(/-/g, "/")) : '');
        }
    }, [car])

    const changeDelivery = async (name, selected, label) => {
        const deliverDate = selected;
        if ((deliverDate === null || deliverDate === '') || car['deliver_date']?.toString() === deliverDate?.toString()) return;
        const dataToSend = {
            car: {
                id: car.id,
                deliver_date: deliverDate ? formattedDateIso(deliverDate) : null,
                is_draft: true,
            }
        };
        try {
            await httpEditCarInfo(dataToSend);
            dispatch(alertToggle(`La data di consegna prevista è stata modificata correttamente.`, 'success'))
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    };

    return <>
        &#8203;
        <div className={classes.root}>
            <h4>Preconsegna</h4>
            {loading && <Spinner overlayFullscreen={true}/>}
            <form className={classes.form}>
                <DayPickerInput
                    label={'Data prevista di fine lavori'}
                    name={'deliver_date'}
                    inForm={true}
                    ref={register}
                    control={control}
                    isClearable={false}
                    showYearDropdown={true}
                    closeOnSelect={true}
                    callback={changeDelivery}
                />
                <DayPickerInput
                    label={'Data di consegna'}
                    name={'disharge_date'}
                    inForm={true}
                    ref={register}
                    control={control}
                    disabled={true}
                    showYearDropdown={true}
                    closeOnSelect={true}
                    callback={changeDelivery}
                />
            </form>
        </div>
        &#8203;
    </>
};

export default RestoreChangeDelivery;