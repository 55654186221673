import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import LineChart from "./LineCahrt.js";
import {SettingsIcon} from "../../assets/icons.js";
import RangePicker from "../../Commons/RangePicker.js";
import {useDispatch, useSelector} from "react-redux";
import {errorHandler, formattedDateIso} from "../../helpers/utils.js";
import moment from "moment";
import { httpGetTasksChartData} from "../../HttpRequests/dashboard.http.js";
import Select from "react-select";

const useStyles = createUseStyles(theme => ({
    root: {
        position:'relative',
        overflow: 'hidden'
    },
    heading:{
        display: "grid",
        gridTemplateColumns: "auto 1fr 120px 120px auto",
        height: 48,
        alignItems: "center",
        padding: theme.spacing,
        gridColumnGap: theme.spacing,
        '& svg':{
            fill: theme.colors.black
        },
        '& b':{
            fontSize: 14
        }
    },
    chart:{
        height: 370,
    },
    chartLoader:{
        height: 370,
        display: "grid",
        alignItems: "center",
        justifyItems: "center",
        fontSize: 14
    }
}));

const style = {
    control: (base, state) => ({
        ...base,
        height: 32,
        fontSize: 13,
        borderColor: '#E3E3E3',
        borderRadius: 8,
        color: '#737373',
        fontWeight: 500,
        boxShadow: '0 !important',
        padding: '0',
        minHeight: 'auto',
        cursor: 'pointer',
        flexWrap: 'unset',
        '& hover':{
            borderColor: '#E3E3E3',
        }
    }),
    indicatorsContainer: (defaults) => ({
        ...defaults,
        '& svg': {
            fill: '#C6C6C6'
        }
    }),
    indicatorSeparator: (defaults) => ({
        ...defaults,
        width: 0,
        padding: '0'
    }),
    clearIndicator: (defaults) => ({
        ...defaults,
        borderRadius: '50px',
        marginRight: 4,
        padding: 0,
        backgroundColor: '#E3E3E3',
        '& svg': {
            fill: '#737373'
        }
    }),
    option: (base, state) => ({
        ...base,
    })
};

export const operations = [
    {
        "value": "internal_transport",
        "label": "Spostamento tra sedi"
    },
    {
        "value": "external_transport",
        "label": "Spostamento per sedi esterne"
    },
    {
        "value": "single_transport",
        "label": "Spostamento"
    },
    {
        "value": "assign_transport",
        "label": "Assegnazione spostamento"
    },
    {
        "value": "wash",
        "label": "Lavaggio"
    },
    {
        "value": "complete_wash",
        "label": "Lavaggio completo"
    },
    {
        "value": "photo",
        "label": "Fotografia"
    },
    {
        "value": "reconditioning",
        "label": "Ricondizionamento"
    },
    {
        "value": "stage_leasing",
        "label": "Documenti Leasing"
    },
    {
        "value": "stage_1",
        "label": "Documenti fase 1"
    },
    {
        "value": "stage_2",
        "label": "Documenti fase 2"
    },
    {
        "value": "stage_3",
        "label": "Documenti fase 3"
    },
    {
        "value": "stage_4",
        "label": "Documenti fase 4"
    }
];


const OperationAnalyses = ({selected}) => {
    const classes = useStyles();
    const [loading,setLoading] = useState(true);
    const [filters,setFilters] = useState({
        /*startDate: formattedDateIso(new Date(moment().subtract(1.5, 'months'))),*/
        startDate: formattedDateIso(new Date(moment().startOf('year'))),
        endDate: formattedDateIso(new Date()),
    });
    const [chartData, setChartData] = useState(null);
    const usersAutocomplete = useSelector(state => state.autocompletes.usersAutocomplete);
    const dispatch = useDispatch();

    useEffect(() => {
        if( selected ){
            getTasksChart({...filters})
        }
    },[filters,selected]);

    const getTasksChart = async () => {
        const dataToSend = {
            date_start: filters?.startDate,
            date_end: filters?.endDate,
            user_id: filters?.user_id?.value,
            type: filters?.type?.value,
        };
        setLoading(true)
        try {
            const {data} = await httpGetTasksChartData(selected,dataToSend);
            setChartData(data)
        }catch (e){
            errorHandler(e, dispatch);
        }finally {
            setLoading(false)
        }
    }

    const onChangeTaskType = (item) => {
        setFilters({...filters, type: item});
    }

    const onChangeUserId = (item) => {
        setFilters({...filters, user_id: item });
    }

    return <div className={classes.root}>
        <div className={classes.heading}>
            <SettingsIcon data-color/>
            <b>Analisi delle operazioni</b>
            <Select options={usersAutocomplete} placeholder={'Operatore'} styles={style} onChange={onChangeUserId} isClearable={true} value={filters?.user_id}/>
            <Select options={operations} placeholder={'Task'} styles={style} onChange={onChangeTaskType} isClearable={true} value={filters?.type}/>
            <div className={classes.datePicker}>
                <RangePicker inForm={false} label={''} isSmall={true} openTo={'bottom'} alignRight={true}
                             filters={filters} setFilters={setFilters} startDate={new Date(moment().startOf('year'))}/>
            </div>
        </div>
        {loading && <span className={classes.chartLoader}> Caricamento dati... </span>}
        {!loading && <div className={classes.chart}>
            <LineChart data={chartData} withLegends={false} tooltipPrefix={true} colorScheme={'dark2'}/>
        </div>}
    </div>
};

export default OperationAnalyses;
