import * as actionTypes from '../actions/shiftsActions.js';

const initialState = {
    shiftsList: [],
    shiftRequestsList: [],
};

const shiftsReducer = (state = initialState, action) => {
    let shiftsListCp = state.shiftsList;

    switch (action.type) {
        case actionTypes.SET_SHIFTS_LIST:
            return {
                ...state,
                shiftsList: action.shiftsList,
            };
        case actionTypes.UPDATE_SHIFTS_LIST:
            shiftsListCp = shiftsListCp.map(item => item.id === action.shift.id ? action.shift : item)
            return {
                ...state,
                shiftsList: shiftsListCp,
            };
        case actionTypes.SET_SHIFT_REQUESTS_LIST:
            return {
                ...state,
                shiftRequestsList: action.shiftRequestsList,
            };
        default:
            return state;
    }
};


export default shiftsReducer;





