import React, {useState} from 'react';
import {createUseStyles} from "react-jss";
import Button from "../../Commons/Button.js";
import {useDispatch} from "react-redux";
import alertTriangle from '../../assets/images/alertTriangle.svg'
import {httpCarsIdentify} from "../../HttpRequests/barters.http.js";
import {useHistory} from 'react-router-dom';
import {errorHandler} from "../../helpers/utils.js";
import Spinner from "../../Commons/Spinner.js";

const useStyles = createUseStyles(theme => ({
    root: {
        display: 'grid',
        width: '100%',
        gridRowGap: theme.spacing * 3,
        justifyItems: 'center'
    },
    title:{
        fontWeight: 600,
        margin: [theme.spacing * 2, 0],
        display: 'grid',
        alignItems: 'center',
        gridColumnGap: theme.spacing,
        gridTemplateColumns: '1fr auto',
        '& img':{
            width: 72
        }
    },
    results:{
        fontSize: 12,
        marginTop: - theme.spacing * 4,
        textAlign: 'center'
    },
    btns:{
        marginTop: theme.spacing * 2,
        display: 'grid',
        alignItems: 'center',
        gridColumnGap: theme.spacing * 2,
        gridTemplateColumns: '1fr 1fr',
    }
}));

const AlreadySet = ({onClose, plate, km, message,isNewVehicle = false}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);

    const onUpdateProcedure = async (update) => {
        try {
            setIsLoading(true);
            const {data} = await httpCarsIdentify({plate:plate, km:km, update:update});
            onClose();
            if(isNewVehicle){
                history.push(`/stock/${data.id}/new`)
            }else{
                history.push(`/barters/${data.id}`)
            }
        } catch (e) {
            // error 503 & update === true
            if (e.response.status === 503 && update){
                //always show error message
                errorHandler(e,dispatch);
                //force update with false
                await onUpdateProcedure(false)
            }else{
                //simply show error message
                errorHandler(e,dispatch);
            }
        } finally {
            setIsLoading(false);
        }
    };

    return <div className={classes.root}>
        {isLoading && <Spinner/>}
        <img src={alertTriangle} alt={'alert'}/>
        <h4 className={classes.title}> Attenzione! </h4>
        <span className={classes.results}>{message}</span>
        <div className={classes.btns}>
            <Button width={228} type={'button'} data-secondary data-small onClick={() => onUpdateProcedure(false)}> Crea una copia da aggiornare </Button>
            <Button width={228} type={'button'} data-primary data-small onClick={() => onUpdateProcedure(true)}>
                {isNewVehicle ? 'crea nuova auto' : 'crea nuova permuta'}
            </Button>
        </div>
    </div>
};

export default AlreadySet;