import React, {useState,useEffect} from 'react';
import {createUseStyles} from "react-jss";
import Radio from "../../Commons/Radio.js";
import DayPickerInput from "../../Commons/DayPickerInput.js";
import {useForm} from "react-hook-form";
import {errorHandler, retrieveValueForRs, warrantyDuration} from "../../helpers/utils.js";
import {useDispatch} from "react-redux";
import {setCurrentCar} from "../../store/actions/carsActions.js";
import {alertToggle} from "../../store/actions/alertActions.js";
import {httpEditCarInfo} from "../../HttpRequests/barters.http.js";
import Select from "../../Commons/Select.js";
import {httpGetWarranties} from "../../HttpRequests/stock.http.js";

const useStyles = createUseStyles(theme => ({
    warranty: {
        display: "grid",
        position: "relative",
        gridTemplateColumns: '1fr 1fr',
        gridRowGap: theme.spacing * 2,
        gridColumnGap: theme.spacing * 2,
        alignItems: 'start'
    },
    sectionRadio: {
        display: 'grid',
        gridTemplateColumns: '60px 60px',
        gridTemplateRows: '1fr 1fr',
        gridColumnGap: theme.spacing,
        gridRowGap: theme.spacing,
        '& p': {
            ...theme.control.label,
            gridColumn: '1 / 5'
        }
    }
}));

const Warranty = ({car}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [warrantyAutocomplete, setWarrantyAutocomplete] = useState([]);
    const {register, watch, control, reset, setValue} = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            ...car,
            warranty_activability: (!!car?.warranty_activability).toString(),
            certification_check: (!!car?.certification_check).toString(),
            warranty_activation: car?.warranty_activation ? new Date(car.warranty_activation) : null,
            warranty_expire_date: car?.warranty_expire_date ? new Date(car.warranty_expire_date) : null,
            warranty_length: retrieveValueForRs(warrantyDuration, car?.warranty_length),
        },
    });

    const resetValues = (currentCar) => {
        reset({
            ...currentCar,
            warranty_activability: (!!currentCar?.warranty_activability).toString(),
            certification_check: (!!currentCar?.certification_check).toString(),
            warranty_activation: currentCar?.warranty_activation ? new Date(currentCar.warranty_activation) : null,
            warranty_expire_date: currentCar?.warranty_expire_date ? new Date(currentCar.warranty_expire_date) : null,
            warranty_length: retrieveValueForRs(warrantyDuration, currentCar?.warranty_length),
            warranty_type: retrieveValueForRs(warrantyAutocomplete, currentCar?.warranty_type),
        });
    }

    useEffect(() => {
        if(car?.id){
            getWarrantyTypes();
            resetValues(car);
        }
    }, [car])

    const getWarrantyTypes = async () => {
        try{
            const {data: warranties} = await httpGetWarranties();
                setWarrantyAutocomplete(warranties);
                setValue('warranty_type', retrieveValueForRs(warranties, car?.warranty_type))
        }catch (e){
            errorHandler(e,dispatch)
        }
    }

    const editCarInfo = async (fieldName, value, label) => {
        if ((!value || car[fieldName]?.toString() === value.toString())) return;
        let dataToSend = {
            car: {
                id: car.id,
                [fieldName]: (fieldName === 'warranty_activability' || fieldName === 'certification_check') ? !!value : value
            }
        };
        try {
            const {data: currentCar} = await httpEditCarInfo(dataToSend);
            dispatch(setCurrentCar(currentCar));
            resetValues(currentCar);
            dispatch(alertToggle(`Il campo ${label} è stato modificato correttamente.`, 'success'))
        } catch (e) {
            errorHandler(e, dispatch);
            dispatch(setCurrentCar({...car}));
        }
    };

    return <>
        <form className={classes.warranty}>
            <span style={{gridColumn: '1 / span 2'}}>
                <Select name={'warranty_type'} options={warrantyAutocomplete} ref={register} control={control}
                        label={`Garanzia`} isClearable={false} placeholder={'seleziona una garanzia'}
                        onChange={(value) => {
                            editCarInfo('warranty_type', value[0].value, `Garanzia casa madre`);
                        }}/>
            </span>
            <div className={classes.sectionRadio}>
                <p className={classes.title}>Elegibilità garanzia</p>
                <Radio label={'Si'} name={'warranty_activability'} value={'true'} ref={register} isSmall={true}
                       onChange={() => editCarInfo('warranty_activability', watch('warranty_activability'), 'Elegibilità garanzia')}/>
                <Radio label={'No'} name={'warranty_activability'} value={'false'} ref={register} isSmall={true}
                       onChange={() => editCarInfo('warranty_activability', watch('warranty_activability'), 'Elegibilità garanzia')}/>
            </div>
            <div className={classes.sectionRadio}>
                <p className={classes.title}>Certificazione effettuata</p>
                <Radio label={'Si'} name={'certification_check'} value={'true'} ref={register} isSmall={true}
                       onChange={() => editCarInfo('certification_check', watch('certification_check'), 'Certificazione effettuata')}/>
                <Radio label={'No'} name={'certification_check'} value={'false'} ref={register} isSmall={true}
                       onChange={() => editCarInfo('certification_check', watch('certification_check'), 'Certificazione effettuata')}/>
            </div>

            <span style={{gridColumn: '1 / span 2'}}>
                <Select name={'warranty_length'} options={warrantyDuration} ref={register} control={control}
                        label={`Durata`} isClearable={false}
                        onChange={(value) => {
                            editCarInfo('warranty_length', value[0].value, `Durata`);
                        }}/>
            </span>

            <DayPickerInput label={'Data di attivazione '} name={'warranty_activation'} inForm={true}
                            ref={register} callback={editCarInfo} control={control} closeOnSelect={true}/>

            <DayPickerInput label={'Data di scadenza '} name={'warranty_expire_date'} inForm={true}
                            ref={register} callback={editCarInfo} control={control} closeOnSelect={true}/>
        </form>
    </>
};

export default Warranty;
