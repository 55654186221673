import React, {useState} from 'react';
import {createUseStyles} from "react-jss";
import {useForm} from "react-hook-form";
import Spinner from "../../Commons/Spinner.js";
import Text from "../../Commons/Text.js";
import Button from "../../Commons/Button.js";
import * as Yup from "yup";
import Textarea from "../../Commons/Textarea.js";

const useStyles = createUseStyles(theme => ({
    root: {
        display: 'grid',
        width: '100%',
        gridRowGap: theme.spacing * 3,
        justifyItems: 'center',
        '& button':{
            marginTop: theme.spacing * 3
        }
    },
    title:{
        fontWeight: 600,
        margin: [theme.spacing * 2, 0]
    },
    btns: {
        width: "300px",
        display: "grid",
        alignItems: "center",
        gridColumnGap: theme.spacing,
        gridTemplateColumns: "1fr 1fr"
    }
}));

const validationSchema = Yup.object().shape({
    withdrawal_price: Yup.string()
        .required(`Inserisci il prezzo.`),
    note: Yup.string()
        .required(`Inserisci una motivaizone.`)
});

const EditWithdrawalPrice = ({onChangePriceCb,barter}) => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const {register, handleSubmit, formState,reset, errors} = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            withdrawal_price: barter.withdrawal_price,
            note: barter.note,
        },
        validationSchema
    });

    const onSubmit = async (values) => {
        setIsLoading(true)
        await onChangePriceCb(values)
        setIsLoading(false)
    };

    return <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        {isLoading && <Spinner/>}
        <Text type={'number'} label={'Prezzo*'} name={'withdrawal_price'} ref={register} errors={errors.withdrawal_price}/>
        <Textarea rows={"4"} label={'Note*'} name={'note'} ref={register} errors={errors.note}/>
        <div className={classes.btns}>
            <Button type="reset" onClick={() => reset()} data-secondary data-small>Annulla</Button>
            <Button type={'submit'} data-primary data-small disabled={!formState.isValid || formState.isSubmitting}>Salva</Button>
        </div>
    </form>
};

export default EditWithdrawalPrice;