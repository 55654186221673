import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {useSelector} from "react-redux";
import PrivateExternalLayout from "../Containers/PrivateExternalLayout";

const PrivateExternalsRoute = ({sidebar, ...rest}) => {
	const access_token = useSelector(state=>state.users.access_token);
	const userOperations = useSelector(state=>state.users.operations);

	const externalPermissionsCheck = (userOperations,rest) => {
		if(userOperations?.admin) return <Route {...rest} />;

		if(userOperations?.body_shop || userOperations?.workshop || userOperations?.tire_center){
			return <Route {...rest} />
		}else if(userOperations?.supplier){
			return <Redirect to={'/external-warehouses'}/>
		}else{
			return <Redirect to={'/my-tasks'}/>
		}
	}

	return access_token ? (
		<PrivateExternalLayout>
			{externalPermissionsCheck(userOperations,rest)}
		</PrivateExternalLayout>
	) : (
		<Redirect to={'/auth/login'}/>
	);
}


export default PrivateExternalsRoute;
