import React from 'react';
import {Link} from "react-router-dom";
import {ArrowLeft} from "../assets/icons.js";

const Back = ({callback, to,}) => callback ?
    <span onClick={() => callback()} style={{
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'auto 1fr',
        gridColumnGap: 8,
        cursor: 'pointer',
        zIndex: 2,
        position: 'relative'
    }}>
        <ArrowLeft/> <span>Indietro</span>
    </span>
    :
    <Link to={to} style={{
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'auto 1fr',
        gridColumnGap: 8,
        textDecoration: 'none',
        zIndex: 2,
        position: 'relative'
    }}>
        <ArrowLeft/> <span>Indietro</span>
    </Link>;

export default Back;