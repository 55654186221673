import React from 'react';
import {createUseStyles} from 'react-jss';
import SidebarNavLink from "../../Commons/SidebarNavLink";
import {useSelector} from "react-redux";

const useStyles = createUseStyles((theme) => ({
	voices: {
		zIndex:2,
		display: ({collapsedNav}) => collapsedNav ? "none" : "grid",
		color: theme.colors.white,
		fontWeight: 500,
	},
}));


const PrimaryNavigation = ({collapsedNav}) => {
	const classes = useStyles({collapsedNav});
	const userOperations = useSelector(state => state.users.operations);
	const isAdmin = useSelector(state => state.users.user.operations?.admin);

	return <>
		<div className={classes.voices}>
			{isAdmin && <SidebarNavLink to={'/'}>Dashboard</SidebarNavLink>}
			<SidebarNavLink to={'/my-tasks'}>I miei Task</SidebarNavLink>
			{(isAdmin || userOperations?.wash_access) && <SidebarNavLink to={'/list/washes'}>Lista lavaggi</SidebarNavLink>}
			{(isAdmin || userOperations?.photo_access) && <SidebarNavLink to={'/list/photos'}>Lista foto</SidebarNavLink>}
			{(isAdmin || userOperations?.reconditioning_access) &&<SidebarNavLink to={'/list/examinations'}>Da periziare</SidebarNavLink>}
			{(isAdmin || userOperations?.reconditioning_access) &&<SidebarNavLink to={'/list/restores'}>Lista ripristini</SidebarNavLink>}
			{(isAdmin || userOperations?.pricing_approval) && <SidebarNavLink to={'/list/price-proposals'}>Proposte nuovo prezzo</SidebarNavLink>}
			{(isAdmin || userOperations?.transfer_access) &&<SidebarNavLink to={'/list/shift-requests'}>Richieste spostamenti</SidebarNavLink>}
			{(isAdmin || userOperations?.transfer_access) &&<SidebarNavLink to={'/list/shifts'}>Lista spostamenti</SidebarNavLink>}
		</div>
	</>
};

export default PrimaryNavigation;
