import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useLocation,useHistory} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {errorHandler} from "../../helpers/utils.js";
import {httpCarReconditioning} from "../../HttpRequests/reconditioning.http.js";
import qs from "query-string";
import Spinner from "../../Commons/Spinner.js";
import {setCurrentCar} from "../../store/actions/carsActions.js";

const useStyles = createUseStyles(theme => ({
    gridContainer: {
        'position': 'relative',
        // with big/small subheader like permute page
        height: ({bigSubHeader}) => `calc(100vh - ${bigSubHeader ? theme.bigSubHeader + theme.headerHeight : theme.smallSubHeader + theme.headerHeight}px)`,
        // without subheader
        //height: `calc(100vh - ${theme.headerHeight}px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        padding: ({isTablet}) => isTablet ? "32px 16px 0" : "32px 32px 0",

        [theme.s]: {
            padding: [theme.spacing],
        },
    },
    card:{
        display:'grid',
        gridRowGap: theme.spacing * 2,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        background: theme.colors.white,
        ...theme.shadow,
        borderRadius: theme.spacing * 2,
        padding: theme.spacing * 3,
        maxWidth: 500,
        margin: "0 auto",
        '& svg':{
            justifySelf: 'center',
            width: 40,
            fill: theme.colors.green,
        }
    }
}));

const CarRestoreIframeHandler = () => {
    const location = useLocation();
    const history = useHistory();
    const classes = useStyles({bigSubHeader: false});
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const getReconditioningData = async (params) => {
        setLoading(true);
        try {
            const {data: car} = await httpCarReconditioning(params);
            setCurrentCar(car);
            history.push(`/stock/${car?.id}/restore`);
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        //console.log(qs.parse(location.search, {arrayFormat: 'comma', parseBooleans: true}));
        const params = qs.parse(location.search, {arrayFormat: 'comma', parseBooleans: true});
        getReconditioningData(params);
    }, []);

    return <>
        <section className={classes.gridContainer}>
                <div className={classes.card}>
                    <div style={{position: 'relative', margin: '24px 0'}}>{loading && <Spinner/>}</div>
                    <h2 className={classes.title}>Stiamo elaborando la tua perizia</h2>
                    <p className={classes.subtitle} style={{marginBottom: '24px'}}>
                        A breve verrai reindirizzato alla pagina con i ricondizionamenti del veicolo processato.
                    </p>
                </div>
                {/*<Button data-primary onClick={() => history.push('/')}> Torna alla Home </Button>*/}
        </section>
    </>
};


export default CarRestoreIframeHandler
