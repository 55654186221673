import React from 'react';
import {createUseStyles} from "react-jss";
import cx from 'classnames';
import {SupportIcon} from "../assets/icons.js";

const useStyles = createUseStyles(theme => ({
    floatingBtn: {
        position: "fixed",
        height: "40px!important",
        width: "40px!important",
        borderRadius: "40px!important",
        bottom: "8px!important",
        zIndex: 1000,
        right: 16,
        display: "grid",
        alignItems: "center",
        justifyItems: "center",
        '& svg': {
            fill: theme.colors.white
        }
    },
    support:{
        color:theme.colors.white,
        backgroundColor: `${theme.colors.alertRed}!important`,
        transition: 'ease-in 300ms',
        '&:hover':{
            backgroundColor: `${theme.colors.red}!important`,
        }
    },
}));

const FloatingBtns = () => {
    const classes = useStyles();

    return <>
       <a data-anchor-unstyled href={'https://z3v1w4x4p8j.typeform.com/to/fnzPgZef'} target={'_blank'} className={cx(classes.floatingBtn, classes.support)}>
            <SupportIcon data-color/>
        </a>
    </>
};

export default FloatingBtns;