import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import Table from "../../Commons/Table.js";
import {
    errorHandler,
    formattedDate,
    removeUnsettedKeyInObj,
    toMoney,
} from "../../helpers/utils.js";
import SubHeading from "../../Commons/SubHeading.js";
import {useDispatch} from "react-redux";
import SimpleSearch from "../../Commons/SimpleSearch.js";
import {setCarsRevision} from "../../store/actions/carsActions.js";
import cx from 'classnames';
import qs from "query-string";
import {httpGetTaskType} from "../../HttpRequests/tasks.http.js";

const useStyles = createUseStyles(theme => ({
    gridContainer: {
        'position': 'relative',
        height: ({bigSubHeader}) => `calc(100vh - ${bigSubHeader ? theme.bigSubHeader + theme.headerHeight : theme.smallSubHeader + theme.headerHeight}px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        display: 'grid',
        gridTemplateColumns: '1fr',
        [theme.mUp]: {
            padding: [theme.spacing * 4, theme.spacing * 4,0],
        },
        [theme.s]: {
            padding: [theme.spacing],
        },
    },
    filters: {},
    table: {},
    tableHeader: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        gridColumnGap: theme.spacing,
        alignItems: 'center',
        marginBottom: theme.spacing * 2,
        height: 80,
        '& h3': {
            fontWeight: 600,
            lineHeight: 1
        },
        '& small': {
            color: theme.colors.darkGrey,
            fontSize: 16,
        }
    },
    dot: {
        width: 10,
        height: 10,
        borderRadius: '50%',
        marginRight: theme.spacing,
        '&:before': {
            content: '""'
        }
    },
    green: {
        backgroundColor: theme.colors.green,
    },
    yellow: {
        backgroundColor: theme.colors.yellow,
    }, red: {
        backgroundColor: theme.colors.red,
    },
}));

const PriceProposalList = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [priceProposals, setPriceProposals] = useState([])
    //table statuses
    const [total, setTotal] = useState(null);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(null);
    const classes = useStyles({bigSubHeader: false});
    //filters things
    const [sorter, setSorter] = useState(null);
    const [filters, setFilters] = useState({price:[0,0]});

    //is reset is temporary to solve async update issue of filters
    const getPriceProposals = async (actualPage = 1,sorters = sorter, isReset = false) => {
        if (lastPage && actualPage > lastPage) return;
        if (actualPage === 1) {
            dispatch(setCarsRevision([]))
        }
        setLoading(true);

        try {
            const {data: {data: newPriceProposals, total, last_page}} = await httpGetTaskType('pending_price', {
                limit: 20, ...filters,
                page: actualPage
            });
            if (actualPage === 1) {
                setPriceProposals(newPriceProposals)
            } else {
                setPriceProposals(priceProposals.concat(newPriceProposals))
            }
            setTotal(total);
            setLastPage(last_page)
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    };

    const onRowClickPath = (item) => `/stock/${item?.car_id}/economics`;

    useEffect(() => {
        getPriceProposals();
    }, []);

    //fetch when filters change
    useEffect(() => {
        setPage(1);
        setLastPage(null);
        getPriceProposals(1);
    }, [filters]);

    //fetch when page change
    useEffect(() => {
        if (!!priceProposals.length && page !== 1) {
            getPriceProposals(page)
        }
    }, [page]);

    const onChangePage = (isFilter = false) => {
        isFilter ? setPage(1) : setPage(page + 1);
    };

    const onDataSorting = (newSort) => {
        const sorters = qs.stringify(newSort, {
            arrayFormat: 'comma', parseBooleans: true, skipEmptyString: true, skipNull: true
        })
        setSorter(sorters);
        setPage(1);
        getPriceProposals(1, sorters,false);
    };

    const onComplexSearch = (search) => {
        const newFilters = {...filters, ...search};
        removeUnsettedKeyInObj(newFilters);
        setFilters(newFilters);
    };

    return (
        <>
            <SubHeading title={"Proposte nuovo prezzo"} type={'small'} subTitleData={`${!!total ? total : 0} risultati`}
                        rightSearchComponent={<SimpleSearch filters={filters} setPage={setPage}
                                                            onComplexSearchCb={onComplexSearch} name={'plate'}
                                                            placeholder={'cerca per targa'}/>}/>

            <section className={classes.gridContainer}>
                {/*{loading && <Spinner />}*/}
                <div className={classes.table}>
                    <Table
                        id={'table'}
                        data={priceProposals}
                        inLoading={loading}
                        filters={filters}
                        onSetPageCb={onChangePage}
                        onSortingDataCb={onDataSorting}
                        theadHeight={40}
                        rowMinHeight={40}
                        classNames={{
                            table: classes.table,
                            thead: classes.thead,
                            row: classes.row,
                        }}
                        columns={['created_at', 'model_name', 'brand_name', 'version', 'plate', 'mileage', 'company_name', 'price', 'proposal']}
                        columnsNames={{
                            created_at: 'Data',
                            brand_name: "Marca",
                            model_name: "Modello",
                            version: "Versione",
                            plate: "Targa",
                            mileage: 'Km',
                            price: "Prezzo attuale",
                            proposal: "Prezzo Proposto",
                            company_name: 'Azienda proprietaria'
                        }}
                        columnsWidths={{
                            created_at: "10%",
                            brand_name: "10%",
                            model_name: "10%",
                            version: "12%",
                            plate: "10%",
                            mileage: '10%',
                            company_name: "12%",
                            price: "12%",
                            proposal: "14%",
                        }}
                        columnsAlignments={{
                            actions: 'right',
                        }}
                        backEndSortable={true}
                        sortable={true}
                        headersSorters={['created_at', 'mileage']}
                        columnsRenderers={{
                            created_at: (value) => <span>{formattedDate(value)}</span>,
                            brand_name: (value) => <strong>{value}</strong>,
                            model_name: (value) => <span>{value}</span>,
                            version: (value) => <span>{value}</span>,
                            plate: (value) => <span style={{textTransform:'uppercase'}}>{value}</span>,
                            mileage: (item, value) => <span>{value?.mileage}</span>,
                            company_name: (item) => <span>{item ? item : '-'}</span>,
                            price: (value) => <strong>{value ? toMoney(value) : '-'}</strong>,
                            proposal: (value) => <strong>{value ? toMoney(value) : '-'}</strong>,
                        }}
                        onRowClickPath={onRowClickPath}
                    />
                </div>
            </section>
        </>);
};

export default PriceProposalList;
