import React from 'react';
import {createUseStyles} from 'react-jss';
import {Link} from 'react-router-dom';
import {ArrowLeft} from "../../assets/icons.js";
import defaultImage from "../../assets/images/car-profile-placeholder.jpg";
import CarCardInfo from "../../Commons/CarCardInfo.js";

const useStyles = createUseStyles((theme) => ({
    baseStyles: {
        position: 'relative',
        display: 'grid',
        gridTemplateRows: '.5fr 1fr',
        boxSizing: 'border-box',
        alignItems: 'center',
        background: theme.colors.white,
        zIndex: 2,
        paddingLeft: 32,
        height: 140,
        '& a': {
            display: 'flex',
            alignItems: 'center',
            color: theme.colors.black,

        },
    },
    carHeadingExternal: {
        top: 88,
        position: "absolute",
        marginLeft: 32,
        minWidth: 460,
    },
}));

const ExternalCarHeading = ({backPath,car}) => {
    const classes = useStyles({});
    const carImage = car?.profilePic;

    return <div className={classes.baseStyles}>
        <div className={classes.goBack}>
            <Link to={backPath}> <ArrowLeft data-color/> Torna all'elenco delle lavorazioni</Link>
        </div>
        <div className={classes.carHeadingExternal}>
            <CarCardInfo image={carImage ? carImage : defaultImage}
                         changeCarStatusCb={Function.prototype} statuses={[]} car={car}/>
        </div>
    </div>
};

export default ExternalCarHeading;
