import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useDispatch} from 'react-redux';
import {useForm} from 'react-hook-form';
import Spinner from "../../Commons/Spinner";
import {httpExaminationsSummary} from "../../HttpRequests/barters.http.js";
import Text from "../../Commons/Text.js";
import Select from "../../Commons/Select.js";
import {errorHandler, motivations, retrieveValueForRs} from "../../helpers/utils.js";
import Textarea from "../../Commons/Textarea.js";
import {setCurrentBarter, setDamages} from "../../store/actions/bartersActions.js";
import Button from "../../Commons/Button.js";
import Popover from "../../Commons/Popover.js";
import ConfirmExamination from "./ConfirmExamination.js";
import * as Yup from "yup";

const useStyles = createUseStyles(theme => ({
    root: {
        position: 'relative',
        'display': 'grid',
        'grid-auto-flow': 'row',
        'grid-row-gap': theme.spacing * 3,
        'grid-column-gap': theme.spacing * 5,
        [theme.s]: {
            width: 320,
            gridTemplateColumns: '1fr',
            justifyContent: "center",
        },
        [theme.mUp]: {
            'width': '100%',
            gridTemplateColumns: '1fr 1fr 1fr',
            justifyContent: "center",
        },
    },

    description:{
        gridColumn: '1 / span 4'
    },
    textarea:{
        gridColumn: '1 / span 4'
    },
    btns: {
        marginTop: theme.spacing * 2,
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: theme.spacing,
        width: 500,
    }
}));

const validationSchema = Yup.object().shape({
    total_amount: Yup.string()
        .nullable()
        .required(`Inserisci l'importo totale.`),
    reason: Yup.string()
        .nullable()
        .required(`Scegli una motivaionze.`)

});

const DamagesFastSummary = ({car}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [showPopover, setShowPopover] = useState(false);
    const {register, watch,handleSubmit,reset,errors,control,formState:{isDirty, isValid}} = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            note: car?.examination?.note,
            total_amount: car?.examination?.total_amount,
            reason: retrieveValueForRs(motivations, car?.examination?.reason ? car?.examination?.reason : null ),
        },
        validationSchema
    });

    useEffect(() => {
        if(watch('reason')?.value && watch('reason')?.value === '' ){

        }
    }, [watch('reason')?.value])

    const onConfirmExaminations = async () => {
        const dataToSend = {
            ...watch(),
            reason: watch('reason').value
        };
        //console.log(dataToSend)
        try {
            setIsLoading(true);
            const {data:{car:barter}} = await httpExaminationsSummary(car.id,dataToSend);
            //set barter and damages
            dispatch(setCurrentBarter(barter));
            dispatch(setDamages(barter?.examination ? barter?.examination.damages : []));
            setShowPopover(!showPopover)
            //todo test
        } catch (e) {
            errorHandler(e,dispatch)
        } finally {
            setIsLoading(false);
        }
    };

    const onAbortFastExamination = async () => {
        const dataToSend = {
            total_amount: null,
            reason:'',
            summary: false
        };
        try {
            setIsLoading(true);
            const {data:{car:barter}} = await httpExaminationsSummary(car.id,dataToSend);
            //set barter and damages
            dispatch(setCurrentBarter(barter));
            dispatch(setDamages(barter?.examination ? barter?.examination.damages : []));
            reset({total_amount: null, reason:'', summary: false})
        } catch (e) {
            errorHandler(e,dispatch)
        } finally {
            setIsLoading(false);
        }
    };

    return <>
        {isLoading && <Spinner overlayFullscreen={true}/>}
        {showPopover && <Popover withHeader={false} width={500} onClose={() => setShowPopover(!showPopover)} contextMode={false}>
            <ConfirmExamination callback={onConfirmExaminations}/>
        </Popover>}

        <form className={classes.root} onSubmit={handleSubmit(() => setShowPopover(!showPopover))}>
            <p className={classes.description}>Compila questa sezione solo se il veicolo è in perfette condizioni, o se presenta danni significativi che
                portano ad una stima indicativa del valore di ripristino. Inserendo questi dati potrai saltare l'inserimento
                dei singoli danni.</p>
            <Text type={'number'} label={'Costi di ripristino stimati'} name={'total_amount'} ref={register} errors={errors.total_amount}/>
            <Select name={'reason'} options={motivations} ref={register} control={control}
                    label={`Motivazione*`} isClearable={false} errors={errors.reason} />
            <Textarea rows={"4"} label={'Note'} name={'note'} ref={register} className={classes.textarea}/>
            <div className={classes.btns}>
                <Button type={'button'} onClick={() => onAbortFastExamination()}> Ripristina </Button>
                <Button type={'submit'} data-primary disabled={!isValid}> Conferma </Button>
            </div>
        </form>
    </>
};

export default DamagesFastSummary;
