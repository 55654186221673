import * as actionTypes from '../actions/settingsActions.js';

const initialState = {
	usersList: [],
	ubicationsList: [],
	businessList: [],
	damagesFlatList: null
};

const settingsReducer = (state = initialState, action) => {
	let myUsersList = state.usersList;
	let myUbicationList = state.ubicationsList;
	let myBusinessList = state.businessList;
	let myDamagesFlatList = state.damagesFlatList;

	switch (action.type) {
		case actionTypes.SET_USERS_LIST:
			return {
				...state,
				usersList: action.usersList
			};
		case actionTypes.EDIT_USER:
			/**
             * we use this action for edit user action
             * or for single user property changes
             * **/
			// console.log(action.user);
			myUsersList = myUsersList.map((item) => (action.user.id === item.id ? { ...action.user } : item));
			// console.log(myUsersList);
			return {
				...state,
				usersList: myUsersList
			};
		case actionTypes.SET_DAMAGES_LIST:
			return {
				...state,
				damagesFlatList: action.damagesFlatList
			};
		case actionTypes.EDIT_SINGLE_DAMAGE:
			myDamagesFlatList[action.damage.key] = action.damage.price;
			return {
				...state,
				damagesFlatList: myDamagesFlatList
			};
		case actionTypes.SET_UBICATIONS_LIST:
			return {
				...state,
				ubicationsList: action.ubicationsList
			};
		case actionTypes.EDIT_UBICATION:
			/**
			 * we use this action for edit ubication action
			 * or for single ubication property changes
			 * **/
			// console.log(action.user);
			myUbicationList = myUbicationList.map(
				(item) => (action.ubication.id === item.id ? { ...action.ubication } : item)
			);
			// console.log(myUsersList);
			return {
				...state,
				ubicationsList: myUbicationList
			};
		case actionTypes.SET_BUSINESS_LIST:
			return {
				...state,
				businessList: action.businessList
			};
		case actionTypes.EDIT_BUSINESS:
			/**
			 * we use this action for edit business action
			 * or for single business property changes
			 * **/
			myBusinessList = myBusinessList.map(
				(item) => (action.business.id === item.id ? { ...action.business } : item)
			);
			return {
				...state,
				myBusinessList: myBusinessList
			};
		default:
			return state;
	}
};

export default settingsReducer;
