import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {StatsIcon} from "../../assets/icons.js";

const useStyles = createUseStyles(theme => ({
    root: {
        position: 'relative',
        display: 'grid',
        padding: theme.spacing * 2,
    },
    heading: {
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        alignItems: "center",
        gridColumnGap: theme.spacing,
        paddingBottom: theme.spacing * 2,
        '& svg': {
            fill: theme.colors.black
        },
        '& b': {
            fontSize: 14
        }
    },
    item: {
        minHeight: 40,
        display: "grid",
        alignItems: "center",
        gridTemplateColumns: "1fr 1fr 1fr auto",
        gridColumnGap: "16px",
        borderTop: `1px solid ${theme.colors.lightGrey}`,
        paddingBottom: [theme.spacing , 0],
        '& strong':{
            fontSize: 14
        }
    }
}));


const StockPerformance = ({stockPerformancesData}) => {
    const classes = useStyles();

    return <div className={classes.root}>
        <div className={classes.heading}>
            <StatsIcon data-color/>
            <b>Performace di stock</b>
        </div>
        <div className={classes.item}>
            <strong style={{textTransform: 'uppercase'}}>Stock 1</strong>
            <small>Data inserimento in stock</small>
            <small>Data esposizione</small>
            <strong>{stockPerformancesData?.stock1}</strong>
        </div>
        <div className={classes.item}>
            <strong style={{textTransform: 'uppercase'}}>Stock 2</strong>
            <small>Data di esposizione</small>
            <small>Data di consolidamento di contratto</small>
            <strong>{stockPerformancesData?.stock1}</strong>
        </div>
        <div className={classes.item}>
            <strong style={{textTransform: 'uppercase'}}> Stock 3</strong>
            <small>Data di consolidamento di contratto</small>
            <small>Data consegna cliente</small>
            <strong>{stockPerformancesData?.stock3}</strong>
        </div>
    </div>
};

export default StockPerformance;
