import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useForm} from 'react-hook-form';
import Text from "../../Commons/Text";
import Textarea from "../../Commons/Textarea";
import Card from "../../Commons/Card";
import DayPickerInput from "../../Commons/DayPickerInput.js";
import Select from "../../Commons/Select.js";
import Spinner from '../../Commons/Spinner';
import Radio from '../../Commons/Radio';
import Checkbox from '../../Commons/Checkbox';
import {useDispatch, useSelector} from "react-redux";
import {setCurrentCar} from "../../store/actions/carsActions";
import {alertToggle} from "../../store/actions/alertActions.js";
import {httpEditCarInfo} from "../../HttpRequests/barters.http.js";
import {
    acquisitionChannels,
    carStatus,
    categories,
    errorHandler,
    gearBoxTypes,
    retrieveAccessoriesForRhf,
    retrieveValueForRs,
    salesChannels,
    simplifiedCarColors,
    simplifiedInteriors
} from "../../helpers/utils.js";
import Button from "../../Commons/Button.js";
import {CarIcon} from "../../assets/icons.js";
import eurotaxLogo from "../../assets/images/eurotax.jpg";
import SelectedVersion from "../Barter/SelectedVersion.js";

const useStyles = createUseStyles(theme => ({
    root: {
        position: 'relative',
        'display': 'grid',
        'grid-auto-flow': 'row',
        'grid-row-gap': theme.spacing * 3,
        'grid-column-gap': theme.spacing * 5,
        [theme.s]: {
            width: 'auto',
            gridTemplateColumns: '1fr',
            justifyContent: "center",
        },
        [theme.mUp]: {
            'width': '100%',
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            justifyContent: "center",
        },
    },
    cardRoot: {
        margin: [theme.spacing * 2, 0]
    },

    subSection: {
        display: 'grid',
        gridTemplateColumns: '1fr 0.3fr',
        gridColumnGap: theme.spacing * 5,
        gridRowGap: theme.spacing,
        alignItems: 'start',
    },
    subSectionTwo: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: theme.spacing * 5,
        gridRowGap: theme.spacing,
        alignItems: 'start',
    },
    subSectionFour:{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gridColumnGap: theme.spacing * 5,
        alignItems: 'start',
    },
    sectionTitle: {
        fontWeight: 600,
        textAlign: "left",
        gridColumnStart: 1,
        gridColumnEnd: 5,
        fontSize: 16,
        margin: [theme.spacing * 2, 0, theme.spacing * 4, 0],
        [theme.s]: {
            gridColumnEnd: 1,
        },
        [theme.mUp]: {
            gridColumnStart: 1,
            gridColumnEnd: 5,
        },
    },

    sectionDivider: {
        gridColumnStart: 1,
        gridColumnEnd: 4,
        fontSize: 16,
        margin: [theme.spacing * 2, 0],
        borderTop: `1px solid ${theme.colors.lightGrey}`
    },
    sectionRadio: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '1fr 1fr',
        gridColumnGap: theme.spacing,
        gridRowGap: theme.spacing,
        maxWidth: 300,
        '& p': {
            ...theme.control.label,
            gridColumn: '1 / 5'
        }
    },
    service: {
        display: "grid",
        width: "100%",
        gridColumnGap: theme.spacing * 4,
        alignItems: "center",
        padding: [0, theme.spacing],
        [theme.mUp]: {
            gridTemplateColumns: "200px 1fr auto auto",
        },
        [theme.s]: {
            gridTemplateColumns: "1fr",
            gridRowGap: theme.spacing,
        },
    },
}));

const CarInfoForm = ({car, canEditField, showAlertCb, isAdmin, accessories}) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const usersAutocomplete = useSelector(state => state.autocompletes.usersAutocomplete);
    const companiesAutocomplete = useSelector(state => state.autocompletes.companiesAutocomplete);
    const familiesAutocomplete = useSelector(state => state.autocompletes.families);
    const structuresAutocomplete = useSelector(state => state.autocompletes.structures);
    const fuelMethodAutocomplete = useSelector(state => state.autocompletes.fuelMethods);
    const userOperations = useSelector(state => state.users.operations);

    const {register, watch, control, setValue} = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            ...car,
            brand: car?.model?.brand.name,
            model: car?.model?.name,
            matriculation: car?.matriculation ? new Date(car.matriculation) : null,
            latest_revision: car?.latest_revision ? new Date(car.latest_revision) : null,
            gearbox: retrieveValueForRs(gearBoxTypes, car?.gearbox),
            sales_channel: retrieveValueForRs(salesChannels, car?.sales_channel),
            type: retrieveValueForRs(carStatus, car?.type),
            administrative_distraint: (!!car?.administrative_distraint).toString(),
            simplified_color: retrieveValueForRs(simplifiedCarColors, car?.simplified_color),
            withdrawal_user_id: retrieveValueForRs(usersAutocomplete, car?.withdrawal_user_id),
            owning_company_id: retrieveValueForRs(companiesAutocomplete, car?.owning_company_id),
            simplified_interiors: retrieveValueForRs(simplifiedInteriors, car?.simplified_interiors),
            acquisition_channel: retrieveValueForRs(acquisitionChannels, car?.acquisition_channel),
            category: retrieveValueForRs(categories, car?.category),
            sd_card: car?.sd_card !== null ? (!!car?.sd_card).toString() : null,
            second_key:  car?.second_key !== null ? (!!car?.second_key).toString() : null,
            neolicenced: car?.neolicenced ? 'Si' : 'No',
            ...retrieveAccessoriesForRhf(car?.accessories)
        },
    });

    const canShowBoxField = ((watch('sd_card') === 'true') || (watch('second_key') === 'true'));

    useEffect(() => {
        if (car) {
            setValue('family', retrieveValueForRs(familiesAutocomplete, car?.family));
            setValue('withdrawal_user_id', retrieveValueForRs(usersAutocomplete, car?.withdrawal_user_id));
            setValue('owning_company_id', retrieveValueForRs(companiesAutocomplete, car?.owning_company_id));
            setValue('structure', retrieveValueForRs(structuresAutocomplete, car?.structure));
            setValue('fuel_method', retrieveValueForRs(fuelMethodAutocomplete, car?.fuel_method));
        }
    }, [familiesAutocomplete, usersAutocomplete, companiesAutocomplete, structuresAutocomplete, fuelMethodAutocomplete])

    const editCarInfo = async (fieldName, value, label, isAccessory = false) => {
        if (!isAccessory && (!value || car[fieldName]?.toString() === value.toString())) return;

        let dataToSend = null;
        if (isAccessory) {
            dataToSend = {
                car: {id: car.id, is_draft: true},
                accessories: {...retrieveAccessoriesForRhf(accessories), [fieldName]: value}
            };
        } else {
            dataToSend = {car: {id: car.id, [fieldName]: value}};
        }
        try {
            const {data} = await httpEditCarInfo(dataToSend);
            dispatch(setCurrentCar(data));
            if (isAccessory) {
                dispatch(alertToggle(`${label} ${value ? 'aggiunto agli' : 'rimosso dagli'} accessori.`, 'success'))
            } else {
                dispatch(alertToggle(`Il campo ${label} è stato modificato correttamente.`, 'success'))
            }
        } catch (e) {
            errorHandler(e, dispatch)
        }
    };

    const Action = (userOperations.admin || userOperations.can_edit_car) &&
        <Button data-small data-secondary onClick={showAlertCb}> Modifica </Button>;

    return (
        <>
            {loading && <Spinner/>}
            <Card cardIcon={<CarIcon data-color/>} rootCustomClass={classes.cardRoot} cardTitle={'Dati generali'}
                  cardSpacing={3}
                  actions={canEditField && !isAdmin ? null : Action}>
                <form>
                    <h4 className={classes.sectionTitle}> Dati generali </h4>
                    <div className={classes.root}>
                        <DayPickerInput label={'Data immatricolazione*'} name={'matriculation'} inForm={true}
                                        ref={register}
                                        control={control} closeOnSelect={true} disabled={true} callback={editCarInfo}/>
                        <Select name={'type'} options={carStatus} ref={register} control={control}
                                disabled={!canEditField}
                                label={`Stato vettura all'acquisto`} isClearable={false} onChange={(value) => {
                            editCarInfo('type', value[0].value, `Stato vettura all'acquisto`);
                            return value
                        }}/>

                        <Select name={'family'} options={familiesAutocomplete} ref={register} control={control}
                                disabled={!canEditField}
                                label={`Famiglia*`} isClearable={false} onChange={(value) => {
                            editCarInfo('family', value[0].value, `Famiglia`);
                            return value
                        }}/>
                        <Select name={'category'} options={categories} ref={register} control={control}
                                disabled={!canEditField}
                                label={`Categoria`} isClearable={false} onChange={(value) => {
                            editCarInfo('category', value[0].value, `Categoria`);
                            return value
                        }}/>
                        <Text type={'text'} label={'Telaio'} name={'frame'} ref={register} disabled={true}
                              onBlur={() => editCarInfo('frame', watch('frame'), 'Telaio')}/>
                        <Select name={'structure'} options={structuresAutocomplete} ref={register} control={control}
                                disabled={!canEditField}
                                label={`Carrozzeria*`} isClearable={false} onChange={(value) => {
                            editCarInfo('structure', value[0].value, `Carrozzeria`);
                            return value
                        }}/>
                        <Select name={'gearbox'} options={gearBoxTypes} ref={register} control={control}
                                disabled={!canEditField}
                                label={`Cambio*`} isClearable={false}
                                onBlur={() => editCarInfo('gearbox', watch('gearbox'), 'Cambio')}/>
                        <Text type={'text'} label={'Giacenza'} name={'total_in_stock'} ref={register} disabled={true}
                              onBlur={() => editCarInfo('total_in_stock', watch('total_in_stock'), 'Giacenza')}/>


                        <Text type={'text'} label={'Targa'} name={'plate'} ref={register} disabled={!canEditField}
                              onBlur={() => editCarInfo('plate', watch('plate'), 'Targa')}/>
                        <Text type={'text'} label={'Colore esterno'} name={'color'} ref={register}
                              disabled={!canEditField}
                              onBlur={() => editCarInfo('color', watch('color'), 'Colore esterno')}/>
                        <Select name={'simplified_color'} options={simplifiedCarColors} ref={register} control={control}
                                disabled={!canEditField}
                                label={`Colore esterno ( semplificato )`} isClearable={false} onChange={(value) => {
                            editCarInfo('simplified_color', value[0].value, `Colore interno ( semplificato )`);
                            return value
                        }}/>
                        <Text type={'text'} label={'Emissioni'} name={'emissions'} ref={register}
                              disabled={!canEditField}
                              onBlur={() => editCarInfo('emissions', watch('emissions'), 'Emissioni')}/>

                        <Text type={'text'} label={`Tipo interni`} name={'interiors_type'} ref={register}
                              disabled={true}
                              onBlur={() => editCarInfo('interiors_type', watch('interiors_type'), 'Tipo interni')}/>
                        <Select name={'simplified_interiors'} options={simplifiedInteriors} ref={register}
                                control={control}
                                disabled={!canEditField}
                                label={`Tipo Interni ( semplificato )`} isClearable={false} onChange={(value) => {
                            editCarInfo('simplified_interiors', value[0].value, `Tipo Interni ( semplificato )`);
                            return value
                        }}/>

                        <Text type={'text'} label={`Tappezzeria`} name={'upholstery'} ref={register} disabled={true}
                              onBlur={() => editCarInfo('upholstery', watch('upholstery'), 'Tappezzeria')}/>

                        <Text type={'text'} label={'Marca*'} name={'brand'} ref={register} disabled={true}
                              onBlur={() => editCarInfo('brand', watch('brand'), 'Marca')}/>
                        <Text type={'text'} label={'Colore interno'} name={'color_interiors'} ref={register}
                              onBlur={() => editCarInfo('color_interiors', watch('color_interiors'), 'Colore interno')}
                              disabled={!canEditField}/>
                        <Text type={'text'} label={'Classe Emissioni*'} name={'euro'} ref={register}
                              disabled={!canEditField}
                              onBlur={() => editCarInfo('euro', watch('euro'), 'Classe Emissioni')}/>

                        <Text type={'text'} label={'Modello*'} name={'model'} ref={register} disabled={true}
                              onBlur={() => editCarInfo('model', watch('model'), 'Modello')}/>
                        <Text type={'text'} label={'Km percorsi*'} name={'mileage'} ref={register}
                              disabled={!canEditField}
                              onBlur={() => editCarInfo('mileage', watch('mileage'), 'Km percorsi')}/>
                        <DayPickerInput label={'Scadenza revisione*'} name={'latest_revision'} inForm={true}
                                        ref={register}
                                        callback={editCarInfo} control={control} closeOnSelect={true}
                                        disabled={!canEditField}/>

                        <Text type={'text'} label={'Versione*'} name={'version'} ref={register}
                              disabled={!canEditField}
                              onBlur={() => editCarInfo('version', watch('version'), 'Allestimento')}/>
                        <Select name={'fuel_method'} options={fuelMethodAutocomplete} ref={register} control={control}
                                disabled={!canEditField}
                                label={`Alimentazione*`} isClearable={false} onChange={(value) => {
                            editCarInfo('fuel_method', value[0].value, `Alimentazione`);
                            return value
                        }}/>
                        <Select name={'sales_channel'} options={salesChannels} ref={register} control={control}
                                label={`Canale di vendita*`} isClearable={false} disabled={!canEditField}
                                onChange={(value) => {
                                    editCarInfo('sales_channel', value[0].value, `Canale di vendita`);
                                }}/>
                        <Select name={'acquisition_channel'} options={acquisitionChannels} ref={register}
                                control={control}
                                disabled={!canEditField}
                                label={`Canale di acquisizione`} isClearable={false} onChange={(value) => {
                            editCarInfo('acquisition_channel', value[0].value, `Canale di acquisizione`);
                            return value
                        }}/>

                        <Select name={'withdrawal_user_id'} options={usersAutocomplete} ref={register} control={control}
                                label={`Funzionario di ritiro*`} isClearable={false} disabled={!canEditField}
                                onChange={(value) => {
                                    editCarInfo('withdrawal_user_id', value[0].value, `Funzionario di ritiro`);
                                }}/>
                        <Select name={'owning_company_id'} options={companiesAutocomplete} ref={register}
                                control={control}
                                label={`Azienda proprietaria*`} isClearable={false} disabled={!canEditField}
                                onChange={(value) => {
                                    editCarInfo('owning_company_id', value[0].value, `Azienda proprietaria`);
                                }}/>
                        <div className={classes.sectionRadio}>
                            <p className={classes.title}>Fermo amministrativo</p>
                            <Radio label={'Si'} name={'administrative_distraint'} value={'true'} ref={register}
                                   disabled={!canEditField}
                                   onChange={() => editCarInfo('administrative_distraint', watch('administrative_distraint'), 'Fermo amministrativo')}/>
                            <Radio label={'No'} name={'administrative_distraint'} value={'false'} ref={register}
                                   disabled={!canEditField}
                                   onChange={() => editCarInfo('administrative_distraint', watch('administrative_distraint'), 'Fermo amministrativo')}/>
                        </div>
                    </div>
                    <div className={classes.subSectionTwo} style={{marginTop: 24}}>
                        <Textarea label={'Note per il cambio azienda proprietaria'} name={'note'} ref={register}
                                  disabled={!canEditField} rows={'4'}
                                  onBlur={() => editCarInfo('note', watch('note'), 'Note per il cambio azienda proprietaria')}/>
                        <Textarea label={'Note generali'} name={'note'} ref={register} disabled={!canEditField}
                                  rows={'4'}
                                  onBlur={() => editCarInfo('note', watch('note'), 'Note generali')}/>
                    </div>
                    <div className={classes.subSection} style={{marginTop: 24}}>
                        <div className={classes.service}>
                            <img src={eurotaxLogo} alt={'eurotax logo'}/>
                            <SelectedVersion brand={car.model?.brand.name} model={car.eurotax_label}
                                             productionDate={null} isCarInfo={true} isRecap={false}
                                             doors={car.doors} service={'eurotax'} id={car.id}
                                             version={car.eurotax_version}/>
                        </div>
                        <div className={classes.sectionDivider}/>
                    </div>

                    <div className={classes.subSectionFour}>
                        <div className={classes.sectionRadio}>
                            <p className={classes.title}>Doppia chiave</p>
                            <Radio label={'Si'} name={'second_key'} value={'true'} ref={register}
                                   disabled={!canEditField}
                                   onChange={() => editCarInfo('second_key', watch('second_key'), 'Doppia chiave')}/>
                            <Radio label={'No'} name={'second_key'} value={'false'} ref={register}
                                   disabled={!canEditField}
                                   onChange={() => editCarInfo('second_key', watch('second_key'), 'Doppia chiave')}/>
                        </div>
                        <div className={classes.sectionRadio}>
                            <p className={classes.title}>Scheda sd</p>
                            <Radio label={'Si'} name={'sd_card'} value={'true'} ref={register}
                                   disabled={!canEditField}
                                   onChange={() => editCarInfo('sd_card', watch('sd_card'), 'Scheda sd')}/>
                            <Radio label={'No'} name={'sd_card'} value={'false'} ref={register}
                                   disabled={!canEditField}
                                   onChange={() => editCarInfo('sd_card', watch('sd_card'), 'Scheda sd')}/>
                        </div>
                        {canShowBoxField && <Text type={'text'} label={'Numero box'} name={'box_number'} ref={register}
                              disabled={!canEditField} onBlur={() => editCarInfo('box_number', watch('box_number'), 'Numero box')}/>}
                        <div className={classes.sectionDivider}/>
                    </div>


                    <div className={classes.root}>

                        <Text type={'text'} label={'Peso (massa a vuoto)'} name={'weight'} ref={register}
                              disabled={!canEditField}
                              onBlur={() => editCarInfo('weight', watch('weight'), 'Peso (massa a vuoto)')}/>

                        <Text type={'text'} label={'Rapporto potenza peso'}
                              value={!!car?.weight ? (car?.engine_power_kw / ((car?.weight) * 0.001).toFixed(2)) : 0}
                              disabled={true}/>

                        <Text type={'text'} label={'Posti'} name={'seats'} ref={register} disabled={!canEditField}
                              onBlur={() => editCarInfo('seats', watch('seats'), 'Posti')}/>

                        <Text type={'text'} label={'KW'} name={'engine_power_kw'} ref={register}
                              disabled={!canEditField}
                              onBlur={() => editCarInfo('engine_power_kw', watch('engine_power_kw'), 'KW')}/>
                        <Text type={'text'} label={'Neopatentati'} name={'neolicenced'} ref={register}
                              disabled={true}
                              onBlur={() => editCarInfo('neolicenced', watch('neolicenced'), 'Neopatentati')}/>
                        <Text type={'text'} label={'Porte'} name={'doors'} ref={register} disabled={!canEditField}
                              onBlur={() => editCarInfo('doors', watch('doors'), 'Porte')}/>

                        <Text type={'text'} label={'CV'} name={'horsepower'} ref={register}
                              disabled={!canEditField}
                              onBlur={() => editCarInfo('horsepower', watch('horsepower'), 'CV')}/>
                        <Text type={'number'} label={'Peso*'} name={'weight'} ref={register} disabled={!canEditField}
                              onBlur={() => editCarInfo('weight', watch('weight'), 'Peso')}/>
                        <Text type={'text'} label={'Cilindrata'} name={'cylinder_capacity'} ref={register}
                              disabled={!canEditField}
                              onBlur={() => editCarInfo('cylinder_capacity', watch('cylinder_capacity'), 'Cilindrata')}/>
                        <Text type={'text'} label={'Trazione'} name={'traction'} ref={register} disabled={!canEditField}
                              onBlur={() => editCarInfo('traction', watch('traction'), 'Trazione')}/>

                        <Text type={'text'} label={'Dimensione pneumatici'} name={'tire_size'} ref={register}
                              disabled={!canEditField}
                              onBlur={() => editCarInfo('tire_size', watch('tire_size'), 'Dimensione pneumatici')}/>
                        <Text type={'text'} label={'Dimensione cerchi'} name={'rim_size'} ref={register}
                              disabled={!canEditField}
                              onBlur={() => editCarInfo('rim_size', watch('rim_size'), 'Dimensione cerchi')}/>
                    </div>

                    <div className={classes.sectionDivider}/>
                    <h4 className={classes.sectionTitle}> Accessori </h4>
                    <div className={classes.root}>
                        {accessories && accessories.map((accessory, index) => {
                            return <Checkbox key={index} label={accessory.label} isSmall={true} name={accessory.label}
                                             disabled={!canEditField} ref={register}
                                             onChange={() => editCarInfo(accessory.label, watch(accessory.label), accessory.label, true)}/>
                        })}
                    </div>
                </form>
            </Card>
        </>
    )
        ;
}

export default CarInfoForm
