import React, {useState, useMemo} from 'react';
import Popover from "../../Commons/Popover";
import Textarea from "../../Commons/Textarea";
import {createUseStyles, useTheme} from "react-jss";
import Button from "../../Commons/Button";
import Text from "../../Commons/Text";
import Select from "react-select";
import UsersMultiselectDropdown from "./UsersDropdownMultiselect/UsersDropdownMultiselect";
import done from "../../assets/images/done.svg";
import {useDispatch, useSelector} from "react-redux";
import {httpStoreCommunication} from "../../HttpRequests/commons.http";
import envelope from "../../assets/images/envelope.svg";
import {errorHandler} from "../../helpers/utils";
import {getLoggedInUserId} from "../../store/reducers/user";
import {alertToggle} from "../../store/actions/alertActions";

const useStyles = createUseStyles(theme => ({
    root: {
        width: 786,
        borderRadius: 28
    },
    body: {
        padding: [0, 40, 40, 40]
    },
    searchUser: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: 16,
        margin: [60, 0, 32, 0]
    },
    searchBarInput: {

    },
    textarea: {
        margin: [16, 0, 32, 0],
        color: '#5B5B5B',
        borderRadius: 4,
        fontSize: 16,
        padding: [8, 12]
    },
    communicationText: {

    },
    title: {
        fontSize: 20,
        color: '#000',
        fontWeight: 600
    },
    subtitle: {
        extend: 'title',
        fontSize: 18
    },
    message: {
        color: '#626262'
    },
    successModal: {
        display: 'grid',
        width: '100%',
        gridRowGap: theme.spacing * 3,
        justifyItems: 'center'
    },
    successTitle:{
        fontWeight: 600,
        margin: [theme.spacing * 2, 0],
        display: 'grid',
        alignItems: 'center',
        gridColumnGap: theme.spacing,
        gridTemplateColumns: '1fr auto',
        textAlign: 'center',
        '& img':{
            marginBottom: theme.spacing,
            width: 72
        }
    },
    alert: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: "center",
        padding: [80, 80, 40, 80],
    },
    alertMessage: {
        textAlign: 'center',
        padding: [8, 0, 48, 0],
        fontSize: 14,
        whiteSpace: 'pre-wrap',
        '& > div': {
            fontSize: 16,
            fontWeight: 700
        },
        '& > span': {
            fontWeight: 700
        }
    },
    alertTitle: {
        padding: [40, 16, 8, 16],
        fontSize: 24,
        textAlign: 'center'
    },
    alertCta: {
        display: "grid",
        gridColumnGap: 8,
        gridTemplateColumns: '1fr 1fr',
        '& > button': {
            minWidth: 212,
        }
    }
}));

const NewCommunication = ({onCloseCb}) => {

    const dispatch = useDispatch();
    const userId = useSelector(getLoggedInUserId);
    const users = useSelector(state => state.autocompletes.usersAutocomplete);
    const companies = useSelector(state => state.autocompletes.companiesAutocomplete);
    const [searchForUser, setSearchForUser] = useState(null);
    const [searchForCompany, setSearchForCompany] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [message, setMessage] = useState(null);
    const [isSending, setIsSending] = useState(false);
    const [currentStatus, setCurrentStatus] = useState('default');
    const classes = useStyles();
    const theme = useTheme();


    const sendCommunication = async () => {
        const usersIds = selectedUsers.map(u => u.value)
        setIsSending(true);
        try {
            const data = await httpStoreCommunication({
                message,
                sender_id: userId,
                target_ids: usersIds
            });
            setCurrentStatus('sent')
        }catch(e){
            errorHandler(e,dispatch)
        }finally {
            setIsSending(false);
        }
    }

    const handleSend = () => {
        if(!message){
            dispatch(alertToggle(`Il messaggio della comunicazione non può essere vuoto.`, 'error'))
            return;
        }else if (!selectedUsers.length){
            dispatch(alertToggle(`Seleziona almeno un destinatario della comunicazione.`, 'error'))
            return;
        }
        setCurrentStatus('awaitingConfirmation')
    }

    const filteredUsers = useMemo(() => {
        return users
            .filter(user => searchForUser ? user.label
                .toLowerCase()
                .includes(searchForUser.toLowerCase()) : true
            )
            .filter(user => searchForCompany ? user.company_id === searchForCompany : true)
    }, [searchForUser, searchForCompany, users])


    const renderCorrectContent = () => {
        switch (currentStatus){
            case 'awaitingConfirmation':
                return <div className={classes.alert}>
                    <img src={envelope} alt={'envelope'}/>
                    <h3 className={classes.alertTitle}>Conferma l’invio del messaggio</h3>
                    <p className={classes.alertMessage}>
                        Stai per inviare questa comunicazione a <span>{selectedUsers.length} utenti</span> selezionati.<br/>
                        Una volta inviato, il messaggio non potrà più essere eliminato.
                    </p>
                    <div className={classes.alertCta}>
                        <Button data-secondary disabled={isSending} onClick={() => setCurrentStatus('default')}>Annulla</Button>
                        <Button data-primary disabled={isSending} onClick={sendCommunication} >Conferma</Button>
                    </div>
                </div>
            case 'sent':
                return <div className={classes.successModal}>
                    <img src={done} alt={'done'}/>
                    <h4 className={classes.successTitle}>Comunciazione inviata correttamente</h4>
                    <Button type={'button'} data-primary data-small onClick={onCloseCb}> Chiudi </Button>
                </div>
            default:
                return <>
                    <div className={classes.title}>Nuova comunicazione</div>
                    <div className={classes.message}>Seleziona gli utenti a cui vuoi inviare la stesso messaggio</div>
                    <div className={classes.searchUser}>
                        <Text
                            inputClassName={classes.searchBarInput}
                            type="text"
                            placeholder={'Cerca utente...'}
                            value={searchForUser}
                            onChange={event => setSearchForUser(event.target.value)}
                        />
                        <Select options={companies}
                                placeholder={'Azienda'}
                                styles={theme.reactSelectsStyle}
                                isClearable={true}
                                isSearchable={true}
                                onChange={(item) => setSearchForCompany(item?.value)}
                                value={companies.filter(obj => {
                                    return obj.value === searchForCompany
                                })}
                        />
                    </div>
                    <UsersMultiselectDropdown users={filteredUsers}
                                              selectedUsers={selectedUsers}
                                              selectionCb={setSelectedUsers}
                    />
                    <div className={classes.communicationText}>
                        <span className={classes.subtitle}>Messaggio</span>
                        <Textarea rows={"5"}
                                  textAreaClassName={classes.textarea}
                                  onChange={(e)=> setMessage(e.target.value)}
                        >
                            {message}
                        </Textarea>
                        <Button data-primary width={'100%'} onClick={handleSend}>Invia</Button>
                    </div>
                </>
        }
    }

    return <Popover onClose={onCloseCb}
                    className={classes.root}
                    bodyClassName={classes.body}
    >
        {renderCorrectContent()}
    </Popover>
}


export default NewCommunication;