import { httpLogoutUser, httpGetUserMe } from '../../HttpRequests/user.http';
import {errorHandler} from "../../helpers/utils.js";

export const SET_LOGGED_USER = 'SET_LOGGED_USER';
export const LOGOUT = 'LOGOUT';
export const UPDATE_USER = 'UPDATE_USER';
export const RESET_APP = 'RESET_APP';

export const setLoggedUser = ({user,access_token,refresh_token}) => {
    return {
        type: SET_LOGGED_USER,
        user: user,
        access_token: access_token,
        refresh_token: refresh_token
    };
};

/* ME API success */
export const meSuccess = (user) => {
	return {
		type: UPDATE_USER,
		user: user
	};
};

// ME API async code pattern
export const me = () => {
	return async (dispatch) => {
		try {
			const { data: user } = await httpGetUserMe();
			dispatch(meSuccess(user));
		} catch (e) {
			//todo: handle error
		}
	};
};

/* LOGOUT async code pattern */
export const logoutSuccess = () => {
	return {
		type: LOGOUT
	};
};

export const resetAppData = () => {
	return {
		type: RESET_APP
	};
};

export const logout = () => {
	return async (dispatch) => {
		try {
			await httpLogoutUser();
			dispatch(logoutSuccess());
			dispatch(resetAppData());
		} catch (e) {
			errorHandler(e,dispatch)
		}
	};
};
