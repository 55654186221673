import React, {useState} from 'react';
import {createUseStyles} from "react-jss";
import Button from "../../Commons/Button.js";
import alert from '../../assets/images/alertTriangle.svg'
import {httpDeleteShift} from "../../HttpRequests/shifts.http.js";
import {alertToggle} from "../../store/actions/alertActions.js";
import {errorHandler} from "../../helpers/utils.js";
import {useDispatch, useSelector} from "react-redux";
import Spinner from "../../Commons/Spinner.js";
import {setShiftsList} from "../../store/actions/shiftsActions.js";

const useStyles = createUseStyles(theme => ({
    root: {
        display: 'grid',
        width: '100%',
        gridRowGap: theme.spacing * 3,
        justifyItems: 'center'
    },
    results: {
        fontSize: 16,
        marginTop: theme.spacing * 4,
        textAlign: 'center'
    },
}));

const DeleteShiftModal = ({onClose, id}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const shifts = useSelector(state => state.shifts.shiftsList);
    const [loading, setLoading] = useState(false);

    const onRemoveShift = async () => {
        setLoading(true);
        try {
            await httpDeleteShift(id);
            dispatch(setShiftsList(shifts.filter(shifts => +shifts.id !== +id)));
            dispatch(alertToggle('Spostamento eliminato con successo.', 'success'));
            onClose();
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    }

    return <>
        {loading && <Spinner overlayFullscreen={true}/>}
        <div className={classes.root}>
            <img src={alert} alt={'alert'}/>
            <p className={classes.results}> Cosi facendo eliminerai lo spostamento e tutti i documenti ad esso correlati.
                Sei sicuro di voler procedere? </p>
            <Button type={'button'} data-primary data-small onClick={onRemoveShift}> Conferma </Button>
        </div>
    </>
};

export default DeleteShiftModal;