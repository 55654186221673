//import invariant from "invariant";

export const MESSAGE_TYPE = {
    text: 'text',
    image: 'photo',
    video: 'video',
    document: 'document'
}


export const MESSAGE_FRAGMENT = {
    sender: {
        id: "PUBNUB-BOT",
        name: "PubNub Bot"
    },
    timetoken: "15735897955841496",
    message: {
        type: MESSAGE_TYPE.text,
        senderId: "PUBNUB-BOT",
        text:
            "Welcome to Team Chat. 👋👋 Send a message now to start interacting with other users in the app. ⬇️"
    }
};

export const MESSAGE_PROPS = {
    messageFragment: MESSAGE_FRAGMENT,
    avatar: null // ReactNode
}

/**
 * Test if a draft message been modified (used to ignore unmodified drafts).
 */
export const isDraftModified = (message) => {
    switch (message.type) {
        case MESSAGE_TYPE.text:
            return message.text !== "";
        default:
            return false;
        /*default:
            invariant(
                false,
                `Cannot determin if message of type "${message.type}" has been modified.`
            );*/
    }
};