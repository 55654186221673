import React from 'react';
import {createUseStyles} from 'react-jss';
import {toMoney} from "../../helpers/utils.js";

const useStyles = createUseStyles(theme => ({
    root: {
        display: "grid",
        height: '100%',
        gridGap: theme.spacing * 2,
        gridTemplateColumns: `repeat(3, 1fr)`,
    },
    mini: {
        background: theme.colors.white,
        borderRadius: "12px",
        padding: [theme.spacing * 2, theme.spacing],
        boxSizing: "border-box",
        display: "grid",
        alignItems: "center",
    },
    content: {
        display: "grid",
        alignItems: "center",
        justifyItems: 'center',
        '& strong': {
            fontSize: 20,
            '& span': {
                fontSize: 12,
            }
        },
        '& small': {
            fontSize: 12
        }
    },
    main: {
        background: theme.colors.white,
        borderRadius: 12,
        padding: theme.spacing * 2,
        gridRow: "2 / span 3",
        gridColumn: "1 / span 3",
        alignItems: 'end',
        display: 'grid'
    },
    heading:{
        display: 'grid',
        borderBottom: `1px solid ${theme.colors.lightGrey}`,
        paddingBottom: theme.spacing,
        justifyItems: 'center'
    },
    singleData:{
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        marginTop: theme.spacing,
        fontSize: 14
    }
}));

const StockInfosStats = ({tasksStats, stockData}) => {
    const classes = useStyles();

    return <div className={classes.root}>
        <div className={classes.mini}>
            <div className={classes.content}>
                <strong>{stockData?.average_days_in_stock}<span>gg</span></strong>
                <small>Giacenza media</small>
            </div>
        </div>
        <div className={classes.mini}>
            <div className={classes.content}>
                <strong>{stockData?.average_rotation}<span>gg</span></strong>
                <small>Indice di rotazione</small>
            </div>
        </div>
        <div className={classes.mini}>
            <div className={classes.content}>
                <strong>{toMoney(stockData?.average_price)}</strong>
                <small>Prezzo medio</small>
            </div>
        </div>
        <div className={classes.main}>
            <div className={classes.heading}>
                <h4><b>{tasksStats?.in_stock}</b></h4>
                <small>Auto presenti in stock</small>
            </div>
            <div className={classes.singleData}>
                <span>In ripristino</span>
                <strong>{tasksStats?.reconditioning}</strong>
            </div>
            <div className={classes.singleData}>
                <span>In lavaggio</span>
                <strong>{tasksStats?.wash}</strong>
            </div>
            <div className={classes.singleData}>
                <span>In shooting</span>
                <strong>{tasksStats?.photo}</strong>
            </div>
            <div className={classes.singleData}>
                <span>In spostamento</span>
                <strong>{tasksStats?.transportations}</strong>
            </div>
            <div className={classes.singleData} style={{color: 'green'}}>
                <span>Sul piazzale</span>
                <strong>{tasksStats?.on_location}</strong>
            </div>
        </div>
    </div>
};

export default StockInfosStats;
