import React, {useEffect} from 'react'
import {createUseStyles} from "react-jss";
import Menu from "./Menu/Menu";
import CurrentConversation from "./CurrentConversation/CurrentConversation";
import ConversationMembers from "./ConversationMembers/ConversationMembers";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentConversationId} from "../../store/reducers/chat/currentConversation";
import Spinner from "../../Commons/Spinner";
import {setChatOpened} from "../../store/actions/chat/statusActions";
import {ChatIcon, CloseIcon} from "../../assets/icons";
import emptyChatPlaceholder from '../../assets/images/emptyChatPlaceholder.png'


const useStyles = createUseStyles( theme => ({
    body: {
        display: 'flex',
        height: `calc(100vh - 48px)`, // full-page without header,
        backgroundColor: '#fff'
    },
    header: {
        backgroundColor: '#353535',
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        gridTemplateRows: '24px',
        alignItems: 'center',
        padding: [12, 24, 12, 32]
    },
    title:{
        height: '100%',
        display: 'grid',
        gridTemplateColumns: '24px auto',
        color: '#fff',
        alignItems: 'center',
        gridColumnGap: 8,
        fontWeight: 700,
        '& > svg': {
            height: '100%'
        }
    },
    emptyConversation: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        flex: 1,
        '& > span': {
            fontWeight: 700,
            marginBottom: 8
        }
    }
}))

const MessageCenter =  () => {
    const classes = useStyles();
    const currentConversation = useSelector(getCurrentConversationId);
    const chatReady = useSelector(state => state.chats.chatStatus.chatReady);
    const showMembers = useSelector(state => state.chats.currentConversation.showMembers)
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(setChatOpened(true))
        return () => dispatch(setChatOpened(false))
    }, [])


    const hideChat = () => dispatch(setChatOpened(false))


    return(
        <>
            <div className={classes.header}>
                <span className={classes.title}><ChatIcon/>Chat</span>
                <span onClick={hideChat}><CloseIcon/></span>
            </div>
            <div className={classes.body}>
                {
                    chatReady ?
                        <>
                            <Menu isOpen={true}/>
                            {currentConversation
                                ?
                                <>
                                    <CurrentConversation/>
                                    <ConversationMembers isOpen={showMembers}/>
                                </>
                                :
                                <div className={classes.emptyConversation}>
                                    <img src={emptyChatPlaceholder} alt={'empty chat'}/>
                                    <span>Inizia una converazione</span>
                                    <p>Ricerca un utente dal pannello laterale e inizia a parlare.</p>
                                </div>
                            }
                        </>
                        :
                        <Spinner overlay={false}/>
                }
            </div>
        </>
    )
}





export default MessageCenter;