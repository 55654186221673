import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import SubHeading from "../../../Commons/SubHeading.js";
import Spinner from "../../../Commons/Spinner.js";
import Table from "../../../Commons/Table.js";
import Button from "../../../Commons/Button.js";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';
import {editUser, setUsersList} from "../../../store/actions/settingsActions.js";
import {httpDeleteUser, httpGetUsers, httpUpdateUser} from "../../../HttpRequests/settings.http.js";
import ToggleSwitch from "../../../Commons/ToggleSwitch.js";
import ContextMenu from "../../../Commons/ContextMenu";
import {AddIcon} from "../../../assets/icons.js";
import {alertToggle} from "../../../store/actions/alertActions.js";
import SimpleSearch from "../../../Commons/SimpleSearch.js";
import {clearFilters, errorHandler} from "../../../helpers/utils.js";

const useStyles = createUseStyles(theme => ({
    gridContainer: {
        'position': 'relative',
        // with big/small subheader like permute page
        height: ({bigSubHeader}) => `calc(100vh - ${bigSubHeader ? theme.bigSubHeader + theme.headerHeight : theme.smallSubHeader + theme.headerHeight}px)`,
        // without subheader
        //height: `calc(100vh - ${theme.headerHeight}px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        [theme.mUp]: {
            padding: [theme.spacing * 4, theme.spacing * 4, 0],
        },
        [theme.s]: {
            padding: [theme.spacing],
        },
    },
}));


const UsersTable = () => {
    const classes = useStyles({bigSubHeader: false});
    const usersList = useSelector(state => state.settings.usersList);
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(null);
    const [filters, setFilters] = useState({});
    const [generalLoading, setGeneralLoading] = useState(false)

    //fetch  data
    useEffect(() => {
        getUsers();
    }, []);

    //fetch data when filters change
    useEffect(() => {
        if (!!Object.keys(filters).length) {
            setPage(1);
            setLastPage(null);
            getUsers();
        }
    }, [filters]);

    //fetch when page change
    useEffect(() => {
        if (!!usersList.length && page !== 1) {
            getUsers(page)
        }
    }, [page]);

    const getUsers = async (actualPage = 1) => {
        if (actualPage === 1) dispatch(setUsersList([]));
        setLoading(true);
        clearFilters(filters);

        try {
            const {data: {data, last_page}} = await httpGetUsers({limit: 18, ...filters, page: actualPage});
            if (actualPage === 1) {
                dispatch(setUsersList(data));
            } else {
                dispatch(setUsersList(usersList.concat(data)));
            }
            setLastPage(last_page);
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    };

    const changeUserStatus = async (item) => {
        const user = {active: !item.active};
        const userId = item.id;
        try {
            setGeneralLoading(true);
            await httpUpdateUser(userId,user);
            dispatch(editUser(user));
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setGeneralLoading(false);
            dispatch(alertToggle((userId ? 'Dati correttamente aggiornati' : 'Dati correttamente inseriti'), 'success'))
        }
    };

    const onChangePage = (isFilter = false) => {
        if (isFilter) {
            setPage(1)
        } else {
            if ((lastPage && page + 1) > lastPage) return;
            setPage(page + 1)
        }
    };

    const onDataSorting = (newSort) => {
        setFilters({
            ...filters,
            ...newSort,
        });
        setPage(1);
    };

    return <>
        {generalLoading && <Spinner overlayFullscreen={true}/>}
        <SubHeading title={'Utenti'} type={'small'} rightSearchComponent={
            <>
                <SimpleSearch filters={filters} setFilters={setFilters} setPage={setPage} name={'q'}
                              placeholder={'ricerca per utente'}/>
                <Button data-primary icon={<AddIcon/>} onClick={() => history.push('/settings/users/new')}>aggiungi
                    utente</Button>
            </>
        }
        />
        <section className={classes.gridContainer}>
            <Table
                id={'table'}
                data={usersList}
                setData={() => getUsers()}
                theadHeight={40}
                rowMinHeight={48}
                filters={filters}
                onSetPageCb={onChangePage}
                onSortingDataCb={onDataSorting}
                inLoading={loading}
                classNames={{
                    table: classes.table,
                    thead: classes.thead,
                    row: classes.row,
                }}
                columns={['first_name', 'last_name', 'email', 'secondary_email', 'company', 'assigned_car_plate', 'daily_report', 'active', 'actions']}
                columnsNames={{
                    first_name: "nome",
                    last_name: "cognome",
                    email: "email",
                    secondary_email: "email secondaria",
                    company: 'gruppo',
                    assigned_car_plate: 'auto aziendale',
                    daily_report: "daily report",
                    active: "Stato",
                    actions: ''
                }}
                columnsWidths={{
                    first_name: "10%",
                    last_name: "10%",
                    email: "15%",
                    secondary_email: "15%",
                    company: '10%',
                    assigned_car_plate: '15%',
                    daily_report: "10%",
                    active: "10%",
                    actions: '5%',
                }}
                columnsAlignments={{
                    actions: 'right',
                }}
                backEndSortable={true}
                sortable={true}
                headersSorters={['last_name']}
                columnsRenderers={{
                    price: value => value,
                    company: value => <span>{value ? value.name : '-'}</span>,
                    assigned_car_plate: value => <span>{value ? value : '-'}</span>,
                    daily_report: value => <span>{value ? 'Si' : 'No'}</span>,
                    active: (value, items) => <ToggleSwitch label={''} defaultChecked={value} isSmall={true}
                                                            onClick={() => changeUserStatus(items)}/>,
                    actions: (value, items, metadata) => <Actions value={value} items={items} metadata={metadata}/>
                }}
            />
        </section>
    </>
};

export default UsersTable;

const Actions = ({items, metadata}) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const usersList = useSelector(state => state.settings.usersList);

    const deleteUser = async () => {
        setLoading(true)
        try {
            await httpDeleteUser(items.id);
            dispatch(setUsersList(usersList.filter(user => +user.id !== +items.id)));
            dispatch(alertToggle(('Utente correttamente eliminato'), 'success'))
        } catch (e) {
            errorHandler(e, dispatch)
        }finally {
            setLoading(false)
        }
    };

    const actions = [
        {to: `/settings/users/${items?.id}`, text: 'Modifica', callback: null, type: 'normal'},
        {to: null, text: 'Elimina utente', callback: () => deleteUser(), type: 'danger'}];

    return <>
        {loading && <Spinner overlayFullscreen={true}/>}
        <ContextMenu actions={actions} id={`${metadata.rowIndex}-user`} customTranslateToTopY={24}/>
    </>
};