import React, {useEffect, useState} from 'react';
import { createUseStyles } from 'react-jss';
import { ArrowUp, ArrowDown } from '../assets/icons';
import PropTypes from 'prop-types';

const useStyles = createUseStyles((theme) => ({
	baseStyles: {
		backgroundColor: theme.colors.white,
		borderRadius: 16,
		marginTop: theme.spacing,
		marginBottom: theme.spacing * 2,
		boxSizing: 'border-box',
		opacity: ({isDisabled}) => isDisabled ? '0.5' : '1',
	},
	cardHeader: {
		display: 'grid',
		gridTemplateRows: '1fr',
		gridColumnGap: theme.spacing,
		backgroundColor: theme.colors.white,
		borderRadius: 16,
		boxSizing: 'border-box',
		padding: [theme.spacing * 2],
		gridTemplateColumns: ({ actions }) => (actions ? '30px 1fr 0.3fr auto' : '30px 1fr auto'),
		/*gridTemplateColumns: '30px 1fr auto',*/
		alignItems: 'center',
		justifyItems: 'left',
		fontWeight: 'bold',
		fontSize: ({ cardTitleSize }) => (cardTitleSize === 'small' ? 14 : cardTitleSize === 'medium' ? 18 : 26),
		'& :first-child': {
			justifySelf: 'center'
		},
		'& svg': {
			fill: `${theme.colors.black}!important`,
			cursor: 'pointer'
		}
	},
	cardTitleDesc: {
		fontSize: ({ cardTitleSize }) => (cardTitleSize === 'small' ? 14 : cardTitleSize === 'medium' ? 18 : 26),
		color: ({ cardTitleDescColor }) => cardTitleDescColor,
		paddingLeft: theme.spacing
	},
	cardAction: {
		justifySelf: 'end',
		'& svg': {
			width: 24,
			cursor: 'pointer'
		}
	},
	baseContentWrapper: {
		width: '100%',
		height: 'auto',
		transition: 'ease-in 300ms',
		padding: [theme.spacing * 2],
	},
}));

const TabCard = ({children, cardIcon, cardIconFill, cardTitle, cardTitleDesc, cardTitleDescColor,
					 cardTitleSize = 'small', showContent = false, isDisabled = false, actions = null,
}) => {
	const [ showState, setShowState ] = useState(showContent);
	const classes = useStyles({ cardTitleSize, cardIconFill, cardTitleDescColor, showState, isDisabled, actions });

	useEffect(() => {
		if(!isDisabled){
			setShowState(showContent)
		}
	}, [showContent]);

	return (
		<div className={classes.baseStyles}>
			<div className={classes.cardHeader} onClick={() => isDisabled ? Function.prototype() : setShowState(!showState)}>
				{cardIcon}
				{cardTitle && (
					<span>
						{cardTitle}
						{cardTitleDesc && <span className={classes.cardTitleDesc}>{cardTitleDesc}</span>}
					</span>
				)}
				{showState && actions}
				<span className={classes.cardAction} onClick={() => isDisabled ? Function.prototype() : setShowState(!showState)}>
					{showState ? <ArrowUp /> : <ArrowDown />}
				</span>
			</div>

			{showState && <div className={classes.baseContentWrapper}>
					{children}
			</div>}
		</div>
	);
};

/*
USAGE:
<TabCard cardTitle={'Some Title'} cardTitleSize={'medium'}>Content .... </Card>
other settable props:
cardIcon={<SomeSvgComponent />} //param: component
cardIconFill={'#f00'} //param: string (hex color val)
cardTitle={'Some Title'} //param: string
cardTitleDesc={'*'} //parame: string
cardTitleDescColor={'#f00'} //param: string (hex color val)
showContent={true} //shows or hide content by default //default: false
cardTitleSize={'small'} //param: string, available 'small', 'medium' or 'large' //default: 'small'
*/

TabCard.propTypes = {
	children: PropTypes.any,
	cardIcon: PropTypes.any,
	cardIconFill: PropTypes.string,
	cardTitle: PropTypes.string,
	cardTitleDesc: PropTypes.string,
	cardTitleDescColor: PropTypes.string,
	showContent: PropTypes.bool,
	cardTitleSize: PropTypes.oneOf([ 'small', 'medium', 'large' ])
};

export default TabCard;
