import React from 'react';
import {createUseStyles} from 'react-jss';
import cx from "classnames";

const useStyles = createUseStyles(theme => ({
    root: {
        'width': ({width}) => width,
        'min-width': theme.spacing * 4,
        'height': 40,
        'border': 0,
        borderRadius: 12,
        'cursor': ({disabled}) => disabled ? 'not-allowed' : 'pointer',
        'font-size': 14,
        'font-weight': 400,
        'text-transform': 'uppercase',
        'display': 'grid',
        'align-items': 'center',
        'justify-content': 'center',
        'padding': [0, theme.spacing * 2],
        /*'max-width': 280,*/
        position: 'relative',
        transition: 'ease-in-out 300ms',
        '& span': {
            display: 'grid',
            alignItems: 'center',
            gridAutoFlow: 'column',
            gridColumnGap: 8,
            '& svg': {
                width: 18
            }
        },

        '&[data-xs-small]': {
            height: "24px",
            fontSize: "10px",
            borderRadius: "8px",
            padding: "0 8px!important",
            '& span': {
                '& svg': {
                    width: 16
                }
            },
        },

        '&[data-small]': {
            'height': 32,
            'font-size': 12,
            borderRadius: 8,
            '& span': {
                '& svg': {
                    width: 16
                }
            },
        },

        '&[data-big]': {
            'height': 48,
            'font-size': 14,
            borderRadius: 12,
            '& span': {
                '& svg': {
                    width: 20
                }
            },
        },

        '&:not([data-primary]):not([data-secondary]):not([data-transparent])': {
            'background-color': 'transparent',
            'border': `solid 1px ${theme.colors.black}`,
            'color': theme.colors.black,
            '& span': {
                '& svg': {
                    fill: `${theme.colors.black}!important`
                }
            },
            '&:hover': {},
        },

        '&[data-primary]': {
            'background-color': ({disabled}) => disabled ? theme.colors.grey : theme.colors.lightBlack,
            'color': theme.colors.white,
            '& span': {
                '& svg': {
                    fill: `${theme.colors.white}!important`
                }
            },
            '&:hover': {
                'background-color': ({disabled}) => disabled ? theme.colors.grey : theme.colors.black,
                'color': theme.colors.white,
                '& span': {
                    '& svg': {
                        fill: `${theme.colors.white}!important`
                    }
                },
            },
        },

        '&[data-secondary]': {
            'background-color': theme.colors.white,
            'border': ({disabled}) => disabled ? `solid 1px ${theme.colors.grey}` : `solid 1px ${theme.colors.black}`,
            'color': ({disabled}) => disabled ? theme.colors.grey : theme.colors.black,
            '& span': {
                '& svg': {
                    fill: ({disabled}) => `${disabled ? theme.colors.grey : theme.colors.black}!important`
                }
            },
            '&:hover': {
                'background-color': ({disabled}) => disabled ? theme.colors.white : theme.colors.black,
                'color': ({disabled}) => disabled ? theme.colors.grey : theme.colors.white,
                'border': ({disabled}) => (disabled) ? `solid 1px ${theme.colors.grey}` : `solid 1px ${theme.colors.black}`,
                '& span': {
                    '& svg': {
                        fill: ({disabled}) => `${disabled ? theme.colors.grey : theme.colors.white}!important`
                    }
                },
            },
        },
        '&[data-transparent]': {
            'opacity': 0.7,
            'background-color': 'transparent',
            'border': ({disabled}) => disabled ? `solid 1px ${theme.colors.lightGrey}` : `solid 1px ${theme.colors.white}`,
            'color': ({disabled}) => disabled ? theme.colors.lightGrey : theme.colors.white,
            '& span': {
                '& svg': {
                    fill: ({disabled}) => `${disabled ? theme.colors.lightGrey : theme.colors.white}!important`
                }
            },
            '&:hover': {
                'opacity': 1
            },
        },
    },
}));

const Button = ({className, children, width = 'auto', type = 'button', disabled, icon, ...props}) => {
    const classes = useStyles({width, disabled: disabled,icon});

    return (
        <button className={cx(classes.root, className)} {...props} type={type} disabled={disabled}>
            {icon ? <span> {icon} <span>{children}</span></span> : <span>{children}</span>}
        </button>
    );
};

export default Button;
