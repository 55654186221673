import Popover from './Popover.js';
import { ShutdownIcon } from '../assets/icons.js';
import { Link, useHistory } from 'react-router-dom';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { logout, logoutSuccess } from '../store/actions/userActions.js';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import DayPickerInput from './DayPickerInput.js';
import cx from 'classnames';

const useStyles = createUseStyles((theme) => ({
	root: {
		transform: 'none',
		top: theme.spacing * 8,
		right: theme.spacing * 3,
		left: 'auto',
		padding: 0,
		display: 'grid',
		gridRowGap: theme.spacing,
		alignItems: 'center',
		fontSize: 14,
		fontWeight: 500,
		borderTopRightRadius: 0,
		overflow: 'hidden',
		...theme.shadow,
		'& span': {
			display: 'grid',
			gridTemplateColumns: '1fr auto',
			alignItems: 'center',
			cursor: 'pointer',
			transition: 'ease-in 300ms',
			'& svg': {
				fill: theme.colors.black,
				width: '18px !important'
			},
			'&:hover': {
				color: theme.colors.red,
				'& svg': {
					fill: theme.colors.red
				}
			}
		},
		'& a': {}
	},
	danger: {
		color: theme.colors.red,
		'& svg': {
			fill: `${theme.colors.red}!important`
		}
	},
	menuItem: {
		fontSize: 14,
		width: '100%',
		transition: 'ease-in 300ms',
		textDecoration: 'none',
		padding: [ theme.spacing, theme.spacing * 2 ],
		display: 'block',
		'&:hover': {
			backgroundColor: theme.colors.lightGrey
		}
	}
}));

const UserActionPopover = ({ popoverToggle }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const isExternal = useSelector(state => (state.users.user.operations.tire_center || state.users.user.operations.body_shop || state.users.user.operations.workshop));

	const logoutCb = () => {
		dispatch(logout());
	};

    return <Popover width={120} overlay={true} withHeader={false} onClose={popoverToggle} contextMode={true}
                    className={classes.root}>
		{!isExternal && <Link to={'/profile'} className={cx(classes.menuItem)}> Profilo </Link>}
        <span onClick={logoutCb} className={cx(classes.menuItem, classes.danger)}>Logout <ShutdownIcon data-color/></span>
    </Popover>
};

export default UserActionPopover;

DayPickerInput.propTypes = {
    popoverToggle: PropTypes.func,
};
