import React, {forwardRef, useEffect} from 'react';
import {createUseStyles} from 'react-jss';
import cx from 'classnames';
import {Controller} from 'react-hook-form';
import {useWindowWidth} from '@react-hook/window-size';
import PropTypes from "prop-types";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, {registerLocale} from "react-datepicker";
import {formattedDateHoursIso, formattedDateIso} from "../helpers/utils.js";
import it from "date-fns/locale/it";
registerLocale("it", it); // register it with the name you want

const useStyles = createUseStyles(theme => ({
    root: {
        'width': '100%',
        'display': 'grid',
        'grid-auto-flow': 'row',
        // 'grid-row-gap': theme.spacing + 'px',
    },

    withDesc: {
        fontSize: "12px",
        color: theme.colors.grey,
        letterSpacing: "0",
        lineHeight: "16px",
        marginTop: 4,
    },

    label: theme.control.label,

    input: {
        ...theme.control.input,
    },

    error: {
        'color': theme.colors.red,
    },

    popper: {
        zIndex: `10!important`
    },

    noBorder: {
        border: 'none'
    }
}));


const DayPickerInput = forwardRef(({
                                       className,
                                       label,
                                       errors,
                                       rootProps,
                                       inForm = true,
                                       customFormat = null,
                                       dateFieldName = null,
                                       defaultValue,
                                       description,
                                       disabled,
                                       minDate,
                                       maxDate,
                                       showYearDropdown = true,
                                       showTimeSelect = false,
                                       isRange = false,
                                       filters,
                                       setFilters,
                                       callback,
                                       border = true,
                                       ...props
                                   }, ref) => {
    const vw = useWindowWidth({wait: 250});
    const classes = useStyles({errors: !!errors});
    useEffect(() => {
        registerLocale('it', it);
    }, [])

    const renderInput = () => {
        if (inForm) {
            return (vw > 600) ?
                <Controller
                    as={
                        <DatePicker
                            dateFormat={customFormat ? customFormat : 'dd-MM-yyyy'}
                            showTimeSelect={showTimeSelect}
                            todayButton={'Data odierna'}
                            shouldCloseOnSelect={true}
                            autoComplete={'off'}
                            isClearable={!disabled}
                            placeholderText={'Seleziona una data...'}
                            closeOnSelect={true}
                            customInput={<input className={cx(classes.input, border ? '' : classes.noBorder)}/>}
                            defaultValue={defaultValue}
                            showYearDropdown={showYearDropdown}
                            yearDropdownItemNumber={15}
                            dropdownMode="scroll"
                            maxDate={maxDate}
                            minDate={minDate}
                            disabled={disabled}
                            locale="it"
                        />
                    }
                    register={ref}
                    control={props.control}
                    name={props.name}
                    valueName="selected" // DateSelect value's name is selected
                    preventOpenOnFocus={true}
                    onChange={([selected]) => {
                        /* onChange={([selected],e) => {
                            if (e && typeof e.preventDefault === 'function') {
                                e.preventDefault();
                            } */
                        if (callback) {
                            callback(props.name, selected, label)
                        }
                        return selected
                    }}
                />
                :
                <input type={'date'} className={classes.input} ref={ref} {...props} />;
        } else {
            return (vw > 600) ?
                <DatePicker
                    dateFormat={customFormat ? customFormat : 'dd-MM-yyyy'}
                    showTimeSelect={showTimeSelect}
                    dateFormatCalendar={'MMMM'}
                    todayButton={'Data odierna'}
                    shouldCloseOnSelect={true}
                    autoComplete={'off'}
                    isClearable={!disabled}
                    placeholderText={'Data'}
                    closeOnSelect={true}
                    customInput={<input className={cx(classes.input, border ? '' : classes.noBorder)}/>}
                    defaultValue={defaultValue}
                    showYearDropdown={showYearDropdown}
                    yearDropdownItemNumber={20}
                    dropdownMode="scroll"
                    maxDate={maxDate}
                    minDate={minDate}
                    disabled={disabled}
                    locale="it"
                    name={props.name}
                    valueName="selected" // DateSelect value's name is selected
                    selected={dateFieldName ? filters?.[dateFieldName] ? new Date(filters?.[dateFieldName].toString().replace(/-/g, "/")) : null :
                        filters?.date ? new Date(filters.date.toString().replace(/-/g, "/")) : null}
                    onChange={(date, e) => {
                        e.preventDefault();
                        setFilters(dateFieldName ? {
                                ...filters,
                                [dateFieldName]: date ? showYearDropdown ? formattedDateHoursIso(date) : formattedDateIso(date) : null
                            } :
                            {
                                ...filters,
                                date: date ? showYearDropdown ? formattedDateHoursIso(date) : formattedDateIso(date) : null
                            })
                    }}
                    popperClassName={classes.popper}
                />
                :
                <input type={'date'} className={classes.input} ref={ref} {...props} />;
        }
    };

    return (
        <label data-control id={props.name} className={cx(classes.root, className)} {...rootProps}>
            {!!label && <span className={classes.label}>{label}</span>}
            {renderInput()}
            {description && <small className={classes.withDesc}> {description} </small>}
            {!!errors && <small className={classes.error}>{errors.message}</small>}
        </label>
    );
});

export default DayPickerInput;

DayPickerInput.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    errors: PropTypes.object,
    description: PropTypes.string,
    register: PropTypes.func, //required if we use it with react hook form
    defaultValue: PropTypes.any,
    maxDate: PropTypes.any,
    isRange: PropTypes.bool
};