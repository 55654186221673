import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useParams} from 'react-router-dom';
import CarHeading from "../../Components/Cars/CarHeading";
import {useDispatch, useSelector} from "react-redux";
import {
    allShiftsTypes,
    clearFilters,
    errorHandler,
    formattedDate, formattedDateHours,
    getLabelByValue, retrieveValueForRs,
    taskStatusColors,
    taskStatuses
} from "../../helpers/utils.js";
import Table from "../../Commons/Table.js";
import Badge from "../../Commons/Badge.js";
import {httpGetCar} from "../../HttpRequests/commons.http.js";
import {setCarShifts, setCurrentCar} from "../../store/actions/carsActions.js";
import ContextMenu from "../../Commons/ContextMenu.js";
import Button from "../../Commons/Button.js";
import {AddIcon} from "../../assets/icons.js";
import {httpGetCarShifts} from "../../HttpRequests/shifts.http.js";
import Popover from "../../Commons/Popover.js";
import SingleShiftModal from "../../Components/Shifts/SingleShiftModal.js";
import InternalOrExternalShiftModal from "../../Components/Shifts/InternalOrExternalShiftModal.js";
import Avatar from "../../Commons/Avatar.js";

const useStyles = createUseStyles(theme => ({
    gridContainer: {
        'position': 'relative',
        // with big/small subheader like permute page
        height: `calc(100vh - ${theme.carSubHeader}px)`,
        // without subheader
        //height: `calc(100vh - ${theme.headerHeight}px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        [theme.mUp]: {
            padding: [theme.spacing * 5, theme.spacing * 4, 0]
        },
        [theme.s]: {
            padding: [theme.spacing],
        },
    },
    gridHeading: {
        display: 'grid',
        position: 'relative',
        gridTemplateColumns: '1fr',
        gridColumnGap: theme.spacing,
        padding: [theme.spacing, 0],
        margin: [theme.spacing * 4, 0],
        borderBottom: `1px solid ${theme.colors.grey}`,
        alignItems: 'center',
        justifyItems: 'end'
    },
    doubleRow: {
        ...theme.grid.doubleRow,
    },
    doc:{
        '& a':{
            fontSize: 12,
            color: theme.colors.darkGrey,
            textDecoration: 'underline!important'
        }
    }
}));

const CarShifts = () => {
    const {id} = useParams();
    const currentCar = useSelector(state => state.cars.currentCar);
    const shifts = useSelector(state => state.cars.carShifts)
    const classes = useStyles({bigSubHeader: false});
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({});
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(null);
    const [showSingleShiftPopover, setShowSingleShiftPopover] = useState(false);
    const [showInternalShiftPopover, setShowInternalShiftPopover] = useState(false);
	const [showExternalShiftPopover, setShowExternalShiftPopover] = useState(false);
    const dispatch = useDispatch();

    const getCarData = async () => {
        setLoading(true);
        try {
            const {data: car} = await httpGetCar(id);
            dispatch(setCurrentCar(car));
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (+currentCar?.id !== +id) {
            //if the requested car is different from the stored one
            dispatch(setCurrentCar(null));
            getCarData();
        }
    }, []);

    const getCarShifts = async (actualPage = 1) => {
        if (lastPage && actualPage > lastPage) return;
        if (actualPage === 1) dispatch(setCarShifts([]))
        setLoading(true);
        clearFilters(filters);
        try {
            const {data: {data: newShifts, last_page}} = await httpGetCarShifts(id, {
                limit: 20, ...filters,
                page: actualPage
            });
            if (actualPage === 1) {
                dispatch(setCarShifts(newShifts));
            } else {
                dispatch(setCarShifts(shifts.concat(newShifts)));
            }
            setLastPage(last_page)
        } catch (e) {
            errorHandler(e, dispatch);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getCarShifts(1);
    }, []);

    //fetch when filters change
    useEffect(() => {
        if (!!Object.keys(filters).length) {
            setPage(1);
            setLastPage(null);
            getCarShifts(1);
        }
    }, [filters]);

    //fetch when page change
    useEffect(() => {
        if (!!shifts.length && page !== 1) {
            getCarShifts(page)
        }
    }, [page]);


    const onChangePage = (isFilter = false) => {
        if (isFilter) {
            setPage(1)
        } else {
            setPage(page + 1)
        }
    };

    const onDataSorting = (newSort) => {
        setFilters({
            ...filters,
            ...newSort,
        });
        setPage(1);
    };

    /** transport modals things **/
    const actions = [{
        to: null,
        text: 'Spostamento tra sedi ( interno )',
        callback: () => setShowInternalShiftPopover(!showInternalShiftPopover),
        type: 'normal',
    }, {
        to: null,
        text: 'Spostamento esterno',
        callback: () => setShowExternalShiftPopover(!showExternalShiftPopover),
        type: 'normal',
    }, {
        to: null,
        text: 'Spostamento singolo ( autonomo )',
        callback:() => setShowSingleShiftPopover(!showSingleShiftPopover),
        type: 'normal',
    }];

   /* const editShiftStatus = async (status, shift) => {
        console.log(status, shift)
        setLoading(true)
        try {
            dispatch(updateCarShift({...shift, status: status}));
            await httpEditShift(shift?.id, {...shift, status: status});
            dispatch(alertToggle('Stato correttamente aggiornato', 'success'));
        } catch (e) {
            errorHandler(e, dispatch);
            dispatch(updateShift({...shift}));
        }finally {
            setLoading(false)
        }
    };*/

    return <>
        {showSingleShiftPopover && <Popover withHeader={false} minWidth={450} onClose={() => setShowSingleShiftPopover(!showSingleShiftPopover)}
                 contextMode={false}>
            <SingleShiftModal onClose={() => setShowSingleShiftPopover(!showSingleShiftPopover)}/>
        </Popover>}
		{showInternalShiftPopover && <Popover withHeader={false} minWidth={450} onClose={() => setShowInternalShiftPopover(!showInternalShiftPopover)}
										  contextMode={false}>
			<InternalOrExternalShiftModal type={'internal_transport'} onClose={() => setShowInternalShiftPopover(!showInternalShiftPopover)}/>
		</Popover>}
		{showExternalShiftPopover && <Popover withHeader={false} minWidth={450} onClose={() => setShowExternalShiftPopover(!showExternalShiftPopover)}
										  contextMode={false}>
			<InternalOrExternalShiftModal type={'external_transport'} onClose={() => setShowExternalShiftPopover(!showExternalShiftPopover)}/>
		</Popover>}

        <CarHeading headingTitle={'Spostamenti'} car={currentCar}/>
        <section className={classes.gridContainer}>
            {/*{loading && <Spinner overlayFullscreen={true}/>}*/}
            <div className={classes.gridHeading}>
                <ContextMenu actions={actions} id={`1-operations`} type={'custom'}
                             customOpenComponent={<Button data-primary icon={<AddIcon/>}> Nuovo spostamento </Button>}
                />
            </div>

            <Table
                id={'table'}
                data={shifts}
                inLoading={loading}
                filters={filters}
                onSetPageCb={onChangePage}
                onSortingDataCb={onDataSorting}
                theadHeight={40}
                rowMinHeight={48}
                classNames={{
                    table: classes.table,
                    thead: classes.thead,
                    row: classes.row,
                }}
                columns={['car_plate', 'type', 'date', 'location_from_name', 'location_to_name','user', 'document', 'status']}
                columnsNames={{
                    car_plate: "Targa",
                    type: "Tipo di spostamento",
                    date: "Data e ora",
                    location_from_name: "Da",
                    location_to_name: "A",
                    user: "Eseguito da",
                    document: "Bolla",
                    status: "Stato",
                }}
                columnsWidths={{
                    car_plate: "8%",
                    type: "17%",
                    date: "10%",
                    location_from_name: "15%",
                    location_to_name: "15%",
                    user: "15%",
                    document: "8%",
                    status: "12%",
                }}
                columnsAlignments={{
                    status: 'center',
                }}
                backEndSortable={true}
                sortable={true}
                //headersSorters={['assign_date', 'user', 'status']}
                columnsRenderers={{
                    car_plate: (value,items) => <span style={{textTransform: 'uppercase'}}>
                        {items?.car_identifiers?.plates ? items.car_identifiers.plates[0] :
                            items?.car_identifiers?.VIN ? items?.car_identifiers?.VIN : '-'}
                    </span>,
                    type: (value) => <span>{retrieveValueForRs(allShiftsTypes, value)[0].label}</span>,
                    date: (value,items) => <div className={classes.doubleRow}>
                        <span> {formattedDate(items?.preferred_date ? items?.preferred_date : items?.transfer_date)}</span>
                        <small>{formattedDateHours(items?.preferred_date ? items?.preferred_date : items?.transfer_date)}</small>
                    </div>,
                    location_from_name: (value) => <span><b>{value}</b></span>,
                    location_to_name: (value) => <span><b>{value}</b></span>,
                    user: (value,items) => <span> {items?.assigned_user ? <Avatar size={'small'} fullName={items?.assigned_user?.full_name} img={items?.assigned_user?.photo_link}/> : '-'} </span>,
                    document: (value,items) => <span className={classes.doc}>
                        {!!items?.transportation_attachments?.length ? <a href={items?.transportation_attachments[0]?.link} target={'_blank'}>
                           {/* {items?.transportation_attachments[0]?.filename} </a> : 'N.d'}*/}
                           Bolla_{items?.id}
                        </a> : 'N.d'}
                    </span>,
                   /* status: (value,items, metadata) => <TaskStatusesTableDropDown value={value} callback={editShiftStatus} items={items} metadata={metadata}/>,*/
                    status: (value) => <Badge title={value && getLabelByValue(taskStatuses, value)}
                                              statusColor={taskStatusColors[value]} badgeSize={'small'}/>

                }}
            />
        </section>
    </>
};

export default CarShifts
