import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import SubHeading from "../../Commons/SubHeading.js";
import {useParams} from 'react-router-dom';
import Spinner from "../../Commons/Spinner.js";
import {httpGetCar} from "../../HttpRequests/commons.http.js";
import {useDispatch, useSelector} from "react-redux";
import {AlertWarningIcon, CarIcon, PhotoCameraIcon, StatsIcon} from "../../assets/icons.js";
import TabCard from "../../Commons/TabCard.js";
import CarInfoForm from "../../Components/Barter/CarInfoForm.js";
import BarterImagesUpload from "../../Components/Barter/BarterImagesUpload";
import {setCurrentBarter, setDamages} from "../../store/actions/bartersActions.js";
import {errorHandler} from "../../helpers/utils.js";
import MarketValues from "../../Components/Barter/MarketValues.js";
import DamagesRecap from "../../Components/BarterRecap/DamagesRecap.js";
import FiscalityRecap from "../../Components/BarterRecap/FiscalityRecap.js";
import RecapSection from "../../Components/Barter/RecapSection.js";
import AdminRecapPanel from "../../Components/BarterRecap/AdminRecapPanel.js";
import {useWindowWidth} from "@react-hook/window-size";
import BarterHistories from "../../Components/Barter/BarterHistories.js";

const useStyles = createUseStyles(theme => ({
    gridContainer: {
        'position': 'relative',
        // with big/small subheader like permute page
        height: ({bigSubHeader}) => `calc(100vh - ${bigSubHeader ? theme.bigSubHeader + theme.headerHeight : theme.smallSubHeader + theme.headerHeight}px)`,
        // without subheader
        //height: `calc(100vh - ${theme.headerHeight}px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        [theme.mUp]: {
            padding: [theme.spacing * 4],
            paddingBottom: ({canSeeAdminPanel}) => canSeeAdminPanel ? theme.spacing * 32 : theme.spacing * 4,
        },
        [theme.s]: {
            height: '100%',
            padding: [theme.spacing],
            paddingBottom: ({canSeeAdminPanel}) => canSeeAdminPanel ? theme.spacing * 16 : theme.spacing * 4,
        },
    },
    recapCard:{
        padding: theme.spacing * 2,
        ...theme.paper,
        borderRadius: theme.spacing,
        display: 'grid',
        gridRowGap: theme.spacing,
        alignItems: 'center',
        '& p':{
            color: theme.colors.red,
            fontSize: 12,
            display: 'grid',
            gridColumnGap: 8,
            gridTemplateColumns: '1fr auto',
            alignItems: 'center',
            '& span':{
                fontWeight: 600,
                fontSize: 14,
            }
        },
        '& h4':{
            color: theme.colors.black,
            fontSize: 14,
            textTransform: 'uppercase',
            fontWeight: 600,
            display: 'grid',
            gridColumnGap: 8,
            gridTemplateColumns: '1fr auto',
            alignItems: 'center',
            '& small':{
                fontSize: 14,
            },
            '& span':{
                fontSize: 18,
            }
        }
    },
    btns: {
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridColumnGap: theme.spacing,
        width: 600,
        justifySelf: 'end'
    },
    salesforceError: {
        position: "absolute",
        padding: "16px 32px",
        top: theme.bigSubHeader,
        background: theme.colors.alertRed,
        left: "0px",
        right: "0",
        display: "flex",
        justifyContent: "center",
        zIndex: 2,
    },
    infos:{
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        gridTemplateColumns: "auto 1fr",
        color: theme.colors.white,
        fontWeight: 600,
        gridColumnGap: "16px",
        maxWidth: 625,
        '& p': {
            fontSize: 12,
            fontWeight: 600
        },
        '& svg': {
           fill: theme.colors.white
        },
    }
}));

const BarterRecap = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const operations = useSelector(state => state.users.operations);
    const isMobile = (useWindowWidth() < 576);
    const [loading, setLoading] = useState(false);
    const [seeAll, setSeeAll] = useState(!isMobile); //in case we should use it in the feature
    const barter = useSelector(state => state.barters.currentBarter);
    const damages = useSelector(state => state.barters.damages);
    const canSeeAdminApproval = (barter?.status === 'negotiating' && operations?.admin);
    const classes = useStyles({bigSubHeader: true, canSeeAdminPanel:canSeeAdminApproval});

    useEffect(() => {
        if (+barter?.id !== +params?.id) {
            //if the requested barter is different from the stored one
            dispatch(setCurrentBarter(null));
            //fetch data
            getBarter();
        }
    }, []);

    const getBarter = async () => {
        setLoading(true);
        try {
            const {data: barter} = await httpGetCar(params?.id);
            //set barter and damages
            dispatch(setCurrentBarter(barter));
            dispatch(setDamages(barter?.examination ? barter?.examination.damages : []));
        } catch (e) {
            errorHandler(e,dispatch)
        } finally {
            setLoading(false);
        }
    };

    const SalesForceError = <div className={classes.salesforceError}>
        <div className={classes.infos}>
            <AlertWarningIcon data-color/>
            <p> Si è verificato un errore nella connessione con Salesforce. La segnalazione è stata inviata al reparto tecnico</p>
        </div>
    </div>

    return <>
        <SubHeading title={barter ? `Valutazione ${barter?.model?.brand?.name} - ${barter?.model?.name}` : 'Valutazione -'}
                    type={'big'} justifySelf={'end'} titleAndSearchAlign={'start'} salesforceError={!!barter?.salesforce_failure ? SalesForceError : null}
                    rightSearchComponent={<>{barter && <BarterHistories id={barter.id} plate={barter?.plate} status={barter?.status}/>} </>}/>

        <div className={classes.gridContainer}>
            {loading && <Spinner overlayFullscreen={true}/>}
            {barter && <div style={{marginTop: !!barter?.salesforce_failure ? 56 : 0}}>
                <TabCard cardTitle={'Dati veicolo *'} cardIcon={<CarIcon data-color/>} cardTitleSize={'medium'} showContent={seeAll}>
                    <CarInfoForm car={barter}/>
                </TabCard>
                <TabCard cardTitle={'Immagini *'} cardIcon={<PhotoCameraIcon data-color/>} cardTitleSize={'medium'}
                         showContent={seeAll}>
                    <BarterImagesUpload car={barter} readOnly={true}/>
                </TabCard>
                <TabCard cardTitle={'Riepilogo Danni e ripristini'} cardIcon={<StatsIcon data-color/>} cardTitleSize={'medium'}
                         showContent={seeAll}>
                    <DamagesRecap damages={damages} isRecap={true}/>
                </TabCard>
                <TabCard cardTitle={'Fiscalità'} cardIcon={<StatsIcon data-color/>} cardTitleSize={'medium'}
                         showContent={seeAll}>
                    <FiscalityRecap barter={barter}/>
                </TabCard>
                <TabCard cardTitle={'Valori di mercato *'} cardIcon={<StatsIcon data-color/>} cardTitleSize={'medium'}
                         showContent={seeAll}>
                    <MarketValues car={barter} isRecap={true}/>
                </TabCard>
                <RecapSection barter={barter} isRecap={true}/>
            </div>}
            {(canSeeAdminApproval) && <AdminRecapPanel barter={barter} />}
        </div>
    </>
};

export default BarterRecap;
