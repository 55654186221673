import React, {useState, useEffect} from 'react';
import {createUseStyles} from 'react-jss';
import SubHeading from "../../../Commons/SubHeading.js";
import Spinner from "../../../Commons/Spinner.js";
import Table from "../../../Commons/Table.js";
import Button from "../../../Commons/Button.js";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';
import {setBusinessList} from "../../../store/actions/settingsActions.js";
import {httpGetBusinessList, httpDeleteBusiness } from "../../../HttpRequests/settings.http.js";
import ContextMenu from "../../../Commons/ContextMenu";
import {AddIcon} from "../../../assets/icons.js";
import {alertToggle} from "../../../store/actions/alertActions.js";
import {
    clearFilters, errorHandler
} from "../../../helpers/utils.js";

const useStyles = createUseStyles(theme => ({
    gridContainer: {
        'position': 'relative',
        height: ({bigSubHeader}) => `calc(100vh - ${bigSubHeader ? theme.bigSubHeader + theme.headerHeight : theme.smallSubHeader + theme.headerHeight}px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        [theme.mUp]: {
            padding: [theme.spacing * 4, theme.spacing * 4, 0],
        },
        [theme.s]: {
            padding: [theme.spacing],
        },
    },
}));


const BusinessSettings = () => {
    const classes = useStyles({bigSubHeader: false});
    const businessList = useSelector(state => state.settings.businessList);
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(null);
    const [filters, setFilters] = useState({});

    //fetch  data
    useEffect(() => {
            getBusiness();
    }, []);

    //fetch data when filters change
    useEffect(() => {
        if (!!Object.keys(filters).length) {
            setPage(1);
            setLastPage(null);
            getBusiness();
        }
    }, [filters]);

    //fetch when page change
    useEffect(() => {
        if (!!businessList.length && page !== 1) {
            getBusiness(page)
        }
    }, [page]);

    const getBusiness = async (actualPage = 1) => {
        if(actualPage === 1)  dispatch(setBusinessList([]));
        setLoading(true);
        clearFilters(filters);
        try {
            const {data:{data, last_page}} = await httpGetBusinessList({limit: 30, ...filters, page: actualPage});
            if(actualPage === 1){
                dispatch(setBusinessList(data));
            }else{
                dispatch(setBusinessList(businessList.concat(data)));
            }
            setLastPage(last_page);
        } catch (e) {
            errorHandler(e,dispatch)
        } finally {
            setLoading(false);
        }
    };

    const onChangePage = (isFilter = false) => {
        if(isFilter){
            setPage(1)
        }else{
            if ((lastPage && page + 1) > lastPage) return;
            setPage(page + 1)
        }
    };

    const onDataSorting = ( newSort ) => {
        setFilters({
            ...filters,
            ...newSort,
        });
        setPage(1);
    };
    
    return <>
        <SubHeading title={'Società'} type={'small'} rightSearchComponent={
            <Button data-primary icon={<AddIcon/>} onClick={()=> history.push('/settings/business/new')}>Nuovo business</Button>
        }
        />
        <section className={classes.gridContainer}>
            {loading && <Spinner/>}
            {businessList &&
                <Table
                    id={'table'}
                    data={businessList}
                    setData={() => getBusiness()}
                    theadHeight={40}
                    rowMinHeight={40}
                    filters={filters}
                    onSetPageCb={onChangePage}
                    onSortingDataCb={onDataSorting}
                    inLoading={loading}
                    classNames={{
                        table: classes.table,
                        thead: classes.thead,
                        row: classes.row,
                    }}

                    columns={['name', 'administrator', 'registered_office','vat_number','actions']}
                    columnsNames={{
                        name: "nome ubicazione",
                        administrator: "business manager",
                        registered_office: "indirizzo sede legale",
                        vat_number: "partita iva",
                        actions: "",
                    }}
                    columnsWidths={{
                        name: "20%",
                        administrator: "25%",
                        registered_office: "27%",
                        vat_number: "25%",
                        actions: '3%'
                    }}
                    columnsAlignments={{
                        actions: 'right',
                    }}
                    headersSorters={['name']}
                    columnsRenderers={{
                        actions: (value,items,metadata) => <Actions value={value} items={items} metadata={metadata}/>
                    }}
                /> 
            }
            
        </section>
    </>
};

export default BusinessSettings;

const Actions = ({value, items, metadata}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const businessList = useSelector(state => state.settings.businessList);

    const deleteBusiness = async () => {
        try {
            await httpDeleteBusiness(items.id);
            dispatch(setBusinessList(businessList.filter( business => +business.id !== +items.id)));
			dispatch(alertToggle(('Business correttamente eliminata'), 'success'))
        } catch (e) {
            errorHandler(e,dispatch)
        }
    };
    
    const actions = [
        {to:`/settings/business/${items?.id}`, text: 'Modifica', callback: null, type: 'normal'},
        {to: null, text: 'Elimina business', callback: () => deleteBusiness(), type: 'danger'}];

    return <ContextMenu actions={actions} id={`${metadata.rowIndex}-business`}/>
};