import * as actionTypes from '../actions/alertActions.js';

const initialState = {
    message: '',
    level: '', // status here can be - success -  warning - error,
};

const alertsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ALERT_TOGGLE:
            return {
                ...state,
                message: action.message,
                level: action.level,
            };
        default:
            return state;
    }
};

export default alertsReducer;
