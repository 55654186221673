import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useParams} from 'react-router-dom';
import Spinner from '../../Commons/Spinner';
import Card from '../../Commons/Card';
import CarHeading from "../../Components/Cars/CarHeading";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentCar} from "../../store/actions/carsActions";
import {httpGetCar} from "../../HttpRequests/commons.http.js";
import {errorHandler} from "../../helpers/utils.js";
import EconomicsSustainedCosts from "../../Components/Cars/EconomicsSustainedCosts";
import MarketValues from "../../Components/Cars/MarketValues";
import SellingPrice from "../../Components/Cars/SellingPrice";
import ActiveCampaigns from "../../Components/Cars/ActiveCampaigns";
import Warranty from "../../Components/Cars/Warranty";
import indicataLogo from "../../assets/images/indicata.jpg";
import {
    ActiveCampaignIcon,
    FiscalityIcon,
    StatsIcon,
    WarrantyIcon
} from "../../assets/icons.js";
import Checkbox from "../../Commons/Checkbox.js";
import FiscalityForm from "../../Components/Barter/FiscalityForm.js";
import TabCard from "../../Commons/TabCard.js";

const useStyles = createUseStyles(theme => ({
    gridContainer: {
        position: 'relative',
        // with big/small subheader like permute page
        height: `calc(100vh - ${theme.carSubHeader}px)`,
        // without subheader
        //height: `calc(100vh - ${theme.headerHeight}px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        [theme.mUp]: {
            padding: [theme.spacing * 10, theme.spacing * 4, theme.spacing * 4]
        },
        [theme.s]: {
            padding: [theme.spacing],
        },
    },
    gridContent: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        paddingBottom: 100,
        gridColumnGap: theme.spacing * 2,
        gridRowGap: theme.spacing * 2,
        padding: 0
    },
    row: {
        display: "grid",
        position: "relative",
        fontSize: "12px",
        alignItems: "center",
        gridTemplateColumns: "1fr auto",
        gridColumnGap: theme.spacing,
        padding: [10, theme.spacing * 2],
    },
    cardRoot: {
        margin: 0,
    },
    card: {
        display: 'grid',
        textAlign: 'center',
        padding: [theme.spacing * 3, theme.spacing],
        // gridRowGap: theme.spacing,
        height: 130,
        '& span': {
            fontWeight: 600,
            fontSize: 24,
        },
        '& img': {
            maxWidth: '60px',
            position: 'relative',
            margin: [0, 'auto'],
        },
        '& p': {
            fontSize: 12,
        },
    },
    priceContainer: {
        display: 'grid',
        gridTemplateColumns: '2fr auto',
        padding: theme.spacing,
        '& div': {
            alignSelf: 'center',
            fontWeight: 'bold',
            alignItems: 'center'
        },
        '& div>span': {
            fontSize: 24
        }
    },
    box1: {gridArea: "1 / 1 / 3 / 7"},
    box2: {gridArea: "1 / 7 / 3 / 13"},
    box3: {gridArea: "3 / 1 / 4/ 4"},
    box4: {gridArea: "3 / 4 / 4 / 7"},
    box5: {gridArea: "3 / 7 / 4 / 10"},
    box6: {gridArea: "3 / 10 / 4 / 13"},
    box7: {gridArea: "4 / 1 / 5 / 13", paddingBottom: theme.spacing * 3},
    box8: {gridArea: "7 / 1 / 12 / 9"},
    box9: {gridArea: "7 / 9 / 8 / 13"},
    box10: {gridArea: "8 / 9 / 10 / 13"},

    footer: {
        bottom: 0,
        height: 80,
        left: 0,
        right: 0,
        display: 'grid',
        position: "fixed",
        background: theme.colors.white,
        alignItems: "center",
        justifyContent: "end",
        padding: [0, theme.spacing * 3],
        gridColumnGap: theme.spacing * 2,
        gridTemplateColumns: "1fr 172px 172px 172px",
        '& a': {
            padding:  [theme.spacing, theme.spacing * 2],
            backgroundColor: theme.colors.black,
            borderRadius: 12,
            color: theme.colors.white,
            textDecoration: "none",
            fontSize: 14,
            textAlign: 'center',
            textTransform: "uppercase",
        },
    },
    warrantyHeading:{
        display: "grid",
        gridColumnGap: "16px",
        gridTemplateColumns: "1fr auto",
        alignItems: "center",
        '& label': {
            '& span': {
               fontWeight:400
            }
        }
    }
}));

const CarEconomics = () => {
    const {id} = useParams();
    const currentCar = useSelector(state => state.cars.currentCar);
    const classes = useStyles({bigSubHeader: false});
    const [loading, setLoading] = useState(false);
    /*const [showPopover, setShowPopover] = useState(false);*/
    const dispatch = useDispatch();
    const activeCampaign = currentCar?.active_campaign;

    const getCarData = async () => {
        setLoading(true);
        try {
            const {data: car} = await httpGetCar(id);
            dispatch(setCurrentCar(car));
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (+currentCar?.id !== +id) {
            //if the requested car is different from the stored one
            dispatch(setCurrentCar(null));
            getCarData();
        }
    }, []);

    return <>
        {loading && <Spinner overlayFullscreen={true}/>}
        <CarHeading headingTitle={'Dati economici'} car={currentCar}/>

        {/*{showPopover && <Popover title={'prezzo di listino'} withHeader={true} onClose={() => setShowPopover(!showPopover)}>
            <AddPriceDocumentModal onClose={() => setShowPopover(!showPopover)} attachment={null}/>
        </Popover>}*/}

        {currentCar && <section className={classes.gridContainer}>
            <div className={classes.gridContent}>
                <div className={classes.box1}>
                    <Card rootCustomClass={classes.cardRoot} cardTitle={"Costi sostenuti"}
                          cardIcon={<StatsIcon data-color/>}>
                        <EconomicsSustainedCosts car={currentCar}/>
                    </Card>
                </div>
                <div className={classes.box2}>
                    <Card rootCustomClass={classes.cardRoot} cardTitle={"Valori di mercato"}
                          cardIcon={<StatsIcon data-color/>} cardSpacing={0}>
                        <MarketValues car={currentCar}/>
                    </Card>
                </div>
                <div className={classes.box3}>
                    <Card rootCustomClass={classes.cardRoot} bodyCustomClass={classes.card}>
                        <span> {currentCar?.days_in_stock ? currentCar.days_in_stock : '-'}  </span>
                        <p>Giorni di giacenza</p>
                    </Card>
                </div>
                <div className={classes.box4}>
                    <Card rootCustomClass={classes.cardRoot} bodyCustomClass={classes.card}>
                        <span> {currentCar?.average_national_rotation ? currentCar.average_national_rotation : '-'} </span>
                        <img src={indicataLogo} alt={'indicata logo'}/>
                        <p>Indice di rotazione</p>
                    </Card>
                </div>
                <div className={classes.box5}>
                    <Card rootCustomClass={classes.cardRoot} bodyCustomClass={classes.card}>
                        <span> {currentCar?.same_model_amount ? currentCar.same_model_amount : '-'} </span>
                        <p>Presenti sul parco auto</p>
                    </Card>
                </div>
                <div className={classes.box6}>
                    <Card rootCustomClass={classes.cardRoot} bodyCustomClass={classes.card}>
                        <span> {currentCar?.average_stock_rotation ? currentCar.average_stock_rotation : '-'} </span>
                        <p>Giorni di giacenza delle auto in stock</p>
                    </Card>
                </div>
                <div className={classes.box7}>
                   {/* <Card rootCustomClass={classes.cardRoot} bodyCustomClass={classes.priceContainer}>
                        <div><span>€</span> Prezzo di listino Km0</div>
                        <div className={classes.row}>
                           <a href={'link'}> Document link clickable </a>
                            <Button data-secondary data-small style={{height: 24}} onClick={() => setShowPopover(!showPopover)}>Carica documento</Button>
                        </div>
                    </Card>*/}
                    {currentCar?.id && <TabCard cardTitle={'Fiscalità'} cardIcon={<FiscalityIcon data-color/>} cardTitleSize={'medium'}
                             showContent={true}>
                        <FiscalityForm car={currentCar} isNewCar={true} isStock={true}/>
                    </TabCard>}
                </div>

                <div className={classes.box8}>
                    <Card rootCustomClass={classes.cardRoot} cardTitle={"Prezzo di vendita"}
                          cardIcon={<StatsIcon data-color/>}>
                        <SellingPrice car={currentCar} activeCampaign={activeCampaign} setLoading={setLoading}/>
                    </Card>
                </div>
                <div className={classes.box9}>
                    <Card rootCustomClass={classes.cardRoot} cardTitle={"Campagne attive"}
                          cardIcon={<ActiveCampaignIcon data-color/>}>
                        {!!activeCampaign ? <ActiveCampaigns car={currentCar} activeCampaign={activeCampaign}/> :
                            <p> Non ci sono campagne attive su questo veicolo. </p>}
                    </Card>
                </div>
                <div className={classes.box10}>
                    <Card rootCustomClass={classes.cardRoot} cardIcon={<WarrantyIcon data-color/>}
                          cardTitle={<p className={classes.warrantyHeading}>
                              <span>Garanzia</span>
                              {currentCar?.confgest_activability ? <Checkbox isSmall={true} disabled={true} label={"Eligibile ConformGest"} checked={true}/> : <span/>}
                          </p>
                          }>
                        <Warranty car={currentCar}/>
                    </Card>
                </div>
            </div>
        </section>}
        <div className={classes.footer}>
            <span/>
            <a href={`${process.env.REACT_APP_ENDPOINT}/cars/${id}/withdrawal_form`} target={'_black'}>
                Atto di ritiro
            </a>
            <a href={`${process.env.REACT_APP_ENDPOINT}/cars/${id}/delivery_form`} target={'_black'}>
                Atto di consegna
            </a>
            <a href={`${process.env.REACT_APP_ENDPOINT}/cars/${id}/playbill`} target={'_black'}>
                Stampa locandina
            </a>
        </div>
    </>

};


export default CarEconomics
