import React, {useState, useEffect} from 'react';
import {createUseStyles, useTheme} from 'react-jss';
import {useDispatch} from 'react-redux';
import {useDropzone} from 'react-dropzone';
import Button from "../../Commons/Button";
import {httpDeletePhotoset, httpUpdatePhotoset} from "../../HttpRequests/stock.http";
import {httpGetSignedS3Url, httpPostS3} from "../../HttpRequests/commons.http";
import {alertToggle} from "../../store/actions/alertActions";
import {uuid, errorHandler, isCarProfilePicDeleted} from "../../helpers/utils.js";
import Spinner from "../../Commons/Spinner";
import {CancelIcon, EditIcon, FavoriteIcon, RemoveIcon} from "../../assets/icons";
import Select from "react-select";
import {
	addPhoto,
	deletePhoto,
	deletePhotoset, resetCarProfilePic,
	setCurrentCar, setFavoritePhoto,
	updatePhoto,
	updatePhotoset
} from "../../store/actions/carsActions";
import EditableInput from "../../Commons/EditableInput";
import {bytesToSize} from "../../helpers/utils";
import CircularProgress from "../../Commons/CircularProgress";
import Checkbox from "../../Commons/Checkbox";
import Popover from "../../Commons/Popover";
import DeletePopover from "../../Commons/DeletePopover";
import cx from 'classnames';

const useStyles = createUseStyles(theme => ({
	root: {
		position: 'relative',
		'display': 'grid',
		'grid-auto-flow': 'row',
		'grid-row-gap': theme.spacing * 3,
		'grid-column-gap': theme.spacing * 3,
		[theme.s]: {
			width: 320,
			gridTemplateColumns: '1fr',
			justifyContent: "center",
		},
		[theme.mUp]: {
			'width': '100%',
			gridTemplateColumns: '1fr',
			justifyContent: "center",
		},
	},
	cardBaseStyles: {
		position: 'grid',
		gridTemplateColumns: '1fr',
		gridTemplateRows: 'repeat(2, 1fr)',
		boxShadow: "rgba(23, 29, 41, 0.08) 0px 0px 12px 0px",
		background: theme.colors.white,
		margin: `${theme.spacing * 2}px 0`,
		borderRadius: 16,
		boxSizing: 'border-box',
		verticalAlign: 'middle'
	},
	cardHeader: {
		display: 'grid',
		gridAutoFlow: 'column',
		gridAutoColumns: `fit-content(${theme.spacing * 50}px) 88px 1fr `,
		gridColumnGap: theme.spacing * 2,
		justifyItems: 'end',
		textAlign: 'left',
		width: '100%',
		borderBottom: `1px solid ${theme.colors.lightGrey}`,
		padding: [theme.spacing * 2],
		fontWeight: 'bold',
		alignItems: 'center',
		fontSize:  18
	},
	notesContainer: {
		height: '100%',
		width: '100%',
		'& span': {
			justifyItems: 'start',
			width: '100%',
			textAlign: 'left',
			position: 'relative',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
		'& input': {
			width: '100%',
		},
		'& div': {
			height: '100%',
			display: 'grid',
			textAlign: 'center',
			alignItems: 'center',
			justifyItems: 'stretch'
		}
	},
	photosetActions: {
		display: 'grid',
		gridAutoFlow: 'column',
		gridColumnGap: theme.spacing,
		justifySelf: 'start',
		'& .remove':{
			fill: theme.colors.red
		},
		'& button': {
			padding: 0,
			height: theme.spacing * 4,
			borderRadius: theme.spacing,
			'&.edit':{
				backgroundColor: theme.colors.yellow
			},
			'&.remove':{
				backgroundColor: theme.colors.red
			}
		}
	},
	cardBaseContent: {
		padding: theme.spacing * 2,
		gridArea: '2 / 1 / 3 / 2',
		verticalAlign: 'middle'
	},
	cardFooter: {
		display: 'grid',
		justifyContent: 'end',
		padding: [theme.spacing * 2],
		borderTop: `1px solid ${theme.colors.lightGrey}`
	},
	photoCards: {
		position: 'relative',
		'display': 'grid',
		'grid-auto-flow': 'row',
		'grid-column-gap': theme.spacing * 2,
		gridTemplateColumns: 'repeat(auto-fill, minmax(160px, 1fr));',
		justifyContent: "center",
	},
	photoThumbnails: {
		position: 'relative',
		'display': 'grid',
		'grid-auto-flow': 'column',
		'grid-column-gap': theme.spacing,
		/*gridTemplateColumns: 'repeat(12,1fr)',*/
		justifyContent: 'start',
		/*gridTemplateColumns: 'min-content'*/
	},
	uploadBox: {

	},
	uploadArea: {
		position: 'relative',
		display: 'grid',
		backgroundColor: 'transparent',
		borderRadius: '16px',
		border: '1px dashed #000',
		minHeight: '160px',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		padding: '8px',
		alignItems: 'center',
		justifyItems: 'center'
	},
	uploadStatus: {
		display: 'grid',
		justifyItems: 'center',
		gridRowGap: theme.spacing
	}
}));

// Remove photoTypes not included for barter
export const exclude360Photos = (options) => {
	return options.filter((item) => !item.label.includes('360'))
};

const PhotosetManager = ({car, initialPhotoset, photoTypes, readOnly = false}) => {

	const classes = useStyles();
	const dispatch = useDispatch();
	const [photos, setPhotos] = useState(initialPhotoset.photos);
	const [loading, setLoading] = useState(false);
	const [editEnabled, setEditEnabled] = useState(false)
	const [availablePhotoTypes, setAvailablePhotoTypes] = useState([]);
	const [showDelete, setShowDelete] = useState(false)

	const { id: photosetId, default: isDefault, notes, modified = false, photos: initialPhotos, isNew = false} = initialPhotoset;

	useEffect(() => {
		const photosWithName = initialPhotoset.photos.map( photo => {
			return {
				...photo,
				name: photo.name ?? uuid()
			}
		})
		// update current photos
		dispatch(updatePhotoset({...initialPhotoset, photos: photosWithName}, !modified))
	}, [])

	useEffect(() => {
		// update current photos
		setPhotos(initialPhotos)
		setEditEnabled(isNew || modified)
		setAvailablePhotoTypes(photoTypes.filter(type => !initialPhotos.find(photo => photo.type === type.value)))
	}, [initialPhotoset])

	// Dropzone config
	const MAX_SIZE = 8388608 // 8MB

	const onDrop = (acceptedFiles, rejectedFiles) => {
		// for every file, create temp photo with name and preview
		acceptedFiles.map(file => {
			setPhotos( photos => [
				...photos,
				{
					preview: URL.createObjectURL(file),
					photoset_id: photosetId,
					name: uuid(),
					file
				}
			])
		})
	}

	const {getRootProps, getInputProps, isDragActive, isDragReject, open} = useDropzone({
		accept: 'image/*',
		noClick: true,
		minSize: 0,
		MAX_SIZE,
		onDrop
	});

	const savePhotoset = async () => {
		// Check photo integrity
		let missingType = false;
		const photosRemappedForBe = photos.map(photo => {
			if(!photo.type){
				missingType = true;
				return null;
			}
			return {url: photo.url, type: photo.type, default: photo.default }
		})
		// If at least one photo has no type, return
		if(missingType) {
			dispatch(alertToggle('Assegna una posizione ad ogni foto prima di salvare', 'error'));
			return;
		}
		// Set photoset for BE
		const cleanedPhotoset = {
			...initialPhotoset,
			photos: photosRemappedForBe
		}
		setLoading(true)
		// photoset is created when you add it
		try{
			// update photoset
			const {data: updatedPhotoset} = await httpUpdatePhotoset(car.id, cleanedPhotoset.id, cleanedPhotoset)
			// add name to photos
			const photosWithName = updatedPhotoset.photos.map( photo => ({
				...photo,
				name: photo.name ?? uuid()
			}))
			console.log(updatedPhotoset)
			//if the car profile pic was removed remove it from the store
			if(!isCarProfilePicDeleted(updatedPhotoset?.photos,car?.profilePic)){
				dispatch(resetCarProfilePic())
			}
			// Update store
			dispatch(updatePhotoset({...updatedPhotoset, photos: photosWithName}, true));
			dispatch(alertToggle('Photoset salvato con successo', 'success'));

		}catch (e){
			errorHandler(e,dispatch)
		}finally {
			setLoading(false)
		}
	}

	const removePhotoset = async () => {
		try {
			setLoading(true)
			await httpDeletePhotoset(car.id, photosetId)
			dispatch(deletePhotoset(photosetId))
			const currentPhotoset = car?.photosets?.filter( photoset => photosetId === photoset.id)
			console.log(currentPhotoset)
			if(isCarProfilePicDeleted(currentPhotoset[0]?.photos,car?.profilePic)){
				dispatch(resetCarProfilePic())
			}
			dispatch(alertToggle('Photoset rimosso con successo', 'success'));
		}catch (e){
			errorHandler(e,dispatch)
		}finally {
			setShowDelete(!showDelete);
			setLoading(false)
		}
	}

	return <div className={classes.cardBaseStyles}>
		<div className={classes.cardHeader}>
			{showDelete && <Popover onClose={()=>setShowDelete(!showDelete)} width={400}>
				<DeletePopover title={'Stai per eliminare il photoset. Sei sicuro di voler procedere?'}
							   deleteAction={() => removePhotoset()}
							   closePopover={()=>setShowDelete(!showDelete)}/>
			</Popover>}
			<div className={classes.notesContainer}>
				<EditableInput inputText={notes || ""}
							   placeholder={`Photoset ${photosetId}`}
							   disabled={!editEnabled}
							   callback={(item) => dispatch(updatePhotoset({
								   ...initialPhotoset,
								   notes: item.note
							   }))}/>
			</div>
			<div className={classes.photosetActions}>
				{!readOnly && (editEnabled ? <>
						{!modified && <CancelIcon onClick={() => { setEditEnabled(false)}}/>}
						<RemoveIcon data-color className={'remove'} onClick={()=>setShowDelete(!showDelete)}/>
					</> :
					<>
						<EditIcon data-color onClick={() => { setEditEnabled(true)}}/>
						<RemoveIcon data-color className={'remove'} onClick={()=>setShowDelete(!showDelete)}/>
					</>)
				}
			</div>
			<Checkbox label={'Imposta come predefinito'} isSmall={true} name={'default'}
					  checked={isDefault}
					  disabled={!editEnabled}
					  onChange={() => {
					  	!isDefault ?
						  dispatch(setCurrentCar({
							  ...car,
							  photosets: car.photosets.map(photoset => {
							  	return photoset.id !== photosetId ?
									{...photoset, default: 0} :
									{...initialPhotoset, default: 1, modified: true}
							  })
						  })) :
						  dispatch(updatePhotoset({...initialPhotoset, default: 0}))
					  }}/>

		</div>
		<div className={classes.cardBaseContent}>
			<div className={classes.root}>
				{loading && <Spinner overlayFullscreen={true}/>}
				{editEnabled ?
					<>
						<div {...getRootProps({className: 'dropzone'})} className={classes.uploadArea}>
							<input {...getInputProps()} />
							{!isDragActive &&
							<div className={classes.uploadStatus}>
								<p>Trascina qui per caricare</p>
								<small>oppure</small>
								<Button data-primary data-small onClick={open}>Seleziona file</Button>
								<p>Dimensione massima di caricamento file: {bytesToSize(MAX_SIZE)}</p>
							</div>}
							{isDragActive && !isDragReject && <p>Rilascia qui...</p>}
							{isDragReject && <small>File non accettato</small>}
						</div>
						{(photos?.length > 0) && <div className={classes.photoCards}>
							{photos?.map((photo, i) => <Photo key={i}
															  photo={photo}
															  readOnly={!editEnabled}
															  photoTypes={photoTypes}
															  availableSelectOptions={availablePhotoTypes}
							/>)}
						</div>}
					</> :
					<div className={classes.photoThumbnails}>
						{photos.map((photo, i) => <Photo key={i}
														 photo={photo}
														 photoTypes={photoTypes}
														 readOnly={!editEnabled} />)}
					</div>
				}
			</div>
		</div>
		{editEnabled && <div className={classes.cardFooter}>
			<Button width={200} disabled={!modified} type={'button'} data-secondary onClick={() => savePhotoset()}> Salva </Button>
		</div>}
	</div>

};

export default PhotosetManager;

const useStylesPhoto = createUseStyles(theme => ({
	overlay: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		/*padding: theme.spacing * 4,*/
		borderRadius: "16px",
		zIndex: 1,
		backgroundColor: theme.colors.overlay,
		display: 'grid',
		alignItems: 'center',
		'& div': {
			textAlign: 'center',
			'& span':{
				padding: [theme.spacing, theme.spacing * 2],
				'&:not(:last-child)': {
					borderRight: '1px solid #fff'
				}
			}
		}
	},
	icon: {
		'&.favorite': {
			fill: ({favorite}) => `${favorite ? theme.colors.red : theme.colors.white}`,
		},
		fill: theme.colors.white,
		'&:hover': {
			animation: '$zoominout .5s',
			cursor: 'pointer'
		}
	},
	photoContainer: {
		display: 'grid',
		gridTemplateRows: '0.8fr 0.2fr',
	},
	thumbnail: {
		position: 'relative',
		borderRadius: "16px",
		overflow: 'hidden',
		height: '92px',
		/*minWidth: '100px',*/
		maxWidth: '150px',
		width: '100%'
	},
	photoBox: {
		position: 'relative',
		height: '172px',
		/*minWidth: '184px',*/
		/*maxWidth: '240px',*/
		width: '100%',
	},
	photoArea: {
		position: 'relative',
		overflow: 'hidden',
		display: 'grid',
		backgroundColor: theme.colors.lightGrey,
		borderRadius: "16px",
		width: '100%',
		height: '100%',
		alignItems: 'center',
		justifyItems: 'center',
	},
	photoActions: {
		margin: `${theme.spacing * 2}px 0`,
	},
	status: {
		width: '100%',
		position: 'absolute',
		padding: '8px',
		'& :first-child': {
			margin: '0 auto',
			zIndex: 1
		}
	},
	photo: {
		display: 'block',
		height: '100%',
		width: '100%',
		objectFit: 'cover',
	},
	preview: {
		opacity: '0.5',
		height: '100%',
		display: 'block',
		maxWidth: '100%',
		objectFit: 'cover',
	},
	'@keyframes zoominout': {
		'0%': {transform: 'scale(1,1)'},
		'50%': {transform: 'scale(1.2,1.2)'},
		'100%': {transform: 'scale(1,1)'}
	},
}));

const Photo = ({ readOnly, photo, availableSelectOptions, photoTypes}) => {

	const SIGNED_URL_TYPE = 'photo'
	const theme = useTheme();
	const classes = useStylesPhoto({favorite: photo?.default, theme});
	const dispatch = useDispatch();
	const [showOverlay, setShowOverlay] = useState(false);
	const [progress, setProgress] = useState(0);
	const [inUpload, setInUpload] = useState(false);
	const [failure, setFailure] = useState(false);
	const [showPopover, setShowPopover] = useState(false)

	useEffect(() => {
		// Upload new dropped photo
		if(!photo.url) handleUpload(photo.file);

	}, [photo])

	const handleUpload = async (file) => {
		setInUpload(true);
		try {
			const {data: { attributes : { action, enctype }, inputs}} = await httpGetSignedS3Url(file.name, SIGNED_URL_TYPE);
			// set header
			const options = {
				headers: {
					'Content-Type': enctype,
				},
				onUploadProgress: progressEvent => {
					const status = parseInt((progressEvent.loaded / progressEvent.total) * 100);
					if (status >= 1) setProgress(status)
				}
			};
			// Append inputs to form
			let formData = new FormData();
			for (const key in inputs) {
				formData.append(key, inputs[key]);
			}
			formData.append('file', file);
			const postS3 = await httpPostS3(action, formData, options);
			dispatch(alertToggle('Foto caricata con successo','success'))

			// Remove temp url
			URL.revokeObjectURL(file)

			dispatch(addPhoto({
				name: photo.name,
				url: `${action}/${inputs.key}`,
				photoset_id: photo?.photoset_id
			}))
			setFailure(false)
		} catch(e) {
			dispatch(alertToggle(e.message,'error'))
			setFailure(true)
		}finally {
			setInUpload(false);
		}
	}

	// Handle hover event
	const handleMouseHover = (show = false) => {
		setShowOverlay(show);
	}

	const setFavorite = () =>{
		if(photo?.default){
			dispatch(alertToggle(`Foto già impostata come preferita!`, 'warning'));
		}else{
			dispatch(setFavoritePhoto(photo));
			dispatch(alertToggle(`Foto impostata come preferita!`, 'success'));
		}
	}

	return <div className={classes.photoContainer}>
		{readOnly ?
			<div className={classes.thumbnail}>
				{showPopover && <Popover onClose={() => setShowPopover(false)}
				withHeader title={photoTypes.find(type => photo.type === type.value)?.label || 'Non assegnato'}>
					<img className={classes.photo}
						 src={photo.url}
						 alt={photo.name}/>
				</Popover>}
				<img className={classes.photo}
					 style={{cursor: 'pointer'}}
					 src={photo.url}
					 alt={photo.name}
					onClick={() => setShowPopover(true) }/>
			</div>
			:
			<>
				<div className={classes.photoBox}
					 onMouseEnter={() => handleMouseHover(true)}
					 onMouseLeave={() => handleMouseHover(false)}>
					{showOverlay && !failure && !inUpload && <div className={classes.overlay}>
						<div>
							<span>
								<FavoriteIcon data-color className={cx(classes.icon, 'favorite')} onClick={setFavorite}/>
							</span>
							<span>
								<RemoveIcon data-color className={classes.icon} onClick={() => {
									dispatch(deletePhoto(photo));
									dispatch(alertToggle(`Foto eliminata con successo.`, 'success'));
								}}/>
							</span>
						</div>
					</div>}
					<div className={classes.photoArea}>
						{inUpload && <div className={classes.status}>
							<CircularProgress size={80} value={progress} valuePosition={'center'}/>
						</div>}
						{failure && !inUpload && <div className={classes.status}>
							<Button data-primary data-small onClick={() => handleUpload(photo.file)}>Riprova</Button>
						</div>}
						<img className={photo.preview ? classes.preview : classes.photo} src={photo.preview ?? photo.url} alt={photo.name}/>
					</div>
				</div>
				{!inUpload && !failure && <div className={classes.photoActions}>
					<Select
						placeholder={'Assegna posizione'}
						options={availableSelectOptions}
						classNamePrefix={'select'}
						menuPlacement={'auto'}
						styles={theme.reactSelectsStyle}
						onChange={(item) => dispatch(updatePhoto({
								...photo,
								type: item.value
						}))}
						value={photoTypes.filter(type => type.value === photo.type)}
					/>
				</div>}
			</>
		}
	</div>
}