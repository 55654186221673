import React, {useCallback, useState} from 'react';
import {createUseStyles} from "react-jss";
import {useForm} from "react-hook-form";
import Text from "../../Commons/Text.js";
import Button from "../../Commons/Button.js";
import * as Yup from "yup";
import {httpGetSignedS3Url, httpPostS3} from "../../HttpRequests/commons.http.js";
import {alertToggle} from "../../store/actions/alertActions.js";
import {useDropzone} from "react-dropzone";
import {useDispatch} from "react-redux";
import {EditIcon} from "../../assets/icons.js";
import Spinner from "../../Commons/Spinner.js";
import {
    httpEditRepairDocumentExternal,
    httpSaveRepairDocumentExternal
} from "../../HttpRequests/reconditioning.http.js";
import {errorHandler} from "../../helpers/utils.js";

const useStyles = createUseStyles(theme => ({
    root: {
        display: 'grid',
        width: '100%',
        gridRowGap: theme.spacing * 3,
        justifyItems: 'center',
    },
    title: {
        fontWeight: 600,
        margin: [theme.spacing * 2, 0]
    },
    btns: {
        width: "300px",
        display: "grid",
        alignItems: "center",
        gridColumnGap: theme.spacing,
        gridTemplateColumns: "1fr 1fr",
        marginTop: theme.spacing * 3
    },
    uploadArea: {
        position: 'relative',
        padding: theme.spacing * 3,
        border: `1px dotted ${theme.colors.smokeWhite}`,
        width: "100%",
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        gridRowGap: theme.spacing
    },
    doc: {
        display: "grid",
        alignItems: "center",
        gridTemplateColumns: '1fr auto',
        gridColumnGap: 4,
        '& a': {
            textDecoration: 'none'
        },
        '& svg': {
            width: '18px!important'
        }
    }
}));

const validationSchema = Yup.object().shape({
    invoice_amount: Yup.number().integer('Sono accettati solo interi.')
        .typeError('Inserisci l\'importo.')
        .min(1,'Inserisci un valore maggiore di 0')
        .transform((value, originalValue) => originalValue.trim() === "" ? null: value)
        .required(`Inserisci l'importo.`)

});

const ExternalUploadDocument = ({onCloseCb,onSuccessCb, repair, carId, doc}) => {
    const MAX_SIZE = 1048576;
    const SIGNED_URL_TYPE = 'document';
    const classes = useStyles();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [inUpload, setInUpload] = useState(false);
    const [document, setDocument] = useState();
    const {register, handleSubmit, formState, errors} = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        nativeValidation: false,
        validationSchema
    });

    // carica solo su aws
    const handleUpload = async (file) => {
        setInUpload(true);
        try {
            const {
                data: {
                    attributes: {action, enctype},
                    inputs
                }
            } = await httpGetSignedS3Url(file.name, SIGNED_URL_TYPE);
            // set header
            const options = {
                headers: {
                    'Content-Type': enctype,
                },
                onUploadProgress: progressEvent => {
                    //todo add progressbar
                    /*console.log(progressEvent.loaded / file.size * 100)
                     const status = parseInt((progressEvent.loaded / progressEvent.total) * 100);
                     if (status > 1) {
                         setProgress(parseInt((progressEvent.loaded / progressEvent.total) * 100))
                     }*/
                }
            };
            // Append inputs to form
            let formData = new FormData();
            for (const key in inputs) {
                formData.append(key, inputs[key]);
            }
            formData.append('file', file);
            await httpPostS3(action, formData, options);
            // set updatedDocument to store and component state
            const updatedDocument = `${action}/${inputs.key}`;
            //update doc in list
            setDocument({
                url: updatedDocument,
                type: 'supplier_invoice',
                filename: file.name,
                repair_id: repair?.id,
                attachment_id: repair?.attachments?.filter(attach => attach.type === 'supplier_invoice')[0]?.id
            })
        } catch (e) {
            dispatch(alertToggle(e.message, 'error'))
        } finally {
            setInUpload(false);
        }
    };

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        // upload new document on AWS
        acceptedFiles.map(file => {
            const uploadResult = handleUpload(file);
        })
    }, [doc]);

    const {getRootProps, getInputProps, open} = useDropzone({
        accept: '/image* , .pdf',
        noClick: true,
        minSize: 0,
        multiple: false,
        MAX_SIZE,
        onDrop
    });

    const onSubmit = async (values) => {
        setLoading(true);
        const dataToSave = {...document, invoice_amount:values?.invoice_amount}
         try {
             document?.attachment_id ? await httpEditRepairDocumentExternal(carId, dataToSave) : await httpSaveRepairDocumentExternal(carId, dataToSave);
             onSuccessCb();
             onCloseCb();
             dispatch(alertToggle(`Documento caricato con successo`, 'success'));
         } catch (e) {
             errorHandler(e, dispatch)
         }finally {
             setLoading(false);
         }
    };

    return <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        {loading && <Spinner overlayFullscreen={true}/>}
        <div {...getRootProps({className: 'dropzone'})} className={classes.uploadArea}>
            <input {...getInputProps()} />
            {(!inUpload && !document) && <Button data-primary data-small onClick={open}>Carica Documento</Button>}
            {inUpload && <Spinner size={14}/>}
            {((document) && !inUpload) &&
            <div className={classes.doc}>
                <a href={document?.url} target={'_blank'}>{document.filename}</a>
                <EditIcon data-color onClick={open}/>
            </div>}
        </div>

        <Text type={'number'} label={'Totale*'} name={'invoice_amount'} ref={register} errors={errors.invoice_amount}/>
        <div className={classes.btns}>
            <Button type="reset" onClick={onCloseCb} data-secondary data-small>Annulla</Button>
            <Button type={'submit'} data-primary data-small
                    disabled={(!formState.isValid || formState.isSubmitting) && !document}>Salva</Button>
        </div>
    </form>
};

export default ExternalUploadDocument;