import React, {useCallback, useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useDispatch} from 'react-redux';
import {useDropzone} from 'react-dropzone';
import ProgressBar from "../../../Commons/ProgressBar";
import {httpGetSignedS3Url, httpPostS3} from "../../../HttpRequests/commons.http";
import {alertToggle} from "../../../store/actions/alertActions";
import PropTypes from "prop-types";
import {EditIcon} from "../../../assets/icons";
import {BusinessIcon} from "../../../assets/icons.js";

const useStyles = createUseStyles(theme => ({
    root: {
        position: 'relative',
        'display': 'grid',
        'grid-auto-flow': 'row',
        'grid-row-gap': theme.spacing * 3,
        'grid-column-gap': theme.spacing * 3,
        [theme.s]: {
            width: 320,
            gridTemplateColumns: 'repeat(3,1fr)',
            justifyContent: "center",
        },
        [theme.mUp]: {
            'width': '100%',
            gridTemplateColumns: 'repeat(6,1fr)',
            justifyContent: "center",
        },
    },
    icon: {
        width: `64px !important`,
        cursor: 'default !important'
    },
    avatar: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    uploadBox: {
        position: 'relative'
    },
    uploadArea: {
        position: 'relative',
        overflow: 'hidden',
        display: 'grid',
        backgroundColor: theme.colors.lightGrey,
        borderRadius: "50%",
        width: "160px",
        height: "160px",
        alignItems: 'center',
        justifyItems: 'center',
        margin: theme.spacing * 2,
        border: `1px solid ${theme.colors.lightGrey}`
    },
    status: {
        padding: '0 4px',
        width: '140px'
    },
    contextMenu: {
        position: 'absolute',
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
        borderRadius: '50%',
        top: '32px',
        right: '12px',
        width: '36px',
        height: '36px',
        background: theme.colors.smokeWhite,
        boxShadow: "rgba(23, 29, 41, 0.08) -4px 4px 14px 0px",
    }
}));

const BusinessProfileImagesUpload = ({businessPhoto, setPhotoLink}) => {
    const MAX_SIZE = 1048576;
    const SIGNED_URL_TYPE = 'avatar';
    const classes = useStyles();
    const dispatch = useDispatch();
    const [progress, setProgress] = useState(0);
    const [inUpload, setInUpload] = useState(false);
    const [photo, setPhoto] = useState(businessPhoto);

    useEffect(() => {
        if(businessPhoto) setPhoto(businessPhoto)
        }, [ businessPhoto ]
    );

    // carica solo su aws
    const handleUpload = async (file) => {
        setInUpload(true);
        try {
            const {data: {attributes: {action, enctype}, inputs}} = await httpGetSignedS3Url(file.name, SIGNED_URL_TYPE);
            // set header
            const options = {
                headers: {
                    'Content-Type': enctype,
                },
                onUploadProgress: progressEvent => {
                     const status = parseInt((progressEvent.loaded / progressEvent.total) * 100);
                     if (status > 1) {
                         setProgress(parseInt((progressEvent.loaded / progressEvent.total) * 100))
                     }
                }
            };
            // Append inputs to form
            let formData = new FormData();
            for (const key in inputs) {
                formData.append(key, inputs[key]);
            }
            formData.append('file', file);
            await httpPostS3(action, formData, options);
            dispatch(alertToggle('Foto caricata con successo', 'success'))
            // set updatedPhoto to store and component state
            // with only type and url, photos will be created then with "save as draft"
            const updatedPhoto = `${action}/${inputs.key}`;
            setPhoto(updatedPhoto);
            setPhotoLink(updatedPhoto)
        } catch (e) {
            dispatch(alertToggle(e.message, 'error'))
        } finally {
            setInUpload(false);
        }
    };

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        acceptedFiles.map(file => {
            // upload new photo on AWS
            const uploadResult = handleUpload(file);
        })
    }, [photo]);

    const {getRootProps, getInputProps, isDragActive, isDragReject, open} = useDropzone({
        accept: 'image/*',
        noClick: true,
        minSize: 0,
        multiple: false,
        MAX_SIZE,
        onDrop
    });

    return <div className={classes.uploadBox}>
        <div className={classes.contextMenu} onClick={open}>
            <EditIcon/>
        </div>
        <div {...getRootProps({className: 'dropzone'})} className={classes.uploadArea}>
            {inUpload && <div className={classes.status}>
                <ProgressBar small percentCompleted={progress} showPercent={false}/>
            </div>}
            <input {...getInputProps()} />
            {!isDragActive && !photo && !inUpload && <BusinessIcon className={classes.icon}/>}
            {isDragActive && !isDragReject && <span>Rilascia qui...</span>}
            {isDragReject && <small>File non accettato</small>}
            {photo && !inUpload && !isDragActive && <img className={classes.avatar} src={photo} alt={"avatar"}/>}
        </div>
    </div>
}


BusinessProfileImagesUpload.propTypes = {
    businessPhoto: PropTypes.object.isRequired,
};

export default BusinessProfileImagesUpload;