export const FOCUS_ON_CONVERSATION = 'FOCUS_ON_CONVERSATION';
export const RESET_CURRENT_CONVERSATION = 'RESET_CURRENT_CONVERSATION';
export const SHOW_MEMBERS = 'SHOW_MEMBERS';


export const focusOnConversation = target => {
    return {
        type: FOCUS_ON_CONVERSATION,
        payload: target
    };
};


export const resetCurrentConversation = () => {
    return {
        type: RESET_CURRENT_CONVERSATION,
        payload: ''
    };
};


export const showMembers = (show) => {
    return {
        type: SHOW_MEMBERS,
        payload: show
    };
};
