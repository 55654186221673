import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import Table from "../../../Commons/Table.js";
import {
    errorHandler,
    formattedDate,
    normalizeFiltersForBend,
    removeSpecifcKeyInObj, removeUnsettedKeyInObj,
    toMoney,
} from "../../../helpers/utils.js";
import {useHistory, useLocation} from 'react-router-dom';
import SubHeading from "../../../Commons/SubHeading.js";
import {useDispatch} from "react-redux";
import SimpleSearch from "../../../Commons/SimpleSearch.js";
import {setCars, setCarTasks, setCurrentCar} from "../../../store/actions/carsActions.js";
import Checkbox from "../../../Commons/Checkbox.js";
import qs from "query-string";
import {httpFetchStock, httpGetStockFilters} from "../../../HttpRequests/stock.http.js";
import Popover from "../../../Commons/Popover.js";
import AdvancedFilters from "../../../Components/Cars/AdvancedFilters.js";
import RepriceForm from "../../../Components/Stock/RepriceForm.js";
import RepricePopover from '../../../Components/Stock/RepricePopover.js';
import {httpRepriceCars} from '../../../HttpRequests/reprices.http.js';
import AdvancedFiltersController from "../../../Components/Cars/AdvancedFiltersController.js";
import cx from "classnames";

const useStyles = createUseStyles(theme => ({
    gridContainer: {
        'position': 'relative',
        // with big/small subheader like permute page
        height: ({bigSubHeader}) => `calc(100vh - ${bigSubHeader ? theme.bigSubHeader + theme.headerHeight : theme.smallSubHeader + theme.headerHeight}px)`,
        // without subheader
        //height: `calc(100vh - ${theme.headerHeight}px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        display: 'grid',
        gridTemplateColumns: '1fr',
        [theme.mUp]: {
            padding: [theme.spacing * 4],
        },
        [theme.s]: {
            padding: [theme.spacing],
        },
    },
    filters: {},
    table: {},
    tableHeader: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        gridColumnGap: theme.spacing,
        alignItems: 'center',
        marginBottom: theme.spacing * 2,
        height: 80,
        '& h3': {
            fontWeight: 600,
            lineHeight: 1
        },
        '& small': {
            color: theme.colors.darkGrey,
            fontSize: 16,
        }
    },
    dot: {
        width: 10,
        height: 10,
        borderRadius: '50%',
        marginRight: theme.spacing,
        '&:before': {
            content: '""'
        }
    },
    green: {
        backgroundColor: theme.colors.green,
    },
    yellow: {
        backgroundColor: theme.colors.yellow,
    }, red: {
        backgroundColor: theme.colors.red,
    },
}));

const RepriceList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles({bigSubHeader: false});
    const [stock, setStock] = useState([]);
    const [loading, setLoading] = useState(false);
    //table statuses
    const [total, setTotal] = useState(null);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(null);
    const [showPopover, setShowPopover] = useState(false);
    const [selectAllActive, setSelectAllActive] = useState(false);
    //filters things
    const [sorter, setSorter] = useState(null);
    const [filters, setFilters] = useState(location.search ? {
        ...qs.parse(location.search, {
            arrayFormat: 'comma', parseBooleans: true, skipNull: true, skipEmptyString: true
        })
    } : {});
    const [structure, setStructure] = useState(null);
    const [autocompletes, setAutocompletes] = useState(null);
    const [hideBooked, setHideBooked] = useState(false);
    //reprice variable
    const [repricePopover, setRepricePopover] = useState(false);
    const [repriceInfos, setRepriceInfos] = useState(null);

    //is reset is temporary to solve async update issue of filters
    const getStock = async (actualPage = 1, isReset = false, dataSorters = sorter) => {
        if (lastPage && actualPage > lastPage) return;
        if (actualPage === 1) {
            setStock([])
        }
        setLoading(true);

        try {
            const {data: {data, total, last_page}} = await httpFetchStock(actualPage, 20, dataSorters, isReset ? {} : filters);
            if (actualPage === 1) {
                setStock(data)
            } else {
                setStock((stock.concat(data)));
            }
            setTotal(total);
            setLastPage(last_page)
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    };

    const getFilters = async (actualFilters) => {
        if (actualFilters) {
            normalizeFiltersForBend(actualFilters);
        }
        try {
            const {data: {autocompletes, structure}} = await httpGetStockFilters(actualFilters);
            setStructure(structure);
            setAutocompletes(autocompletes);
        } catch (e) {
            errorHandler(e, dispatch)
        }
    };

    //fetch at the init
    useEffect(() => {
        //fetch Autocompletes
        getFilters();
        //clear currentCar if present to prevent flash fake data in the specific sections
        dispatch(setCurrentCar(null));
        dispatch(setCarTasks([]));
        //clean up on unmount
        return () => {
            dispatch(setCars([]))
        };
    }, []);

    //fetch when filters change
    useEffect(() => {
        setPage(1);
        setLastPage(null);
        getStock(1);
        history.replace({
            pathname: '/stock/reprice', search: qs.stringify({...filters}, {
                arrayFormat: 'comma',parseBooleans: true,  skipEmptyString: true, skipNull: true
            }),
        });
    }, [filters]);

    //fetch when page change
    useEffect(() => {
        if (!!stock.length && page !== 1) {
            getStock(page)
        }
    }, [page]);

    //clean up
    useEffect(() => {
        return () => {
            setStock([])
        };
    }, []);

    const onChangePage = (isFilter = false) => {
        isFilter ? setPage(1) : setPage(page + 1);
    };

    const onDataSorting = (newSort) => {
        const sorters = qs.stringify(newSort, {
            arrayFormat: 'comma', parseBooleans: true, skipEmptyString: true, skipNull: true
        })
        setSorter(sorters);
        setPage(1);
        getStock(1, false, sorters);
    };

    const onComplexSearch = (search) => {
        const newFilters = {...filters, ...search};
        removeUnsettedKeyInObj(newFilters);
        history.replace({
            pathname: '/stock/reprice', search: qs.stringify(newFilters, {
                arrayFormat: 'comma',
                skipEmptyString: true,
                skipNull: true
            }),
        });
        setFilters(newFilters);
    };

    const handleHideBooked = (e) => {
        setHideBooked(!hideBooked);
        history.replace({
            pathname: '/stock/reprice',
            search: qs.stringify({...filters, hide_booked: !hideBooked}, {
                arrayFormat: 'comma',
                skipEmptyString: true,
                skipNull: true
            }),
        });
        setFilters({...filters, hide_booked: !hideBooked});
    };

    const applyAdvancedFilters = (values) => {
        normalizeFiltersForBend(values);
        setFilters({...values});
        //add query params to route
        history.replace({
            pathname: '/stock/reprice', search: qs.stringify({...filters, ...values}, {
                arrayFormat: 'comma',
                skipEmptyString: true,
                skipNull: true
            }),
        });
        setShowPopover(!showPopover)
    };

    const resetAdvancedFilters = () => {
        //reset filters and sorters
        setFilters({});
        setSorter(null);
        setHideBooked(false);
        //clear query params from route
        history.replace({pathname: '/stock/reprice', search: null});
        getFilters({});
        getStock(1, true, null);
    };

    const removeSpecificFilter = (key) => {
        if (key === 'hide_booked') {
            handleHideBooked()
        }
        setFilters({...removeSpecifcKeyInObj(filters, key)});
        history.replace({
            pathname: '/stock/reprice', search: qs.stringify({...filters}, {
                arrayFormat: 'comma',
                skipEmptyString: true,
                skipNull: true,
            }),
        });
    };

    /**
     * Reprice Selections code
     * */
    const selectionIsNotEmpty = stock.some(item => item.selected);

    const selectAll = () => {
        setSelectAllActive(!selectAllActive);
        stock.forEach(item => item.selected = (!selectionIsNotEmpty));
        setStock([...stock]);
        //dispatch(setCars([...stock]));
    };

    const selectRow = ({id}) => event => {
        if (event.target.hasAttribute('data-onclick')) return;
        const item = stock.find(item => +item.id === +id);
        item.selected = !item.selected;
        setStock([...stock]);
    };

    const getAllSelectedItems = (values) => {
        let filteredItems = stock.filter(item => item.selected);
        if (values.currentType !== 'fixed') {
            filteredItems.map((item) =>
                item.reprice = (values.operation.value === 'add' ?
                    ((item.price * (+values.base_price) / 100) + item.price) :
                    (item.price - (item.price * (+values.base_price) / 100))));
        } else {
            filteredItems.map((item) =>
                item.reprice = (values.operation.value === 'add' ?
                    (item.reprice = item.price + (+values.base_price)) :
                    (item.reprice = item.price - (+values.base_price))));
        }
        //set reprice infos
        setRepriceInfos({
            type: values.currentType,
            amount: +values.base_price,
            operation: values.operation.value,
            cars: filteredItems,
        });
        //set confirmation popover
        setRepricePopover(!repricePopover);
    };

    const confirmReprice = async () => {
        setLoading(true);
        try {
            await httpRepriceCars({...repriceInfos, cars: repriceInfos.cars.map(item => item.id)});
            //maybe call get cars here or redux?
            setStock([]);
            await getStock()
        } catch (error) {
            dispatch(errorHandler, error)
        } finally {
            setLoading(false);
            //close popover
            setRepricePopover(!repricePopover);
            setRepriceInfos(null);
        }
    };

    return <>
        <SubHeading title={"Reprice"} type={'small'} subTitleData={`${!!total ? total : 0} risultati`}
                    rightSearchComponent={<SimpleSearch filters={filters} setPage={setPage}
                                                        onComplexSearchCb={onComplexSearch} name={'plate'}
                                                        placeholder={'cerca per targa'}/>}/>

        {showPopover &&
        <Popover withHeader={true} title={'Filtri'} minWidth={800} onClose={() => setShowPopover(!showPopover)}>
            <AdvancedFilters filters={filters}
                             structure={structure}
                             autocompletes={autocompletes}
                             onRecalculateAutocompletesCb={getFilters}
                             applyFilterCb={applyAdvancedFilters}
                             resetFilterCb={Function.prototype}/>
        </Popover>}
        {repricePopover && <Popover onClose={() => setRepricePopover(!repricePopover)} width={650}>
            <RepricePopover confirmReprice={confirmReprice} repriceInfos={repriceInfos} loading={loading}
                            deleteReprice={() => setRepricePopover(!repricePopover)}/>
        </Popover>}

        <section className={classes.gridContainer}>
            <div className={classes.table}>
                <div className={classes.tableHeader}>
                    <AdvancedFiltersController structure={structure} filters={filters}
                                               showFilterModalCb={() => setShowPopover(!showPopover)}
                                               handleHideBookedCb={handleHideBooked}
                                               resetAdvancedFiltersCb={resetAdvancedFilters}
                                               removeSpecificFilterCb={removeSpecificFilter}/>
                </div>
                <RepriceForm repriceCb={getAllSelectedItems} subtractOnly={false}
                             selectionIsNotEmpty={selectionIsNotEmpty}/>

                <Table
                    id={'table'}
                    data={stock}
                    inLoading={loading}
                    filters={filters}
                    onSetPageCb={onChangePage}
                    onSortingDataCb={onDataSorting}
                    theadHeight={48}
                    rowMinHeight={48}
                    classNames={{
                        table: classes.table,
                        thead: classes.thead,
                        row: classes.row,
                    }}
                    columns={['selected','brand', 'model', 'version', 'days_in_stock', 'plate', 'mileage','company', 'latest_reprice','price', 'average_market_value','price_deviation']}
                    columnsNames={{
                        selected: '',
                        brand: "Marca",
                        model: "Modello",
                        version: "Versione",
                        days_in_stock: 'Giacenza',
                        plate: "Targa",
                        mileage: 'Km',
                        company: "Azienda",
                        latest_reprice: "Ultimo reprice",
                        average_market_value: 'Mercato',
                        price: "Prezzo",
                        price_deviation: 'Scostamento',
                    }}
                    columnsWidths={{
                        selected: '2%',
                        brand: "9%",
                        model: "10%",
                        version: "10%",
                        days_in_stock: '7%',
                        plate: "7%",
                        mileage: '5%',
                        company: "12%",
                        latest_reprice:"10%",
                        average_market_value: '8%',
                        price: "8%",
                        price_deviation: '8%'
                    }}
                    columnsAlignments={{
                        actions: 'right',
                    }}
                    backEndSortable={true}
                    sortable={true}
                    headersSorters={['days_in_stock', "latest_reprice",'mileage','difference','price','price_deviation']}
                    headersRenderers={{
                        selected: <Checkbox className={classes.selected} data-onclick data-id={0} isSelectAll={true}
                                            checked={selectionIsNotEmpty} isSmall={true} onChange={selectAll}/>,
                    }}
                    columnsRenderers={{
                        selected: (value, {id}) => <Checkbox data-checkbox data-id={id} checked={value} isSmall={true}
                                                             onClick={selectRow({id})}/>,
                        brand: (value, item) => <strong>{item?.model?.brand?.name}</strong>,
                        model: (value) => <span>{value?.name}</span>, /*  */
                        days_in_stock: (item) => <span>
                                <span
                                    className={cx(classes.dot, item <= 25 ? classes.green : item <= 75 ? classes.yellow : classes.red)}/>
                            {item}
                            </span>,
                        plate: (value) => <span style={{textTransform:'uppercase'}}>{value}</span>,
                        mileage: (value) => <span>{value}</span>,
                        company:  (value, item) => <span>{item?.company?.name}</span>,
                        location: (value) => <span>{value}</span>,
                        latest_reprice: (value) => <span> {formattedDate(value)}</span>,
                        average_market_value: (value) => <strong>{toMoney(value)}</strong>,
                        price_deviation: (value) => <strong>{ value > 0 ? `+${toMoney(value)}` : toMoney(value)}</strong>,
                        price: (value) => <strong>{value ? toMoney(value) : '-'}</strong>,
                    }}
                />
            </div>
        </section>
    </>
};

export default RepriceList;
