import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useParams} from "react-router-dom";
import Spinner from "../../Commons/Spinner";
import ExternalCarHeading from "../../Components/Externals/ExternalCarHeading.js";
import {httpGetCar} from "../../HttpRequests/commons.http.js";
import {errorHandler} from "../../helpers/utils.js";
import {useDispatch, useSelector} from "react-redux";
import {httpGetCarRepairs} from "../../HttpRequests/reconditioning.http.js";
import {SetCarRepairs} from "../../store/actions/repairsActions.js";
import ExternalWorkTable from "../../Components/Externals/ExternalWorkTable.js";
import {setCurrentCar} from "../../store/actions/carsActions.js";

const useStyles = createUseStyles(theme => ({
	gridContainer: {
		'position': 'relative',
		height: `calc(100vh - 80px)`,
		overflowY: 'scroll',
		overflowX: 'hidden',
		[theme.mUp]: {
			padding: [theme.spacing * 4],
		},
		[theme.s]: {
			padding: [theme.spacing],
		},
	},
	title: {
		fontSize: 32,
		fontWeight: 600
	},
}));


const ExternalSuppliersWorkPage = () => {
	const classes = useStyles();
	const {id} = useParams();
	const dispatch = useDispatch();
	const user = useSelector(state => state.users.user);
	const car = useSelector(state => state.cars.currentCar);
	const [loading, setLoading] = useState(true);
	const tireCenter = useSelector(state => state.repairs.tireCenter);
	const bodyShop = useSelector(state => state.repairs.bodyShop);
	const workshop = useSelector(state => state.repairs.workshop);
	const workInfo = [...tireCenter,...bodyShop,...workshop].filter( repair => (repair?.assigned_user && repair?.assigned_user.id === user.id))
	//console.log(repairs.filter( repair => (repair?.assigned_user && repair?.assigned_user.id === user.id)));

	useEffect(() => {
		//fetch work info
		getCarInfo(id)
		getCarRepairs();
		return () => {
			dispatch(setCurrentCar(null));
		};
	}, []);

	const getCarRepairs = async () => {
		setLoading(true);
		try {
			const {data: repairs} = await httpGetCarRepairs(id);
			dispatch(SetCarRepairs(repairs));
		} catch (e) {
			errorHandler(e, dispatch)
		} finally {
			setLoading(false);
		}
	};

	const getCarInfo = async (id) => {
		setLoading(true);
		try {
			const {data: car} = await httpGetCar(id);
			dispatch(setCurrentCar(car));
		} catch (e) {
			errorHandler(e, dispatch)
		} finally {
			setLoading(false);
		}
	};

	return <>
		{loading && <Spinner overlayFullscreen={true}/>}
		<ExternalCarHeading backPath={'/external-workers'} car={car}/>
		<div className={classes.gridContainer}>
			<ExternalWorkTable data={workInfo} setDataCallback={getCarRepairs} title={""}/>
		</div>
	</>
};

export default ExternalSuppliersWorkPage;

