import axios from 'axios';

/**
 *
 * @param id
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpGetCarRepairs = (id) => {
    return axios.get(`/cars/${id}/repairs`);
};

/**
 *
 * @param id
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpGetCarEstimates = (id) => {
    return axios.get(`/cars/${id}/estimates`);
};

/**
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpCarReconditioning = (params) => {
    return axios.post(`/cars/reconditioning`, params);
};

/**
 *
 * @param id
 * @param repair_rows
 * @param user_id
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpCarAssignRepair = (id, repair_rows, user_id) => {
    return axios.post(`/cars/${id}/repairs/assign`,{user_id, repair_rows });
};

/**
 *
 * @param id
 * @param repair_rows
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpCarDiscardRepair = (id, repair_rows) => {
    return axios.post(`/cars/${id}/repairs/discard`,{repair_rows: repair_rows});
};

/**
 *
 * @param id
 * @param repair_rows
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpCarRestoreRepair = (id, repair_rows) => {
    return axios.post(`/cars/${id}/repairs/discard?undo=true`,{repair_rows: repair_rows});
};

/**
 *
 * @param id
 * @param repair
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpCarEditRepair = (id, repair) => {
    return axios.put(`/cars/${id}/repairs/${repair.id}`, {...repair});
};

/**
 *
 * @param id
 * @param repair_id
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpCarDeleteRepair = (id, repair_id) => {
    return axios.delete(`/cars/${id}/repairs/${repair_id}`);
};

/**
 * DEROGA
 * @param id
 * @param repair_id
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpAddRowsToRepair = (id, repair_id,data) => {
    return axios.post(`/cars/${id}/repairs/${repair_id}/rows`,{...data});
};

/**
 * @param id
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpChangeDelivery = (id, data) => {
    return axios.post(`/cars/${id}/repairs/assign`,{...data});
};

/**

 * @param id
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpSaveEstimateDocument = (id, data) => {
    return axios.post(`/cars/${id}/estimates/${data.id}/attachments`,{...data});
};

/**
 * @param id
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpUpdateEstimateDocument = (id, data) => {
    return axios.put(`/cars/${id}/estimates/${data.id}/attachments/${data.attachment_id}`,{...data});
};

/**
 * @param id
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpSaveRepairDocument = (id, data) => {
    return axios.post(`/cars/${id}/repairs/${data.id}/attachments`,{...data});
};

/**
 * @param id
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpUpdateRepairDocument = (id, data) => {
    return axios.put(`/cars/${id}/repairs/${data.id}/attachments/${data.attachment_id}`,{...data});
};

/**
 * @param dataToSend
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpGenerateSupplierDocument = (dataToSend) => {
    return axios.post(`/cars/${dataToSend.id}/repairs/${dataToSend.repair_id}/supplier_list`,{repair_rows: [...dataToSend.data]});
};

/**
 * get supplier attachment
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpGetSupplierDocuments = (filters) => {
    return axios.get(`/repairs/supplier_list`,{ params:{ ...filters } });
};

/**
 * @param id
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpSaveRepairDocumentExternal = (id, data) => {
    return axios.post(`/cars/${id}/repairs/${data.repair_id}/attachments`,{...data});
};

/**
 * @param id
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpEditRepairDocumentExternal = (id, data) => {
    return axios.put(`/cars/${id}/repairs/${data.repair_id}/attachments/${data.attachment_id}`,{...data});
};


/**
 * @param id
 * @param repair_rows
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpCarCreateEstimate = (repair_rows,id) => {
    return axios.post(`/cars/${id}/estimates`,{repair_rows: repair_rows});
};