import React from 'react';
import Button from '../../Commons/Button';
import { createUseStyles } from 'react-jss';
import { formattedDate, toMoney } from "../../helpers/utils.js";
import alertTriangle from '../../assets/images/alertTriangle.svg'
import Spinner from "../../Commons/Spinner.js";
const useStyles = createUseStyles(theme => ({
    gridContainer: {
        padding: theme.spacing * 2,
        '& img': {
            display: "block",
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        '& h4': {
            textAlign: "center",
            marginBottom: theme.spacing * 4,
            marginTop: theme.spacing * 4,
            'font-weight': 'bold'
        },
        '& p': {
            textAlign: "center",
            marginBottom: theme.spacing * 4
        }

    },
    buttons: {
        display: "grid",
        gridTemplateColumns: "200px 200px",
        gridColumnGap: theme.spacing * 2,
        justifyContent: 'center',
        marginTop: theme.spacing * 8
    },
    doubleRow: {
        ...theme.grid.doubleRow,
        borderBottom: '1px solid lightgrey',
        maxWidth: '100px',
        'white-space': 'nowrap',
        'word-wrap': 'break-word',
        'text-overflow': 'ellipsis',
        overflow: 'hidden',
        '& span': {
            fontWeight: 'bold',
            borderBottom: 'unset!important',
        }
    },
    tableContainer:{
        overflow: "auto", 
        height: '200px', 
        width: '100%' ,
        '&::-webkit-scrollbar': {
            width: '4px',//(props) => props.scrollbarWidth,
            background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.colors.black,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#909090'
        },
    },
    carsTable: {
        width: '100%',
        overflow: 'hidden',
        'grid-auto-rows': 50,
        padding: theme.spacing,
        display: "grid",
        gridTemplateColumns: 'repeat(6,minmax(80px,100px))',
        marginBottom: theme.spacing,
        fontSize: 14,

        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',

        '& span': {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            borderBottom: '1px solid lightgrey',
            display: 'inline-block',
            textAlign: 'right',
        },
    },
    tableHeader: {
        display: "grid",
        justifyContent: 'end',
        marginBottom: theme.spacing * 2,
        '& span': {
            fontWeight: 'bold',
            marginRight: theme.spacing * 2
        }
    },
    reprice: {
        color: theme.colors.blue,
        fontWeight: 'bold'
    }
}));

const RepricePopover = ({ repriceInfos, confirmReprice, deleteReprice,loading }) => {
    const classes = useStyles();

    return <div className={classes.gridContainer}>
            {loading && <Spinner/>}
            <img src={alertTriangle} alt="alert triangle" />
            <h4>Attenzione</h4>
            <p>
                Stai {repriceInfos?.operation === 'add' ? <strong>aumentando</strong> : <strong>diminuendo</strong>}
                il prezzo di {repriceInfos.cars.length} auto, {repriceInfos?.type === 'fixed' ? `Di ${repriceInfos.amount}€` : `Del ${repriceInfos.amount}%`}.<br/>
                Sei sicuro di voler confermare le seguenti variazioni di prezzo?
            </p>
            <div className={classes.tableHeader}>
                <span>Reprice</span>
            </div>
            <div  className={classes.tableContainer}>
                {repriceInfos?.cars.map(item => {
                    return <div key={item.id} className={classes.carsTable}>
                        <div className={classes.doubleRow} >
                            <span>{item.model.brand.name}</span>
                            <small>{item.model.name}</small>
                        </div>
                        <span >{item.plate}</span>
                        <span>{item.mileage} km</span>
                        <span>{formattedDate(item.created_at)}</span>
                        <span>{toMoney(item.price)}</span>
                        <span className={classes.reprice}>{toMoney(item.reprice)}</span>
                    </div>
                })}
            </div>
            <div className={classes.buttons}>
                <Button onClick={deleteReprice}>Annulla</Button>
                <Button data-primary onClick={confirmReprice}>Procedi</Button>
            </div>
        </div>
};

export default RepricePopover