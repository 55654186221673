import React from 'react';
import {useTheme} from 'react-jss';
import PropTypes from 'prop-types';
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CircularProgress = ({value = 0, size = 100, valuePosition = 'center'}) => {
    const theme = useTheme();

    return <div style={{
        width: valuePosition === 'center' ? size : size + 30,
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: `${size}px auto`,
        gridColumnGap: 4
    }}>
        <CircularProgressbar
            value={value}
            text={valuePosition === 'center' ? `${value}%` : ''}
            styles={buildStyles({
                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: 'butt',
                // Text size
                textSize: '16px',
                // How long animation takes to go from one percentage to another, in seconds
                pathTransitionDuration: 0.5,
                // Can specify path transition in more detail, or remove it entirely
                // pathTransition: 'none',
                // Colors
                pathColor: `${value <= 25 ? theme.colors.red : value <= 75 ? theme.colors.yellow : theme.colors.green}`,
                textColor: `${value <= 25 ? theme.colors.red : value <= 75 ? theme.colors.yellow : theme.colors.green}`,
                trailColor: '#d6d6d6',
                backgroundColor: 'transparent',
            })}
        />
        {valuePosition !== 'center' && <span style={{
            fontSize: 12,
            fontWeight: 600,
            color: value <= 25 ? theme.colors.red : value <= 75 ? theme.colors.yellow : theme.colors.green
        }}>{value}%</span>}
    </div>
};

CircularProgress.propTypes = {
    value: PropTypes.number, // value the chart should show
    valuePosition: PropTypes.oneOf(['center', 'right']),
    size: PropTypes.number, // diameter of chart
};

export default CircularProgress;
