import React from 'react';
import { createUseStyles } from 'react-jss';
import Button from './Button';
const useStyles = createUseStyles(theme => ({
    root: {
        display: 'grid',
        textAlign:'center',
        '& h4':{
            fontSize: 18,
        }
    },
    buttons:{
        marginTop:theme.spacing*8,
        display:'grid',
        gridTemplateColumns:'1fr 1fr',
        gridColumnGap:theme.spacing*2
    }
}));


const DeletePopover = ({ title,deleteAction,closePopover}) => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <h4>{title}</h4>
            <div className={classes.buttons}>
                <Button onClick={closePopover}>ANNULLA</Button>
                <Button data-primary onClick={deleteAction}>CONFERMA</Button>
            </div>
        </div>
    )
}

export default DeletePopover;