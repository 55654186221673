import React from 'react';
import { createUseStyles } from "react-jss";
import Select  from "react-select";
import RangePicker from '../../Commons/RangePicker'

const useStyles = createUseStyles(theme => ({
    root: {
        position: 'relative',
        'width': '100%',
        'display': 'grid',
        gridTemplateColumns: '1fr auto',
        gridColumnGap: theme.spacing * 4,
        alignItems: 'center',
        'align-items': 'center',
        'justify-content': 'start',
        'grid-column-gap': theme.spacing * 2,
        marginBottom: theme.spacing * 3
    },

    subTitleAndButton: {
        display: "grid",
        gridTemplateColumns: '1fr auto',
        gridTemplateRows: '1fr auto',
    },
    filters: {
        display: "grid",
        [theme.s]: {
            'grid-auto-flow': 'row',
        },
        [theme.mUp]: {
            /* 'grid-template-columns': ({withSelects}) => withSelects ? `auto ${withSelects.map( () => 'auto')}  repeat(2, max-content)`: '1fr repeat(2, max-content)',*/
            gridTemplateColumns: '1fr auto',
        },
        'align-items': 'center',
        /*'justify-content': 'start',*/
        'grid-row-gap': theme.spacing * 4
    },
    datePicker: {
        "max-width": 300,
    },
    selectContainer: {
        display: "flex",
        alignItems: "center",

    }
}));

const customSelect = {
    control: (defaults) => ({
        ...defaults,
        border: "none",
        boxShadow: "none",
        backgroundColor: "transparent",
        width: 130,
        outline: 0
    }),
    option: (base, state) => ({
        ...base,
        fontWeight: state.isSelected ? "bold" : "",
        backgroundColor: "transparent",
        color: "black"
    })
}

const PolicesFilters = ({ selectsProps, filters, showDate, setFilters, callback }) => {
    
    const classes = useStyles({ withSelects: !!selectsProps, /*showRange: showRange*/ });

    return (
        <div className={classes.root}>
            <div className={classes.filters}>
                {showDate &&
                    <div className={classes.datePicker}>
                        <RangePicker 
                        inForm={false} label={'Periodo'}
                        openTo={'bottom'}
                        filters={filters} setFilters={setFilters}
                        />
                    </div>
                }

                {(selectsProps) && <>
                    {selectsProps.map((select, index) => {
                        return <div className={classes.selectContainer} key={index}>
                            <label>ORDINA PER:</label>
                            <Select placeholder={select.placeholder} options={select.options} classNamePrefix={'select'}
                                styles={customSelect}
                                isSearchable={false}
                                name={'ordina'}
                                isDisabled={(select.name === 'user_id' && filters?.user_id_toggle)}
                                onChange={(item) => {
                                    setFilters({ ...filters, [select.name]: item ? item.value : null })
                                }}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                value={select.options.filter(obj => {
                                    return obj.value === filters[select.name]
                                })}
                            />
                        </div>
                    })}
                </>}
            </div>
        </div>
    )

}

export default PolicesFilters;