import React from 'react';
import {useSelector} from "react-redux";
import {createUseStyles} from "react-jss";
import NetworkStatus from "./NetworkStatus/NetworkStatus";
import UserInitialsAvatar from "../../CurrentConversation/UserInitialsAvatar/UserInitialsAvatar";
import {getLoggedInUserName, getLoggedInUserUuid} from "../../../../store/reducers/user";

const useStyles = createUseStyles(theme => ({
    root: {
        display: 'flex',
        textTransform: 'capitalize',
        padding: [58, 32],
    },
    avatar: {
        display: 'flex'
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
        padding: [4, 12]
    },
    name: {
        fontSize: 18,
        color: '#B8B8B8',
        marginBottom: 4
    },
    title: {

    }
}))

const MyUserDetails = () => {
    const classes = useStyles();
    const userName = useSelector(getLoggedInUserName)
    const userUuid = useSelector(getLoggedInUserUuid)

    return (
        <div className={classes.root}>
            <div className={classes.avatar}>
                <UserInitialsAvatar size={56} name={userName} userId={userUuid} />
            </div>
            <div className={classes.info}>
                <span className={classes.name}>{userName}</span>
                <NetworkStatus/>
            </div>
        </div>
    );
};


export default MyUserDetails;
