import React, {useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import theme from '../theme.js';
import {AlertErrorIcon, AlertSuccessIcon, AlertWarningIcon, CloseIcon} from '../assets/icons.js';
import { useDispatch } from 'react-redux';
import { alertToggle } from '../store/actions/alertActions.js';

const useStyles = createUseStyles((theme) => ({
	root: {
		width: ({ fullWidth }) => (fullWidth ? '100%' : 'max-content'),
		borderRadius: 16,
		/* ...theme.columns,*/
		justifyContent: 'center',
		alignItems: 'center',
		gridColumnGap: theme.spacing,
		padding: theme.spacing * 2,
		color: ({ color }) => color,
		backgroundColor: ({ backgroundColor }) => backgroundColor,
		'& svg': {
			fill: ({ color }) => color + ' !important'
		},
		'& p': {
			color: ({ color }) => `${color}!important`
		},
		zIndex: 100,
		top: theme.headerHeight + 24,
		maxWidth: 500,
		position: 'fixed',
		right: ({ right }) => (right ? 24 : 0),
		left: '0',
		marginRight: ({ right }) => (right ? 24 : 'auto'),
		marginLeft: 'auto',
		height: 'auto',
		minHeight: 48,
		transition: 'transform 0.5s',
		willChange: 'transform',
		[theme.s]: {
			right: 8,
			marginRight: 'auto',
			width: 320
		}
	},
	innerContent: {
		position: 'relative',
		display: 'grid',
		gridTemplateColumns: 'auto 1fr',
		gridColumnGap: theme.spacing,
		alignItems: 'center',
		'& p':{
			fontSize: 14,
			fontWeight: 500
		}
	},
	in: {
		transform: 'translateX(0%)'
	},
	out: {
		transform: 'translateX(+300%)'
	},
	button: {
		...theme.button,
		...theme.buttonSmall
	},
	close: {
		display: 'block',
		position: 'absolute',
		top: -12,
		right: -8,
		width: '18px!important',
		fill: `${theme.colors.white}!important`,
		cursor: 'pointer'
		// 'mix-blend-mode': 'difference',
	},
	typeIcon:{
		fill: `${theme.colors.white}!important`,
	}
}));

// can be triggered with ==> dispatch(alertToggle('this is a test message','error', true));

const Snackbar = ({
	level = 'success',
	fullWidth = true,
	right,
	marginX = 0,
	marginY = 0,
	content,
	dismissTimeout = 8000,
	actionCopy,
	action = Function.prototype,
	actionPath
}) => {
	const dispatch = useDispatch();
	const [ show, setShown ] = useState(false);
	let timeOut;

	const [ color, backgroundColor ] =
		level === 'success'
			? [ theme.colors.white, theme.colors.alertGreen ]
			: level === 'warning'
				? [ theme.colors.white, theme.colors.alertYellow ]
				: [ theme.colors.white, theme.colors.alertRed ];

	const classes = useStyles({ level, fullWidth, color, backgroundColor, marginX, marginY, right });

	useEffect(() => {
			// trigger "out" animation
			if (dismissTimeout && show) {
				timeOut = setTimeout(() => actionCallback(), +dismissTimeout);
			}
			return () => {
				clearTimeout(timeOut);
			};
		}, [ show, timeOut ]
	)
	;
	// trigger "in" animation
	useEffect(() => {
			if (content) {
				setShown(true);
			}
		}, [ content ]
	);

	const actionCallback = () => {
		dispatch(alertToggle('', level));
		setShown(false);
	};

	const iconTypeRenderer = () => {
		switch (level) {
			case 'success':
				return <AlertSuccessIcon className={classes.typeIcon} data-color/>
			case 'warning':
				return <AlertWarningIcon className={classes.typeIcon} data-color/>
			default:
				return <AlertErrorIcon className={classes.typeIcon} data-color/>
		}
	}

	return (
		<main className={cx(classes.root, show ? classes.in : classes.out)}>
			<div className={classes.innerContent}>
				{iconTypeRenderer()}
				<p>{content}</p>
				{!!action && (
					<a className={classes.link} href={''} onClick={action}>
						{actionCopy}
					</a>
				)}
				<CloseIcon className={classes.close} data-color onClick={actionCallback}/>
			</div>
		</main>
	);
};

Snackbar.propTypes = {
	level: PropTypes.oneOf([ 'success', 'warning', 'error', '' ]),
	fullWidth: PropTypes.bool,
	/* y: PropTypes.oneOf(['top', 'center', 'bottom']),
    x: PropTypes.oneOf(['left', 'center', 'right']), */
	right: PropTypes.bool,
	marginX: PropTypes.number,
	marginY: PropTypes.number,
	content: PropTypes.string.isRequired,
	dismissTimeout: PropTypes.number,
	dismissCopy: PropTypes.any,
	action: PropTypes.func,
	actionCopy: PropTypes.string,
	actionPath: PropTypes.string
};

export default Snackbar;
