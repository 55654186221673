import React, {useEffect, useState} from 'react';
import {createUseStyles} from "react-jss";
import {formattedDateIso} from "../../helpers/utils.js";

const useStyles = createUseStyles(theme => ({
    root: {
        display: 'grid',
        width: '100%',
        gridRowGap: theme.spacing / 2,
        padding: theme.spacing * 2,
        justifyItems: 'start',
        backgroundColor: ({isSelected}) => isSelected ? theme.colors.black : theme.colors.xLightGrey,
        borderRadius: 8,
        marginBottom: theme.spacing,
        cursor: 'pointer',
        transition: 'ease-in 300ms',
        '&:hover':{
            backgroundColor: ({isSelected}) => isSelected ? theme.colors.black : theme.colors.grey,
        }
    },
    model:{
        marginBottom: theme.spacing * 2,
        fontSize: 14,
        fontWeight: 500,
        color: ({isSelected}) => isSelected ? theme.colors.white : theme.colors.black
    },
    secondary:{
        fontSize: 12,
        color: theme.colors.darkGrey
    }
}));

const CarVersion = ({setCurrentVersion, currentVersion, version, isDat}) => {
    const isSelected = isDat ? (currentVersion?.ecode === version?.ecode) : (currentVersion?.value === version?.value);
    const classes = useStyles({isSelected:isSelected});

    return <div className={classes.root} onClick={() => setCurrentVersion(version)}>
       <span className={classes.secondary}> {isDat ? version.manufacturerName : version.brand} </span>
        <h5 className={classes.model}>{isDat ? `${version.mainTypeName} ${version.subTypeName}` : version?.label}</h5>
        {/* DAT SHIT: `Prodotto dal ${formattedDateIso(version.production_start_at)} al ${formattedDateIso(version.production_ends_at)}` */}
        <span className={classes.secondary}>{isDat ? `Prodotto dal - al -`
            : `Prodotto dal ${formattedDateIso(version.production_start_at)} al ${version.production_ends_at ? formattedDateIso(version.production_ends_at) : 'In corso'}`}
        </span>
        {/* ${version.equipments['2'] this shit is cause of Dat crazy data model */}
        <span className={classes.secondary}> {isDat ? `${version.equipments['2']}` : `${version.doors} porte`}</span>
    </div>
};

export default CarVersion;