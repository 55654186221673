import React  from 'react';
import Text from "../../Commons/Text.js";
import { useForm } from "react-hook-form";
import { errorHandler, formattedDateIso } from "../../helpers/utils.js";
import Button from '../../Commons/Button';
import RangePicker from '../../Commons/RangePicker';
import { createUseStyles } from "react-jss";
import { httpAddCampaign } from '../../HttpRequests/campaigns.http.js';
import { useDispatch, useSelector } from 'react-redux';
import { setCampaignsList } from '../../store/actions/campaignActions.js';
import {useHistory} from "react-router-dom";

const useStyles = createUseStyles(theme => ({
    root: {
        display: 'grid',
        width: '100%',
        gridRowGap: theme.spacing * 3,
        justifyItems: 'center',
    },
    title: {
        fontWeight: 600,
        margin: [theme.spacing * 2, 0]
    },
    datePickerPosition: {

    }
}));

const NewCampaign = ({ closeModalCb,setLoading}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const campaigns = useSelector(state=>state.campaigns.campaignsList)
    const { register, handleSubmit, errors, control } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
    })

    const onSubmit = async (values) => {
        const dataToSend = {
            name: values?.name,
            start_campaign: formattedDateIso(values?.start_date),
            end_campaign: formattedDateIso(values?.end_date)
        };
        setLoading(true)
        try {
            const { data } = await httpAddCampaign(dataToSend);
            dispatch(setCampaignsList([data, ...campaigns]))
            history.push(`/stock/trade-policies/${data?.id}`)
        } catch (e) {
            errorHandler(e, dispatch)
        } finally{
            setLoading(false)
            closeModalCb()
        }  
    };

    return (
        <>
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
            <h4 className={classes.title}> Nuova Campagna </h4>
            <Text type={'text'} label={'Nome'} name={'name'} ref={register} errors={errors.first_name} />
            <RangePicker
                inForm={true}
                label={'Periodo'}
                openTo={'top'}
                control={control}
                name={'range'}
                callback={onSubmit}
                ref={register}
            />
            <Button width={250} type={'submit'} data-primary style={{ marginTop: 24}} > Crea </Button>
        </form>
        </>
    )
}

export default NewCampaign;