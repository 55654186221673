import React from 'react';
import {createUseStyles} from "react-jss";
import {useDispatch} from "react-redux";
import CarVersion from "./CarVersion.js";

const useStyles = createUseStyles(theme => ({
    root: {
        display: 'grid',
        width: '100%',
        gridRowGap: theme.spacing * 3,
        justifyItems: 'center'
    },
    empty: {
        width: "100%",
        height: "100%",
        display: "grid",
        background: theme.colors.xLightGrey,
        borderRadius: 8,
        justifyContent: "center",
        alignItems: "center",
        textAlign: 'center',
        padding: theme.spacing * 2,
        '& h4': {
            color: theme.colors.grey,
            fontWeight: 600
        },
        '& p': {
            color: theme.colors.grey,
            fontSize: 14
        }
    },
    filled: {width:'100%'},
    listContainer: {
        marginBottom: theme.spacing * 2,
        width: "100%",
        overflow: "scroll",
        scrollX: "hidden",
        scrollY: "scroll",
        maxHeight: "380px",
        padding: "0 16px 0 0",
        marginTop: "24px"
    },
    title: {
        fontWeight: 600,
        margin: [theme.spacing * 2, 0, 0],
        display: 'grid',
        alignItems: 'center',
        gridColumnGap: theme.spacing,
        gridTemplateColumns: '1fr auto',
    },
    results: {
        fontSize: 12,
        marginTop: -theme.spacing * 4
    }
}));

const DatLiveResults = ({versions,currentVersion,setCurrentVersion}) => {
    const classes = useStyles();

    return <div className={classes.root}>
        {!!versions?.length ? <div className={classes.filled}>
                <h4 className={classes.title}> Risultati Della ricerca</h4>
                <span className={classes.results}> <strong>{versions.length}</strong> Versioni trovate </span>
                <div className={classes.listContainer}>
                    {versions.map((item, index) => {
                        return <CarVersion setCurrentVersion={setCurrentVersion} currentVersion={currentVersion}
                                           version={item} key={index} isDat={true}/>
                    })}
                </div>
            </div>
            :
            <div className={classes.empty}>
                <div>
                    <h4>Nessun risultato trovato</h4>
                    <p>aggiungi maggiori informazioni sul veicolo che stai cercando</p>
                </div>
            </div>}
    </div>
};

export default DatLiveResults;