import {
    MEMBERSHIP_REMOVED_EVENT,
    MEMBERSHIP_SET_EVENT,
    MEMBERSHIPS_REMOVED,
    MEMBERSHIPS_RETRIEVED,
    MEMBERSHIPS_SET,
} from "../../actions/chat/joinedConversationsActions";
import {createSelector} from "reselect";
import {getLoggedInUserUuid} from "../user";

const createInitialState = () => ({
    byId: {},
});

const uuidRemovedFromChannel = (
    state,
    payload
) => {
    if (
        state.byId[payload.data.uuid.id] &&
        state.byId[payload.data.uuid.id].filter(
            (membership) => membership.id === payload.data.channel.id
        ).length > 0
    ) {
        const newState = {
            byId: { ...state.byId },
        };

        newState.byId[payload.data.uuid.id] = newState.byId[
            payload.data.uuid.id
            ].filter((membership) => membership.id !== payload.data.channel.id);

        return newState;
    }

    return state;
};

const uuidMembershipUpdatedOnChannel = (
    state,
    payload
) => {
    const newState = {
        byId: { ...state.byId },
    };

    let clonedUUID = [...(newState.byId[payload.data.uuid.id] || [])];

    let exists = false;
    clonedUUID = clonedUUID.map((channel) => {
        if (channel.id === payload.data.channel.id) {
            exists = true;
            return {
                ...channel,
                custom: payload.data.custom,
            };
        } else {
            return channel;
        }
    });
    if (!exists) {
        clonedUUID.push({
            id: payload.data.channel.id,
            custom: payload.data.custom,
        });
    }

    newState.byId[payload.data.uuid.id] = clonedUUID;

    return newState;
};

const membershipResult = (
    state,
    payload
) => {
    const newState = {
        byId: { ...state.byId },
    };
    const uuid = payload.request.uuid;
    const memberships = payload.response.data.map((complete) => ({
        id: complete.channel.id,
        custom: complete.custom || null,
        channelMeta: {...complete.channel}
    }));

    if (
        payload.request.page &&
        (payload.request.page.next || payload.request.page.prev)
    ) {
        // append and deduplicate
        const previous = newState.byId[uuid] || [];
        const ids = previous.map((m) => m.id);
        newState.byId[uuid] = [
            ...previous,
            ...memberships.filter(({ id }) => ids.indexOf(id) === -1),
        ];
    } else {
        // reset if pagination was not used
        newState.byId[uuid] = memberships;
    }

    return newState;
};





export const createMembershipReducer = () => (
        state = createInitialState(),
        action
)=> {
    switch (action.type) {
        // pay attention here: membershipResult is executed inside all 3 cases (retrieved, set, removed)
        case MEMBERSHIPS_RETRIEVED:
        case MEMBERSHIPS_SET:
        case MEMBERSHIPS_REMOVED:
            return membershipResult(
                state,
                action.payload
            );
        case MEMBERSHIP_SET_EVENT:
            return uuidMembershipUpdatedOnChannel(
                state,
                action.payload
            );
        case MEMBERSHIP_REMOVED_EVENT:
            return uuidRemovedFromChannel(state, action.payload);
        default:
            return state;
    }
};


/**
 * Selectors
 *
 */

export const getJoinedConversationsSlice = state => state.chats.joinedConversations;


export const getJoinedConversationsByUserId = createSelector(
    [getJoinedConversationsSlice],
    joinedConversations => {
        return joinedConversations.byId;
    }
);

export const getLoggedInUserJoinedConversations = createSelector(
    [getJoinedConversationsByUserId, getLoggedInUserUuid],
    (
        joinedConversations,
        userId,
    ) => {
        return joinedConversations[userId] ?? [];
    }
);




