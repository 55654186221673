import * as actionTypes from '../actions/communicationsActions';
import {createSelector} from "reselect";


const initialState = {
    communicationsList: [],
};


const communicationsReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.SET_COMMUNICATIONS_LIST:
            return {
                ...state,
                communicationsList: action.communicationsList
            };
        default:
            return state;
    }
};

const getCommunicationsSlice = state => state.communications;

export const getCommunicationsList = createSelector(
    [getCommunicationsSlice],
    communications => {
        return communications.communicationsList;
    }
);

export default communicationsReducer;