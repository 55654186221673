import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useHistory, useParams} from 'react-router-dom';
import SubHeading from "../../../Commons/SubHeading.js";
import Card from '../../../Commons/Card';
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {httpCreateBusiness, httpGetBusinessList, httpUpdateBusiness} from "../../../HttpRequests/settings.http.js";
import {errorHandler, retrieveValueForRs} from "../../../helpers/utils.js";
import {editBusiness, setBusinessList} from "../../../store/actions/settingsActions.js";
import {alertToggle} from "../../../store/actions/alertActions.js";
import Spinner from "../../../Commons/Spinner.js";
import {Pointer} from "../../../assets/icons.js";
import Text from "../../../Commons/Text.js";
import Button from "../../../Commons/Button.js";
import * as Yup from "yup";
import BusinessProfileImagesUpload from "../../../Components/Settings/BusinessAccount/BusinessProfileImageUpload.js";
import Select from "../../../Commons/Select.js";

const useStyles = createUseStyles(theme => ({
    gridContainer: {
        'position': 'relative',
        // with big/small subheader like permute page
        height: ({bigSubHeader}) => `calc(100vh - ${bigSubHeader ? theme.bigSubHeader + theme.headerHeight : theme.smallSubHeader + theme.headerHeight}px)`,
        // without subheader
        //height: `calc(100vh - ${theme.headerHeight}px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        [theme.mUp]: {
            padding: [theme.spacing * 4],
        },
        [theme.s]: {
            padding: [theme.spacing],
        }
    },
    formRoot: {
        display: 'grid',
        gridTemplateColumns: '210px 1fr',
        gridColumnGap: theme.spacing,
        padding: theme.spacing,
        borderRadius: 20,
        ...theme.paper
    },
    imageContent: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto 1fr',
        gridColumnGap: theme.spacing * 2,
        gridRowGap: theme.spacing * 2,
        padding: theme.spacing,
        alignItems: 'start',
        textAlign: 'center'
    },
    gridContent: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        alignItems: 'start',
        gridColumnGap: theme.spacing * 2,
        gridRowGap: theme.spacing * 2,
        padding: theme.spacing
    },
    colThird: {
        gridColumn: '3/4'
    },
    colFirstSecond: {
        gridColumn: '1/3'
    },
    title: {
        display: 'grid',
        gridColumn: '1/4',
        fontWeight: 'bold',
        fontSize: 18,
    },
    avatar: {
        width: "160px",
        height: "160px",
        display: "grid",
        position: "relative",
        borderRadius: "50%",
        alignItems: "center",
        justifyItems: "center",
        boxSizing: 'border-box',
        background: theme.colors.grey,
        alignSelf: 'start',
        margin: theme.spacing * 2
    },
    btns: {
        marginTop: theme.spacing * 6,
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: theme.spacing,
        gridColumnStart: 3,
        width: 300,
        justifySelf: 'end'
    }
}));

const validationSchema = Yup.object().shape({
    name: Yup.string().required(`Inserisci il nome della società.`).min(3, 'Il nome della società deve contenere almeno 3 caratteri'),
    registered_office: Yup.string().required(`Inserisci l'indirizzo.`).min(3, 'Il campo indirizzo deve contenere almeno 3 caratteri'),
    vat_number: Yup.string().required(`Inserisci la P.Iva.`).length(11, 'Il campo Partita Iva deve contenere 11 caratteri')
});

const BusinessAccount = () => {
    const classes = useStyles({bigSubHeader: false});
    const {id} = useParams(); //id of user from url params react-router-dom
    const history = useHistory();
    const [business, setBusiness] = useState(useSelector((state) => state?.settings?.businessList && state.settings.businessList.find((arr) => +arr.id === +id)));
    const [loading, setLoading] = useState(false);
    const [photoLink, setPhotoLink] = useState(null);
    const dispatch = useDispatch();
    const usersAutocomplete = useSelector(state => state.autocompletes.usersAutocomplete);

    const {register, handleSubmit, errors, reset,control} = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            ...business,
            backoffice_supervisor_id:  retrieveValueForRs(usersAutocomplete, business?.backoffice_supervisor_id)
        },
        validationSchema
    });

    useEffect(
        () => {
            if(usersAutocomplete?.length){
                getBusinessData();
            }
        },
        [usersAutocomplete]
    );

    const getBusinessData = async () => {
        if (id && !business) {
            setLoading(true);
            try {
                const {data: {data}} = await httpGetBusinessList(null);
                //UPDATE REDUX STATE
                dispatch(setBusinessList(data));
                const actualBusiness = data.find((arr) => +arr.id === +id);
                //SET LOCAL STATE
                setBusiness(data.find((arr) => +arr.id === +id));
                //react-hook-form reset
                reset({...actualBusiness,  backoffice_supervisor_id:  retrieveValueForRs(usersAutocomplete, actualBusiness?.backoffice_supervisor_id)});
            } catch (e) {
                errorHandler(e, dispatch)
            } finally {
                setLoading(false);
            }
        }
    };

    const onSubmit = async (values) => {
        const dataToSend = {
            ...values,
            id: id,
            backoffice_supervisor_id: values.backoffice_supervisor_id ? values.backoffice_supervisor_id?.value : null,
        };
        if (photoLink) dataToSend.logo_link = photoLink;
        try {
            setLoading(true);
            const {data} = id ? await httpUpdateBusiness(dataToSend, id) : await httpCreateBusiness(dataToSend);
            dispatch(editBusiness(data));
            history.push("/settings/business")
            dispatch(alertToggle((id ? 'Dati correttamente aggiornati' : 'Dati correttamente inseriti'), 'success'))
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    };

    return <>
        <SubHeading title={id ? "Modifica società" : "Crea nuova società"} type={'small'}/>
        <div className={classes.gridContainer}>
            <Card cardTitle={"Informazioni"} cardIcon={<Pointer data-color/>}>
                <form onSubmit={handleSubmit(onSubmit)} className={classes.formRoot}>
                    {loading && <Spinner/>}
                    <div className={classes.imageContent}>
                        <p className={classes.title}>Business logo</p>
                        <BusinessProfileImagesUpload businessPhoto={business?.logo_link} setPhotoLink={setPhotoLink}/>
                    </div>
                    <div className={classes.gridContent}>
                        <Text className={classes.colFirst} type={'text'} label={'Nome società'} name={'name'}
                              ref={register} errors={errors.name}/>

                        <h4 className={classes.title} style={{marginTop: 24}}>Sede legale</h4>
                        <Text className={classes.colFirst} type={'text'} label={'Indirizzo'}
                              name={'registered_office'} ref={register} errors={errors.registered_office}/>
                        <Text className={classes.colSecond} type={'text'} label={'Città'}
                              name={'city'} ref={register} errors={errors.city}/>

                        <h4 className={classes.title} style={{marginTop: 24}}>Amministrazione</h4>
                        <Text className={classes.colFirst} type={'text'} label={'Partita Iva'} name={'vat_number'}
                              ref={register} errors={errors.vat_number}/>
                        <Text className={classes.colSecond} type={'text'} label={'Business Manager'}
                              name={'administrator'} ref={register} errors={errors.administrator}/>
                        <Select className={classes.colThird} name={'backoffice_supervisor_id'} label={'Responsabile backoffice'}
                                options={usersAutocomplete ? usersAutocomplete : []} ref={register} control={control}
                        />

                        <div className={classes.btns}>
                            <Button type="button" onClick={() => history.push("/settings/business")}
                                    data-secondary>Annulla</Button>
                            <Button type={'submit'} data-primary>Salva</Button>
                        </div>
                    </div>

                </form>
            </Card>
        </div>
    </>
};

export default BusinessAccount;
