export const SET_CAMPAIGNS_LIST = 'SET_CAMPAIGNS_LIST';
export const SET_CAMPAIGN_CARS = 'SET_CAMPAIGN_CARS';
export const EDIT_CAMPAIGNS_LIST = 'EDIT_CAMPAIGNS_LIST';

export const setCampaignsList = (campaigns) => {
    return {
        type: SET_CAMPAIGNS_LIST,
        campaignsList: campaigns
    }
}

export const editCampaignsList = (campaign) => {
    return {
        type: EDIT_CAMPAIGNS_LIST,
        campaign: campaign
    }
}

export const setCampaignCars = (cars) =>{
    return {
        type:SET_CAMPAIGN_CARS,
        campaignCars:cars
    }
}






