import React from 'react';
import {createUseStyles} from "react-jss";
import Button from "../../Commons/Button.js";
import alertTriangle from '../../assets/images/alertTriangle.svg'

const useStyles = createUseStyles(theme => ({
    root: {
        display: 'grid',
        width: '100%',
        gridRowGap: theme.spacing * 3,
        justifyItems: 'center'
    },
    title:{
        fontWeight: 600,
        margin: [theme.spacing * 2, 0],
        display: 'grid',
        alignItems: 'center',
        gridColumnGap: theme.spacing,
        gridTemplateColumns: '1fr auto',
        '& img':{
            width: 72
        }
    },
    results:{
        fontSize: 12,
        marginTop: - theme.spacing * 4,
        textAlign: 'center'
    },
    btns:{
        marginTop: theme.spacing * 2,
        display: 'grid',
        alignItems: 'center',
        gridColumnGap: theme.spacing * 2,
        gridTemplateColumns: '1fr 1fr',
    }
}));

const EditAlert = ({onClose, callback}) => {
    const classes = useStyles();

    return <div className={classes.root}>
        <img src={alertTriangle} alt={'alert'}/>
        <h4 className={classes.title}> Attenzione! </h4>
        <span className={classes.results}>
            Stai per modificare dei campi con informazioni importanti tratte da sistemi automatici e visionate già da altri operatori. Sei sicuro di procedere?
        </span>

        <div className={classes.btns}>
            <Button width={150} type={'button'} data-secondary data-small onClick={onClose}> Annulla </Button>
            <Button width={150} type={'button'} data-secondary data-small onClick={callback}> Procedi </Button>
        </div>
    </div>
};

export default EditAlert;