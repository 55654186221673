import axios from 'axios';

export const httpGetCarUbicationLocations = (id) => {
    return axios.get(`/ubications/${id}/locations`);
}

export const httpGetAllCarLocations = () => {
    return axios.get(`/locations`);
}

export const httpGetCarShifts = (id,filters) => {
    return axios.get(`/cars/${id}/transportations`, {params: {...filters}});
}

export const httpSetShiftRequest = (id,data) =>{
    return axios.post(`/cars/${id}/transportations`, {...data});
}

export const httpSetMultipleShiftsRequest = (data) =>{
    return axios.post(`/transportations`, {...data});
}

export const httpSetQuickShifts = (id, location) => {
    return axios.post(`/cars/${id}/transportations/quick_store`, {location_id_to: location.value})
}

export const httpGetShiftRequests = (filters) =>{
    return axios.get(`/transportations/drafts`,{params: {...filters}} );
}

export const httpConfirmShiftRequests = (data) =>{
    return axios.post(`/transportations/confirm`,{...data});
}

export const httpAddNewCarToShiftsRequests = (data) =>{
    return axios.post(`/transportations/new_car`,{...data});
}

export const httpGetShifts = (filters) =>{
    return axios.get(`/transportations`, {params: {...filters}});
}

export const httpDeleteShift = (id) =>{
    return axios.delete(`/transportations/${id}`, );
}

export const httpEditShift = (id,data) =>{
    return axios.put(`/transportations/${id}`, {...data});
}

export const downloadAllShiftDocs = (id) => {
    return axios.get(`/transportations/download_all/${id}`, {
        responseType: 'arraybuffer',
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    });
}