import * as actionTypes from '../actions/carsActions.js';
import {getCarDefaultImage} from "../../helpers/utils.js";

const initialState = {
	carsRevision: [], //stock commercial revision list
	cars: [], //stock list
	currentCar: null, // car data visualized in the car page ( remember to clear at page init if actual id is different form requested id)
	carTasks: [],
	carShifts: [],
	carDocuments: []
};

const carsReducer = (state = initialState, action) => {
	let newCarTasks = state.carTasks;
	let carShiftsListCp = state.carShifts;
	let carDocumentsCp = state.carDocuments;

	switch (action.type) {
		case actionTypes.SET_CARS_REVISION:
			return {
				...state,
				carsRevision: action.carsRevision
			};
		case actionTypes.SET_CARS:
			return {
				...state,
				cars: action.cars
			};
		case actionTypes.SET_CURRENT_CAR:
			return {
				...state,
				currentCar: {...action.car, profilePic: getCarDefaultImage(action.car)}
			};
		case actionTypes.SET_CAR_TASKS:
			return {
				...state,
				carTasks: action.tasks
			};
		case actionTypes.UPDATE_CAR_TASKS:
			return {
				...state,
				carTasks: newCarTasks.map(task => (+task.id === action.newTask.id) ? action.newTask : task)
			};
		case actionTypes.SET_CAR_SHIFTS:
			return {
				...state,
				carShifts: action.shifts
			};
		case actionTypes.UPDATE_CAR_SHIFT:
			return {
				...state,
				carShifts: carShiftsListCp.map(item => item.id === action.shift.id ? action.shift : item)
			};
		case actionTypes.REMOVE_CAR_TASK:
			return {
				...state,
				carTasks: state.carTasks.filter(task => task.id !== action.id)
			};
		case actionTypes.ADD_PHOTOSET:
			return {
				...state,
				currentCar: {
					...state.currentCar,
					photosets: [
						...state.currentCar.photosets,
						{...action.photoset, isNew: true}
					]
				}
			};
		case actionTypes.UPDATE_PHOTOSET:
			return {
				...state,
				currentCar: {
					...state.currentCar,
					photosets: state.currentCar.photosets.map( photoset => {
						return action.photoset.id === photoset.id ?
							{ ...action.photoset, modified: !action.definitive } : photoset
					})

				}
			};
		case actionTypes.DELETE_PHOTOSET:
			return {
				...state,
				currentCar: {
					...state.currentCar,
					photosets: state.currentCar.photosets.filter( photoset => action.photosetId !== photoset.id)

				}
			};
		case actionTypes.ADD_PHOTO:
			return {
				...state,
				currentCar: {
					...state.currentCar,
					photosets: state.currentCar.photosets.map( photoset => {
						return action.photo.photoset_id === photoset.id ?
							{
								...photoset,
								modified: true,
								photos: [
									...photoset.photos,
									action.photo
								]
							} : photoset
					})

				}
			};
		case actionTypes.UPDATE_PHOTO:
			return {
				...state,
				currentCar: {
                    ...state.currentCar,
					photosets: state.currentCar.photosets.map( photoset => {
						return action.photo.photoset_id === photoset.id ?
							{
								...photoset,
								modified: true,
								photos: photoset.photos.map(photo => {
									return action.photo.name === photo.name ?
										action.photo : photo
								})
							} : photoset
					})

				}
			};
		case actionTypes.DELETE_PHOTO:
			return {
				...state,
				currentCar: {
                    ...state.currentCar,
					photosets: state.currentCar.photosets.map( photoset => {
						return action.photo.photoset_id === photoset.id ?
							{
								...photoset,
								modified: true,
								photos: photoset.photos.filter(photo => action.photo.name !== photo.name)
							} : photoset
					})

				}
			};
		case actionTypes.SET_FAVORITE_PHOTO:
			return {
				...state,
				currentCar: {
                    ...state.currentCar,
					photosets: state.currentCar.photosets.map( photoset => {
						return action.photo.photoset_id === photoset.id ?
							{
								...photoset,
								modified: true,
								photos: photoset.photos.map(photo => {
									return action.photo.name === photo.name ?
										{...photo, default: true } : {...photo, default: false}
								})
							} : photoset
					}),
                    profilePic: action.photo.url
				}
			};
		case actionTypes.RESET_CAR_PROFILE_PIC:
			return {
				...state,
				currentCar: {...state.currentCar, profilePic: null}
			};
		case actionTypes.SET_CAR_DOCUMENTS:
			return {
				...state,
				carDocuments: action.documents
			};
		case actionTypes.UPDATE_CAR_DOCUMENTS:
			if (action.id) {
				carDocumentsCp = carDocumentsCp.map(item => item.type === action.document.type ? action.document : item);
			} else {
				carDocumentsCp = carDocumentsCp.map(item => item.value === action.document.type ? action.document : item);
			}
			return {
				...state,
				carDocuments: carDocumentsCp
			};
		case actionTypes.DELETE_CAR_DOCUMENTS:
			carDocumentsCp = carDocumentsCp.map(item => item.type ===  action.document.type ? {
				category: action.document?.category,
				label: action.document?.label,
				value: action.document?.type
			} : item);
			return {
				...state,
				carDocuments: carDocumentsCp
			};
		default:
			return state;
	}
};

export default carsReducer;
