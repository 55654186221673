import React from 'react';
import { createUseStyles } from 'react-jss';
import { UserIcon } from '../assets/icons.js';

const useStyles = createUseStyles((theme) => ({
	root: {
		display: 'grid',
		gridTemplateColumns: ({ fullName }) => (fullName ? '40px auto' : '1fr'),
		gridColumnGap: ({ fullName }) => (fullName ? '8px' : '0'),
		position: 'relative',
		alignItems: 'center',
		justifyItems: ({ fullName }) => (fullName ? 'start' : 'center'),
		overflow: 'hidden',
		cursor: 'pointer',
		'& span':{
			fontSize: ({ size }) => (size === 'small' ? '12px' : '16px'),
			fontWeight: 500
		},
		'& img':{
			boxSizing: 'border-box',
			borderRadius: '50%',
			border: `1px solid ${theme.colors.lightGrey}`,
			width: ({ size }) => (size === 'small' ? '30px' : '40px'),
			height: ({ size }) => (size === 'small' ? '30px' : '40px'),
		},
	},
	palceholder:{
		borderRadius: '50%',
		border: `1px solid ${theme.colors.lightGrey}`,
		width: ({ size }) => (size === 'small' ? '30px' : '40px'),
		height: ({ size }) => (size === 'small' ? '30px' : '40px'),
		display:'grid',
		alignItems: 'center',
		justifyItems: 'center',
		'& svg':{
			fill: theme.colors.lightGrey,
			width: '16px!important'
		}
	}
}));

const Avatar = ({ img, fullName, onClickEvent, size = 'medium' }) => {
	const classes = useStyles({ img,fullName, size });

	return (
		<main className={classes.root} onClick={onClickEvent ? onClickEvent : Function.prototype}>
			{img ? <img src={img} alt={'avatar'} /> : <span className={classes.palceholder}>
				<UserIcon data-color/>
			</span>}
			{fullName && <span>{fullName}</span>}
		</main>
	);
};

export default Avatar;
