import axios from 'axios';
import mime from 'mime-types';

export const httpInitChat = () => {
    return axios.post(`/chat/init`);
};

export const httpStartDirectChat = (userId) => {
    return axios.post(`/chat/start`, {uuid: userId});
};

export const httpSendChatFile = ({ url, filename, receiverUuid, type = 'image', channelName }) => {
    //const formDataObject = serialize({file, client_id: receiverId});
    let formDataObject = new FormData();
    formDataObject.append('url', url);
    formDataObject.append('filename', filename);
    formDataObject.append('type', type);
    formDataObject.append('channel_name', channelName)
    formDataObject.append('receiver_uuid', receiverUuid);
    return axios.post(`/chat/attachment`, formDataObject);
};

export const httpFetchChatFile = (attachmentId, channelName) => {
    return axios.get(`/chat/attachment/${attachmentId}?channel_name=${channelName}`);
};

export const httpFetchChatUsers = () => {
    return axios.get(`/chat/messages`);
};

export const httpDownloadAttachment = (url, filename) => {
    axios
        .get(url, {responseType: 'blob'})
        .then(({data}) => {
            const url = URL.createObjectURL(new Blob([data]));
            const a = document.createElement('a');
            const ext = mime.extension(data.type)
            a.href = url;
            a.download = `${filename || 'download'}.${ext}`;
            document.body.appendChild(a);
            a.click();
            URL.revokeObjectURL(url);
            a.remove();
        });
}

export const sendBroadcastMessage = (channel, message) => {
    return axios.post(`/chat/broadcast`, {channel_name: channel, message});
}

export const httpGetExcludedChatUsers = () => {
    return axios.get(`/bots`);
}
