import React from 'react';
import { createUseStyles } from 'react-jss';
import theme from '../theme';
import PropTypes from 'prop-types';

const useStyles = createUseStyles((theme) => ({
	containerStyles: {
		display: 'grid',
		gridGap: theme.spacing,
		width: '100%',
		gridTemplateColumns: ({ showPercent }) => (showPercent ? '1fr auto' : '1fr'),
		alignItems: 'center'
	},
	baseStyles: {
		height: ({ barSize }) =>
			barSize === 'small' ? theme.spacing - 2 : barSize === 'medium' ? theme.spacing : theme.spacing * 2,
		backgroundColor: ({ barBkgColor }) => (barBkgColor !== undefined ? barBkgColor : theme.colors.lightGrey),
		borderRadius: ({ barRadius }) => barRadius,
		margin: 0
	},
	fillerStyles: {
		display: 'block',
		height: '100%',
		width: ({ percentCompleted }) => `${percentCompleted}%`,
		backgroundColor: ({ percentCompleted }) =>
			`${percentCompleted <= 25
				? theme.colors.red
				: percentCompleted <= 75 ? theme.colors.yellow : theme.colors.green}`,
		transition: 'width 1s ease-in-out',
		borderRadius: 'inherit',
		textAlign: 'right'
	},
	labelStyles: {
		color: ({ percentCompleted }) =>
			`${percentCompleted <= 25
				? theme.colors.red
				: percentCompleted <= 75 ? theme.colors.yellow : theme.colors.green}`,
		fontWeight: 'bold',
		fontSize: ({ barSize }) =>
			barSize === 'small' ? 10 : barSize === 'medium' ? theme.spacing * 2 : theme.spacing * 3,
		lineHeight: ({ barSize }) => (barSize === 'small' ? 16 : barSize === 'medium' ? 24 : 32) + 'px',
		textAlign: 'left'
	}
}));

const ProgressBar = ({ barBkgColor, barSize = 'medium', barRadius = 50, showPercent = true, percentCompleted }) => {
	(percentCompleted < 0 || percentCompleted > 100 || !percentCompleted) && (percentCompleted = 0);
	const classes = useStyles({ barBkgColor, barSize, barRadius, showPercent, percentCompleted });
	return (
		<div className={classes.containerStyles}>
			<div className={classes.baseStyles}>
				<div className={classes.fillerStyles} />
			</div>
			{showPercent && <span className={classes.labelStyles}>{`${percentCompleted}%`}</span>}
		</div>
	);
};

/*
USAGE:
<ProgressBar percentCompleted={50} />
other settable props:
barSize={'small'}   //param: string, available 'small', 'medium' or 'large' //default: 'medium'
barRadius={0} //param: int // default: 50
showPercent={true} //param: true||false //default: true
barBkgColor={'#fff'} //param: hex val //default: theme.colors.xLightGrey (color from sketch)
*/

ProgressBar.propTypes = {
	barBkgColor: PropTypes.string,
	barSize: PropTypes.oneOf([ 'small', 'medium', 'large' ]),
	barRadius: PropTypes.number,
	showPercent: PropTypes.bool,
	percentCompleted: PropTypes.number
};

export default ProgressBar;
