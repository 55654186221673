export const SET_USERS_PAGINATION = "pagination/SET_USERS_PAGINATION";
export const SET_CHANNELS_PAGINATION = "pagination/SET_CHANNELS_PAGINATION";
export const SET_CHANNEL_MEMBERS_PAGINATION = "pagination/SET_CHANNEL_MEMBERS_PAGINATION";
export const SET_MEMBERSHIPS_PAGINATION = "pagination/SET_MEMBERSHIPS_PAGINATION";
export const SET_HISTORY_PAGINATION = "pagination/SET_HISTORY_PAGINATION";

export const setUsersPagination = (state) => {
    return {
        type: SET_USERS_PAGINATION,
        payload: state
    };
};

export const setChannelsPagination = (state) => {
    return {
        type: SET_CHANNELS_PAGINATION,
        payload: state
    };
};

export const setChannelMembersPagination = (
        channelId,
    state
) => {
    return {
        type: SET_CHANNEL_MEMBERS_PAGINATION,
        payload: {
            channelId,
            state
        }
    };
};

export const setMembershipsPagination = (
        uuid,
    state
) => {
    return {
        type: SET_MEMBERSHIPS_PAGINATION,
        payload: {
            uuid,
            state
        }
    };
};

export const setHistoryPagination = (channelId, state) => {
    return {
        type: SET_HISTORY_PAGINATION,
        payload: {
            channelId,
            state
        }
    };
};