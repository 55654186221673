import React from 'react';
import Radio from '../../Commons/Radio';
import {useForm} from "react-hook-form";
import Textarea from '../../Commons/Textarea';
import Button from '../../Commons/Button';
import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles(theme => ({
    formContainer: {
        display: "grid",
        gridRowGap: theme.spacing * 2,
        padding: theme.spacing * 2,
        '& h4': {
            fontWeight: "bold"
        }
    },
    customLabel: {
        textTransform: 'none!important',
        marginTop: theme.spacing * 4,
        '& span': {
            color: theme.colors.grey,
            marginBottom: theme.spacing * 2
        }
    },
    import:{
        '& span':{
            cursor: 'pointer'
        }
    },
    fakeBtn:{
        padding: [4 ,theme.spacing * 2],
        fontSize: 14,
        boxSizing: "border-box",
        border: `1px solid ${theme.colors.black}`,
        borderRadius: 4,
        textTransform: "uppercase",
        cursor: 'pointer'
    }
}));


export const CustomLabel = () => {
    const classes = useStyles();
    return (
        <div className={classes.customLabel}>
            <p>Incolla qui le informazioni</p>
            <span>Assicurati che le targhe siano separate de una virgola</span>
        </div>
    )
}

const ImportBody = ({importPlatesCb}) => {
    const classes = useStyles();
    const acceptedFiles = ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,text/plain";
    const {register, watch, handleSubmit} = useForm({
        defaultValues: {
            mode: 'onChange',
            reValidateMode: 'onChange',
            dataArea: 'csv'
        }
    });

    return <form className={classes.formContainer} onSubmit={handleSubmit(importPlatesCb)}>
                <h4>Seleziona la modalita' di importazione</h4>
                <Radio label={'Copia/Incolla da un file'} name="dataArea" value={'csv'} ref={register}/>
                <Radio label={'Importa CSV'} name="dataArea" value={'file'} ref={register}/>
                {watch('dataArea') === 'csv' ? <Textarea ref={register} label={<CustomLabel/>} rows={8}
                                                     className={classes.textArea} name={'plates'}/> :
                    <div className={classes.import}>
                        <label htmlFor="file-upload">
                            {!!watch('plates_file')?.length ? <span> File scelto: <b>{watch('plates_file')[0]?.name}</b> </span> : <span className={classes.fakeBtn}>Scegli File</span>}
                        </label>
                        <input id='file-upload' ref={register} type="file" name="plates_file" accept={acceptedFiles} style={{display: 'none'}}/>
                    </div>
                }
                <div style={{justifyContent: "end", display: "grid", marginTop: 24}}>
                    <Button data-primary type='submit' disabled={!(!!watch('plates_file')?.length) && !(!!watch('plates'))}>importa targhe</Button>
                </div>
            </form>
}

export default ImportBody