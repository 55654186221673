import React, {useState} from 'react';
import {createUseStyles} from 'react-jss';
import Table from "../../../Commons/Table.js";
import CustomEmptyRow from "../../../Commons/CustomEmptyRow.js";
import {formattedDate, toMoney} from "../../../helpers/utils.js";
import {RemoveIcon} from "../../../assets/icons.js";
import RepriceForm from "../../../Components/Stock/RepriceForm.js";
import {useDispatch} from 'react-redux';
import cx from "classnames";
import Popover from "../../../Commons/Popover.js";
import RepricePopover from "../../../Components/Stock/RepricePopover.js";

const useStyles = createUseStyles(theme => ({
    gridContainer: {
        minHeight: 500,
        maxHeight: 700
    },
    header:{
        display: "grid",
        gridTemplateColumns: "1fr auto",
        alignItems: 'center',
        gridColumnGap: theme.spacing,
        '& h4': {
            textAlign: "left",
            marginBottom: theme.spacing * 4,
            marginTop: theme.spacing * 4,
            'font-weight': 'bold'
        },
    },
    buttons: {
        display: "grid",
        gridTemplateColumns: "200px 200px",
        gridColumnGap: theme.spacing * 2,
        justifyContent: 'center',
        marginTop: theme.spacing * 8
    },
    trashIcon: {
        marginLeft: "auto"
    },
    repriceForm:{
        marginTop: theme.spacing * 3
    },
    dot: {
        width: 10,
        height: 10,
        borderRadius: '50%',
        marginRight: theme.spacing,
        '&:before': {
            content: '""'
        }
    },
    green: {
        backgroundColor: theme.colors.green,
    },
    yellow: {
        backgroundColor: theme.colors.yellow,
    }, red: {
        backgroundColor: theme.colors.red,
    },
}));

const CampaignReprice = ({campaignCars,loading,repriceCampaignCb,removeCarFromCampaignCb}) => {
    const classes = useStyles();
    const [repricePopover, setRepricePopover] = useState(false);
    const [repriceInfos, setRepriceInfos] = useState(null);

    const generateReprice = (values) => {
        if (values.currentType !== 'fixed') {
            campaignCars.map((item) =>
                item.reprice = (values.operation.value === 'add' ?
                    ((item.price * (+values.base_price) / 100) + item.price) :
                    (item.price - (item.price * (+values.base_price) / 100))));
        } else {
            campaignCars.map((item) =>
                item.reprice = (values.operation.value === 'add' ?
                    (item.reprice = item.price + (+values.base_price)) :
                    (item.reprice = item.price - (+values.base_price))));
        }
        //set reprice infos
        setRepriceInfos({
            type: values.currentType,
            amount: +values.base_price,
            operation: 'subtract',
            cars: campaignCars,
        });
        //set confirmation popover
        setRepricePopover(!repricePopover);
    };

    const repriceConfirm = async () => {
        await repriceCampaignCb({...repriceInfos,cars: repriceInfos.cars.map(item => item.id)});
        setRepricePopover(!repricePopover);
    };


    return <>
        {repricePopover && <Popover onClose={() => setRepricePopover(!repricePopover)} width={650}>
            <RepricePopover confirmReprice={repriceConfirm}
                            repriceInfos={repriceInfos} loading={loading}
                            deleteReprice={() => setRepricePopover(!repricePopover)}/>
        </Popover>}
        <div className={classes.gridContainer}>
            <div className={classes.header}>
                <h4 className={classes.tableTitle}>Auto in campagna</h4>
                <RepriceForm subtractOnly={true} repriceCb={generateReprice}
                             selectionIsNotEmpty={!!campaignCars.length}
                             className={classes.repriceForm}/>
            </div>
            <Table
                id={'table'}
                data={campaignCars}
                inLoading={loading}
                onSetPageCb={Function.prototype}
                onSortingDataCb={Function.prototype}
                customEmptyRow={CustomEmptyRow}
                noHeightLimit={true}
                theadHeight={48}
                rowMinHeight={48}
                classNames={{
                    table: classes.table,
                    thead: classes.thead,
                    row: classes.row,
                }}
                columns={['brand', 'model', 'version', 'days_in_stock', 'plate', 'mileage', 'fuel_method', 'owning_company', 'matriculation', 'current_position','price','retail_price','delete']}
                columnsNames={{
                    brand: "Marca",
                    model: "Modello",
                    version: "Versione",
                    days_in_stock: 'Giacenza',
                    plate: "Targa",
                    mileage: 'Km',
                    fuel_method: "Alimentazione",
                    owning_company: "Azienda",
                    matriculation: "Immatricolazione",
                    current_position: "Ubicazione",
                    price: 'Prezzo',
                    retail_price: "Reprice",
                    delete: ''
                }}
                columnsWidths={{
                    brand: "10%",
                    model: "10%",
                    version: "10%",
                    days_in_stock: '8%',
                    plate: "5%",
                    mileage: '5%',
                    fuel_method: '7%',
                    owning_company: "8%",
                    matriculation: "8%",
                    current_position: "10%",
                    price: '7%',
                    retail_price: "7%",
                    delete: '2%'
                }}
                columnsAlignments={{
                    actions: 'right',
                }}
                backEndSortable={true}
                sortable={true}
                headersSorters={['days_in_stock', 'mileage']}
                columnsRenderers={{
                    brand: (value, item) => <strong>{item?.model?.brand?.name}</strong>,
                    model: (value, item) => <span>{value?.name}</span>, /*  */
                    price: (value, item) => <strong>{value ? toMoney(value) : '-'}</strong>,
                    days_in_stock: (item) => <span>
                                <span className={cx(classes.dot, item <= 25 ? classes.green : item <= 75 ? classes.yellow : classes.red)}/>
                        {item}
                            </span>,
                    mileage: (value, item) => <span>{value}</span>,
                    location: (value, item) => <span>{value}</span>,
                    matriculation: (value, item) => <span>{formattedDate(value)}</span>,
                    retail_price: (item) => <strong style={{color: '#4ac5ff'}}>{item ? toMoney(item) : '-'}</strong>,
                    delete: (value, items) => <RemoveIcon onClick={() => removeCarFromCampaignCb(items)}
                                                         className={classes.trashIcon}/>,
                }}
            />
        </div>
    </>
}

export default CampaignReprice