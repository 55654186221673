import React, {useState, useEffect} from "react";
import NewCommunication from "./NewCommunication";
import SubHeading from "../../Commons/SubHeading";
import Button from "../../Commons/Button";
import Table from "../../Commons/Table";
import {AddIcon} from "../../assets/icons";
import {createUseStyles} from "react-jss";
import noAvatar from '../../assets/images/noAvatar.png'
import CommunicationDetails from "./CommunicationDetails";
import {httpGetCommunications} from "../../HttpRequests/commons.http";
import {setBusinessList} from "../../store/actions/settingsActions";
import {errorHandler, formattedDateHours, formattedDateIso} from "../../helpers/utils";
import {useDispatch, useSelector} from "react-redux";
import {setCommunicationsList} from "../../store/actions/communicationsActions";
import {getCommunicationsList} from "../../store/reducers/communications";
import ToggleSwitch from "../../Commons/ToggleSwitch";
import AutoLink from "../../Commons/AutoLink";


const useStyles = createUseStyles(theme => ({
    gridContainer: {
        'position': 'relative',
        height: ({bigSubHeader}) => `calc(100vh - ${bigSubHeader ? theme.bigSubHeader + theme.headerHeight : theme.smallSubHeader + theme.headerHeight}px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        [theme.mUp]: {
            padding: [theme.spacing * 4, theme.spacing * 4, 0],
        },
        [theme.s]: {
            padding: [theme.spacing],
        },
    },
    sentBy: {
        maxWidth: 128,
        padding: [4, 8],
        color: theme.colors.white,
        backgroundColor: '#787DFF',
        borderRadius: 8
    },
    receiverPhoto: {
        width: 32,
        height: 32,
        border: '1px solid #FFFFFF',
        borderRadius: 28,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        gridArea: '1 / 2 / span 2 / span 1',
        zIndex: 0,
        '&:first-child':{
            gridArea: '1 / 1 / span 2 / span 1',
            zIndex: 1
        }

    },
    receiversPhotos: {
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '24px 24px 24px',
        gridTemplateRows: '32px'
    },
    receiversCounter: {
        alignSelf: 'center',
        justifySelf: 'end',
        fontWeight: 600
    },
    message: {
        maxWidth: 600,
    },
    details: {
        cursor: 'pointer',
        textDecoration: 'underline'
    },
    dateTime: {
        display: 'grid',
        gridTemplateRows: '1fr 1fr'
    },
    date: {

    },
    time: {
        color: '#737373'
    },
    filter: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: [0, 0, 16, 0]
    }
}))

const Communications = () => {
    const communicationsList = useSelector(getCommunicationsList);
    const dispatch = useDispatch();
    const [openNewCommunication, setOpenNewCommunication] = useState(false);
    const [openDetail, setOpenDetail] = useState(null);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({system_communications: 0});
    const classes = useStyles({bigSubHeader: false})

    useEffect(() => {
        getCommunications()
    }, [])

    useEffect(() => {
        if (!!Object.keys(filters).length) {
            setPage(1);
            setLastPage(null);
            getCommunications();
        }
    }, [filters]);

    //fetch when page change
    useEffect(() => {
        if (!!communicationsList.length && page !== 1) {
            getCommunications(page)
        }
    }, [page]);

    const getCommunications = async (currentPage = 1) => {
        if(currentPage === 1)  dispatch(setBusinessList([]));
        setLoading(true);
        try {
            const {data: {data, last_page}} = await httpGetCommunications({limit: 100, ...filters, page: currentPage});
            if(currentPage === 1){
                dispatch(setCommunicationsList(data));
            }else{
                dispatch(setCommunicationsList(communicationsList.concat(data)));
            }
            setLastPage(last_page);
            console.log('data', data)
        }catch(e){
            errorHandler(e,dispatch)
        } finally {
            setLoading(false);
        }
    }

    const onChangePage = () => {
        if ((lastPage && page + 1) > lastPage) return;
        setPage(page + 1)
    };

    const onFiltering = (systemOnly) => {
        console.log('filter only_system_communications is', systemOnly)
        setFilters({system_communications: systemOnly ? 1 : 0}); // backend accept 1/0  instead of true/false in query params
        setPage(1);
    };


    const Cta = () => {
        return <Button data-primary icon={<AddIcon/>} onClick={()=> setOpenNewCommunication(true)}>
            Inserisci comunicazione
        </Button>
    }

    const CreationDate = ({date}) => {
        return <span className={classes.dateTime}>
            <span className={classes.date}>{formattedDateIso(date)}</span>
            <span className={classes.time}>{formattedDateHours(date)}</span>
        </span>
    }

    const SentBy = ({sender}) => {
        return sender ? <span>{sender.full_name}</span>
            : <span className={classes.sentBy}>Sistema OCH</span>
    }

    const Message = ({message}) => {
        return <span className={classes.message}><AutoLink text={message}/></span>
    }

    const Receivers = ({receivers}) => {
        return <span className={classes.receiversPhotos}>
                {receivers.length > 1 ?
                    <span className={classes.receiverPhoto} style={{ backgroundImage: `url(${receivers[1].photo_link || noAvatar})`}}/>
                    :
                    <span/>
                }
                <span className={classes.receiverPhoto} style={{ backgroundImage: `url(${receivers[0].photo_link || noAvatar})`}}/>
                <span className={classes.receiversCounter}>{receivers.length}</span>
            </span>
    }

    const Details = ({communicationId}) => {
        const communication = communicationsList.find(c => c.id === communicationId);
        return <span className={classes.details} onClick={() => setOpenDetail(communication)}>DETTAGLI</span>
    }

    return <>
        <SubHeading title={"Comunicazioni"}
                    type={'small'}
                    rightSearchComponent={<Cta/>}
        />

        <section className={classes.gridContainer}>
            <div className={classes.filter}>
                <ToggleSwitch label={'Visualizza messaggi di sistema'}
                              labelOn={'right'}
                              isSmall={false}
                              onClick={(e) => onFiltering(e.target.checked)}
                              defaultChecked={false}
                />
            </div>
            <Table
                id={'table'}
                data={communicationsList}
                setData={() => getCommunications()}
                theadHeight={40}
                rowMinHeight={40}
                onSetPageCb={onChangePage}
                inLoading={loading}
                classNames={{
                    table: classes.table,
                    thead: classes.thead,
                    row: classes.row,
                }}
                columns={['created_at', 'sender', 'message', 'users', 'id']}
                columnsNames={{
                    created_at: "Data",
                    sender: "Inviato da",
                    message: "Messaggio",
                    users: '',
                    id: ''
                }}
                columnsWidths={{
                    created_at: "10%",
                    sender: "10%",
                    message: "65%",
                    users: '8%',
                    id : "6%"
                }}
                columnsAlignments={{
                    id: 'right',
                }}
                columnsRenderers={{
                    created_at: date => <CreationDate date={date}/>,
                    sender: sender => <SentBy sender={sender}/> ,
                    message: msg => <Message message={msg}/>,
                    users: receivers => <Receivers receivers={receivers}/>,
                    id: id => <Details communicationId={id}/>
                }}
            />
        </section>
        {openNewCommunication && <NewCommunication onCloseCb={() => setOpenNewCommunication(false)}/>}
        {openDetail && <CommunicationDetails message={openDetail.message}
                                             receivers={openDetail.users}
                                             onCloseCb={() => setOpenDetail(null)}
        />}
    </>
}


export default Communications;