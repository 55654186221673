import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import cx from 'classnames';
import logo from "../assets/images/bianco.svg";
import ochMark from "../assets/images/ochmarkmenu.png";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {MenuIcon, MenuOpenIcon} from "../assets/icons.js";

const useStyles = createUseStyles(theme => ({
	icon: {
		fill: theme.colors.black,
		[theme.s]: {
			'z-index': 3,
			'position': 'fixed',
			'top': 20,
			'left': 8,
			width: `24px!important`,
		},
		[theme.mUp]: {
			'display': 'none',
			width: `40px!important`,
		},
	},
	root: {
		position: 'relative',
		padding: ({collapsedNav}) => collapsedNav ? `${theme.spacing * 2}px` : `${theme.spacing * 5}px ${theme.spacing * 2}px ${theme.spacing * 5}px ${theme.spacing * 2}px`,
		gridArea: "nav",
		background: 'linear-gradient(146deg, #000000 0%, #272727 100%)',
		overflow: "hidden",
		height: '100vh',
		gridAutoRows: "max-content",
		gridRowGap: "160px",
		/*'transform': ({collapsedNav}) => collapsedNav ? 'scaleX(1)' : 'scaleX(1)',*/
		'transition': '0.3s linear all',
		[theme.s]: {
			'display': 'grid',
			'padding-top': 50,
			'position': 'fixed',
			'width': '60vw',
			'height': `100%`,
			'top': 0,
			'left': 0,
			'z-index': 10,
			'transform': ({menu}) => menu ? 'translateX(0%)' : 'translateX(-100%)',
			'transition': '0.25s linear all',
		},
		[theme.mUp]: {
			'display': 'grid',
		},
	},
	overlay: {
		[theme.s]: {
			display: ({menu}) => menu ? 'block' : 'none',
			'z-index': 1,
			'background-color': 'rgba(0,0,0,0.5)',
			top: "0px",
			left: "0px",
			width: "auto",
			height: "auto",
			zIndex: "1",
			position: "fixed",
			right: "0",
			bottom: "0"
		},
		[theme.mUp]: {
			'display': 'none',
		},
	},
	heading: {
		display: "grid",
		gridRowGap: "8px",
		alignItems: 'center',
		gridTemplateColumns: ({collapsedNav}) => collapsedNav ? '1fr' : '1fr auto',
		'& svg': {
			fill: theme.colors.white,
			width: `32px`,
		}
	},
	voices: {
		display: ({collapsedNav}) => collapsedNav ? "none" : "grid",
		gridRowGap: "32px",
		color: theme.colors.white,
		'& a': {
			textDecoration: "none",
			color: theme.colors.white,
			'&:visited': {
				color: theme.colors.white,
			}
		}
	},
	foo: {
		display: ({collapsedNav}) => collapsedNav ? "none" : "block",
		left: "16px",
		position: "absolute",
		bottom: 48,
		fontWeight: 500,
		color: theme.colors.white
	},
	mark:{
		left: "-83px",
		bottom: "-42px",
		height: "420px",
		position: "absolute",
		maxWidth: "420px"
	}
}));

const Nav = ({className, setCollapsedNav, collapsedNav,sidebar:Sidebar,permissions}) => {
	//todo use user permission adn menuVoices for content managing
	const dispatch = useDispatch();
	const [menu, setMenu] = useState(false);
	const classes = useStyles({collapsedNav, menu});

	useEffect(() => {
		window.document.body.style['overflow-y'] = 'auto';
		return () => window.document.body.style['overflow-y'] = 'auto';
	});

	const toggleMenu = () => {
		setMenu(!menu);
		// prevent scolling of body when menu is open (mobile only)
		window.document.body.style['overflow-y'] = menu ? 'auto' : 'hidden';
		return true;
	};
	return <>
		<MenuIcon className={classes.icon} data-color onClick={toggleMenu}/>
		<div className={classes.overlay} onClick={toggleMenu}/>

		<div className={cx(classes.root, className)}>
			<div className={classes.heading}>
				<Link to={"/"}><img src={logo} alt='logo'/></Link>
				{collapsedNav ? <MenuIcon data-color onClick={setCollapsedNav}/> :
					<MenuOpenIcon data-color onClick={menu ? toggleMenu : setCollapsedNav}/>}
			</div>
			{Sidebar ? <Sidebar collapsedNav={collapsedNav} permissions={permissions}/>:null}
			<div className={classes.foo}>
				<small>Och® 2020</small>
			</div>
			<img src={ochMark} alt={'logo mark'} className={classes.mark}/>
		</div>
	</>
};

export default Nav;
