export const SET_CAR_STATUSES_LIST = 'SET_CAR_STATUSES_LIST';
export const SET_USERS_AUTOCOMPLETE = 'SET_USERS_AUTOCOMPLETE';
export const SET_COMPANIES_AUTOCOMPLETE = 'SET_COMPANIES_AUTOCOMPLETE';
export const SET_LOCATIONS_AUTOCOMPLETE = 'SET_LOCATIONS_AUTOCOMPLETE';
export const SET_LOCATIONS_SIMPLE_AUTOCOMPLETE = 'SET_LOCATIONS_SIMPLE_AUTOCOMPLETE';
export const SET_FAMILIES_AUTOCOMPLETE = 'SET_FAMILIES_AUTOCOMPLETE';
export const SET_STRUCTURES_AUTOCOMPLETE = 'SET_STRUCTURES_AUTOCOMPLETE';
export const SET_FUEL_METHODS_AUTOCOMPLETE = 'SET_FUEL_METHODS_AUTOCOMPLETE';

/**
 * How to use setCarStatusesBartersList:
 * dispatch(setCarStatusesList(statuses));
 **/
export const setCarStatusesList = (carStatuses) => {
    return {
        type: SET_CAR_STATUSES_LIST,
        carStatuses: carStatuses
    };
};

/**
 * How to use setUsersAutocomplete:
 * dispatch(setUsersAutocomplete(usersAutocomplete));
 **/
export const setUsersAutocomplete = (users) => {
    return {
        type: SET_USERS_AUTOCOMPLETE,
        users: users
    };
};

/**
 * How to use setBusinessAutocomplete:
 * dispatch(setBusinessAutocomplete(businessesAutocomplete));
 **/
export const setCompaniesAutocomplete = (businesses) => {
    return {
        type: SET_COMPANIES_AUTOCOMPLETE,
        businesses: businesses
    };
};

/**
 * How to use setLocationsAutocomplete:
 * dispatch(setLocationsAutocomplete(locations));
 **/
export const setLocationsAutocomplete = (locations) => {
    return {
        type: SET_LOCATIONS_AUTOCOMPLETE,
        locations: locations
    };
};

/**
 * How to use setLocationsSimpleAutocomplete:
 * dispatch(setLocationsSimpleAutocomplete(locations));
 **/
export const setLocationsSimpleAutocomplete = (locations) => {
    return {
        type: SET_LOCATIONS_SIMPLE_AUTOCOMPLETE,
        locations: locations
    };
}

/**
 * How to use setFamiliesAutocomplete:
 * dispatch(setFamiliesAutocomplete(families));
 **/
export const setFamiliesAutocomplete = (families) => {
    return {
        type: SET_FAMILIES_AUTOCOMPLETE,
        families: families
    };
};

/**
 * How to use setStructuresAutocomplete:
 * dispatch(setStructuresAutocomplete(families));
 **/
export const setStructuresAutocomplete = (structures) => {
    return {
        type: SET_STRUCTURES_AUTOCOMPLETE,
        structures: structures
    };
};

/**
 * How to use setFuelMethodsAutocomplete:
 * dispatch(setFuelMethodsAutocomplete(families));
 **/
export const setFuelMethodsAutocomplete = (fuelMethods) => {
    return {
        type: SET_FUEL_METHODS_AUTOCOMPLETE,
        fuelMethods: fuelMethods
    };
};