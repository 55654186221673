import React, {useCallback} from 'react';
import {createUseStyles} from 'react-jss';
import {useForm} from 'react-hook-form';
import {debounce} from 'lodash-es';
import Text from "./Text.js";
import {SearchIcon} from "../assets/icons.js";

const useStyles = createUseStyles(theme => ({
    root: {
        position: 'relative',
        'width': '100%',
        /*[theme.s]: {
            'grid-auto-flow': 'row',
        },
        [theme.mUp]: {
            /!* 'grid-template-columns': ({withSelects}) => withSelects ? `auto ${withSelects.map( () => 'auto')}  repeat(2, max-content)`: '1fr repeat(2, max-content)',*!/
            gridTemplateColumns: 'repeat(auto-fll, minmax(160px, 270px))'
        },*/
        'align-items': 'end',
        'justify-content': 'start',
    },

    search: {
        'justify-self': 'left',
        [theme.s]: {
            /* display: 'none',*/
        },
        [theme.mUp]: {
            display: 'grid',
            'max-width': ({fullWidth}) => fullWidth ? '100%' : 240,
            'min-width': 132,
        },
    },
    label: {
        ...theme.control.label
    }
}));

const SimpleSearch = ({setFilters, filters,name, placeholder, setPage, onComplexSearchCb,fullWidth = false}) => {
    const {register, handleSubmit, watch} = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
    });

    const classes = useStyles({fullWidth});

    const submit = () => {
        setPage(1);
        if (onComplexSearchCb ) {
            onComplexSearchCb({
                ...watch(),
                order_by: null,
                order_direction: null,
            })
        } else {
            setFilters({
                ...watch(),
                order_by: null,
                order_direction: null,
            })
        }
    };

    const submitDebounced = useCallback(debounce(() => submit(), 750), []);
    const submitInstant = () => {
        submitDebounced.cancel();
        submit();
    };

    return (
        <form className={classes.root} noValidate={true} onSubmit={handleSubmit(submitInstant)}>
            <Text className={classes.search} type={'text'} ref={register} name={name}
                  defaultValue={filters[name]}
                  placeholder={placeholder} icon={<SearchIcon data-color onClick={submitInstant}/>}/>
        </form>
    );
};

export default SimpleSearch;
