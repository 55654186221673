import React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme) => ({
    root: {
        gridArea: 'header',
        ...theme.paper,
        ...theme.shadow,
        height: 'auto',
        display: 'grid',
        gridTemplateColumns: '1fr',
        padding: [theme.spacing * 2, theme.spacing * 4],
        alignItems: 'center',
        '& h3':{
            fontWeight:600,
            fontSize:28
        }
    },
}));

const ExternalSubHeading = ({className, title, justifySelf = 'start'}) => {
    const classes = useStyles({ justifySelf });

    return <main className={cx(classes.root, className)}>
            <h3>{title}</h3>
        </main>
};

export default ExternalSubHeading;
