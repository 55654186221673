import React from "react";
import {createUseStyles} from "react-jss";
import ConversationHeader from "./Header/Header";
import MessageList from "./MessageList/MessageList";
import TypingIndicatorDisplay from "./TypingIndicatorDisplay/TypingIndicatorDisplay";
import MessageInput from "./MessageInput/MessageInput";


const useStyles = createUseStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#EDEDED',
        width: '100%',
        [theme.s]: {
            margin: 0,
            position: 'fixed',
            zIndex: 200,
            height: '100%',
        }
    }
}))


const CurrentConversation = () => {

    const classes = useStyles();
    return <section className={classes.root}>
        <ConversationHeader/>
        <MessageList />
        <MessageInput/>
        {/*<TypingIndicatorDisplay />*/}
    </section>
}

export default CurrentConversation;