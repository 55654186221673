import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import Badge from './Badge';
import {getLabelByValue, taskStatuses} from '../helpers/utils.js';

const taskStatusColors = {
    open: 'primary',
    active: 'warning',
    closed: 'success'
};

const formatOptionLabel = ({value, label}) => (
    <div style={{display: 'flex'}}>
        <Badge title={label} statusColor={taskStatusColors[value]} badgeSize={'small'}/>
    </div>
);

const style = {
    control: (base, state) => ({
        ...base,
        border: '0 !important',
        boxShadow: '0 !important',
        padding: '0',
        minHeight: 'auto',
        cursor: 'pointer',
        '&:hover': {
            border: '0 !important'
        },
        '& div': {
            justifyContent: 'center'
        },
        '& > div:first-of-type': {
            padding: '0 !important'
        },
        '& > div:last-of-type': {
            display: 'none'
        }
    }),
    option: (base, state) => ({
        ...base,
        padding: 5,
        '& div': {
            justifyContent: 'center'
        }
    })
};

const DropdownStatuses = ({options, actualStatus, isActive, callback,isMyTask}) => {
    const [selectedStatus, setSelectedStatus] = useState('');
    useEffect(() => {
            setSelectedStatus(options.filter((item) => item.value === actualStatus));
        }, [actualStatus]
    );

    const onChange = (option) => {
        setSelectedStatus(option)
        callback({ status: option.value });
    };

    return isActive  ? (
        <Select formatOptionLabel={formatOptionLabel}
            options={options}
            styles={style}
            onChange={onChange}
            value={selectedStatus}
        />
    ) : (
        <span>
			<Badge
                title={actualStatus && getLabelByValue(taskStatuses, actualStatus)}
                statusColor={taskStatusColors[actualStatus]}
                badgeSize={'small'}
            />
		</span>
    );
};

export default DropdownStatuses;
