import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import Spinner from "../../Commons/Spinner";
import Text from "../../Commons/Text";
import {alertToggle} from "../../store/actions/alertActions.js";
import {httpEditCarInfo} from "../../HttpRequests/barters.http.js";
import DayPickerInput from "../../Commons/DayPickerInput.js";
import Select from "../../Commons/Select.js";
import {
    acquisitionChannels,
    carStatus, categories, clearDamagesForBackend,
    errorHandler,
    gearBoxTypes,
    retrieveAccessoriesForRhf,
    retrieveValueForRs,
    salesChannels,
    simplifiedCarColors,
    simplifiedInteriors
} from "../../helpers/utils.js";
import {setCurrentBarter} from "../../store/actions/bartersActions.js";
import Checkbox from "../../Commons/Checkbox";
import {setCurrentCar} from "../../store/actions/carsActions";

const useStyles = createUseStyles(theme => ({
    root: {
        position: 'relative',
        'display': 'grid',
        'grid-auto-flow': 'row',
        'grid-row-gap': theme.spacing * 3,
        'grid-column-gap': theme.spacing * 5,
        [theme.s]: {
            width: 'auto',
            gridTemplateColumns: '1fr',
            justifyContent: "center",
        },
        [theme.mUp]: {
            'width': '100%',
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            justifyContent: "center",
        },
    },

    sectionTitle: {
        fontWeight: 600,
        textAlign: "left",
        gridColumnStart: 1,
        gridColumnEnd: 5,
        fontSize: 16,
        padding: [theme.spacing * 2, 0, 0],
        borderTop: `1px solid ${theme.colors.lightGrey}`,
        [theme.s]: {
            gridColumnEnd: 1,
        },
        [theme.mUp]: {
            gridColumnStart: 1,
            gridColumnEnd: 5,
        },
    },
}));

//car obj in sycn with bartersStore
const CarInfoForm = ({car, accessories, canEditField, isStock = false, isNewCar = false,isBarter=false}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const familiesAutocomplete = useSelector(state => state.autocompletes.families);
    const structuresAutocomplete = useSelector(state => state.autocompletes.structures);
    const fuelMethodAutocomplete = useSelector(state => state.autocompletes.fuelMethods);
    const damages = useSelector(state => state.barters.damages);
    const {register, watch, control, setValue} = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            ...car,
            brand: car?.model?.brand.name,
            model: car?.model?.name,
            matriculation: car?.matriculation ? new Date(car.matriculation) : null,
            latest_revision: car?.latest_revision ? new Date(car.latest_revision) : null,
            retrieve_date: car?.retrieve_date ? new Date(car.retrieve_date) : null,
            gearbox: retrieveValueForRs(gearBoxTypes, car?.gearbox),
            sales_channel: retrieveValueForRs(salesChannels, car?.sales_channel),
            type: retrieveValueForRs(carStatus, car?.type),
            simplified_color: retrieveValueForRs(simplifiedCarColors, car?.simplified_color),
            simplified_interiors: retrieveValueForRs(simplifiedInteriors, car?.simplified_interiors),
            category: retrieveValueForRs(categories,car?.category),
            acquisition_channel: retrieveValueForRs(acquisitionChannels,car?.acquisition_channel),
            ...retrieveAccessoriesForRhf(car?.accessories)
        },
    });

    useEffect(() => {
        if(car){
            setValue('family', retrieveValueForRs(familiesAutocomplete, car?.family))
            setValue('structure',retrieveValueForRs(structuresAutocomplete, car?.structure));
            setValue('fuel_method',retrieveValueForRs(fuelMethodAutocomplete, car?.fuel_method))
        }
    },[familiesAutocomplete,structuresAutocomplete,fuelMethodAutocomplete])

    const editCarInfo = async (fieldName, value, label, isAccessory = false) => {
        if ((value === null || value === '') || car[fieldName]?.toString() === value.toString()) return;
        const dataToSend = isAccessory ?
            {
                car: {
                    id: car.id,
                    ...(isStock && car),
                    ...(isNewCar && {is_draft: true}),
                },
                accessories: {
                    ...retrieveAccessoriesForRhf(accessories),
                    [fieldName]: value
                },
            } : {
                car: {
                    id: car.id,
                    ...(isStock && car),
                    [fieldName]: value,
                    ...((isNewCar || isBarter) && {is_draft: true}),
                },
                damages: clearDamagesForBackend(damages),
            };
        try {
            const {data} = await httpEditCarInfo(dataToSend,isNewCar);
            isStock ? dispatch(setCurrentCar(data)) : dispatch(setCurrentBarter(data));

            if (isAccessory) {
                dispatch(alertToggle(`${label} ${value ? 'aggiunto agli' : 'rimosso dagli'} accessori.`, 'success'))
            } else {
                dispatch(alertToggle(`Il campo ${label} è stato modificato correttamente.`, 'success'))
            }
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setIsLoading(false);
        }
    };

    return <form className={classes.root} /*onSubmit={handleSubmit(onSubmit)}*/>
        {isLoading && <Spinner/>}
        <h4 className={classes.sectionTitle}> Informazioni vettura</h4>
        <Text type={'text'} label={'Targa*'} name={'plate'} ref={register} disabled={true}/>
        <Text type={'text'} label={'Telaio*'} name={'VIN'} ref={register} disabled={!canEditField}
              onBlur={() => editCarInfo('VIN', watch('VIN'), 'Telaio*')}/>
       {/* <Text type={'text'} label={'Carrozzeria*'} name={'structure'} ref={register} disabled={true}/>*/}
        <Select name={'structure'} options={structuresAutocomplete} ref={register} control={control} disabled={!canEditField}
                label={`Carrozzeria`} isClearable={false} onChange={(value) => {
            editCarInfo('structure', value[0].value, `Carrozzeria`);
            return value
        }}/>
        <Select name={'category'} options={categories} ref={register} control={control} disabled={!canEditField}
                label={`Categoria`} isClearable={false} onChange={(value) => {
            editCarInfo('category', value[0].value, `Categoria`);
            return value
        }}/>

        <Text type={'text'} label={'Classe Emissioni*'} name={'euro'} ref={register} disabled={!canEditField}
              onBlur={() => editCarInfo('euro', watch('euro'), 'Classe Emissioni')}/>
        <Text type={'text'} label={'Marca*'} name={'brand'} ref={register} disabled={true}/>
        <Text type={'text'} label={'Modello*'} name={'model'} ref={register} disabled={true}/>
        <Select name={'family'} options={familiesAutocomplete} ref={register} control={control} disabled={!canEditField}
                label={`Famiglia*`} isClearable={false} onChange={(value) => {
            editCarInfo('family', value[0].value, `Famiglia`);
            return value
        }}/>
        <Text type={'text'} label={'Versione*'} name={'version'} ref={register} disabled={!canEditField}
              onBlur={() => editCarInfo('version', watch('version'), 'Versione')}/>

        <Text type={'text'} label={'Cilindrata*'} name={'cylinder_capacity'} ref={register} disabled={!canEditField}
              onBlur={() => editCarInfo('cylinder_capacity', watch('cylinder_capacity'), 'Cilindrata')}/>
        <Text type={'text'} label={'kWatt*'} name={'engine_power_kw'} ref={register} disabled={!canEditField}
              onBlur={() => editCarInfo('engine_power_kw', watch('engine_power_kw'), 'kWatt')}/>
        <Text type={'number'} label={'Peso*'} name={'weight'} ref={register} disabled={!canEditField}
              onBlur={() => editCarInfo('weight', watch('weight'), 'Peso')}/>
        <Text type={'text'} label={'CV'} name={'horsepower'} ref={register} disabled={!canEditField}
              onBlur={() => editCarInfo('horsepower', watch('horsepower'), 'CV')}/>
        <Select name={'fuel_method'} options={fuelMethodAutocomplete} ref={register} control={control} disabled={!canEditField}
                label={`Alimentazione*`} isClearable={false} onChange={(value) => {
            editCarInfo('fuel_method', value[0].value, `Alimentazione`);
            return value
        }}/>
        <Text type={'text'} label={'Porte'} name={'doors'} ref={register} disabled={!canEditField}
              onBlur={() => editCarInfo('doors', watch('doors'), 'Porte')}/>
        <Text type={'text'} label={'Posti*'} name={'seats'} ref={register} disabled={!canEditField}
              onBlur={() => editCarInfo('seats', watch('seats'), 'Posti')}/>

        <Text type={'text'} label={'km percorsi*'} name={'mileage'} ref={register} disabled={true}/>
        <Select name={'gearbox'} options={gearBoxTypes} ref={register} control={control} disabled={!canEditField}
                label={`Cambio*`} isClearable={false} onChange={(value) => {
            editCarInfo('gearbox', value[0].value, `Cambio`);
            return value
        }}/>
        <DayPickerInput label={'Data immatricolazione*'} name={'matriculation'} inForm={true} ref={register}
                        control={control} closeOnSelect={true} disabled={true}/>
        <DayPickerInput label={'Scadenza revisione'} name={'latest_revision'} inForm={true} ref={register}
                        disabled={!canEditField}
                        callback={editCarInfo} control={control} closeOnSelect={true}/>

        <Select name={'sales_channel'} options={salesChannels} ref={register} control={control} disabled={!canEditField}
                label={`Canale di vendita*`} isClearable={false} onChange={(value) => {
            editCarInfo('sales_channel', value[0].value, `Canale di vendita`);
            return value
        }}/>
        <DayPickerInput label={'Previsto ritiro'} name={'retrieve_date'} inForm={true} ref={register}
                        disabled={!canEditField}
                        callback={editCarInfo} control={control} closeOnSelect={true}/>
        <Select name={'type'} options={carStatus} ref={register} control={control} disabled={!canEditField}
                label={`Stato vettura all'acquisto`} isClearable={false} onChange={(value) => {
            editCarInfo('type', value[0].value, `Stato vettura all'acquisto`);
            return value
        }}/>
        <Select name={'acquisition_channel'} options={acquisitionChannels} ref={register} control={control} disabled={!canEditField}
                label={`Canale di acquisizione`} isClearable={false} onChange={(value) => {
            editCarInfo('acquisition_channel', value[0].value, `Canale di acquisizione`);
            return value
        }}/>


        <h4 className={classes.sectionTitle}> Esterno e interno </h4>
        <Text type={'text'} label={'Colore esterno'} name={'color'} ref={register} disabled={true}
              onBlur={() => editCarInfo('color', watch('color'), 'Colore esterno')}/>
        <Select name={'simplified_color'} options={simplifiedCarColors} ref={register} control={control}
                disabled={!canEditField}
                label={`Colore esterno ( semplificato )`} isClearable={false} onChange={(value) => {
            editCarInfo('simplified_color', value[0].value, `Colore interno ( semplificato )`);
            return value
        }}/>
        <Text type={'text'} label={`Tipo interni`} name={'interiors_type'} ref={register} disabled={true}
              onBlur={() => editCarInfo('interiors_type', watch('interiors_type'), 'Tipo interni')}/>
        <Select name={'simplified_interiors'} options={simplifiedInteriors} ref={register} control={control}
                disabled={!canEditField}
                label={`Tipo Interni ( semplificato )`} isClearable={false} onChange={(value) => {
            editCarInfo('simplified_interiors', value[0].value, `Tipo Interni ( semplificato )`);
            return value
        }}/>

        <Text type={'text'} label={'Colore interno'} name={'color_interiors'} ref={register} disabled={true}
              onBlur={() => editCarInfo('color_interiors', watch('color_interiors'), 'Colore interno')}/>
        <Text type={'text'} label={`Tappezzeria`} name={'upholstery'} ref={register} disabled={true}
              onBlur={() => editCarInfo('upholstery', watch('upholstery'), 'Tappezzeria')}/>

        <h4 className={classes.sectionTitle}> Pneumatici e cerchi </h4>
        <Text type={'number'} label={`Dimensione pneumatici`} name={'tire_size'} ref={register} disabled={!canEditField}
              onBlur={() => editCarInfo('tire_size', watch('tire_size'), 'Dimensione pneumatici')}/>
        <Text type={'number'} label={`Dimensione cerchi*`} name={'rim_size'} ref={register} disabled={!canEditField}
              onBlur={() => editCarInfo('rim_size', watch('rim_size'), 'Dimensione cerchi')}/>
        {/* <DevTool control={control}/>*/}

        {accessories && <>
            <h4 className={classes.sectionTitle}> Accessori </h4>
            {accessories.map((accessory, index) => <Checkbox key={index} label={accessory.label} isSmall={true}
                                                             name={accessory.label} disabled={!canEditField}
                                                             ref={register}
                                                             onChange={() => editCarInfo(accessory.label, watch(accessory.label), accessory.label, true)}/>)}
        </>}
    </form>
};

export default CarInfoForm;
