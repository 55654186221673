import React from 'react';
import ToggleSwitch from "../../Commons/ToggleSwitch.js";
import Select from "react-select";
import DayPickerInput from "../../Commons/DayPickerInput.js";
import {createUseStyles, useTheme} from "react-jss";

const useStyles = createUseStyles(theme => ({
    root: {
        position: 'relative',
        'width': '100%',
        'display': 'grid',
         gridTemplateColumns: '1fr auto',
        gridColumnGap: theme.spacing * 4,
        alignItems: 'center',
        'align-items': 'center',
        'justify-content': 'start',
        'grid-column-gap': theme.spacing * 2,
    },

    filters:{
        ...theme.columns,
        [theme.s]: {
            'grid-auto-flow': 'row',
        },
        [theme.mUp]: {
            /* 'grid-template-columns': ({withSelects}) => withSelects ? `auto ${withSelects.map( () => 'auto')}  repeat(2, max-content)`: '1fr repeat(2, max-content)',*/
            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr'
        },
        'align-items': 'center',
        /*'justify-content': 'start',*/
        'grid-column-gap': theme.spacing * 2,
    },
    cta:{

    },

    search: {
        'justify-self': 'left',
        [theme.s]: {
            /* display: 'none',*/
        },
        [theme.mUp]: {
            display: 'grid',
            'max-width': 200,
        },
    },


    label: {
        ...theme.control.label
    }
}));

const BarterFilters = ({filters, setFilters, selectsProps, showDate, toggles = [],searchComponent}) => {
    /* const isMobile = (useWindowWidth() < 576);
     const [showFilters, setShowFilters] = useState(!isMobile);*/
    const theme = useTheme();
    const classes = useStyles({withSelects: !!selectsProps});

    return <div className={classes.root}>
        <div className={classes.filters}>
            {showDate &&
            <DayPickerInput label={''} name={'date'} inForm={false} filters={filters} setFilters={setFilters}/>}

            {searchComponent}

            {(selectsProps) && <>
                {selectsProps.map((select, index) => {

                    return  <Select placeholder={select.placeholder} options={select.options} classNamePrefix={'select'}
                                   key={index}
                                   styles={theme.reactSelectsStyle}
                                   isClearable={true}
                                   isDisabled={(select.name === 'user_id' && filters?.user_id_toggle)}
                                   onChange={(item) => {
                                       setFilters({...filters, [select.name]: item ? item.value : null})
                                   }}
                                   value={select.options.filter(obj => {
                                       return obj.value?.toString() === filters[select.name]?.toString()
                                   })}
                            />
                })}
            </>}

            {toggles?.map(toggle => (
                <ToggleSwitch key={toggle.name} label={toggle.label} labelOn={'right'} isSmall={false}
                              onClick={() => toggle.callback()} defaultChecked={toggle.defaultChecked}/>
            ))}
        </div>
    </div>
};

export default BarterFilters;
