import axios from 'axios';

//here we can insert the urls that should ignore the refresh token process
const escapedUrls = ['/auth/login','/auth/refresh'];

const shouldIntercept = (error) => {
    try {
        return error.response.status === 401 && !escapedUrls.includes(error.config.url)
    } catch (e) {
        return false;
    }
};

const setTokenData = (tokenData = {}) => {
    window.localStorage.setItem('access_token', tokenData.access_token);
    window.localStorage.setItem('refresh_token', tokenData.refresh_token);
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + tokenData.access_token;
};

const handleTokenRefresh = () => {
    const refreshToken = window.localStorage.getItem('refresh_token');
    return new Promise((resolve, reject) => {
        axios.post('/auth/refresh', { refresh_token: refreshToken })
            .then(({data}) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
                localStorage.removeItem('user');
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                window.location.replace("/auth/login");
            })
    });
};

const attachTokenToRequest = (request, token) => {
    request.headers['Authorization'] = 'Bearer ' + token;
    // If there is an edge case where access token is also set in request query,
    // this is also a nice place to add it
    // Example: /orders?token=xyz-old-token
    if (/\/orders/.test(request.url)) {
        request.params.token = token;
    }
};

export default (axiosClient, customOptions = {}) => {
    let isRefreshing = false;
    let failedQueue = [];
    const options = {attachTokenToRequest, handleTokenRefresh, setTokenData, shouldIntercept, ...customOptions};

    const processQueue = (error, token = null) => {
        failedQueue.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(token);
            }
        });
        failedQueue = [];
    };

    const interceptor = (error) => {
        if (!options.shouldIntercept(error)) {return Promise.reject(error);}

        if (error.config._retry || error.config._queued) {return Promise.reject(error);}

        const originalRequest = error.config;
        //prevent and handle multiple refresh request
        if (isRefreshing) {
            return new Promise(function (resolve, reject) {
                failedQueue.push({resolve, reject})
            }).then(token => {
                originalRequest._queued = true;
                options.attachTokenToRequest(originalRequest, token);
                return axiosClient.request(originalRequest);
            }).catch(err => {
                return Promise.reject(error); // Ignore refresh token request's "err" and return actual "error" for the original request
            })
        }

        originalRequest._retry = true;
        isRefreshing = true;
        return new Promise((resolve, reject) => {
            options.handleTokenRefresh.call(options.handleTokenRefresh)
                .then((tokenData) => {
                    //set token data
                    options.setTokenData(tokenData);
                    //attach new token to request
                    originalRequest.headers['Authorization'] = 'Bearer ' + tokenData.access_token;
                    processQueue(null, tokenData.idToken);
                    resolve(axiosClient.request(originalRequest));
                })
                .catch((err) => {
                    processQueue(err, null);
                    reject(err);
                })
                .finally(() => {
                    isRefreshing = false;
                })
        });
    };

    axiosClient.interceptors.response.use(undefined, interceptor);
};