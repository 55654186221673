import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useParams} from 'react-router-dom';
import Spinner from '../../Commons/Spinner';
import Task from '../../Commons/Task';
import Button from '../../Commons/Button';
import CarHeading from "../../Components/Cars/CarHeading";
import {useDispatch, useSelector} from "react-redux";
import {setCarTasks, setCurrentCar} from "../../store/actions/carsActions";
import {alertToggle} from "../../store/actions/alertActions.js";
import {httpGetCar} from "../../HttpRequests/commons.http.js";
import {httpAddCarTask} from "../../HttpRequests/tasks.http";
import {AddIcon} from "../../assets/icons.js";
import {errorHandler, operationsTypes} from "../../helpers/utils.js";
import ContextMenu from "../../Commons/ContextMenu.js";
import Popover from "../../Commons/Popover.js";
import SingleShiftModal from "../../Components/Shifts/SingleShiftModal.js";
import InternalOrExternalShiftModal from "../../Components/Shifts/InternalOrExternalShiftModal.js";

const useStyles = createUseStyles(theme => ({
    overlay: {
        // fullscreen
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        // backgroundColor: ({contextMode}) => contextMode ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,0.5)',
        display: 'grid',
        alignContent: 'center',
        justifyContent: 'center',
        zIndex: 11,
    },
    gridContainer: {
        position: 'relative',
        // with big/small subheader like permute page
        height: `calc(100vh - ${theme.carSubHeader}px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        [theme.mUp]: {
            padding: [theme.spacing * 5, theme.spacing * 4, theme.spacing * 4]
        },
        [theme.s]: {
            padding: [theme.spacing],
        },
    },
    gridHeading: {
        display: 'grid',
        position: 'relative',
        gridTemplateColumns: '1fr auto',
        gridColumnGap: theme.spacing,
        padding: [theme.spacing, 0],
        margin: [theme.spacing * 4, 0],
        borderBottom: `1px solid ${theme.colors.grey}`,
        alignItems: 'center',
        '& h4 ': {
            fontSize: 20,
            fontWeight: 600,
        },
    },
    backOfficeHeading: {
        '& h4 ': {
            borderBottom: `1px solid ${theme.colors.grey}`,
            margin: [theme.spacing * 4, 0],
            padding: [12, 0],
            fontSize: 20,
            fontWeight: 600,
        },
        display: 'grid',
        position: 'relative',
        gridTemplateColumns: '1fr',
        gridColumnGap: theme.spacing,
        alignItems: 'center',
    },
    taskTitlesStyles: {
        display: "grid",
        fontSize: 14,
        boxSizing: "border-box",
        marginTop: theme.spacing,
        alignItems: "center",
        fontWeight: 600,
        gridTemplateColumns: "minmax(200px, 3fr) minmax(120px, 1fr) minmax(220px, 4fr) minmax(120px, 1fr) minmax(200px, 3fr)",
        '& div': {
            padding: [0, theme.spacing * 2],
        }
    },
    taskContainer: {
        maxWidth: "1200px", margin: "0 auto"
    }
}));

const CarTask = () => {
    const {id} = useParams(); //get id of car from url params react-router-dom
    const currentCar = useSelector(state => state.cars.currentCar); //set current car in local state selecting from cars Redux store
    const carTasks = useSelector(state => state.cars.carTasks); //tasks loading from Redux
    const classes = useStyles({bigSubHeader: false});
    const [loading, setLoading] = useState(false);
    const [showOperations, setShowOperations] = useState(false);
    const [showSingleShiftPopover, setShowSingleShiftPopover] = useState(false);
    const [showInternalShiftPopover, setShowInternalShiftPopover] = useState(false);
    const [showExternalShiftPopover, setShowExternalShiftPopover] = useState(false);
    const usersAutocomplete = useSelector(state => state.autocompletes.usersAutocomplete);
    const isAdmin = useSelector(state => state.users.user.operations.admin);
    const dispatch = useDispatch();

    const getCarData = async () => {
        setLoading(true);
        try {
            const {data: car} = await httpGetCar(id);
            dispatch(setCurrentCar(car));
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (+currentCar?.id !== +id) {
            //if the requested car is different from the stored one
            dispatch(setCurrentCar(null));
            getCarData();
        }
        // getUsersAutocomplete(); //get users autocomplete
        // clean up
        /* return () => {
             //CLEANUP DI TASKS
             dispatch(setCarTasks([]))
         };*/
    }, []);

    const isPhysicalTask = (taskType) => {
        switch (taskType) {
            case 'internal_transport':
            case 'wash':
            case 'complete_wash':
            case 'external_transport':
            case 'estimate':
            case 'photo':
            case 'reconditioning':
                return true;
            default:
                return false;
        }
    };

    const addTask = async (taskType) => {
        //task model creation
        let newTask = {
            "car_id": id, "type": taskType, "status": "open", "essential": false,
            "physical": isPhysicalTask(taskType), "user_id": null, "note": "", "deadline": null, "completed_at": ""
        };
        setLoading(true);

        try {
            const {data: task} = await httpAddCarTask(newTask);
            const updatedTasksConcat = [...carTasks, task];
            dispatch(setCarTasks(updatedTasksConcat)); //UPDATE OF REDUX TASK STORE
            dispatch(alertToggle(`Il task ${operationsTypes.filter(item => item.value === taskType)[0].label} è stato inserito correttamente.`, 'success'));
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
            setShowOperations(false)
        }
    };

    const taskTitles = (
        <div className={classes.taskTitlesStyles}>
            <div>OPERAZIONE</div>
            <div>Data</div>
            <div>Note</div>
            <div>Stato</div>
            <div>Operatore</div>
        </div>
    );

    const actions = [{
        to: null,
        text: 'Spostamento tra sedi ( interno )',
        callback: () => setShowInternalShiftPopover(!showInternalShiftPopover),
        type: 'normal',
    }, {
        to: null,
        text: 'Spostamento esterno',
        callback: () => setShowExternalShiftPopover(!showExternalShiftPopover),
        type: 'normal',
    }, {
        to: null,
        text: 'Spostamento singolo ( autonomo )',
        callback: () => setShowSingleShiftPopover(!showSingleShiftPopover),
        type: 'normal',
    }, {
        to: null, text: 'Lavaggio', callback: () => addTask('wash'), type: 'normal',
    }, {
        to: null, text: 'Lavaggio completo', callback: () => addTask('complete_wash'), type: 'normal',
    }, {
        to: null, text: 'Fotografia', callback: () => addTask('photo'), type: 'normal',
    }, {
        to: null, text: 'Perizia', callback: () => addTask('estimate'), type: 'normal',
    }, {
        to: null, text: 'Ricondizionamento', callback: () => addTask('reconditioning'), type: 'normal',
    },];

    return (<>
        {showSingleShiftPopover &&
        <Popover withHeader={false} minWidth={450} onClose={() => setShowSingleShiftPopover(!showSingleShiftPopover)}
                 contextMode={false}>
            <SingleShiftModal onClose={() => setShowSingleShiftPopover(!showSingleShiftPopover)}/>
        </Popover>}
        {showInternalShiftPopover && <Popover withHeader={false} minWidth={450}
                                              onClose={() => setShowInternalShiftPopover(!showInternalShiftPopover)}
                                              contextMode={false}>
            <InternalOrExternalShiftModal type={'internal_transport'}
                                          onClose={() => setShowInternalShiftPopover(!showInternalShiftPopover)}/>
        </Popover>}
        {showExternalShiftPopover && <Popover withHeader={false} minWidth={450}
                                              onClose={() => setShowExternalShiftPopover(!showExternalShiftPopover)}
                                              contextMode={false}>
            <InternalOrExternalShiftModal type={'external_transport'}
                                          onClose={() => setShowExternalShiftPopover(!showExternalShiftPopover)}/>
        </Popover>}
        {showOperations && <div className={classes.overlay} onClick={() => setShowOperations(false)}/>}
        <CarHeading headingTitle={'Informazioni vettura'} car={currentCar}/>

        <section className={classes.gridContainer}>
            {loading && <Spinner overlayFullscreen={true}/>}
            <div className={classes.taskContainer}>
                <div className={classes.gridHeading}>
                    <h4>Lista operazioni fisiche</h4>
                    <ContextMenu actions={actions} id={`1-operations`} type={'custom'}
                                 customOpenComponent={<Button data-primary icon={<AddIcon/>} width={240}>
                                     Aggiungi operazione
                                 </Button>}
                    />
                </div>
                {carTasks && carTasks.some(e => e.physical) && taskTitles}
                {carTasks && carTasks.map((el, index) => {
                    return el.physical && <Task key={index} task={el} taskUsers={usersAutocomplete}
                                                canDelete={isAdmin && !el.essential}/>
                })}

                <div className={classes.backOfficeHeading}>
                    <h4>Lista operazioni backoffice</h4>
                </div>
                {carTasks && carTasks.some(e => !e.physical) && taskTitles}
                {carTasks && carTasks.map((el, key) => {
                    return !el.physical && <Task key={key} task={el} taskUsers={usersAutocomplete}
                                                 canDelete={isAdmin && !el.essential}/>
                })}
            </div>
        </section>

    </>);
};

export default CarTask
