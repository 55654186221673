import React from 'react';
import TextMessageEditor from "./TextMessageEditor/TextMessageEditor";
import {MESSAGE_TYPE} from "../../../messageModel";


const MessageEditor = ({
                           message,
                           sendDraft,
                           updateDraft
                       }) => {

    switch(message?.type){
        case MESSAGE_TYPE.text:
            return (
                <TextMessageEditor
                    message={message}
                    sendDraft={sendDraft}
                    updateDraft={updateDraft}
                />
            )
        default:
            return null // add invariant
    }
}

/*
*
    default:
      invariant(
        false,
        `No editor available for draft message of type "${message.type}"`
      );
* */

export default MessageEditor;