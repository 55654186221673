import React from 'react';
import {createUseStyles} from 'react-jss';
import theme from '../theme';
import PropTypes from 'prop-types';
import {CloseIcon} from "../assets/icons.js";

const useStyles = createUseStyles((theme) => ({
    baseStyles: {
        position: 'relative',
        display: 'grid',
        width: 'fit-content',
        minWidth: ({minSize}) => minSize ? 80 : 'auto',
        height: 'fit-content',
        borderRadius: ({badgeRadius,isDot}) => isDot ? 100 : badgeRadius,
        backgroundColor: ({statusColor, statusColorCodes}) => statusColorCodes[statusColor],
        margin: ({margin}) => (margin ? margin : 0),
        paddingLeft: theme.spacing,
        paddingRight: theme.spacing,
        paddingTop:  ({badgeSize,isDot}) => isDot ? 0 : (badgeSize === 'small' ? theme.spacing / 2 : 2),
        paddingBottom:  ({badgeSize,isDot}) => isDot ? 3 : (badgeSize === 'small' ? theme.spacing / 2 : 2),
        boxSizing: 'border-box',
        textAlign: 'center',
        verticalAlign: 'middle',
        border: ({statusColor}) => `1px solid ${statusColor === 'light' ? theme.colors.darkGrey : 'transparent'}`,
        '&:before': {
            content: '""',
            display: 'inline-block',
            verticalAlign: 'middle',
            height: '100%'
        }
        ,
        fontWeight: ({fontWeight}) => `${fontWeight === 'bold' ? 'bold' : 'normal'}`
    },
    baseContent: {
        verticalAlign: 'middle',
        display: ({isRemovable}) => isRemovable ? 'grid' : 'inline-block',
        whiteSpace: 'nowrap',
        fontSize: ({badgeSize}) => (badgeSize === 'small' ? 10 : badgeSize === 'medium' ? 16 : 24),
        color: ({statusColor}) => (statusColor === 'light' || statusColor === 'xLightGrey' ? theme.colors.darkGrey : theme.colors.white),
        alignItems: ({isRemovable}) => isRemovable ? "center" : 'unset',
        gridTemplateColumns: ({isRemovable}) => isRemovable ? "1fr auto" : 'unset',
        gridColumnGap: ({isRemovable}) => isRemovable ? "4px" : 'unset',
        fontWeight: ({badgeSize}) => (badgeSize === 'small' ? 700 : badgeSize === 'medium' ? 500 : 500),
        '& svg':{
            width: `18px!important`
        }
    }
}));

const Badge = ({title, badgeSize = 'medium', badgeRadius = 15, statusColor = 'primary', margin = 0, fontWeight = 'normal', isRemovable = false, removableCallback, minSize = false,isDot}) => {
    const statusColorCodes = {
        primary: theme.colors.blue,
        warning: theme.colors.yellow,
        success: theme.colors.green,
        secondary: theme.colors.darkGrey,
        light: theme.colors.lightGrey,
        danger: theme.colors.red,
        xLightGrey: theme.colors.xLightGrey,
        black: theme.colors.black,
    };
    const classes = useStyles({badgeSize, badgeRadius, statusColor, statusColorCodes, margin, fontWeight, isRemovable,minSize,isDot});
    return <div className={classes.baseStyles}>
            <span className={classes.baseContent}><span>{title}</span> {isRemovable &&
            <CloseIcon data-color onClick={removableCallback}/>} </span>
        </div>
};

/*
USAGE:
<Badge title={'test'}>
other settable props:
badgeSize={'small'} //param: string, available 'small', 'medium' or 'large' //default: 'medium'
statusColor={'pending'}   //param: string, available 'primary', 'warning', 'success', 'secondary', 'danger' //default: 'primary'
badgeRadius={'50'} //param: number
*/

Badge.propTypes = {
    title: PropTypes.string,
    badgeSize: PropTypes.oneOf(['small', 'medium', 'large']),
    badgeRadius: PropTypes.number,
    statusColor: PropTypes.oneOf(['primary', 'warning', 'success', 'secondary', 'danger', 'light','xLightGrey','black']),
    fontWeight: PropTypes.oneOf(['bold']),
};

export default Badge;
