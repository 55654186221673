import React, {forwardRef} from 'react';
import {createUseStyles} from 'react-jss';
import cx from 'classnames';
import PropTypes from "prop-types";

const useStyles = createUseStyles(theme => ({
    root: {
        'display': 'grid',
        'grid-auto-flow': ({labelOn}) => !labelOn ? 'row' : 'column',
        'grid-column-gap': ({labelOn}) => !labelOn ? undefined : theme.spacing / 2,
        'align-content': 'center',
        'align-items': 'center',
        'position': 'relative',
        gridTemplateColumns: "max-content"
    },

    label: {
        ...theme.control.label,
        fontSize: 14,
        marginLeft: 4,
        cursor: ({disabled}) => disabled ? 'not-allowed' : 'pointer',
        'margin-bottom': ({labelOn}) => labelOn ? 0 : theme.control.label['margin-bottom'],
        'grid-column': ({labelOn}) => !labelOn ? undefined : labelOn === 'left' ? 1 : 2,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },

    input: {
        position: 'absolute',
        opacity: 0,
        width: 0,
        height: 0,
        '&:checked + $slider': {
            'background-color': theme.colors.red,
        },
        '&:focus + $slider': {},
        '&:checked + $slider:before': {
            transform: ({isSmall}) => isSmall ? 'translateX(18px)' : 'translateX(20px)',
        },
        '&:disabled': {
            'background-color': theme.colors.lightGrey,
        },
    },

    slider: {
        'position': 'relative',
        'display': 'inline-block',
        'width': ({isSmall}) => isSmall ? 40 : 48,
        'height': ({isSmall}) => isSmall ? 20 : 24,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        'background-color': ({disabled}) => (disabled) ? theme.colors.lightGrey : theme.colors.grey,
        transition: '0.5s',
        borderRadius: 40,
        cursor: ({disabled}) => (disabled) ? 'not-allowed' : 'pointer',

        '&:before': {
            position: 'absolute',
            content: '" "',
            height: ({isSmall}) => isSmall ? 14 : 18,
            width: ({isSmall}) => isSmall ? 14 : 18,
            left: 4,
            bottom: ({isSmall}) => isSmall ? 3 : 3,
            'background-color': 'white',
            transition: '0.5s',
            borderRadius: '50%',
        },
    },
}));

const ToggleSwitch = forwardRef(({className, label, labelOn, errors, disabled, isSmall,defaultChecked, ...props}, ref) => {
    const classes = useStyles({disabled,labelOn, isSmall, errors: !!errors});

    return <label className={cx(classes.root)}>
        {!!label && <span className={classes.label} title={label}>{label}</span>}
        <input type={'checkbox'} className={classes.input} ref={ref} disabled={disabled} defaultChecked={defaultChecked}  {...props} />
        <span className={classes.slider} id={props.name}/>
        {(!!errors && disabled) && <small className={classes.error}>{errors.message}</small>}
    </label>

});

export default ToggleSwitch;

ToggleSwitch.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    labelOn: PropTypes.oneOf(['right', 'left']),
    disabled: PropTypes.bool,
    errors: PropTypes.object,
    defaultChecked: PropTypes.bool,
    isSmall: PropTypes.bool,
};