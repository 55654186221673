import React from 'react';
import { createUseStyles } from 'react-jss';
const useStyles = createUseStyles((theme) => ({
    emptyRow: {
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        background: theme.colors.white,
        padding:30,
        '& span': {
            fontSize: 16,
            color: theme.colors.grey,
            fontWeight: 500
        },
        '& h4': {
            fontWeight: "bold",
            textAlign:"center"
        }
    }
}));

const CustomEmptyRow = ({title,subtitle}) => {
    const classes = useStyles();
    return (
        <div className={classes.emptyRow}>
            <h4>{title}</h4>
            <span>{subtitle}</span>
        </div>
    )
}

export default CustomEmptyRow;