import React, {useEffect, useState} from 'react';
import Select from "react-select";
import {createUseStyles, useTheme} from "react-jss";
import {errorHandler, formattedDate, toMoney} from "../../helpers/utils";
import {useDispatch, useSelector} from 'react-redux';
import {ArrowDown, ArrowUp, EditIcon, TimeIcon} from '../../assets/icons';
import Badge from '../../Commons/Badge';
import Avatar from "../../Commons/Avatar.js";
import {salesChannels} from "../../helpers/utils.js";
import {httpEditCarInfo} from "../../HttpRequests/barters.http.js";
import {alertToggle} from "../../store/actions/alertActions.js";
import {setCurrentCar} from "../../store/actions/carsActions.js";
import Popover from "../../Commons/Popover.js";
import EditCarPricePopover from "./EditCarPricePopover.js";
import {httpEditPriceHistory, httpFetchPriceHistory,httpApproveCarPrice, httpDenyCarPrice} from "../../HttpRequests/stock.http.js";
import Button from "../../Commons/Button.js";

const useStyles = createUseStyles(theme => ({
    destination: {
        width: 200,
        '& p': {
            ...theme.control.label
        }
    },
    promoPrice: {
        color: theme.colors.green,
        fontSize: 28,
        fontWeight: 'bold'
    },
    priceListToggle: {
        fontWeight: 'bold',
        cursor: 'pointer',
        textDecoration: 'underline',
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        alignContent: 'center',
        '& span': {
            fontSize: 14,
            justifySelf: ' end',
        },
        '& svg': {
            fill: theme.colors.black
        }
    },
    promoContainer: {
        display: "grid",
        position: "relative",
        alignItems: "center",
        gridTemplateColumns: "auto 1fr auto",
        padding: [theme.spacing * 3, 0, 4],
        gridColumnGap: theme.spacing,
        borderBottom: `1px solid ${theme.colors.smokeWhite}`,
        '& p': {
            fontSize: 14,
            textTransform: 'uppercase'
        },
        '& h3': {
            fontSize: 30,
            fontWeight: 600,
            color: theme.colors.green
        }
    },
    basePrice: {
        display: "grid",
        position: "relative",
        alignItems: "center",
        gridTemplateColumns: "1fr auto auto",
        padding: [theme.spacing, 0, theme.spacing * 2],
        gridColumnGap: theme.spacing,
        '& span': {
            fontSize: 14,
            textTransform: 'uppercase'
        },
        '& h5': {
            fontSize: 24,
            fontWeight: 600,
            color: ({onHold}) => onHold ? theme.colors.grey : theme.colors.black
        }
    },

    table: {
        marginTop: 20,
    },
    tableRows: {
        display: "grid",
        gridTemplateColumns: "1fr 2fr 1.5fr 1fr",
        gridColumnGap: theme.spacing,
        alignItems: 'center',
        borderBottom: `1px solid ${theme.colors.lightGrey}`,
        '& div': {
            fontWeight: 'bold',
            fontSize: 12,
            padding: theme.spacing
        },
        '& span': {
            fontSize: 12,
            padding: theme.spacing
        }
    },
    scrollable: {
        height: 160,
        width: '100%',
        overflow: 'hidden scroll'
    },
    onHold:{
        padding: "4px",
        width: "32px",
        height: "32px",
        display: "grid",
        justifyContent: "center",
        alignItems: "center",
        background: theme.colors.yellow,
        borderRadius: "32px",
        '& svg':{
            fill: theme.colors.white
        }
    },
    priceApproval:{
        display: "grid",
        justifyContent: "end",
        textAlign: "end",
        margin: "0 0 24px 0",
        '& p':{
            color: theme.colors.yellow
        }
    },
    btns: {
        marginTop: theme.spacing * 2,
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: theme.spacing,
        width: 300,
        justifySelf: 'end',
    }

}));

const SellingPrice = ({car,activeCampaign,setLoading}) => { //{ plate, price }
    const theme = useTheme();
    const dispatch = useDispatch();
    const [priceHistory, setPriceHistory] = useState([]);
    const [showPopover, setShowPopover] = useState(false);
    const [offersListOpened, setOffersListOpened] = useState(false);
    const [onHold, setOnHold] = useState(false);
    const canApprovePrice = useSelector(state => (state.users.user.operations?.pricing_approval || state.users.user.operations?.admin));
    const classes = useStyles({onHold});

    //console.log(car)
    const getPriceHistory = async (id) => {
        try {
            const {data: priceHistory} = await httpFetchPriceHistory(id); //key:value pair obj
            setPriceHistory(priceHistory);
            setOnHold(priceHistory[0]?.status === 'on_hold')
        } catch (e) {
            errorHandler(e, dispatch)
        }
    };

    const editCarPrice = async (values) => {
        if ((values.price === null || values.price === '') || car.price?.toString() === values.price.toString()) return;
        try {
            const {data: priceHistory} = await httpEditPriceHistory(car.id, values);
            dispatch(alertToggle(`Il campo Prezzo è stato modificato correttamente.`, 'success'));
            dispatch(setCurrentCar({...car, price: priceHistory?.price}));
            setShowPopover(false)
        } catch (e) {
            errorHandler(e, dispatch);
        }
    };

    const editCarInfo = async (fieldName, fieldValue) => {
        if ((fieldValue === null || fieldValue === '') || car[fieldName]?.toString() === fieldValue.toString()) return;
        const oldCar = {...car};
        const dataToSend = {
            car: {
                id: car.id,
                [fieldName]: fieldValue?.value ? fieldValue?.value : fieldValue,
            },
        };
        try {
            dispatch(setCurrentCar({...car, [fieldName]: fieldValue?.value ? fieldValue?.value : fieldValue}));
            await httpEditCarInfo(dataToSend);
            dispatch(alertToggle(`Il campo ${fieldName} è stato modificato correttamente.`, 'success'));
            setShowPopover(false)
        } catch (e) {
            errorHandler(e, dispatch);
            dispatch(setCurrentCar(oldCar));
        }
    };

    const approveOrDenyPrice = async (type) => {
        setLoading(true)
        try{
            type === 'deny' ? await httpDenyCarPrice(car.id) : await httpApproveCarPrice(car.id);
            dispatch(alertToggle(type === 'deny'? 'Proposta rifiutata con successo' : 'Proposta accettata con successo', 'success'));
            setOnHold(false)
        }catch (e){
            errorHandler(e,dispatch)
        }finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if(car.id){
            getPriceHistory(car.id);
        }
    }, [car?.price]);

    return <>
        {showPopover &&
        <Popover withHeader={false} minWidth={300} width={500} onClose={() => setShowPopover(!showPopover)}
                 contextMode={false}>
            <EditCarPricePopover onChangePriceCb={editCarPrice} car={car}/>
        </Popover>}

        <div className={classes.destination}>
            <p>Destinazione</p>
            <Select placeholder={'Select'} options={salesChannels} classNamePrefix={'select'}
                    styles={theme.reactSelectsStyle} isClearable={false} name={'sales_channel'}
                    onChange={(item) => editCarInfo('sales_channel', item)}
                    value={salesChannels.filter(obj => obj.value === car?.sales_channel)}/>
        </div>
        {!!activeCampaign && <div className={classes.promoContainer}>
            <p>Prezzo in promo</p>
            <Badge title={`- ${activeCampaign.amount}${activeCampaign.type === 'fixed' ? '€' : "%"}`} statusColor={'success'} badgeSize={'small'}/>
            <h3>{toMoney(car?.retail_price)}</h3>
        </div>}

        <div className={classes.basePrice}>
            <span>Prezzo base</span>
            <h5>
                {toMoney(priceHistory[0]?.price)}
            </h5>
            {!onHold && <span onClick={() => setShowPopover(!showPopover)}><EditIcon data-color/></span>}
            {onHold && <span className={classes.onHold}><TimeIcon data-color/></span>}
        </div>
        <div className={classes.priceApproval}>
            {onHold && <p>  <span>{formattedDate(priceHistory[0]?.created_at)} </span> Richiesta nuovo prezzo da approvare </p>}
            {(canApprovePrice && onHold) && <div className={classes.btns}>
                <Button data-small style={{color: '#fff', backgroundColor: '#E41B13', border: 'none'}} onClick={() => approveOrDenyPrice('deny')}> Rifiuta </Button>
                <Button data-small style={{color: '#fff', backgroundColor: '#4DCC4E', border: 'none'}}  onClick={() => approveOrDenyPrice('accept')}> Approva </Button>
            </div>}
        </div>

        <div className={classes.priceListToggle} onClick={() => setOffersListOpened(!offersListOpened)}>
            {!!priceHistory.length && <><span>Vedi storico prezzi</span> {offersListOpened ? <ArrowDown/> : <ArrowUp/>}</>}
        </div>

        {offersListOpened && <div className={classes.table}>
            <div className={classes.tableRows}>
                <div>Data</div>
                <div>Nota/Operazione</div>
                <div>Operatore</div>
                <div>Prezzo</div>
            </div>

            <div className={classes.scrollable}>
                {priceHistory.filter( el => el.status !== 'on_hold')?.map((el, index) => <div key={index} className={classes.tableRows}>
                    <span>{el?.created_at ? formattedDate(el.created_at) : '-'}</span>
                    <span>{el?.note}</span>
                    <span className={classes.rowBase}>
                        {el.user ? <>
                            <Avatar size={'small'} img={el.user.photo_link} fullName={el?.user.full_name}/>
                        </> : "-"}
                    </span>
                    <span>{el?.price ? toMoney(el.price) : '-'}</span>
                </div>)}
            </div>
        </div>
        }
    </>
};

export default SellingPrice;
