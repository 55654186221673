import {Redirect} from "react-router-dom";
import PublicRoute from './RouteGuards/PublicRoute';
import Login from './Pages/Authenticaiton/Login';
import ResetPassword from './Pages/Authenticaiton/ResetPassword';
import RecoverPassword from './Pages/Authenticaiton/RecoverPassword';
import PrivateRoute from './RouteGuards/PrivateRoute';
import Home from './Pages/Home/Home';
import PrimaryNavigation from './Components/Sidebars/PrimaryNavigation';
import ListWashes from './Pages/Home/ListWashes';
import ListPhotos from './Pages/Home/ListPhotos';
import ListExaminations from './Pages/Home/ListExaminations';
import ListRestore from './Pages/Home/ListRestore';
import ListShiftRequests from './Pages/Home/ListShiftRequests';
import ListShifts from './Pages/Home/ListShifts';
import Profile from './Pages/Profile/Profile';
import UsersTable from './Pages/Settings/UsersTable/UsersTable';
import SettingsNavigation from './Components/Sidebars/SettingsNavigation';
import UserAccount from './Pages/Settings/UserAccount/UserAccount';
import LocationsSettings from './Pages/Settings/LocationsSettings/LocationsSettings';
import BarterSettings from './Pages/Settings/BarterSettings/BarterSettings';
import Stock from './Pages/Stock/Stock';
import StockNavigation from './Components/Sidebars/StockNavigation';
import RepriceList from './Pages/Stock/Reprice/RepriceList';
import TradePoliciesList from './Pages/Stock/Trade-policies/TradePoliciesList';
import CarTask from './Pages/Car/CarTask';
import CarNavigation from './Components/Sidebars/CarNavigation';
import CarInfo from './Pages/Car/CarInfo';
import CarRestore from './Pages/Car/CarRestore';
import CarPhoto from './Pages/Car/CarPhoto';
import CarShifts from './Pages/Car/CarShifts';
import CarDocuments from './Pages/Car/CarDocuments';
import CarEconomics from './Pages/Car/CarEconomics';
import Barters from './Pages/Barters/Barters';
import BarterNavigation from './Components/Sidebars/BarterNavigation';
import Barter from './Pages/Barter/Barter';
import PrivateExternalsRoute from './RouteGuards/PrivateExternalsRoute';
import ExternalSuppliers from './Pages/ForExternals/ExternalSuppliers';
import ExternalSuppliersWorkPage from './Pages/ForExternals/ExternalSuppliersWorkPage';
import ExternalWarehouses from './Pages/ForExternals/ExternalWarehouses';
import BarterRecap from './Pages/BarterRecap/BarterRecap.js';
import LocationAccount from "./Pages/Settings/LocationAccount/LocationAccount.js";
import BusinessSettings from "./Pages/Settings/BusinessSettings/BusinessSettings.js";
import BusinessAccount from "./Pages/Settings/BusinessAccount/BusinessAccount.js";
import CampaignDetails from "./Pages/Stock/Trade-policies/CampaignDetails.js";
import CarRestoreIframeHandler from "./Pages/Car/CarRestoreIframeProcessHandler.js";
import ProfileNavigation from "./Components/Sidebars/ProfileNavigation.js";
import EditPassword from "./Pages/EditPassword/EditPassword.js";
import ShiftRequestsPlanning from "./Pages/Home/ShiftRequestsPlanning.js";
import MyTasks from "./Pages/Home/MyTasks.js";
import PrivateSupplierRoute from "./RouteGuards/PrivateSupplierRoute.js";
import CarRevisionNavigation from "./Components/Sidebars/CarRevisionNavigation.js";
import Pricing from "./Pages/Pricing/Pricing.js";
import ConfirmEmail from "./Pages/Authenticaiton/ConfirmEmail.js";
import NewVehicleInfo from "./Pages/Stock/NewVehicleInfo";
import PriceProposalList from "./Pages/Home/PriceProposalList.js";
import ListWithdrawal from "./Pages/Withdrawal/ListWithdrawal.js";
import WithdrawalsNavigation from "./Components/Sidebars/WithdrawalsNavigation.js";
import Communications from "./Pages/Communications/Communications";

const routes = [
	{
		routeType: PublicRoute,
		exact: true,
		path: '/auth/login',
		component: Login,
		breadcrumb: 'Login'
	},
	{
		routeType: PublicRoute,
		exact: true,
		path: '/reset-password/:token',
		component: ResetPassword,
		breadcrumb: 'Resetta password'
	},
	{
		routeType: PublicRoute,
		exact: true,
		path: '/reset-password',
		component: RecoverPassword,
		breadcrumb: 'Richiesta reset password'
	},
	{
		routeType: PublicRoute,
		exact: true,
		path: '/confirm-email/:token',
		component: ConfirmEmail,
		breadcrumb: 'Conferma Email'
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/',
		component: Home,
		breadcrumb: 'Home',
		sidebar: PrimaryNavigation,
		justOneRequired: true,
		requiredPermissions:['admin', 'can_view_dashboard']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/my-tasks',
		component: MyTasks,
		breadcrumb: 'I miei task',
		sidebar: PrimaryNavigation,
		requiredPermissions:[]
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/list/washes',
		component: ListWashes,
		breadcrumb: 'Lista lavaggi',
		sidebar: PrimaryNavigation,
		requiredPermissions:['wash_access']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/list/photos',
		component: ListPhotos,
		breadcrumb: 'Lista fotografie',
		sidebar: PrimaryNavigation,
		requiredPermissions:['photo_access']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/list/examinations',
		component: ListExaminations,
		breadcrumb: 'Lista perizie',
		sidebar: PrimaryNavigation,
		requiredPermissions:['reconditioning_access']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/list/restores',
		component: ListRestore,
		breadcrumb: 'Lista ripristini',
		sidebar: PrimaryNavigation,
		requiredPermissions:['reconditioning_access']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/list/price-proposals',
		component: PriceProposalList,
		breadcrumb: 'Proposte nuovo prezzo',
		sidebar: PrimaryNavigation,
		requiredPermissions:['pricing_approval']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/stock/pricing',
		component: Pricing,
		breadcrumb: 'Pricing',
		sidebar: StockNavigation,
		requiredPermissions:['can_edit_car','can_read_car']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/list/shift-requests',
		component: ListShiftRequests,
		breadcrumb: 'Richieste di spostamento',
		sidebar: PrimaryNavigation,
		requiredPermissions:['transfer_access']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/list/shift-requests/planning',
		component: ShiftRequestsPlanning,
		breadcrumb: 'Pianifica trasporto',
		sidebar: PrimaryNavigation,
		requiredPermissions:['transfer_access']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/list/shifts',
		component: ListShifts,
		breadcrumb: 'Lista spostamenti',
		sidebar: PrimaryNavigation,
		requiredPermissions:['transfer_access']
	},
	{
		routeType: Redirect,
		from: '/list',
		breadcrumb: 'Lista',
		to: '/'
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/profile',
		component: Profile,
		breadcrumb: 'Profilo',
		sidebar: ProfileNavigation,
		requiredPermissions:[]
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/edit-password',
		component: EditPassword,
		breadcrumb: 'Modifica password',
		sidebar: ProfileNavigation,
		requiredPermissions:[]
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/settings/users',
		component: UsersTable,
		breadcrumb: 'Lista utenti',
		sidebar: SettingsNavigation,
		requiredPermissions:['admin']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/settings/users/new',
		component: UserAccount,
		breadcrumb: 'Creazione utente',
		sidebar: SettingsNavigation,
		requiredPermissions:['admin']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/settings/users/:id',
		component: UserAccount,
		breadcrumb: 'Modifica utente',
		sidebar: SettingsNavigation,
		requiredPermissions:['admin']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/settings/locations',
		component: LocationsSettings,
		breadcrumb: 'Ubicazioni',
		sidebar: SettingsNavigation,
		requiredPermissions:['admin']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/settings/locations/new',
		component: LocationAccount,
		breadcrumb: 'Creazione ubicazione',
		sidebar: SettingsNavigation,
		requiredPermissions:['admin']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/settings/locations/:id',
		component: LocationAccount,
		breadcrumb: 'Modifica ubicazione',
		sidebar: SettingsNavigation,
		requiredPermissions:['admin']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/settings/business',
		component: BusinessSettings,
		breadcrumb: 'società',
		sidebar: SettingsNavigation,
		requiredPermissions:['admin']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/settings/business/new',
		component: BusinessAccount,
		breadcrumb: 'Creazione società',
		sidebar: SettingsNavigation,
		requiredPermissions:['admin']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/settings/business/:id',
		component: BusinessAccount,
		breadcrumb: 'Modifica società',
		sidebar: SettingsNavigation,
		requiredPermissions:['admin']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/settings/barter',
		component: BarterSettings,
		breadcrumb: 'Configurazione parametri permuta',
		sidebar: SettingsNavigation,
		requiredPermissions:['admin']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/settings/communications',
		component: Communications,
		breadcrumb: 'Comunicazioni',
		requiredPermissions:[]
	},
	{
		routeType: Redirect,
		from: '/settings',
		to: '/settings/users'
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/withdrawals',
		component: ListWithdrawal,
		breadcrumb: 'Lista previsti ritiri',
		sidebar: PrimaryNavigation,
		justOneRequired: true,
		requiredPermissions:['sales_access','transfer_access']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/withdrawals/:id',
		component: CarInfo,
		breadcrumb: '',
		sidebar: WithdrawalsNavigation,
		justOneRequired: true,
		requiredPermissions:['sales_access','transfer_access','can_edit_car','can_read_car']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/withdrawals/:id/documents',
		component: CarDocuments,
		breadcrumb: '',
		sidebar: WithdrawalsNavigation,
		justOneRequired: true,
		requiredPermissions:['backoffice_access','ACI_access','sales_access','transfer_access']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/stock',
		component: Stock,
		breadcrumb: 'Stock',
		sidebar: StockNavigation,
		requiredPermissions:['can_edit_car','can_read_car']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/stock/reprice',
		component: RepriceList,
		breadcrumb: 'Reprice',
		sidebar: StockNavigation,
		requiredPermissions:['price_access']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/stock/trade-policies',
		component: TradePoliciesList,
		breadcrumb: 'Politiche commerciali',
		sidebar: StockNavigation,
		requiredPermissions:['price_access']
	},
	{
		routeType:PrivateRoute,
		exact:true,
		path:`/stock/trade-policies/:id`,
		component:CampaignDetails,
		bradcrumb:'',
		sidebar:StockNavigation,
		requiredPermissions:['price_access']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/stock/pricing/:id',
		component: CarInfo,
		breadcrumb: '',
		sidebar: CarRevisionNavigation,
		requiredPermissions:['can_edit_car','can_read_car']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/stock/pricing/:id/economics',
		component: CarEconomics,
		breadcrumb: 'Dati economici',
		sidebar: CarRevisionNavigation,
		requiredPermissions:['can_edit_car','can_read_car']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/stock/:id',
		component: CarTask,
		breadcrumb: '',
		sidebar: CarNavigation,
		requiredPermissions:['can_edit_car','can_read_car']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/stock/:id/info',
		component: CarInfo,
		breadcrumb: 'Info',
		sidebar: CarNavigation,
		requiredPermissions:['can_edit_car','can_read_car']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/stock/:id/restore',
		component: CarRestore,
		breadcrumb: 'Ripristino',
		sidebar: CarNavigation,
		requiredPermissions:['reconditioning_access']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/stock/restore/iframe-process',
		component: CarRestoreIframeHandler,
		breadcrumb: 'Ripristino',
		sidebar: CarNavigation,
		requiredPermissions:['reconditioning_access']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/stock/:id/photo',
		component: CarPhoto,
		breadcrumb: 'Fotografie',
		sidebar: CarNavigation,
		requiredPermissions:['photo_access']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/stock/:id/shifts',
		component: CarShifts,
		breadcrumb: 'Spostamenti',
		sidebar: CarNavigation,
		requiredPermissions:['transfer_access']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/stock/:id/documents',
		component: CarDocuments,
		breadcrumb: 'Documenti',
		sidebar: CarNavigation,
		justOneRequired: true,
		requiredPermissions:['backoffice_access','ACI_access']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/stock/:id/economics',
		component: CarEconomics,
		breadcrumb: 'Dati economici',
		sidebar: CarNavigation,
		requiredPermissions:['can_edit_car','can_read_car']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/stock/:id/new',
		component: NewVehicleInfo,
		breadcrumb: 'Nuovo veicolo',
		sidebar: StockNavigation,
		requiredPermissions:['can_edit_car','can_read_car']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/barters',
		component: Barters,
		breadcrumb: 'Lista permute',
		sidebar: BarterNavigation,
		justOneRequired: true,
		requiredPermissions:['sales_approval','sales_access']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/barters/:id',
		component: Barter,
		breadcrumb: 'Permuta',
		sidebar: BarterNavigation,
		justOneRequired: true,
		requiredPermissions:['sales_approval','sales_access']
	},
	//little hack maybe
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/barters-recap/',
		component: Barters,
		breadcrumb: 'Lista permute',
		sidebar: BarterNavigation,
		requiredPermissions:['sales_approval','sales_access']
	},
	{
		routeType: PrivateRoute,
		exact: true,
		path: '/barters-recap/:id',
		component: BarterRecap,
		breadcrumb: 'Riepilogo',
		sidebar: BarterNavigation,
		justOneRequired: true,
		requiredPermissions:['sales_approval','sales_access']
	},
	{
		routeType: PrivateExternalsRoute,
		exact: true,
		path: '/external-workers',
		component: ExternalSuppliers,
		breadcrumb: 'Lista affidamenti lavoro',
	},
	{
		routeType: PrivateExternalsRoute,
		exact: true,
		path: '/external-workers/:id',
		component: ExternalSuppliersWorkPage,
		breadcrumb: 'Lavoro',
	},
	{
		routeType: PrivateSupplierRoute,
		exact: true,
		path: '/external-warehouses',
		component: ExternalWarehouses,
		breadcrumb: 'Ricerca documento',
	},
	{
		routeType: Redirect,
		to: '/'
	}
];

export default routes;
