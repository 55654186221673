import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {formattedDate, toMoney} from "../../helpers/utils.js";
import Checkbox from "../../Commons/Checkbox.js";
import PropTypes from "prop-types";
import {ArrowDown, ArrowUp} from "../../assets/icons.js";

const useStyles = createUseStyles((theme) => ({
    root: {
        marginTop: theme.spacing * 6
    },
    legends: {
        display: 'grid',
        gridTemplateColumns: '32px  0.3fr 0.2fr 0.6fr 24px',
        width: "100%",
        padding: [0, theme.spacing * 2],
        fontSize: 12,
        alignItems: "center",
        fontWeight: 600,
        marginBottom: "2px",
        gridAutoFlow: "column",
        textTransform: "capitalize",
        columnGap: 16,
    },
    card: {
        zIndex: 0,
        position: "relative",
        background: ({type}) => type === 'work' ? theme.colors.lightBlue : theme.colors.white,
        alignItems: "center",
        marginBottom: 4,
        borderRadius: ({expanded}) => expanded ? "16px 16px 0px 0px" : "16px",
        ...theme.shadow,
        overflow: "hidden"
    },
    header: {
        display: "grid",
        gridTemplateColumns: '32px 0.3fr 0.2fr 0.6fr auto',
        columnGap: 16,
        padding: "16px",
        alignItems: 'center',
        ...theme.shadow,
    },
    body: {
        padding: "0 8px",
        margin: "0 8px 8px",
        gridRow: 2,
        gridColumn: "1 / span 5",
        background: theme.colors.smokeWhite,
        /* ...theme.shadow,*/
    },
    repair_row: {
        display: "grid",
        gridTemplateColumns: ({type}) => type === 'assign' ? '32px 0.3fr 0.2fr 0.6fr' : '32px 0.35fr 0.2fr 0.6fr auto',
        borderBottom: `1px solid ${theme.colors.lightGrey}`,
        padding: [10, 0],
        fontSize: 12,
        columnGap: 6,
        textTransform: "lowercase",
        alignItems: 'center',
        '&::last-child': {
            borderBottom: "none",
        }
    },
    actions: {
        ...theme.grid.actions
    },
    title: {
        fontWeight: 600,
        fontSize: 20,
    },
    rollback: {
        padding: [4],
        boxSizing: 'border-box',
        border: `1px solid ${theme.colors.black}`,
        cursor: 'pointer'
    }
}));

const SimpleRepairList = ({list, selectCb, selectAllCb, allSelected, total, title, type, rollbackCb}) => {
    const [expanded, setExpanded] = useState(false);
    const classes = useStyles({type, expanded});

    useEffect(() => {
        setExpanded(!!list.length)
    }, [list]);

    return <div className={classes.root}>
        <div className={classes.legends}>
            <span/>
            <span/>
            <span>{type === 'assign' ? 'Tot. periziato' : 'Tot. Da lavorare'}</span>
            <span>Perizia</span>
            <span/>
        </div>
        <div className={classes.card}>
            <div className={classes.header}>
                {!!list.length ?
                    <Checkbox data-checkbox checked={allSelected} isSmall={true} onClick={() => selectAllCb()}
                              isDark={true}/> : <span/>}
                <p className={classes.title}>{title}</p>
                <strong>{toMoney(total)}</strong>
                <span/>
                <div className={classes.actions}>
                    {!!list.length ? <span onClick={() => setExpanded(!expanded)}>
                        {expanded ? <ArrowUp/> : <ArrowDown/>}
                    </span> : <span/>}
                </div>
            </div>

            {expanded && <div className={classes.body}>
                {list?.map((item, index) => <div className={classes.repair_row} key={`${index}-a`}>
                    <Checkbox data-checkbox data-id={`${index}-a`}
                              checked={type === 'assign' ? item.toAssignSelected : item.toWorkSelected} isSmall={true}
                              onClick={() => selectCb(item.id)} isDark={true}/>
                    <span>{item.operation}</span>
                    <span>{toMoney(item.price)}</span>
                    <span> Perizia del {formattedDate(item.created_at)}</span>
                    {rollbackCb &&
                    <span className={classes.rollback} onClick={() => rollbackCb(item)}> Riporta in periziato </span>}
                </div>)}
            </div>}
        </div>
    </div>

};

SimpleRepairList.propTypes = {
    list: PropTypes.array.isRequired,
    selectCb: PropTypes.func.isRequired,
    selectAllCb: PropTypes.func.isRequired,
    allSelected: PropTypes.bool.isRequired,
    total: PropTypes.number.isRequired,
    title: PropTypes.string,
    type: PropTypes.oneOf(['assign', 'work']).isRequired,
    rollbackCb: PropTypes.func,
};

export default SimpleRepairList;
