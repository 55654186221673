import React from 'react';
import ContextMenu from "../../Commons/ContextMenu.js";
import {getLabelByValue, taskStatusColors, taskStatuses} from "../../helpers/utils.js";
import Badge from "../../Commons/Badge.js";

export const TaskStatusesTableDropDown = ({items, metadata, value, callback}) => {

    const actions = [
        {
            to: null, text: <Badge title={'Da svolgere'} statusColor={'primary'} badgeSize={'small'}/>,
            callback: () => callback('open', items), type: 'normal',
        },
        {
            to: null, text: <Badge title={'In svolgimento'} statusColor={'warning'} badgeSize={'small'}/>,
            callback: () => callback('active', items), type: 'normal'
        },
        {
            to: null, text: <Badge title={'Completato'} statusColor={'success'} badgeSize={'small'}/>,
            callback: () => callback('closed', items), type: 'normal'
        },
    ];


    return <ContextMenu actions={actions} id={`${metadata.rowIndex}-status`} type={'custom'}
                        customOpenComponent={<Badge
                            title={value && getLabelByValue(taskStatuses, value)}
                            statusColor={taskStatusColors[value]}
                            badgeSize={'small'}
                        />}
    />

};

export default TaskStatusesTableDropDown;
