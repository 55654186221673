import {getCurrentConversationId} from "../../reducers/chat/currentConversation";
import {getTargetUserUuid} from "../../../Pages/messageCenter/chatUtils";
import {sendMessage as sendPubnubMessage} from "pubnub-redux";
import {errorFetchingMessageHistory, MessageActionType} from "pubnub-redux";
import {getUsersById} from "../../reducers/chat/chats";

/**
 * Send a message to the current conversation
 *
 * This command does not handle failure and leaves the error to the caller
 */
export const sendMessage = (message) => {
    return (dispatch, getState) => {
        const state = getState();
        const channel = getCurrentConversationId(state);
        const userUuid = getTargetUserUuid(channel, message.senderId);
        const users = getUsersById(state);
        const name = users[userUuid]?.name
        return dispatch(
            sendPubnubMessage({
                channel,
                message: {
                    ...message,
                    pn_gcm: {
                        notification: {
                            body: message.text,
                            title: name
                        },
                        data: {
                            alert: message.text
                        }
                    }
                }
            })
        );
    };
};


/**
 * Override fetchMessageHistory to include message actions
 */

export const messageHistoryRetrieved = (payload, meta) => {
    const {response, request} = payload;
    const messages = response.channels[request.channel] ?
        response.channels[request.channel].map(msg => {
            const deleted = !!msg.actions?.deleted
            return ({
                timetoken: msg.timetoken,
                entry: {
                    ...msg.message,
                    data: {
                        ...msg.data
                    },
                    actions: {
                        deleted
                    }
                }
            })
        }) : [];
    return {
        type: MessageActionType.MESSAGE_HISTORY_RETRIEVED,
        payload: {
            ...payload,
            response: {
                startTimeToken: response.startTimeToken,
                messages,
                endTimeToken: response.endTimeToken,
            },
            meta
        }
    };
};

export const fetchMessageHistory = (request, meta) => {
    return (dispatch, getState, {pubnub : {api}}) => {
        let customRequest = {...request, channels: [request.channel]}
        delete customRequest.channel;
        return new Promise(function (resolve, reject) {
            api.fetchMessages(customRequest, (status, response) => {
                if (status.error) {
                    const payload = {
                        request: request,
                        status: status
                    };
                    dispatch(errorFetchingMessageHistory(payload, meta));
                    reject(payload);
                } else {
                    const _payload = {
                        request: request,
                        response: response,
                        status: status
                    };
                    const action = messageHistoryRetrieved(_payload, meta);
                    dispatch(action);
                    resolve(action);
                }
            })
        })

    }
};