import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import Table from "../../Commons/Table.js";
import {
    allShiftsTypes,
    clearFilters,
    errorHandler,
    formattedDate,
    retrieveValueForRs,
} from "../../helpers/utils.js";
import SubHeading from "../../Commons/SubHeading.js";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from 'react-router-dom';
import qs from "query-string";
import {useWindowWidth} from "@react-hook/window-size";
import {setShiftsList, updateShift} from "../../store/actions/shiftsActions.js";
import {downloadAllShiftDocs, httpEditShift, httpGetShifts} from "../../HttpRequests/shifts.http.js";
import Popover from "../../Commons/Popover.js";
import Button from "../../Commons/Button.js";
import {ArrowDown, ArrowUp, DownloadIcon, RemoveIcon} from "../../assets/icons.js";
import Spinner from "../../Commons/Spinner.js";
import ShiftAttachment from "../../Components/Shifts/ShiftAttachment.js";
import DeleteShiftModal from "../../Components/Shifts/DeleteShiftModal.js";
import ShiftsFilters from "../../Components/Shifts/ShiftsFilters.js";
import TaskStatusesTableDropDown from "../../Components/Cars/TaskStatusesTableDropDown.js";
import {alertToggle} from "../../store/actions/alertActions.js";

const useStyles = createUseStyles(theme => ({
    gridContainer: {
        'position': 'relative',
        // with big/small subheader like permute page
        height: ({bigSubHeader}) => `calc(100vh - ${bigSubHeader ? theme.bigSubHeader + theme.headerHeight : theme.smallSubHeader + theme.headerHeight}px)`,
        // without subheader
        //height: `calc(100vh - ${theme.headerHeight}px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        padding: ({isTablet}) => isTablet ? "32px 16px 0" : "32px 32px 0",
        [theme.s]: {
            padding: [theme.spacing],
        },
    },
    doubleRow: {
        ...theme.grid.doubleRow,
    },
    headingBtns: {
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '1fr auto',
        gridColumnGap: theme.spacing
    },
}));

const ListShifts = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const shifts = useSelector(state => state.shifts.shiftsList);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState(location.search ? {
        ...qs.parse(location.search, {
            arrayFormat: 'comma',
            parseBooleans: true
        })
    } : {order_by: 'transfer_date', order_direction: 'desc'});
    const allLocations = useSelector(state => state.autocompletes.simpleLocations);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(null);
    const [rowExpandedId, setRowExpandedId] = useState(null);
    const classes = useStyles({bigSubHeader: true, isTablet: useWindowWidth() < 1200});// or false if type is small

    //fetch when filters change
    useEffect(() => {
        if (!!Object.keys(filters).length) {
            setPage(1);
            setLastPage(null);
            getShifts(1);
            history.replace({
                pathname: '/list/shifts', search: qs.stringify({...filters}, {
                    arrayFormat: 'comma',
                    skipEmptyString: true,
                    skipNull: true
                }),
            });
        }
    }, [filters]);

    //fetch when page change
    useEffect(() => {
        if (!!shifts.length && page !== 1) {
            getShifts(page)
        }
    }, [page]);

    //clean up
    useEffect(() => {
        return () => {
            dispatch(setShiftsList([]))
        };
    }, []);

    const getShifts = async (actualPage = 1) => {
        if (lastPage && actualPage > lastPage) return;
        if (actualPage === 1) {
            dispatch(setShiftsList([]))
        }
        setLoading(true);
        clearFilters(filters);
        try {
            const {data: {data, last_page}} = await httpGetShifts({limit: 20, ...filters, page: actualPage});
            if (actualPage === 1) {
                dispatch(setShiftsList(data));
            } else {
                dispatch(setShiftsList(shifts.concat(data)));
            }
            setLastPage(last_page)
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    };

    const onChangePage = (isFilter = false) => {
        if (isFilter) {
            setPage(1)
        } else {
            setPage(page + 1)
        }
    };

    const onDataSorting = (newSort) => {
        setFilters({
            ...filters,
            ...newSort,
        });
        setPage(1);
    }

    const editShiftStatus = async (status, shift) => {
        setLoading(true)
        try {
            dispatch(updateShift({...shift, status: status}));
            await httpEditShift(shift?.id, {...shift, status: status});
            dispatch(alertToggle('Stato correttamente aggiornato', 'success'));
        } catch (e) {
            errorHandler(e, dispatch);
            dispatch(updateShift({...shift}));
        }finally {
            setLoading(false)
        }
    };

    return <>
      {/*  {loading && <Spinner overlayFullscreen={true}/>}*/}
        <SubHeading title={"Lista spostamenti"} type={'big'} justifySelf={'unset'}
                    filtersComponent={
                        <ShiftsFilters setFilters={setFilters} filters={filters} showDate={true} dateFieldName={'transfer_date'}
                                       setPage={setPage}
                                       selectsProps={[
                                           {
                                               name: 'type', placeholder: 'Tipologia', label: 'modello',
                                               options: (allShiftsTypes ? allShiftsTypes : [])
                                           }, {
                                               name: 'location_id_from', placeholder: 'Da', label: 'Da',
                                               options: (allLocations ? allLocations : [])
                                           }, {
                                               name: 'location_id_to', placeholder: 'A', label: 'A',
                                               options: (allLocations ? allLocations : [])
                                           }]}
                                       rightButtons={
                                           <Button data-primary onClick={() => history.push('/list/shift-requests')}>Registra spostamento</Button>
                                       }
                        />}
        />

        <section className={classes.gridContainer}>
            <Table
                id={'table'}
                data={shifts}
                inLoading={loading}
                filters={filters}
                rowExpandedId={rowExpandedId}
                setRowExpandedId={setRowExpandedId}
                onSetPageCb={onChangePage}
                onSortingDataCb={onDataSorting}
                theadHeight={40}
                rowMinHeight={40}
                classNames={{
                    table: classes.table,
                    thead: classes.thead,
                    row: classes.row,
                }}
                columns={['type', 'date', 'stages','vector', 'status','delete','actions']}
                columnsNames={{
                    type: "Tipo di spostamento",
                    date: "Data",
                    stages: 'Tappe',
                    vector: "Vettore",
                    status: 'Stato',
                    delete: "",
                    actions: "",
                }}
                columnsWidths={{
                    type: "25%",
                    date: "15%",
                    stages: '30%',
                    vector: '12%',
                    status: "10%",
                    delete: "3%",
                    actions: "3%",
                }}
                /*columnsAlignments={{
                    status: 'center',
                }}*/
                backEndSortable={true}
                sortable={true}
                expandedComponent={RepairRows}
                columnsRenderers={{
                    type: (value) => <span>{retrieveValueForRs(allShiftsTypes, value)[0]?.label}</span>,
                    date: (value, items) => <span> {formattedDate(items?.transfer_date)}</span>,
                    stages: (value,items) => <span>Da {items?.location_from_name} - a {items?.location_to_name}</span>,
                    vector: (value) => <span>{value ? (value === 'internal'  ? 'Bisarca interna': 'Bisarca esterna'): 'Autonomo'}</span>,
                    status: (value,items, metadata) => <TaskStatusesTableDropDown value={value} callback={editShiftStatus} items={items} metadata={metadata}/>,
                    delete: (value,items) => <DeleteShift data={items}/>,
                    actions: (value, items, metadata) => <Actions rowIndex={metadata.rowIndex}
                                                                  rowExpandedId={rowExpandedId}
                                                                  data={items}
                                                                  setRowExpandedId={setRowExpandedId}/>
                }}
            />
        </section>
    </>
};

/** expand actions **/
const useStylesActions = createUseStyles(theme => ({
    root: theme.grid.actions,
}));

export const Actions = ({setRowExpandedId, rowIndex, rowExpandedId,data}) => {
    const classes = useStylesActions();
    const isExpanded = (rowIndex === rowExpandedId);
    return (
        <div className={classes.root}>
            {!!data?.cars?.length && <span onClick={() => {
                setRowExpandedId(isExpanded ? null : rowIndex)
            }}>
                {isExpanded ? <ArrowUp/> : <ArrowDown/>}
            </span>}
        </div>
    );
};

/** subcomponent expanded **/
const useStylesRepairRows = createUseStyles(theme => ({
    root: {
        margin: "12px -12px 0",
        background: theme.colors.smokeWhite,
        padding: [0, 12],
        display: "grid",
    },
    row: {
        borderBottom: `1px solid ${theme.colors.lightGrey}`,
        padding: [theme.spacing * 2, 0],
        fontSize: 12,
        textTransform: "lowercase",
    },
    attachmentsContainer:{
        display: "grid",
        gridAutoFlow: "column",
        alignItems: 'center',
        gridTemplateColumns: '1fr auto',
        margin: [theme.spacing * 2, 0],
    },
    download:{
        position: 'relative',
        cursor: 'pointer',
        width: 32,
        height: 32,
        background: theme.colors.black,
        color: theme.colors.white,
        display: "grid",
        alignItems: 'center',
        justifyItems: 'center',
        borderRadius: '50%',
        '& svg':{
            fill: '#fff!important',
            width: '16px !important'
        }
    },
    attachments:{
        display: "grid",
        gridAutoFlow: "column",
        gridAutoColumns: "max-content",
        gridColumnGap: theme.spacing * 2,
    },
    tooltip:{
        top: "-28px",
        color: "white",
        right: "16px",
        width: "180px",
        padding: "4px 8px",
        position: "absolute",
        fontSize: "13px",
        background: "black",
        textAlign: "center",
        fontWeight: 400,
        borderRadius: "12px",
        borderBottomRightRadius: "0",
    }
}));

export const RepairRows = ({data}) => {
    const classes = useStylesRepairRows();
    const companiesAutocomplete = useSelector( state => state.autocompletes.companiesAutocomplete);
    const [loading, setLoading] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const dispatch = useDispatch();

    const downloadAllDocsZip = async () => {
        setLoading(true);
        try {
            const {data:download} = await  downloadAllShiftDocs(data?.id);
            const url = window.URL.createObjectURL(new Blob([download], { type: 'application/zip' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'bolle.zip'); //or any other extension
            document.body.appendChild(link);
            link.click();
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    }

    return <div className={classes.root}>
        {loading && <Spinner overlayFullscreen={true}/>}
        {!!data?.cars?.length && data?.cars?.map((car, index) => <div className={classes.row} key={index}>
            <span><span style={{textTransform: "capitalize"}}>{car?.model?.brand?.name}</span> {car?.model.name}</span>
            <strong style={{textTransform: "uppercase"}}> - {car?.plate} </strong>
            <span style={{textTransform: "capitalize"}}> - {car?.owning_company_id ? companiesAutocomplete.filter(company => +company.value === +car?.owning_company_id)[0]?.label : 'società proprietaria non disponibile'} </span>
            </div>)}
        {!!data?.transportation_attachments?.length && <div className={classes.attachmentsContainer}>
            <div className={classes.attachments}>
                {data.transportation_attachments?.map((attachment, index) => <ShiftAttachment data={attachment} key={index}/>)}
            </div>
            <div className={classes.download} onClick={() => downloadAllDocsZip()}
                 onMouseLeave={() => setShowTooltip(!showTooltip)}
                 onMouseEnter={() => setShowTooltip(!showTooltip)}>
                {showTooltip && <span className={classes.tooltip}> Scarica tutti i documenti </span>}
                <DownloadIcon data-color/>
            </div>
        </div>}
    </div>
};

/** Remove component **/
const useStylesRemove = createUseStyles(theme => ({
    remove: {
        '& svg':{
            fill: theme.colors.lightGrey,
            transition: 'ease-in 300ms',
            '&:hover':{
                fill: theme.colors.red,
            }
        }
    },
}));

export const DeleteShift = ({data}) => {
    const classes = useStylesRemove();
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    return <>
        {showDeleteModal && <Popover withHeader={false} width={360} onClose={() => setShowDeleteModal(!showDeleteModal)}>
            <DeleteShiftModal onClose={() => setShowDeleteModal(!showDeleteModal)} id={data.id}/>
        </Popover>}
        <span className={classes.remove}><RemoveIcon onClick={() => setShowDeleteModal(!showDeleteModal)} data-color/></span>
    </>
};

export default ListShifts;
