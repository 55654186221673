import React,{useState} from 'react';
import {createUseStyles} from 'react-jss';
import Button from "../Commons/Button.js";
import CircularProgress from '../Commons/CircularProgress.js';
import RangeWrapper from '../Commons/RangeWrapper';

import { useForm } from "react-hook-form";

const useStyles = createUseStyles(theme => ({
    gridContainer: {
        'position': 'relative',
        // with big/small subheader like permute page
        height: ({bigSubHeader}) => `calc(100vh - ${bigSubHeader ? theme.bigSubHeader + theme.headerHeight : theme.smallSubHeader + theme.headerHeight}px)`,
        // without subheader
        // height: `calc(100vh - ${theme.headerHeight}px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        display: 'grid',
        maxWidth: 600,
        [theme.mUp]: {
            padding: [theme.spacing * 4],
        },
        [theme.s]: {
            padding: [theme.spacing],
        },
    },
}));

const ShowCase = () => {
    //based on the type {"big","small"} property of subHeader component
    const classes = useStyles({bigSubHeader: false}); // or false if type is small

    const { handleSubmit, control } = useForm({ });
    const [data, setData] = useState(null);

    return <>
        {/* <SubHeading title={"Showcase"} type={'small'}/>*/}
        {/* <StepInfo image={"https://media.philstar.com/images/articles/gadgets1-toyota-vios_2018-03-12_17-21-02.jpg"}
                activeStep={1}
                carStatus={"IN STOCK"}
                carTitle={"Toyota Vios"}
                carPlate={"AX200SV"}
                carKm={"122000"}
                percentCompleted={25}
        /> */}
        <div className={classes.gridContainer}>
            <h2>Buttons all states and type </h2>
            <Button data-primary data-small>Button small</Button>
            <Button data-primary>Button primary</Button>
            <Button data-primary data-big>Button primary big</Button>
            <Button data-secondary data-small>Button small</Button>
            <Button data-secondary>Button primary</Button>
            <Button data-secondary data-big>Button primary big</Button>
            <Button data-primary disabled={true} >Button primary disabled</Button>
            <Button data-secondary disabled={true} >Button secondary disabled</Button>

            <br />
            <br />
            <form onSubmit={handleSubmit(data => setData(data))}>
                {/* <StepProgress
                    selected={1}
                /> */}
                {/* <InputNumberCustom control={control} name={'test_input_number_custom'} val={5} /> */}
                {/*  */}
                <section style={{marginTop: '50px'}}>
                    <RangeWrapper
                        control={control}
                        name={'range 1'}
                        unit={'€'}
                        marks={{
                            0: `0`,
                            20: `20`,
                            40: `40`,
                            60: `60`,
                            80: `80`,
                            100: `100`
                        }}
                        step={10}
                        min={0}
                        max={100}
                        defaultValue={[10, 20]}
                    />
                </section>

                <section style={{marginTop: '50px'}}>
                    <button>submit</button>
                    {data && (
                        <pre style={{ textAlign: "left", color: "red" }}>
                        {JSON.stringify(data, null, 2)}
                    </pre>
                    )}
                </section>
            </form>

            <br />
            <CircularProgress value={50} valueLabel={'completato'} valuePosition={'right'} textSize={32} /><br />
            <CircularProgress value={50} valueLabel={'completato'} valuePosition={'center'} textSize={22} strokeFill={'#009688'} trackFill={'#ddd'}  />
            <br />
        </div>
    </>
};

export default ShowCase;
