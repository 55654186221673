import React, {useCallback, useState} from 'react';
import {createUseStyles} from 'react-jss';
import Text from "../../Commons/Text.js";
import {EditIcon, PdfIcon, SearchIcon, UploadIcon} from "../../assets/icons.js";
import {useForm} from "react-hook-form";
import {debounce} from "lodash-es";
import Button from "../../Commons/Button.js";
import Popover from "../../Commons/Popover.js";
import ExternalUploadDocument from "../../Components/Externals/ExternalUploadDocument.js";
import {useDispatch} from "react-redux";
import {errorHandler, formattedDate} from "../../helpers/utils.js";
import Spinner from "../../Commons/Spinner.js";
import {httpGetSupplierDocuments} from "../../HttpRequests/reconditioning.http.js";

const useStyles = createUseStyles(theme => ({
    gridContainer: {
        'position': 'relative',
        height: `calc(100vh - 60px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        [theme.mUp]: {
            padding: [theme.spacing * 4,theme.spacing * 4, 0 ],
        },
        [theme.s]: {
            padding: [theme.spacing],
        },
    },
    title: {
        fontSize: 32,
        fontWeight: 600,
        textAlign: 'center'
    },
    form: {
        maxWidth: 600,
        margin: "80px auto 0",
        display: "grid",
        alignItems: "center"
    },
    search: {
        marginBottom: theme.spacing * 5,
        '& input': {
            borderBottomColor: theme.colors.lightGrey,
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            color: theme.colors.darkGrey,
            fontSize: 18,
            textTransform: "capitalize",
            fontWeight: 600,
            borderRadius: "0",
            background: "transparent",
        }
    },
    document: {
        background: theme.colors.white,
        padding: theme.spacing * 2,
        display: "grid",
        borderRadius: 16,
        marginBottom: theme.spacing * 3,
        gridRowGap: theme.spacing,
        '& p': {
            fontSize: 14,
            fontWeight: 500,
            color: theme.colors.black,
            '& span': {
                marginLeft: theme.spacing,
                color: theme.colors.darkGrey
            }
        },
        '& a': {
            fontSize: 14,
            textDecoration: 'none',
            display: 'grid',
            alignItems: 'center',
            gridTemplateColumns: 'auto 1fr',
            gridColumnGap: theme.spacing,
            color: theme.colors.black,
            '& svg': {
                width: '16px !important'
            },
        },
        '& button': {
            display: "grid", gridArea: "7 / 1", width: 180
        }
    },
    header: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',

        '& span': {
            fontSize: 14,
            fontWeight: 500,
            color: theme.colors.grey
        }
    },
    results: {
        width: 600,
        height: `calc(100vh - 292px)`,
        overflow: 'scroll',
        padding: '0 24px',
    },
    external:{
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'auto 1fr',
        gridColumnGap: theme.spacing,
        '& svg': {
            width: '16px !important'
        },
    }
}));

const ExternalWarehouses = () => {
    const dispatch = useDispatch();
    const [documents, setDocuments] = useState([]);
    const [showPopover, setShowPopover] = useState(false);
    const [loading, setLoading] = useState(false);
    const [invoiceData, setInvoiceData] = useState({
        carId: null,
        repair: null
    })
    const {register, handleSubmit, watch} = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
    });
    const classes = useStyles();

    const submit = async () => {
        //todo: search document api here
        if (watch('search') === null || watch('search') === '') return
        setLoading(true)
        try {
            const {data: {data: documents}} = await httpGetSupplierDocuments({q: watch('search')});
            setDocuments(documents);
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false)
        }
    };

    const submitDebounced = useCallback(debounce(() => submit(), 750), []);
    const submitInstant = () => {
        submitDebounced.cancel();
        submit();
    };

    const popoverToggle = (repair) => {
        if(showPopover) return !showPopover

        setInvoiceData({
            carId: repair.car_id,
            repair: repair,
        });
        setShowPopover(!showPopover);
    };

    return <>
        {showPopover &&
        <Popover withHeader={false} minWidth={300} width={500} onClose={() => setShowPopover(!showPopover)}
                 contextMode={false}>
            <ExternalUploadDocument repair={invoiceData?.repair} carId={invoiceData?.carId}
                                    onSuccessCb={() => submitInstant()}
                                    onCloseCb={() => setShowPopover(!showPopover)}/>
        </Popover>}

        {loading && <Spinner overlayFullscreen={true}/>}
        <div className={classes.gridContainer}>
            <h3 className={classes.title}>Ricerca documento</h3>
            <form className={classes.form} noValidate={true} onSubmit={handleSubmit(submitInstant)}>
                <Text className={classes.search} type={'text'} ref={register}
                      onChange={submitDebounced} name={'search'}
                      placeholder={'numero documento'} icon={<SearchIcon data-color onClick={submitInstant}/>}/>

                <div className={classes.results}>
                    {documents.map((repair, index) => {
                        const supplierGenerated = repair?.attachments.filter(attach => attach.type === 'supplier_list');
                        const supplierInvoice = repair?.attachments.filter(attach => attach.type === 'supplier_invoice');

                        return <div className={classes.document} key={index}>
                            <div className={classes.header}>
                                <span style={{color: 'red'}}>Documento n {repair?.estimate_id}</span>
                                <span>{formattedDate(supplierInvoice?.created_at)}</span>
                            </div>
                            <p>{repair?.assigned_user?.full_name} <span> - </span></p>
                            {!!supplierGenerated.length && <a href={supplierGenerated[0]?.url} download target={`_blank`}> <PdfIcon/>
                                <span>{supplierGenerated[0]?.filename}</span></a>}
                            {!!supplierInvoice.length && <span className={classes.external}>
                                    <a href={supplierInvoice[0]?.url} download target={`_blank`}> <PdfIcon/>
                                    <span>{supplierInvoice[0]?.filename} </span> </a>
                                    <EditIcon data-color onClick={() => popoverToggle(repair)}/>
                                </span>}
                            {!(!!supplierInvoice.length) && <Button data-primary data-small icon={<UploadIcon/>}
                                    onClick={() => popoverToggle(repair)}> Carica documento </Button>}
                        </div>
                    })}
                </div>
            </form>
        </div>
    </>
};

export default ExternalWarehouses;

