import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import DeletePopover from '../../../Commons/DeletePopover.js';
import Popover from '../../../Commons/Popover.js';
import Spinner from '../../../Commons/Spinner.js';
import SubHeading from "../../../Commons/SubHeading.js";
import CampaignBody from '../../../Components/TradePolices/CampaignBody.js';
import NewCampaign from "../../../Components/TradePolices/NewCampaign.js";
import PolicesFilters from '../../../Components/TradePolices/PolicesFilters';
import {clearFilters, errorHandler} from '../../../helpers/utils.js';
import {httpDeleteCampaign, httpGetCampaigns, httpSuspendCampaign} from '../../../HttpRequests/campaigns.http.js';
import {setCampaignsList} from '../../../store/actions/campaignActions.js';
import Button from "../../../Commons/Button.js";
import {AddIcon} from "../../../assets/icons.js";

const useStyles = createUseStyles(theme => ({
    gridContainer: {
        'position': 'relative',
        // with big/small subheader like permute page
        height: ({bigSubHeader}) => `calc(100vh - ${bigSubHeader ? theme.bigSubHeader + theme.headerHeight : theme.smallSubHeader + theme.headerHeight}px)`,
        // without subheader
        //height: `calc(100vh - ${theme.headerHeight}px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        [theme.mUp]: {
            padding: [theme.spacing * 4,theme.spacing * 4, 0],
        },
        [theme.s]: {
            padding: [theme.spacing],
        },
    },
    campaignsContainer:{
        maxWidth: "1232px", margin: "0 auto", padding: [0, theme.spacing * 2]
    },
    body:{
        height: `calc(100vh - 282px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        padding: [0, theme.spacing * 2, theme.spacing * 10, 0]
    }
}));

const TradePoliciesList = () => {
    //based on the type {"big","small"} property of subHeader component
    const dispatch = useDispatch();
    const campaigns = useSelector(state => state.campaigns.campaignsList);
    const classes = useStyles({bigSubHeader: false}); // or false if type is small
    const [filters, setFilters] = useState({});
    const [showPopover, setShowPopover] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const history = useHistory();
    const [showDelete, setShowDelete] = useState(false);
    const [campaignId, setCampaignId] = useState();

    useEffect(() => {
        getCampaigns()
    }, [])

    //fetch when filters change
    useEffect(() => {
        //console.log(filters, !!Object.keys(filters).length)
        if (!!Object.keys(filters).length) {
            getCampaigns();
        }
    }, [filters]);

    const getCampaigns = async () => {
        setLoading(true);
        clearFilters(filters);
        try {
            const {data: campaigns} = await httpGetCampaigns({
                ...filters,
                start_campaign: filters?.startDate,
                end_campaign: filters?.endDate
            });
            dispatch(setCampaignsList(campaigns))
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false)
        }
    };

    const deletePopover = (id) => {
        setCampaignId(id);
        setShowDelete(!showDelete)
    };

    const deleteCampaign = async () => {
        setLoading(true);
        try {
            await httpDeleteCampaign(campaignId);
            const newCampaignList = campaigns.filter(campaign => +campaign.id !== +campaignId);
            dispatch(setCampaignsList(newCampaignList))
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false)
            setShowDelete(!showDelete)
        }
    };

    const suspendCampaign = async (id, status) => {
        setLoading(true)
        try {
            await httpSuspendCampaign(id, !status)
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false)
            history.go(0)
        }
    }

    return <>

        {isLoading && <Spinner overlayFullscreen={true}/>}
        {showDelete && <Popover onClose={() => setShowDelete(!showDelete)} width={400}>
            <DeletePopover title={'Stai per eliminare una campagna sei sicuro di voler procedere?'}
                           deleteAction={deleteCampaign} closePopover={() => setShowDelete(!showDelete)}/>
        </Popover>}
        {showPopover && <Popover onClose={() => setShowPopover(!showPopover)} width={500}>
            <NewCampaign closeModalCb={() => setShowPopover(!showPopover)} setLoading={setLoading}/>
        </Popover>}

        <SubHeading title={"Politiche commerciali"} type={'small'}
                    rightSearchComponent={<Button data-primary icon={<AddIcon/>}
                                                  onClick={() => setShowPopover(!showPopover)}>
                        Nuova Campagna
                    </Button>}/>

        <div className={classes.gridContainer}>
            <div className={classes.campaignsContainer}>
                <div className={classes.filters}>
                    <PolicesFilters setFilters={setFilters} filters={filters} showDate={true}
                                    callback={() => setShowPopover(!showPopover)}
                                    selectsProps={[{
                                        name: 'order_direction',
                                        placeholder: 'Seleziona',
                                        options: [{value: "desc", label: "Piu' recente"}, {value: "asc", label: "Meno Recente"}]
                                    }
                                    ]}
                    />
                </div>
                <div className={classes.body}>
                    {campaigns?.map((campaign) => <CampaignBody key={campaign.id} campaign={campaign} deletePopoverCb={deletePopover} suspendCampaignCb={suspendCampaign}/>)}
                </div>
            </div>
        </div>
    </>
};

export default TradePoliciesList;
