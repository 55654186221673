import React, {useState} from 'react';
import {createUseStyles} from 'react-jss';
import PropTypes from 'prop-types';
import Table from "../../Commons/Table.js";
import {useParams} from 'react-router-dom';
import {
    formattedDateIso,
    toMoney
} from "../../helpers/utils.js";
import {ArrowDown, ArrowUp, EditIcon} from "../../assets/icons.js";
import RestoreWorkerTableSubrow from "./RestoreWorkerTableSubrow.js";
import RestoreWorkerTableActions from "./RestoreWorkerTableActions.js";
import {RestoreWorkerTableFooter} from "./RestoreWorkerTableFooter.js";
import Popover from "../../Commons/Popover.js";
import EditNegotiatedAmountModal from "./EditNegotiatedAmountModal.js";
import RepairsStatusesDropdown from "./RepairsStatusesDrpdown.js";

const useStyles = createUseStyles((theme) => ({
    root: {
        '& h4': {
            width: '100%',
            marginTop: theme.spacing * 8,
            borderTop: `1px solid ${theme.colors.grey}`,
            padding: [theme.spacing * 2 , 0],
            fontSize: 24,
            fontWeight:600
        },
    },
    repairTitle:{
        fontSize: 18,
        fontWeight: 600,
    },
    doc:{
        '& a':{
            fontSize: 12,
            color: theme.colors.darkGrey,
            textDecoration: 'underline!important'
        }
    }
}));

const  RestoreWorkerTable = ({setDataCallback, data, title}) => {
    const {id} = useParams();
    const classes = useStyles({});
    const [rowExpandedId, setRowExpandedId] = useState(null);

    const retrieveInvoiceDoc = (item) => {
        return item?.attachments.filter( attach => (attach.type === 'invoice'))[0];
    };

    const retrieveSupplierDoc = (item) => {
        if(item?.attachments.some(attach => (attach.type === 'supplier_invoice'))){
            return item?.attachments.filter( attach => (attach.type === 'supplier_invoice'))[0];
        }else{
            return item?.attachments.filter( attach => (attach.type === 'supplier_list'))[0];
        }
    };

    return <div className={classes.root}>
        <h4>{title}</h4>
        <Table
            noHeightLimit={true}
            id={'table'}
            data={data}
            rowExpandedId={rowExpandedId}
            setRowExpandedId={setRowExpandedId}
            onSetPageCb={Function.prototype}
            onSortingDataCb={Function.prototype}
            setData={() => setDataCallback()}
            theadHeight={56}
            rowMinHeight={56}
            /* inLoading={loading}*/
            classNames={{
                table: classes.table,
                thead: classes.thead,
                row: classes.row,
            }}
            columns={['name', 'total_amount', 'estimate', 'negotiated_amount', 'negotiated_doc', 'invoice_amount', 'invoice_doc', 'status', 'expander', 'actions']}
            columnsNames={{
                name: "",
                total_amount: "tot. perizia",
                estimate: "Perizia",
                negotiated_amount: "tot. aff. lavoro",
                negotiated_doc: "Aff. lavoro",
                invoice_amount: "tot. fattura",
                invoice_doc: "fattura",
                status: "stato",
                expander: "",
                actions: ""
            }}
            columnsWidths={{
                name: "16%",
                total_amount:"10%",
                estimate: "13%",
                negotiated_amount: "10%",
                negotiated_doc: "12%",
                invoice_amount: "10%",
                invoice_doc: "10%",
                status: "10%",
                expander: "3%",
                actions: "2%",
            }}
            columnsAlignments={{
                total: 'right'
            }}
            expandedComponent={RestoreWorkerTableSubrow}
            tableFooterComponent={RestoreWorkerTableFooter}
            columnsRenderers={{
                name: (value, items, metadata) => <span className={classes.repairTitle}>
                    {items?.assigned_user?.full_name}
                </span>,
                total_amount: (value, items, metadata) => <span> {toMoney(value)}</span>,
                estimate: (value, items, metadata) => <span> Perizia del {formattedDateIso(items.created_at)}</span>,
                negotiated_amount: (value, items, metadata) => <EditNegotiated data={items} carId={id}/>,
                negotiated_doc: (value, items, metadata) => {
                    const supplierDoc = retrieveSupplierDoc(items);
                    return <span className={classes.doc}>
                        {supplierDoc ?  <a href={supplierDoc.url} target={'_blank'}>
                            {supplierDoc.filename} </a> : 'N.d'}
                </span>},
                invoice_amount: (value, items, metadata) => <span> {toMoney(value)} </span>,
                invoice_doc: (value, items, metadata) => {
                    const invoice = retrieveInvoiceDoc(items);
                    return <span className={classes.doc}>
                        {invoice ?  <a href={invoice.url} target={'_blank'}>
                            {invoice.filename} </a> : 'N.d'}
                </span>},
                status: (value, items, metadata) =>  <RepairsStatusesDropdown items={items} metadata={metadata} value={value}/>,
                expander: (item, value, metadata) => <Expander rowIndex={metadata.rowIndex} rowExpandedId={rowExpandedId}
                                                               setRowExpandedId={setRowExpandedId}/>,
                actions: (value,items,metadata) => <RestoreWorkerTableActions value={value} items={items} metadata={metadata} callback={setDataCallback} title={title}/>
            }}
        />
    </div>
};

RestoreWorkerTable.propTypes = {
    setDataCallback: PropTypes.any,
    data: PropTypes.array,
};

/** Expander Component **/
const useStylesExpander = createUseStyles(theme => ({
    root: theme.grid.actions,
}));

export const Expander = ({setRowExpandedId, rowIndex, rowExpandedId}) => {
    const classes = useStylesExpander();
    const isExpanded = (rowIndex === rowExpandedId);
    return (
        <div className={classes.root}>
            <span onClick={() => {
                setRowExpandedId(isExpanded ? null : rowIndex)
            }}>
                {isExpanded ? <ArrowUp/> : <ArrowDown/>}
            </span>
        </div>
    );
};

/** Edit negotiated amount **/
const useStylesNegotiated = createUseStyles(theme => ({
    root: {},
    affid: {
        display: 'grid',
        gridColumnGap: 4,
        alignItems: 'center',
        gridTemplateColumns: "1fr auto",
        '& svg': {
            width: `18px!important`
        }
    }
}));

export const EditNegotiated = ({data, carId}) => {
    const classes = useStylesNegotiated();
    const [negotiatedModal, setNegotiatedModal] = useState(false);

    return <>
        {negotiatedModal &&
        <Popover withHeader={true} onClose={() => (setNegotiatedModal(!negotiatedModal))} title={'Modifica importo'}>
            <EditNegotiatedAmountModal onCloseCb={() => (setNegotiatedModal(!negotiatedModal))} carId={carId} repair={data}/>
        </Popover>}

        <span className={classes.affid}>
            <>
                <span>{toMoney(data?.negotiated_amount ? data.negotiated_amount : 0 )}</span>
                <EditIcon data-color onClick={() => setNegotiatedModal(!negotiatedModal)}/>
            </>
        </span>
    </>
};

export default RestoreWorkerTable;