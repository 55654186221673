import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useHistory, useParams} from 'react-router-dom';
import SubHeading from "../../../Commons/SubHeading.js";
import Card from '../../../Commons/Card';
import {useDispatch, useSelector} from "react-redux";
import {useFieldArray, useForm} from "react-hook-form";
import cx from 'classnames';
import {
    httpCreateUbication,
    httpGetBusinessList,
    httpGetUbication,
    httpUpdateUbication
} from "../../../HttpRequests/settings.http.js";
import {errorHandler, retrieveValueForRs} from "../../../helpers/utils.js";
import {editUbication, setBusinessList} from "../../../store/actions/settingsActions.js";
import Spinner from "../../../Commons/Spinner.js";
import {AddIcon, Pointer, RemoveIcon} from "../../../assets/icons.js";
import Text from "../../../Commons/Text.js";
import Select from "../../../Commons/Select.js";
import Checkbox from "../../../Commons/Checkbox.js";
import Button from "../../../Commons/Button.js";
import * as Yup from "yup";
import {alertToggle} from "../../../store/actions/alertActions.js";

const useStyles = createUseStyles(theme => ({
    gridContainer: {
        'position': 'relative',
        height: ({bigSubHeader}) => `calc(100vh - ${bigSubHeader ? theme.bigSubHeader + theme.headerHeight : theme.smallSubHeader + theme.headerHeight}px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
    },
    formRoot: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridColumnGap: theme.spacing,
        borderRadius: 20,
        ...theme.paper
    },
    gridContent: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        alignItems: 'start',
        gridColumnGap: theme.spacing * 2,
        gridRowGap: theme.spacing * 2,
        padding: theme.spacing
    },
    colAll: {
        gridColumn: '1/4'
    },
    colFirst: {
        gridColumn: '1/2'
    },
    colSecond: {
        gridColumn: '2/3'
    },
    colThird: {
        gridColumn: '3/4'
    },
    title: {
        display: 'grid',
        gridColumnStart: 1,
        gridColumnEnd: 1,
        fontWeight: 'bold',
        margin: [theme.spacing * 2, 0, 0]
    },
    btns: {
        marginTop: theme.spacing * 2,
        display: 'grid',
        alignItems: 'center',
        gridColumn: '1/4',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: theme.spacing,
        width: 300,
        justifySelf: 'end'
    },
    location: {
        display: 'grid',
        gridColumn: '1/2',
        gridColumnGap: theme.spacing * 2,
        gridTemplateColumns: '1fr 40px',
        alignItems: 'end'
    }
}));

const validationSchema = Yup.object().shape({
    name: Yup.string().required(`Inserisci il nome.`).min(3, 'Il nome deve contenere almeno 3 caratteri'),
    address: Yup.string().required(`Inserisci l'indirizzo.`),
    city: Yup.string().required(`Inserisci la città.`),
    ubication_capacity: Yup.string()
        .when("external", {is: false,
        then: Yup.string().required("Inserisci la capienza del piazzale.")
    }),
    daily_permanence_cost: Yup.string().when("external", {is: false,
        then: Yup.string().required("Inserisci un costo giornaliero di giacenza.")}),
});

const UserAccount = () => {
    const classes = useStyles({bigSubHeader: false});
    const {id} = useParams(); //id of user from url params react-router-dom
    const history = useHistory();
    const [ubication, setUbication] = useState(useSelector((state) => state?.settings?.ubicationsList && state.settings.ubicationsList.find((arr) => +arr.id === +id)));
    const [loading, setLoading] = useState(false);
    const [businesses, setBusinesses] = useState(null);
    const dispatch = useDispatch();
    const usersAutocomplete = useSelector(state => state.autocompletes.usersAutocomplete);

    const {register, handleSubmit, control, errors, reset,watch} = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            ...ubication,
            city: ubication?.city,
            supervisor_id: retrieveValueForRs(usersAutocomplete, ubication?.supervisor_id)
        },
        validationSchema
    });
    const {fields, append, remove} = useFieldArray({control, name: "locations"});

    useEffect(() => {
            if (id) {
                getUbicationsData();
            }
            getBusinesses();
        }, []
    );

    const getBusinesses = async () => {
        setLoading(true);
        try {
            const {data: {data}} = await httpGetBusinessList();
            dispatch(setBusinessList(data));
            setBusinesses(data.map((el) => ({label: el.name, value: el.id})));
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false)
        }
    };

    const getUbicationsData = async () => {
        setLoading(true);
        try {
            const {data} = await httpGetUbication(id);
            setUbication(data);
            reset({
                ...data,
                city: data?.city,
                company_id: data.company ? {value: data?.company.id, label: data?.company.name} : null,
                supervisor_id: retrieveValueForRs(usersAutocomplete, ubication?.supervisor_id)
            });
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    };

    const onSubmit = async (values) => {
        const locationsRemapped = values.locations && values.locations.map(item => {
            if (item.id === 'new') {
                delete item['id'];
            }
            return item
        });
        const dataToSend = {
            ...values,
            active: ubication?.active,
            id: id,
            company_id: values.company_id ? values.company_id?.value : null,
            supervisor_id: values.supervisor_id ? values.supervisor_id?.value : null,
            locations: locationsRemapped ? locationsRemapped : []
        };

        try {
            setLoading(true);
            const {data: ubication} = id ? await httpUpdateUbication(dataToSend, id) : await httpCreateUbication(dataToSend);
            dispatch(editUbication(ubication));
            history.push(`/settings/locations`);
            dispatch(alertToggle((id ? 'Ubicazione correttamente aggiornata' : 'Nuova ubicazione correttamente creata'), 'success'))
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    };

    return <>
        {loading && <Spinner overlayFullscreen={true}/>}
        <SubHeading title={id ? "Modifica ubicazione" : "Crea ubicazione"} type={'small'}/>
        <div className={classes.gridContainer}>
            <Card cardTitle={"Informazioni"} cardIcon={<Pointer data-color/>}>
                <form onSubmit={handleSubmit(onSubmit)} className={classes.formRoot}>
                    <div className={classes.gridContent}>
                        <p className={cx(classes.colAll, classes.title)}>Dati ubicazione</p>
                        <Checkbox className={classes.colAll} name={'external'} ref={register} isSmall={true}
                                  label={'Ubicazione esterna'}/>
                        <Text className={classes.colFirst} type={'text'} label={'Nome Ubicazione*'} name={'name'}
                              ref={register} errors={errors.name}/>
                        <Select className={classes.colSecond} name={'company_id'} label={'Business*'}
                                options={businesses ? businesses : []} ref={register} control={control}
                        />
                        <Text className={classes.colFirst} type={'text'} label={'Indirizzo*'} name={'address'}
                              ref={register} errors={errors.address}/>
                        <Text className={classes.colSecond} type={'text'} label={'Città*'} name={'city'} ref={register}
                              errors={errors.city}/>
                        {!watch('external') && <Text className={classes.colFirst} type={'number'} min={0} label={'Capienza piazzale*'}
                              name={'ubication_capacity'} ref={register} errors={errors.ubication_capacity}/>}
                        {!watch('external') && <Text className={classes.colSecond} type={'text'} label={'Costo giornaliero giacenza*'}
                              name={'daily_permanence_cost'} ref={register} errors={errors.daily_permanence_cost}/>}
                        {!watch('external') && <Select className={classes.colThird} name={'supervisor_id'} label={'Responsabile logistica'}
                                options={usersAutocomplete ? usersAutocomplete : []} ref={register} control={control}
                        />}

                        <p className={cx(classes.colAll, classes.title)}>Posizioni</p>
                        {fields.map((item, index) => <div key={item.bId ? item.bId : item.id}
                                                          className={classes.location}>
                            <Text type={'text'} label={'Nome posizione'} name={`locations[${index}].name`}
                                  defaultValue={item.name} ref={register()}/>
                            <input type={'text'} style={{display: 'none'}} name={`locations[${index}].id`}
                                   defaultValue={item.id} ref={register()}/>
                            <Button type="button" onClick={() => remove(index)} data-primary><RemoveIcon
                                data-color/></Button>
                        </div>)}
                        <Button className={classes.colFirst} type="button" onClick={() => append({
                            id: 'new',
                            name: "",
                            bId: `new${Math.random().toString(36).substring(7)}`
                        })} data-primary icon={<AddIcon data-color/>}> Aggiungi Posizione </Button>

                        <div className={classes.btns}>
                            <Button type="button" onClick={() => history.push(`/settings/locations`)} data-secondary>Annulla</Button>
                            <Button type={'submit'} data-primary>Salva</Button>
                        </div>
                    </div>
                </form>
            </Card>
        </div>
    </>
};

export default UserAccount;
