import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useDispatch, useSelector} from 'react-redux';
import Button from '../../../Commons/Button.js';
import Spinner from '../../../Commons/Spinner.js';
import SubHeading from "../../../Commons/SubHeading.js";
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {
    errorHandler,
    formattedDate,
    normalizeFiltersForBend,
    removeSpecifcKeyInObj,
    removeUnsettedKeyInObj,
    toMoney,
} from '../../../helpers/utils.js';
import {httpFetchStock, httpGetCampaignFilters} from "../../../HttpRequests/stock.http.js";
import qs from 'query-string';
import Table from '../../../Commons/Table.js';
import Checkbox from '../../../Commons/Checkbox.js';
import Popover from '../../../Commons/Popover.js';
import AdvancedFilters from '../../../Components/Cars/AdvancedFilters.js';
import ImportPlate from '../../../Components/TradePolices/ImportPlate.js';
import SimpleSearch from '../../../Commons/SimpleSearch.js';
import {
    httpEditCampaign,
    httpGetCampaign,
    httpGetCarsFromPlates, httpGetCarsFromPlatesCsv,
    httpModifyAmount,
    httpSaveCampaignCars
} from '../../../HttpRequests/campaigns.http.js';
import CampaignReprice from './CampaignReprice.js';
import AdvancedFiltersController from "../../../Components/Cars/AdvancedFiltersController.js";
import cx from "classnames";
import {setCars} from "../../../store/actions/carsActions.js";
import {AddIcon, EditIcon, StarIcon} from "../../../assets/icons.js";
import {alertToggle} from "../../../store/actions/alertActions.js";
import EditCampaignNamePopover from "../../../Components/TradePolices/EditCampaignNamePopover.js";
import RangePicker from "../../../Commons/RangePicker.js";
import {editCampaignsList} from "../../../store/actions/campaignActions.js";

const useStyles = createUseStyles(theme => ({
    gridContainer: {
        'position': 'relative',
        // with big/small subheader like permute page
        height: ({bigSubHeader}) => `calc(100vh - ${bigSubHeader ? theme.bigSubHeader + theme.headerHeight : theme.smallSubHeader + theme.headerHeight}px)`,
        // without subheader
        //height: `calc(100vh - ${theme.headerHeight}px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        display: 'grid',
        gridTemplateColumns: '1fr',
        [theme.mUp]: {
            padding: [0, theme.spacing * 4, 0],
        },
        [theme.s]: {
            padding: [theme.spacing],
        },
    },
    tableHeader: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        gridColumnGap: theme.spacing,
        alignItems: 'center',
        marginBottom: theme.spacing * 2,
        height: 80,
        '& h3': {
            fontWeight: 600,
            lineHeight: 1
        },
        '& small': {
            color: theme.colors.darkGrey,
            fontSize: 16,
        }
    },
    activeFilters: {
        display: 'grid',
        justifyContent: "start",
        alignContent: "center",
        gap: theme.spacing,
        gridAutoFlow: "row",
        marginBottom: theme.spacing * 2,
    },
    dot: {
        width: 10,
        height: 10,
        borderRadius: '50%',
        marginRight: theme.spacing,
        '&:before': {
            content: '""'
        }
    },
    green: {
        backgroundColor: theme.colors.green,
    },
    yellow: {
        backgroundColor: theme.colors.yellow,
    }, red: {
        backgroundColor: theme.colors.red,
    },
    filtersList: {
        gridColumnGap: theme.spacing,
        display: "grid",
        gridAutoFlow: "column",
        margin: [theme.spacing, 0]
    },
    plateSearch: {
        display: "grid",
        justifyContent: "end",
        gridTemplateColumns: 'auto auto auto',
        alignItems: "center",
        gridColumnGap: theme.spacing * 3,
        '& a': {
            textDecoration: "underline"
        }
    },
    filtersButtons: {
        display: "grid",
        gridTemplateColumns: "150px 80px auto",
        gridColumnGap: theme.spacing * 2,
        alignItems: "center",
        justifyContent: "end",
        marginBottom: theme.spacing * 4,
        marginTop: theme.spacing * 4
    },
    reset: {
        color: theme.colors.red,
        fontSize: 16,
        fontWeight: 400,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            marginRight: "4px",
            fill: theme.colors.red
        }
    },
    badgeContainer: {
        '& svg': {}
    },
    thead: {
        height: '10%'
    },
    addCars: {
        marginBottom: theme.spacing * 4,
        marginTop: theme.spacing * 4
    },
    tableTitle: {
        fontWeight: 'bold',
        marginBottom: theme.spacing * 4,
        marginTop: theme.spacing * 4
    },
    btns:{
        display: "grid",
        width: 420,
        alignItems: "center",
        gridAutoFlow: "column",
        gridColumnGap: 12
    },
    edit:{
        width: '16px!important',
        fill: theme.colors.darkGrey
    },
}));

const CampaignDetails = () => {
    //id of campaign
    let {id} = useParams();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [campaignLoading, setCampaignLoading] = useState(false);
    const [stock, setStock] = useState([]);
    const [campaignCars, setCampaignCars] = useState(useSelector(state => state.campaigns.campaignCars));
    const [showEditNamePopover, setShowEditNamePopover] = useState(false);
    const location = useLocation();
    const history = useHistory();
    //table statuses
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(1);
    const [details, setDetails] = useState();
    const [showPopover, setShowPopover] = useState(false);
    const [lastPage, setLastPage] = useState(null);
    const classes = useStyles({bigSubHeader: false});
    const [showImport, setShowImport] = useState(false);
    //filters things
    const [sorter, setSorter] = useState(null);
    const [filters, setFilters] = useState(location.search ? {
        ...qs.parse(location.search, {
            arrayFormat: 'comma', parseBooleans: true, skipNull: true, skipEmptyString: true
        })
    } : {});
    const [structure, setStructure] = useState(null);
    const [autocompletes, setAutocompletes] = useState(null);
    const [hideBooked, setHideBooked] = useState(false)
    //selections
    const [selectAllActive, setSelectAllActive] = useState(false);

    useEffect(() => {
        if (!!campaignCars.length && total > 0) {
            removeInCampaignsFromStock(campaignCars)
        }
    }, [campaignCars,total])

    //fetch at the init
    useEffect(() => {
        //fetch Autocompletes and campaigns
        getFilters();
        getCampaignDetails();
        //clean up on unmount
        return () => {
            dispatch(setCars([]))
        };
    }, []);

    //fetch when filters change
    useEffect(() => {
        setPage(1);
        setLastPage(null);
        getStock(1);
        history.replace({
            pathname: `/stock/trade-policies/${id}`, search: qs.stringify({...filters}, {
                arrayFormat: 'comma', parseBooleans: true, skipEmptyString: true, skipNull: true
            }),
        });
    }, [filters]);

    //fetch when page change
    useEffect(() => {
        if (!!stock.length && page !== 1) {
            getStock(page)
        }
    }, [page]);

    const removeInCampaignsFromStock = (inCampaignArray) => {
        const results = stock.filter(({id: id1}) => !inCampaignArray.some(({id: id2}) => id2 === id1));
        setStock(results);
    }

    //is reset is temporary to solve async update issue of filters
    const getStock = async (actualPage = 1, isReset = false, dataSorters = sorter) => {
        if (lastPage && actualPage > lastPage) return;
        if (actualPage === 1) {
            setStock([]);
        }
        setLoading(true);

        try {
            const {data: {data, total, last_page}} = await httpFetchStock(actualPage, 20, dataSorters, isReset ? {} : filters);
            if (actualPage === 1) {
                setStock(data);
            } else {
                setStock(stock.concat(data));
            }
            setTotal(total);
            setLastPage(last_page)
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    };

    const getCampaignDetails = async () => {
        setCampaignLoading(true)
        try {
            const {data} = await httpGetCampaign(id);
            setDetails(data);
            setCampaignCars(data.cars);
        } catch (e) {
            errorHandler(e, dispatch);
        }finally {
            setCampaignLoading(false)
        }
    };

    const getFilters = async (actualFilters) => {
        if (actualFilters) {
            normalizeFiltersForBend(actualFilters);
        }
        try {
            const {data: {autocompletes, structure}} = await httpGetCampaignFilters(actualFilters);
            setStructure(structure);
            setAutocompletes(autocompletes);
        } catch (e) {
            errorHandler(e, dispatch)
        }
    };

    const onChangePage = (isFilter = false) => {
        isFilter ? setPage(1) : setPage(page + 1);
    };

    const onDataSorting = (newSort) => {
        const sorters = qs.stringify(newSort, {
            arrayFormat: 'comma', parseBooleans: true, skipEmptyString: true, skipNull: true
        })
        setSorter(sorters);
        setPage(1);
        getStock(1, false, sorters);
    };

    const onComplexSearch = (search) => {
        const newFilters = {...filters, ...search};
        removeUnsettedKeyInObj(newFilters);
        history.replace({
            pathname: `/stock/trade-policies/${id}`, search: qs.stringify(newFilters, {
                arrayFormat: 'comma',
                skipEmptyString: true,
                skipNull: true
            }),
        });
        setFilters(newFilters);
    };

    const handleHideBooked = () => {
        setHideBooked(!hideBooked);
        history.replace({
            pathname: `/stock/trade-policies/${id}`,
            search: qs.stringify({...filters, hide_booked: !hideBooked}, {
                arrayFormat: 'comma', parseBooleans: true, skipEmptyString: true, skipNull: true
            }),
        });
        setFilters({...filters, hide_booked: !hideBooked});
    };

    const applyAdvancedFilters = (values) => {
        normalizeFiltersForBend(values);
        setFilters({...values});
        //add query params to route
        history.replace({
            pathname: `/stock/trade-policies/${id}`, search: qs.stringify({...filters, ...values}, {
                arrayFormat: 'comma',
                skipEmptyString: true,
                skipNull: true
            }),
        });
        setShowPopover(!showPopover)
    };

    const resetAdvancedFilters = () => {
        setFilters({});
        setSorter(null);
        setHideBooked(false);
        //clear query params from route
        history.replace({pathname: `/stock/trade-policies/${id}`, search: null});
        getStock(1, true);
        getFilters({});
    };

    const confirmCarsDiscount = async (discountInfos) => {
        setCampaignLoading(true);
        try {
            const {data} = await httpModifyAmount(id, discountInfos);
            setCampaignCars(data.cars);
            history.push(`/stock/trade-policies/`);
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setCampaignLoading(false)
        }
    };

    const removeCarFromCampaign = (removedCar) => {
        const filteredCars = campaignCars.filter(car => car.id !== removedCar.id);
        //add removed car to stock data
        removedCar.selected = false;
        setStock([removedCar, ...stock]);
        //set new campaigns array
        setCampaignCars(filteredCars)
    };

    const removeSpecificFilter = (key) => {
        if (key === 'hide_booked') {
            handleHideBooked()
        }
        setFilters({...removeSpecifcKeyInObj(filters, key)});
        history.replace({
            pathname: `/stock/trade-policies/${id}`, search: qs.stringify({...filters}, {
                arrayFormat: 'comma',
                skipEmptyString: true,
                skipNull: true
            }),
        });
    };

    /** Selection code **/
    const selectionIsNotEmpty = stock.some(item => item.selected);

    const selectRow = ({id}) => event => {
        if (event.target.hasAttribute('data-onclick'))
            return;
        const item = stock.find(item => +item.id === +id);
        item.selected = !item.selected;
        setStock([...stock]);
    };

    const selectAll = () => {
        setSelectAllActive(!selectAllActive);
        stock.forEach(item => item.selected = (!selectionIsNotEmpty));
        setStock([...stock]);
    };

    const addCarsToCampaign = () => {
        const selectedCars = stock.filter(item => item.selected);
        setCampaignCars([...campaignCars, ...selectedCars]);
    };

    /** Import anda save functions **/
    const importPlates = async (values) => {
       /* console.log(values?.plates_file[0])*/
        try {
            setLoading(true)
            const {data} = values?.plates ? await httpGetCarsFromPlates(id, values.plates) : await httpGetCarsFromPlatesCsv(id, values?.plates_file[0]);
            setCampaignCars([...data.cars, ...campaignCars])
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
            setShowImport(!showImport)
        }
    };

    const saveCampaign = async () => {
        setLoading(true)
        let carsId = campaignCars.map(({id}) => id)
        try {
            await httpSaveCampaignCars(id, carsId);
            dispatch(alertToggle('Campagna salvata correttamente.', 'success'));
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false)
        }
    };

    const editCampaignPeriod = async (data) => {
        console.log(data)
        setLoading(true);
        try {
            const {data: newCampaign} = await httpEditCampaign(details?.id, {start_campaign: data.startDate, end_campaign: data.endDate});
            setDetails(newCampaign);
            dispatch(alertToggle('Periodo della campagna modificato correttamente.', 'success'));
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    }

    return <>
        {showEditNamePopover && <Popover title={'Modifica nome'} withHeader={true} onClose={() => setShowEditNamePopover(!showEditNamePopover)}>
            <EditCampaignNamePopover campaign={details} onCloseCb={() => setShowEditNamePopover(!showEditNamePopover)} setCampaignCb={setDetails}/>
        </Popover>}

        {loading && <Spinner overlayFullscreen={false}/>}
        <SubHeading title={<><strong>{details?.name}</strong> <EditIcon className={classes.edit} data-color onClick={() => setShowEditNamePopover(!showEditNamePopover)}/></>}
                    type={'small'}
                    rightSearchComponent={<div className={classes.plateSearch}>
                        <Button data-primary onClick={saveCampaign}>Salva campagna</Button>
                        <SimpleSearch filters={filters} setPage={setPage} name={'plate'}
                                      onComplexSearchCb={onComplexSearch} placeholder={'cerca per targa'}/>
                    </div>}
        />
        {showPopover &&
        <Popover withHeader={true} title={'Filtri'} minWidth={500} onClose={() => setShowPopover(!showPopover)}>
            <AdvancedFilters filters={filters}
                             structure={structure}
                             autocompletes={autocompletes}
                             isTwoColumns={true}
                             onRecalculateAutocompletesCb={getFilters}
                             applyFilterCb={applyAdvancedFilters}
                             resetFilterCb={Function.prototype}/>
        </Popover>}
        {showImport &&
        <Popover withHeader={false} width={700} onClose={() => setShowImport(!showImport)}>
            <ImportPlate importPlatesCb={importPlates}/>
        </Popover>}

        <div className={classes.gridContainer}>
            {details && <div style={{maxWidth: 240, marginTop: 16}}><RangePicker
                startDate={details?.start_campaign}
                endDate={details?.end_campaign}
                notInFilters={true}
                inForm={false}
                label={'Periodo'}
                openTo={'bottom'}
                name={'range'}
                callback={(data) => editCampaignPeriod(data)}
            /></div>}
            <div className={classes.tableHeader}>
                <AdvancedFiltersController structure={structure} filters={filters}
                                           showFilterModalCb={() => setShowPopover(!showPopover)}
                                           handleHideBookedCb={handleHideBooked}
                                           resetAdvancedFiltersCb={resetAdvancedFilters}
                                           removeSpecificFilterCb={removeSpecificFilter}/>
            </div>

            <Table
                id={'table'}
                data={stock}
                inLoading={loading}
                filters={filters}
                onSetPageCb={onChangePage}
                onSortingDataCb={onDataSorting}
                theadHeight={40}
                rowMinHeight={40}
                bodyMinHeight={250}
                classNames={{
                    table: classes.table,
                    thead: classes.thead,
                    row: classes.row,
                }}
                columns={['selected', 'brand', 'model', 'version', 'days_in_stock', 'plate', 'mileage', 'owning_company', 'matriculation', 'current_position', 'price']}
                columnsNames={{
                    selected: '',
                    brand: "Marca",
                    model: "Modello",
                    version: "Versione",
                    days_in_stock: 'Giacenza',
                    plate: "Targa",
                    mileage: 'Km',
                    owning_company: "Azienda proprietaria",
                    matriculation: "Immatricolazione",
                    current_position: "Ubicazione",
                    price: "Prezzo",
                }}
                columnsWidths={{
                    selected: '3%',
                    brand: "10%",
                    model: "10%",
                    version: "10%",
                    days_in_stock: '10%',
                    plate: "5%",
                    mileage: '5%',
                    fuel_method: '5%',
                    owning_company: "15%",
                    matriculation: "10%",
                    current_position: "10%",
                    price: "5%",
                }}
                columnsAlignments={{
                    actions: 'right',
                }}
                backEndSortable={true}
                sortable={true}
                headersSorters={['days_in_stock', 'mileage']}
                headersRenderers={{
                    selected: <Checkbox className={classes.selected} data-onclick data-id={0} isSelectAll={true}
                                        checked={selectionIsNotEmpty} isSmall={true} onChange={selectAll}/>,
                }}
                columnsRenderers={{
                    selected: (value, {id}) => <Checkbox data-checkbox data-id={id} checked={value} isSmall={true}
                                                         onClick={selectRow({id})}/>,
                    brand: (item, value) => <strong>{value?.model?.brand?.name}</strong>,
                    model: (value) => <span>{value?.name}</span>,
                    price: (item) => <strong>{item ? toMoney(item) : '-'}</strong>,
                    days_in_stock: (item) => <span>
                                <span
                                    className={cx(classes.dot, item <= 25 ? classes.green : item <= 75 ? classes.yellow : classes.red)}/>{item}
                            </span>,
                    mileage: (item, value) => <span>{value?.mileage}</span>,
                    matriculation: (item) => <span>{formattedDate(item)}</span>,
                    owning_company: (item) => <span>{item ? item : '-'}</span>,
                    current_position: (item) => <span>{item ? item : '-'}</span>,
                }}
            />

            <div className={classes.btns}>
                <Button data-primary data-small disabled={!selectionIsNotEmpty} icon={<AddIcon data-color/>}
                        className={classes.addCars} onClick={addCarsToCampaign}>Inserisci nella campagna </Button>
                <Button data-secondary data-small onClick={() => setShowImport(true)}>
                    Import massivo targhe
                </Button>
            </div>

            <CampaignReprice campaignCars={campaignCars} loading={campaignLoading}
                             removeCarFromCampaignCb={removeCarFromCampaign} repriceCampaignCb={confirmCarsDiscount}/>
        </div>
    </>
};

export default CampaignDetails