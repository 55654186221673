import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import Table from "../../Commons/Table.js";
import {
    allShiftsTypes,
    clearFilters,
    errorHandler,
    formattedDate,
    formattedDateHours,
    getLabelByValue,
    retrieveValueForRs,
    taskStatusColors,
    taskStatuses,
} from "../../helpers/utils.js";
import SubHeading from "../../Commons/SubHeading.js";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from 'react-router-dom';
import SimpleSearch from "../../Commons/SimpleSearch.js";
import qs from "query-string";
import {useWindowWidth} from "@react-hook/window-size";
import Avatar from "../../Commons/Avatar.js";
import Badge from "../../Commons/Badge.js";
import ShiftsFilters from "../../Components/Shifts/ShiftsFilters.js";
import {setShiftRequestsList} from "../../store/actions/shiftsActions.js";
import {httpGetShiftRequests} from "../../HttpRequests/shifts.http.js";
import Button from "../../Commons/Button.js";
import Popover from "../../Commons/Popover.js";
import ShiftPlanningModal from "../../Components/Shifts/ShiftPlanningModal.js";
import AddNewCarToShiftsModal from "../../Components/Shifts/AddNewCarToShiftsModal.js";

const useStyles = createUseStyles(theme => ({
    gridContainer: {
        'position': 'relative',
        // with big/small subheader like permute page
        height: ({bigSubHeader}) => `calc(100vh - ${bigSubHeader ? theme.bigSubHeader + theme.headerHeight: theme.smallSubHeader + theme.headerHeight}px)`,
        // without subheader
        //height: `calc(100vh - ${theme.headerHeight}px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        padding: ({isTablet}) => isTablet ? "32px 16px 0" : "32px 32px 0",
        [theme.s]: {
            padding: [theme.spacing],
        },
    },
    doubleRow: {
        ...theme.grid.doubleRow,
    },
    headBtns:{
        display: "grid",
        alignItems: "center",
        gridTemplateColumns: "180px",
        justifyContent: "end",
        padding: "0 0 8px"
    }
}));

const ListShiftRequests = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    // change autocomplete with simpleLocations composed with ubicaiton_name
    const allLocations = useSelector(state => state.autocompletes.simpleLocations);
    const shiftRequests = useSelector(state => state.shifts.shiftRequestsList);
    const [showShiftPlanModal, setShowShiftPlanModal] = useState(false);
    const [showAddCarModal, setShowAddCarModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState(location.search ? {
        ...qs.parse(location.search, {
            arrayFormat: 'comma',
            parseBooleans: true
        })
    } : {order_by: 'preferred_date', order_direction: 'desc'});
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(null);
    const classes = useStyles({bigSubHeader: true, isTablet: useWindowWidth() < 1200});// or false if type is small

    //fetch when filters change
    useEffect(() => {
        if (!!Object.keys(filters).length) {
            setPage(1);
            setLastPage(null);
            getShiftRequests(1);
            history.replace({
                pathname: '/list/shift-requests', search: qs.stringify({...filters}, {
                    arrayFormat: 'comma',
                    skipEmptyString: true,
                    skipNull: true
                }),
            });
        }
    }, [filters]);

    //fetch when page change
    useEffect(() => {
        if (!!shiftRequests.length && page !== 1) {
            getShiftRequests(page)
        }
    }, [page]);

    //clean up
    useEffect(() => {
        return () => {
            dispatch(setShiftRequestsList([]))
        };
    }, []);

    const getShiftRequests = async (actualPage = 1) => {
        if (lastPage && actualPage > lastPage) return;
        if (actualPage === 1) {
            dispatch(setShiftRequestsList([]))
        }
        setLoading(true);
        clearFilters(filters);
        try {
            const {data: {data, last_page}} = await httpGetShiftRequests({limit: 20, ...filters, page: actualPage});
            if (actualPage === 1) {
                dispatch(setShiftRequestsList(data));
            } else {
                dispatch(setShiftRequestsList(shiftRequests.concat(data)));
            }
            setLastPage(last_page)
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    };

    const onChangePage = (isFilter = false) => {
        if (isFilter) {
            setPage(1)
        } else {
            setPage(page + 1)
        }
    };

    const onDataSorting = (newSort) => {
        setFilters({
            ...filters,
            ...newSort,
        });
        setPage(1);
    };

    return <>
        {showShiftPlanModal && <Popover withHeader={false} minWidth={450} onClose={() => setShowShiftPlanModal(!showShiftPlanModal)}
                                            contextMode={false}>
            <ShiftPlanningModal onClose={() => setShowShiftPlanModal(!showShiftPlanModal)}/>
        </Popover>}
        {showAddCarModal && <Popover withHeader={false} minWidth={450} onClose={() => setShowAddCarModal(!showAddCarModal)}
                                              contextMode={false}>
            <AddNewCarToShiftsModal onClose={() => setShowAddCarModal(!showAddCarModal)}/>
        </Popover>}
        <SubHeading title={"Richieste spostamenti"} type={'big'} justifySelf={'unset'}
                    filtersComponent={
                        <ShiftsFilters setFilters={setFilters} filters={filters} showDate={true} dateFieldName={'preferred_date'}
                                       setPage={setPage}
                                       selectsProps={[
                                           {
                                               name: 'type', placeholder: 'Tipologia', label: 'modello',
                                               options: (allShiftsTypes ? allShiftsTypes : [])
                                           }, {
                                               name: 'location_id_from', placeholder: 'Da', label: 'Da',
                                               options: (allLocations ? allLocations : [])
                                           }, {
                                               name: 'location_id_to', placeholder: 'A', label: 'A',
                                               options: (allLocations ? allLocations : [])
                                           }]}
                                       searchComponent={<SimpleSearch filters={filters} setFilters={setFilters}
                                                                      name={'plate'}
                                                                      placeholder={'cerca targa'} setPage={setPage}/>}
                                       rightButtons={
                                           <Button data-primary onClick={() => setShowShiftPlanModal(!showShiftPlanModal)}>Pianifica Trasporto</Button>
                                       }
                        />}
        />

        <section className={classes.gridContainer}>
            <div className={classes.headBtns}>
                <Button data-xs-small onClick={() => setShowAddCarModal(!showAddCarModal)}> Aggiungi auto dal nuovo</Button>
            </div>
            <Table
                id={'table'}
                data={shiftRequests}
                inLoading={loading}
                filters={filters}
                onSetPageCb={onChangePage}
                onSortingDataCb={onDataSorting}
                theadHeight={40}
                rowMinHeight={40}
                classNames={{
                    table: classes.table,
                    thead: classes.thead,
                    row: classes.row,
                }}
                columns={['car_plate', 'type', 'preferred_date', 'location_from', 'location_to']}
                columnsNames={{
                    car_plate: "Targa",
                    type: "Tipologia trasferimento",
                    preferred_date: "Data richiesta",
                    location_from: "Da",
                    location_to: "A",
                   /* user: "Eseguito da",
                    document: "Bolla",
                    status: "Stato",*/
                }}
                columnsWidths={{
                    car_plate: "16%",
                    type: "25%",
                    preferred_date: "15%",
                    location_from: "22%",
                    location_to: "22%",
                   /* user: "15%",
                    document: "8%",
                    status: "12%",*/
                }}
               /* columnsAlignments={{
                    status: 'center',
                }}*/
                backEndSortable={true}
                sortable={true}
                //headersSorters={['assign_date', 'user', 'status']}
                columnsRenderers={{
                    car_plate: (value,items) => <span style={{textTransform: 'uppercase'}}>
                        {items?.car_identifiers?.plates ? items.car_identifiers.plates[0] :
                            items?.car_identifiers?.VIN ? items?.car_identifiers?.VIN : '-'}
                    </span>,
                    type: (value) => <span>{retrieveValueForRs(allShiftsTypes, value)[0]?.label}</span>,
                    preferred_date: (value, items) => <div className={classes.doubleRow}>
                        <span> {formattedDate(items?.preferred_date ? items?.preferred_date : items?.transfer_date)}</span>
                        <small>{formattedDateHours(items?.preferred_date ? items?.preferred_date : items?.transfer_date)}</small>
                    </div>,
                    location_from: (value,items) => <span><b>{items?.location_from?.ubication_name}</b></span>,
                    location_to: (value,items) => <span><b>{items?.location_to?.ubication_name}</b></span>,
                    user: (value, items) => <span> {items?.assigned_user ?
                        <Avatar size={'small'} fullName={items?.assigned_user?.full_name}
                                img={items?.assigned_user?.photo_link}/> : '-'} </span>,
                    document: (value, items) => <span className={classes.doc}>
                        {items?.attachment ? <a href={items?.attachment?.link}
                                                target={'_blank'}>{items?.attachment?.filename} </a> : 'N.d'}
                    </span>,
                    status: (value) => <Badge title={value && getLabelByValue(taskStatuses, value)}
                                              statusColor={taskStatusColors[value]} badgeSize={'small'}/>
                }}
            />
        </section>
    </>
};

export default ListShiftRequests;
