import axios from 'axios';

/**
 *
 * @param id
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpGetCarDocumentsStructure = () => {
    return axios.get(`/documents/autocomplete`);
};

/**
 * @param id
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpGetCarDocuments = (id) => {
    return axios.get(`/cars/${id}/documents`);
};

/**
 * ‘type’ => ‘string|required’,
 ‘link’ => ‘string|required’,
 ‘leasing_related’ => ‘boolean|required’,

 * @param id
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpSaveCarDocument = (id, data) => {
    return axios.post(`/cars/${id}/documents`,{...data});
};

/**
 *
 * @param data
 * @param id
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpUpdateCarDocument = (id,data) => {
    return axios.put(`/cars/${id}/documents/${data.id}`, {...data});
};

/**
 *
 * @param data
 * @param id
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpDeleteCarDocuments = (id,data) => {
    return axios.delete(`/cars/${id}/documents/${data.id}`, {...data});
};





