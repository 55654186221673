import React, {useEffect, useState} from 'react';
import {createUseStyles} from "react-jss";
import Button from "../../Commons/Button.js";
import {useForm} from "react-hook-form";
import RangeWrapper from "../../Commons/RangeWrapper.js";
import Select from "../../Commons/Select.js";
import DayPickerInput from "../../Commons/DayPickerInput.js";
import Radio from "../../Commons/Radio.js";
import ToggleSwitch from "../../Commons/ToggleSwitch.js";
import {retrieveValueForRs} from "../../helpers/utils.js";
import {RefreshIcon} from "../../assets/icons.js";
import moment from 'moment'

const useStyles = createUseStyles(theme => ({
    root: {
        display: 'grid',
        width: '100%',
        gridRowGap: theme.spacing * 3,
        justifyItems: 'center'
    },
    form: {
        display: "grid",
        gridColumnGap: theme.spacing * 4,
        gridRowGap: theme.spacing * 3,
        gridTemplateColumns: ({isTwoColumns}) => isTwoColumns ? "repeat(2, minmax(100px, 1fr))" : "repeat(3, minmax(100px, 1fr))",
        width: "100%",
        padding: [0, theme.spacing * 2]
    },
    radioContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: theme.spacing,
        gridRowGap: theme.spacing,
        padding: theme.spacing
    },
    label: theme.control.label,
    single_line: {
        gridColumn: ({isTwoColumns}) => isTwoColumns ? '1 / span 2' : '1 / span 3',
        display: 'grid',
        marginTop: theme.spacing * 2
    },
    btns: {
        gridColumn: ({isTwoColumns}) => isTwoColumns ? '1 / span 2' : '1 / span 3',
        marginTop: theme.spacing * 2,
        display: 'flex',
        alignItems: 'center',
        justifySelf: 'end',
        gridColumnGap: theme.spacing * 2,
        gridTemplateColumns: '1fr',
    },
    reset: {
        color: theme.colors.red,
        fontSize: 16,
        fontWeight: 400,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            marginRight: "4px",
            fill: theme.colors.red
        }
    },
    daysInStockPad:{
        padding: [0,0,0, theme.spacing * 2]
    },
    pricePad:{
        padding: [0,theme.spacing * 2, 0 , 0]
    }
}));

const resetData = {
    "autocompletes": null,
    "days_in_stock": null,
    "status": null,
    "category": null,
    "type": null,
    "brand": null,
    "version": null,
    "color": null,
    "fuel_method": null,
    "seats": null,
    "doors": null,
    "structure": null,
    "accessories": null,
    "company": null,
    "fiscality": null,
    "ubication": null,
    "acquisition_channel": null,
    "warranty": null,
    "matriculation": null,
    "no_price": null
}

const AdvancedFilters = ({structure, autocompletes, applyFilterCb, isTwoColumns = false, filters, onRecalculateAutocompletesCb}) => {
    const classes = useStyles({isTwoColumns});
    const [resetObj, setResetObj] = useState(null);

    //prepare reset obj
    useEffect(() => {
        setResetObj({
            ...resetData,
            no_price: false,
            price: Object.values(autocompletes.price ? autocompletes.price : {}),
            days_in_stock: Object.values(autocompletes.days_in_stock ? autocompletes.days_in_stock : {}),
            mileage: Object.values(autocompletes.mileage ? autocompletes.mileage : {}),
            model: null
        });
    },[]);

    const setDefaultsValues = () => {
        let newFilters = {
            ...filters,
            warranty: filters.warranty?.toString(),
        };
        Object.keys(autocompletes).map((autocomplete) => {
            newFilters = {
                ...newFilters,
                [autocomplete]: newFilters[autocomplete] && autocompleteFiller(autocompletes[autocomplete], newFilters[autocomplete], autocomplete),
            }
        });
        return {...newFilters,  matriculation: filters?.matriculation ? new Date(filters?.matriculation.toString().replace(/-/g, "/")) : ''}
    };

    const autocompleteFiller = (options, values, key) => {
        if ((["price", 'days_in_stock', "mileage"].indexOf(key) !== -1)) {
            return Object.values(filters[key])
        } else if (Array.isArray(values)) {
            return values.map((value) => retrieveValueForRs(options, value)[0]);
        } else {
            return retrieveValueForRs(options, values)
        }
    };

    const {register, handleSubmit, watch, control, setValue, reset} = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            ...setDefaultsValues()
        },
    });

    const onSubmit = async (values) => {
        applyFilterCb({
            ...values,
            no_price: values.no_price,
            warranty: values.warranty,
        })
    };

    const onChangeMultichoiseCustomLogics = (value, name) => {
        switch (name) {
            case 'brand':
                //if status brand we need to recalculate the autocompletes, and empty model and version
                setValue("model", null);
                setValue("version", null);
                onRecalculateAutocompletesCb({...watch(), brand: value[0] ? [...value[0]] : null});
                return value[0];
            case 'model':
                //if model change we need to recalculate the autocompletes, and empty structure and version
                setValue("structure", null);
                setValue("version", null);
                onRecalculateAutocompletesCb({...watch(), model: value[0] ? [...value[0]] : null});
                return value[0];
            case 'status':
                //if status change we need to recalculate the autocompletes
                onRecalculateAutocompletesCb({...watch(), status: value[0] ? [...value[0]] : null});
                return value[0];
            case 'category':
                //if category change we need to reset all the field except stauts company
                onRecalculateAutocompletesCb({status: watch('status'), category: value[0] ? [...value[0]] : null});
                reset({category: value[0] ? [...value[0]] : null, status: watch('status')});
                return value[0];
            default:
                return value[0];
        }
    };

    /**
     * filters render funciton
     * @param filter
     * @returns {*}
     */
    const filtersRender = (filter) => {
        switch (filter.type) {
            case 'range':
                if (filter.slug === 'days_in_stock') {
                    return <RangeWrapper control={control} name={filter.slug} unit={''} showTips={true} ref={register}
                                         min={autocompletes['days_in_stock'].min} classNames={classes.daysInStockPad}
                                         max={autocompletes['days_in_stock'].max}
                                         step={5} key={filter.slug} label={filter.label}
                                         disabled={(autocompletes['days_in_stock'].min === autocompletes['days_in_stock'].max)}
                                         defaultValue={[autocompletes['days_in_stock'].min, autocompletes['days_in_stock'].max]}/>
                } else if (filter.slug === 'price') {
                    return <RangeWrapper control={control} name={filter.slug} unit={'€'} showTips={true}
                                         label={filter.label} key={filter.slug} ref={register} classNames={classes.pricePad}
                                         disabled={(autocompletes['price'].min === autocompletes['price'].max)}
                                         step={500} min={autocompletes['price'].min} max={autocompletes['price'].max}
                                         defaultValue={[autocompletes['price'].min, autocompletes['price'].max]}/>
                } else {
                    return <RangeWrapper control={control} name={filter.slug} unit={''} showTips={true}
                                         label={filter.label} key={filter.slug} ref={register}
                                         disabled={(autocompletes['mileage'].min === autocompletes['mileage'].max)}
                                         step={1000} min={autocompletes['mileage'].min}
                                         max={autocompletes['mileage'].max}
                                         defaultValue={[autocompletes['mileage'].min, autocompletes['mileage'].max]}/>
                }
            case 'toggle':
                return <div className={classes.single_line} key={filter.slug}>
                    <ToggleSwitch className={classes.toggle} name={filter.slug} key={filter.slug}
                                  label={filter.label} labelOn={'right'} isSmall={true} ref={register}
                                  control={control}/>
                </div>;
            case 'range_date':
                return <DayPickerInput label={filter.label} name={filter.slug} ref={register} key={filter.slug}
                                       control={control} closeOnSelect={true} disabled={false} inForm={true}/>
            case 'multichoice':
                return <Select name={filter.slug} options={autocompletes[filter.slug]} ref={register} control={control}
                               key={filter.slug}
                               label={filter.label} isClearable={false} isMulti={true}
                               onChange={(value) => onChangeMultichoiseCustomLogics(value, filter.slug)}/>;
            default:
                return <div key={filter.slug}>
                    <label className={classes.label}>{filter.label}</label>
                    <div className={classes.radioContainer}>
                        <Radio ref={register} name={filter.slug} value={true} labelOn={'right'} isSmall={true}
                               label={'si'}/>
                        <Radio ref={register} name={filter.slug} value={false} labelOn={'right'} isSmall={true}
                               label={'no'}/>
                    </div>
                </div>
        }
    };

    return <div className={classes.root}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            {structure?.map((structure) => filtersRender(structure))}
            <div className={classes.btns}>
                <span className={classes.reset} onClick={() => reset(resetObj)}> <RefreshIcon
                    data-color/> Resetta </span>
                <Button width={150} type={'submit'} data-primary data-small> Procedi </Button>
            </div>
        </form>
    </div>
};

export default AdvancedFilters;