import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {createUseStyles} from 'react-jss';
import Select from 'react-select';
import Avatar from "./Avatar.js";

const style = {
    control: (base) => ({
        ...base,
        border: '0 !important',
        boxShadow: '0 !important',
        cursor: 'pointer',
        '&:hover': {
            border: '0 !important'
        }
    })
};

const useStyles = createUseStyles((theme) => ({
    active: {
        display: 'grid',
        gridTemplateColumns: '30px auto',
        gridColumnGap: theme.spacing,
        alignItems: 'center'
    }
}));

const DropdownUsers = ({options, actualUser, callback}) => {
    const [value, setValue] = useState('');
    const classes = useStyles();

    useEffect(() => {
        setValue(options.filter((item) => +item.value === +actualUser));
    }, [options, actualUser]);

    const onChange = (newUser) => {
        setValue(options.filter((item) => +item.value === +newUser.value));
        callback({user_id: +newUser.value});
    };

    const formatOptionLabel = ({value, label, img}) => (
        <div className={classes.active}>
            <Avatar size={'small'} img={img}/>
            <div>{label}</div>
        </div>
    );

    return <Select value={value} formatOptionLabel={formatOptionLabel} options={options} styles={style}
                   onChange={onChange} menuPlacement={'auto'}/>
};

DropdownUsers.propTypes = {
    arrVal: PropTypes.array,
    fistElement: PropTypes.bool
};

export default DropdownUsers;
