import axios from 'axios';

/**
 * get all tasks from a given car id
 * @param car_id
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpGetCarTasks = (car_id) => {
	return axios.get(`/cars/${car_id}/tasks`);
};

/**
 * set all tasks from a given car id
 * @param data car_id, task_id
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpUpdateCarTask = (data) => {
	// alert(JSON.stringify(data));
	return axios.put(`/cars/${data.car_id}/tasks/${data.id}`, { ...data });
};

/**
 * set all tasks from a given car id
 * @param data car_id, task_id
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpAddCarTask = (data) => {
	// alert(JSON.stringify(data));
	return axios.post(`/cars/${data.car_id}/tasks`, { ...data });
};

/**
 * delete a task
 * @param data car_id, task_id
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpDeleteCarTask = (data) => {
	// alert(JSON.stringify(data));
	return axios.delete(`/cars/${data.car_id}/tasks/${data.id}`);
};


/**
 * get all task of a defined type
 * @param task task_type
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpGetTaskType = (task,filters) => {
	return axios.get(`/tasks?type=${task}`,{ params:{ ...filters } });
};


