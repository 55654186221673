import React, {useState} from 'react';
import {createUseStyles} from "react-jss";
import {useForm} from "react-hook-form";
import Text from "../../Commons/Text.js";
import Button from "../../Commons/Button.js";
import * as Yup from "yup";
import {errorHandler} from "../../helpers/utils.js";
import {useDispatch} from "react-redux";
import {httpEditCampaign} from "../../HttpRequests/campaigns.http.js";
import {editCampaignsList} from "../../store/actions/campaignActions.js";
import Spinner from "../../Commons/Spinner.js";
import {alertToggle} from "../../store/actions/alertActions.js";

const useStyles = createUseStyles(theme => ({
    root: {
        display: 'grid',
        width: '100%',
        gridRowGap: theme.spacing * 3,
        justifyItems: 'center',
        '& button':{
            marginTop: theme.spacing * 3
        }
    },
    title:{
        fontWeight: 600,
        margin: [theme.spacing * 2, 0]
    },
    btns: {
        width: "300px",
        display: "grid",
        alignItems: "center",
        gridColumnGap: theme.spacing,
        gridTemplateColumns: "1fr 1fr"
    }
}));

const validationSchema = Yup.object().shape({
    name: Yup.string().required(`Inserisci il nome della campagna.`),
});

const EditCampaignNamePopover = ({campaign,onCloseCb,setCampaignCb}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const {register, handleSubmit, formState,reset, errors} = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            name: campaign?.name,
        },
        validationSchema
    });

    const onSubmit = async (values) => {
        setLoading(true);
        try {
            const {data: newCampaign} = await httpEditCampaign(campaign?.id, {name:values.name});
            dispatch(editCampaignsList(newCampaign));
            setCampaignCb({...campaign, ...newCampaign})
            dispatch(alertToggle('Nome campagna modificato correttamente', 'success'));
            onCloseCb();
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    }

    return <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        {loading && <Spinner/>}
        <Text type={'text'} label={'Nome campagna*'} name={'name'} min={0} ref={register} errors={errors.name}/>
        <div className={classes.btns}>
            <Button type="reset" onClick={reset} data-secondary data-small>Annulla</Button>
            <Button type={'submit'} data-primary data-small disabled={!formState.isValid || formState.isSubmitting}>Salva</Button>
        </div>
    </form>
};

export default EditCampaignNamePopover;