import React from 'react';
import {ResponsiveBar} from "@nivo/bar";
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.

const chartTheme = {
    "background": "transparent",
    "textColor": "#333333",
    "fontSize": 11,
    "axis": {
        "domain": {
            "line": {
                "stroke": "transparent",
                "strokeWidth": 1
            }
        },
        "ticks": {
            "line": {
                "stroke": "transparent",
                "strokeWidth": 1
            }
        }
    },
    "grid": {
        "line": {
            "stroke":'#efefef',
            "strokeWidth": 1
        }
    }
}

const BarChart = ({ data }) => {
    const colors = {'income': '#91DF91', 'expenses': '#ED746F'}
    const getColor = bar => colors[bar.id]

    return <ResponsiveBar
        theme={chartTheme}
        data={data}
        keys={['income', 'expenses']}
        indexBy="acquisition_channel"
        margin={{top: 8, right: 220, bottom: 32, left: 16}}
        padding={0.3}
        groupMode="grouped"
        valueScale={{type: 'linear'}}
        indexScale={{type: 'band', round: true}}
        colors={getColor}
        borderColor={{from: 'color', modifiers: [['darker', 1.6]]}}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: 16
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        tooltip={(input) => {
            return <div style={{color: input?.color, boxShadow: '0 0 12px 0 rgb(23 29 41 / 8%)', fontSize: 12, padding: 0}}>
                <b>{input?.value}</b>
            </div>
        }}
        axisLeft={null}
        enableLabel={false}
        labelTextColor={{from: 'color', modifiers: [['darker', '3']]}}
        legends={[]}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
    />
}

export default BarChart