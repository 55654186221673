import React, {useEffect, useState} from "react";
import {errorHandler, formattedDateIso, repairTypeResolver, toMoney} from "../../helpers/utils.js";
import Checkbox from "../../Commons/Checkbox.js";
import {createUseStyles} from 'react-jss';
import InlineDocumentUpload from "./InlineDocumentUpload.js";
import Button from "../../Commons/Button.js";
import {useParams} from 'react-router-dom';
import {alertToggle} from "../../store/actions/alertActions.js";
import Spinner from "../../Commons/Spinner.js";
import {httpGenerateSupplierDocument} from "../../HttpRequests/reconditioning.http.js";
import {useDispatch, useSelector} from "react-redux";
import {setRepairAttachment} from "../../store/actions/repairsActions.js";

const useStylesRepairRows = createUseStyles(theme => ({
    root: {
        margin: "24px -12px 0",
        background: theme.colors.smokeWhite,
        padding: [0, 12],
        display: "grid",
    },
    row: {
        display: "grid",
        gridTemplateColumns: "16% 10% 15% auto",
        gridColumnGap: 2,
        padding: [10, 0],
        fontSize: 12,
        textTransform: "lowercase",
        '&::last-child': {
            borderBottom: "none",
        }
    },
    postRows: {
        margin: "0px -12px 0",
        background: theme.colors.white,
        padding: [theme.spacing, 12],
        display: "grid",
    },
    postRow: {
        maxHeight: "56px",
        alignItems: "center",
        color: theme.colors.purple,
        gridTemplateColumns: "16% 8% 20%",
        display: "grid",
        padding: "8px 0",
        fontSize: "12px",
        textTransform: "lowercase",
        gridColumnGap: "8px"
    },
    notes: {
        '& p':{
            margin: "0px -12px 0",
            display: "grid",
            padding: "12px",
            background: theme.colors.white,
            borderTop: `1px solid ${theme.colors.smokeWhite}`,
            fontSize: 14,
            '& i':{
                display: 'contents',
            }
        }
    },
    generateBtn:{
        width: '100%',
        display: "grid",
        borderTop: `1px solid ${theme.colors.lightGrey}`,
        padding: "8px 0 16px", margin: "16px 0 0px",
        '& button':{
            width: 120
        }
    }
}));

const RestoreWorkerTableSubrow = ({data, callback}) => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const classes = useStylesRepairRows();
    const [loading, setLoading] = useState(false);
    const [alreadyGenerated, setAlreadyGenerated] = useState(false);
    const isExternal = useSelector(state => (state.users.user.operations.tire_center || state.users.user.operations.body_shop || state.users.user.operations.workshop));

    //to check if a document was already generated
    const retrieveSupplierDoc = () => {
        return data?.attachments?.filter( attach => (attach.type === 'supplier_list'));
    };
    useEffect(() => {
        setAlreadyGenerated(!!retrieveSupplierDoc(data)?.length);
    },[])

    //utili ai fini della creazione del documento
    const [toDocument, setToDocument] = useState([...data.repair_rows?.filter(item => !item.unforeseen)]);
    //deroghe
    const postAddedRows = [...data.repair_rows?.filter(item => (item.type === 'work' && item.unforeseen))];
    //selctions utils
    const selectionIsNotEmpty = toDocument.some(item => item.selected);
    const atLeastOneMaterialRow = toDocument.some(item => item.type === 'material');
    //seleziona o deseleziona una riga della tabaella
    const selectToAssignRow = (id) => {
        const item = toDocument.find(item => +item.id === +id);
        item.selected = !item.selected;
        setToDocument([...toDocument]);
    };

    const generateSupplier = async () => {
        const dataToSend = {
            id: id,
            repair_id: data.id,
            data: toDocument.filter(item => item.selected)?.map(item => item.id)
        };
        setLoading(true)
        try{
            const {data: attach} = await httpGenerateSupplierDocument(dataToSend);
            dispatch(setRepairAttachment(attach));
            dispatch(alertToggle( 'Documento di affidamento lavori generato con successo', 'success'));
            setAlreadyGenerated(true)
        }catch (e) {
            errorHandler(e,dispatch);
        }finally {
            setLoading(false)
        }
    };

    return <div className={classes.root}>
        {loading && <Spinner/>}
        {toDocument?.map((repair, index) => <div className={classes.row} key={index}>
            <span>{repair.operation}</span>
            <strong> {toMoney(repair.price)} </strong>
            <strong> {formattedDateIso(repair.created_at)} </strong>
            {data.status !== 'closed' && repair.type === 'material' && !alreadyGenerated  && !isExternal ?
                <Checkbox data-checkbox checked={repair.selected} isSmall={true}
                          onClick={() => selectToAssignRow(repair.id)}
                          isDark={true} defaultChecked={repair.selected}/> : <span/>}
        </div>)}
        {(!alreadyGenerated && atLeastOneMaterialRow && !isExternal) && <div className={classes.generateBtn}>
            <Button data-primary data-xs-small onClick={() => generateSupplier()} disabled={!selectionIsNotEmpty}>
                Genera documento
            </Button>
        </div>}

        {!!postAddedRows.length && <div className={classes.postRows}>
            {postAddedRows?.map((row, index) => <div className={classes.postRow} key={index}>
                <span>{row.operation}</span>
                <strong> {toMoney(row.price)} </strong>
                <InlineDocumentUpload data={data} type={`unexpected_repairs`} list={repairTypeResolver(data.type)}
                                      repairRowId={row?.id} isExternal={isExternal}
                                      attachment={data?.attachments.filter((attach) => (attach.type === 'unexpected_repairs' && attach.repair_row_id === row.id))[0]}/>
            </div>)}
        </div>}
        {data.note && <div className={classes.notes}>
            <p>Nota: <i> {data.note} </i></p>
        </div>}
    </div>
};

export default RestoreWorkerTableSubrow;