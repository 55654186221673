import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import PrivateLayout from '../Containers/PrivateLayout.js';
import {useSelector} from "react-redux";

const PrivateRoute = ({sidebar, requiredPermissions, justOneRequired, ...rest}) => {
    const access_token = useSelector(state => state.users.access_token);
    const userOperations = useSelector(state => state.users.operations);

    const redirectChecker = () => {
        if (userOperations?.supplier) {
            return <Redirect to={'/external-warehouses'}/>
        } else if (userOperations?.body_shop || userOperations?.workshop || userOperations?.tire_center) {
            return <Redirect to={'/external-workers'}/>
        } else {
            return <Redirect to={'/my-tasks'}/>
        }
    }

    const privatePermissionsCheck = (requiredPermissions, userOperations, rest) => {
        //check not needed for admin
        if (userOperations?.admin || !(!!requiredPermissions.length)) return <Route {...rest} />;

		if (justOneRequired) {
			//just one of permission needed to see the page
			for (let i = 0; i < requiredPermissions.length; i++) {
				if (userOperations[requiredPermissions[i]]) {
					return <Route {...rest} />;
				}
			}
			return redirectChecker();
		}else{
			//all permission needed to see the page
			for (let i = 0; i < requiredPermissions.length; i++) {
				if (!userOperations[requiredPermissions[i]]) {
					return redirectChecker();
				}
			}
			return <Route {...rest} />;
		}
    }

    return access_token ? (
        <PrivateLayout sidebar={sidebar}>
            {privatePermissionsCheck(requiredPermissions, userOperations, rest)}
        </PrivateLayout>
    ) : (
        <Redirect to={'/auth/login'}/>
    );
}


export default PrivateRoute;
