import React from "react";
import {MESSAGE_TYPE} from "../../../messageModel";
import TextMessageDisplay from "./TextMessageDisplay/TextMessageDisplay";
import MediaMessageDisplay from "./MediaMessageDisplay/MediaMessageDisplay";


const MessageDisplay = ({message, channel,  parentKey, ...rest}) => {

    switch (message.type){
        case MESSAGE_TYPE.text:
            return <TextMessageDisplay channel={channel} message={message} time={parentKey} {...rest}/>
        case MESSAGE_TYPE.image:
        case MESSAGE_TYPE.video:
        case MESSAGE_TYPE.document:
            return <MediaMessageDisplay channel={channel} message={message} time={parentKey} {...rest}/>
        // Don't show anything for an unrecognized message type
        default:
            return <span>Invalid message type</span>;
            /*invariant(
                false,
                `No component available for displaying message of type "${message?.type}"`
            );*/

    }
}

export default MessageDisplay