import React, {useEffect, useState} from 'react';
import {createUseStyles} from "react-jss";
import Spinner from "../../Commons/Spinner.js";
import Button from "../../Commons/Button.js";
import {useDispatch} from "react-redux";
import {httpInitDatMultistep, httpSetDatCarVersion} from "../../HttpRequests/barters.http.js";
import DatLiveResults from "./DatLiveResults.js";
import {useForm} from "react-hook-form";
import Select from "../../Commons/Select.js";
import {errorHandler, remapOptions} from "../../helpers/utils.js";

const useStyles = createUseStyles(theme => ({
    root: {
        display: 'grid',
        width: '100%',
        gridRowGap: theme.spacing * 3,
        justifyItems: 'center'
    },
    title: {
        fontWeight: 600,
        margin: [theme.spacing * 2, 0],
        display: 'grid',
        alignItems: 'center',
        gridColumnGap: theme.spacing,
        gridTemplateColumns: '1fr auto',
        '& img': {
            width: 72
        }
    },
    results: {
        fontSize: 12,
        marginTop: -theme.spacing * 4
    },
    body: {
        minWidth: 800,
        display: 'grid',
        gridTemplateColumns: '1fr 1px 1fr',
        gridColumnGap: theme.spacing * 4,
    },
    vDivisor: {
        width: 1,
        height: '100%',
        background: theme.colors.lightGrey,
    },
    formRoot: {
        width: '100%',
        display: 'grid',
        gridRowGap: theme.spacing,
    },
    datResults: {
        width: '100%',
        display: 'grid',
        gridRowGap: theme.spacing,
    },
    btns:{
        display:'grid',
        gridColumnStart: 1,
        gridColumnEnd: 4,
        justifyItems: 'center',
        marginTop: 32,
        gridRowGap: 8,
        '& small':{

        },
        '& p':{
            textDecoration: 'underline',
            cursor: 'pointer'
        }
    }
}));

/**
 * After this multistep process we will see
 * the normal flow with eurotax and indicata.
 */
const NotFoundMultiStep = ({setStep, setCarsId, plate, mileage}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [datUuid, setDatUuid] = useState(null);
    const [datVersions, setDatVersions] = useState(null);
    const [datSelections, setDatSelections] = useState([]);
    const [currentVersion, setCurrentVersion] = useState([]);
    const [treeStructure, setTreeStructure] = useState([
        {category: 'manufacturer', label:'Costruttore', value: null, items: null},
        {category: 'mainTypeGroupFb', label:'Modello', value: null, items: null,watchIndex: 0},
        {category: 'fuelMethod', label:'Alimentazione', value: null, items: null,watchIndex: 1},
        {category: 'countOfDoors', label:'Numero porte', value: null, items: null,watchIndex: 2},
        {category: 'gearbox', label:'Cambio', value: null, items: null,watchIndex: 2},
        {category: 'subType', label:'Versione', value: null, items: null, watchIndex: 4},
        {category: 'engine', label:'Motorizzazione', value: null, items: null,watchIndex: 5},
        {category: 'constructionYear', label:'Anno di produzione', value: null, items: null,watchIndex: 6}
    ]);
    const {register, handleSubmit, setValue, control, watch} = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
    });

    useEffect(() => {
        getDatInfos();
    }, []);

    const selectUpdateAndCallback = (index, name) => {
        if (!watch(name)) return;
        clearAllAfter(index);
        const selection = {category: name, value: watch(name).value};
        treeStructure[index].value = watch(name).value;
        setTreeStructure(treeStructure);
        setDatSelections([...datSelections.slice(0, index),selection]);
        getDatInfos([...datSelections.slice(0, index),selection]);
    };

    useEffect(() => {
        selectUpdateAndCallback(0, 'manufacturer')
    }, [watch('manufacturer')]);

    useEffect(() => {
        selectUpdateAndCallback(1, 'mainTypeGroupFb')
    }, [watch('mainTypeGroupFb')]);

    useEffect(() => {
        selectUpdateAndCallback(2, 'fuelMethod')
    }, [watch('fuelMethod')]);

    useEffect(() => {
        selectUpdateAndCallback(3, 'countOfDoors')
    }, [watch('countOfDoors')]);

    useEffect(() => {
        selectUpdateAndCallback(4, 'gearbox')
    }, [watch('gearbox')]);

    useEffect(() => {
        selectUpdateAndCallback(5, 'subType')
    }, [watch('subType')]);

    useEffect(() => {
        selectUpdateAndCallback(6, 'engine')
    }, [watch('engine')]);

    useEffect(() => {
        selectUpdateAndCallback(7, 'constructionYear')
    }, [watch('constructionYear')]);

    const fillTreeStructureItems = (items) => {
        treeStructure.map((select, index) => {
            if (!select.items || select.items.length === 0) {
                select.items = items[select.category]
            }
            return select
        });
        setTreeStructure(treeStructure)
    };

    const clearAllAfter = (actualIndex) => {
        treeStructure.map((select, index) => {
            if (actualIndex < index) {
                select.items = null;
                select.value = null;
                setValue(select.category, null);
            }
            return select
        });
        setTreeStructure(treeStructure)
    };

    const getDatInfos = async (selections) => {
        //data to send
        const dataToSend = selections ? {
            uuid: datUuid,
            selections: selections
        } : null;
        //console.log(dataToSend);

        try {
            setIsLoading(true);
            const {data: {items, uuid, versions}} = await httpInitDatMultistep(dataToSend);
            //setting up the uuid only the first time
            setDatUuid(uuid);
            //fill the selects based on items and selects object returned by dat
            fillTreeStructureItems(items);
            //fill the versions and selections returned from the dat three search
            setDatVersions(versions);
            setCurrentVersion(versions.filter(item => item?.ecode === currentVersion?.ecode));
        } catch (e) {
            errorHandler(e,dispatch)
        } finally {
            setIsLoading(false);
        }
    };

    const onSubmit = async (values) => {
        try {
            setIsLoading(true);
            const {data} = await httpSetDatCarVersion({uuid:datUuid, ecode: currentVersion?.ecode, plate: plate, mileage: +mileage});
            setCarsId(data.id);
            setStep('successEurotax');
        } catch (e) {
            errorHandler(e,dispatch)
        } finally {
            setIsLoading(false);
        }
    };

    return <div className={classes.root}>
        {isLoading && <Spinner/>}
        <h4 className={classes.title}> Veicolo non identificabile </h4>
        <span className={classes.results}> Inserisci i campi richeisti </span>

        <form className={classes.body} onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.formRoot}>
                {treeStructure && treeStructure.map((select, index) => {
                    return <Select options={(select.items ? remapOptions(select.items) : {})} name={select.category} key={index}
                                   isClearable={false} disabled={index > 0 && !watch(treeStructure[select.watchIndex].category)}
                                   control={control} label={select.label} ref={register}/>
                })}
            </div>
            <div className={classes.vDivisor}/>
            <div className={classes.datResults}>
                <DatLiveResults versions={datVersions} currentVersion={currentVersion} setCurrentVersion={setCurrentVersion}/>
            </div>
            <div className={classes.btns}>
                <Button type={'submit'}  width={250} data-primary data-small disabled={!currentVersion?.ecode}> Avanti </Button>
                <small>oppure</small>
                <p onClick={() => setStep('manualInsert')}>Inserisci il veicolo manualmente</p>
            </div>
        </form>
    </div>
};

export default NotFoundMultiStep;