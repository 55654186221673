import axios from 'axios';

export const httpGetBrandsAutocomplete = () => {
	return axios.get('/brands/autocomplete');
};

export const httpGetUsersAutocomplete = () => {
	return axios.get('/users/autocomplete');
};

export const httpGetBarterPhotosAutocomplete = () => {
	return axios.get('/photos/autocomplete');
};

export const httpGetFuelMethodAutocomplete = () => {
	return axios.get('/fuel_methods/autocomplete');
};

export const httpGetStructuresAutocomplete = () => {
	return axios.get('/structures/autocomplete');
};

export const httpGetCarFamiliesAutocomplete = () => {
	return axios.get(`/families/autocomplete`);
}

/**
 * get car accessories autocomplete
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpGetCarAccessories = (id) => {
	return axios.get(`/cars/${id}/accessories/autocomplete`);
};

/**
 * get car / barter from a given
 * @param car_id
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpGetCar = (car_id) => {
	return axios.get(`/cars/${car_id}`);
};

/**
 * get car damages from a given
 * @param car_id
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpGetCarDamages = (car_id) => {
	return axios.get(`/damages`,{ params: { car_id: car_id, web:true } });
};

export const httpGetCarStatuses = () => {
	return axios.get(`/cars/statuses`);
};

export const httpChangeCarStatus = (carId, data) => {
	return axios.post(`/cars/${carId}/status_history`, { status: data });
};

/**
 * get car damages settings
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpGetCarSettingsDamages = () => {
	return axios.get(`/settings/damages`,{ params: {  web:true } });
};

/**
 * get car barters settings params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpGetBartersSettingsParams = () => {
	return axios.get(`/weight_settings`);
};

/**
 * get car barters settings params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpEditBartersSettingsParams = (data) => {
	return axios.put(`/weight_settings`, data);
};

/**
 * get signed S3 url
 */
export const httpGetSignedS3Url = (filename, type) => {
	const url = `/upload/url`;
	return axios.get(url, {
		params: {
			filename: filename,
			type: type
		}
	})
};

/**
 * post to signed S3 url
 */
export const httpPostS3 = (url, formData, options) => {
	return axios.post(url, formData, options); //s3
};

/**
 * To get admin communications
 */

export const httpGetCommunications = (filters = null) => {
	const url = '/communications';
	return axios.get(url, { params: { ...filters } })
}

/**
 * To add admin communications
 */

export const httpStoreCommunication = (data) => {
	const url = '/communications';
	return axios.post(url, data)
}