import React, {useState} from 'react';
import {createUseStyles} from 'react-jss';
import {format, subDays} from "date-fns";
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {removeCarTask, setCarTasks, setCurrentCar, updateCarTasks} from "../store/actions/carsActions";
import {httpDeleteCarTask, httpGetCarTasks, httpUpdateCarTask} from '../HttpRequests/tasks.http';
import {httpGetCar} from '../HttpRequests/commons.http.js';
import {errorHandler, formattedDateIso, taskIconResolver, taskStatuses} from '../helpers/utils';
import {alertToggle} from "../store/actions/alertActions.js";
import Avatar from "../Commons/Avatar.js";
import EditableInput from './EditableInput';
import DayPickerInput from './DayPickerInput';
import DropdownUsers from './DropDownUsers';
import DropdownStatuses from './DropdownStatuses';
import moment from 'moment';
import PropTypes from 'prop-types';
import Spinner from "./Spinner.js";
import ContextMenu from "./ContextMenu.js";

const useStyles = createUseStyles((theme) => ({
    taskBaseStyles: {
        display: 'grid',
        gridTemplateColumns: 'minmax(200px, 3fr) minmax(120px, 1fr) minmax(220px, 4fr) minmax(120px,  1fr) minmax(200px, 3fr) 16px',
        gridTemplateRows: '1fr',
        gridRowGap: 0,
        backgroundColor: theme.colors.white,
        borderRadius: 10,
        paddingLeft: theme.spacing,
        paddingRight: theme.spacing,
        marginTop: theme.spacing,
        marginBottom: ({isSmall}) => isSmall ? theme.spacing : theme.spacing * 2,
        boxSizing: 'border-box',
        alignItems: 'center',
        justifyItems: 'center',
    },
    taskName: {
        display: 'grid',
        gridTemplateColumns: '36px 1fr',
        fontWeight: 'bold',
        justifySelf: 'start',
        paddingLeft: ({isSmall}) => (isSmall) ? 0 : theme.spacing * 2,
        paddingRight: ({isSmall}) => (isSmall) ? 0 : theme.spacing * 2,
        paddingTop: ({isSmall}) => (isSmall) ? theme.spacing : theme.spacing * 2,
        paddingBottom: ({isSmall}) => (isSmall) ? theme.spacing : theme.spacing * 2,
        textDecoration: 'none',
        '& span': {
            display: 'grid',
            alignItems: 'center',
            justifyItems: 'start',
            fontSize: '.7em',
            textTransform: 'uppercase'
        },
        '& svg': {
            fill: `${theme.colors.black} !important`
        },
        opacity: ({isActive}) => isActive ? '1' : '.6'
    },
    taskDateContainer: {
        height: '100%',
        width: '100%',
        borderLeft: `2px solid ${theme.colors.smokeWhite}`,
        alignSelf: 'center',
        display: 'grid',
        justifyItems: 'center',
        alignItems: 'center',
        '& input': {
            textAlign: 'center',
            fontSize: '.9em'
        },
        '& span': {
            textAlign: 'center',
            fontSize: '.9em'
        },
        '& button': {
            display: 'none'
        },
        opacity: 1
    },
    taskNoteContainer: {
        // background: ({isSmall}) => isSmall ? 'red' : 'transparent',
        height: '100%',
        width: '100%',
        borderLeft: `2px solid ${theme.colors.smokeWhite}`,
        paddingTop: ({isSmall}) => (isSmall) ? theme.spacing : theme.spacing * 2,
        paddingBottom: ({isSmall}) => (isSmall) ? theme.spacing : theme.spacing * 2,
        '& span': {
            padding: [0, theme.spacing],
            justifyItems: 'start',
            width: '100%',
            textAlign: 'left',
            fontSize: '.9em',
            position: 'relative',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        '& input': {
            width: '100%',
        },
        '& div': {
            height: '100%',
            display: 'grid',
            textAlign: 'center',
            alignItems: 'center',
            justifyItems: 'stretch'
        }
    },
    taskStatusContainer: {
        height: '100%',
        width: '100%',
        borderLeft: `2px solid ${theme.colors.smokeWhite}`,
        alignItems: 'center',
        display: 'grid',
        alignContent: 'center',
        placeContent: 'unset',
        '& span': {
            display: 'grid',
            alignItems: 'center',
            justifyItems: 'center'
        },
        opacity: ({isActive}) => isActive ? '1' : '.6'
    },
    taskUserContainer: {
        display: 'grid',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        borderLeft: `2px solid ${theme.colors.smokeWhite}`,
        fontSize: '.9em',
    },
    taskUserSelected: {
        display: 'grid',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        borderLeft: `2px solid ${theme.colors.smokeWhite}`,
        fontSize: '.9em',
        '& span': {
            padding: theme.spacing,
        }
    },
    /* DROPDOWN */
    shadow: {
        ...theme.shadow
    },
    dropDownMenu: {
        justifyContent: 'end',
        padding: [theme.spacing, 0],
        '& div': {
            display: 'grid',
            padding: theme.spacing / 2,
            '&:hover': {
                background: theme.colors.xLightGrey
            }
        }
    },
    menuItem: {
        textTransform: 'uppercase',
        display: 'inline-block',
        padding: [theme.spacing / 2, theme.spacing],
        color: theme.colors.black,
        zIndex: 4,
        cursor: 'pointer',
        fontSize: 12,
        '&:hover': {
            backgroundColor: theme.colors.lightGrey
        }
    },
    closedTaskUserContainer: {
        padding: [0, theme.spacing + 2],
        display: 'grid',
        gridTemplateColumns: '30px auto',
        gridColumnGap: theme.spacing,
        alignItems: 'center'
    }
}));

const Task = ({task, taskUsers, isSmall = false, canDelete = false, isMyTask = false}) => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const isActive = task?.is_active;
    const classes = useStyles({isActive, isSmall});
    const [loading, setLoading] = useState(false);
    const currentCar = useSelector(state => state.cars.currentCar);
    const currentCarTasks = useSelector(state => state.cars.carTasks);

    const getCarTasks = async (id) => {
        setLoading(true);
        try {
            const {data: tasks} = await httpGetCarTasks(id);
            //setTasks(tasks);
            //save tasks in REDUX STORE for others pages
            dispatch(setCarTasks(tasks));
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    };

    const fieldUpdatedLabelResolver = (field) => {
        switch (field) {
            case 'deadline':
                return dispatch(alertToggle(`Il campo Data di scadenza è stato modificato correttamente.`, 'success'));
            case 'user_id':
                return dispatch(alertToggle(`Il campo utente è stato modificato correttamente.`, 'success'));
            case 'note':
                return dispatch(alertToggle(`Il campo note è stato modificato correttamente.`, 'success'));
            default:
                return dispatch(alertToggle(`Il campo stato è stato modificato correttamente.`, 'success'));
        }
    };

    const resolveTaskLink = () => {
        switch (task?.type) {
            case 'wash':
            case 'complete_wash':
                return `/stock/${task.car_id}`
            case 'photo':
                return `/stock/${task.car_id}/photo`
            case 'reconditioning':
            case 'estimate':
                return `/stock/${task.car_id}/restore`
            case 'internal_transport':
            case 'external_transport':
            case 'assign_transport':
            case 'single_transport':
                return `/stock/${task.car_id}/shifts`
            default:
                return `/stock/${task.car_id}/documents`
        }
    }

    const saveTaskData = async (newData) => {
        const oldTask = task;
        //useless data update preventions
        if (newData?.user_id === task.user_id) return false;
        if (newData?.status === task.status) return false;
        if (newData?.note === task.note || newData?.note === '') return false;
        if (newData?.deadline === formattedDateIso(task.deadline)) return false;

        //task obj build
        const taskObjUpdated = {
            ...task,
            deadline: newData?.deadline ? moment(newData.deadline).format('YYYY-MM-DD hh:mm') : task.deadline,
            status: newData?.status ? newData.status : task.status,
            note: newData?.note ? newData.note : task.note,
            user_id: newData?.user_id ? newData.user_id : task.user_id,
        };

        try {
            dispatch(updateCarTasks(taskObjUpdated));
            const {data: newTask, status} = await httpUpdateCarTask({...taskObjUpdated});
            //special 201 check
            if (status === 201) {
                //dispatch(updateCarTasks(oldTask));
                dispatch(setCarTasks([newTask, ...currentCarTasks]))
                dispatch(alertToggle('Prima di poter eseguire operazioni sul task, è necessario portare il veicolo nella sede corrretta', 'warning'));
            } else {
                //success message dispatch logic
                fieldUpdatedLabelResolver(Object.keys(newData)[0])
            }
            //if the new status is closed we need to refetch tasks in order to obtain the new isActive logic
            if (newTask?.status === 'closed' || (newData?.status && task?.status === 'closed')) {
                if (!isMyTask) {
                    getCarTasks(id);
                    getCarData(id);
                }
            }
            //update is moving param we use indexOf for internet explorer support
            if ((newTask?.type.indexOf('assign_transport') !== -1) && newTask?.status === 'active') {
                dispatch(setCurrentCar({...currentCar, is_moving: true}))
            }
        } catch (e) {
            dispatch(updateCarTasks(oldTask));
            let message = e.response?.data?.message ? e.response.data.message : "Sembra ci sia un errore di sistema. Riprova o contatta il supporto.";
            if (e.response.status === 500) return dispatch(alertToggle(message, 'error'));
            const errorObj = e.response.data;
            //horrible response from b-end here
            if (errorObj[0]?.user_id) {
                message = `È necessario selezionare un utente prima di qualsiasi altra operazione`
            }
            dispatch(alertToggle(message, 'error'));
        }
    };

    const getCarData = async () => {
        try {
            const {data: car} = await httpGetCar(id);
            dispatch(setCurrentCar(car));
        } catch (e) {
            errorHandler(e, dispatch)
        }
    };

    const deleteTask = async (data) => {
        setLoading(true)
        try {
            await httpDeleteCarTask(data);
            dispatch(removeCarTask(data.id))
            dispatch(alertToggle('Task rimosso correttamente.', 'success'));
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false)
        }
    };

    const assignedUserDisabled = (!!taskUsers.length && task?.user_id) ? taskUsers.filter((item) => +item.value === +task.user_id)[0] : null;
    const actions = [{
        to: null,
        text: 'Elimina',
        callback: () => deleteTask({car_id: id, id: task.id}),
        type: 'danger'
    }];

    return <>
        {loading && <Spinner overlayFullscreen={true}/>}
        <div className={classes.taskBaseStyles}>
            <a href={resolveTaskLink()} className={classes.taskName}>
                <span>{taskIconResolver(task.type)}</span>
                <span>{task.label}</span>
            </a>
            <div className={classes.taskDateContainer}>
                {(task?.status === 'closed' || isMyTask || !task?.is_active) ?
                    (<span>{task?.deadline ? format(new Date(task.deadline), 'dd-MM-Y') : 'Data'}</span>)
                    :
                    <DayPickerInput
                        closeOnSelect={true}
                        name={'date'}
                        inForm={false}
                        filters={{date: task.deadline ? task.deadline : ''}} //date naming necessary for the component
                        setFilters={(item) => saveTaskData({deadline: item.date})} //renaming here for integrity
                        border={false}
                        minDate={subDays(new Date(), 0)}
                    />
                }
            </div>
            <div className={classes.taskNoteContainer}>
                <EditableInput inputText={task?.note ? task.note : ''} placeholder={'inserisci qui la tua nota...'}
                               callback={saveTaskData} disabled={task.status === 'closed'}/>
            </div>
            <div className={classes.taskStatusContainer}>
                <DropdownStatuses actualStatus={task.status} options={taskStatuses} isActive={task?.is_active}
                                  callback={saveTaskData} isMyTask={isMyTask}/>
            </div>

            <div className={classes.taskUserContainer}>
                {(isMyTask || (task.status === 'active' || task.status === 'closed')) ?
                    <div className={classes.closedTaskUserContainer}>
                        <Avatar size={'small'} img={assignedUserDisabled?.img}/>
                        {assignedUserDisabled?.label}
                    </div>
                    :
                    <DropdownUsers actualUser={task?.user_id} options={taskUsers} callback={saveTaskData}/>
                }
            </div>
            {canDelete && <div className={classes.actions}>
                <ContextMenu actions={actions} id={`${task.id}-task`}/>
            </div>}
        </div>
    </>
};

Task.propTypes = {
    task: PropTypes.object,
    taskUsers: PropTypes.array
};

export default Task;
