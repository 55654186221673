import {createSelector} from "reselect";
import {SET_EXCLUDED_USERS} from "../../actions/chat/excludedUsersActions";

const initialState = {
    excludedUsers: []
};

const excludedChatUserReducer = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case SET_EXCLUDED_USERS: {
            console.log('excluded users', action.payload)
            return action.payload.map(user => user.pubnub_uuid)
        }
        default:
            return state;
    }
};

export default excludedChatUserReducer;

export const getExcludedUsersSlice = state => state.chats.excludedUsers;