import React, {forwardRef} from 'react';
import {createUseStyles} from 'react-jss';
import cx from 'classnames';
import PropTypes from 'prop-types';

const useStyles = createUseStyles(theme => ({
    root: {
        'width': 'max-content',
        'display': 'grid',
        'grid-auto-flow': ({labelOn}) => !labelOn ? 'row' : 'column',
        'grid-column-gap': ({labelOn}) => !labelOn ? undefined : theme.spacing / 2,
        'align-content': 'center',
        'align-items': 'center',
        'position': 'relative'
    },

    label: {
        ...theme.control.label,
        fontSize: 14,
        marginLeft: 4,
        cursor: ({disabled}) => disabled ? 'not-allowed' : 'pointer',
        'margin-bottom': ({labelOn}) => labelOn ? 0 : theme.control.label['margin-bottom'],
        'grid-column': ({labelOn}) => !labelOn ? undefined : labelOn === 'left' ? 1 : 2,
    },

    input: {
        width: ({isSmall}) => isSmall ? 16 : 20,
        height: ({isSmall}) => isSmall ? 16 : 20,
        cursor: ({disabled}) => disabled ? 'not-allowed' : 'pointer',
        border: `1px solid ${theme.colors.grey}`,
        borderRadius: 4,
        '&[type=checkbox]:not(:checked)': {},
        '&[type=checkbox]:checked': {
            content: ({isSelectAll}) => isSelectAll ? `url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 24 24'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='minimize_black_24dp'%3E%3Cpolygon id='Path' points='0 0 24 0 24 24 0 24'%3E%3C/polygon%3E%3Cpolygon id='Path' fill='%23FFFFFF' fill-rule='nonzero' points='6 11 18 11 18 13 6 13'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`
                : `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' fill='none' width='24'%3E%3Cpath d='M0 0h24v24H0z' /%3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z' fill='%23fff'/%3E%3C/svg%3E")`,
            fill: theme.colors.white,
            background: ({isDark}) => isDark ? theme.colors.black : theme.colors.red,
            border: `none`,
        },

        '&[type=checkbox]:disabled': {
            background: theme.colors.lightGrey,
            border: `none`,
        },
    },
    error: {
        display: "grid",
        position: "relative",
        gridRow: "2",
        gridColumnStart: "1",
        gridColumnEnd: "3",
        color: theme.colors.red
    },
}));

const Checkbox = forwardRef(({
                                 children,
                                 className,
                                 label,
                                 labelOn = 'right',
                                 disabled,
                                 errors,
                                 isSmall,
                                 isDark = false,
                                 isSelectAll = false,
                                 ...props
                             }, ref) => {
    const classes = useStyles({labelOn, errors: !!errors, disabled, isSmall, isDark, isSelectAll});

    return (
        <label id={props.name} className={cx(classes.root, className)}>
            {!!label && <span className={classes.label}>{label}</span>}
            <input type={"checkbox"} className={classes.input} disabled={disabled} ref={ref} {...props} />
            {(!!errors && !props?.disabled) && <small className={classes.error}>{errors.message}</small>}
            {children}
        </label>
    );
});

export default Checkbox;

Checkbox.propTypes = {
    children: PropTypes.any,
    label: PropTypes.string,
    labelOn: PropTypes.oneOf(['right', 'left']),
    disabled: PropTypes.bool,
    errors: PropTypes.object,
    isSmall: PropTypes.bool,
};