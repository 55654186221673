import React, {useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import Table from "../../Commons/Table.js";
import Avatar from "../../Commons/Avatar.js";
import {formattedDate, toMoney} from "../../helpers/utils.js";
import {ArrowDown, ArrowUp} from "../../assets/icons.js";
import InlineDocumentUpload from "./InlineDocumentUpload.js";

const useStyles = createUseStyles((theme) => ({
    repairTitle: {
        '& small': {
            color: theme.colors.grey,
            marginBottom: theme.spacing
        },
        '& p': {
            color: theme.colors.black,
            fontSize: 16,
            textTransform: 'uppercase'
        }
    }
}));

const EstimatesTable = ({setDataCallback, data}) => {
    const dispatch = useDispatch();
    const classes = useStyles({});
    const [rowExpandedId, setRowExpandedId] = useState(null);

    return <Table
        noHeightLimit={true}
        id={'table'}
        data={data}
        rowExpandedId={rowExpandedId}
        setRowExpandedId={setRowExpandedId}
        onSetPageCb={Function.prototype}
        onSortingDataCb={Function.prototype}
        setData={() => setDataCallback()}
        theadHeight={56}
        rowMinHeight={56}
        /* inLoading={loading}*/
        classNames={{
            table: classes.table,
            thead: classes.thead,
            row: classes.row,
        }}
        columns={['title', 'doc', 'total', 'operator', 'actions']}
        columnsNames={{
            title: "",
            doc: "",
            total: "Tot.Perizia",
            operator: "Operatore",
            actions: ""
        }}
        columnsWidths={{
            title: "25%",
            doc: "35%",
            total: "15%",
            operator: "20%",
            actions: "5%"
        }}
        columnsAlignments={{
            total: 'right'
        }}
        expandedComponent={RepairRows}
        columnsRenderers={{
            title: (value, items, metadata) => <div className={classes.repairTitle}>
                <small> {formattedDate(items.updated_at)} </small>
                <p> {items?.title ? items.title : `Perizia del ${formattedDate(items.updated_at)}`}</p>
            </div>,
            doc: (value, items, metadata) => <span>
                {!items?.title && <InlineDocumentUpload data={items} type={`supplier_list`} list={'estimates'}
                                                        attachment={items?.attachments?.filter((attach) => attach.type === 'supplier_list')[0]} />}
            </span>,
            total: (value, items, metadata) => <strong> {toMoney(items.total_amount)} </strong>,
            operator: (value, items, metadata) => <span> {items?.estimating_user ? <Avatar size={'small'} fullName={items?.estimating_user?.full_name} img={items?.estimating_user?.photo_link}/> : '-'} </span>,
            actions: (item, value, metadata) => <Actions rowIndex={metadata.rowIndex} rowExpandedId={rowExpandedId}
                                                         setRowExpandedId={setRowExpandedId}/>
        }}
    />
};

EstimatesTable.propTypes = {
    setDataCallback: PropTypes.any,
    data: PropTypes.array,
};

const useStylesActions = createUseStyles(theme => ({
    root: theme.grid.actions,
}));

export const Actions = ({setRowExpandedId, rowIndex, rowExpandedId}) => {
    const classes = useStylesActions();
    const isExpanded = (rowIndex === rowExpandedId);
    return (
        <div className={classes.root} >
            {rowIndex !== 0 && <span onClick={() => {
                setRowExpandedId(isExpanded ? null : rowIndex)
            }}>
                {isExpanded ? <ArrowUp/> : <ArrowDown/>}
            </span>}
        </div>
    );
};

const useStylesRepairRows = createUseStyles(theme => ({
    root: {
        margin: "12px -12px 0",
        background: theme.colors.smokeWhite,
        padding: [0, 12],
        display: "grid",
    },
    row: {
        display: "grid",
        gridTemplateColumns: "60% 40%",
        gridColumnGap: theme.spacing,
        borderBottom: `1px solid ${theme.colors.lightGrey}`,
        padding: [theme.spacing , 0],
        fontSize: 12,
        textTransform: "lowercase",
        '&::last-child':{
            borderBottom: "none",
        }
    }
}));

export const RepairRows = ({data}) => {
    const classes = useStylesRepairRows();

    return <div className={classes.root}>
        {data?.repair_rows?.map((repair, index) => <div className={classes.row} key={index}>
            <span>{repair.operation}</span>
            <strong> {toMoney(repair.price)} </strong>
        </div>)}
    </div>
};

export default EstimatesTable;
