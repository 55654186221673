import React from 'react';
import {Link} from "react-router-dom";

const AutoLink = ({ text }) => {
    const delimiter = /((?:https?:\/\/)?(?:(?:[a-z0-9]?(?:[a-z0-9\-]{1,61}[a-z0-9])?\.[^\.|\s])+[a-z\.]*[a-z]+|(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3})(?::\d{1,5})*[a-z0-9.,_\/~#&=;%+?\-\\(\\)]*)/gi;

    return (
        <>
            {text.split(delimiter).map(word => {
                let match = word.match(delimiter);
                if (match) {
                    let url = match[0];
                    return (
                        url.startsWith("/") ?
                            <Link to={url}>{url}</Link>
                            :
                            <a href={url.startsWith("http") ? url : `https://${url}`} target="_blank">{url.replace(/http:\/\/|https:\/\//g,'')}</a>
                    );
                }
                return word;
            })}
        </>
    );
}

export default AutoLink;