import React, {useEffect, useState} from "react";
import {httpDownloadAttachment, httpFetchChatFile} from "../../../../../../HttpRequests/chat.http";
import Spinner from "../../../../../../Commons/Spinner";
import Media from "../../../../../../Commons/Media";
import Popover from "../../../../../../Commons/Popover";
import {convertTimestampToTime, getExtension} from "../../../../chatUtils";
import {createUseStyles} from "react-jss";
import {MESSAGE_TYPE} from "../../../../messageModel";
import videoPlayButton from '../../../../../../assets/images/video-play-button.svg'
import {ChatDownloadIcon} from "../../../../../../assets/icons";


const useStyles = createUseStyles(theme => ({
    textMessage: {
        padding: '16px 24px',
        borderRadius: ({isMine}) => isMine ? '28px 0 28px 28px' : '0 28px 28px 28px',
        textAlign: 'left',
        backgroundColor: ({isMine}) => isMine ? '#2F2F2F' : '#FFF',
        color: ({isMine}) => isMine ? '#FFF' : '#2F2F2F',
        whiteSpace: 'pre-wrap',
        width: 'fit-content',
        fontSize: 14
    },
    mediaMessage: {
        extend: 'textMessage',
        padding: '0',
        display: 'flex',
        flexDirection: 'column',
        position: "relative",
        backgroundColor: ({isMine}) => isMine ? '#2F2F2F' : '#FFF',
        borderRadius: 16,
        width: 180,
        height: 180
    },
    imageAttachment: {
        width: 'auto',
        height: '100%',
        cursor: 'pointer',
        borderRadius: '16px 16px 0 0',
    },
    videoAttachment: {
        maxHeight: 800,
        width: '100%'
    },
    timeSent: {
        padding: [8, 12],
        fontSize: 12,
        color: ({isMine}) => isMine ? '#FFF' : '#2F2F2F',
    },
    deleted: {
        fontStyle: 'italic'
    },
    videoContainer: {
        overflow: 'hidden',
        borderRadius: [12, 12 , 0, 0],
        position: 'relative',
    },
    videoAttachmentPlayButton: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundImage: `url(${videoPlayButton})`,
        backgroundSize: '75px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        opacity: 0.7,
        cursor: 'pointer',
        '&:hover': {
            opacity: 1,
        }
    },
    downloadIcon:{
        width: 32,
        position: 'absolute',
        bottom: 8,
        right: 8,
        cursor: 'pointer'
    }
}))

const MediaMessageDisplay = ({message, time, isMine, isDeleted, channel}) => {

    const [isFetchingAttachment, setIsFetchingAttachment] = useState();
    const [attachmentObject, setAttachmentObject] = useState(null)
    const classes = useStyles({isMine});
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);

    useEffect(() => {
        if(!isDeleted) fetchAttachment();
    }, [])

    const fetchAttachment = async () => {
        setIsFetchingAttachment(true);
        try{
            const {data} = await httpFetchChatFile(message.meta.attachmentId, channel);
            setAttachmentObject(data);
        }catch (e){
            console.log('error fetching attachment', e)
        }finally {
            setIsFetchingAttachment(false);
        }
    };

    //httpDownloadAttachment


    const renderCorrectMedia = () => {
        switch (message.type){
            case MESSAGE_TYPE.image:
                return(
                    <>
                        <Media
                            image={attachmentObject?.url}
                            className={classes.imageAttachment}
                            onClick={() => setIsPreviewOpen(!isPreviewOpen)}
                        />
                        {attachmentObject && isPreviewOpen && <Popover onClose={() => setIsPreviewOpen(false)}>
                            <img className={classes.photo}
                                 src={attachmentObject.url}
                                 alt={'attachment'}/>
                        </Popover>
                        }
                    </>
                )
            case MESSAGE_TYPE.video:
                return (
                    <>
                        {attachmentObject &&
                            <div onClick={() => setIsPreviewOpen(true)}
                                 className={classes.videoContainer}
                            >
                                <video className={classes.videoAttachment} preload="metadata">
                                    <source src={`${attachmentObject.url}#t=0.1`} type={`video/${getExtension(attachmentObject.url)}`} />
                                </video>
                                <div className={classes.videoAttachmentPlayButton}/>
                            </div>
                        }
                        {attachmentObject && isPreviewOpen && <Popover onClose={() => setIsPreviewOpen(false)}>
                            <video controls className={classes.videoAttachment} preload="metadata">
                                <source src={`${attachmentObject.url}#t=0.1`} type={`video/${getExtension(attachmentObject.url)}`} />
                            </video>
                        </Popover>}
                    </>
                )
            case MESSAGE_TYPE.file:
                return (
                    <>
                        <div>
                            Filename
                        </div>
                    </>
                )
            default:
                break;
        }
    }

    return isDeleted ?
        <div className={classes.textMessage}>
            <div className={classes.deleted}>Il messaggio è stato eliminato.</div>
        </div> :
        <div className={classes.mediaMessage}>
            {
                isFetchingAttachment ?
                    <Spinner overlay={false}/>
                    :
                    renderCorrectMedia()
            }
            <span className={classes.timeSent}>{convertTimestampToTime(time)}</span>
            <span className={classes.downloadIcon} onClick={()=> httpDownloadAttachment(attachmentObject.url)}><ChatDownloadIcon/></span>
        </div>
}

export default MediaMessageDisplay;