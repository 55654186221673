import {clearDamagesForBackend, errorHandler, toMoney} from "../../helpers/utils.js";
import Text from "../../Commons/Text.js";
import React, {useCallback, useEffect, useState} from "react";
import {createUseStyles} from "react-jss";
import {httpEditCarInfo} from "../../HttpRequests/barters.http.js";
import {setCurrentBarter} from "../../store/actions/bartersActions.js";
import {alertToggle} from "../../store/actions/alertActions.js";
import Spinner from "../../Commons/Spinner.js";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import Button from "../../Commons/Button.js";
import {debounce} from "lodash-es";

const useStyles = createUseStyles(theme => ({
    root: {
        padding: theme.spacing * 2,
        ...theme.paper,
        borderRadius: theme.spacing,
        display: 'grid',
        //gridRowGap: theme.spacing,
        alignItems: 'center',

        '& h4': {
            color: theme.colors.black,
            display: 'grid',
            gridColumnGap: 8,
            alignItems: 'start',
            padding: [theme.spacing * 2, 0],
            borderBottom: `1px solid ${theme.colors.lightGrey}`,
            [theme.mUp]: {
                gridTemplateColumns: '1fr 300px',
            },
            [theme.s]: {
                gridTemplateColumns: '1fr auto',
            },
            '& p': {
                display: 'grid',
                fontSize: 18,
                fontWeight: 600,
                '& small': {
                    fontSize: 14,
                    fontWeight: 500,
                }
            },
            '& span': {
                fontSize: 18,
                justifySelf: 'end'
            },
            '&:last-child': {
                borderBottom: 'none',
                '& p': {
                    display: 'grid',
                    fontSize: 18,
                    fontWeight: 600,
                    textTransform: 'uppercase',
                },
            }
        }
    },
    warning: {
        color: theme.colors.yellow,
        fontWeight: 400,
        width: '100%',
        textAlign: 'right',
        '& span': {
            fontWeight: 600,
            fontSize: 14,
        },
        '& small': {
            fontSize: '11px!important',
            textTransform: 'none!important'
        }
    },
    warnInput: {
        '& input': {
            border: `1px solid ${theme.colors.yellow}!important`
        },
        '& span': {
            color: theme.colors.yellow,
            textAlign: 'end',
            width: '100%'
        },
        '& small': {
            color: theme.colors.yellow,
            fontSize: "12px !important",
            fontWeight: 500,
            textTransform: "none",
            textAlign: 'end',
            width: '100%'
        }
    },
    normal: {
        '& span': {
            color: theme.colors.black,
            fontWeight: 600,
            textTransform: "none",
            textAlign: 'end',
            width: '100%'
        },
    },
    warnText: {
        justifySelf: "end",
        display: "grid",
        gridColumnStart: "1",
        gridColumnEnd: "3",
        gridRow: "4",
        width: "300px",
        marginTop: "4px",
        color: theme.colors.yellow,
        fontSize: "12px !important",
        fontWeight: 500,
        textTransform: "none"
    },
    completeBtn: {
        margin: "8px 0 0 auto",
        '& span': {
            fontSize: `12px!important`,
            justifySelf: 'end!important'
        }
    }
}));


const RecapSection = ({barter, isRecap, callback}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const liveDamages = useSelector(state => state.barters.damagesLiveSum);
    const operations = useSelector(state => state.users.operations);
    const damages = useSelector(state => state.barters.damages);
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState(false);
    const [totalCosts, setTotalCosts] = useState(barter?.total_costs);
    const isAdmin = useSelector(state => state.users.user.operations?.admin);
    const isSalesApproval = useSelector(state => state.users.operations?.sales_approval);

    const {register, watch, setValue} = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
    });

    useEffect(() => {
        setWarning(((+watch('withdrawal_price')) > +barter.ideal_price) && barter.status === 'trade_in');
    }, [watch('withdrawal_price')]);


    const liveDamagesSum = (myDamages) => {
        if (myDamages.length > 0 && !barter?.examination.summary) {
            setTotalCosts(liveDamages + 150);
            //ogni volta che cambiano i danni va ricalcolato il suggerimento di valore di ritiro sulla nuova base
            setValue('withdrawal_price', barter?.average_market_value - (liveDamages + 150) < 0 ? 0 : barter?.average_market_value - (liveDamages + 150));
        }
    };
    useEffect(() => {liveDamagesSum(damages)},[liveDamages])

    useEffect(() => {
        if(barter){
            setTotalCosts(barter?.first_damage_estimate + 150);
            setValue('withdrawal_price', barter?.withdrawal_price ? barter?.withdrawal_price : barter?.average_market_value - (barter?.total_costs) < 0 ?
                0 : barter?.average_market_value - (barter?.total_costs))
        }
    }, [barter]);

    //ogni volta che cambiano i danni va ricalcolato il suggerimento di valore di ritiro sulla nuova base
    useEffect(() => {
        if(barter){
            setValue('withdrawal_price', (barter?.average_market_value - (totalCosts)) < 0 ? 0 : barter?.average_market_value - (totalCosts));
        }
    }, [barter?.average_market_value]);

    const editCarInfo = async (fieldName, value, label, isAccessory = false) => {
        if ((value === null || value === '') || barter[fieldName]?.toString() === value.toString()) return;

        const dataToSend = {
            car: {
                id: barter.id,
                withdrawal_price: value,
                is_draft: true
            },
        };
        setLoading(true);
        try {
            const {data} = await httpEditCarInfo(dataToSend);
            dispatch(setCurrentBarter(data));
            dispatch(alertToggle(`Il Valore di ritiro proposto è stato aggiornato correttamente.`, 'success'))
            setValue('withdrawal_price', data?.withdrawal_price)
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    };

    const submitDebounced = useCallback(debounce((fieldName, value, label, isAccessory = false) => editCarInfo(fieldName, value, label, isAccessory = false), 900), []);
    const submitInstant = (fieldName, value, label, isAccessory = false) => {
        submitDebounced.cancel();
        editCarInfo(fieldName, value, label, isAccessory = false);
    };

    //todo: check if is admin for message
    return <div className={classes.root}>

        {loading && <Spinner overlayFullscreen={true}/>}
        <h4>
            <p> Valore medio di mercato
                {(isAdmin || isSalesApproval) && <small>{"(Eurotax, Indicata)"}</small>}
            </p>
            <span>{toMoney(barter?.average_market_value)}</span>
        </h4>
        <h4 style={{color: 'red'}}>
            <p>Costi
                <small>(Valore complessivo di ripristini, fiscalità e minivoltura)</small>
            </p>
            <span>{toMoney(totalCosts)}</span>
        </h4>
        <h4>
            <p>Costo complessivo di acquisto permuta
                <small>(Valore di ritiro + costi)</small>
            </p>
            <span>{toMoney((isRecap ? +barter?.withdrawal_price : +watch('withdrawal_price')) + (totalCosts))}</span>
        </h4>
        <h4>
            <p>Valore di ritiro proposto dal venditore *</p>
            {isRecap ? <div className={warning ? classes.warnInput : classes.normal}>
                    <span>{toMoney(barter.withdrawal_price)}</span>
                    {warning && <small>
                        {operations?.admin ? 'ATTENZIONE: Questo valore supera la soglia di marginalità stabilita.' : 'ATTENZIONE: Il valore di ritiro supera la soglia di marginalità stabilita. il valore proposto verrà valutato da un responsabile.'}
                    </small>}
                </div>
                :
                <>
                    <form className={''}>
                        <Text type={'number'} label={''} name={'withdrawal_price'} ref={register} min={0}
                              defaultValue={(barter?.withdrawal_price < 0) ? 0 : barter?.withdrawal_price}
                              className={warning ? classes.warnInput : ''}
                              onChange={(e) => submitDebounced('withdrawal_price', watch('withdrawal_price'), '', false)}/>
                        {warning && <small className={warning ? classes.warnText : ''}>
                            {operations?.admin ? 'ATTENZIONE: Questo valore supera la soglia di marginalità stabilita.' : 'ATTENZIONE: Il valore di ritiro supera la soglia di marginalità stabilita. il valore proposto verrà valutato da un responsabile.'}
                        </small>}
                        <Button type={'button'} data-primary data-small className={classes.completeBtn}
                                onClick={() => callback(false)}> Concludi permuta </Button>
                    </form>
                </>}
        </h4>
    </div>
};

export default RecapSection;
