
const pubnubConfig = {
    restore: true,
    heartbeatInterval: 0,
    publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
    subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
    //cipherKey: "myCipherKey",
    logVerbosity: false,
    //ssl: true,
    presenceTimeout: 130
}


export default pubnubConfig;