import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import Table from "../../Commons/Table.js";
import {
    errorHandler,
    formattedDate,
    removeUnsettedKeyInObj,
    toMoney,
} from "../../helpers/utils.js";
import {httpFetchStocksRevision,} from "../../HttpRequests/stock.http.js";
import SubHeading from "../../Commons/SubHeading.js";
import {useDispatch, useSelector} from "react-redux";
import SimpleSearch from "../../Commons/SimpleSearch.js";
import {setCarsRevision, setCarTasks, setCurrentCar} from "../../store/actions/carsActions.js";
import cx from 'classnames';
import qs from "query-string";

const useStyles = createUseStyles(theme => ({
    gridContainer: {
        'position': 'relative',
        height: ({bigSubHeader}) => `calc(100vh - ${bigSubHeader ? theme.bigSubHeader + theme.headerHeight : theme.smallSubHeader + theme.headerHeight}px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        display: 'grid',
        gridTemplateColumns: '1fr',
        [theme.mUp]: {
            padding: [theme.spacing * 4, theme.spacing * 4,0],
        },
        [theme.s]: {
            padding: [theme.spacing],
        },
    },
    filters: {},
    table: {},
    tableHeader: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        gridColumnGap: theme.spacing,
        alignItems: 'center',
        marginBottom: theme.spacing * 2,
        height: 80,
        '& h3': {
            fontWeight: 600,
            lineHeight: 1
        },
        '& small': {
            color: theme.colors.darkGrey,
            fontSize: 16,
        }
    },
    dot: {
        width: 10,
        height: 10,
        borderRadius: '50%',
        marginRight: theme.spacing,
        '&:before': {
            content: '""'
        }
    },
    green: {
        backgroundColor: theme.colors.green,
    },
    yellow: {
        backgroundColor: theme.colors.yellow,
    }, red: {
        backgroundColor: theme.colors.red,
    },
}));

const Pricing = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const stocksRevision = useSelector(state => state.cars.carsRevision);
    //table statuses
    const [total, setTotal] = useState(null);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(null);
    const classes = useStyles({bigSubHeader: false});
    //filters things
    const [sorter, setSorter] = useState(null);
    const [filters, setFilters] = useState({price:[0,0]});

    //is reset is temporary to solve async update issue of filters
    const getStocksRevisions = async (actualPage = 1,sorters = sorter, isReset = false) => {
        if (lastPage && actualPage > lastPage) return;
        if (actualPage === 1) {
            dispatch(setCarsRevision([]))
        }
        setLoading(true);

        try {
            const {data: {data, total, last_page}} = await httpFetchStocksRevision(actualPage, 20,sorters, isReset ? {} : filters);
            if (actualPage === 1) {
                dispatch(setCarsRevision(data));
            } else {
                dispatch(setCarsRevision(stocksRevision.concat(data)));
            }
            setTotal(total);
            setLastPage(last_page)
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    };

    const onRowClickPath = (item) => `/stock/pricing/${item?.id}`;

    //fetch at the init
    useEffect(() => {
        //clear currentCar if present to prevent flash fake data in the specific sections
        dispatch(setCurrentCar(null));
        dispatch(setCarTasks([]));
        //clean up
        return () => {
            dispatch(setCarsRevision([]))
        };
    }, []);

    //fetch when filters change
    useEffect(() => {
        setPage(1);
        setLastPage(null);
        getStocksRevisions(1);
    }, [filters]);

    //fetch when page change
    useEffect(() => {
        if (!!stocksRevision.length && page !== 1) {
            getStocksRevisions(page)
        }
    }, [page]);

    const onChangePage = (isFilter = false) => {
        isFilter ? setPage(1) : setPage(page + 1);
    };

    const onDataSorting = (newSort) => {
        const sorters = qs.stringify(newSort, {
            arrayFormat: 'comma', parseBooleans: true, skipEmptyString: true, skipNull: true
        })
        setSorter(sorters);
        setPage(1);
        getStocksRevisions(1, sorters,false);
    };

    const onComplexSearch = (search) => {
        const newFilters = {...filters, ...search};
        removeUnsettedKeyInObj(newFilters);
        setFilters(newFilters);
    };

    return (
        <>
            <SubHeading title={"Pricing"} type={'small'} subTitleData={`${!!total ? total : 0} risultati`}
                        rightSearchComponent={<SimpleSearch filters={filters} setPage={setPage}
                                                            onComplexSearchCb={onComplexSearch} name={'plate'}
                                                            placeholder={'cerca per targa'}/>}/>

            <section className={classes.gridContainer}>
                {/*{loading && <Spinner />}*/}
                <div className={classes.table}>
                    <Table
                        id={'table'}
                        data={stocksRevision}
                        inLoading={loading}
                        filters={filters}
                        onSetPageCb={onChangePage}
                        onSortingDataCb={onDataSorting}
                        theadHeight={40}
                        rowMinHeight={40}
                        classNames={{
                            table: classes.table,
                            thead: classes.thead,
                            row: classes.row,
                        }}
                        columns={['brand', 'model', 'version', 'days_in_stock', 'plate', 'mileage', 'fuel_method', 'owning_company', 'matriculation', 'current_position', 'price']}
                        columnsNames={{
                            brand: "Marca",
                            model: "Modello",
                            version: "Versione",
                            days_in_stock: 'Giacenza',
                            plate: "Targa",
                            mileage: 'Km',
                            fuel_method: "Alimentazione",
                            owning_company: "Azienda proprietaria",
                            matriculation: "Immatricolazione",
                            current_position: "Ubicazione",
                            price: "Prezzo",
                        }}
                        columnsWidths={{
                            brand: "10%",
                            model: "10%",
                            version: "10%",
                            days_in_stock: '8%',
                            plate: "6%",
                            mileage: '6%',
                            fuel_method: '5%',
                            owning_company: "15%",
                            matriculation: "10%",
                            current_position: "10%",
                            price: "5%",
                        }}
                        columnsAlignments={{
                            actions: 'right',
                        }}
                        backEndSortable={true}
                        sortable={true}
                        headersSorters={['days_in_stock', 'mileage']}
                        columnsRenderers={{
                            brand: (item, value) => <strong>{value?.model?.brand?.name}</strong>,
                            model: (value) => <span>{value?.name}</span>,
                            price: (item) => <strong>{item ? toMoney(item) : '-'}</strong>,
                            days_in_stock: (item) => <span>
                                <span
                                    className={cx(classes.dot, item <= 25 ? classes.green : item <= 75 ? classes.yellow : classes.red)}/>
                                {item}
                            </span>,
                            plate: (value) => <span style={{textTransform:'uppercase'}}>{value}</span>,
                            mileage: (item, value) => <span>{value?.mileage}</span>,
                            matriculation: (item) => <span>{formattedDate(item)}</span>,
                            owning_company: (item) => <span>{item ? item : '-'}</span>,
                            current_position: (item) => <span>{item ? item : '-'}</span>,
                        }}
                        onRowClickPath={onRowClickPath}
                    />
                </div>
            </section>
        </>);
};

export default Pricing;
