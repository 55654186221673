import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import SubHeading from "../../Commons/SubHeading.js";
import {useDispatch} from "react-redux";
import {clearFilters, errorHandler, formattedDate, getLabelByValue, taskStatuses} from "../../helpers/utils.js";
import {httpGetTaskType} from "../../HttpRequests/tasks.http.js";
import SimpleSearch from "../../Commons/SimpleSearch.js";
import Table from "../../Commons/Table.js";
import Badge from "../../Commons/Badge.js";
import {ArrowDown, ArrowUp} from "../../assets/icons.js";

const useStyles = createUseStyles(theme => ({
    gridContainer: {
        'position': 'relative',
        // with big/small subheader like permute page
        height: ({bigSubHeader}) => `calc(100vh - ${bigSubHeader ? theme.bigSubHeader + theme.headerHeight : theme.smallSubHeader + theme.headerHeight}px)`,
        // without subheader
        //height: `calc(100vh - ${theme.headerHeight}px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        [theme.mUp]: {
            padding: [theme.spacing * 4, theme.spacing * 4, 0],
        },
        [theme.s]: {
            padding: [theme.spacing],
        },
    },
    doubleRow: {
        ...theme.grid.doubleRow,
    },
}));

const taskStatusColors = {
    open: 'primary',
    active: 'warning',
    closed: 'success'
};

const ListRestore = () => {
    const dispatch = useDispatch();
    const [restoresList, setRestoresList] = useState([])
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({});
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(null);
    const [rowExpandedId, setRowExpandedId] = useState(null);
    //based on the type {"big","small"} property of subHeader component
    const classes = useStyles({bigSubHeader: false}); // or false if type is small

    const getCarsRestores = async (actualPage = 1) => {
        if (lastPage && actualPage > lastPage) return;
        if (actualPage === 1) setRestoresList([])
        setLoading(true);
        clearFilters(filters);
        try {
            const {data: {data: newRestoresList, last_page}} = await httpGetTaskType('reconditioning', {
                limit: 20, ...filters,
                page: actualPage
            });
            if (actualPage === 1) {
                setRestoresList(newRestoresList)
            } else {
                setRestoresList(restoresList.concat(newRestoresList))
            }
            setLastPage(last_page);
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getCarsRestores();
    }, []);

    //fetch when filters change
    useEffect(() => {
        if (!!Object.keys(filters).length) {
            setPage(1);
            setLastPage(null);
            getCarsRestores(1);
        }
    }, [filters]);

    //fetch when page change
    useEffect(() => {
        if (!!restoresList.length && page !== 1) {
            getCarsRestores(page)
        }
    }, [page]);


    const onChangePage = (isFilter = false) => {
        if (isFilter) {
            setPage(1)
        } else {
            setPage(page + 1)
        }
    };

    const onDataSorting = (newSort) => {
        setFilters({
            ...filters,
            ...newSort,
        });
        setPage(1);
    };

    return <>
        <SubHeading title={"Lista ripristini"} type={'small'} justifySelf={'unset'}
                    rightSearchComponent={<SimpleSearch filters={filters} setFilters={setFilters} name={'plate'}
                                                        placeholder={'cerca targa'} setPage={setPage}/>}
        />

        <section className={classes.gridContainer}>
            <Table
                id={'table'}
                data={restoresList}
                inLoading={loading}
                filters={filters}
                rowExpandedId={rowExpandedId}
                setRowExpandedId={setRowExpandedId}
                onSetPageCb={onChangePage}
                onSortingDataCb={onDataSorting}
                theadHeight={40}
                rowMinHeight={48}
                classNames={{
                    table: classes.table,
                    thead: classes.thead,
                    row: classes.row,
                }}
                //real name is created_at not date
                columns={['plate', 'assigned_at', 'deadline', 'cta', 'status', 'actions']}
                columnsNames={{
                    plate: "Targa",
                    assigned_at: "Data di assegnazione",
                    deadline: "Data di scadenza",
                    cta: "",
                    status: "Stato",
                    actions: ''
                }}
                columnsWidths={{
                    plate: "15%",
                    assigned_at: "20%",
                    deadline: "35%",
                    cta: "17%",
                    status: "10%",
                    actions: '3%'
                }}
                backEndSortable={true}
                sortable={true}
                expandedComponent={RepairRows}
                headersSorters={['assigned_at', 'deadline', 'status']}
                columnsRenderers={{
                    plate: (value, items) => <span style={{textTransform: 'uppercase'}}>{items?.car?.plate}</span>,
                    assigned_at: (value) => <span>{formattedDate(value)}</span>,
                    deadline: (value) => <span>{formattedDate(value)}</span>,
                    cta: (value, items) => <a href={`/stock/${items?.car_id}/restore`}
                                              style={{
                                                  textTransform: 'uppercase',
                                                  fontSize: 12,
                                                  textDecoration: "underline"
                                              }}>
                        Vedi scheda ripristini
                    </a>,
                    status: (value,items) => <span style={{opacity: (!items?.is_active) ? 0.7 : 1 }}><Badge title={getLabelByValue(taskStatuses, value)}
                                                    statusColor={taskStatusColors[value]} badgeSize={'small'}/></span>,
                    actions: (value, items, metadata) => <Actions rowIndex={metadata.rowIndex}
                                                                  rowExpandedId={rowExpandedId}
                                                                  repairs={items?.car?.repairs}
                                                                  setRowExpandedId={setRowExpandedId}/>
                }}
            />
        </section>
    </>
};

export default ListRestore;

/** expand actions **/
const useStylesActions = createUseStyles(theme => ({
    root: theme.grid.actions,
}));

export const Actions = ({setRowExpandedId, rowIndex, rowExpandedId,repairs}) => {
    const classes = useStylesActions();
    const isExpanded = (rowIndex === rowExpandedId);
    return (
        <div className={classes.root}>
            {!!repairs?.length && <span onClick={() => {
                setRowExpandedId(isExpanded ? null : rowIndex)
            }}>
                {isExpanded ? <ArrowUp/> : <ArrowDown/>}
            </span>}
        </div>
    );
};

/** subcomponent expanded **/
const useStylesRepairRows = createUseStyles(theme => ({
    root: {
        margin: "12px -12px 0",
        background: theme.colors.smokeWhite,
        padding: [0, 12],
        display: "grid",
    },
    row: {
        display: "grid",
        gridTemplateColumns: "88% 22%",
        gridColumnGap: theme.spacing,
        borderBottom: `1px solid ${theme.colors.lightGrey}`,
        padding: [theme.spacing, 0],
        fontSize: 12,
        textTransform: "lowercase",
        '&::last-child': {
            borderBottom: "none",
        }
    }
}));

export const RepairRows = ({data}) => {
    const classes = useStylesRepairRows();

    return <div className={classes.root}>
        {!!data?.car?.repairs?.length && data?.car?.repairs?.map((repair, index) => !!repair.users.length &&
            <div className={classes.row} key={index}>
                <strong style={{fontSize: 14, textTransform: 'capitalize'}}>{repair.users[0].full_name}</strong>
                <Badge title={getLabelByValue(taskStatuses, repair.status)}
                       statusColor={taskStatusColors[repair.status]} badgeSize={'small'}/>
            </div>)}
    </div>
};