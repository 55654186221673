export const SET_BARTERS_LIST = 'SET_BARTERS_LIST';
export const SET_BARTER = 'SET_BARTER';
export const SET_DAMAGES = 'SET_DAMAGES';
export const ADD_BARTER_PHOTO = 'ADD_BARTER_PHOTO';
export const DELETE_BARTER_PHOTO = 'DELETE_BARTER_PHOTO';

/**
* How to use setBarter:
* dispatch(setBarter(barter));
**/
export const setCurrentBarter = (barter) => {
    return {
        type: SET_BARTER,
        barter: barter,
    };
};

/**
 * How to use setBartersList:
 * dispatch(setBartersList(barters));
 **/
export const setBartersList = (barters) => {
    return {
        type: SET_BARTERS_LIST,
        bartersList: barters
    };
};

/**
 * How to use setDamages:
 * dispatch(setDamages(damages));
 **/
export const setDamages = (damages) => {
    return {
        type: SET_DAMAGES,
        damages: damages
    };
};


export const addBarterPhoto = (photo) => {
    return {
        type: ADD_BARTER_PHOTO,
        photo
    };
};

export const deleteBarterPhoto = (photo) => {
    return {
        type: DELETE_BARTER_PHOTO,
        photo
    };
};


