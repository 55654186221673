import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useHistory, useParams} from 'react-router-dom';
import SubHeading from "../../../Commons/SubHeading.js";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {
    httpCreateUser,
    httpGetBusinessList,
    httpGetUbications,
    httpGetUser,
    httpGetUsersPermissions,
    httpUpdateUser
} from "../../../HttpRequests/settings.http.js";
import {errorHandler, formattedDateIso, retrieveValueForRs, setErrors} from "../../../helpers/utils.js";
import Spinner from "../../../Commons/Spinner.js";
import Text from "../../../Commons/Text.js";
import DayPickerInput from "../../../Commons/DayPickerInput.js";
import Select from "../../../Commons/Select.js";
import Radio from "../../../Commons/Radio.js";
import ToggleSwitch from "../../../Commons/ToggleSwitch.js";
import Button from "../../../Commons/Button.js";
import * as Yup from "yup";
import ProfileImagesUpload from "../../../Components/Settings/UserAccount/ProfileImageUpload";
import {editUser} from "../../../store/actions/settingsActions.js";
import {alertToggle} from "../../../store/actions/alertActions.js";

const useStyles = createUseStyles(theme => ({
    gridContainer: {
        'position': 'relative',
        // with big/small subheader like permute page
        height: ({bigSubHeader}) => `calc(100vh - ${bigSubHeader ? theme.bigSubHeader + theme.headerHeight : theme.smallSubHeader + theme.headerHeight}px)`,
        // without subheader
        //height: `calc(100vh - ${theme.headerHeight}px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        [theme.mUp]: {
            padding: [theme.spacing * 4],
        },
        [theme.s]: {
            padding: [theme.spacing],
        }
    },
    formRoot: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gridColumnGap: theme.spacing,
        padding: theme.spacing,
        borderRadius: 20,
        ...theme.paper
    },
    imageContent: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto 1fr',
        gridColumnGap: theme.spacing * 2,
        gridRowGap: theme.spacing * 2,
        padding: theme.spacing,
        alignItems: 'start',
        textAlign: 'center'
    },
    gridContent: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        alignItems: 'start',
        gridColumnGap: theme.spacing * 2,
        gridRowGap: theme.spacing * 2,
        padding: theme.spacing
    },
    radioContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 2fr',
        gridColumnGap: theme.spacing,
        gridRowGap: theme.spacing * 2,
        padding: theme.spacing
    },
    mainRow: {
        display: 'grid',
        gridColumnStart: 1,
        gridColumnEnd: 4,
        margin: 0,
        border: 0
    },
    title: {
        display: 'grid',
        gridColumnStart: 1,
        gridColumnEnd: 1,
        fontWeight: 'bold',
        margin: [theme.spacing * 2, 0, theme.spacing * 2, 0]
    },
    titleWide: {
        display: 'grid',
        gridColumnStart: 1,
        gridColumnEnd: 4,
        fontWeight: 'bold',
        margin: [theme.spacing * 2, 0, theme.spacing * 2, 0]
    },
    avatar: {
        width: "160px",
        height: "160px",
        display: "grid",
        position: "relative",
        borderRadius: "50%",
        alignItems: "center",
        justifyItems: "center",
        boxSizing: 'border-box',
        background: theme.colors.grey,
        alignSelf: 'start',
        margin: theme.spacing * 2
    },
    btns: {
        marginTop: theme.spacing * 2,
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: theme.spacing,
        gridColumnStart: 3,
        width: 300,
        justifySelf: 'end'
    }
}));

const validationSchema = Yup.object().shape({
    first_name: Yup.string().required(`Inserisci il nome.`).min(3, 'Il nome deve contenere almeno 3 caratteri'),
    last_name: Yup.string().required(`Inserisci il cognome.`).min(3, 'Il cognome deve contenere almeno 3 caratteri'),
    birth_date: Yup.string().required(`Inserisci una data di nascita  ( gg-mm-aaaa ).`).nullable(),
    email: Yup.string()
        .email(`L'email inserita non è un email valida`)
        .required(`Inserisci l'indirizzo email aziendale.`),
    phone: Yup.string().required(`Inserisci il numero telefonico.`),
    company_id: Yup.string().nullable().required(`Inserisci la sede di appartenennza.`),
    ubication_id: Yup.string().nullable().required(`Inserisci l'ubicaizone.`),
});

const validationSchemaExternal = Yup.object().shape({
    first_name: Yup.string().required(`Inserisci il nome.`).min(3, 'Il nome deve contenere almeno 3 caratteri'),
    email: Yup.string()
        .email(`L'email inserita non è un email valida`)
        .required(`Inserisci l'indirizzo email aziendale.`),
    phone: Yup.string().required(`Inserisci il numero telefonico.`),
});

const UserAccount = () => {
    const classes = useStyles({bigSubHeader: false});
    const {id} = useParams();
    const history = useHistory();
    const [user, setUser] = useState(useSelector((state) => state?.settings?.usersList && state.settings.usersList.find((arr) => +arr.id === +id)));
    const [loading, setLoading] = useState(false);
    const [operations, setOperations] = useState(null);
    const [businesses, setBusinesses] = useState(null);
    const [externals, setExternals] = useState(null);
    const [photoLink, setPhotoLink] = useState(null);
    const [ubications, setUbications] = useState([]);
    const usersAutocomplete = useSelector(state => state.autocompletes.usersAutocomplete);
    const dispatch = useDispatch();
    const [schema, setSchema] = useState((user && user?.type === 'internal') ? validationSchema : validationSchemaExternal);

    const {register, handleSubmit, control, errors, setValue, reset, watch, setError} = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            ...user,
            birth_date: user?.birth_date ? new Date(user?.birth_date.toString().replace(/-/g, "/")) : '',
            assigned_office: user?.assigned_office ? user.assigned_office : null,
            assigned_car: user?.assigned_car && user?.assigned_car?.toString(),
            daily_report: user?.daily_report && user?.daily_report?.toString(),
            company_id: user?.company ? {'value': user?.company.id, 'label': user?.company.name} : null,
            external_user: (user && user?.type !== 'internal') ? 'true' : 'false',
            ...user?.operations,
        },
        validationSchema: schema
        /* ...((user?.type === 'internal') ? {validationSchema} : {validationSchemaExternal}),*/
    });
    const externalUser = (watch('external_user') === 'true');

    useEffect(
        () => {
            getBusinesses();
            getUserOperations();
        },
        []
    );

    useEffect(() => {
        if(watch('external_user') === 'false' ){
            setSchema(validationSchema)
        }else{
            setSchema(validationSchemaExternal)
        }
    },[watch('external_user')])

    useEffect(() => {
        if (user?.id) {
            getUbications(user);
            setValue('supervisor_id', retrieveValueForRs(usersAutocomplete, user?.supervisor_id));
        }else{
            getUbications(null);
        }
        if (user?.type !== 'internal') {
            resetInternalOperations();
        }
    }, [user]);

    const getUbications = async (actualUser) => {
        setLoading(true);
        try {
            const {data: {data}} = await httpGetUbications({limit: 200, filters: {}, page: 1});
            setUbications(data.map((ubication) => ({
                value: ubication.id,
                label: `${ubication.city} - ${ubication.address}`
            })));
            if(actualUser){
                setValue('ubication_id', retrieveValueForRs(data.map((ubication) => ({
                    value: ubication.id,
                    label: `${ubication.city} - ${ubication.address}`
                })), actualUser?.ubication_id));
            }
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    };

    const getUserOperations = async () => {
        setLoading(true)
        try {
            let isExternal = null;
            let actualUser = null;
            const {data} = await httpGetUsersPermissions();
            setOperations(data.filter((el) => !el.is_external));
            setExternals(data.filter((el) => el.is_external).map((el) => ({label: el.title, value: el.value})));
            //get user if needed
            if (id && !user) {
                const {data: selectedUser} = await httpGetUser(id);
                actualUser = selectedUser;
                isExternal = retrieveIsExternal(selectedUser?.operations, data.filter((el) => el.is_external).map((el) => ({
                    label: el.title,
                    value: el.value,
                    identifier: el.label
                })));
                setValue('is_external', isExternal);
            } else {
                actualUser = user;
                isExternal = retrieveIsExternal(user?.operations, data.filter((el) => el.is_external).map((el) => ({
                    label: el.title,
                    value: el.value,
                    identifier: el.label
                })));
            }

            if (actualUser) {
                setUser(actualUser);
                reset({
                    ...actualUser,
                    birth_date: actualUser?.birth_date ? new Date(actualUser?.birth_date?.toString().replace(/-/g, "/")) : null,
                    company_id: actualUser?.company ? {
                        'value': actualUser?.company.id,
                        'label': actualUser?.company.name
                    } : null,
                    assigned_car: user?.assigned_car && user.assigned_car?.toString(),
                    daily_report: actualUser?.daily_report && actualUser.daily_report?.toString(),
                    ...actualUser?.operations,
                    is_external: isExternal,
                    external_user: (actualUser && actualUser?.type !== 'internal') ? 'true' : 'false',
                    ubication_id: retrieveValueForRs(ubications, actualUser?.ubication_id),
                    supervisor_id: retrieveValueForRs(usersAutocomplete, actualUser?.supervisor_id),
                });
            }
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false)
        }
    };

    const getBusinesses = async () => {
        try {
            const {data: {data}} = await httpGetBusinessList();
            setBusinesses(data.map((el) => ({label: el.name, value: el.id})));
        } catch (e) {
            errorHandler(e, dispatch)
        }
    };

    const operationsRemapForBe = (values) => {
        let operationsToSend = [];
        let keysToCompare = Object.keys(values).map((key, index) => {
            if (values[key]) return key
        });

        keysToCompare.map((item) => {
            operations.find(operation => {
                if (operation.label === item) {
                    operationsToSend.push(operation.value);
                }
            })
        });

        if (watch('is_external')) {
            operationsToSend.push(watch('is_external')?.value)
        }

        return operationsToSend
    };

    const retrieveIsExternal = (userOperations, externalsOperations) => {
        if (!userOperations) return;

        if (userOperations['workshop']) {
            setValue('is_external', externalsOperations.filter(external => external.identifier === 'workshop')[0]);
            return externalsOperations.filter(external => external.identifier === 'workshop')[0]
        } else if (userOperations['body_shop']) {
            setValue('is_external', externalsOperations.filter(external => external.identifier === 'body_shop')[0]);
            return externalsOperations.filter(external => external.identifier === 'body_shop')[0]
        } else if (userOperations['tire_center']) {
            setValue('is_external', externalsOperations.filter(external => external.identifier === 'tire_center')[0]);
            return externalsOperations.filter(external => external.identifier === 'tire_center')[0]
        } else if (userOperations['supplier']) {
            setValue('is_external', externalsOperations.filter(external => external.identifier === 'supplier')[0]);
            return externalsOperations.filter(external => external.identifier === 'supplier')[0]
        } else {
            setValue('is_external', null)
        }
    }

    const onSubmit = async (values) => {
        const dataToSend = {
            ...values,
            id: id,
            birth_date: values?.birth_date ? formattedDateIso(values?.birth_date) : null,
            assigned_office: values?.assigned_office ? values.assigned_office.value : 'default',
            operations: [...operationsRemapForBe(values)],
            company_id: watch('company_id') ? watch('company_id')?.value?.toString() : null,
            ubication_id: watch('ubication_id') ? watch('ubication_id')?.value?.toString() : null,
            supervisor_id: watch('supervisor_id') ? watch('supervisor_id')?.value?.toString() : null,
        };
        // Add photo if exists
        if (photoLink) dataToSend.photo_link = photoLink;

        try {
            setLoading(true);
            const {data: user} = id ? await httpUpdateUser(id, dataToSend) : await httpCreateUser(dataToSend);
            dispatch(editUser(user));
            history.push('settings/users')
            dispatch(alertToggle((id ? 'Dati correttamente aggiornati' : 'Dati correttamente inseriti'), 'success'))
            setUser(user);
        } catch (e) {
            errorHandler(e, dispatch);
            if (e?.response?.data.message) {
                errorHandler(e, dispatch);
            } else {
                setErrors(e?.response?.data, setError);
            }
        } finally {
            setLoading(false);
        }
    };

    const resetInternalOperations = () => {
        return operations?.map((operation) => setValue(operation.label, false))
    };

    const maxBirthDate = () => {
        let date = new Date();
        date.setFullYear(date.getFullYear() - 18);
        return date;
    }

    return <>
        <SubHeading title={id ? "Modifica utente" : "Crea utente"} type={'small'}/>
        <div className={classes.gridContainer}>
            <form onSubmit={handleSubmit(onSubmit)} className={classes.formRoot}>
                {loading && <Spinner overlayFullscreen={true}/>}
                <div className={classes.imageContent}>
                    <p className={classes.title}>Immagine del profilo</p>
                    <ProfileImagesUpload userPhoto={user?.photo_link} setPhotoLink={setPhotoLink}/>
                </div>
                <div className={classes.gridContent}>
                    <div>
                        <p className={classes.title}>Utente esterno</p>
                        <div className={classes.radioContainer}>
                            <Radio label={'Si'} name={'external_user'} value={'true'} ref={register}/>
                            <Radio label={'No'} name={'external_user'} value={'false'} ref={register}/>
                        </div>
                    </div>
                    {externalUser && <div style={{gridColumn: '1 / span 1'}}>
                        <Select name={'is_external'} label={'Tipologia utente esterno'}
                                options={externals} ref={register} control={control} errors={errors.permissions}
                                placeholder={'Seleziona tipologia'}/>
                    </div>}

                    <p className={classes.title}> {externalUser ? 'Dati utente' : 'Dati anagrafici'} </p>
                    <hr className={classes.mainRow}/>
                    <Text type={'text'} label={'Nome*'} name={'first_name'} ref={register} errors={errors.first_name}/>
                    {!externalUser && <Text type={'text'} label={'Cognome*'} name={'last_name'} ref={register}
                                            errors={errors.last_name}/>}
                    {!externalUser &&
                    <DayPickerInput label={'Data di Nascita*'} name={'birth_date'} inForm={true} ref={register}
                                    control={control} errors={errors.birth_date} showYearDropdown={true}
                                    closeOnSelect={true}
                                    maxDate={maxBirthDate()}/>}
                    <Text type={'text'} label={watch('external_user') ? 'Email*' : 'Email Aziendale*'} name={'email'}
                          ref={register} errors={errors.email}/>
                    {!externalUser &&
                    <Text type={'text'} label={'Email Secondaria'} name={'secondary_email'} ref={register}
                          errors={errors.secondary_email}/>}
                    <Text type={'text'} label={'Telefono*'} name={'phone'} ref={register} errors={errors.phone}/>
                    {externalUser &&
                    <Text type={'text'} label={'Indirizzo'} name={'address'} ref={register} errors={errors.address}/>}
                    {externalUser &&
                    <Text type={'text'} label={'Città'} name={'city'} ref={register} errors={errors.city}/>}
                    {externalUser &&
                    <Text type={'text'} label={'P.iva'} name={'vat_number'} ref={register} errors={errors.vat_number}/>}
                    {!externalUser &&
                    <>
                        <Select name={'company_id'} label={'Azienda di appartenenza*'} errors={errors.company_id}
                                options={businesses} ref={register} control={control} disabled={loading && !businesses?.length}
                                isClearable={false}
                        />
                        <Select name={'ubication_id'} label={'Ubicazione*'} errors={errors.ubication_id}
                                options={ubications} ref={register} control={control} disabled={loading && !ubications?.length}
                                isClearable={false}
                        />
                        <Select name={'supervisor_id'} label={'Supervisore'} options={usersAutocomplete}
                                ref={register} control={control} disabled={loading && !usersAutocomplete?.length}
                        />
                    </>}

                    {!externalUser && <>
                        <hr className={classes.mainRow}/>
                        <div>
                            <p className={classes.title}>Auto Aziendale</p>
                            <div className={classes.radioContainer}>
                                <Radio label={'Si'} name={'assigned_car'} value={'true'} ref={register}/>
                                <Radio label={'No'} name={'assigned_car'} value={'false'} ref={register}
                                       defaultChecked/>
                            </div>
                        </div>
                        <div>
                            <p className={classes.title}>Daily Report</p>
                            <div className={classes.radioContainer}>
                                <Radio label={'Si'} name={'daily_report'} value={'true'} ref={register}/>
                                <Radio label={'No'} name={'daily_report'} value={'false'} ref={register}
                                       defaultChecked/>
                            </div>
                        </div>
                        {(watch('assigned_car') === 'true') && <>
                            <hr className={classes.mainRow}/>
                            <Text type={'text'} label={'Targa Auto Aziendale'} name={'assigned_car_plate'}
                                  ref={register}
                                  errors={errors.assigned_car_plate}/>
                        </>}

                        <hr className={classes.mainRow}/>
                        <p className={classes.titleWide}>Permessi</p>
                        {operations && operations.map((operation, index) =>
                            <ToggleSwitch key={index} className={classes.formElement} name={operation.label}
                                          label={operation.title} labelOn={'right'} ref={register}
                                          disabled={!!watch('is_external')}
                                          control={control}
                            />)}
                    </>}

                    <hr className={classes.mainRow}/>
                    <div className={classes.btns}>
                        <Button type="button" onClick={() => history.push('settings/users')}
                                data-secondary>Annulla</Button>
                        <Button type={'submit'} data-primary>Salva</Button>
                    </div>
                </div>
            </form>
        </div>
    </>
};

export default UserAccount;
