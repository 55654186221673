import React, { forwardRef } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import PropTypes from "prop-types";
import Checkbox from "./Checkbox.js";

const useStyles = createUseStyles(theme => ({
    root: {
        'width': '100%',
        'display': 'grid',
        'grid-auto-flow': 'row',
    },

    label: theme.control.label,
    textarea: theme.control.textarea,
    inputError: theme.control.inputError,
    error: {
        color: theme.colors.red,
        position: 'absolute',
        top: ({label}) => label ? 64 : 44,
        left: 0,
    },
}));

const Textarea = forwardRef(({ children, className, textAreaClassName, label, errors, rootProps, ...props }, ref) => {
    const classes = useStyles({ errors: !!errors,label });

    return (
        <label data-control id={props.name} className={cx(classes.root, className)} {...rootProps}>
            { !!label && <span className={classes.label}>{label}</span> }
            <textarea className={cx(classes.textarea, textAreaClassName, !!errors ? classes.inputError : '')} ref={ref} {...props}>
                {children}
            </textarea>
            { (!!errors && !props?.disabled) && <small className={classes.error}>{errors.message}</small> }
        </label>
    );
});

export default Textarea;

Checkbox.propTypes = {
    className: PropTypes.any,
    label: PropTypes.string,
    labelOn: PropTypes.string,
    disabled: PropTypes.bool,
    errors: PropTypes.object,
    isSmall: PropTypes.bool,
    type: PropTypes.oneOf(['checkbox', 'radio']),
};