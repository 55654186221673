import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import Spinner from "../../Commons/Spinner.js";
import StockInfosStats from "./StockInfosStats.js";
import SellingAnalyses from "./SellingAnalyses.js";
import OperationAnalyses from "./OperationAnalyses.js";
import MoneyFlows from "./MoneyFlows.js";
import SalesChannels from "./SalesChannels.js";
import StockPerformance from "./StockPerformance.js";
import CarStatusesAnalyses from "./CarStatusesAnalyses.js";
import {httpGetDashGeneralData,} from "../../HttpRequests/dashboard.http.js";
import {errorHandler} from "../../helpers/utils.js";
import {useDispatch} from "react-redux";

const useStyles = createUseStyles(theme => ({
    root: {
        display: "grid",
        gridTemplateColumns: `repeat(7, 1fr)`,
        gridGap: theme.spacing * 3
    },
    sellingAnalysesRoot:{
        background: theme.colors.white,
        borderRadius: 12,
        display: "grid",
        gridRow: "1 / span 5",
        gridColumn: "1 / span 4",
        padding: theme.spacing,
        overflow: 'hidden'
    },
    operationAnalysesRoot:{
        borderRadius: 12,
        background: theme.colors.white,
        display: "grid",
        gridRow: "6 / span 6",
        gridColumn: "1 / span 4",
        padding: theme.spacing,
        overflow: 'hidden'
    },
    moneyFlowsRoot:{
        borderRadius: 12,
        background: theme.colors.white,
        gridRow: "12 / span 5",
        gridColumn: "1 / span 4",
        display: "grid",
        padding: theme.spacing,
        overflow: 'hidden'
    },
    stockInfosRoot:{
        borderRadius: 12,
        overflow: 'hidden',
        display: "grid",
        gridRow: "1 / span 5",
        gridColumn: "5 / span 4",
    },
    salesChannelsRoot:{
        borderRadius: 12,
        background: 'linear-gradient(320deg, rgba(45,45,45,1) 0%, rgba(3,3,3,1) 80%)',
        display: "grid",
        gridRow: "6 / span 5",
        gridColumn: "5 / span 4",
    },
    stockPerformanceRoot:{
        borderRadius: 12,
        background: theme.colors.white,
        display: "grid",
        gridRow: "11 / span 4",
        gridColumn: "5 / span 4",
    },
    carStatusesAnalysesRoot:{
        borderRadius: 12,
        background: theme.colors.white,
        display: "grid",
        gridRow: "15 / span 3",
        gridColumn: "5 / span 4",
    },
}));

const Dashboard = ({selected}) => {
    const classes = useStyles({bigSubHeader: false});
    const [loading,setLoading] = useState(true);
    const [generalData, setGeneralData] = useState(null)
    const dispatch = useDispatch();

    useEffect(() => {
        if(selected){
            //console.log('refetch data dash here with new selected => ', selected);
            fetchGeneralData()
        }
    },[selected])

    const fetchGeneralData = async () => {
        setLoading(true)
        try {
            const {data} = await httpGetDashGeneralData(selected);
            //console.log(data)
            setGeneralData({
                tasksStats: data?.active_tasks,
                stockData: {...data?.average_days_in_stock,...data?.average_price,...data?.average_stock_rotation},
                saleChannelAnalysis: data?.sales_channel_analysis,
                stockPerformances:data?.stock_performances,
                carStatuses: data?.cars_statuses,
            });
        }catch (e){
            errorHandler(e, dispatch);
        }finally {
            setLoading(false)
        }
    }

    return <div className={classes.root}>
        {loading && <Spinner overlayFullscreen={true}/>}
        <div className={classes.sellingAnalysesRoot}>
            <SellingAnalyses selected={selected}/>
        </div>
        <div className={classes.operationAnalysesRoot}>
            <OperationAnalyses selected={selected}/>
        </div>
        <div className={classes.moneyFlowsRoot}>
            <MoneyFlows selected={selected}/>
        </div>

        <div className={classes.stockInfosRoot}>
            <StockInfosStats tasksStats={generalData?.tasksStats} stockData={generalData?.stockData}/>
        </div>
        <div className={classes.salesChannelsRoot}>
            {generalData && <SalesChannels saleChannelData={generalData?.saleChannelAnalysis}/>}
        </div>
        <div className={classes.stockPerformanceRoot}>
            <StockPerformance stockPerformancesData={generalData?.stockPerformances}/>
        </div>
        <div className={classes.carStatusesAnalysesRoot}>
            <CarStatusesAnalyses carStatusesData={generalData?.carStatuses}/>
        </div>
    </div>
};

export default Dashboard;
