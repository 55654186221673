import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useParams} from 'react-router-dom';
import Spinner from '../../Commons/Spinner';
import {useDispatch, useSelector} from 'react-redux';
import {setCurrentCar} from '../../store/actions/carsActions';
import {httpGetCar, httpGetCarAccessories} from '../../HttpRequests/commons.http.js';
import CarInfoForm from '../../Components/Barter/CarInfoForm';
import {errorHandler} from "../../helpers/utils.js";
import Popover from "../../Commons/Popover.js";
import {CarIcon, EuroIcon} from "../../assets/icons";
import TabCard from "../../Commons/TabCard";
import FiscalityForm from "../../Components/Barter/FiscalityForm";
import SubHeading from "../../Commons/SubHeading";
import SellPricingForm from "../../Components/Stock/SellPricingForm";
import WithdrawalPricingForm from "../../Components/Stock/WithdrawalPricingForm";
import SavedStock from "../../Components/Stock/SavedStock";
import Button from "../../Commons/Button";
import {alertToggle} from "../../store/actions/alertActions.js";
import {httpEditStockInfo} from "../../HttpRequests/stock.http.js";
import { Prompt } from 'react-router-dom'

const useStyles = createUseStyles(theme => ({
    gridContainer: {
        'position': 'relative',
        // with big/small subheader like permute page
        height: ({bigSubHeader}) => `calc(100vh - ${bigSubHeader ? theme.bigSubHeader + theme.headerHeight : theme.smallSubHeader + theme.headerHeight }px)`,
        // without subheader
        //height: `calc(100vh - ${theme.headerHeight}px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        [theme.mUp]: {
            padding: [theme.spacing * 4],
        },
        [theme.s]: {
            padding: [theme.spacing],
        },
    },
    card: {
        margin: [theme.spacing * 2, 0],
    },
    onTab: {
        zIndex: 3
    },
    btns: {
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: theme.spacing,
        width: 400,
        justifySelf: 'end'
    }
}));

const NewVehicleInfo = () => {
    const {id} = useParams(); //get id of car from url params react-router-dom
    const car = useSelector(state => state.cars.currentCar);
    const classes = useStyles({bigSubHeader: false}); // or false if type is small
    const [loading, setLoading] = useState(false);
    const [seeAll, setSeeAll] = useState(false);
    const dispatch = useDispatch();
    const userOperations = useSelector(state => state.users.user.operations);
    const isAdmin = useSelector(state => state.users.user.operations.admin);
    const canEditField = (isAdmin || userOperations?.can_read_car || userOperations?.can_edit_car)
    const [accessories, setAccessories] = useState(null);
    const [showSavedStockPopover, setShowSavedStockPopover] = useState(false);

    useEffect(() => {
        if (+car?.id !== +id) {
            //if the requested car is different from the stored one
            dispatch(setCurrentCar(null));
            getCarData();
        }
    }, []);

    const getCarData = async () => {
        setLoading(true);
        try {
            const {data: car} = await httpGetCar(id);
            const {data: accessories} = await httpGetCarAccessories(id);
            dispatch(setCurrentCar(car));
            setAccessories(accessories);
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
            //setShowSavedStockPopover(true)
        }
    };

    const saveNewCar = async () => {
        setLoading(true);
        const dataToSend = {
            car: {
                ...car,
                is_draft: false,
            },
            damages: [],
            photosets: []
        };
        try {
            const {data: car} = await httpEditStockInfo(dataToSend, true);
            //set barter and damages
            dispatch(setCurrentCar(car));
            //show saved popover
            setShowSavedStockPopover(true);
        } catch (e) {
            let message = "Sembra ci sia un errore di sistema. Riprova o contatta il supporto.";
            if (e.response.status === 500) return dispatch(alertToggle(message, 'error'));
            const errorObj = e.response.data;
            //horrible response from b-end here
            if (errorObj[0]?.car?.fiscality) {
                message = `Completa l'inerimento dei dati relativi alla fiscalità.`
            }else if(errorObj[0]?.car?.withdrawal_price){
                message = `Inserisci un prezzo d'acquisto.`
            }else{
                message = `Completa l'inserimento dei campi contrassegnati con l'asterisco.`
            }
            dispatch(alertToggle(message, 'error'));
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <SubHeading title={car ? `${car?.model?.brand?.name} - ${car?.model?.name}` : 'Nuovo veicolo'} type={'small'}
                        justifySelf={'end'} rightSearchComponent={<div className={classes.btns}>
                <Button data-secondary data-small
                        onClick={() => setSeeAll(!seeAll)}> {seeAll ? 'chiudi tutto' : 'apri tutto'} </Button>
                <Button data-small data-primary onClick={() => saveNewCar()}
                        disabled={false}> Salva veicolo</Button>
            </div>}/>
            <Prompt when={car?.status !== 'in_stock' }
                message='Uscendo senza aver prima salvato il veicolo, ogni modifica ed ilv eicolo stesso saranno eliminati'
            />

            {showSavedStockPopover &&
            <Popover withHeader={false} minWidth={400} width={400}
                     onClose={() => setShowSavedStockPopover(!showSavedStockPopover)}>
                <SavedStock onClose={() => setShowSavedStockPopover(!showSavedStockPopover)}/>
            </Popover>}

            <section className={classes.gridContainer}>
                {loading && <Spinner/>}
                {car && <>
                    <TabCard cardTitle={'Dati generali'} cardIcon={<CarIcon data-color/>} cardTitleSize={'medium'}
                             showContent={seeAll}>
                        <CarInfoForm isStock={true} car={car} canEditField={canEditField} accessories={accessories} isNewCar={true}/>
                    </TabCard>
                    <TabCard cardTitle={`Prezzo d'acquisto`} cardIcon={<EuroIcon data-color/>} cardTitleSize={'medium'}
                             showContent={seeAll}>
                        <WithdrawalPricingForm isStock={true} car={car} canEditField={canEditField} isNewCar={true}/>
                    </TabCard>
                    <TabCard cardTitle={'Fiscalità'} cardIcon={<EuroIcon data-color/>} cardTitleSize={'medium'}
                             showContent={seeAll}>
                        <FiscalityForm isStock={true} car={car} isNewCar={true}/>
                    </TabCard>
                    <TabCard cardTitle={'Prezzo di vendita'} cardIcon={<EuroIcon data-color/>} cardTitleSize={'medium'}
                             showContent={seeAll}>
                        <SellPricingForm isStock={true} car={car} isNewCar={true}/>
                    </TabCard>
                </>}
            </section>
        </>);
}

export default NewVehicleInfo;