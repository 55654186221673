import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {toMoney} from "../../helpers/utils.js";
import PropTypes from "prop-types";
import {ArrowDown, ArrowUp} from "../../assets/icons.js";

const useStyles = createUseStyles((theme) => ({
    root: {
        marginTop: theme.spacing * 6
    },
    card: {
        zIndex: 0,
        position: "relative",
        background: theme.colors.redLight,
        alignItems: "center",
        marginBottom: 4,
        borderRadius: ({expanded}) => expanded ? "16px 16px 0px 0px" : "16px",
        border: `1px solid ${theme.colors.grey}`,
        ...theme.shadow,
        overflow: "hidden"
    },
    header: {
        display: "grid",
        gridTemplateColumns: '0.5fr 1fr auto',
        columnGap: 16,
        padding: "16px",
        alignItems: 'center',
        ...theme.shadow,
    },
    body: {
        padding: "0 8px",
        margin: "0 8px 8px",
        gridRow: 2,
        gridColumn: "1 / span 5",
        background: theme.colors.smokeWhite,
        /* ...theme.shadow,*/
    },
    repair_row: {
        display: "grid",
        gridTemplateColumns: '0.5fr 1fr auto',
        borderBottom: `1px solid ${theme.colors.lightGrey}`,
        padding: [10, 0],
        fontSize: 12,
        columnGap: 6,
        textTransform: "lowercase",
        alignItems: 'center',
        '&::last-child': {
            borderBottom: "none",
        }
    },
    title: {
        fontWeight: 600,
        fontSize: 20,
    },
    rollback: {
        padding: [4],
        textTransform: 'uppercase',
        cursor: 'pointer'
    },
    actions: {
        ...theme.grid.actions
    },

}));

const DiscardedRepairs = ({list, rollbackCb}) => {
    const [expanded, setExpanded] = useState(false);
    const classes = useStyles({expanded});

    useEffect(() => {
        setExpanded(!!list.length)
    }, [list]);

    return <div className={classes.root}>
        <div className={classes.card}>
            <div className={classes.header}>
                <p className={classes.title}>Scartati</p>
                <span/>
                <div className={classes.actions}>
                    {!!list.length ? <span onClick={() => setExpanded(!expanded)}>
                        {expanded ? <ArrowUp/> : <ArrowDown/>}
                    </span> : <span/>}
                </div>
            </div>

            {expanded && <div className={classes.body}>
                {list?.map((item, index) => <div className={classes.repair_row} key={`${index}-a`}>
                    <span>{item.operation}</span>
                    <span>{toMoney(item.price)}</span>
                    {rollbackCb &&
                    <span className={classes.rollback} onClick={() => rollbackCb(item)}> Ripristina </span>}
                </div>)}
            </div>}
        </div>
    </div>

};

DiscardedRepairs.propTypes = {
    list: PropTypes.array.isRequired,
    rollbackCb: PropTypes.func,
};

export default DiscardedRepairs;
