import * as actionTypes from '../actions/bartersActions.js';

const initialState = {
    bartersList: [],
    currentBarter: null,
    damages: [], // current car damages
    damagesLiveSum: 0,
};

const bartersReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_BARTERS_LIST:
            return {
                ...state,
                bartersList: action.bartersList,
            };
        case actionTypes.SET_BARTER:
            return {
                ...state,
                currentBarter: action.barter,
            };
        case actionTypes.SET_DAMAGES:
            return {
                ...state,
                damages: action.damages,
                damagesLiveSum: action.damages.map(item => item.price).reduce((a, b) => a + b, 0)
            };
        case actionTypes.ADD_BARTER_PHOTO:
            return {
                ...state,
                currentBarter: {
                    ...state.currentBarter,
                    photosets: state.currentBarter.photosets.map( photoset => {
                        return action.photo.photoset_id === photoset.id ?
                            {
                                ...photoset,
                                modified: true,
                                photos: [
                                    ...photoset.photos,
                                    action.photo
                                ]
                            } : photoset
                    })

                }
            };
        case actionTypes.DELETE_BARTER_PHOTO:
            return {
                ...state,
                currentBarter: {
                    ...state.currentBarter,
                    photosets: state.currentBarter.photosets.map( photoset => {
                        return action.photo.photoset_id === photoset.id ?
                            {
                                ...photoset,
                                photos: photoset.photos.filter(photo => action.photo.id !== photo.id)
                            } : photoset
                    })

                }
            };
        default:
            return state;
    }
};



export default bartersReducer;
