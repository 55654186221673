import axios from 'axios';

//USERS
/**
 *
 * @param {Object} filters
 */

export const httpGetUsers = (filters = null) => {
	return axios.get('/users', { params: { ...filters } });
};

export const httpGetUser = (id) => {
	return axios.get(`/users/${id}`);
};

export const httpCreateUser = (data) => {
	const url = '/users';
	return axios.post(url, data);
};

export const httpUpdateUser = (userId,data) => {
	const url = `/users/${userId}`;
	return axios.put(url, data);
};

export const httpDeleteUser = (userId) => {
	const url = `/users/${userId}`;
	return axios.delete(url);
};

//UBICATIONS
export const httpGetUbications = (filters = null) => {
	return axios.get('/ubications', { params: { ...filters } });
};

export const httpGetUbication = (ubicationId) => {
	const url = `/ubications/${ubicationId}`;
	return axios.get(url);
};

export const httpCreateUbication = (data) => {
	const url = '/ubications';
	return axios.post(url, data);
};

export const httpUpdateUbication = (data, ubicationId) => {
	const url = `/ubications/${ubicationId}`;
	return axios.put(url, data);
};

export const httpDeleteUbication = (ubicationId) => {
	const url = `/ubications/${ubicationId}`;
	return axios.delete(url);
};

//BUSINESS
export const httpGetBusinessList = (filters = null) => {
	return axios.get('/companies', { params: { ...filters } });
};

export const httpCreateBusiness = (data) => {
	const url = '/companies';
	return axios.post(url, data);
};

export const httpUpdateBusiness = (data, businessId) => {
	const url = `/companies/${businessId}`;
	return axios.put(url, data);
};

export const httpDeleteBusiness = (businessId) => {
	const url = `/companies/${businessId}`;
	return axios.delete(url);
};

//MISC
export const httpGetUsersPermissions = () => {
	return axios.get('/operations/autocomplete');
};

export const httpGetTaskTypesPhysical = () => {
	return axios.get('/tasks/autocomplete?type=physical');
};

export const httpGetTaskTypesBackoffice = () => {
	return axios.get('/tasks/autocomplete?type=backoffice');
};

export const httpEditDamageDefaultValue = (id, price) => {
	return axios.put(`/settings/damages/${id}`, { price: +price });
};
