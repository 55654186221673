import React, {useState} from 'react';
import SearchStep from "./SearchStep.js";
import EurotaxStep from "./EurotaxStep.js";
import IndicataStep from "./IndicataStep.js";
import AlreadySet from "./AlreadySet.js";
import NotFoundMultiStep from "./NotFoundMultiStep.js";
import {useHistory} from 'react-router-dom';
import ManualInsertStep from "./ManualInsertStep.js";

const NewBarterStepsModal = ({onClose}) => {
    /**
     * we got multiple step to identify the main component to render
     * - search
     * - errorDat
     * - successEurotax
     * - successIndicata
     * - alreadySet
     * - manualInsert
     */
    const history = useHistory();
    const [step, setStep] = useState('search'); //search
    const [carId, setCarsId] = useState(null);
    const [plate, setPlate] = useState(null);
    const [km,setKm] = useState(null);
    const [alreadySetMessage, setAlreadySetMessage] = useState('');

    const renderStep = () => {
      switch (step) {
          case 'successEurotax':
              return <EurotaxStep carId={carId} onVersionChoiceCb={() => setStep('successIndicata')}/>;
          case 'successIndicata':
              return <IndicataStep carId={carId} onVersionChoiceCb={() => history.push(`/barters/${carId}`)}/>;
          case 'datStep':
              return <NotFoundMultiStep setStep={setStep} setCarsId={setCarsId} plate={plate} mileage={km}/>;
          case 'manualInsert':
              return <ManualInsertStep setStep={setStep} plate={plate} mileage={km} onClose={onClose}/>;
          case 'alreadySet':
              return <AlreadySet onClose={onClose} plate={plate} km={km} message={alreadySetMessage}/>;
          default:
              return <SearchStep setStep={setStep} setCarsId={setCarsId} setKm={setKm} setAlreadySetMessage={setAlreadySetMessage} setPlate={setPlate}/>
      }
    };

    return renderStep()
};

export default NewBarterStepsModal;