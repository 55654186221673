import React, {useState,useEffect} from 'react';
import {createUseStyles} from "react-jss";
import {BartersActionsIcon, BartersProposalsIcon} from "../../assets/icons.js";
import Popover from "../../Commons/Popover.js";
import EurotaxStep from "../Barters/EurotaxStep.js";
import IndicataStep from "../Barters/IndicataStep.js";
import {useDispatch} from "react-redux";
import {setCurrentBarter} from "../../store/actions/bartersActions.js";
import {errorHandler} from "../../helpers/utils.js";
import {httpGetBarterActionsHistory, httpGetBarterProposalsHistory} from "../../HttpRequests/barters.http.js";
import BarterHistoryLogs from "./BarterHistoryLogs.js";

const useStyles = createUseStyles(theme => ({
    root: {
        position: 'relative',
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '1fr 1fr',
        maxWidth: 500,
        gridColumnGap: theme.spacing * 2,
        justifyItems: 'start',
        [theme.s]: {},
    },
    overview: {
        display: 'grid',
        alignItems: 'start',
        gridTemplateColumns: 'auto 1fr',
        gridGap: theme.spacing,
        '& svg':{
            width: 20
        },
    },
    infos: {
        display: 'grid',
        gridGap: theme.spacing * 2,
        '& p':{

        },
        '& strong':{
            fontWeight: 600,
            color: theme.colors.black,
            textDecoration: 'underline',
            cursor: 'pointer',

        }
    },
}));

const BarterHystories = ({id,plate, status}) => {
    const classes = useStyles();
    const [showPopover, setShowPopover] = useState(false);
    const [popoverType, setPopoverType] = useState('actions');
    const [actionsHistory, setActionsHistory] = useState([]);
    const [proposalsHistory, setProposalsHistory] = useState([]);
    const isTradeIn = status === 'trade_in';
    const dispatch = useDispatch();

    useEffect(() => {
        getPopoverInfos()
    },[]);

    const getPopoverInfos = async () => {
        try{
            const {data: actionsHistory} = await httpGetBarterActionsHistory(id);
            setActionsHistory(actionsHistory);
            const {data: proposalsHistory} = await httpGetBarterProposalsHistory(plate);
            setProposalsHistory(proposalsHistory)
        }catch (e){
            errorHandler(e, dispatch)
        }
    }

    const renderServiceModal = () => {
        if(popoverType === 'actions'){
            return <BarterHistoryLogs historyType={'actions'} logs={actionsHistory}/>
        }else{
            return <BarterHistoryLogs historyType={'proposals'} logs={proposalsHistory}/>
        }
    };

    return <div className={classes.root}>
        {showPopover && <Popover withHeader={false} minWidth={700} onClose={() => setShowPopover(!showPopover)} contextMode={false}>
            {renderServiceModal()}
        </Popover>}

        {!!actionsHistory.length && <div className={classes.overview}>
           <BartersActionsIcon/>
           <div className={classes.infos}>
               <p>Persone che hanno modificato questo preventivo</p>
               <strong onClick={() => {setPopoverType('actions'); setShowPopover(!showPopover)}}><p>Vedi storico</p></strong>
           </div>
       </div>}

        {((isTradeIn && !!proposalsHistory.length) || (proposalsHistory.length > 1 && !isTradeIn)) && <div className={classes.overview}>
            <BartersProposalsIcon/>
            <div className={classes.infos}>
                {proposalsHistory.length > 1 ? <p>{proposalsHistory.length} proposte di permuta precedenti su questa targa</p> :
                    <p>1 proposta di permuta già esistente su questa targa.</p>
                }
                <strong onClick={() => {setPopoverType('proposals'); setShowPopover(!showPopover)}}><p>Vedi storico</p></strong>
            </div>
        </div>}
    </div>
};

export default BarterHystories;