import React, { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import theme from '../theme';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import cx from 'classnames';
const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

const useStyles = createUseStyles((theme) => ({
	ToolTip: {
		border: "1px solid",
		display: "inline-block",
		padding: "2px 8px",
		borderColor: theme.colors.lightGrey,
		borderRadius: 8,
		backgroundColor: theme.spacing.white,
		fontSize: 12
	},
	root:{
		display: "grid",
		gridRowGap: theme.spacing,
	},
	label: theme.control.label,
}));

const RangeWrapper = forwardRef(({unit, showTips = false, showMarks = true, marks, step, min, max, defaultValue, classNames,
			trackColor, railColor, label, tips = false,disabled, ...props}, ref) => {

		const classes = useStyles({});

		return <div className={cx(classNames, classes.root)}>
			{!!label && <span className={classes.label}>{label}</span>}
			<Controller
				name={props.name}
				control={props.control}
				defaultValue={defaultValue}
				as={
					<Range marks={marks} step={step} min={min} max={max} defaultValue={defaultValue}
						   disabled={disabled}
						onChange={([ , value ]) => value} tipFormatter={(value) => `${unit} ${value}`}
						tipProps={{
							prefixCls: classes.ToolTip,
							placement: 'bottom',
							visible: showTips
						}}
						tabIndex={[ 0, 200 ]}
						trackStyle={[
							{
								backgroundColor: trackColor ? trackColor : theme.colors.red,
								height: 4
							},
							{
								backgroundColor: trackColor ? trackColor : theme.colors.red,
								height: 4,
								borderRadius: 4
							}
						]}
						handleStyle={[
							{
								visibility: 'block',
								width: 8,
								height: 16,
								border: `1px solid ${theme.colors.grey}`,
								borderRadius: 8,
							},
							{
								visibility: 'block',
								width: 8,
								height: 16,
								border: `1px solid ${theme.colors.grey}`,
								borderRadius: 8,
							},
							{
								visibility: 'visible',
								borderRadius: '1px',
								width: '5px',
								border: '1px solid #666',
								backgroundColor: theme.colors.white,
								marginTop: '-3px'
							}
						]}
						dotStyle={
							showMarks && {
								borderColor: '#333',
								borderWidth: 'thin',
								borderRadius: '0',
								width: 0,
								height: '5px',
								marginLeft: '-2px',
								bottom: '20px'
							}
						}
						activeDotStyle={{
							borderColor: '#f00'
						}}
						railStyle={{
							backgroundColor: theme.colors.smokeWhite,
							height: 4,
							borderRadius: 4
						}}
					/>
				}
			/>
		</div>
	}
);

RangeWrapper.propTypes = {
	name: PropTypes.string, //name of the field
	//unit: PropTypes.string, //string
	showTips: PropTypes.bool, //show/hide fixed indicators of active values, default false
	showMarks: PropTypes.bool, //show/hide indicators at the bottom of the scale, default false
	marks: PropTypes.object, //interval of the indicators at the bottom of the scale
	step: PropTypes.number, //scale of values step for each slide move
	min: PropTypes.number, //min value
	max: PropTypes.number, //max value
	defaultValue: PropTypes.array, //array of default given values (defauls as min and max if not specified) - same of controller in react-hook-form
	trackColor: PropTypes.string, //hex for the active track //default: theme.colors.red
	railColor: PropTypes.string, //hex for the background track //default: theme.colors.grey
	tips: PropTypes.bool //show/hide tips, default false
};
export default RangeWrapper;
