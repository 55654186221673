import React, {useState} from 'react';
import {createUseStyles} from "react-jss";
import {useForm} from "react-hook-form";
import Spinner from "../../Commons/Spinner.js";
import Text from "../../Commons/Text.js";
import Button from "../../Commons/Button.js";
import * as Yup from "yup";
import {useDispatch} from "react-redux";
import {alertToggle} from "../../store/actions/alertActions.js";
import {httpEditCarInfo} from "../../HttpRequests/barters.http.js";
import {errorHandler} from "../../helpers/utils.js";
import {setCurrentBarter} from "../../store/actions/bartersActions.js";

const useStyles = createUseStyles(theme => ({
    root: {
        display: 'grid',
        width: '100%',
        gridRowGap: theme.spacing * 3,
        justifyItems: 'center',
        '& button': {
            marginTop: theme.spacing * 3
        }
    },
    title: {
        fontWeight: 600,
        margin: [theme.spacing * 2, 0]
    },
    btns: {
        marginTop: theme.spacing * 2,
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: theme.spacing,
        width: 300,
    }
}));

const validationSchema = Yup.object().shape({
    eurotax_version: Yup.string()
        .required(`Inserisci la versione Eurotax.`),
});

const ForceEurotaxVersion = ({onClose, car}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const {register, handleSubmit, errors} = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            eurotax_version: '',
        },
        validationSchema
    });

    const onSubmit = async (values) => {
        setIsLoading(true);
        if ((values.eurotax_version === null || values.eurotax_version === '') || car['eurotax_version']?.toString() === values.eurotax_version) return;
        const dataToSend = {
            car: {
                id: car.id,
                eurotax_version: values.eurotax_version,
                is_draft: true,
            }
        };
        try {
            const {data} = await httpEditCarInfo(dataToSend);
            dispatch(setCurrentBarter(data));
            dispatch(alertToggle(`Il campo Versione Eurotax è stato aggiornato correttamente.`, 'success'));
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setIsLoading(false);
            onClose();
        }
    };

    return <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <h4 className={classes.title}> Inserisci la versione eurotax </h4>
        {isLoading && <Spinner/>}
        <Text type={'text'} label={'Versione eurotax'} name={'eurotax_version'} ref={register}
              errors={errors.eurotax_version}/>
        <div className={classes.btns}>
            <Button type={'button'} data-secondary data-small onClick={onClose}> Chiudi </Button>
            <Button type={'submit'} data-primary data-small> Salva </Button>
        </div>
    </form>
};

export default ForceEurotaxVersion;