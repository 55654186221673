import React, {useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {DoneIcon} from "../../assets/icons.js";
import Spinner from "../../Commons/Spinner";
import Button from "../../Commons/Button";
import Text from "../../Commons/Text";
import {httpResetPasswordRequest} from "../../HttpRequests/user.http";
import Back from "../../Commons/Back.js";
import * as Yup from "yup";
import {setErrors} from "../../helpers/utils.js";

const useStyles = createUseStyles(theme => ({
    root: {
        position: 'relative',
        'max-width': 446,
        'margin': [theme.spacing * 4, 'auto'],
        'display': 'grid',
        'grid-auto-flow': 'row',
        'grid-row-gap': theme.spacing * 2 + 'px',
        [theme.s]: {
            width: 320,
            padding: [theme.spacing * 4, theme.spacing * 3],
            gridTemplateColumns: 280,
            justifyContent: "center",
        },
        [theme.mUp]: {
            'width': '100%',
            'padding': [theme.spacing * 4],
            gridTemplateColumns: 350,
            justifyContent: "center",
        },
        '& button':{
            marginTop: theme.spacing*4
        }
    },
    title: {
        fontWeight: 500,
        textAlign: "left",
        margin: [theme.spacing * 3, 0 ,theme.spacing *2 , 0],
        color: theme.colors.black,
        [theme.s]: {
            fontSize: 28,
        },
        [theme.mUp]: {
            fontSize: 38,
        },
    },
    done: {
        width: "60px!important",
        fill: `${theme.colors.green}!important`
    },
    link: {
        ...theme.link,
        textAlign: 'center',
    },
    error: {
        'text-align': 'center',
        'color': theme.colors.red,
    },
    contact: {
        fontWeight: "300",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.14",
        letterSpacing: "normal",
        textAlign: "center",
        color: theme.colors.black,
        [theme.s]: {
            fontSize: 18,
        },
        [theme.mUp]: {
            fontSize: 24,
        },
    },
    subtitle: {
        margin: [theme.spacing *2, 0, theme.spacing * 2, 0],
        'color': theme.colors.darkGrey,
    },
}));

const validationSchema =Yup.object().shape({
    email: Yup.string()
        .email(`L'email inserita non è un email valida`)
        .required(`Inserisci l'email.`),
});

const RecoverPassword = () => {
    const classes = useStyles();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const {register, handleSubmit, errors,setError} = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            email: "",
        },
        validationSchema
    });

    const onSubmit = async values => {
        try {
            setIsLoading(true);
            await  httpResetPasswordRequest(values);
            setSuccess(true);
        } catch (error) {
            if(error?.response?.data.message){
                const message = error.response ? error.response?.data.message : error.message ? error.message : 'Errore di sistema. Ricarica la pagina e riprova.';
                setErrorMessage(message)
                //call setAlert action here if needed to show a snackbar
            }else{
                setErrors(error.response.data,setError);
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {success &&
            <div className={classes.root}>
                <DoneIcon className={classes.done}/>
                <h2 className={classes.title}>Operazione completata</h2>
                <p className={classes.subtitle} style={{marginBottom: '24px'}}>
                    Ti verra inviata un email con le istruzioni da seguire! <br/>
                    Controlla la tua casella di posta.
                </p>

                <Button data-primary onClick={() => history.push('/auth/login')} style={{zIndex: 2}}> Torna al Login </Button>
            </div>}

            {!success &&
            <form className={classes.root} noValidate={true} onSubmit={handleSubmit(onSubmit)}>
                {isLoading && <Spinner/>}
                <Back to={'/auth/login'}/>
                <div>
                    <h2 className={classes.title} >Hai dimenticato la password?</h2>
                    <p className={classes.subtitle}>Inserisci il tuo indirizzo email. Riceverai una mail contenente un link per creare una nuova password.</p>
                </div>

                <Text type={'text'} label={'Email'} name={'email'} ref={register} errors={errors.email} style={{zIndex: 2}}/>

                <Button data-primary type={'submit'} style={{zIndex: 2}}> Conferma </Button>
                {!!errorMessage && <p className={classes.error}>{errorMessage}</p>}
            </form>
            }
        </>
    );
};

export default RecoverPassword;
