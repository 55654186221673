import React from 'react';
import {createUseStyles} from 'react-jss';
import Button from '../../Commons/Button.js';
import {toMoney} from "../../helpers/utils.js";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";

const useStyles = createUseStyles(theme => ({
    footer: {
        left: "0",
        right: "0",
        bottom: "0",
        height: 90,
        position: "fixed",
        alignItems: "center",
        boxShadow: "rgba(23, 29, 41, 0.08) 0px 0px 12px 0px",
        zIndex: 2,
        background: theme.colors.white,
        display: 'grid',
        gridTemplateColumns: '1fr 1px 1fr 1px 1fr 1px 1fr 1fr',
        margin: '0 auto',
        padding: [0, '10%'],
        gridColumnGap: theme.spacing * 2,
        '& button': {
            marginRight: 50,
            display: 'grid',
            justifyItems: 'center'
        },
    },
    divisor: {
        background: theme.colors.lightGrey,
        width: 1,
        height: '70%'
    },
    data: {
        '& h4': {
            fontWeight: 600
        }
    }
}));

const RestoreSummaryFooter = ({firstEstimate}) => {
    const classes = useStyles({bigSubHeader: false});
    const {id} = useParams();
    //totals
    const allRepairsTotalAmount = useSelector(state => state.repairs.allRepairsTotalAmount);
    const allRepairsNegotiatedAmount = useSelector(state => state.repairs.allRepairsNegotiatedAmount);
    const allRepairsInvoiceAmount = useSelector(state => state.repairs.allRepairsInvoiceAmount);

    return <div className={classes.footer}>
        <div className={classes.data}>
            <h4>{toMoney(firstEstimate?.total_amount)}</h4>
            <p> Stato d'uso </p>
        </div>
        <span className={classes.divisor}/>
        <div className={classes.data}>
            <h4>{toMoney(allRepairsTotalAmount)}</h4>
            <p> Perizie </p>
        </div>
        <span className={classes.divisor}/>
        <div className={classes.data}>
            <h4>{toMoney(allRepairsNegotiatedAmount)}</h4>
            <p> Affidamento lavori </p>
        </div>
        <span className={classes.divisor}/>
        <div className={classes.data}>
            <h4>{toMoney(allRepairsInvoiceAmount)}</h4>
            <p> Spesa finale </p>
        </div>
        <a href={`${process.env.REACT_APP_ENDPOINT}/cars/${id}/reconditioning_pdf`} target={'_black'} data-anchor-unstyled>
            <Button data-primary width={200}> Stampa </Button>
        </a>
    </div>

};


export default RestoreSummaryFooter
