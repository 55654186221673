import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {BusinessIcon, StatsIcon} from "../../assets/icons.js";

const useStyles = createUseStyles(theme => ({
    root: {
        position: 'relative',
    },
    heading:{
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        height: 48,
        alignItems: "center",
        padding: theme.spacing,
        gridColumnGap: theme.spacing,
        '& svg':{
            fill: theme.colors.black
        },
        '& b':{
            fontSize: 14
        }
    },
    item: {
        display: "grid",
        alignItems: "center",
        gridTemplateColumns: "1fr auto",
        gridColumnGap: "16px",
        padding: [theme.spacing, theme.spacing * 2],
        '& strong':{
            fontSize: 14
        }
    }
}));

const CarStatusesAnalyses = ({carStatusesData}) => {
    const classes = useStyles();

    return <div className={classes.root}>
        <div className={classes.heading}>
            <StatsIcon data-color/>
            <b>Analisi per stato dell'auto</b>
        </div>
        <div className={classes.item}>
            <strong>In stock</strong>
            <strong>{carStatusesData?.in_stock}</strong>
        </div>
        <div className={classes.item}>
            <strong>Vendute</strong>
            <strong>{carStatusesData?.sold}</strong>
        </div>
        <div className={classes.item}>
            <strong>In trattativa</strong>
            <strong>{carStatusesData?.negotiating}</strong>
        </div>
        <div className={classes.item}>
            <strong>Consolidate</strong>
            <strong>{carStatusesData?.to_retrieve}</strong>
        </div>
        <div className={classes.item}>
            <strong>Da consegnare</strong>
            <strong>{carStatusesData?.to_be_delivered}</strong>
        </div>
    </div>
};

export default CarStatusesAnalyses;
