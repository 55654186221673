import React from 'react';
import {createUseStyles} from "react-jss";
import Button from "../../Commons/Button.js";
import done from '../../assets/images/done.svg';
import {useHistory} from 'react-router-dom';

const useStyles = createUseStyles(theme => ({
    root: {
        display: 'grid',
        width: '100%',
        gridRowGap: theme.spacing * 3,
        justifyItems: 'center'
    },
    title:{
        fontWeight: 600,
        margin: [theme.spacing * 2, 0],
        display: 'grid',
        alignItems: 'center',
        gridColumnGap: theme.spacing,
        gridTemplateColumns: '1fr auto',
        textAlign: 'center',
        '& img':{
            marginBottom: theme.spacing,
            width: 72
        }
    },
    btns: {
        marginTop: theme.spacing * 2,
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: ({isDraft}) => isDraft ? '1fr': '1fr 1fr',
        gridColumnGap: theme.spacing,
        width: ({isDraft}) => isDraft ? 'auto' : 300,
    }
}));

const SavedBarter = ({onClose,isDraft,barterId}) => {
    const classes = useStyles({isDraft});
    const history = useHistory();

    return <div className={classes.root}>
        <img src={done} alt={'done'}/>
        <h4 className={classes.title}> {isDraft ? 'Bozza della permuta salvata.' : 'Permuta salvata correttamente'} </h4>
        <div className={classes.btns}>
            <Button type={'button'} data-secondary data-small onClick={onClose}> Chiudi </Button>
            {!isDraft && <Button type={'button'} data-primary data-small onClick={() => history.replace(`/barters-recap/${barterId}`)}> Vedi riepilogo </Button>}
        </div>
    </div>
};

export default SavedBarter;