import React from 'react';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import cx from 'classnames';

const useStyles = createUseStyles((theme) => ({
	baseStyles: {
		position: 'grid',
		gridTemplateColumns: '1fr',
		gridTemplateRows: 'repeat(2, 1fr)',
		/* display: 'inline-block', */
		boxShadow: 'rgba(23, 29, 41, 0.08) 0px 0px 12px 0px',
		background: theme.colors.white,
		margin: theme.spacing * 2,
		borderRadius: 16,
		boxSizing: 'border-box',
		verticalAlign: 'middle',
		/*overflow: 'hidden',*/
	},
	cardHeader: {
		display: 'grid',
		gridTemplateColumns: ({ actions }) => (actions ? '32px 1fr 0.3fr' : '32px 1fr'),
		textAlign: 'left',
		width: '100%',
		borderBottom: `1px solid ${theme.colors.lightGrey}`,
		padding: [ theme.spacing * 2 ],
		fontWeight: 'bold',
		alignItems: 'center',
		fontSize: ({ cardTitleSize }) => (cardTitleSize === 'small' ? 14 : cardTitleSize === 'medium' ? 18 : 26),
		'& :first-child': {
			/* justifySelf: 'center' */
		},
		'& svg': {
			fill: ({ cardIconFill }) => `${cardIconFill} !important`,
			height: 20,
			width: 20
		}
	},
	baseContent: {
		padding: ({ cardSpacing }) => theme.spacing * +cardSpacing,
		gridArea: '2 / 1 / 3 / 2',
		verticalAlign: 'middle'
	}
}));

const Card = ({
	children,
	cardIcon,
	cardIconFill,
	cardTitle,
	cardTitleSize = 'small',
	cardSpacing = 2,
	actions = null,
	rootCustomClass,
	bodyCustomClass
}) => {
	const classes = useStyles({ cardTitleSize, cardIconFill, cardSpacing, actions });
	return (
		<div className={cx(rootCustomClass, classes.baseStyles)}>
			{cardTitle && (
				<div className={classes.cardHeader}>
					{cardIcon}
					<span>{cardTitle}</span>
					{actions}
				</div>
			)}
			<div className={cx(bodyCustomClass, classes.baseContent)}>{children}</div>
		</div>
	);
};

/*
USAGE:
<Card cardTitle={'Some Title'} cardTitleSize={'medium'}>Content .... </Card>
other settable props:
cardIcon={<SomeSvgComponent />} //param: component
cardIconFill={'#f00'} //param: string (hex color val)
cardTitle={'Some Title'} //param: string
cardTitleSize={'small'} ////param: string, available 'small', 'medium' or 'large' //default: 'small'
*/

Card.propTypes = {
	children: PropTypes.any,
	cardTitle: PropTypes.any,
	cardIcon: PropTypes.any,
	cardIconFill: PropTypes.string,
	cardTitleSize: PropTypes.oneOf([ 'small', 'medium', 'large' ]),
	cardSpacing: PropTypes.number
};

export default Card;
