export const ACCEPTABLE_IMAGE_TYPES = ['jpeg', 'jpg'];
export const ACCEPTABLE_VIDEO_TYPES = ['mp4'];
export const ACCEPTABLE_DOC_TYPES = ['pdf', 'word', 'xls'];
export const ACCEPTABLE_FILE_TYPE = [...ACCEPTABLE_IMAGE_TYPES, ...ACCEPTABLE_VIDEO_TYPES];
export const MESSAGE_TYPE_TEXT = 1;
export const MESSAGE_TYPE_FILE = 2;
export const MESSAGE_TYPE_IMAGE = 3;
export const MESSAGE_TYPE_VIDEO = 4;
export const TYPING_INDICATOR_DURATION_SECONDS = 5;
export const SHOW_TYPING_INDICATOR = "ti_show";
export const HIDE_TYPING_INDICATOR = "ti_hide";
export const DIRECT_CHANNEL_TYPE = 'direct';
export const COMMUNICATIONS_CHANNEL_TYPE = 'communications';
