import axios from 'axios';

/**
 * get barters
 * @param {Object} filters
 */
export const httpGetBarters = (filters = null) => {
	return axios.get('/cars/trade_in', {params: { ...filters }});
};

/**
 * search and create new barter
 * @param plate
 * @param km
 * @param update
 */
export const httpCarsIdentify = ({plate,km,update}) => {
	const url = (update !== undefined) ? `/cars/identify/${plate}?update=${update}` : `/cars/identify/${plate}`;
	return axios.get(url, {params: {km:km}});
};

/**
 * search and create new barter
 * @param plate
 * @param km
 * @param update
 */
export const httpNewCarsIdentify = ({plate,km,update}) => {
	const url = `/cars/identify/${plate}?&direct_acquisition=true`;
	return axios.get(url, {params: {km:km}});
};


/**
 * get eurotax and indicata versions
 * @param {Object} car_id
 * @param service string name of the service used for evaluation
 */
export const httpCarsVersions = (car_id,service) => {
	return axios.get(`/cars/versions/${car_id}/${service}`);
};

/**
 * set eurotax and indicata version choosed
 * @param {Object} car_id
 * @param service string name of the service used for evaluation
 * @param value string identifier of the choosed version
 */
export const httpSetCarServiceVersion = (car_id,service,value) => {
	const car = service === 'eurotax' ? {eurotax_version: value} : {indicata_version: value};
	return axios.put(`/cars/${car_id}`, {car:{...car,is_draft: true}} );
};

/**
 * get dat multistep process
 */
export const httpInitDatMultistep = (data) => {
	return axios.post(`/cars/selections`, data);
};

/**
 * car manual creation
 */
export const httpCreateCar = (data) => {
	return axios.post(`/cars`, {...data} );
};

/**
 * choose the correct car version returned by dat multistep
 */
export const httpSetDatCarVersion = (data) => {
	return axios.post(`/cars/selections/create`, data );
};

/**
 * edit a car field
 */
export const httpEditCarInfo = (data, isNewVehicle = false) => {
	return axios.put(isNewVehicle ? `/cars/${data.car.id}?direct_acquisition=true` : `/cars/${data.car.id}`, {...data});
};

/**
 * fetch the buyback field
 */
export const httpBuyBack = (id) => {
	return axios.get(`/cars/${id}/buyback_price`);
};

/**
 * change the summary
 */
export const httpExaminationsSummary = (id,data) => {
	return axios.post(`/cars/${id}/examinations/summary`, data);
};


/**
 * Store photo
 */
export const httpConfirmPhotoUpload = (carId, uploadUrl, type, photosetId) => {
	const url = `/cars/${carId}/photosets/${photosetId}/photos`;
	return axios.post(url, {
		url: uploadUrl,
		type: type,
		photoset_id: photosetId
	});
};


/**
 * Delete photo
 */
export const httpDeletePhoto = (carId, photosetId, photoId) => {
	const url = `/cars/${carId}/photosets/${photosetId}/photos/${photoId}`;
	return axios.delete(url);
};


/**
 * Get barter photo
 */
export const getBarterPhoto = (carId, photosetId, photoId) => {
	const url = `/cars/${carId}/photosets/${photosetId}/photos/${photoId}`;
	return axios.get(url);
};

/**
* Edit barter price or confirm barter price
 */
export const httpConfirmBarterPrice = (data) => {
	const url = `/cars/${data.id}/counteroffer`;
	return axios.post(url, {price: data.price, note: data.note ? data.note : ''});
};

/**
 * Get barter actions history
 */
export const httpGetBarterActionsHistory = (id) => {
	const url = `/cars/${id}/action_history`;
	return axios.get(url);
};

/**
 * Get barter proposal history
 */
export const httpGetBarterProposalsHistory = (plate) => {
	const url = `/cars/current_offers?plate=${plate}`;
	return axios.get(url);
};