import React from 'react';
import {createUseStyles} from "react-jss";
import Button from "../../Commons/Button.js";
import alert from '../../assets/images/alertTriangle.svg'

const useStyles = createUseStyles(theme => ({
    root: {
        display: 'grid',
        width: '100%',
        gridRowGap: theme.spacing * 3,
        justifyItems: 'center'
    },
    results:{
        fontSize: 16,
        marginTop: theme.spacing * 4,
        textAlign: 'center'
    },
}));

const ConfirmExamination = ({callback}) => {
    const classes = useStyles();

    return <div className={classes.root}>
        <img src={alert} alt={'alert'}/>
        <p className={classes.results}> Cosi facendo confermerai la valutazione. Potrai sempre annullarla in seguito, ed inserire manualmente i danni.</p>
        <Button type={'button'} data-primary data-small onClick={() => callback()}> Conferma </Button>
    </div>
};

export default ConfirmExamination;