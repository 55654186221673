import React from 'react';
import {createUseStyles} from "react-jss";
import PropTypes from "prop-types";
import cx from 'classnames';

const useStyles = createUseStyles(theme => ({
    root: {
        'background-image': ({image}) => `url(${image})`,
        'background-repeat': 'no-repeat',
        'background-position': 'center',
        'background-size': ({variant}) => variant
    },
}));

const Media = props => {
    const {className, image, variant, children, ...rest} = props;
    const classes = useStyles({image, variant});

    return (
        <div className={cx(classes.root, className)} {...rest}>
            {children}
        </div>
    );
};

Media.defaultProps = {
    variant: 'cover',
}

Media.propTypes = {
    image: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(['cover', 'contain', 'fill'])
}

export default Media;
