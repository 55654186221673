import Badge from "../../Commons/Badge.js";
import React from "react";
import { createUseStyles } from 'react-jss';
import CircularProgress from "../../Commons/CircularProgress.js";
import {AlertErrorIcon} from "../../assets/icons.js";
import ReactTooltip from "react-tooltip";

const useStyles = createUseStyles((theme) => ({
    root: {
        display: "grid",
        marginTop: theme.spacing,
        gridColumnGap: theme.spacing,
        gridAutoFlow: "column",
        gridAutoColumns: "max-content"
    },
    salesforceError:{
        backgroundColor: theme.colors.red,
        width: "22px",
        height: "22px",
        borderRadius: "22px",
        textAlign: "center",
        paddingTop: 1,
        '& svg':{
            fill: theme.colors.white,
            width: `14px !important`
        }
    }
}));

const StockBadges = ({data}) => {
    const classes = useStyles();
    const type = data?.type;
    const inStock = ['in_stock', 'booked', 'sold'].includes(data?.status);
    const delivered = data?.status === 'delivered';
    const sold = data?.status === 'sold';
    const booked = data?.status === 'booked';
    const completed = data?.status === 'trade_completed';
    const toRetrieve = data?.status === 'to_retrieve';
    const tradeIn = data?.status === 'trade_in';
    const negotiating = data?.status === 'negotiating';
    const carPercentage = data?.closed_tasks_percentage ? +data.closed_tasks_percentage : 0;

    return <div className={classes.root}>
        {carPercentage > 0 && <CircularProgress size={20} value={carPercentage} valuePosition={'right'}/>}
        {type && <Badge title={type} statusColor={'xLightGrey'} badgeSize={'small'} badgeRadius={4} />}
        {inStock && <Badge title={'In stock'} statusColor={'xLightGrey'} badgeSize={'small'} badgeRadius={4} />}
        {completed && <Badge title={'Completata'} statusColor={'xLightGrey'} badgeSize={'small'} badgeRadius={4} />}
        {toRetrieve && <Badge title={'In Ritiro'} statusColor={'xLightGrey'} badgeSize={'small'} badgeRadius={4} />}
        {tradeIn && <Badge title={'Permuta'} statusColor={'xLightGrey'} badgeSize={'small'} badgeRadius={4} />}
        {negotiating && <Badge title={'Trattativa'} statusColor={'xLightGrey'} badgeSize={'small'} badgeRadius={4} />}
        {delivered && <Badge title={'Consegnata'} statusColor={'black'} badgeSize={'small'} badgeRadius={4} />}
        {sold && <Badge title={'Venduta'} statusColor={'black'} badgeSize={'small'} badgeRadius={4} />}
        {booked && <Badge title={'Opzionata'} statusColor={'danger'} badgeSize={'small'} badgeRadius={4} />}
        {
            data?.salesforce_failure && <>
                <span data-tip data-for='alert' className={classes.salesforceError}> <AlertErrorIcon data-color/> </span>
                <ReactTooltip id="alert" place="top" type="dark" effect="solid" >
                    <small style={{fontSize: 12, maxWidth: 240, textAlign: "center"}}>Errore nella connessione con Salesforce. La segnalazione è stata inviata al reparto tecnico</small>
                </ReactTooltip>
            </>
        }
    </div>
};

export default StockBadges;