import React, {useState} from 'react';
import {createUseStyles} from 'react-jss';
import {errorHandler, toMoney} from "../../helpers/utils.js";
import {useDispatch} from "react-redux";
import {ArrowDown, ArrowUp, DoneIcon, StatsIcon} from "../../assets/icons.js";
import Card from "../../Commons/Card.js";
import Button from "../../Commons/Button.js";
import Popover from "../../Commons/Popover.js";
import EditWithdrawalPrice from "./EditWithdrawalPrice.js";
import {setCurrentBarter} from "../../store/actions/bartersActions.js";
import Spinner from "../../Commons/Spinner.js";
import {httpConfirmBarterPrice} from "../../HttpRequests/barters.http.js";
import CompletedBarterModal from "./CompletedBarterModal.js";
import {useWindowWidth} from "@react-hook/window-size";
import Avatar from "../../Commons/Avatar.js";
import indicataLogo from "../../assets/images/indicata.jpg";

const useStyles = createUseStyles(theme => ({
    root: {
        left: "0",
        right: "0",
        bottom: "0",
        display: 'grid',
        padding: "8px 24px",
        position: "fixed",
        background: theme.colors.white,
        alignItems: "center",
        borderTopLeftRadius: "24px",
        borderTopRightRadius: "24px",
        gridTemplateRows: "min-content",
        ...theme.shadow,
        [theme.s]: {height: ({showDetails}) => showDetails ? 'auto' : 112, zIndex: 2,},
        [theme.mUp]: {height: ({showDetails}) => showDetails ? 280 : 100,},
    },
    overlay: {
        // fullscreen
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: ({contextMode}) => contextMode ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,0.5)',
        display: 'grid',
        alignContent: 'center',
        justifyContent: 'center',
        zIndex: 1,
    },
    header: {
        display: "grid",
        alignItems: "center",
        gridAutoFlow: 'column',
        gridAutoColumns: 'max-content',
        gridColumnGap: "8px",
        zIndex: 2,
        position: 'relative'
    },
    body: {
        display: 'grid',
        'grid-row-gap': theme.spacing,
        gridTemplateColumns: '1fr 1fr',
        alignItems: 'center',
        gridColumnGap: 16,
        [theme.s]: {gridTemplateColumns: '1fr',},
        [theme.mUp]: {gridTemplateColumns: '1fr 1fr',},
    },
    carData: {
        display: 'grid',
        'grid-row-gap': theme.spacing,
        gridTemplateColumns: '1fr',
        alignItems: 'center',
    },
    indexes: {
        display: 'grid',
        'grid-row-gap': theme.spacing,
        gridTemplateColumns: '1fr',
        alignItems: 'center',
    },
    footer: {
        display: "grid",
        alignItems: "center",
        background: theme.colors.lightGrey,
        justifyContent: 'center',
        height: "100%",
        margin: "0 -24px -16px",
        padding: "0 24px",
        gridColumnGap: "8px",
    },
    btnsContainer:{
        display: "grid",
        alignItems: "center",
        gridTemplateColumns: "1fr 1fr",
        gridColumnGap: theme.spacing * 3
    },
    footerMobile: {
        display: "grid",
        alignItems: "center",
        gridTemplateColumns: "1fr 1fr",
        background: theme.colors.lightGrey,
        height: 80,
        margin: "0 -24px -16px",
        padding: "0 24px",
        gridColumnGap: "8px",

    },
    cardsContainer: {
        display: "grid",
        alignItems: "start",
        width: "100%",
        [theme.s]: {gridTemplateColumns: '1fr 1fr',},
        [theme.mUp]: {gridTemplateColumns: "1fr 1fr 1fr 1fr",},
    },
    card: {
        border: `1px solid ${theme.colors.red}`,
        borderRadius: 8,
        display: "grid",
        padding: theme.spacing,
        textAlign: "center",
        gridRowGap: theme.spacing,
        alignItems: "center",
        minHeight: 88,
        '& span': {
            fontWeight: 600,
            fontSize: 20,
        },
        '& p': {
            fontSize: 11,
        },
    },
    general: {
        display: "grid", gridTemplateColumns: "1fr auto"
    },
    car: {
        '& p': {
            fontSize: "14px", fontWeight: 500,
            '& span': {
                fontSize: "12px",
                color: theme.colors.darkGrey,
                fontWeight: 500
            }
        },
        '& span': {
            fontSize: "12px",
            color: theme.colors.darkGrey,
            fontWeight: 500
        }
    },
    user: {},
    row: {
        display: "grid",
        alignItems: "center",
        gridTemplateColumns: "1fr auto",
        padding: "4px 0",
        borderBottom: `1px solid ${theme.colors.lightGrey}`,
        gridColumnGap: 16,
        '& span': {
            fontSize: "12px",
            color: theme.colors.black,
            fontWeight: 500
        }
    },
    total: {
        display: "grid",
        alignItems: "center",
        gridTemplateColumns: "1fr auto",
        padding: "8px 0",
        fontSize: "16px",
        color: theme.colors.black,
        fontWeight: 600,
        '& span': {
            fontSize: "18px",
            color: theme.colors.black,
            fontWeight: 600
        }
    }
}));

const AdminRecapPanel = ({barter}) => {
    const dispatch = useDispatch();
    const isMobile = (useWindowWidth() < 576);
    const [showDetails, setShowDetails] = useState(isMobile);
    const [showPopover, setShowPopover] = useState(false);
    const [showCompletedPopover, setShowCompletedPopover] = useState(false);
    const [loading, setLoading] = useState(false);
    const classes = useStyles({showDetails});

    const onChangeWithdrawal = async (values) => {
        setLoading(true);
        const dataToSend = {
            id: barter.id,
            price: values ? values.withdrawal_price : barter.withdrawal_price,
            note: values ? values.note : barter.note
        };
        try {
            const {data: barter} = await httpConfirmBarterPrice(dataToSend);
            //set barter
            dispatch(setCurrentBarter(barter));
            setShowPopover(false);
            setShowCompletedPopover(true);
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    };

    return <>
        {showDetails && isMobile && <div className={classes.overlay} onClick={() => setShowDetails(!showDetails)}/>}
        <div className={classes.root}>
            {showPopover && <Popover withHeader={true} title={'Modifica prezzo di ritiro'} minWidth={300} width={'auto'}
                                     onClose={() => setShowPopover(!showPopover)} contextMode={false}>
                <EditWithdrawalPrice onChangePriceCb={onChangeWithdrawal} barter={barter}/>
            </Popover>}
            {showCompletedPopover && <Popover withHeader={false} minWidth={300} width={500} contextMode={false}
                                              onClose={() => setShowCompletedPopover(!showCompletedPopover)}>
                <CompletedBarterModal onClose={() => setShowCompletedPopover(!showCompletedPopover)}/>
            </Popover>}

            {loading && <Spinner overlayFullscreen={true}/>}

            <div className={classes.header}>
                <StatsIcon data-color/>
                <h5>Riepilogo</h5>
                {showDetails ? <ArrowDown data-color onClick={() => setShowDetails(!showDetails)}/> :
                    <ArrowUp data-color onClick={() => setShowDetails(!showDetails)}/>}
            </div>
            {showDetails && <div className={classes.body}>
                <div className={classes.indexes}>
                    <div className={classes.cardsContainer}>
                        <Card bodyCustomClass={classes.card}>
                            <span> {barter.average_rotation ? barter.average_rotation : 0} </span>
                            <p>Indice di rotazione del venduto</p>
                        </Card>
                        <Card bodyCustomClass={classes.card}>
                            <span> {barter.average_national_rotation ? barter.average_national_rotation : 0} </span>
                            <p>Indice di rotazione</p>
                            <img src={indicataLogo} alt={'indicata logo'} style={{height: 12, margin: '0 auto'}}/>
                        </Card>
                        <Card bodyCustomClass={classes.card}>
                            <span> {barter.same_model_amount ? barter.same_model_amount : 0} </span>
                            <p>IPresenti sul parco auto</p>
                        </Card>
                        <Card bodyCustomClass={classes.card}>
                            <span> {barter.average_stock_rotation ? barter.average_stock_rotation : 0} </span>
                            <p>Giorni di giacenza delle auto a stock</p>
                        </Card>
                    </div>
                </div>
                <div className={classes.carData}>
                    <div className={classes.general}>
                        <div className={classes.car}>
                            <p><span> {barter?.model?.brand?.name} </span> {barter?.model?.name} </p>
                            <span>{barter?.mileage}</span>
                        </div>
                        <div className={classes.user}>
                            <Avatar fullName={barter?.withdrawal_user.full_name} img={barter?.withdrawal_user.photo_link} size={'small'}/>
                        </div>
                    </div>
                    <div className={classes.row}>
                        <small>Costi di ripristino stimati</small>
                        <span> {toMoney(barter.first_damage_estimate)} </span></div>
                    <div className={classes.row}>
                        <small>Minivoltura</small>
                        <span> {toMoney(150)} </span></div>
                    <div className={classes.total}>
                        <small>Ritiro proposto</small>
                        <span>{toMoney(barter.withdrawal_price)} </span></div>
                </div>
            </div>}
            {isMobile ? <div className={classes.footerMobile}>
                    <Button data-primary icon={<DoneIcon data-color/>} data-small
                            onClick={() => onChangeWithdrawal(null)}> Accetta </Button>
                    <Button data-primary onClick={() => setShowPopover(!showPopover)} data-small> Modifica ritiro </Button>
                </div> :
                <div className={classes.footer}>
                    <div className={classes.btnsContainer}>
                        <Button icon={<DoneIcon data-color/>} data-primary onClick={() => onChangeWithdrawal(null)}
                                width={232}> Accetta </Button>
                        <Button data-primary onClick={() => setShowPopover(!showPopover)} width={232}> Modifica prezzo di
                            ritiro </Button>
                    </div>
                </div>}
        </div>
    </>
};

export default AdminRecapPanel;
