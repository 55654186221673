import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useDispatch} from 'react-redux';
import {useForm} from 'react-hook-form';
import {useHistory, useParams} from 'react-router-dom';
import {DoneIcon} from "../../assets/icons.js";
import Spinner from "../../Commons/Spinner";
import Button from "../../Commons/Button";
import Text from "../../Commons/Text";
import Back from "../../Commons/Back.js";
import * as Yup from "yup";
import {
    httpResetPasswordConfirmation,
    httpResetPasswordPermission,
} from "../../HttpRequests/user.http.js";
import {errorHandler} from "../../helpers/utils.js";

const useStyles = createUseStyles(theme => ({
    root: {
        position: 'relative',
        'max-width': 446,
        'margin': [theme.spacing * 4, 'auto'],
        'display': 'grid',
        'grid-auto-flow': 'row',
        'grid-row-gap': theme.spacing * 2 + 'px',
        '& h1': {
            marginBottom: theme.spacing * 4
        },
        [theme.s]: {
            width: 320,
            padding: [theme.spacing * 4, theme.spacing * 3],
            gridTemplateColumns: 280,
            justifyContent: "center",
        },
        [theme.mUp]: {
            'width': '100%',
            'padding': [theme.spacing * 4],
            gridTemplateColumns: 350,
            justifyContent: "center",
        },
        '& button':{
            marginTop: theme.spacing * 2
        }
    },
    done: {
        width: "60px!important",
        fill: `${theme.colors.green}!important`
    },
    title: {
        fontWeight: 500,
        textAlign: "left",
        margin: [theme.spacing * 3, 0 ,theme.spacing *2 , 0],
        color: theme.colors.black,
        [theme.s]: {
            fontSize: 28,
        },
        [theme.mUp]: {
            fontSize: 38,
        },
    },
    subtitle: {
        margin: [theme.spacing *2, 0, theme.spacing * 2, 0],
        'color': theme.colors.darkGrey,
    },
    contact: {
        fontWeight: "300",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.14",
        letterSpacing: "normal",
        textAlign: "center",
        color: theme.colors.black,
        [theme.s]: {
            fontSize: 18,
        },
        [theme.mUp]: {
            fontSize: 24,
        },
    },
}));

const validationSchema = Yup.object().shape({
    password: Yup.string()
        .required('Inserisci la password.')
        .min(8, 'La password deve essere lunga almeno 8 caratteri'),
    password_confirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Le password inserite sono diverse')
        .required('Inserisci la password'),
});

const ResetPassword = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [isWrongToken, setIsWrongToken] = useState(false);
    const [email, setEmail] = useState(null);
    const history = useHistory();
    const {register, handleSubmit, errors} = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            password: "",
            passwordConfirm: ""
        },
        validationSchema
    });

    //todo: perform token validity check
    const {token} = useParams();

    useEffect(() => {
        validateToken();
    }, [token]);


    const onSubmit = async values => {
        try {
            setIsLoading(true);
            const {data} = await httpResetPasswordConfirmation({...values,email:email, token:token});
            setSuccess(true);
        } catch (e) {
            errorHandler(e,dispatch)
        } finally {
            setIsLoading(false);
        }
    };

    const validateToken = async () => {
         setIsLoading(true);
         try {
             const {data: {email}} = await httpResetPasswordPermission(token);
             setEmail(email)
         } catch (e) {
             setIsWrongToken(true);
         }finally {
             setIsLoading(false);
         }
    };

    return (
        <>
            {isWrongToken ? <div className={classes.root}>
                    <h2 className={classes.title}>Ops!</h2>
                    <p className={classes.subtitle}>
                        Abbiamo avuto un problema con la tua richiesta. <br/>
                        Il link potrebbe essere scaduto.
                    </p>
                    <p className={classes.subtitle} >
                        Ricarica la pagina oppure
                        Prova di nuovo a richeidere di reimpostare la tua password.
                    </p>
                    <Button data-primary onClick={() => history.push('/reset-password')} style={{zIndex: 2}}> Torna al Reset </Button>
                </div>
                : success ? <div className={classes.root}>
                        <DoneIcon className={classes.done}/>
                        <h2 className={classes.title}>Nuova password inserita</h2>
                        <p className={classes.subtitle}>
                            La password è stata reimpostata correttamente
                        </p>
                        <Button data-primary onClick={() => history.push('/auth/login')} style={{zIndex: 2}}> Accedi </Button>
                    </div>
                    :
                    <form className={classes.root} noValidate={true} onSubmit={handleSubmit(onSubmit)}>
                        {isLoading && <Spinner/>}
                        <Back callback={() => history.goBack()}/>
                        <h2 className={classes.title}>Inserisci la nuova password</h2>
                        <p className={classes.subtitle}>
                            La password deve avere minimo 8 caratteri.
                            Per renderla più potente, utilizza almeno una maiuscola, un numero e un carattere speciale.
                        </p>
                        <Text type={'password'} label={'Nuova password'} name={'password'} ref={register}  errors={errors.password} style={{zIndex: 2}}/>
                        <Text type={'password'} label={'Conferma nuova password'} name={'password_confirmation'} ref={register} errors={errors.password_confirmation} style={{zIndex: 2}}/>
                        <Button data-primary  type={'submit'} style={{zIndex: 2}}>Conferma</Button>
                    </form>}
        </>
    );
};

export default ResetPassword;
