import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import SubHeading from "../../../Commons/SubHeading.js";
import TabCard from "../../../Commons/TabCard.js";
import Card from "../../../Commons/Card.js";
import {EuroIcon} from "../../../assets/icons.js";
import {
    httpEditBartersSettingsParams,
    httpGetBartersSettingsParams,
    httpGetCarSettingsDamages
} from "../../../HttpRequests/commons.http.js";
import {
    damagesIconsResolver,
    errorHandler,
    normalizeDamagesName,
    retrieveDamagesSettingsForRhf
} from "../../../helpers/utils.js";
import {useDispatch} from "react-redux";
import Spinner from "../../../Commons/Spinner.js";
import DamagesGroupValues from "../../../Components/Settings/BarterSettings/DamagesGroupValues.js";
import Text from "../../../Commons/Text.js";
import {useForm} from "react-hook-form";
import {setDamagesList} from "../../../store/actions/settingsActions.js";
import cx from 'classnames';
import {alertToggle} from "../../../store/actions/alertActions.js";

const useStyles = createUseStyles(theme => ({
    gridContainer: {
        'position': 'relative',
        height: ({bigSubHeader}) => `calc(100vh - ${bigSubHeader ? theme.bigSubHeader + theme.headerHeight : theme.smallSubHeader + theme.headerHeight}px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        '& h2': {
            fontWeight: 600,
            fontSize: 24,
            marginBottom: theme.spacing * 2
        },
        [theme.mUp]: {
            padding: [theme.spacing * 4],
        },
        [theme.s]: {
            padding: [theme.spacing],
        },
    },
    customMargin: {
        margin: [0, 0, theme.spacing * 4],
        padding: [0]
    },
    description: {
        gridColumn: '1 / span 4'
    },
    inputsContainer: {
        display: "grid",
        alignItems: "center",
        gridAutoFlow: "column",
        gridColumnGap: theme.spacing * 3,
        gridAutoColumns: 'max-content',
        padding: [theme.spacing * 3, 0, 0]
    },
    paramInputs: {
        maxWidth: 200
    },
    tab_container: {
        display: 'grid',
        gridColumnGap: theme.spacing,
        gridTemplateColumns: "140px 140px 140px 140px",
        justifySelf: "center",
        justifyItems: "end",
        justifyContent: "end",
        textAlign: "center",
        fontWeight: 600,
        fontSize: 14,
        marginBottom: theme.spacing * 5
    },
    tab: {
        width: "100%",
        borderBottom: `1px solid ${theme.colors.grey}`,
        padding: "0 0 4px 0",
        cursor: 'pointer',
        transition: 'ease-in 300ms',
        '&:hover': {
            color: theme.colors.red,
        }
    },
    currentTab: {
        borderBottom: `2px solid ${theme.colors.red}`,
        color: theme.colors.red,
    }
}));

const BarterSettings = () => {
    //based on the type {"big","small"} property of subHeader component
    const classes = useStyles({bigSubHeader: false}); // or false if type is small
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [damagesGroups, setDamagesGroups] = useState(null);
    const [damagesGroupKeys, setDamagesGroupKeys] = useState([]);
    const [bartersParams, setBartersParams] = useState(null);
    const [currentTab, setCurrentTab] = useState(null);
    const {register, watch, reset} = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
    });

    useEffect(() => {
        //fetch data
        getBarterParams();
        getDamages();
    }, []);

    const getDamages = async () => {
        setLoading(true);
        try {
            const {data: damagesGroup} = await httpGetCarSettingsDamages();
            let damagesSettingsList = {};
            let damagesGroupList = {};
            setDamagesGroupKeys(Object.keys(damagesGroup));
            Object.keys(damagesGroup).map((item, index) => {
                damagesGroupList = {...damagesGroupList, [item.toString()]: damagesGroup[item],};
                Object.keys(damagesGroup[item]).map((damageCategory) => {
                    damagesSettingsList = {...damagesSettingsList, ...retrieveDamagesSettingsForRhf(damagesGroup[item][damageCategory])}
                });
            });
            dispatch(setDamagesList(damagesSettingsList));
            setDamagesGroups(damagesGroupList);
            setCurrentTab(Object.keys(damagesGroup)[0]);
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false)
        }
    };

    const getBarterParams = async () => {
        try {
            const {data} = await httpGetBartersSettingsParams();
            setBartersParams(data);
            reset(data);
        } catch (e) {
            errorHandler(e, dispatch)
        }
    };

    const setParams = async (value, label, field) => {
        if ((value === null || value === '') || bartersParams[field]?.toString() === value.toString()) return;
        setLoading(true);
        const dataToSend = {
            ...bartersParams,
            [field]: value,
        };
        try {
            const {data} = await httpEditBartersSettingsParams(dataToSend);
            setBartersParams(data);
            reset(data);
            dispatch(alertToggle(`Il parametro ${label} è stato modificato correttamente.`, 'success'))
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false)
        }
    };

    return <>
        <SubHeading title={"Impostazioni Permute"} type={'small'}/>
        {loading && <Spinner overlayFullscreen={true}/>}
        <div className={classes.gridContainer}>
            <h2>Supervalutazione e scontistica </h2>
            <Card cardTitle={'Parametri di supervalutazione'} cardIcon={<EuroIcon data-color/>}
                  rootCustomClass={classes.customMargin}>
                <form className={classes.root}>
                    <p className={classes.description}>
                        Compila i valori in questa sezione per assegnare un grado di importanza ai parametri che
                        calcolano il valore massimo assegnabile ad una permuta.
                    </p>
                    <div className={classes.inputsContainer}>
                        <Text type={'number'} label={'Modelli uguali in stock'} name={'same_model_amount_weight'}
                              ref={register} className={classes.paramInputs}
                              onChange={() => setParams(watch('same_model_amount_weight'), 'Modelli uguali in stock', 'same_model_amount_weight')}/>
                        <Text type={'number'} label={'Indice di rotazione locale'} name={'local_rotation_weight'}
                              ref={register} className={classes.paramInputs}
                              onChange={() => setParams(watch('local_rotation_weight'), 'Indice di rotazione locale', 'local_rotation_weight')}/>
                        <Text type={'number'} label={'Indice di rotazione nazionale'} name={'national_rotation_weight'}
                              ref={register} className={classes.paramInputs}
                              onChange={() => setParams(watch('national_rotation_weight'), 'Indice di rotazione nazionale', 'national_rotation_weight')}/>
                    </div>
                </form>
            </Card>

            <h2>Valore ripristini in base al valore auto </h2>
            {damagesGroupKeys && <>
                <div className={classes.tab_container}>
                    {damagesGroupKeys.map((key, index) => <div
                            className={cx(classes.tab, currentTab === key ? classes.currentTab : '')} key={`${key}-id`}
                            onClick={() => setCurrentTab(key)}>
                            {damagesGroupKeys.length - 1 === index ? `oltre ${damagesGroupKeys[index - 1].substring(key.length, damagesGroupKeys[index - 1].indexOf("-") + 1)}€`
                                : `fino a ${key.substring(key.length, key.indexOf("-") + 1)}€`}
                        </div>
                    )}
                </div>

                {damagesGroupKeys.map(key =>
                    currentTab === key && <>
                        {damagesGroups && Object.keys(damagesGroups[currentTab]).map((category, i) => {
                            return <TabCard cardTitle={normalizeDamagesName(category)} cardIcon={damagesIconsResolver(category)}
                                            cardTitleSize={'medium'} showContent={false} key={i} isDisabled={false}>
                                <DamagesGroupValues damagesGroup={damagesGroups[currentTab][category]}/>
                            </TabCard>
                        })}
                    </>
                )}
            </>}
        </div>
    </>
};

export default BarterSettings;
