export const ALERT_TOGGLE = 'ALERT_TOGGLE';

/**
* How to use alertToggle:
* dispatch(alertToggle(message,'error'));
**/
export const alertToggle = (message, level, opened) => {
    return {
        type: ALERT_TOGGLE,
        message: message,
        level: level,
    };
};