import React, {useState} from 'react';
import {createUseStyles} from "react-jss";
import {EditIcon} from "../../assets/icons.js";
import Popover from "../../Commons/Popover.js";
import EurotaxStep from "../Barters/EurotaxStep.js";
import IndicataStep from "../Barters/IndicataStep.js";
import {useDispatch} from "react-redux";
import {setCurrentBarter} from "../../store/actions/bartersActions.js";

const useStyles = createUseStyles(theme => ({
    root: {
        position: 'relative',
        display: 'grid',
        maxWidth: 300,
        gridRowGap: theme.spacing / 2,
        padding: theme.spacing * 2,
        justifyItems: 'start',
        backgroundColor: ({isRecap}) => isRecap ? theme.colors.white : theme.colors.smokeWhite,
        borderRadius: 8,
        transition: 'ease-in 300ms',
        [theme.s]: {
            border: `1px solid ${theme.colors.smokeWhite}`,
        },
    },
    model: {
        fontSize: 14,
        fontWeight: 500,
        color: theme.colors.black
    },
    secondary: {
        fontSize: 12,
        color: theme.colors.darkGrey
    },
    edit: {
        position: 'absolute',
        backgroundColor: theme.colors.grey,
        width:24,
        height: 24,
        borderRadius: '50%',
        cursor: 'pointer',
        right: "-6px",
        top: "-6px",
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        transition: 'ease-in 200ms',
        '& svg':{
            display: 'grid',
            alignItems: 'center',
            justifyContent: 'center',
            width: '16px!important',
            fill: theme.colors.darkGrey
        },
        '&:hover':{
            backgroundColor: theme.colors.black,
            '& svg':{
                fill: theme.colors.smokeWhite
            },
        }
    }
}));

const SelectedVersion = ({brand, model, doors, service, id, isRecap, isCarInfo}) => {
    const classes = useStyles({isRecap});
    const [showPopover, setShowPopover] = useState(false);
    const dispatch = useDispatch();

    const renderServiceModal = (service) => {
        if(service === 'eurotax'){
            return <EurotaxStep carId={id} onVersionChoiceCb={(data) => { dispatch(setCurrentBarter(data)); setShowPopover(!showPopover)}}/>
        }else{
            return <IndicataStep carId={id} isInBarter={true} onVersionChoiceCb={(data) => { dispatch(setCurrentBarter(data)); setShowPopover(!showPopover)}} onClose={() => setShowPopover(!showPopover)}/>
        }
    };

    return <div className={classes.root}>
        {showPopover && <Popover withHeader={false} minWidth={500} onClose={() => setShowPopover(!showPopover)} contextMode={false}>
            {renderServiceModal(service)}
        </Popover>}

        {(service !== 'dat' && !isRecap && !isCarInfo) && <div className={classes.edit}>
            <EditIcon data-color onClick={() => setShowPopover(service)}/>
        </div>}
        <span className={classes.secondary}> {brand} </span>
        <h5 className={classes.model}>{model}</h5>
        {/* <span className={classes.secondary}>{isDat ? `Prodotto -` : `Prodotto dal ${formattedDateIso(productionDate.start)} al ${formattedDateIso(productionDate.end)}`}</span>*/}
        <span className={classes.secondary}> {doors} porte</span>
    </div>
};

export default SelectedVersion;