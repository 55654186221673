import React from 'react';
import {createUseStyles} from "react-jss";
import {formattedDate} from "../../helpers/utils.js";

const useStyles = createUseStyles(theme => ({
    promo: {
        display: "grid",
        position: "relative",
        gridTemplateRows: '1fr 1fr',
        '& h5': {
            color: theme.colors.green,
            fontSize: 20,
            fontWeight: 'bold'
        },
        '& p': {
            '& span': {
                color: theme.colors.darkGrey,
                fontSize: 12,
            }
        }
    },
    data: {
        position: "relative",
        fontSize: "10px",
        alignItems: "center",
        gridRowGap: "8px",
    },
}));

const ActiveCampaigns = ({activeCampaign}) => {
    const classes = useStyles();

    return <>
            <div className={classes.promo}>
                <h5>{`- ${activeCampaign.amount}${activeCampaign.type === 'fixed' ? '€' : "%"} sul prezzo base`} </h5>
                <p>dal <span>{formattedDate(activeCampaign.start_campaign)}</span> al <span>{formattedDate(activeCampaign.end_campaign)}</span></p>
            </div>
           {/* <div className={classes.data}>
                <div>Promozione attiva su <strong>Toyota</strong> Yaris, Aygo, Rav4, Corolla</div>
            </div>*/}
        </>
};

export default ActiveCampaigns;
