import React from 'react';
import {useSelector} from "react-redux";
import {createUseStyles} from "react-jss";
import {getCurrentConversationTypingIndicators} from "../../../../store/reducers/chat/chats";

const useStyles = createUseStyles(theme => ({
    root: {
        marginLeft: '37px',
        paddingBottom: '5px',
        color: '#979797',
    }
}))

const TypingIndicatorDisplay = () => {
    const classes = useStyles();
    const typingIndicators = useSelector(getCurrentConversationTypingIndicators);

    if (typingIndicators.length === 0) {
        return <div className={classes.root}>&nbsp;</div>;
    } else if (typingIndicators.length === 1) {
        //TODO: handle myself writing
        return <div className={classes.root}>{typingIndicators[0].sender.name} is typing ...</div>
    } else {
        return <div className={classes.root}>Multiple users typing ...</div>;
    }
}

export default TypingIndicatorDisplay;