import { createSelector } from "reselect";
import {MESSAGE_DRAFT_DISCARDED, MESSAGE_DRAFT_UPDATED} from "../../actions/chat/draftActions";

const initialState = {
    messageDraftById: {}
};

/**
 * Remove a message draft from the store for a specific conversation
 */
export const discardMessageDraft = (conversationId) => {
    return dispatch => {
        return dispatch({
            type: MESSAGE_DRAFT_DISCARDED,
            payload: {
                conversationId
            }
        });
    };
};

/**
 * Indicate that a draft message has been updated
 */
export const updateMessageDraft = (
        conversationId,
    newDraft
) => {
    return dispatch => {
        return dispatch({
            type: MESSAGE_DRAFT_UPDATED,
            payload: {
                conversationId,
                value: newDraft
            }
        });
    };
};

export const conversationDraftStateReducer = (
        state = initialState,
        action
) => {
    switch (action.type) {
        case MESSAGE_DRAFT_UPDATED: {
            const messageDraftById = {
                ...state.messageDraftById,
                [action.payload.conversationId]: action.payload.value
            };
            return {
                ...state,
                messageDraftById
            };
        }
        case MESSAGE_DRAFT_DISCARDED: {
            const {
                [action.payload.conversationId]: discard,
                ...messageDraftById
            } = state.messageDraftById;
            return {
                ...state,
                messageDraftById
            };
        }
    }
    return state;
};


const getDraftSlice = (state) => state.chats.drafts;

/**
 * Returns an index which can be used to find user objects
 */
export const getMessageDrafts = createSelector(
    [getDraftSlice],
    (drafts) => {
        return drafts.messageDraftById;
    }
);