import React from 'react';
import Badge from "../Commons/Badge.js";
import {alertToggle} from "../store/actions/alertActions.js";
import {
    BodyShopDamagesIcon,
    CarIcon,
    DocumentsTaskIcon,
    ElectricalDamagesIcon,
    EstimateTaskIcon,
    GlassesDamagesIcon,
    InternalDamagesIcon, MirrorDamagesIcon, OpticalDamagesIcon,
    OtherCarInfosIcon,
    PhotoCameraIcon,
    ReconditioningTaskIcon,
    SearchIcon,
    ShiftTaskIcon, TireCenterIcon,
    WashTaskIcon
} from '../assets/icons.js';
import moment from 'moment'
import 'moment/locale/it' // without this line it didn't work
/**
 * number to money format
 * @param value
 * @param sign
 * @returns {string}
 */
export const toMoney = (value, sign = false) => value ? (sign === true ? value > 0 ? '+' : '-' : '') + value.toLocaleString('it-IT', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
}) : (0).toLocaleString('it-IT', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

/**
 * Js date to human format
 * @param value
 * @returns {string}
 */
export const formattedDate = value => value ? moment(value).locale('it').format('l') : '-';
export const formattedDateIso = value => value ? moment(value).locale('it').format('YYYY-MM-DD') : '-';
export const formattedDateHoursIso = value => value ? moment(value).locale('it').format('YYYY-MM-DD hh:mm') : '-';

/**
 * Js date to human hours format
 * @param value
 * @returns {string}
 */
export const formattedDateHours = value => value ? moment(value).locale('it').format('HH:mm ') : '-';

/**
 * bytes to user friendly format with size
 * @param bytes
 * @returns {string}
 */
export const bytesToSize = (bytes) => {
    let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

/**
 * Generate random uuid
 * @returns {string}
 */
export const uuid = () => {
    return '_' + Math.random().toString(36).substr(2, 9);
}

/**
 * Set Errors returned form an api into the correct form field
 * @param errors array of erreors returner
 * @param setError react-hook-form method
 */
export const setErrors = (errors, setError) => {
    //expected errors array structure: [{password: 'sbagliata'}]
    errors.map((error) => {
        /* setError(Object.keys(error)[0], {type: "manual", message:  error[Object.keys(error)[0]]})*/
        setError(Object.keys(error)[0], "message", error[Object.keys(error)[0]][0]) //shit
        /* console.log({key: Object.getOwnPropertyNames(error)[0], value: error[Object.getOwnPropertyNames(error)[0]]})*/
    })
};

/** barter status resolver
 * @param a string that identify the status of the barter
 * **/
export const barterStatusLabelRenderer = (status) => {
    switch (status) {
        case 'trade_in':
            return <Badge title={'In corso'} statusColor={'black'} badgeRadius={50} badgeSize={"small"}
                          minSize={true}/>;
        case 'negotiating':
            return <Badge title={'Da Approvare'} statusColor={'warning'} badgeRadius={50} badgeSize={"small"}
                          minSize={true}/>;
        default:
            return <Badge title={'Conclusa'} statusColor={'success'} badgeRadius={50} badgeSize={"small"}
                          minSize={true}/>
    }
};

/** clear useless parameters from obj
 * @param filters obj
 * **/
export const clearFilters = (filters) => {
    Object.keys(filters).forEach(key => {
        if (filters[key] === '') {
            filters[key] = null
        }
    });
    return filters
};


/** clear useless parameters from obj
 * @param obj obj
 * **/
export const removeUnsettedKeyInObj = (obj) => {
    return Object.keys(obj).forEach(key => (obj[key] === undefined || obj[key] === null || obj[key] === '' || obj[key] === false) && delete obj[key]);
};

/** remov specific key form obj
 * @param obj object to analize
 * @param myKey to remove
 * **/
export const removeSpecifcKeyInObj = (obj, myKey) => {
    delete obj[myKey];
    return obj;
};

//remap option to have the correct fotmat to use with react select
export const remapOptions = (options) => {
    return Object.keys(options).map((item) => ({label: item, value: options[item]}));
};

export const retrieveValueForRs = (options, value) => {
    //console.log(options,value)
    if (value === null || value === '' || value === undefined) return null;
    return options.filter(option => option.value.toString() === value.toString())
};

//clean damages for backend
export const clearDamagesForBackend = (damages) => {
    const damagesRemapped = [];
    damages.map((damage, index) => {
        if (damage.id) {
            damagesRemapped.push(damage.id)
        }
    });
    return damagesRemapped;
};

//normalize damages names
export const normalizeDamagesName = (name) => {
    const regex = /_/gi;
    return name.replace(regex, ' ')
};

//Retrieve damages for react hoock form
export const retrieveDamagesForRhf = (damages) => {
    const damagesRemapped = {};
    damages.map((damage, index) => {
        damagesRemapped[damage.car_part] = damage.id.toString()
    });
    return damagesRemapped;
};

//Retrieve accessories for react hoock form
export const retrieveAccessoriesForRhf = (accessories) => {
    const accessoriesRemapped = {};
    accessories && accessories.map((accessory, index) => {
        accessoriesRemapped[accessory.label] = accessory.id
    });
    return accessoriesRemapped;
};

//Retrieve damages for react hoock form SETTINGS
export const retrieveDamagesSettingsForRhf = (damagesSettings) => {
    const damagesRemapped = {};
    damagesSettings.map((damage, index) => {
        damage.damage_sets[0].options.map(({id, price}) => {
            damagesRemapped[id] = price
        });
    });
    return damagesRemapped;
};

//calculate total costs for barter
export const completeCostsValue = (barter) => {
    let damagesTotal = 0;
    barter.examination.damages && barter.examination.damages.map((damage, index) => {
        damagesTotal = damagesTotal + damage.price;
    });
    return barter.ideal_price + ((barter.ideal_price * 22) / 100) + damagesTotal
};

/**
 * normalize stock filters structure for b end usability
 * @param values
 * @returns {*}
 */
export const normalizeFiltersForBend = (values) => {
    //remove null or undefined
    removeUnsettedKeyInObj(values);
    //remove same value in range filters
    if (values.days_in_stock && (values.days_in_stock[0] === values.days_in_stock[1])) {
        removeSpecifcKeyInObj(values, 'days_in_stock')
    }
    if (values.price && (values?.price[0] === values?.price[1])) {
        removeSpecifcKeyInObj(values, 'price')
    }
    if (values.mileage && (values?.mileage[0] === values?.mileage[1])) {
        removeSpecifcKeyInObj(values, 'mileage')
    }
    //normalize react selects values for b-end structure
    Object.keys(values).map((key) => {
        if (Array.isArray(values[key])) {
            return values[key] = values[key].map((item, index) => {
                return item.value ? item.value : item;
            });
        }
    });
    return values
};

/**
 * Error handler
 * @param e => error object returned
 * @param dispatch => redux callback for alerts
 */
export const errorHandler = (e, dispatch) => {
    if (e.response) {
        dispatch(alertToggle(e.response?.data?.message, 'error'));
        if (e.response.status === 500) {
            dispatch(alertToggle('Errore di sistema. Riprovare.', 'error'))
        }
    } else {
        // Something happened in setting up the request and triggered an Error 500
        console.log('Error', e.message);
        dispatch(alertToggle(e.message ? e.message : 'Errore di sistema. Riprovare.', 'error'))
    }
};

/**
 * filter name resolver for the badges
 * @param structure
 *  @param key
 * @returns {*}
 */
export const filterNameResolver = (structure, key) => {
    if (key === 'order_by' || key === 'order_direction') return;
    if (key === 'plate') {
        return 'Targa'
    } else if (key === 'hide_booked') {
        return 'Escludi prenotate'
    } else {
        return structure.filter((item) => item.slug === key)[0]?.label
    }
}

export const filterBarterPhotoset = (photosets) => {
    return photosets?.find(photoset => photoset.is_barter);
};

export const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
};

export const getLabelByValue = (optionsArr, value) => {
    return optionsArr.find(optionObj => optionObj.value === value)?.label;
};

/**
 * set car default image
 * @param car
 * @returns {Promise<void>}
 */
export const getCarDefaultImage = (car) => {
    let defaultPhoto = '';
    car && car.photosets.map((photoset) => {
        photoset.photos.map(photo => {
            if (photo.default) {
                defaultPhoto = photo.url
            }
        })
    });
    return defaultPhoto;
};

export const taskIconResolver = (name) => {
    switch (name) {
        case 'internal_transport':
        case 'external_transport':
        case 'single_transport':
            return <ShiftTaskIcon style={{fill: 'black'}}/>
        case 'wash':
        case 'complete_wash':
            return <WashTaskIcon style={{fill: 'black'}}/>
        case 'reconditioning':
            return <ReconditioningTaskIcon style={{fill: 'black'}}/>
        case  'estimate':
            return <EstimateTaskIcon style={{fill: 'black'}}/>
        case 'photo':
            return <PhotoCameraIcon style={{fill: 'black'}}/>
        case 'expertise':
            return <SearchIcon style={{fill: 'black'}}/>
        default:
            return <DocumentsTaskIcon width={24}/>
    }
};

export const damagesIconsResolver = (name) => {
    switch (name) {
        case ' Danni_carrozzeria':
            return <BodyShopDamagesIcon style={{fill: 'black'}}/>
        case 'Danni_gomme':
            return <TireCenterIcon style={{fill: 'black'}}/>
        case 'Danni_luce':
            return <OpticalDamagesIcon style={{fill: 'black'}}/>
        case  'Danni_specchi':
            return <MirrorDamagesIcon style={{fill: 'black'}}/>
        case 'Danni_vetri':
            return <GlassesDamagesIcon style={{fill: 'black'}}/>
        case 'Interni':
            return <InternalDamagesIcon style={{fill: 'black'}}/>
        case 'Meccanica_e_parti_elettriche':
            return <ElectricalDamagesIcon style={{fill: 'black'}}/>
        default:
            return <OtherCarInfosIcon style={{fill: 'black'}}/>
    }
}

export const repairTypeResolver = (type) => {
    if (type === 'workshop') {
        return 'workshop'
    } else if (type === 'body_shop') {
        return 'bodyShop'
    } else {
        return 'tireCenter'
    }
};

export const isCarProfilePicDeleted = (photoset, actualPicUrl) => {
    if(!photoset || !photoset.length || !actualPicUrl) return false;

    for (let i = 0; i < photoset.length; i++) {
       if (photoset[i]?.url === actualPicUrl) return true
    }
    return false
}

/** ENUMS & AUTOCOMPLETES **/
export const taskStatusColors = {
    open: 'primary',
    active: 'warning',
    closed: 'success'
};

export const bartersStatuses = [
    {
        "label": "In corso",
        "value": "trade_in"
    },
    {
        "label": "Da approvare",
        "value": "negotiating"
    },
    {
        "label": "Conclusa",
        "value": "trade_completed"
    }
];

export const carStatus = [
    {
        "label": "Usato",
        "value": "usato"
    },
    {
        "label": "Km 0",
        "value": "km0"
    }
];

export const salesChannels = [
    {
        "label": "B2B",
        "value": "b2b"
    },
    {
        "label": "Privato",
        "value": "b2c"
    }
];

export const gearBoxTypes = [
    {
        "label": "Manuale",
        "value": "manual"
    },
    {
        "label": "Automatico",
        "value": "automatic"
    }
];

export const fiscality = [
    {
        "label": "IVA Esposta",
        "value": "iva_esposta"
    },
    {
        "label": "Margine",
        "value": "margine"
    },
    {
        "label": "IVA 40/60",
        "value": "iva_40_60"
    }
];

export const buybackFiscality = [
    {
        "label": "IVA 40/60",
        "value": "iva_40_60"
    },
    {
        "label": "IVA 100%",
        "value": "iva_100"
    },
    {
        "label": "No Fattura (Margine)",
        "value": "no_fattura_margine"
    }
]

export const financialServices = [
    {
        "label": "Agos",
        "value": "agos"
    },
    {
        "label": "Cofidis",
        "value": "cofidis"
    },
    {
        "label": "Findomestic",
        "value": "findomestic"
    }
];

export const simpleYesOrNo = [
    {
        "label": "Si",
        "value": true
    },
    {
        "label": "No",
        "value": false
    }
]

export const motivations = [
    {
        "label": "Veicolo incidentato",
        "value": "veicolo_incidentato"
    },
    {
        "label": "Veicolo in perfetto stato",
        "value": "veicolo_in_perfetto_stato"
    },
    {
        "label": "Motore fuso",
        "value": "motore_fuso"
    },
    {
        "label": "Macchina vecchia",
        "value": "macchina vecchia"
    },
    {
        "label": "Non presente in fase di preventivo",
        "value": "veicolo_non_presente"
    }
];

export const taskStatuses = [
    {
        "label": "Da svolgere",
        "value": "open"
    },
    {
        "label": "In svolgimento",
        "value": "active"
    },
    {
        "label": "Completato",
        "value": "closed"
    }
];

export const operationsTypes = [
    {
        "value": "internal_transport",
        "label": "Spostamento tra sedi"
    },
    {
        "value": "external_transport",
        "label": "Spostamento per sedi esterne"
    },
    {
        "value": "single_transport",
        "label": "Spostamento"
    },
    {
        "value": "wash",
        "label": "Lavaggio"
    },
    {
        "value": "complete_wash",
        "label": "Lavaggio completo"
    },
    {
        "value": "photo",
        "label": "Fotografia"
    },
    {
        "value": "reconditioning",
        "label": "Ricondizionamento"
    }
];

export const warranties = [
    {
        "label": "Audi Prima Scelta Plus",
        "value": "audi_prima_scelta_plus"
    },
    {
        "label": "BMW",
        "value": "bmw_approved_selection"
    },
    {
        "label": "Citroen Select",
        "value": "citroen_select"
    },
    {
        "label": "Mercedes Certified",
        "value": "mercedes_certified"
    },
    {
        "label": "Mini Next",
        "value": "mini_next"
    },
    {
        "label": "Porsche Approved",
        "value": "porsche_approved"
    },
    {
        "label": "Smart Certified",
        "value": "smart_certified"
    }
];

export const simplifiedCarColors = [
    {
        "label": "Grigio Chiaro",
        "value": "grigio_chiaro"
    },
    {
        "label": "Grigio Scuro",
        "value": "grigio_scuro"
    },
    {
        "label": "Grigio Medio",
        "value": "grigio_medio"
    },
    {
        "label": "Nero",
        "value": "nero"
    },
    {
        "label": "Bianco",
        "value": "bianco"
    },
    {
        "label": "Rosso",
        "value": "rosso"
    },
    {
        "label": "Rosso Bordeaux",
        "value": "rosso_bordeaux"
    },
    {
        "label": "Verde Chiaro",
        "value": "verde_chiaro"
    },
    {
        "label": "Verde Scuro",
        "value": "verde_scuro"
    },
    {
        "label": "Marrone",
        "value": "marrone"
    },
    {
        "label": "Giallo",
        "value": "giallo"
    },
    {
        "label": "Arancio",
        "value": "arancio"
    },
    {
        "label": "Viola",
        "value": "viola"
    },
    {
        "label": "Oro",
        "value": "oro"
    },
    {
        "label": "Blu",
        "value": "blu"
    },
    {
        "label": "Beige",
        "value": "beige"
    }
];

export const simplifiedInteriors = [
    {
        "label": "Interni in pelle",
        "value": "interni_in_pelle"
    },
    {
        "label": "Interni in pelle mista",
        "value": "interni_in_pelle_mista"
    },
    {
        "label": "Interni in tessuto",
        "value": "interni_in_tessuto"
    },
    {
        "label": "Alcantara",
        "value": "alcantara"
    },
    {
        "label": "Sportivi",
        "value": "sportivi"
    }
];

export const warrantyDuration = [
    {label: "1 anno", value: 1},
    {label: "2 anni", value: 2},
    {label: "3 anni", value: 3},
    {label: "4 anni", value: 4},
];

export const shiftsTypes = [
    {label: "Spostamento tra sedi (interno)", value: 'internal_transport'},
    {label: "Spostamento esterno", value: 'external_transport'},
]

export const allShiftsTypes = [
    {label: "Spostamento tra sedi (interno)", value: 'internal_transport'},
    {label: "Spostamento esterno", value: 'external_transport'},
    {label: "Spostamento autonomo", value: 'single_transport'},
    {label: "Richiesta spostamento interno", value: 'internal_draft'},
    {label: "Richiesta spostamento esterno", value: 'external_draft'},
]

export const shiftsReasons = [
    {label: "Vendita a concessionari esterni", value: 'sold'},
    {label: "Spostamento tra sedi", value: 'ubication_change'},
    {label: "Manifestazioni o esposizioni", value: 'exposition'},
    {label: "Spostamento interno alla sede", value: 'location_change'},
    {label: "Uso personale", value: 'personal_use'},
]

export const InternalShiftsReasons = [
    {label: "Spostamento tra sedi", value: 'ubication_change'},
    {label: "Manifestazioni o esposizioni", value: 'exposition'},
]

export const externalShiftsReasons = [
    {label: "Vendita a concessionari esterni", value: 'sold'},
    {label: "Spostamento tra sedi", value: 'ubication_change'},
    {label: "Manifestazioni o esposizioni", value: 'exposition'},
]

export const categories = [
    {"label": "Autovettura", "value": "Autovettura"},
    {"label": "Moto", "value": "Moto"},
    {"label": "Autocarro", "value": "Autocarro"},
    {"label": "Microcar", "value": "Microcar"}
]

export const acquisitionChannels = [
    {"label": "Permuta dal nuovo", "value": "vn"},
    {"label": "Permuta dall'usato", "value": "vo"},
    {"label": "Remarketing", "value": "rmk"},
    {"label": "Km 0", "value": "km0"},
    {"label": "Acquisizione diretta", "value": "nlt"}
]