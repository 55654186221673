import React from 'react';
import {NavLink} from "react-router-dom";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles((theme) => ({
	default: {
		textDecoration: "none",
		color: theme.colors.navGrey,
		fontWeight: 500,
		padding: [16, 16, 16, 0],
		'&:hover': {
			color: theme.colors.white,
		},
		transition: 'ease-in 400ms'
	},
	active:{
		background: 'rgb(216 216 216 / 0.10)',
		color: theme.colors.white,
		borderTopRightRadius: 32,
		borderBottomRightRadius: 32,
		marginLeft: '-16px',
		paddingLeft: 16
	}
}));

const SidebarNavLink = ({children, ...rest}) => {
	const classes = useStyles();
	return <NavLink exact={true} className={classes.default} activeClassName={classes.active}  {...rest}>{children}</NavLink>
}

export default SidebarNavLink;
