import {createMessageReducer} from "pubnub-redux";

const messageReducer = createMessageReducer();

const extendedReducer = () => (state, action) => {
    switch(action.type) {
        case 'SET_MESSAGE_ACTION':
            const channel = action.payload.channel;
            const messageIndex = state.byId[channel].findIndex(message => message.timetoken === action.payload.messageTimetoken);
            return Number.isInteger(messageIndex) ? {
                byId: {
                    ...state.byId,
                    [channel]: state.byId[channel].map((msg, i) => {
                        return i === messageIndex ?
                            ({
                                ...state.byId[channel][messageIndex],
                                message: {
                                    ...state.byId[channel][messageIndex]['message'],
                                    actions: {
                                        deleted: true
                                    }
                                }
                            }) :
                            msg
                    })
                }
            } : state
        default:
            return messageReducer(state, action);
    }
};

const extendedMessageReducer = extendedReducer();

export default extendedMessageReducer;