import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import Table from "../../Commons/Table.js";
import Badge from '../../Commons/Badge';
import {clearFilters, errorHandler, formattedDate, getLabelByValue, taskStatuses,} from "../../helpers/utils.js";
import {httpGetTaskType} from "../../HttpRequests/tasks.http";
import SubHeading from "../../Commons/SubHeading.js";
import {useDispatch} from "react-redux";
import SimpleSearch from "../../Commons/SimpleSearch.js";

const useStyles = createUseStyles(theme => ({
    gridContainer: {
        'position': 'relative',
        // with big/small subheader like permute page
        height: ({bigSubHeader}) => `calc(100vh - ${bigSubHeader ? theme.bigSubHeader + theme.headerHeight : theme.smallSubHeader + theme.headerHeight}px)`,
        // without subheader
        //height: `calc(100vh - ${theme.headerHeight}px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        display: 'grid',
        gridTemplateColumns: '1fr',
        [theme.mUp]: {
            padding: [theme.spacing * 4],
        },
        [theme.s]: {
            padding: [theme.spacing],
        },
    },
    doubleRow: {
        ...theme.grid.doubleRow,
    },
    greenField: {
        backgroundColor: theme.colors.greenLight,
        height: "auto",
        width: "90%",
        margin: [-theme.spacing, 0],
        alignItems: "center",
        display: "grid",
        padding: [theme.spacing * 2,4],
        borderLeft: `1px solid ${theme.colors.lightGrey}`,
        borderRight: `1px solid ${theme.colors.lightGrey}`,
    }
}));

const taskStatusColors = {
    open: 'primary',
    active: 'warning',
    closed: 'success'
};

const ListWashes = () => {
    const dispatch = useDispatch();
    const [washList, setWashList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({});
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(null);
    //based on the type {"big","small"} property of subHeader component
    const classes = useStyles({bigSubHeader: false}); // or false if type is small

    const getCarsToWash = async (actualPage = 1) => {
        if (lastPage && actualPage > lastPage) return;
        if(actualPage === 1)  setWashList([])
        setLoading(true);
        clearFilters(filters);
        try {
            const {data: {data: newWashList, last_page}} = await httpGetTaskType('wash', {limit: 20, ...filters, page: actualPage});
            if(actualPage === 1){
                setWashList(newWashList);
            }else{
                setWashList(washList.concat(newWashList));
            }
            setLastPage(last_page)
        } catch (e) {
            errorHandler(e, dispatch);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getCarsToWash(1);
    }, []);

    //fetch when filters change
    useEffect(() => {
        if (!!Object.keys(filters).length) {
            setPage(1);
            setLastPage(null);
            getCarsToWash(1);
        }
    }, [filters]);

    //fetch when page change
    useEffect(() => {
        if (!!washList.length && page !== 1) {
            getCarsToWash(page)
        }
    }, [page]);


    const onChangePage = (isFilter = false) => {
        if (isFilter) {
            setPage(1)
        } else {
            setPage(page + 1)
        }
    };

    const onDataSorting = (newSort) => {
        setFilters({
            ...filters,
            ...newSort,
        });
        setPage(1);
    };

    const onRowClickPath = (item) => {
        return `/stock/${item?.car_id}`;
    };

    return <>
        <SubHeading title={"Lista Lavaggi"} type={'small'} justifySelf={'unset'}
                    rightSearchComponent={<SimpleSearch filters={filters} setFilters={setFilters} name={'plate'}
                                                        placeholder={'cerca targa'} setPage={setPage}/>}
        />
        <section className={classes.gridContainer}>
            <Table
                id={'table'}
                data={washList}
                inLoading={loading}
                filters={filters}
                onSetPageCb={onChangePage}
                onSortingDataCb={onDataSorting}
                theadHeight={40}
                rowMinHeight={48}
                classNames={{
                    table: classes.table,
                    thead: classes.thead,
                    row: classes.row,
                }}
                //real name is created_at not date
                columns={['brand_name', 'model_name', 'plate', 'wash_type', 'updated_at', 'status']}
                columnsNames={{
                    brand_name: "Marca",
                    model_name: "Modello",
                    plate: "Targa",
                    wash_type: "Tipologia di lavaggio",
                    updated_at: "Data di assegnazione",
                    status: "Stato",
                }}
                columnsWidths={{
                    brand: '10%',
                    model: "25%",
                    plate: "10%",
                    wash_type: "20%",
                    updated_at: '25%',
                    status: "10%",
                }}
                columnsAlignments={{
                    status: 'center',
                    mileage: 'right',
                }}
                backEndSortable={true}
                sortable={true}
                //headersSorters={['assign_date', 'user', 'status']}
                columnsRenderers={{
                    brand: (value) => <span>{value}</span>,
                    model: (value) => <span>{value}</span>,
                    plate: (value) => <strong>{value}</strong>,
                    wash_type: (value) => <span>{value}</span>,
                    updated_at: (value) => <span>{formattedDate(value)}</span>,
                    status: (value,items) => <span style={{opacity: (!items?.is_active) ? 0.7 : 1 }}>
                        <Badge title={value && getLabelByValue(taskStatuses, value)} statusColor={taskStatusColors[value]} badgeSize={'small'}/>
                    </span>,
                }}
                onRowClickPath={onRowClickPath}
            />
        </section>
    </>

};

export default ListWashes;
