import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useHistory, useParams} from 'react-router-dom';
import {httpChangeCarStatus} from '../../HttpRequests/commons.http.js';
import {httpGetCarTasks} from "../../HttpRequests/tasks.http";
import {useDispatch, useSelector} from 'react-redux';
import {setCarTasks, setCurrentCar} from '../../store/actions/carsActions';
import {alertToggle} from '../../store/actions/alertActions.js';
import BreadCrumbsRender from '../../Commons/BreadCrumbsRender';
import StepInfo from '../../Commons/StepInfo';
import defaultImage from '../../assets/images/car-profile-placeholder.jpg';
import PropTypes from 'prop-types';
import {errorHandler} from "../../helpers/utils";
import CarCardInfo from "../../Commons/CarCardInfo.js";
import CarCardPositionInfo from "../../Commons/CarPositionInfo.js";
import Spinner from "../../Commons/Spinner.js";
import Button from "../../Commons/Button.js";
import CarToRetrievePositionInfo from "./CarToRetrievePositionInfo.js";
import {httpGetAllCarLocations} from "../../HttpRequests/shifts.http.js";

const useStyles = createUseStyles((theme) => ({
    baseStyles: {
        position: 'relative',
        display: 'grid',
        gridTemplateRows: '1fr 0.5fr',
        boxSizing: 'border-box',
        alignItems: 'center',
        background: theme.colors.white,
        zIndex: 2
    },
    headingContent: {
        display: 'grid',
        gridTemplateColumns: ({toRetrieve}) => toRetrieve ? '1fr 0.7fr 1fr' : '1fr 1fr 0.7fr',
        position: 'absolute',
        top: 88,
        width: '77vw',
        maxWidth: 1300,
        margin: [0, theme.spacing * 2],
        gridColumnGap: theme.spacing * 2
    },
    headingTitle: {
        padding: [theme.spacing * 2, 0],
        margin: [theme.spacing, theme.spacing * 2],
        '& h4': {
            fontWeight: 'bold',
            marginBottom: theme.spacing * 2,
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            alignItems: "center",
            gridColumnGap: theme.spacing * 2
        }
    },
}));

const CarHeading = ({car, headingTitle}) => {
    const {id} = useParams();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const statuses = useSelector(state => state.autocompletes.carStatuses);
    const dispatch = useDispatch();
    const classes = useStyles({toRetrieve: car?.status === 'to_retrieve'});
    const carPercentage = car?.closed_tasks_percentage ? +car.closed_tasks_percentage : 0;
    const carImage = car?.profilePic;
    const [canGoToStock, setCanGoToStock] = useState(false);
    const [locations, setLocations] = useState([]);

    const getCarTasks = async (id) => {
        setLoading(true);
        try {
            const {data: tasks} = await httpGetCarTasks(id);
            dispatch(setCarTasks(tasks));
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getCarLocations()
        if (+car?.id !== +id) {
            getCarTasks(id);
        }
        /* return () => {
             dispatch(setCarTasks([])); //dispatch and set to zero tasks redux store on component unmount
         }*/
    }, []);

    const getCarLocations = async () => {
        setLoading(true)
        try {
            const {data} = await httpGetAllCarLocations();
            setLocations(data.filter((location => location.default_location)).map(location => ({
                label: location.ubication_name,
                value: location.id,
                location_name: location.name
            })));
        } catch (e) {
            errorHandler(e, dispatch);
        } finally {
            setLoading(false)
        }
    }

    const changeCarStatus = async (value) => {
        //necessary field exeptions
        if(car?.sd_card === null){
            dispatch(alertToggle(`Il campo scheda sd deve essere compilato.`, 'error'));
            return;
        }
        if(car?.second_key === null){
            dispatch(alertToggle(`ll campo seconda chiave deve essere compilato.`, 'error'));
            return;
        }
        if((car?.sd_card || car?.second_key) && !car?.box_number){
            dispatch(alertToggle(`ll campo box deve essere compilato.`, 'error'));
            return;
        }

        setLoading(true);
        const carBefore = {...car};
        try {
            dispatch(setCurrentCar({...car, status: value})); //update car local object
            await httpChangeCarStatus(id, value);
            dispatch(alertToggle(`Stato del veicolo modificato correttamente.`, 'success'));
            //fetch new task structure
            if (value === 'to_retrieve' || value === 'in_stock') {
                await getCarTasks(id);
            }
            if (value === 'in_stock') {
               history.push(`/stock/${id}`)
            }
        } catch (e) {
            errorHandler(e, dispatch);
            dispatch(setCurrentCar({...carBefore})); //update car local object
        } finally {
            setLoading(false);
        }
    };

    const canGoToStockCb = (value) => {
        setCanGoToStock(value)
    }

    return <div className={classes.baseStyles}>
        {loading && <Spinner overlayFullscreen={true}/>}
        <div className={classes.headingTitle}>
            <BreadCrumbsRender/>
            {headingTitle && <h4> {headingTitle}
                {car?.status === 'to_retrieve' && <Button width={140} data-xs-small data-primary onClick={() => changeCarStatus('in_stock')} disabled={!canGoToStock}>Manda in stock</Button>}
            </h4>}
        </div>

        <div className={classes.headingContent}>
            <CarCardInfo image={carImage ? carImage : defaultImage}
                         changeCarStatusCb={changeCarStatus} statuses={statuses} car={car}/>
            {car?.status && car.status !== 'to_retrieve' && <StepInfo percentCompleted={carPercentage}/>}
            {car?.status && car.status !== 'to_retrieve' && <CarCardPositionInfo car={car}/>}
            {car?.status && car.status === 'to_retrieve' && <CarToRetrievePositionInfo car={car} canGoToStockCb={(value) =>canGoToStockCb(value)} locations={locations}/>}
        </div>
    </div>
};

CarHeading.propTypes = {
    headingTitle: PropTypes.string
};

export default CarHeading;
