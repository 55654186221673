export const SET_UNREAD_MESSAGE_COUNTER = 'SET_UNREAD_MESSAGE_COUNTER';
export const ADD_UNREAD_MESSAGE = 'ADD_UNREAD_MESSAGE';
export const SET_ALL_UNREAD_MESSAGE_COUNTERS = 'SET_ALL_UNREAD_MESSAGE_COUNTERS';

export const setUnreadMessageCounter = (channel, counter = 0) => {
    return {
        type: SET_UNREAD_MESSAGE_COUNTER,
        payload: {
            channel,
            counter
        }
    };
};

export const addUnreadMessages = (channel, counter = 1) => {
    return {
        type: ADD_UNREAD_MESSAGE,
        payload: {
            channel,
            counter
        }
    };
};


export const setAllUnreadMessages = (affectedChannels) => {
    return {
        type: SET_ALL_UNREAD_MESSAGE_COUNTERS,
        payload: {
            affectedChannels
        }
    };
};



