import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useParams} from 'react-router-dom';
import Spinner from '../../Commons/Spinner';
import CarHeading from '../../Components/Cars/CarHeading';
import {useDispatch, useSelector} from 'react-redux';
import {setCurrentCar} from '../../store/actions/carsActions';
import {
    httpGetCar,
    httpGetCarAccessories,
} from '../../HttpRequests/commons.http.js';
import CarInfoForm from '../../Components/Cars/CarInfoForm';
import {errorHandler} from "../../helpers/utils.js";
import Popover from "../../Commons/Popover.js";
import EditAlert from "../../Components/Cars/EditAlert.js";

const useStyles = createUseStyles(theme => ({
    gridContainer: {
        'position': 'relative',
        // with big/small subheader like permute page
        height: `calc(100vh - ${theme.carSubHeader}px)`,
        // without subheader
        //height: `calc(100vh - ${theme.headerHeight}px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        [theme.mUp]: {
            padding: [theme.spacing * 12, theme.spacing * 4, theme.spacing * 4]
        },
        [theme.s]: {
            padding: [theme.spacing],
        },
    }
}));

const CarPage = () => {
    const {id} = useParams(); //get id of car from url params react-router-dom
    const car = useSelector(state => state.cars.currentCar);
    const classes = useStyles({bigSubHeader: true}); // or false if type is small
    const [loading, setLoading] = useState(false);
    const [showPopover, setShowPopover] = useState(false);
    const dispatch = useDispatch();
    const isAdmin = !useSelector(state => state.users.user.operations.admin);
    const [canEditField, setCanEditField] = useState(false);
    const [accessories, setAccessories] = useState(null);

    useEffect(() => {
        if (+car?.id !== +id) {
            //if the requested car is different from the stored one
            dispatch(setCurrentCar(null));
            getCarData();
        }
        getCarAccessories();
    }, []);

    const getCarData = async () => {
        setLoading(true);
        try {
            const {data: car} = await httpGetCar(id);
            dispatch(setCurrentCar(car));
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    };

    const getCarAccessories = async () => {
        setLoading(true);
        try {
            const {data: accessories} = await httpGetCarAccessories(id);
            setAccessories(accessories);
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    };

    const showAlertToggle = () => {
        setShowPopover(!showPopover);
        setCanEditField(!canEditField);
    };

    return (
        <>
            <CarHeading headingTitle={'Informazioni vettura'} car={car}/>
            {showPopover &&
            <Popover withHeader={false} minWidth={400} width={400} onClose={() => setShowPopover(!showPopover)}>
                <EditAlert onClose={() => setShowPopover(!showPopover)} callback={showAlertToggle} type={'dat'}/>
            </Popover>}
            <section className={classes.gridContainer}>
                {loading && <Spinner overlayFullscreen={true}/>}
                {car?.id &&
                <CarInfoForm car={car} canEditField={canEditField} isAdmin={isAdmin} showAlertCb={setShowPopover}
                             accessories={accessories} />}
            </section>
        </>);
}

export default CarPage