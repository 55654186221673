import React from 'react';
import {createUseStyles} from "react-jss";
import Avatar from "../../../Commons/Avatar";
import Checkbox from "../../../Commons/Checkbox";
import {CloseIcon} from "../../../assets/icons";

const useStyles = createUseStyles(theme => ({
    searchUser: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: 16
    },
    searchBarInput: {

    },
    textarea: {

    },
    usersSelection: {
        margin: [24, 0],
        boxShadow: '0px 0px 8px 1px rgb(0 0 0 / 5%)',
        borderRadius: 16,
        height: 300,
        overflow: 'hidden'
    },
    usersSelectionHeader: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr auto',
        gridTemplateRows: 48,
        gridColumnGap: 16,
        alignItems: "center",
        fontWeight: 700,
        fontSize: 16,
        padding: [0, 24],
    },
    userCounter: {
        color: '#626262',
        fontWeight: 400
    },
    userList: {
        overflow: "auto",
        maxHeight: 252
    },
    userOption: {
        cursor: 'pointer',
        display: 'grid',
        gridTemplateColumns: 'auto auto 1fr',
        gridTemplateRows: 48,
        gridColumnGap: 16,
        alignItems: "center",
        borderBottom: '1px solid #E3E3E3',
        fontWeight: 500,
        padding: [0, 24],
        '&:last-child':{
            borderBottom: 'none',
        }
    },
    usersSelected: {
        display: "flex",
        flexWrap: 'wrap',
        margin: [32, 0, 48, 0],
        padding: [8, 16],
        minHeight: 40,
        borderRadius: 8,
        minWidth: 140,
        border: `1px solid ${theme.colors.darkGrey}`,
        backgroundColor: theme.colors.white,
        '& > span':{
            display: "grid",
            gridTemplateColumns: '1fr 24px',
            alignItems: 'center',
            justifyItems: 'center',
            backgroundColor: 'rgba(23, 29, 41, 0.08)',
            borderRadius: 4,
            fontSize: 12,
            fontWeight: 500,
            color: theme.colors.black,
            padding: [4, 0, 4, 8],
            margin: [0, 4, 4, 0],
        }
    },
    removeIcon: {
        cursor: 'pointer',
        display: 'flex',
        '& > svg': {
            height: '12px !important',
            width: '12px !important',
            '&:hover': {
                fill: theme.colors.red
            }
        }
    },
    selectAll: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 20,
        cursor: 'pointer',
        border: ({hasSelections}) =>  hasSelections ? 'none' : `1px solid rgb(198, 198, 198)`,
        fontWeight: 400,
        borderRadius: 4,
        width: 16,
        height: 16,
        backgroundColor: ({hasSelections}) =>  hasSelections ? theme.colors.red : 'transparent',
        color: theme.colors.white,
    }
}));


const UsersMultiselectDropdown = ({users, selectionCb, selectedUsers}) => {
    const hasSelections = selectedUsers.length > 0;
    const handleUserSelection = (touchedUser, selected) => {
        selectionCb(selected ? selectedUsers.filter(user => user.value !== touchedUser.value) : [...selectedUsers, touchedUser])
    }
    const classes = useStyles({hasSelections});
    return <>
        <div className={classes.usersSelection}>
            <div className={classes.usersSelectionHeader}>
                <span className={classes.selectAll}
                      onClick={() => selectionCb(selectedUsers.length > 0 ? [] : users)}>
                    {selectedUsers.length === users.length
                        ? '+' : hasSelections ? '-': '' }
                </span>
                <span>Utenti</span>
                <span className={classes.userCounter}>{selectedUsers.length} utenti selezionati</span>
            </div>
            <div className={classes.userList}>
                {users.map(user => {
                    const selected = !!selectedUsers.find(u => u.value === user.value);
                    return <div className={classes.userOption} key={user.value} onClick={() => handleUserSelection(user, selected)}>
                        <Checkbox isSmall checked={selected}/>
                        <Avatar size={'small'} img={user.img}/>
                        <div>{user.label}</div>
                    </div>
                })}
            </div>
        </div>
        <div className={classes.usersSelected}>
            {selectedUsers.map(u =>
                <span>
                    {u.name}
                    <span className={classes.removeIcon}>
                        <CloseIcon data-color onClick={() => handleUserSelection(u, true)}/>
                    </span>
                </span>
            )}
        </div>
    </>
}

export default UsersMultiselectDropdown;