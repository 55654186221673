import React, {useEffect} from 'react';
import {Router as BrowserRouter, Switch} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {useDispatch, useSelector} from 'react-redux';
import routes from "./routes";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import {errorHandler, remapOptions} from "./helpers/utils.js";
import {
    httpGetCarFamiliesAutocomplete,
    httpGetCarStatuses,
    httpGetFuelMethodAutocomplete, httpGetStructuresAutocomplete
} from "./HttpRequests/commons.http.js";
import {
    setCarStatusesList,
    setCompaniesAutocomplete,
    setFamiliesAutocomplete,
    setFuelMethodsAutocomplete,
    setLocationsAutocomplete,
    setLocationsSimpleAutocomplete,
    setStructuresAutocomplete,
    setUsersAutocomplete
} from "./store/actions/autocompletesActions.js";
import {httpGetBusinessList, httpGetUsers} from "./HttpRequests/settings.http.js";
import {httpGetAllCarLocations} from "./HttpRequests/shifts.http.js";
import PublicRoute from "./RouteGuards/PublicRoute.js";
import ShowCase from "./Pages/ShowCase.js";
import {httpGetExcludedChatUsers, httpInitChat} from "./HttpRequests/chat.http";
import {usePubNub} from 'pubnub-react';
// Chat
import {
    fetchMemberships,
    createNetworkStatusListener,
    createMessageListener,
    createPresenceListener,
    combineListeners,
    createSignalListener,
    createErrorStatusListener,
    createSubscriptionStatusListener,
    createUserDataListener,
    createChannelDataListener, fetchAllUserData, fetchHereNow
} from "pubnub-redux";
import {
    createMembershipListener,
    updateUnreadMessageCounter
} from "./store/actions/chat/joinedConversationsActions";
import {createTypingIndicatorsListener} from "./store/actions/chat/typingIndicatorActions";
import {getLoggedInUserChannelGroups} from "./store/reducers/user";
import {resetCurrentConversation} from "./store/actions/chat/currentConversationActions";
import {refreshStatus} from "./store/actions/chat/statusActions";
import {setMessageAction} from "./store/actions/chat/messageActions";
import {
    setAllUnreadMessages,
} from "./store/actions/chat/unreadMessageActions";
import {setExcludedChatUsers} from "./store/actions/chat/excludedUsersActions";


export const history = createBrowserHistory({basename: '/'});

history.listen(({pathname}) => {
    window.document.title = pathname.slice(1) + ' | OCH';
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
});

if (process.env.REACT_APP_ENVIRONMENT_BUILD) {
    Sentry.init({
        dsn: "https://408e132f5b8642eca76e06aeed20771f@o266244.ingest.sentry.io/5513922",
        environment: process.env.REACT_APP_ENVIRONMENT_BUILD,
        integrations: [new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        })],
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
        // for user feedback
        beforeSend(event, hint) {
            // Check if it is an exception, and if so, show the report dialog
            if (event.exception) {
                Sentry.showReportDialog({ eventId: event.event_id,
                    lang: 'it',
                    title:'Sembra che tu stia riscontrando un problema',
                    subtitle:'Il nostro team è stato aggiornato.',
                    subtitle2: 'Lasciaci comunque il tuo feedback per aiutarci a risolverlo.',
                    successMessage: 'Grazie, il tuo feedback è stato inviato con successo.'
                });
            }
            return event;
        }
    });
}

export const Router = () => {
    const dispatch = useDispatch();
    const pubnub = usePubNub();
    // this is kept in sync by actions signIn and signOut
    const isAuthenticated = useSelector(state => state.users.access_token);
    const userChannelGroups = useSelector(getLoggedInUserChannelGroups);

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(refreshStatus(false))
            dispatch(resetCurrentConversation)
            getCommonUsedData();
            // Initialize chat to grant permission on user
            initChat();
        }
        return () => {
            dispatch(refreshStatus(false));
            pubnub.unsubscribeAll()
        };
    }, [isAuthenticated]);


    const initChat = async () => {
        try {
            const {data: {uuid, authKey}} = await httpInitChat();
            pubnub.setUUID(uuid);
            pubnub.setAuthKey(authKey);
            // Connect all listeners combined
            console.log('initListeners with UUID:', pubnub.getUUID())
            const messageListener = createMessageListener(dispatch);
            pubnub.addListener(
                combineListeners(
                    createSignalListener(dispatch),
                    createErrorStatusListener(dispatch),
                    createSubscriptionStatusListener(dispatch),
                    createUserDataListener(dispatch),
                    createChannelDataListener(dispatch),
                    createMembershipListener(dispatch),
                    createNetworkStatusListener(dispatch),
                    //createMessageListener(dispatch),
                    createPresenceListener(dispatch),
                    createTypingIndicatorsListener(dispatch),
                    {
                        message: (msg) => {
                            messageListener.message(msg) //reuse the listener func from the pubnub-redux package
                            dispatch(updateUnreadMessageCounter(msg.channel, msg))
                        },
                        messageAction: (action) => {
                            dispatch(setMessageAction(action.channel, action.data.messageTimetoken))
                        }
                    }
                )
            );

            // Subscribe to the user's channel to receive events involving this user
            //const channelGroupsNames = userChannelGroups.filter(ch => ch.name.includes('-direct')).map(ch => ch.name);
            const channelGroupsNames = userChannelGroups.map(ch => ch.name);
            console.log('subscribing to', channelGroupsNames);
            pubnub.subscribe({
                channelGroups: channelGroupsNames,
                withPresence: true
            })

            // User's channels (not categorized)
            // Populates "joinedConversations"
            const {payload} = await dispatch(fetchMemberships({
                uuid,
                include: {
                    totalCount: true,
                    customFields: true,
                    channelFields: true,
                    customChannelFields: true
                }
            }));
            // Add unread badges on channels
            const channelsWithCustom = payload.response?.data.filter(membership => membership.custom);
            const channelNames = channelsWithCustom.map(membership => membership.channel.id);
            const lastReadTimetokens = channelsWithCustom.map(membership => membership.custom.lastReadTimetoken);
            if(lastReadTimetokens.length > 0){
                pubnub.messageCounts({
                    channels: channelNames,
                    channelTimetokens: lastReadTimetokens,
                }, (status, results) => {
                    if (!results) return;
                    dispatch(setAllUnreadMessages(results.channels))
                });
            }
            dispatch(fetchAllUserData());
            dispatch(fetchHereNow({
                channels: channelNames
            }));
            dispatch(refreshStatus(true))
        }catch (e){
            console.log('Cannot initialize chat', e)
        }
    }

    const getExcludedChatUsers = async () => {
        try {
            const {data: users} = await httpGetExcludedChatUsers();
            dispatch(setExcludedChatUsers(users));
        } catch (e) {
            errorHandler(e, dispatch);
        }
    };

    const getCarStatuses = async () => {
        try {
            const {data: statuses} = await httpGetCarStatuses();
            dispatch(setCarStatusesList(remapOptions(statuses)));
        } catch (e) {
            errorHandler(e, dispatch);
        }
    };

    const getGetUsers = async () => {
        try {
            const {data: users} = await httpGetUsers({limit: 250});
            dispatch(setUsersAutocomplete(users.data.map((item) => ({
                label:item.full_name,
                value: item.id,
                img:item.photo_link,
                name: item.first_name,
                company_id: item.company?.id
            }))));
        } catch (e) {
            errorHandler(e, dispatch);
        }
    };

    const getGetBusinessList = async () => {
        try {
            const {data: companies} = await httpGetBusinessList({limit: 250});
            dispatch(setCompaniesAutocomplete(companies.data.map((item) => ({label:item.name,value: item.id,img:item.logo_link}))));
        } catch (e) {
            errorHandler(e, dispatch);
        }
    };

    const getAllCarLocations= async () => {
        try {
            const {data: locations} = await httpGetAllCarLocations();
            dispatch(setLocationsAutocomplete(locations.map((item) => ({label:item.name,value: item.id}))));
            dispatch(setLocationsSimpleAutocomplete(locations.map((item) => ({label:item?.ubication_name,value: item.id}))));
        } catch (e) {
            errorHandler(e, dispatch);
        }
    };

    const getAllCarFamilies = async () => {
        try {
            const {data: families} = await httpGetCarFamiliesAutocomplete();
            dispatch(setFamiliesAutocomplete(families));
        } catch (e) {
            errorHandler(e, dispatch);
        }
    };

    const getStructuresAutocomplete = async () => {
        try {
            const {data: structures} = await httpGetStructuresAutocomplete();
            dispatch(setStructuresAutocomplete(structures));
        } catch (e) {
            errorHandler(e, dispatch);
        }
    };

    const getFuelMethodAutocomplete = async () => {
        try {
            const {data: fuelMethods} = await httpGetFuelMethodAutocomplete();
            dispatch(setFuelMethodsAutocomplete(fuelMethods));
        } catch (e) {
            errorHandler(e, dispatch);
        }
    };

    const getCommonUsedData = () => {
        getCarStatuses();
        getGetUsers();
        getGetBusinessList();
        getAllCarLocations();
        getAllCarFamilies();
        getStructuresAutocomplete();
        getFuelMethodAutocomplete();
        getExcludedChatUsers();
    };



    return (
        <BrowserRouter history={history} basename={'/'}>
            <Switch>
                <PublicRoute path={'/showcase'} exact={true} component={ShowCase}/>
                {routes.map(({routeType: RouteComponent, ...rest}, index) => <RouteComponent {...rest} key={index}/>)}
            </Switch>
        </BrowserRouter>
    );
};

export default Sentry.withProfiler(Router);
