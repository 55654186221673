import React, {useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useDispatch} from 'react-redux';
import {setLoggedUser} from '../../store/actions/userActions';
import {useForm} from 'react-hook-form';
import {Link} from 'react-router-dom';
import {httpLoginUser} from "../../HttpRequests/user.http";
import Spinner from "../../Commons/Spinner";
import Text from "../../Commons/Text";
import Button from "../../Commons/Button";
import * as Yup from "yup";
import logoDark from "../../assets/images/black.svg";
import {setErrors} from "../../helpers/utils.js";

const useStyles = createUseStyles(theme => ({
    root: {

        position: 'relative',
        'max-width': 500,
        'margin': [theme.spacing * 4, 'auto'],
        'display': 'grid',
        'grid-auto-flow': 'row',
        'grid-row-gap': theme.spacing * 3 + 'px',
        [theme.s]: {
            width: 320,
            padding: [theme.spacing * 4, theme.spacing * 3],
            gridTemplateColumns: 280,
            justifyContent: "center",
        },
        [theme.mUp]: {
            'width': '100%',
            'padding': [theme.spacing * 5, theme.spacing * 6],
            gridTemplateColumns: 350,
            justifyContent: "center",
        },
        '& img': {
            width: 140,
            marginBottom: theme.spacing * 2
        }
    },

    error: {
        'text-align': 'center',
        'color': theme.colors.red,
    },
    title: {
        fontWeight: 500,
        textAlign: "left",
        margin: [theme.spacing * 3, 0, theme.spacing * 2, 0],
        color: theme.colors.black,
        [theme.s]: {
            fontSize: 28,
        },
        [theme.mUp]: {
            fontSize: 38,
        },
    },
    subtitle: {
        margin: [theme.spacing, 0, theme.spacing * 2, 0],
        'color': theme.colors.darkGrey,
    },
    link: {
        ...theme.link,
        display: 'block',
        marginTop: theme.spacing * 2,
        zIndex: 2,
        position: 'relative'
    },
    composed: {
        'color': theme.colors.darkGrey,
        fontSize: "15px",
        textAlign: "center",
        '& a': {
            ...theme.link,
        }
    }
}));

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email(`L'email inserita non è un email valida`)
        .required(`Inserisci l'email.`),
    password: Yup.string()
        .required(`Inserisci la password.`)
        .min(8, 'La password deve contenere almeno 8 caratteri'),
});

const Login = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const {register, handleSubmit, errors, setError} = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            email: '',
            password: ''
        },
        validationSchema
    });

    const onSubmit = async (values) => {
        try {
            setIsLoading(true);
            const {data: {access_token, refresh_token, user}} = await httpLoginUser(values);
            dispatch(setLoggedUser({user, access_token, refresh_token}));
        } catch (error) {
            if (error?.response?.data.message) {
                const message = error.response ? error.response?.data.message : error.message ? error.message : 'Errore di sistema. Ricarica la pagina e riprova.';
                setErrorMessage(message)
                //call setAlert action here if needed to show a snackbar
            } else {
                setErrors(error.response.data, setError);
            }
        } finally {
            setIsLoading(false);
        }
    };

    return <>
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
            {isLoading && <Spinner/>}
            <div>
                <img src={logoDark} alt='logo'/>
                <h2 className={classes.title}>Login</h2>
                <p className={classes.subtitle}>Inserisci le tue credenziali per procedere</p>
            </div>
            <Text type={'text'} label={'Email'} name={'email'} ref={register} errors={errors.email}
                  style={{zIndex: 2}}/>
            <div>
                <Text type={'password'} label={'Password'} name={'password'} ref={register} errors={errors.password}
                      style={{zIndex: 2}}/>
                <Link to={'/reset-password'} className={classes.link}>Password dimenticata?</Link>
            </div>
            <Button type={'submit'} data-primary style={{zIndex: 2}}> Accedi </Button>
            {!!errorMessage && <p className={classes.error}>{errorMessage}</p>}
        </form>
    </>
};

export default Login;
