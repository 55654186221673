import React from "react";
import {useSelector} from "react-redux";
import {createUseStyles} from "react-jss";
import PresenceIndicator from "../PresenceIndicator/PresenceIndicator";

const useStyles = createUseStyles(theme => ({
    wrapper: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gridColumnGap: 8,
        justifyContent: 'start',
        alignItems: 'center',
        fontSize: 12
    }
}))

const NetworkStatus = () => {
    const classes = useStyles();
    let isConnected = useSelector(state => state.chats.networkStatus.isConnected);

    return <span className={classes.wrapper}>
       <PresenceIndicator title={isConnected ? "connesso" : "inattivo"}
                          active={isConnected}/>
        {isConnected ? "Online" : "Inattivo"}
    </span>
}
export default NetworkStatus;