import React, {useState} from 'react';
import {createUseStyles} from 'react-jss';
import PropTypes from 'prop-types';
import Table from "../../Commons/Table.js";
import {
    formattedDateIso, repairTypeResolver,
    toMoney
} from "../../helpers/utils.js";
import {ArrowDown, ArrowUp} from "../../assets/icons.js";
import RestoreWorkerTableSubrow from "../Cars/RestoreWorkerTableSubrow.js";
import RestoreWorkerTableFooter from "../Cars/RestoreWorkerTableFooter.js";
import InlineDocumentUpload from "../Cars/InlineDocumentUpload.js";

const useStyles = createUseStyles((theme) => ({
    root: {
        marginTop:80,
    },
    repairTitle:{
        fontSize: 18,
        fontWeight: 600,
    },
    doc:{
        '& a':{
            fontSize: 12,
            color: theme.colors.darkGrey,
            textDecoration: 'underline!important'
        }
    }
}));

const ExternalWorkTable = ({setDataCallback, data}) => {
    const classes = useStyles({});
    const [rowExpandedId, setRowExpandedId] = useState(null);

    const retrieveInvoiceDoc = (item) => {
        return item?.attachments.filter( attach => (attach.type === 'invoice'))[0];
    };

    const retrieveSupplierDoc = (item) => {
        if(item?.attachments.some(attach => (attach.type === 'supplier_invoice'))){
            return item?.attachments.filter( attach => (attach.type === 'supplier_invoice'))[0];
        }else{
            return item?.attachments.filter( attach => (attach.type === 'supplier_list'))[0];
        }
    };

    return <div className={classes.root}>
        <Table
            noHeightLimit={true}
            id={'table'}
            data={data}
            rowExpandedId={rowExpandedId}
            setRowExpandedId={setRowExpandedId}
            onSetPageCb={Function.prototype}
            onSortingDataCb={Function.prototype}
            setData={() => setDataCallback()}
            theadHeight={56}
            rowMinHeight={56}
            classNames={{
                table: classes.table,
                thead: classes.thead,
                row: classes.row,
            }}
            columns={['name', 'total_amount', 'estimate', 'negotiated_amount', 'negotiated_doc', 'invoice_amount', 'invoice_doc', 'expander']}
            columnsNames={{
                name: "",
                total_amount: "tot. perizia",
                estimate: "Perizia",
                negotiated_amount: "tot. aff. lavoro",
                negotiated_doc: "Aff. lavoro",
                invoice_amount: "tot. fattura",
                invoice_doc: "fattura",
                expander: "",
                actions: ""
            }}
            columnsWidths={{
                name: "16%",
                total_amount:"10%",
                estimate: "13%",
                negotiated_amount: "10%",
                negotiated_doc: "15%",
                invoice_amount: "15%",
                invoice_doc: "15%",
                expander: "3%",
            }}
            columnsAlignments={{
                total: 'right'
            }}
            expandedComponent={RestoreWorkerTableSubrow}
            tableFooterComponent={RestoreWorkerTableFooter}
            columnsRenderers={{
                name: (value, items) => <span className={classes.repairTitle}>
                    {items?.assigned_user?.full_name}
                </span>,
                total_amount: (value) => <span> {toMoney(value)}</span>,
                estimate: (value, items) => <span> Perizia del {formattedDateIso(items.created_at)}</span>,
                negotiated_amount: (value, items, metadata) =>  <span>{toMoney(data?.negotiated_amount ? data.negotiated_amount : value )}</span>,
                negotiated_doc: (value, items) => {
                    const supplierDoc = retrieveSupplierDoc(items);
                    return <span className={classes.doc}>
                        {supplierDoc ?  <a href={supplierDoc.url} target={'_blank'}>
                            {supplierDoc.filename} </a> : 'N.d'}
                </span>},
                invoice_amount: (value) => <span> {toMoney(value)} </span>,
                invoice_doc: (value, items) => {
                    return <InlineDocumentUpload data={items} type={`invoice`} list={repairTypeResolver(items.type)}
                                                  attachment={items?.attachments.filter((attach) => (attach.type === 'invoice' && attach.repair_id === items.id))[0]}/>
                },
                expander: (item, value, metadata) => <Expander rowIndex={metadata.rowIndex} rowExpandedId={rowExpandedId}
                                                               setRowExpandedId={setRowExpandedId}/>,
            }}
        />
    </div>
};

ExternalWorkTable.propTypes = {
    setDataCallback: PropTypes.any,
    data: PropTypes.array,
};

/** Expander Component **/
const useStylesExpander = createUseStyles(theme => ({
    root: theme.grid.actions,
}));

export const Expander = ({setRowExpandedId, rowIndex, rowExpandedId}) => {
    const classes = useStylesExpander();
    const isExpanded = (rowIndex === rowExpandedId);
    return (
        <div className={classes.root}>
            <span onClick={() => {
                setRowExpandedId(isExpanded ? null : rowIndex)
            }}>
                {isExpanded ? <ArrowUp/> : <ArrowDown/>}
            </span>
        </div>
    );
};

export default ExternalWorkTable;