export const SET_SHIFTS_LIST = 'SET_SHIFTS_LIST';
export const UPDATE_SHIFTS_LIST = 'UPDATE_SHIFTS_LIST';
export const SET_SHIFT_REQUESTS_LIST = 'SET_SHIFT_REQUESTS_LIST';

export const setShiftsList = (shiftsList) => {
    return {
        type: SET_SHIFTS_LIST,
        shiftsList: shiftsList
    }
}

/**
 * How to use updateShift:
 * dispatch(updateShift(shift));
 **/
export const updateShift = (shift) => {
    return {
        type: UPDATE_SHIFTS_LIST,
        shift: shift,
    };
};

export const setShiftRequestsList = (shiftRequestsList) => {
    return {
        type: SET_SHIFT_REQUESTS_LIST,
        shiftRequestsList: shiftRequestsList
    }
}
