import React, {useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import Text from "../../../Commons/Text.js";
import Spinner from "../../../Commons/Spinner.js";
import {errorHandler} from "../../../helpers/utils.js";
import {httpEditDamageDefaultValue} from "../../../HttpRequests/settings.http.js";
import {alertToggle} from "../../../store/actions/alertActions.js";
import {editDamageInList} from "../../../store/actions/settingsActions.js";

const useStyles = createUseStyles(theme => ({
    root: {
        position: 'relative',
        'display': 'grid',
        'grid-auto-flow': 'row',
        'grid-row-gap': theme.spacing,
        'grid-column-gap': theme.spacing * 5,
        [theme.s]: {
            width: 320,
            gridTemplateColumns: '1fr',
            justifyContent: "center",
        },
        [theme.mUp]: {
            'width': '100%',
            gridTemplateColumns: '0.3fr 1fr',
            justifyContent: "center",
        },
    },

    sectionTitle: {
        display: 'grid',
        gridColumn: '1 / span 3',
        fontWeight: 600,
        textAlign: "left",
        fontSize: 16,
        padding: [theme.spacing * 2, 0, 0],
        //borderTop: `1px solid ${theme.colors.lightGrey}`
    },

    head: {
        display: 'grid',
        marginBottom: theme.spacing * 2,
        gridTemplateColumns: '0.3fr 1fr',
        alignItems: 'center',
        gridColumn: '1 / span 3',
        fontSize: 16,
        fontWeight: 600
    },

    row: {
        display: 'grid',
        'grid-row-gap': theme.spacing,
        gridTemplateColumns: '0.3fr 1fr',
        alignItems: 'center',
        gridColumn: '1 / span 3',
        fontSize: 14,
        borderBottom: `1px solid ${theme.colors.lightGrey}`,
        paddingBottom: 16,
    },

    inputGroup: {
        display: "grid",
        alignItems: "center",
        gridAutoFlow: "column",
        gridColumnGap: theme.spacing * 3,
        gridAutoColumns: 'max-content',
    },
    damageInput:{
        maxWidth: 200
    }
}));

const DamagesGroupValues = ({damagesGroup}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const {register, watch} = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            ...useSelector(state => state.settings.damagesFlatList),
        },
    });

    const setDamage = async (price,oldPrice, id, label) => {
        //console.log(price,oldPrice, id, label);
        //console.log(damages);
        if(+price === +oldPrice) return;
        setIsLoading(true);
        try{
            await httpEditDamageDefaultValue(id,price);
            dispatch(editDamageInList({key:id, price:price}));
            dispatch(alertToggle(`Il valore del campo ${label} è stato modificato.`, 'success'))
        }catch (e) {
            errorHandler(e,dispatch)
        }finally {
            setIsLoading(false)
        }
    };

    return <form className={classes.root}>
        {isLoading && <Spinner overlayFullscreen={true}/>}
        <div className={classes.sectionTitle} style={{padding: 0}}/>
        <div className={classes.head}>
            <span> Pezzo </span>
            <span> Valori </span>
        </div>
        {damagesGroup.map((damage, index) => {
            return <div className={classes.row} key={index}>
                <label> {damage.title} </label>
                <div className={classes.inputGroup}>
                    {damage.damage_sets[0].options.map(({id,price,label}, radioIndex) => {
                        return <Text type={'text'} ref={register} name={id} label={label} key={radioIndex} className={classes.damageInput}
                                      onBlur={() => setDamage(watch(id.toString()),price, id, `${damage.title}[${label}]` )}/>
                    })}
                </div>
            </div>
        })}
    </form>
};

export default DamagesGroupValues;
