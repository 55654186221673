import React, {useState} from 'react';
import {createUseStyles} from "react-jss";
import {useForm} from "react-hook-form";
import Spinner from "../../Commons/Spinner.js";
import Text from "../../Commons/Text.js";
import Button from "../../Commons/Button.js";
import * as Yup from "yup";
import {useDispatch} from "react-redux";
import {alertToggle} from "../../store/actions/alertActions.js";
import {httpCarsIdentify, httpNewCarsIdentify} from "../../HttpRequests/barters.http.js";

const useStyles = createUseStyles(theme => ({
    root: {
        display: 'grid',
        width: '100%',
        gridRowGap: theme.spacing * 3,
        justifyItems: 'center',
        '& button':{
            marginTop: theme.spacing * 3
        }
    },
    title:{
        fontWeight: 600,
        margin: [theme.spacing * 2, 0]
    }
}));

const validationSchema = Yup.object().shape({
    plate: Yup.string()
        .required(`Inserisci la targa.`),
    km: Yup.string()
        .required(`Inserisci i km.`)

});

const SearchStep = ({setStep,setCarsId,setPlate,setAlreadySetMessage,setKm,isNewVehicle = false}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const {register, handleSubmit, formState, control, errors, setError} = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            plate: '',
            km: ''
        },
        validationSchema
    });

    const onSubmit = async (values) => {
        try {
            setIsLoading(true);
            const {data} = isNewVehicle ? await httpNewCarsIdentify(values) : await httpCarsIdentify(values);
            setCarsId(data.id);
            setStep('successEurotax')
        } catch (e) {
            if (e.response) {
                //console.log('errore', e.response)
                if(e.response.status === 503){
                    setStep('datStep');
                    setKm(values.km);
                    setPlate(values.plate);
                }else if(e.response.status === 409){
                    setStep('alreadySet');
                    setAlreadySetMessage(e.response.data.message);
                    setKm(values.km);
                    setPlate(values.plate);
                }else{
                    dispatch(alertToggle(e.response?.data?.message,'error'))
                }
            } else {
                // Something happened in setting up the request and triggered an Error 500
                console.log('Error', e.message);
                dispatch(alertToggle(e.message ? e.message : 'Errore di sistema. Riprovare.','error'))
            }
        } finally {
            setIsLoading(false);
        }
    };

    return <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <h4 className={classes.title}> Inserisci i dati del veicolo </h4>
        {isLoading && <Spinner/>}
        <Text type={'text'} label={'Targa'} name={'plate'} ref={register} errors={errors.plate}/>
        <Text type={'number'} label={'km'} name={'km'} ref={register} errors={errors.km}/>
        <Button width={250} type={'submit'} data-primary > Avanti </Button>
    </form>
};

export default SearchStep;