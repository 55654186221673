import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import PropTypes from 'prop-types';

const useStyles = createUseStyles((theme) => ({
    baseStyles: {
        display: 'grid',
        alignItems: 'center',
        justifyItems: 'stretch',
        height: '100%',
        textAlign: 'center'
    }
}));

const EditableInput = ({inputText, callback, placeholder = null, disabled}) => {
    const [inputVisible, setInputVisible] = useState(false);
    const [text, setText] = useState(inputText);
    const classes = useStyles({});

    useEffect(() => {
        setText(inputText)
    }, [inputText])

    const handleBlur = () => {
        callback({note: text});
        setInputVisible(false);
    };

    const handleChange = (evt) => {
        setText(evt.target.value);
    };

    return <span className={classes.baseStyles}>
			{inputVisible ?
                <input value={text} autoFocus onBlur={handleBlur} onChange={handleChange} placeholder={placeholder}/>
                : <span onClick={() => !disabled && setInputVisible(true)}>{text ? text : placeholder}</span>}
		</span>
};

EditableInput.propTypes = {
    inputText: PropTypes.string,
    callback: PropTypes.func
};

export default EditableInput;
