import React, {useEffect} from 'react';
import {createPortal} from 'react-dom';
import {createUseStyles} from 'react-jss';
import cx from 'classnames';
import useKey from 'use-key-hook';
import {CloseIcon} from '../assets/icons.js';
import PropTypes from "prop-types";

const useStyles = createUseStyles(theme => ({
    overlay: {
        // fullscreen
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: ({contextMode}) => contextMode ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,0.5)',
        display: 'grid',
        alignContent: 'center',
        justifyContent: 'center',
        zIndex: 11,
    },

    root: {
        position: 'fixed',
        'min-width': ({minWidth}) => minWidth || undefined,
        width: ({width}) => width || undefined,
        height: ({height}) => height || undefined,
        top: ({y}) =>  y ? y : '50%',
        left: ({x}) =>  x ? x : '50%',
        transform: ({x}) =>  x ? 'unset' : 'translate(-50%,-50%)',
        backgroundColor: theme.colors.white,
        overflow: 'visible',
        zIndex: 12,
        borderRadius: 16,
        [theme.s]: {
            position: "fixed",
            top: "0",
            left: "0",
            transform: "none",
            zIndex: "12"
        },
    },

    header: {
        display: ({contextMode}) => contextMode ? 'none':'grid',
        gridTemplateColumns: ({withHeader}) => withHeader ? '1fr max-content' : '1fr',
        'grid-column-gap': theme.spacing * 2,
        justifyItems: ({withHeader}) => withHeader ? 'center' : 'end',
        alignItems: 'center',
        padding: theme.spacing * 2,
        position: 'relative',
        fontWeight: 600,
        '& h4':{
            marginTop: 32
        }
    },

    body:{
        padding: ({contextMode}) => contextMode ? 0 : `${theme.spacing * 3}px`
    },

    closeIcon: {
        width: '18px!important',
        fill: theme.colors.darkGrey,
        transition: 'ease-in 300ms',
        position: 'absolute',
        top: theme.spacing * 2,
        right: theme.spacing * 2,
        '&:hover':{
            fill: theme.colors.red,
        }
    },
    message: {

    }
}));

const Popover = ({children, className, bodyClassName, minWidth, width, height, overlay = true, title, message, onClose, withHeader,x,y,contextMode}) => {
    const classes = useStyles({minWidth, width, height,withHeader,x,y,contextMode});

    useEffect(() => {
        window.document.body.style['overflow-y'] = 'hidden'; // lock body scroll
        return () => window.document.body.style['overflow-y'] = 'auto'; // unlock body scroll;
    }, []);

    useKey(onClose, {detectKeys: [27]});

    const render = (
        <>
            {overlay && <div className={classes.overlay} onClick={onClose}/>}

            <section className={cx(classes.root, className)}>
                {/* {'x' + x + ' y' + y + ' width' + width} */}
                {withHeader ? <header className={classes.header}>
                    <h4>{title}</h4>
                    {message && <span className={classes.message}>{message}</span>}
                    <CloseIcon data-color className={classes.closeIcon} onClick={onClose}/>
                </header> : <header className={classes.header}>
                    <CloseIcon data-color className={classes.closeIcon}  onClick={onClose}/>
                </header>}

                <div className={cx(classes.body, bodyClassName)}>
                    {children}
                </div>
            </section>
        </>
    );

    return createPortal(render, document.body);
};


Popover.propTypes = {
    className: PropTypes.string,
    minWidth: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    overlay: PropTypes.bool,
    title: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    withHeader: PropTypes.bool,
    x: PropTypes.number,
    y: PropTypes.number,
    contextMode: PropTypes.bool
};

export default Popover;
