import React, {useState, useCallback, useRef, useEffect} from "react";
import {createUseStyles} from "react-jss";
import {useDispatch, useSelector} from "react-redux";
import MessageListItem from "./MessageListItem/MessageListItem";
import UserInitialsAvatar from "../UserInitialsAvatar/UserInitialsAvatar";
import {
    getCurrentConversationMessages,
    getUnknownUsers
} from "../../../../store/reducers/chat/chats";
import {getCurrentConversationId} from "../../../../store/reducers/chat/currentConversation";
import {getHistoryPaginationStateById} from "../../../../store/reducers/chat/pagination";
import {setHistoryPagination} from "../../../../store/actions/chat/pagnationActions";
import {fetchUserData} from "pubnub-redux";
import {usePagination} from "../../../../hooks/usePagination";
import {convertTimestampToDate} from "../../chatUtils";
import {fetchMessageHistory} from "../../../../store/actions/chat/chatActions";


const useStyles = createUseStyles(theme => ({

    root: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        overflowY: 'scroll',
        height: '100%',
        paddingBottom: '10px',

    },
    dateBadge: {
        display: "flex",
        justifyContent: 'center',
        margin: 8,
        '& > span': {
            borderRadius: 12,
            padding: 8,
            backgroundColor: '#434343',
            color: theme.colors.white,
            fontSize: 12
        }
    }
}))


// prevent multiple fetches for the same data
const wasFetched = (() => {
    const ids = {};
    return (id) => {
        if (ids.hasOwnProperty(id)) {
            return true;
        } else {
            ids[id] = true;
            return false;
        }
    };
})();

const MessageList = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const messages = useSelector(getCurrentConversationMessages);
    const conversationId = useSelector(getCurrentConversationId);
    const [conversationsScrollPositions, setConversationsScrollPositions] = useState({});
    const [height, setHeight] = useState(0);

    // SCROLL HANDLING
    const updateCurrentConversationScrollPosition = (scrollPosition) => {
        setConversationsScrollPositions({
            ...conversationsScrollPositions,
            [conversationId]: scrollPosition
        });
    };

    const handleScroll = (e) => {
        const scrollPosition = e.target.scrollTop;
        if (scrollPosition !== 0) {
            updateCurrentConversationScrollPosition(scrollPosition);
        }
    };

    // PAGINATION HANDLING
    const storedPaginationState = useSelector(getHistoryPaginationStateById)[conversationId];

    const restorePaginationState = useCallback(() => {
        return storedPaginationState;
    }, [storedPaginationState]);

    const savePaginationState = useCallback(
        (channel, pagination, count, pagesRemain) => {
            dispatch(
                setHistoryPagination(channel, { pagination, count, pagesRemain })
            );
        },
        [dispatch]
    );

    const getNextPage = useCallback(
        async (tt, total, channel) => {
            const pageSize = 100;
            const action = await dispatch(
                fetchMessageHistory({
                    count: pageSize,
                    channel,
                    start: tt || undefined,
                    stringifiedTimeToken: true,
                    includeMessageActions: true,
                })
            );
            const response = action.payload.response;
            return {
                results: response.messages,
                pagination: `${response.startTimeToken}`,
                pagesRemain: response.messages.length === pageSize
            };
        },
        [dispatch]
    );

    const { containerRef, endRef } = usePagination(
        getNextPage,
        conversationId,
        savePaginationState,
        restorePaginationState
    );


    const restoreConversationScrollPosition = (conversationId) => {
        const conversationScrollPosition=
            conversationsScrollPositions[conversationId];
        if (containerRef.current) {
            if (conversationScrollPosition) {
                containerRef.current.scrollTo(0, conversationScrollPosition);
            } else {
                // scroll to bottom
                containerRef.current.scrollTo(0, containerRef.current.scrollHeight);
            }
        }
    };

    const memoizedRestoreConversationScrollPositionCallback = useCallback(
        restoreConversationScrollPosition,
        [conversationId]
    );


    // UNKNOWN USERS
    const unknownUsers = useSelector(getUnknownUsers);
    useEffect(() => {
        // send requests for missing data
        unknownUsers.forEach(uuid => {
            if (!wasFetched(uuid)) {
                dispatch(
                    fetchUserData({
                        uuid
                    })
                );
            }
        });
    }, [unknownUsers, dispatch]);


    const el = containerRef.current;

    // when history is pulled, scroll down to compensate
    const newHeight = el?.scrollHeight;
    useEffect(() => {
        if (height === 0 && newHeight) {
            setHeight(newHeight);
        } else if (newHeight && newHeight !== height) {
            if (el) {
                el.scrollTop += newHeight - height;
            }
            setHeight(newHeight);
        }
    }, [newHeight, height, el]);

    const scrollToBottom = useCallback(() => {
        return el && (el.scrollTop = el.scrollHeight - el.clientHeight);
    }, [el]);

    const hasReachedBottom = el
        ? el.scrollHeight - el.clientHeight === el.scrollTop
        : false;


    useEffect(() => {
        scrollToBottom();
        /*if (hasReachedBottom) {
            scrollToBottom();
        }*/
    }, [messages.length, hasReachedBottom, scrollToBottom]);


    useEffect(() => {
        memoizedRestoreConversationScrollPositionCallback(conversationId);
    }, [memoizedRestoreConversationScrollPositionCallback, conversationId]);

    useEffect(() => {
        scrollToBottom()
    }, [])

    //console.log('messages', messages)

    const isDifferent = (array, index) => {
        if(index === 0) return true
        const before = convertTimestampToDate(array[index -1].timetoken);
        const now = convertTimestampToDate(array[index].timetoken);
        return now !== before
    }

    return <div ref={containerRef} className={classes.root} onScroll={handleScroll}>
        <div ref={endRef} />
        { conversationId && messages.map((message, i) => (
            <>
                {
                    isDifferent(messages, i) &&
                    <div className={classes.dateBadge}>
                        <span>{convertTimestampToDate(message.timetoken)}</span>
                    </div>
                }
                <MessageListItem
                    messageFragment={message}
                    key={message.timetoken}
                    avatar={
                        <UserInitialsAvatar
                            size={36}
                            name={message.sender.name}
                            userId={message.sender.id}
                        />
                    }
                />
            </>
        ))}
    </div>
}

export default MessageList;