import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {
    clearFilters,
    errorHandler,
    formattedDate,
    taskIconResolver,
    taskStatusColors,
} from "../../helpers/utils.js";
import {httpGetTaskType} from "../../HttpRequests/tasks.http";
import SubHeading from "../../Commons/SubHeading.js";
import {useDispatch} from "react-redux";
import Table from "../../Commons/Table.js";
import Badge from "../../Commons/Badge.js";
import SimpleSearch from "../../Commons/SimpleSearch.js";

const useStyles = createUseStyles(theme => ({
    gridContainer: {
        'position': 'relative',
        // with big/small subheader like permute page
        height: ({bigSubHeader}) => `calc(100vh - ${bigSubHeader ? theme.bigSubHeader + theme.headerHeight : theme.smallSubHeader + theme.headerHeight}px)`,
        // without subheader
        //height: `calc(100vh - ${theme.headerHeight}px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        display: 'grid',
        gridTemplateColumns: '1fr',
        [theme.mUp]: {
            padding: [theme.spacing * 4],
        },
        [theme.s]: {
            padding: [theme.spacing],
        },
    },
    taskTitlesStyles: {
        display: "grid",
        fontSize: 14,
        boxSizing: "border-box",
        marginTop: theme.spacing,
        alignItems: "center",
        fontWeight: 600,
        gridTemplateColumns: "minmax(200px, 3fr) minmax(120px, 1fr) minmax(220px, 4fr) minmax(120px, 1fr) minmax(200px, 3fr)",
        '& div': {
            padding: [0, theme.spacing * 2],
        }
    },
    taskContainer: {
        maxWidth: "1200px", margin: "0 auto"
    },
    emptyList: {
        display: "grid",
        justifyItems: 'center',
        gridRowGap: theme.spacing * 2,
        '& svg': {
            width: 200,
            height: 200
        },
    },
    taskType:{
        display: "grid",
        alignItems: "center",
        gridAutoFlow: "column",
        gridColumnGap: "4px",
        '& svg':{
            width: '16px!important'
        },
        '& span':{
          fontWeight: 500
        }
    }
}));

const MyTasks = () => {
    const dispatch = useDispatch();
    const [myTasks, setMyTasks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({order_by: 'assign_date', order_direction: 'desc'});
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(null);
    //based on the type {"big","small"} property of subHeader component
    const classes = useStyles({bigSubHeader: false}); // or false if type is small

    const getTasks = async (actualPage = 1) => {
        if (actualPage === 1) setMyTasks([])
        setLoading(true);
        clearFilters(filters);
        try {
            const {data: {data: newTasks, last_page}} = await httpGetTaskType('my_tasks', {
                limit: 30, ...filters,
                page: actualPage
            });
            if (actualPage === 1) {
                setMyTasks(newTasks);
            } else {
                setMyTasks(myTasks.concat(newTasks));
            }
            setLastPage(last_page)
        } catch (e) {
            errorHandler(e, dispatch);
        } finally {
            setLoading(false);
        }
    };

    /* useEffect(() => {
         getTasks(1);
     }, []);*/

    //fetch when filters change
    useEffect(() => {
        if (!!Object.keys(filters).length) {
            setPage(1);
            setLastPage(null);
            getTasks(1);
        }
    }, [filters]);

    //fetch when page change
    useEffect(() => {
        if (!!myTasks.length && page !== 1) {
            getTasks(page)
        }
    }, [page]);


    const onChangePage = (isFilter = false) => {
        if (isFilter) {
            setPage(1)
        } else {
            if ((lastPage && page + 1) > lastPage) return;
            setPage(page + 1)
        }
    };

    const onDataSorting = (newSort) => {
        setFilters({
            ...filters,
            ...newSort,
        });
        setPage(1);
    };

    const onRowClickPath = (item) => {
        switch (item?.type) {
            case 'wash':
                return `/stock/${item.car_id}`
            case 'photo':
                return `/stock/${item.car_id}/photo`
            case 'reconditioning':
            case 'estimate':
                return `/stock/${item.car_id}/restore`
            case 'internal_transport':
            case 'external_transport':
            case 'single_transport':
                return `/stock/${item.car_id}/shifts`
            default:
                return `/stock/${item.car_id}/documents`
        }
    };

    return <>
        <SubHeading title={"I miei task"} type={'small'} justifySelf={'unset'}
                    rightSearchComponent={<SimpleSearch filters={filters} setFilters={setFilters} name={'plate'}
                                                        placeholder={'cerca targa'} setPage={setPage}/>}
        />
        <section className={classes.gridContainer}>
            <Table
                id={'table'}
                data={myTasks}
                inLoading={loading}
                filters={filters}
                onSetPageCb={onChangePage}
                onSortingDataCb={onDataSorting}
                theadHeight={40}
                rowMinHeight={48}
                classNames={{
                    table: classes.table,
                    thead: classes.thead,
                    row: classes.row,
                }}
                //real name is created_at not date
                columns={['type','brand_name', 'plate', 'company_name', 'assigned_at', 'status']}
                columnsNames={{
                    type: 'Tipologia',
                    brand_name: "Marca",
                    plate: "Targa",
                    company_name: "Società",
                    assigned_at: "Data di assegnazione",
                    status: "Stato",
                }}
                columnsWidths={{
                    type: '25%',
                    brand: '10%',
                    plate: "15%",
                    company_name: "20%",
                    assigned_at: '20%',
                    status: "10%",
                }}
                columnsAlignments={{
                    status: 'center',
                    mileage: 'right',
                }}
                backEndSortable={true}
                sortable={true}
               headersSorters={['assigned_at']}
                columnsRenderers={{
                    type: (value,item) =>  <span className={classes.taskType}>
                        {taskIconResolver(item.type)}
                        <span>{item.label}</span>
                    </span>,
                    brand: (value) => <span>{value}</span>,
                    plate: (value) => <strong style={{textTransform: 'uppercase'}}>{value}</strong>,
                    company_name: (value) => <span>{value} </span>,
                    assigned_at: (value) => <span>{formattedDate(value)}</span>,
                    status: (value,items) => <span style={{opacity: (!items?.is_active) ? 0.5 : 1 }}>
                        <Badge title={''} statusColor={taskStatusColors[value]} badgeSize={'small'} isDot={true}/>
                    </span>,
                }}
                onRowClickPath={onRowClickPath}
            />
            {/*<div className={classes.taskContainer}>
                {!loading && !(!!myTasks.length) &&
                <div className={classes.emptyList}>
                    <EmptyListIcon data-color/>
                    <h3> Non hai alcun task assegnato. </h3>
                </div>}
                {!!myTasks.length && taskTitles}
                {myTasks?.map((el, key) => <Task key={key} task={el} taskUsers={usersAutocomplete}
                                                 canDelete={isAdmin && !el.essential} isMyTask={true}/>)}
            </div>*/}
        </section>
    </>

};

export default MyTasks;
