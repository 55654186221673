import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import Table from "../../Commons/Table.js";
import {
    errorHandler,
    formattedDate,
    normalizeFiltersForBend,
    removeSpecifcKeyInObj,
    removeUnsettedKeyInObj,
    toMoney,
} from "../../helpers/utils.js";
import {httpDownloadStockReport, httpFetchStock, httpGetStockFilters} from "../../HttpRequests/stock.http.js";
import SubHeading from "../../Commons/SubHeading.js";
import {useDispatch, useSelector} from "react-redux";
import SimpleSearch from "../../Commons/SimpleSearch.js";
import {setCars, setCarTasks, setCurrentCar} from "../../store/actions/carsActions.js";
import StockBadges from "../../Components/Stock/StockBadges.js";
import Popover from "../../Commons/Popover.js";
import AdvancedFilters from "../../Components/Cars/AdvancedFilters.js";
import {useHistory, useLocation} from "react-router-dom";
import qs from 'query-string';
import cx from 'classnames';
import AdvancedFiltersController from "../../Components/Cars/AdvancedFiltersController.js";
import {DownloadIcon} from "../../assets/icons.js";
import moment from "moment";

const useStyles = createUseStyles(theme => ({
    gridContainer: {
        'position': 'relative',
        // with big/small subheader like permute page
        height: ({bigSubHeader}) => `calc(100vh - ${bigSubHeader ? theme.bigSubHeader + theme.headerHeight : theme.smallSubHeader + theme.headerHeight}px)`,
        // without subheader
        //height: `calc(100vh - ${theme.headerHeight}px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        display: 'grid',
        gridTemplateColumns: '1fr',
        [theme.mUp]: {
            padding: [theme.spacing * 2, theme.spacing * 4, theme.spacing * 4],
        },
        [theme.s]: {
            padding: [theme.spacing],
        },
    },
    filters: {},
    table: {},
    tableHeader: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        gridColumnGap: theme.spacing,
        alignItems: 'center',
        marginBottom: theme.spacing * 2,
        height: 80,
        '& h3': {
            fontWeight: 600,
            lineHeight: 1
        },
        '& small': {
            color: theme.colors.darkGrey,
            fontSize: 16,
        }
    },
    dot: {
        width: 10,
        height: 10,
        borderRadius: '50%',
        marginRight: theme.spacing,
        '&:before': {
            content: '""'
        }
    },
    green: {
        backgroundColor: theme.colors.green,
    },
    yellow: {
        backgroundColor: theme.colors.yellow,
    }, red: {
        backgroundColor: theme.colors.red,
    },
    export: {
        position: "fixed",
        height: "40px",
        width: "40px",
        color: "#fff",
        borderRadius: 40,
        bottom: 110,
        zIndex: 1000,
        right: 16,
        display: "grid",
        alignItems: "center",
        justifyItems: "center",
        '& svg': {
            fill: theme.colors.white
        },
        backgroundColor: `${theme.colors.darkGrey}!important`,
        transition: 'ease-in 300ms',
        '&:hover':{
            backgroundColor: `${theme.colors.black}!important`,
        }
    }
}));

const Stock = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const stock = useSelector(state => state.cars.cars);
    const location = useLocation();
    const history = useHistory();
    //table statuses
    const [total, setTotal] = useState(null);
    const [page, setPage] = useState(1);
    const [showPopover, setShowPopover] = useState(false);
    const [lastPage, setLastPage] = useState(null);
    const classes = useStyles({bigSubHeader: false});
    //filters things
    const [sorter, setSorter] = useState(null);
    const [filters, setFilters] = useState(location.search ? {
        ...qs.parse(location.search, {
            arrayFormat: 'comma', parseBooleans: true, skipNull: true, skipEmptyString: true
        })
    } : {});
    const [structure, setStructure] = useState(null);
    const [autocompletes, setAutocompletes] = useState(null);
    const [hideBooked, setHideBooked] = useState(false)
    const [searchIds, setSearchIds] = useState([])

    //is reset is temporary to solve async update issue of filters
    const getStock = async (actualPage = 1, isReset = false, dataSorters = sorter) => {
        if (lastPage && actualPage > lastPage) return;
        if (actualPage === 1) {
            dispatch(setCars([]))
        }
        setLoading(true);

        try {
            const searchIdToSend = lastPage > 1 ? searchIds[lastPage - 2] : searchIds[0]
            const {data: {data, total,last_page, uuid}} = await httpFetchStock(actualPage, 20, dataSorters, isReset ? {} : filters,searchIdToSend);
            if (actualPage === 1) {
                dispatch(setCars(data));
            } else {
                dispatch(setCars(stock.concat(data)));
            }
            setTotal(total);
            setLastPage(last_page);
            setSearchIds([...searchIds, uuid])
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    };

    const getFilters = async (actualFilters) => {
        if (actualFilters) {
            normalizeFiltersForBend(actualFilters);
        }
        try {
            const {data: {autocompletes, structure}} = await httpGetStockFilters(actualFilters);
            setStructure(structure);
            setAutocompletes(autocompletes);
        } catch (e) {
            errorHandler(e, dispatch)
        }
    };

    const onRowClickPath = (item) => `/stock/${item?.id}`;

    //fetch at the init
    useEffect(() => {
        //fetch Autocompletes
        getFilters();
        //clear currentCar if present to prevent flash fake data in the specific sections
        dispatch(setCurrentCar(null));
        dispatch(setCarTasks([]));
        //clean up on unmount
        return () => {
            dispatch(setCars([]))
        };
    }, []);

    //fetch when filters change
    useEffect(() => {
        setPage(1);
        setLastPage(null);
        getStock(1);
        history.replace({
            pathname: '/stock', search: qs.stringify({...filters}, {
                arrayFormat: 'comma', parseBooleans: true, skipEmptyString: true, skipNull: true
            }),
        });
        /*if (!!Object.keys(filters).length) {
            setPage(1);
            setLastPage(null);
            getStock(1);
            history.replace({
                pathname: '/stock', search: qs.stringify({...filters}, {
                    arrayFormat: 'comma', parseBooleans: true, skipEmptyString: true, skipNull: true
                }),
            });
        }*/
    }, [filters]);

    //fetch when page change
    useEffect(() => {
        if (!!stock.length && page !== 1) {
            getStock(page)
        }
    }, [page]);

    const onChangePage = (isFilter = false) => {
        isFilter ? setPage(1) : setPage(page + 1);
    };

    const onDataSorting = (newSort) => {
        const sorters = qs.stringify(newSort, {
            arrayFormat: 'comma', parseBooleans: true, skipEmptyString: true, skipNull: true
        })
        setSorter(sorters);
        setPage(1);
        getStock(1, false, sorters);
    };

    const onComplexSearch = (search) => {
        const newFilters = {...filters, ...search};
        removeUnsettedKeyInObj(newFilters);
        history.replace({
            pathname: '/stock', search: qs.stringify(newFilters, {
                arrayFormat: 'comma', parseBooleans: true, skipEmptyString: true, skipNull: true
            }),
        });
        setFilters(newFilters);
    };

    const handleHideBooked = () => {
        const hideBookedValue = !hideBooked;
        setHideBooked(hideBookedValue);
        history.replace({
            pathname: '/stock',
            search: qs.stringify({...filters, hide_booked: hideBookedValue}, {
                arrayFormat: 'comma', parseBooleans: true, skipEmptyString: true, skipNull: true
            }),
        });
        setFilters({...filters, hide_booked: hideBookedValue});
    };

    const applyAdvancedFilters = (values) => {
        normalizeFiltersForBend(values);
        setFilters({...values});
        //add query params to route
        history.replace({
            pathname: '/stock', search: qs.stringify({...filters, ...values}, {
                arrayFormat: 'comma', parseBooleans: true, skipEmptyString: true, skipNull: true
            }),
        });
        setShowPopover(!showPopover)
    };

    const resetAdvancedFilters = () => {
        //reset filters and sorters
        setFilters({});
        setSorter(null);
        setHideBooked(false);
        //clear query params from route
        history.replace({pathname: '/stock', search: null});
        getFilters({});
        getStock(1, true, null);
    };

    const removeSpecificFilter = (key) => {
        if (key === 'hide_booked') {
            handleHideBooked()
        }
        setFilters({...removeSpecifcKeyInObj(filters, key)});
        history.replace({
            pathname: '/stock', search: qs.stringify({...filters}, {
                arrayFormat: 'comma', parseBooleans: true, skipEmptyString: true, skipNull: true,
            }),
        });
    };

    const exportData = async () => {
        //for backend reasons we need to send te previous one page uuid
        const searchIdToSend = lastPage > 1 ? searchIds[lastPage - 2] : searchIds[0]
        try {
            const {data} = await httpDownloadStockReport('csv', searchIdToSend);
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'stock.csv'); //or any other extension
            document.body.appendChild(link);
            link.click();
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <SubHeading title={"Veicoli in stock"} type={'small'} subTitleData={`${!!total ? total : 0} risultati`}
                        rightSearchComponent={<SimpleSearch filters={filters} setPage={setPage}
                                                            onComplexSearchCb={onComplexSearch} name={'plate'}
                                                            placeholder={'cerca per targa'}/>}/>

            {showPopover &&
            <Popover withHeader={true} title={'Filtri'} minWidth={800} onClose={() => setShowPopover(!showPopover)}>
                <AdvancedFilters filters={filters} structure={structure} autocompletes={autocompletes}
                                 onRecalculateAutocompletesCb={getFilters} applyFilterCb={applyAdvancedFilters}
                                 resetFilterCb={Function.prototype}/>
            </Popover>}

            {!!searchIds.length && <div className={classes.export} onClick={() => exportData()}>
                <DownloadIcon data-color/>
            </div>}

            <section className={classes.gridContainer}>
                {/*{loading && <Spinner />}*/}
                <div className={classes.table}>
                    <div className={classes.tableHeader}>
                        <AdvancedFiltersController structure={structure} filters={filters}
                                                   showFilterModalCb={() => setShowPopover(!showPopover)}
                                                   handleHideBookedCb={handleHideBooked}
                                                   resetAdvancedFiltersCb={resetAdvancedFilters}
                                                   removeSpecificFilterCb={removeSpecificFilter}/>
                    </div>
                    <Table
                        id={'table'}
                        data={stock}
                        inLoading={loading}
                        filters={filters}
                        onSetPageCb={onChangePage}
                        onSortingDataCb={onDataSorting}
                        theadHeight={40}
                        rowMinHeight={40}
                        classNames={{
                            table: classes.table,
                            thead: classes.thead,
                            row: classes.row,
                        }}
                        columns={['brand', 'model', 'version', 'days_in_stock', 'plate', 'mileage', 'fuel_method', 'company', 'matriculation', 'current_position', 'price']}
                        columnsNames={{
                            brand: "Marca",
                            model: "Modello",
                            version: "Versione",
                            days_in_stock: 'Giacenza',
                            plate: "Targa",
                            mileage: 'Km',
                            fuel_method: "Alimentazione",
                            company: "Azienda proprietaria",
                            matriculation: "Immatricolazione",
                            current_position: "Ubicazione",
                            price: "Prezzo",
                        }}
                        columnsWidths={{
                            brand: "10%",
                            model: "10%",
                            version: "10%",
                            days_in_stock: '8%',
                            plate: "6%",
                            mileage: '6%',
                            fuel_method: '5%',
                            company: "15%",
                            matriculation: "10%",
                            current_position: "10%",
                            price: "5%",
                        }}
                        columnsAlignments={{
                            actions: 'right',
                        }}
                        backEndSortable={true}
                        sortable={true}
                        headersSorters={['days_in_stock', 'mileage']}
                        columnsRenderers={{
                            brand: (value, item) => <strong>{item?.model?.brand?.name}</strong>,
                            model: (value) => <span>{value?.name}</span>,
                            price: (value) => <strong>{value ? toMoney(value) : '-'}</strong>,
                            days_in_stock: (value) => <span>
                                <span
                                    className={cx(classes.dot, value <= 25 ? classes.green : value <= 75 ? classes.yellow : classes.red)}/>
                                {value}
                            </span>,
                            plate: (value) => <span style={{textTransform: 'uppercase'}}>{value}</span>,
                            mileage: (value) => <span>{value}</span>,
                            matriculation: (value) => <span>{formattedDate(value)}</span>,
                            company:  (value, item) => <span>{item?.company?.name}</span>,
                            current_position: (item) => <span>{item ? item : '-'}</span>,
                        }}
                        subRowComponent={StockBadges}
                        onRowClickPath={onRowClickPath}
                    />
                </div>
            </section>
        </>);
};

export default Stock;
