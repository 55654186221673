import React from "react";
import {createUseStyles} from "react-jss";
import MessageDisplay from "../MessageDisplay/MessageDisplay";
import useHover from "../../../../../hooks/useHover";
import {useDispatch, useSelector} from "react-redux";
import {getLoggedInUserUuid} from "../../../../../store/reducers/user";
import {RemoveIcon} from "../../../../../assets/icons";
import {usePubNub} from "pubnub-react";
import {setMessageAction} from "../../../../../store/actions/chat/messageActions";

const useStyles = createUseStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        flexShrink: 0,
        padding: '4px 16px 4px 16px',
        justifyContent: ({isMine}) => isMine ? 'flex-end' : 'flex-start',
            [theme.mUp]: {
            padding: ({isMine}) => isMine ?  '12px 24px 12px 90px' : '12px 90px 12px 24px'
        },
        '&:hover': {
            backgroundColor: '#eaeef3',
        },
        '&:first-child': {
            marginTop: 'auto'
        }
    },
    avatar: {
        width: '40px',
        height: '40px',
    },
    body: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10px',
    },
    header: {
        margin: '5px 0',
    },
    senderName: {
        lineHeight: '20px',
        fontSize: 16,
        color: '#585858',
        fontWeight: 500,
    },
    timeSent: {
        fontSize: 11,
        fontWeight: 300,
        marginLeft: 10,
        color: "#3F3F3F",
    },
    removeIcon: {
        display: ({isHovering}) => isHovering ? 'block' : 'none',
        position: 'absolute',
        right: 'calc(100% + 16px)',
        top: '50%',
        transform: 'translateY(-50%)',
        borderRadius: '50%',
        backgroundColor: '#E3E3E3',
        padding: 6,
        width: 36,
        height: 36
    }
}));


const MessageListItem = ({ messageFragment, avatar}) => {

    const [isHovering, hoverProps] = useHover({ mouseEnterDelayMS: 0 });
    const {sender, message, timetoken, channel} = messageFragment;
    const isDeleted = message.actions?.deleted;
    const userUUID = useSelector(getLoggedInUserUuid);
    const isMine = sender.id === userUUID
    const classes = useStyles({isHovering, isMine});
    const pubnub = usePubNub();
    const dispatch = useDispatch();

    const handleMessageDelete = ({timetoken, channel}) => {
        if (pubnub) {
            pubnub.addMessageAction({
                channel: channel,
                messageTimetoken: timetoken,
                action: {
                    type: 'deleted',
                    value: '.',
                },
            });
        }
        dispatch(setMessageAction(channel, timetoken))
    }

    return <div className={classes.root} {...hoverProps}>
        {!isMine && <div className={classes.avatar}>{avatar}</div>}
            <div className={classes.body}>
                {isMine && !isDeleted && <span className={classes.removeIcon}
                                 onClick={() => {
                                     handleMessageDelete(messageFragment);
                                 }}
                ><RemoveIcon/></span>}
                <MessageDisplay
                    isDeleted={isDeleted}
                    isMine={isMine}
                    channel={channel}
                    message={message}
                    parentKey={timetoken}
                />
            </div>
     </div>

}

export default MessageListItem