import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import Spinner from "../../Commons/Spinner.js";
import ContextMenu from "../../Commons/ContextMenu.js";
import {httpCarEditRepair} from "../../HttpRequests/reconditioning.http.js";
import {alertToggle} from "../../store/actions/alertActions.js";
import {
    errorHandler,
    getLabelByValue,
    repairTypeResolver,
    taskStatusColors,
    taskStatuses
} from "../../helpers/utils.js";
import {updateRepair} from "../../store/actions/repairsActions.js";
import Badge from "../../Commons/Badge.js";

export const RepairsStatusesDropdown = ({items, metadata, value}) => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const editRepairStatus = async (status, repair) => {
        setLoading(true)
        try {
            const {data: newRepair} = await httpCarEditRepair(id, {...repair, status: status});
            dispatch(updateRepair(newRepair, repairTypeResolver(newRepair.type)));
            dispatch(alertToggle('Stato correttamente aggiornato', 'success'));
        } catch (e) {
            errorHandler(e, dispatch);
            dispatch(updateRepair(repair, repairTypeResolver(repair.type)));
        }finally {
            setLoading(false)
        }
    };

    const actions = [
        {
            to: null, text: <Badge title={'Da svolgere'} statusColor={'primary'} badgeSize={'small'}/>,
            callback: () => editRepairStatus('open', items), type: 'normal',
        },
        {
            to: null, text: <Badge title={'In svolgimento'} statusColor={'warning'} badgeSize={'small'}/>,
            callback: () => editRepairStatus('active', items), type: 'normal'
        },
        {
            to: null, text: <Badge title={'Completato'} statusColor={'success'} badgeSize={'small'}/>,
            callback: () => editRepairStatus('closed', items), type: 'normal'
        },
    ];


    return <>
        {loading && <Spinner overlayFullscreen={false} size={'24px'}/>}
        <ContextMenu actions={actions} id={`${metadata.rowIndex}-status`} type={'custom'}
                     customOpenComponent={<Badge
                             title={value && getLabelByValue(taskStatuses, value)}
                             statusColor={taskStatusColors[value]}
                             badgeSize={'small'}
                         />}
        />
    </>
};

export default RepairsStatusesDropdown;
