import React from 'react';
import {createUseStyles} from "react-jss";
import {getInitials, getUniqueColor} from "../../chatUtils";


const useStyles = createUseStyles(theme => ({
    avatar: {
        borderRadius: '50%',
        textAlign: 'center',
        color: 'white',
        textTransform: 'uppercase',
        verticalAlign: 'middle',
        backgroundColor: ({uniqueColor}) => uniqueColor,
        width: ({size}) => `${ size}px`,
        fontSize: ({size}) => `${ Math.round(size / 3)}px`,
        height: ({size}) => `${size}px`,
        lineHeight: ({size}) => `${size}px`,
    }
}))


const colorSet = [
    "#FFAB91",
    "#80DEEA",
    "#EF9A9A",
    "#CE93D8",
    "#AED581",
    "#9FA7DF",
    "#BCAAA4",
    "#FFE082",
    "#F48FB1",
    "#DCE775"
];

const UserInitialsAvatar = ({
                                size = 36,
                                name,
                                userId,
                                muted
}) => {

    const uniqueColor = getUniqueColor(userId, colorSet);
    const initials = getInitials(name).slice(0, 2);
    //const processedColor = muted ? setLightness(0.9, uniqueColor) : uniqueColor;

    const classes = useStyles({uniqueColor, size});

    return <div className={classes.avatar}>
        {initials}
    </div>
}

export default UserInitialsAvatar;