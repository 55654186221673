import {createSelector} from "reselect";
import {
    SET_UNREAD_MESSAGE_COUNTER,
    ADD_UNREAD_MESSAGE,
    SET_ALL_UNREAD_MESSAGE_COUNTERS
} from "../../actions/chat/unreadMessageActions";
import {getCurrentConversationId} from "./currentConversation";

const initialState = {
    byId: {}
};

const unreadMessageStateReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_UNREAD_MESSAGE_COUNTER:
            return {
                byId: {
                    ...state.byId,
                    [action.payload.channel] : {
                        added: 0,
                        totalCount: action.payload.counter
                    }
                }
            }
        case SET_ALL_UNREAD_MESSAGE_COUNTERS:
            const affectedChannelsNames = Object.keys(action.payload.affectedChannels);
            const newState = {}
            affectedChannelsNames.forEach(m => {
                newState[m] = {
                    added: 0,
                    totalCount: action.payload.affectedChannels[m]
                }
            })
            return {
                byId: {
                    ...state.byId,
                    ...newState
                }
            }
        case ADD_UNREAD_MESSAGE:
            return {
                byId: {
                    ...state.byId,
                    [action.payload.channel] : {
                        added: action.payload.counter,
                        totalCount: (state.byId[action.payload.channel]?.totalCount || 0 ) + action.payload.counter
                    }
                }
            }
        default:
            return state;
    }
};

export { unreadMessageStateReducer };


const getUnreadMessageSlice = state => state.chats.unreadMessages;

export const getUnreadMessagesById = createSelector(
    [getUnreadMessageSlice],
    unreadMessages => {
        return unreadMessages.byId
    }
)

export const getCurrentConversationUnreadMessages = createSelector(
    [
        getUnreadMessagesById,
        getCurrentConversationId
    ],
    (
        unreadMessagesById,
        currentConversationId
    ) => {
        return unreadMessagesById[currentConversationId];
    }
);


export const getTotalUnreadMessages = createSelector(
    [getUnreadMessagesById],
    unreadMessagesById => {
        console.log(Object.values(unreadMessagesById).reduce((a, b) => ({totalCount: a.totalCount + b.totalCount}), {totalCount: 0}).totalCount)
        return Object.values(unreadMessagesById).reduce((a, b) => ({totalCount: a.totalCount + b.totalCount}), {totalCount: 0}).totalCount
    }
);

