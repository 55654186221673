import React, {useCallback, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import Button from "../../Commons/Button.js";
import {httpGetSignedS3Url, httpPostS3} from "../../HttpRequests/commons.http.js";
import {alertToggle} from "../../store/actions/alertActions.js";
import {useDropzone} from "react-dropzone";
import ProgressBar from "../../Commons/ProgressBar.js";
import {MoreVert, PdfIcon} from "../../assets/icons.js";
import {errorHandler, formattedDate} from "../../helpers/utils.js";
import Spinner from "../../Commons/Spinner.js";
import {httpDeleteCarDocuments, httpSaveCarDocument, httpUpdateCarDocument} from "../../HttpRequests/documents.http.js";

const useStyles = createUseStyles((theme) => ({
    uploadArea: {
        display: "grid",
        alignItems: "center",
        textAlign: "center",
        gridTemplateRows: "1fr 0.5fr",
        '& strong': {
            fontSize: 12
        },
        '& button': {
            marginBottom: 4,
            textTransform: 'capitalize'
        },
        '& small': {
            fontSize: 10,
        }
    },
    emptyDoc: {
        position: 'relative',
        border: `2px dotted ${theme.colors.darkGrey}`,
        minHeight: 200,
        display: "grid",
        padding: 8,
        gridRowGap: 8,
        borderRadius: 16,
    },
    loadedDoc: {
        padding: theme.spacing,
        display: 'grid',
        gridTemplateRows: '0.1fr 0.6fr 0.3fr',
        gridRowGap: theme.spacing,
        background: `linear-gradient(180deg, #fff 0%, #d6d6d6 100%)`,
        minHeight: 200,
        borderRadius: 16,
        ...theme.shadow,
        '& svg': {
            fontSize: 12,
            width: '40px!important',
        },
        '& p': {
            marginBottom: 0,
            '&::first-letter':{
                textTransform: 'capitalize'
            }
        },
        '& small': {
            fontSize: 10,
        }
    },
    header: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        gridColumnGap: theme.spacign,
        alignItems: 'center',
        position: 'relative',
        '& strong': {
            fontSize: 12,
            width: 140,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
        },
        '& span': {
            display: 'grid',
            padding: 4,
            borderRadius: '50%',
            background: theme.colors.lightGrey,
            '& svg': {
                color: theme.colors.darkGrey,
                width: '16px!important',
            },
        },
        '& ul':{
            position: "absolute",
            top: "24px",
            right: "-56px",
            padding: "4px 8px",
            borderRadius: 4,
            background: theme.colors.white,
            display: "grid",
            gridRowGap: theme.spacing,
            fontSize: "12px",
            zIndex: 1,
            width: 80,
            '& li':{
                fontWeight: 500,
                cursor: 'pointer'
            }
        }
    },
    filename:{
        textAlign: "center",
        fontSize: 14,
        maxWidth: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        height: 17
    },
    footer: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        '& span': {
            fontSize: 12,
        },
        '& small': {
            fontSize: 12,
            color: theme.colors.darkGrey
        }
    }
}));

const CarDocument= ({doc, setDocumentsCb, label, type, onDeleteDocumentCb}) => {
    const {id} = useParams();
    const MAX_SIZE = 10000000; // in bytes
    const SIGNED_URL_TYPE = 'document';
    const classes = useStyles();
    const dispatch = useDispatch();
    const [progress, setProgress] = useState(0);
    const [inUpload, setInUpload] = useState(false);
    const [loading, setLoading] = useState(false);
    const [actionsToggle, setActionsToggle] = useState(false);

    // carica solo su aws
    const handleUpload = async (file) => {
        //console.log(file)
        setInUpload(true);
        try {
            const {data: {attributes: {action, enctype}, inputs}} = await httpGetSignedS3Url(file.name, SIGNED_URL_TYPE);
            // set header
            const options = {
                headers: {
                    'Content-Type': enctype,
                },
                onUploadProgress: progressEvent => {
                    const status = parseInt((progressEvent.loaded / progressEvent.total) * 100);
                    if (status > 1) {
                        setProgress(parseInt((progressEvent.loaded / progressEvent.total) * 100))
                    }
                }
            };
            // Append inputs to form
            let formData = new FormData();
            for (const key in inputs) {
                formData.append(key, inputs[key]);
            }
            formData.append('file', file);
            await httpPostS3(action, formData, options);
            // set updatedDocument to store and component state
            const updatedDocument = `${action}/${inputs.key}`;
            console.log(type)
            saveDocument({link: updatedDocument, type: type, filename: file.name, id: doc ? doc.id : null})
        } catch (e) {
            dispatch(alertToggle(e.message, 'error'))
        } finally {
            setInUpload(false);
        }
    };

    const saveDocument = async (data) => {
        setLoading(true);
        setActionsToggle(false);
        try {
            const {data: document} = data.id ? await httpUpdateCarDocument(id, data) : await httpSaveCarDocument(id, data);
            setDocumentsCb(data?.id,document);
            dispatch(alertToggle(`Documento ${data.id ? "modificato" : "caricato"} con successo`, 'success'));
        } catch (e) {
            errorHandler(e, dispatch)
        }finally {
            setLoading(false);
        }
    };

    const deleteDocument = async () => {
        setLoading(true);
        setActionsToggle(false);
        try {
            await httpDeleteCarDocuments(id, {...doc});
            onDeleteDocumentCb({...doc});
            dispatch(alertToggle('Documento cancellato con successo', 'success'));
        } catch (e) {
            errorHandler(e, dispatch)
        }finally {
            setLoading(false);
        }
    };

    const onDrop = useCallback((acceptedFiles) => {
        // upload new document on AWS
        acceptedFiles.map(file => {const uploadResult = handleUpload(file);})
    }, [doc]);

    const {getRootProps, getInputProps, open} = useDropzone({
        accept: '/image* , .pdf, .jpeg, .jpg',
        noClick: true,
        minSize: 0,
        multiple: false,
        maxSize: MAX_SIZE,
        onDrop
    });

    const handlePrint = (event,file) => {
        event.preventDefault();
        window.open(file, "PRINT", "height=768,width=1366");
        setActionsToggle(false);
    };

    return <>
        {(inUpload || (!doc?.id && !loading)) && <div className={classes.emptyDoc}>
            <div {...getRootProps({className: 'dropzone'})} className={classes.uploadArea}>
                <strong>{label}</strong>
                {inUpload && <div className={classes.status}>
                    <ProgressBar small percentCompleted={progress} showPercent={false}/>
                </div>}
                {!inUpload && <>
                    <input {...getInputProps()} />
                    <Button data-primary data-small onClick={open}>Carica Documento</Button>
                    <small>Dimensione massima di caricamento file: 10MB</small>
                </>}
            </div>
        </div>}

        {(loading) && <div className={classes.emptyDoc}>
            <div {...getRootProps({className: 'dropzone'})} className={classes.uploadArea}>
                <strong>{label}</strong>
                <Spinner/>
            </div>
        </div>}

        {(doc?.id && !loading && !inUpload) && <div className={classes.loadedDoc}>
            <div className={classes.header}>
                <strong>{label}</strong>
                <span onClick={() => setActionsToggle(!actionsToggle)}><MoreVert data-color/></span>
                {actionsToggle && <ul>
                    <li onClick={() => setActionsToggle(false)}><a style={{textDecoration: 'none'}} href={`${doc.link}`} target="_blank" download>Scarica</a></li>
                    <li onClick={(e) => handlePrint(e,doc.link)}>Stampa</li>
                    <li onClick={() => open()}>Sostituisci</li>
                    <li style={{color: 'red'}} onClick={() => deleteDocument()}>Elimina</li>
                    <input {...getInputProps()} />
                </ul>}
            </div>
            <PdfIcon width={56} height={70} style={{justifySelf: 'center'}}/>
            <p className={classes.filename}>{doc.filename}</p>
            <small style={{alignItems: "end", display: "grid" }}>Caricato da</small>
            <div className={classes.footer}>
                <span style={{textTransform: 'capitalize'}}>{doc?.user?.full_name}</span>
                <small>{formattedDate(doc.updated_at)}</small>
            </div>
        </div>}
    </>
};

export default CarDocument;
