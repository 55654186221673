import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import SubHeading from "../../../Commons/SubHeading.js";
import Spinner from "../../../Commons/Spinner.js";
import Table from "../../../Commons/Table.js";
import Button from "../../../Commons/Button.js";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';
import {editUbication, setUbicationsList} from "../../../store/actions/settingsActions.js";
import {
    httpDeleteUbication,
    httpGetUbications,
    httpUpdateUbication,
} from "../../../HttpRequests/settings.http.js";
import ContextMenu from "../../../Commons/ContextMenu";
import {AddIcon} from "../../../assets/icons.js";
import {alertToggle} from "../../../store/actions/alertActions.js";
import {clearFilters, errorHandler} from "../../../helpers/utils.js";
import ToggleSwitch from "../../../Commons/ToggleSwitch.js";

const useStyles = createUseStyles(theme => ({
    gridContainer: {
        'position': 'relative',
        // with big/small subheader like permute page
        height: ({bigSubHeader}) => `calc(100vh - ${bigSubHeader ? theme.bigSubHeader + theme.headerHeight : theme.smallSubHeader + theme.headerHeight}px)`,
        // without subheader
        //height: `calc(100vh - ${theme.headerHeight}px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        [theme.mUp]: {
            padding: [theme.spacing * 4, theme.spacing * 4, 0],
        },
        [theme.s]: {
            padding: [theme.spacing],
        },
    },
}));


const LocationsSettings = () => {
    const classes = useStyles({bigSubHeader: false});
    const ubicationsList = useSelector(state => state.settings.ubicationsList);
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(null);
    const [filters, setFilters] = useState({});

    //fetch  data
    useEffect(() => {
            getUbications();
    }, []);

    //fetch data when filters change
    useEffect(() => {
        if (!!Object.keys(filters).length) {
            setPage(1);
            setLastPage(null);
            getUbications();
        }
    }, [filters]);

    //fetch when page change
    useEffect(() => {
        if (!!ubicationsList.length && page !== 1) {
            getUbications(page)
        }
    }, [page]);

    const getUbications = async (actualPage = 1) => {
        if(actualPage === 1)  dispatch(setUbicationsList([]));
        setLoading(true);
        clearFilters(filters);
        try {
            const {data: {data,last_page}} = await httpGetUbications({limit:30, ...filters, page: actualPage});
            // alert(JSON.stringify(data));
            if (actualPage === 1) {
                dispatch(setUbicationsList(data));
            } else {
                dispatch(setUbicationsList(ubicationsList.concat(data)));
            }
            setLastPage(last_page);
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    };

    const onChangePage = (isFilter = false) => {
        if (isFilter) {
            setPage(1)
        } else {
            if ((lastPage && page + 1) > lastPage) return;
            setPage(page + 1)
        }
    };

    const onDataSorting = (newSort) => {
        setFilters({
            ...filters,
            ...newSort,
        });
        setPage(1);
    };

    const changeUbicaitonStatus = async (item) => {
        dispatch(editUbication({...item,active: !item.active}));
        try {
            setLoading(true);
            await httpUpdateUbication({...item,active: !item.active},  item?.id);
            dispatch(alertToggle((item?.id ? 'Dati correttamente aggiornati':'Dati correttamente inseriti'), 'success'))
        } catch (e) {
            dispatch(editUbication({...item}));
            errorHandler(e,dispatch)
        } finally {
            setLoading(false);
        }
    };

    return <>
        <SubHeading title={'Ubicazioni'} type={'small'} rightSearchComponent={
            <Button data-primary icon={<AddIcon/>} onClick={() => history.push('/settings/locations/new')}>Nuova
                ubicazione</Button>
        }/>
        <section className={classes.gridContainer}>
            {loading && <Spinner/>}
            <Table
                id={'table'}
                data={ubicationsList}
                setData={() => getUbications()}
                theadHeight={40}
                rowMinHeight={40}
                filters={filters}
                onSetPageCb={onChangePage}
                onSortingDataCb={onDataSorting}
                inLoading={loading}
                classNames={{
                    table: classes.table,
                    thead: classes.thead,
                    row: classes.row,
                }}
                columns={['name', 'address', 'city','active', 'actions',]}
                columnsNames={{
                    name: "nome ubicazione",
                    address: "indirizzo",
                    city: "città",
                    active: "attivo",
                    actions: ''
                }}
                columnsWidths={{
                    name: "30%",
                    address: "30%",
                    city: "25%",
                    active: "10%",
                    actions: '5%',
                }}
                columnsAlignments={{
                    actions: 'right',
                }}
                headersSorters={['name']}
                columnsRenderers={{
                    active: (value,items) => <ToggleSwitch label={''} /*defaultChecked={value}*/ checked={value} isSmall={true} onClick={() => changeUbicaitonStatus(items)}/>,
                    actions: (value, items, metadata) => <Actions value={value} items={items} metadata={metadata} ubicationsList={ubicationsList}/>
                }}
            />
        </section>

    </>
};

export default LocationsSettings;

const Actions = ({value, items, metadata,ubicationsList}) => {
    const dispatch = useDispatch();

    const deleteUbication = async () => {
        try {
            await httpDeleteUbication(items.id);
            dispatch(setUbicationsList( ubicationsList.filter(ubication => +ubication.id !== +items.id)));
            dispatch(alertToggle(('Ubicazione correttamente eliminata'), 'success'))
        } catch (e) {
            errorHandler(e, dispatch)
        }
    };

    const actions = [
        {to: `/settings/locations/${items?.id}`, text: 'Modifica', callback: null, type: 'normal'},
        {to: null, text: 'Elimina ubicazione', callback: () => deleteUbication(), type: 'danger'}];

    return <ContextMenu actions={actions} id={`${metadata.rowIndex}-locations`}/>
};