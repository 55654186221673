export const MESSAGE_DRAFT_UPDATED = "MESSAGE_DRAFT_UPDATED";
export const MESSAGE_DRAFT_DISCARDED = "MESSAGE_DRAFT_DISCARDED";


export const messageDraftUpdated = (conversationId, value) => {
    return {
        type: MESSAGE_DRAFT_UPDATED,
        payload: {
            conversationId,
            value
        }
    }
}


export const messageDraftDiscarded = (conversationId) => {
    return {
        type: MESSAGE_DRAFT_DISCARDED,
        payload: {
            conversationId
        }
    }
}



