import {
    SET_CHANNEL_MEMBERS_PAGINATION,
    SET_CHANNELS_PAGINATION, SET_HISTORY_PAGINATION, SET_MEMBERSHIPS_PAGINATION,
    SET_USERS_PAGINATION
} from "../../actions/chat/pagnationActions";
import {createSelector} from "reselect";


const initialState = {
    users: undefined,
    channels: undefined,
    members: {},
    memberships: {},
    messages: {}
};


const updatePagination = (
        state,
        key, // "users" | "channels"
        paginationState
) => {
    return {
        ...state,
        [key]: paginationState
    };
};

const nestedUpdatePagination = (
        state,
        key, //"members" | "memberships" | "messages",
        id,
        paginationState
) => {
    return {
        ...state,
        [key]: {
            ...state[key],
            [id]: paginationState
        }
    };
};

export const PaginationStateReducer = (
        state = initialState,
        action
) => {
    switch (action.type) {
        case SET_USERS_PAGINATION:
            return updatePagination(state, "users", action.payload);
        case SET_CHANNELS_PAGINATION:
            return updatePagination(state, "channels", action.payload);
        case SET_CHANNEL_MEMBERS_PAGINATION:
            return nestedUpdatePagination(
                state,
                "members",
                action.payload.channelId,
                action.payload.state
            );
        case SET_MEMBERSHIPS_PAGINATION:
            return nestedUpdatePagination(
                state,
                "memberships",
                action.payload.uuid,
                action.payload.state
            );
        case SET_HISTORY_PAGINATION:
            return nestedUpdatePagination(
                state,
                "messages",
                action.payload.channelId,
                action.payload.state
            );

        default:
            return state;
    }
};

const getPaginationSlice = (state) => state.chats.pagination;

export const getUsersPaginationState = createSelector(
    [getPaginationSlice],
    (pagination) => pagination.users
);

export const getChannelsPaginationState = createSelector(
    [getPaginationSlice],
    (pagination) => pagination.channels
);

export const getChannelMembersPaginationStateById = createSelector(
    [getPaginationSlice],
    (pagination) => pagination.members
);

export const getMembershipsPaginationStateById = createSelector(
    [getPaginationSlice],
    (pagination) => pagination.memberships
);

export const getHistoryPaginationStateById = createSelector(
    [getPaginationSlice],
    (pagination) => pagination.messages
);