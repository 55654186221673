import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import LineChart from "./LineCahrt.js";
import RangePicker from "../../Commons/RangePicker.js";
import {StatsIcon} from "../../assets/icons.js";
import {httpGetSalesChartData} from "../../HttpRequests/dashboard.http.js";
import {errorHandler, formattedDateIso} from "../../helpers/utils.js";
import moment from 'moment'
import {useDispatch} from "react-redux";

const useStyles = createUseStyles(theme => ({
    root: {
        position:'relative',
        overflow: 'hidden'
    },
    heading:{
        display: "grid",
        gridTemplateColumns: "auto 1fr auto",
        height: 48,
        alignItems: "center",
        padding: theme.spacing,
        gridColumnGap: theme.spacing,
        '& svg':{
            fill: theme.colors.black
        },
        '& b':{
            fontSize: 14
        }
    },
    chart:{
        height: 310,
    },
    chartLoader:{
        height: 310,
        display: "grid",
        alignItems: "center",
        justifyItems: "center",
        fontSize: 14
    }
}));

const SellingAnalyses = ({selected}) => {
    const classes = useStyles();
    const [loading,setLoading] = useState(true);
    const [filters,setFilters] = useState({});
    const [chartData, setChartData] = useState(null)
    const dispatch = useDispatch();

    //init
    useEffect(() => {
        if( selected ){
            getSalesChart({
                startDate: formattedDateIso(new Date(moment().startOf('year'))),
                endDate: formattedDateIso(new Date()),
            })
        }
    },[selected]);

    useEffect(() => {
        if(selected){
            if(!!Object.keys(filters).length){
                getSalesChart(filters)
            }
        }
    },[selected,filters]);

    const getSalesChart = async ({startDate,endDate}) => {
        setLoading(true)
        try {
            const {data} = await httpGetSalesChartData(selected,{date_start:startDate, date_end:endDate});
            setChartData([{...data}])
        }catch (e){
            errorHandler(e, dispatch);
        }finally {
            setLoading(false)
        }
    }

    return <div className={classes.root}>
        <div className={classes.heading}>
           <StatsIcon data-color/>
            <b>Analisi vendite</b>
            <div className={classes.datePicker}>
                <RangePicker inForm={false} label={''} isSmall={true} openTo={'bottom'} alignRight={true}
                    filters={filters} setFilters={setFilters} startDate={new Date(moment().startOf('year'))}/>
            </div>
        </div>
        {loading && <span className={classes.chartLoader}> Caricamento dati... </span>}
        {!loading && <div className={classes.chart}>
            <LineChart data={chartData} withLegends={false} areaBaselineValue={0}/>}
        </div>}
    </div>
};

export default SellingAnalyses;
