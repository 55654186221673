import React, {useState} from 'react';
import cx from 'classnames';
import {createUseStyles} from 'react-jss';
import {Link, NavLink} from "react-router-dom";
import logoWhite from '../assets/images/bianco.svg'
import Avatar from "./Avatar";
import {SettingsIcon} from "../assets/icons.js";
import UserActionPopover from "./UserActionPopover.js";
import {useSelector} from "react-redux";
import {useWindowWidth} from "@react-hook/window-size";

const useStyles = createUseStyles(theme => ({
    root: {
        gridArea: "header",
        backgroundColor: ({authenticated}) => (authenticated) ? theme.colors.white : theme.colors.black,
        ...theme.paper,
        ...theme.border,
        height: theme.headerHeight,
        padding: [theme.spacing * 2, theme.spacing * 4],
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateColumns: 'max-content',
        gridRowGap: '15px',
        alignItems: 'center',
        position: 'relative',
        justifyItems: ({authenticated}) => (authenticated) ? 'end' : 'start',
        'grid-template-columns': '1fr',
        fontSize: 14,
        [theme.s]: {
            'position': 'sticky',
            padding: [theme.spacing, theme.spacing * 2],
            'top': 0,
            'left': 0,
            'z-index': 1,
        },
        '& img': {
            [theme.s]: {
                position: 'relative',
            },
            [theme.mUp]: {
                position: 'relative',
                margin: '0 auto',
                display: ({authenticated}) => authenticated ? 'block' : 'none'
            }
        },
    },
    links: {
        display: "grid",
        alignItems: "center",
        justifyItems: "center",
        gridColumnGap: "32px",
        [theme.s]: {
            gridColumnGap: "16px",
            gridAutoFlow: 'column'
        },
        [theme.mUp]: {
            gridAutoFlow: 'column'
        }
    },
    link: {
        textDecoration: "none",
        fontWeight: 'bold',
        color: ({authenticated}) => (authenticated) ? theme.colors.black : theme.colors.white,
        [theme.s]: {
            // display: 'none',
            /*'justify-self': ({authenticated}) => (authenticated) ? 'center' : 'right',*/
        },
        [theme.mUp]: {
            'justify-self': 'right',
        },
    },
    active: {
        color: 'red!important',
        textDecoration: "none",
    },
    vDivisor: {
        height: '100%',
        width: 1,
        backgroundColor: theme.colors.black,
        [theme.s]: {
            display: 'none',
        },
    },
}));

const Header = ({className, authenticated}) => {
    const classes = useStyles({authenticated});
    const user = useSelector(state => state.users.user);
    const userOperations = useSelector(state => state.users.operations);
    const [showPopover, setShowPopover] = useState(false);
    const canSeeBarters = (userOperations?.sales_approval || userOperations?.sales_access || userOperations?.admin);
    const canSeeCarInfos = (userOperations?.can_edit_car || userOperations?.can_read_car || userOperations?.admin);
    const canSeeWithdrawals = (userOperations?.sales_access || userOperations?.transfer_access || userOperations?.admin);
    const homePath = (userOperations?.admin ? '/' : userOperations?.supplier ? '/external-warehouses' :
        (userOperations?.body_shop || userOperations?.workshop || userOperations?.tire_center) ? '/external-workers' : '/my-tasks');
    const isMobile = (useWindowWidth() < 576);

    const popoverToggle = () => {
        setShowPopover(!showPopover);
    };

    return <>
        {showPopover && <UserActionPopover popoverToggle={popoverToggle}/>}
        <header className={cx(classes.root, className)}>
            {authenticated ? <div className={classes.links}>
                {!isMobile && <NavLink to={homePath} exact={true} activeClassName={classes.active} className={classes.link}>
                    Home
                </NavLink>}
                {(canSeeBarters && !isMobile) &&
                    <NavLink to={'/barters'} exact={true} activeClassName={classes.active} className={classes.link}>
                    Permute
                </NavLink>}
                {(canSeeWithdrawals &&  !isMobile) &&
                <NavLink to={'/withdrawals'} exact={true} activeClassName={classes.active} className={classes.link}>
                    Previsto ritiro
                </NavLink>}
                {(canSeeCarInfos &&  !isMobile) &&
                <NavLink to={'/stock'} exact={true} activeClassName={classes.active} className={classes.link}>
                    Stock
                </NavLink>}
                <span className={classes.vDivisor}/>
                {(userOperations?.admin && !isMobile) && <Link to={'/settings'}>
                    <SettingsIcon/>
                </Link>}
                <Avatar size={'small'} img={user?.photo_link} onClickEvent={popoverToggle}/>
            </div> : <Link to={'/'}>
                <img src={logoWhite} alt='logo'/>
            </Link>}
        </header>
    </>
};

export default Header;
