import React, {useState} from 'react';

const useHover =({
                     mouseEnterDelayMS = 200,
                     mouseLeaveDelayMS = 0
}) => {

    const [isHovering, setIsHovering] = useState(false);

    let mouseEnterTimer;
    let mouseOutTimer;
    return [
        isHovering,
        {
            onMouseEnter: () => {
                clearTimeout(mouseOutTimer);
                mouseEnterTimer = setTimeout(
                    () => setIsHovering(true),
                    mouseEnterDelayMS
                );
            },
            onMouseLeave: () => {
                clearTimeout(mouseEnterTimer);
                mouseOutTimer = setTimeout(
                    () => setIsHovering(false),
                    mouseLeaveDelayMS
                );
            }
        }
    ];
}

export default  useHover;