import React, {useEffect, useState} from 'react';
import {createUseStyles} from "react-jss";
import Spinner from "../../Commons/Spinner.js";
import Button from "../../Commons/Button.js";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import Select from "../../Commons/Select.js";
import {allShiftsTypes, errorHandler, formattedDateHoursIso} from "../../helpers/utils.js";
import * as Yup from "yup";
import DayPickerInput from "../../Commons/DayPickerInput.js";
import Text from "../../Commons/Text.js";
import {
    httpGetBrandsAutocomplete,
} from "../../HttpRequests/commons.http.js";
import {httpAddNewCarToShiftsRequests} from "../../HttpRequests/shifts.http.js";
import {alertToggle} from "../../store/actions/alertActions.js";
import {setShiftRequestsList} from "../../store/actions/shiftsActions.js";

const useStyles = createUseStyles(theme => ({
    root: {
        display: 'grid',
        width: '100%',
        gridRowGap: theme.spacing * 3,
        justifyItems: 'center',
    },
    title: {
        fontWeight: 600,
        margin: [0,theme.spacing * 2,theme.spacing * 2, 0],
        display: 'grid',
        alignItems: 'center',
        gridColumnGap: theme.spacing,
        gridTemplateColumns: '1fr auto',
        '& img': {
            width: 72
        }
    },
    body: {
        display: 'grid',
        gridTemplateColumns: "1fr 1fr",
        gridRowGap: theme.spacing * 2,
        gridColumnGap: theme.spacing * 4,
        padding: [0, theme.spacing * 2],
        width: '100%',
        maxWidth: 450,
    },
    full: {gridColumn: '1 / span 2'},
    btns: {
        display: 'grid',
        gridColumnStart: 1,
        gridColumnEnd: 3,
        justifyItems: 'center',
        marginTop: 32,
        gridRowGap: 8,
        '& small': {},
        '& p': {
            textDecoration: 'underline',
            cursor: 'pointer'
        }
    }
}));

const validationSchema = Yup.object().shape({
    VIN: Yup.string().nullable()
        .required(`Inserisci il numero id telaio.`),
    location_id_to: Yup.string().nullable()
        .required(`Inserisci la posizione di partenza.`),
    location_id_from: Yup.string().nullable()
        .required(`Inserisci la destinazione di arrivo.`),
    preferred_date: Yup.string().nullable()
        .required(`Inserisci una data.`),
    company_id: Yup.string().nullable()
        .required(`Inserisci l'azienda proprietaria.`),
    type: Yup.string().nullable()
        .required(`Inserisci la causale.`),
    model:Yup.string().nullable()
        .required(`Inserisci un modello.`),
    brand_id:Yup.string().nullable()
        .required(`Inserisci un Brand.`),
});

const AddNewCarToShiftsModal = ({onClose}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [autocompletes, setAutocompletes] = useState(null);
    const allLocations = useSelector(state => state.autocompletes.locations);
    const companiesAutocomplete = useSelector( state => state.autocompletes.companiesAutocomplete);
    const car = useSelector(state => state.cars.currentCar);
    const shiftRequestsList = useSelector(state => state.shifts.shiftRequestsList);
    const {register, handleSubmit, formState: {isSubmitted, isValid, touched}, control, errors, watch} =
        useForm({
            mode: 'onChange',
            reValidateMode: 'onChange',
            nativeValidation: false,
            defaultValues: {
                plate: car?.plate,
                location_id_from: {label: car?.location?.name, value: car?.location_id},
                vector:'internal'
            },
            validationSchema
        });

    useEffect(() => {
        getAllUsedAutocomplete();
    }, []);

    const getAllUsedAutocomplete = async () => {
        setIsLoading(true);
        try {
            const {data: brands} = await httpGetBrandsAutocomplete();
            setAutocompletes({brands});
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setIsLoading(false);
        }
    };
    const onSubmit = async (values) => {
        const dataToSend = {
            ...values,
            type: watch('type')?.value,
            location_id_to: watch('location_id_to')?.value,
            location_id_from: watch('location_id_from')?.value,
            company_id: watch('company_id')?.value,
            brand_id: watch('brand_id')?.value,
            preferred_date: values?.preferred_date ? formattedDateHoursIso(values.preferred_date) : null,
        };

         try {
             setIsLoading(true);
             const {data: transportation} = await httpAddNewCarToShiftsRequests(dataToSend);
             dispatch(setShiftRequestsList([transportation,...shiftRequestsList]));
             dispatch(alertToggle('spostamento nuova auto aggiunto con successo.', 'success'));
             onClose();
         } catch (e) {
             errorHandler(e, dispatch)
         } finally {
             setIsLoading(false);
         }
    };

    return <div className={classes.root}>
        {isLoading && <Spinner/>}
        <h4 className={classes.title}>
            Aggiungi auto dal nuovo
        </h4>

        <form className={classes.body} onSubmit={handleSubmit(onSubmit)}>
            <Text name={'VIN'} type={'text'} ref={register} label={`Telaio*`} disabled={false}
                  errors={errors.VIN} placeholder={'Scrivi qui il telaio'} className={classes.full}/>
            <Select name={'brand_id'} options={autocompletes?.brands ? autocompletes?.brands : []} ref={register} control={control} disabled={false}
                    label={`Marca*`} isClearable={false} errors={errors.brand_id}/>
            <Text type={'text'} label={'Modello*'} name={'model'} ref={register} disabled={false}
                  errors={errors.model}/>

            <Select name={'location_id_from'} options={allLocations} ref={register} control={control} disabled={false}
                    label={`da*`} isClearable={false} errors={errors.location_id_from}/>
            <Select name={'location_id_to'} options={allLocations} ref={register} control={control} disabled={false}
                    label={`a*`} isClearable={false} errors={errors.location_id_to}/>
            <Select name={'company_id'} options={companiesAutocomplete} ref={register} control={control}
                    label={`Azienda proprietaria*`} isClearable={false} className={classes.full} errors={errors.company_id}/>

            <DayPickerInput label={'Data*'} name={'preferred_date'} inForm={true} ref={register} className={classes.full}
                            control={control} errors={errors.preferred_date} showYearDropdown={true}
                            closeOnSelect={true}/>
            <Select name={'type'} options={allShiftsTypes} ref={register} control={control} label={`Tipo spostamento*`}
                    errors={errors.type} className={classes.full}/>

            <div className={classes.btns}>
                <Button type={'submit'} width={250} data-primary data-small
                        disabled={isSubmitted || (!isValid && touched)}> Aggiungi </Button>
            </div>
        </form>
    </div>
};

export default AddNewCarToShiftsModal;