import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useParams} from 'react-router-dom';
import Spinner from '../../Commons/Spinner';
import CarHeading from "../../Components/Cars/CarHeading";
import {useDispatch, useSelector} from "react-redux";
import {addPhotoset, setCurrentCar} from "../../store/actions/carsActions";
import {httpGetBarterPhotosAutocomplete, httpGetCar} from "../../HttpRequests/commons.http.js";
import {errorHandler} from "../../helpers/utils.js";
import Button from "../../Commons/Button";
import {AddIcon} from "../../assets/icons";
import Task from "../../Commons/Task";
import PhotosetManager from "../../Components/Cars/PhotosetManager";
import {httpCreatePhotoset} from "../../HttpRequests/stock.http";
import { Prompt } from 'react-router-dom'

const useStyles = createUseStyles(theme => ({
	gridContainer: {
		'position': 'relative',
		// with big/small subheader like permute page
		height: `calc(100vh - ${theme.carSubHeader}px)`,
		// without subheader
		//height: `calc(100vh - ${theme.headerHeight}px)`,
		overflowY: 'scroll',
		overflowX: 'hidden',
		[theme.mUp]: {
			padding: [theme.spacing * 10, theme.spacing * 4, theme.spacing * 4]
		},
		[theme.s]: {
			padding: [theme.spacing],
		},
	},
	gridHeading: {
		display: 'grid',
		gridTemplateColumns: '1fr auto',
		gridColumnGap: theme.spacing,
		padding: theme.spacing * 2,
		margin: theme.spacing * 2,
		borderBottom: `1px solid ${theme.colors.darkGrey}`,
		'& h3': {
			fontWeight: 600,
			lineHeight: 1,
			display: 'inline-block',
			fontSize: '1em'
		}
	},
	photosetMenu: {
		display: 'grid',
		justifyContent: 'end',
		margin: [theme.spacing * 8, theme.spacing, theme.spacing]
	}
}));

const CarPhoto = () => {
	const {id} = useParams();
	const currentCar = useSelector(state => state.cars.currentCar);
	const photosets = currentCar?.photosets?.sort((a, b) => b.id - a.id);
	const unsavedChanges = photosets && photosets.some( photoset => photoset.modified);
	const carTasks = useSelector(state => state.cars.carTasks.filter(task => task.type === 'photo'));
	const classes = useStyles({bigSubHeader: false});
	const [loading, setLoading] = useState(false);
	const [photoTypes, setPhotoTypes] = useState(null);
	const usersAutocomplete = useSelector(state => state.autocompletes.usersAutocomplete);
	const dispatch = useDispatch();

	useEffect(() => {
		if (+currentCar?.id !== +id) {
			//if the requested car is different from the stored one
			dispatch(setCurrentCar(null));
			getCarData();
		}
		getAutocompletes();
		/*// clean up
		return () => {
			//CLEANUP DI TASKS
			dispatch(setCarTasks([]))
		};*/
	},[]);


	const getCarData = async () => {
		setLoading(true);
		try {
			const {data: car} = await httpGetCar(id);
			dispatch(setCurrentCar(car));
		} catch (e) {
			errorHandler(e,dispatch)
		} finally {
			setLoading(false);
		}
	};

	//todo move into general api call
	const getAutocompletes = async () => {
		setLoading(true);
		try {
			const {data} = await httpGetBarterPhotosAutocomplete();
			setPhotoTypes(data)
		} catch (e) {
			errorHandler(e,dispatch)
		}finally{
			setLoading(false);
		}
	};

	const createPhotoset = async (carId) => {
		setLoading(true);
		try{
			const {data: photoset} = await httpCreatePhotoset(carId, {})
			dispatch(addPhotoset(photoset))
		}catch (e) {
			errorHandler(e,dispatch)
		}finally{
			setLoading(false);
		}
	};

	// TODO: reset edit on route change?
	return (<>
		<CarHeading headingTitle={'Foto'} car={currentCar}/>
		<section className={classes.gridContainer}>
			<Prompt
				when={unsavedChanges}
				message='Ci sono delle modifiche non salvate, sei sicuro di voler uscire?'
			/>
			{loading && <Spinner overlayFullscreen={true}/>}
			{carTasks && carTasks.map((el, key) => {
				return el.physical && <Task key={key} task={el} taskUsers={usersAutocomplete} isSmall={true}/>
			})}
			<div className={classes.photosetMenu}>
				<Button type={'button'}
						icon={<AddIcon/>}
						data-primary
						onClick={() => createPhotoset(currentCar.id)}> Aggiungi set fotografico </Button>
			</div>

			{photoTypes && photosets?.map((photoset,i) => <PhotosetManager car={currentCar}
																		   key={i}
																		   readOnly={!!photoset?.is_barter}
																		   initialPhotoset={photoset}
																		   photoTypes={photoTypes}/>)}

		</section>
	</>);
};


export default CarPhoto
