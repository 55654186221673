import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(theme =>({
    'root': {
        'z-index': 1,
        'width': '100%',
        'height': ({singleRowHeight}) => (singleRowHeight),
        gridAutoFlow: "column",
        alignItems: "center",
        padding: [0, theme.spacing * 2],
        gridColumnGap: theme.spacing * 5,
        background: theme.colors.white,
        display: 'grid',
        marginBottom:4,
        '& span': {
            minWidth: 100,
            maxWidth: 200,
            height: 16,
            borderRadius: 8,
            background: "linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%)",
            backgroundSize: "200px 16px",
            animationName: "$moving-gradient",
            animationDuration: "1s",
            animationIterationCount: "infinite",
            animationTimingFunction: "linear",
            animationFillMode: "forwards",
        },
    },
    "@keyframes moving-gradient": {
        "0%": { backgroundPosition: "-100px 0" },
        "100%": { backgroundPosition: "100px 0" }
    },
    'overlay': {
        'z-index': 2,
        'position': ({ overlayFullscreen }) => overlayFullscreen ? 'fixed' : 'absolute',
        'width': '100%',
        'height': '100%',
        'top': 0,
        'left': 0,
        'bottom': 0,
        'right': 0,
        'background-color': 'rgba(255, 255, 255, 0.5)',
    },
}));

const TableLoader = ({ className, virtualRowNumber = 15, singleRowHeight = 56, overlay = false, overlayFullscreen = false, center = true, ...props }) => {
    const classes = useStyles({overlayFullscreen,singleRowHeight });

    return (
        <>
            {overlay && <div className={classes.overlay} /> }
            {Array.from(Array(virtualRowNumber).keys()).map( (rows, index) => {
                return <div className={classes.root} key={index}>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                </div>
            })}
        </>
    );
};

export default TableLoader;
