import React, {forwardRef} from 'react';
import {createUseStyles} from 'react-jss';
import cx from 'classnames';
import PropTypes from 'prop-types';

const useStyles = createUseStyles(theme => ({
    root: {
        'width': 'max-content',
        'display': 'grid',
        'grid-auto-flow': ({labelOn}) => !labelOn ? 'row' : 'column',
        'grid-column-gap': ({labelOn}) => !labelOn ? undefined : theme.spacing / 2,
        'align-content': 'center',
        'align-items': 'center',
        'position': 'relative'
    },

    label: {
        ...theme.control.label,
        fontSize: 14,
        marginLeft: 4,
        cursor: ({disabled}) => disabled ? 'not-allowed' : 'pointer',
        'margin-bottom': ({labelOn}) => labelOn ? 0 : theme.control.label['margin-bottom'],
        'grid-column': ({labelOn}) => !labelOn ? undefined : labelOn === 'left' ? 1 : 2,
    },

    input: {
        width: ({isSmall}) => isSmall ? 16 : 20,
        height: ({isSmall}) => isSmall ? 16 : 20,
        cursor: ({disabled}) => disabled ? 'not-allowed' : 'pointer',
        border: `1px solid ${theme.colors.grey}`,
        borderRadius: 4,

        '&[type=radio]:not(:checked)': {
            background: theme.colors.white,
            borderRadius: ({isSmall}) => isSmall ? 16 : 20,
            border: `1px solid ${theme.colors.grey}`,
            boxSizing: "border-box"
        },

        '&[type=radio]:checked': {
            background: theme.colors.red,
            borderRadius: ({isSmall}) => isSmall ? 16 : 20,
            border: `none`,
            content: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' fill='%23fff' width='24'%3E%3Ccircle cx='12' cy='12' r='6'/%3E%3C/svg%3E")`,
        },
        '&[type=radio]:disabled': {
            background: theme.colors.lightGrey,
            border: `none`,
        }
    },
    error: {
        display: "grid",
        position: "relative",
        gridRow: "2",
        gridColumnStart: "1",
        gridColumnEnd: "3",
        color: theme.colors.red
    },
}));

const Radio = forwardRef(({children, className, label, labelOn='right', disabled, errors, isSmall, ...props}, ref) => {
    const classes = useStyles({labelOn, errors: !!errors, disabled,isSmall});

    return (
        <label id={props.name} className={cx(classes.root, className)}>
            {!!label && <span className={classes.label}>{label}</span>}
            <input type="radio" className={classes.input} disabled={disabled} ref={ref} {...props} />
            {(!!errors && !props?.disabled) && <small className={classes.error}>{errors.message}</small>}
            {children}
        </label>
    );
});

export default Radio;

Radio.propTypes = {
    children: PropTypes.any,
    label: PropTypes.string,
    labelOn: PropTypes.oneOf(["right","left"]),
    disabled: PropTypes.bool,
    errors: PropTypes.object,
    isSmall: PropTypes.bool,
};