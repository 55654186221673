import React from 'react';
import PublicLayout from '../Containers/PublicLayout.js';
import {Redirect, Route} from 'react-router-dom';
import {useSelector} from "react-redux";

const PublicRoute = (props) => {
    const access_token = useSelector(state => state.users.access_token);
    const operations = useSelector(state => state.users.operations);

    return access_token ?
        ((operations?.admin || operations?.can_view_dashboard)  ? <Redirect to={'/'}/> :
            operations?.supplier ? <Redirect to={'/external-warehouses'}/> :
                (operations?.body_shop || operations?.workshop || operations?.tire_center) ? <Redirect to={'/external-workers'}/> :
                    <Redirect to={'/my-tasks'}/>)
        : (<PublicLayout>
                <Route {...props} />
            </PublicLayout>
        );
}

export default PublicRoute;
