import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useHistory, useParams} from 'react-router-dom';
import {DoneIcon} from "../../assets/icons.js";
import Spinner from "../../Commons/Spinner";
import Button from "../../Commons/Button";
import {httpConfirmEmail} from "../../HttpRequests/user.http.js";

const useStyles = createUseStyles(theme => ({
    root: {
        position: 'relative',
        'max-width': 446,
        'margin': [theme.spacing * 4, 'auto'],
        'display': 'grid',
        'grid-auto-flow': 'row',
        'grid-row-gap': theme.spacing * 2 + 'px',
        '& h1': {
            marginBottom: theme.spacing * 4
        },
        [theme.s]: {
            width: 320,
            padding: [theme.spacing * 4, theme.spacing * 3],
            gridTemplateColumns: 280,
            justifyContent: "center",
        },
        [theme.mUp]: {
            'width': '100%',
            'padding': [theme.spacing * 4],
            gridTemplateColumns: 350,
            justifyContent: "center",
        },
        '& button': {
            marginTop: theme.spacing * 2
        }
    },
    done: {
        width: "60px!important",
        fill: `${theme.colors.green}!important`
    },
    title: {
        fontWeight: 500,
        textAlign: "left",
        margin: [theme.spacing * 3, 0, theme.spacing * 2, 0],
        color: theme.colors.black,
        [theme.s]: {
            fontSize: 28,
        },
        [theme.mUp]: {
            fontSize: 38,
        },
    },
    subtitle: {
        margin: [theme.spacing * 2, 0, theme.spacing * 2, 0],
        'color': theme.colors.darkGrey,
    },
    contact: {
        fontWeight: "300",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.14",
        letterSpacing: "normal",
        textAlign: "center",
        color: theme.colors.black,
        [theme.s]: {
            fontSize: 18,
        },
        [theme.mUp]: {
            fontSize: 24,
        },
    },
}));

const ConfirmEmail = () => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [isWrongToken, setIsWrongToken] = useState(false);
    const {token} = useParams();
    const history = useHistory();

    useEffect(() => {
        validateToken();
    }, [token]);

    const validateToken = async () => {
        setIsLoading(true);
        try {
            await httpConfirmEmail(token);
            setSuccess(true);
        } catch (e) {
            setIsWrongToken(true);
        } finally {
            setIsLoading(false);
        }
    };

    return <>
        {isWrongToken ? <div className={classes.root}>
                <h2 className={classes.title}>Ops!</h2>
                <p className={classes.subtitle}>
                    Abbiamo avuto un problema con la tua richiesta. <br/>
                    Il link potrebbe essere errato.
                </p>
                <p className={classes.subtitle}>
                    Ricarica la pagina, riclicca sul link nell'email.
                    oppure contatta il supporto.
                </p>
                <Button data-primary onClick={() => history.push('/auth/login')} style={{zIndex: 2}}> Torna al Login </Button>
            </div>
            : success ? <div className={classes.root}>
                    <DoneIcon className={classes.done}/>
                    <h2 className={classes.title}>Email confermata correttamente</h2>
                    <p className={classes.subtitle}>
                        Adesso accedi pure con le tue credenziali.
                    </p>
                    <Button data-primary onClick={() => history.push('/auth/login')} style={{zIndex: 2}}> Accedi </Button>
                </div>
                :
                isLoading && <Spinner/>}
    </>
};

export default ConfirmEmail;
