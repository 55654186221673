import React from 'react';
import {createUseStyles} from 'react-jss';
import {NavLink} from "react-router-dom";
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import routes from "../routes.js";

const useStyles = createUseStyles(theme => ({
    root: {
        ...theme.columns,
        fontSize: 14,
    },
    link: {
        textDecoration: "none",
        fontWeight: '500',
        color: theme.colors.black,
    },
    active: {
        color: 'red!important',
        textDecoration: "none",
    },
}));

const BreadCrumbsRender = () => {
    const classes = useStyles();
    const breadcrumbs = useBreadcrumbs(routes.filter(e => e.path));

    return (
        <div className={classes.root}>
            {breadcrumbs.length > 1 && breadcrumbs.map(({match, breadcrumb}, index) => (
                <span key={index}>
                    <NavLink key={match.url} activeClassName={classes.active} className={classes.link} to={match.url}
                             exact={true}>{breadcrumb}</NavLink> {(index < (breadcrumbs.length - 1)) && <span>/</span>}
                </span>
            ))}
        </div>
    );
};

export default BreadCrumbsRender;
