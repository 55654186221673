import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {httpBuyBack} from "../../HttpRequests/barters.http.js";
import {errorHandler, toMoney} from "../../helpers/utils.js";
import {useDispatch} from "react-redux";

const useStyles = createUseStyles(theme => ({
    row: {
        'grid-row-gap': theme.spacing,
        display: "grid",
        position: "relative",
        fontSize: "14px",
        alignItems: "center",
        gridRowGap: "8px",
        gridTemplateColumns: "1fr auto",
        padding: "12px 0",
        borderBottom: `1px solid ${theme.colors.lightGrey}`,
        [theme.s]: {

        },
        [theme.mUp]: {

        },
    },

    total:{
        display: 'grid',
        'grid-row-gap': theme.spacing,
        gridTemplateColumns: '1fr auto',
        alignItems: 'center',
        fontWeight: '600',
        fontSize: 16,
        marginTop: 16,
        '& span':{
            textTransform: 'uppercase',
            color: theme.colors.black
        }
    }

}));

const FiscalityRecap = ({barter}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [buyback, setBuyBack] = useState(null);

    useEffect(() => {
        getBuyBackValue();
    },[]);

    const getBuyBackValue = async () => {
        try {
            const {data : {price}} = await httpBuyBack(barter.id);
            setBuyBack(price);
        } catch (e) {
            errorHandler(e,dispatch)
        }
    };

    return <>
        <div className={classes.row}>
            <span> Fiscalità </span>
            <span> {barter.fiscality ? barter.fiscality  : '-'} </span>
        </div>
        <div className={classes.row}>
            <span>{barter?.extinguishment_value ? 'Valore di estinsione' : 'Riscatto leasing' }</span>
            <span> {barter.buyback_amount ? toMoney(barter.buyback_amount) : barter?.extinguishment_value ? toMoney(barter.extinguishment_value) : '-'} </span>
        </div>
        <div className={classes.row}>
            <span> Fiscalità diritto di riscatto </span>
            <span> {barter.buyback_fiscality ? barter.buyback_fiscality : '-' } </span>
        </div>
        <div className={classes.total}>
            <span> Valore dell'auto al netto del diritto di riscatto </span>
            <span> {buyback ? toMoney(buyback) : '-'} </span>
        </div>
    </>
};

export default FiscalityRecap;
