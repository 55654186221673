import React from 'react';
import {createUseStyles} from 'react-jss';
import SidebarNavLink from "../../Commons/SidebarNavLink";

const useStyles = createUseStyles((theme) => ({
	voices: {
		zIndex:2,
		display: ({collapsedNav}) => collapsedNav ? "none" : "grid",
		color: theme.colors.white,
		fontWeight: 500,
		'& h4':{
			display: 'block',
			marginBottom: theme.spacing * 2,
			textTransform: 'uppercase',
			fontSize: 18,
		}
	},
}));


const SettingsNavigation = ({collapsedNav}) => {
	const classes = useStyles({collapsedNav});

	return <>
		<div className={classes.voices}>
			<h4>Impostazioni</h4>
			<SidebarNavLink to={'/settings/users'}>Utenti</SidebarNavLink>
			<SidebarNavLink to={'/settings/locations'}>Ubicazioni</SidebarNavLink>
			<SidebarNavLink to={'/settings/business'}>Business/Società</SidebarNavLink>
			<SidebarNavLink to={'/settings/barter'}>Impostazioni permuta</SidebarNavLink>
			<SidebarNavLink to={'/settings/communications'}>Comunicazioni</SidebarNavLink>
		</div>
	</>
};

export default SettingsNavigation;
