import React from 'react';
import {ResponsiveLine} from '@nivo/line'
import {retrieveValueForRs} from "../../helpers/utils.js";
import {operations} from "./OperationAnalyses.js";
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.

const chartTheme = {
    "background": "#ffffff",
    "textColor": "#333333",
    "fontSize": 11,
    "axis": {
        fontSize: 10,
        "domain": {
            "line": {
                "stroke": "transparent",
                "strokeWidth": 1
            }
        },
        "ticks": {
            "line": {
                "stroke": "#ccc",
                "strokeWidth": 1
            }
        }
    },
    "grid": {
        "line": {
            "stroke": '#efefef',
            "strokeWidth": 1
        }
    }
}

const LineChart = ({data, withLegends, colorScheme = 'accent', areaBaselineValue = 0, tooltipPrefix = false}) => (
    <ResponsiveLine
        theme={chartTheme}
        data={data}
        margin={{top: 16, right: 16, bottom: 48, left: 40}}
        xScale={{type: 'point'}}
        yScale={{type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false}}
        yFormat=" >-.2f"
        curve="natural"
        axisTop={null}
        axisRight={null}
        tooltip={(input) => <div
            style={{
                background: input?.point?.color,
                color: '#fdfdfd', boxShadow: '0 0 12px 0 rgb(23 29 41 / 8%)',
                borderRadius: 4,
                fontSize: 12, padding: '4px 8px'
            }}>
            {(tooltipPrefix ? `${retrieveValueForRs(operations, input?.point?.serieId)[0]?.label}: `: null)}<strong>{input?.point?.data?.y}</strong>
        </div>}
        pointSize={5}
        pointColor={{ from: 'color', modifiers: [] }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -45,
            legend: '',
            legendOffset: 36,
            legendPosition: 'middle'
        }}
        axisLeft={{
            orient: 'left',
            tickSize: 2,
            tickPadding: 8,
            tickRotation: 0,
            legend: '',
            legendOffset: -60,
            legendPosition: 'middle'
        }}
        areaBaselineValue={areaBaselineValue}
        areaBlendMode={'multiply'}
        enableGridX={false}
        colors={{scheme: colorScheme}}
        enablePoints={true}
        enableArea={true}
        areaOpacity={0.25}
        useMesh={true}
        legends={withLegends ? [] : []}
    />
)

export default LineChart