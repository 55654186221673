import React, {useRef, useState, useEffect} from "react";
import {createUseStyles} from "react-jss";
import {isDraftModified, MESSAGE_TYPE} from "../../../../messageModel";
import {usePubNub} from "pubnub-react";
import {
    ACCEPTABLE_DOC_TYPES,
    ACCEPTABLE_FILE_TYPE,
    ACCEPTABLE_IMAGE_TYPES,
    ACCEPTABLE_VIDEO_TYPES
} from "../../../../../../helpers/constants/chat";
import {httpGetSignedS3Url, httpPostS3} from "../../../../../../HttpRequests/commons.http";
import {alertToggle} from "../../../../../../store/actions/alertActions";
import {useDispatch, useSelector} from "react-redux";
import {
    AttachmentIcon,
    CloseIcon,
    FileAttachmentIcon,
    PaperPlaneIcon,
    SmileIcon,
    VideoAttachmentIcon
} from "../../../../../../assets/icons";
import {getCurrentConversationId} from "../../../../../../store/reducers/chat/currentConversation";
import {httpSendChatFile} from "../../../../../../HttpRequests/chat.http";
import {getExtension, getTargetUserUuid} from "../../../../chatUtils";
import {getLoggedInUserUuid} from "../../../../../../store/reducers/user";
import Spinner from "../../../../../../Commons/Spinner";

const useStyles = createUseStyles(theme => ({
    root: {
        position: 'relative',
        bottom: 0,
        lineHeight: '13px',
        padding: [16, 32],
        height: 'auto',
        display: 'grid',
        gridTemplateColumns: 'auto 1fr auto',
        gridColumnGap: 24,
        alignItems: 'end',
        gridTemplateRows: '1fr auto',
        backgroundColor: '#fff'
    },
    container: {
        height: 'auto',
        display: 'grid',
        borderRadius: 24,
        minHeight: 28,
        padding: [8, 16],
        backgroundColor: '#EDEDED',
        gridTemplateRows: '1fr auto',
    },
    textarea: {
        flexGrow: 1,
        border: 'none',
        resize: 'none',
        overflow: 'auto',
        maxHeight: '150px',
        backgroundColor: '#EDEDED',
        '&::placeholder': {
            color: '#000',
        },
        '&:focus': {
            outline: 'none',
        },
        fontSize: '14px',
        color: '#000',
        minHeight: 24,
        lineHeight: 1.3,
    },
    filePreviewsContainer: {
        marginTop: 16,
        display: 'flex'
    },
    filePreview: {
        position: "relative",
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#fff',
        borderRadius: 16,
        width: 82,
        height: 82,
        marginRight: 16,
        cursor: '.pointer',
        '&:hover': {
            backgroundColor: '#fff'
        },
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
    },
    filePreviewVideo: {
        extend: 'filePreview',
        padding: [10, 16]
    },
    fileIcon: {
        flex: 1,
        width: 24
    },
    fileName:{
        fontSize: 10,
        maxWidth: 56,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    closeIcon: {
        position: 'absolute',
        top: 0,
        right: 0,
        transform: 'translate(50%, -50%)',
        width: 24,
        height: 24,
        backgroundColor: '#E3E3E3',
        borderRadius: '50%',
        '& > svg': {
            padding: 4
        }
    },
    videoContainer: {
        overflow: 'hidden',
        borderRadius: 12,
        position: 'relative',
    },
    videoAttachment: {
        maxHeight: 800,
        width: '100%'
    },
    actions: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: 16
    },
    attachment: {
        cursor: 'pointer',
        width: 24,
        height: 24,
        marginBottom: 8,
    },
    emoticons: {
        cursor: 'pointer',
        width: 24,
        height: 24,
        marginBottom: 8,
    },
    send: {
        cursor: ({inUpload}) => inUpload ? 'not-allowed' :'pointer',
        width: 24,
        height: 24,
        marginBottom: 8,
    }
}))

/**
 * Update the text field on a draft text message by returning a new object if
 * the new text is different than the text in the old object.
 * This is the proper way to do updates to avoid unnecessary rerendering.
 */
const newTextDraft = (draft, newText) => {

    if (draft.text === newText) {
        return draft;
    }
    return {
        type: MESSAGE_TYPE.text,
        senderId: draft.senderId,
        text: newText
    };
};

/**
 * Expand the height of the input box as multiple lines of text are entered.
 */
const autoExpand = (el) => {
    setTimeout(function() {
        el.style.cssText = "height:auto; padding:0";
        el.style.cssText = "height:" + el.scrollHeight + "px";
    }, 0);
};

const TextMessageEditor = ({
                               message,
                               sendDraft,
                               updateDraft,
                               onFocus
}) => {
    const text = message.text;
    const textareaRef = useRef();
    const dispatch = useDispatch();
    const pubnub = usePubNub();
    const [inUpload, setInUpload] = useState(false);
    const [progress, setProgress] = useState(0);
    const [filesToSend, setFilesToSend] = useState([])
    const classes = useStyles({inUpload});
    const conversationId = useSelector(getCurrentConversationId);
    const userUUID = useSelector(getLoggedInUserUuid)
    const targetUUID = getTargetUserUuid(userUUID, conversationId);


    useEffect(() => {
        autoExpand(textareaRef.current);
    }, [textareaRef]);

    useEffect(() => {
        setFilesToSend([])
    }, [conversationId])

    const SIGNED_URL_TYPE = 'chat_attachment';
    const onUpload = progressEvent => {
        const status = parseInt((progressEvent.loaded / progressEvent.total) * 100);
        if (status > 1) {
            setProgress(parseInt((progressEvent.loaded / progressEvent.total) * 100))
        }
    }
    const handleS3Upload = async (file) => {
        console.log('file to was', file, SIGNED_URL_TYPE)
        try {
            const {data: { attributes : { action, enctype }, inputs}} = await httpGetSignedS3Url(file.name, SIGNED_URL_TYPE);
            // set header
            const options = {
                headers: {'Content-Type': enctype},
                onUploadProgress: onUpload
            };
            // Append inputs to form
            let formData = new FormData();
            for (const key in inputs) {
                formData.append(key, inputs[key]);
            }
            formData.append('file', file);
            await httpPostS3(action, formData, options);
            console.log('url is ', `${action}/${inputs.key}`)
            return `${action}/${inputs.key}`;
        } catch(e) {
            dispatch(alertToggle(e.message,'error'))
        }
    };

    const handleUpload = async event => {
        if(event.target.files.length === 0) return
        setInUpload(true);
        const fileObjs = Array.from(event.target.files).map(file => ({
            type: file.type.split('/').pop().toLowerCase(),
            url: URL.createObjectURL(file),
            isPreview: true,
            name: file.name,
            file
        }));
        // carica le preview
        setFilesToSend(fileObjs)
        // Inizia a caricare i file
        const fileUrls = [];
        // Upload

        await Promise.all(fileObjs.map( async fileObj => {
                try{
                    const url = await handleS3Upload(fileObj.file);
                    if(url){
                        URL.revokeObjectURL(fileObj.file)
                        console.log('file caricato su s3', url)
                        fileUrls.push({...fileObj, url, isPreview: false});
                    }
                    return url;
                }catch (err){
                    console.log('error');
                }
            })
        )
        // Set Urls
        setFilesToSend(fileUrls);
        console.log('file settati')
        // Reset
        setInUpload(false);
    }

    const textChanged = (e) => {
        updateDraft(newTextDraft(message, e.target.value));
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            handleSend();
            e.preventDefault();
        }
        autoExpand(e.target);
    };

    const handleSend = () => {
        //if(inUpload) return;
        const draft = newTextDraft(message, text);
        if (isDraftModified(draft)) {
            sendDraft(draft);
            //sendFiles()
        }else if(filesToSend.length > 0){
            //sendFiles()
        }
    }


    const sendFiles = () => {

        console.log('file da inviare', filesToSend)
        // add message type
        let messageType;
        const files = filesToSend.map(file => {

            switch (true) {
                case ACCEPTABLE_IMAGE_TYPES.includes(file.type):
                    messageType = 'photo';
                    break
                case ACCEPTABLE_VIDEO_TYPES.includes(file.type):
                    messageType = 'video';
                    break;
                case ACCEPTABLE_DOC_TYPES.includes(file.type):
                    messageType = 'document';
                    break
                default:
                    messageType = 'photo';
                    break;
            }
            return {
                ...file,
                messageType
            }
        })

        files.forEach( file => {
            console.log('invio questo', file)
            httpSendChatFile({
                url: file.url,
                receiverUuid: targetUUID,
                type: messageType,
                filename: file.name,
                channelName: conversationId
            }).then(response => {
                const fileId = response.data.id;
                pubnub.publish({
                    channel: conversationId,
                    message: {
                        senderId: userUUID,
                        text: null,
                        type: messageType,
                        meta: {
                            attachmentId: fileId
                        },
                    }
                })
            }).catch((e) => {
                console.log(e)
            });
        })
        setFilesToSend([])
    }

    const deleteFile = (fileName) => {
        const newFiles = filesToSend.filter( file => file.name !== fileName);
        setFilesToSend(newFiles)
    }

    const handleFilePreview = (file, index) => {
        switch (true) {
            case ACCEPTABLE_IMAGE_TYPES.includes(file.type):
                return(
                    <span className={classes.filePreview}
                          key={index}
                          style={{backgroundImage: `url(${file.url})`, opacity: file.isPreview ? 0.7 : 1}}
                    >
                        {file.isPreview ?
                            <Spinner overlay={false}/>
                            :
                            <span className={classes.closeIcon}
                                  onClick={() => deleteFile(file.name)}
                            >
                                <CloseIcon/>
                            </span>
                        }
                    </span>
                )
            case ACCEPTABLE_DOC_TYPES.includes(file.type):
                return (<span className={classes.filePreview}
                                          key={index}
                                          style={{opacity: file.isPreview ? 0.7 : 1}}
            >
                        {file.isPreview ?
                            <Spinner overlay={false}/>
                            :
                            <span className={classes.closeIcon}
                                  onClick={() => deleteFile(file.name)}
                            >
                                <CloseIcon/>
                            </span>
                        }
                        <FileAttachmentIcon className={classes.fileIcon} />
                        <span className={classes.fileName}>{file.name}</span>
                    </span>)
            case ACCEPTABLE_VIDEO_TYPES.includes(file.type):
                return (
                    <span className={classes.filePreviewVideo} key={index}>
                        <VideoAttachmentIcon className={classes.fileIcon} />
                        <span className={classes.fileName}>{file.name}</span>
                        {file.isPreview ?
                            <Spinner overlay={false}/>
                            :
                            <span className={classes.closeIcon}
                                  onClick={() => deleteFile(file.name)}
                            >
                                <CloseIcon/>
                            </span>
                        }
                    </span>
                )
            default:
                break;
        }
    }

    return <div className={classes.root}>
        <div className={classes.actions}>
            {/*<span className={classes.emoticons}>
                <SmileIcon/>
            </span>
            <span className={classes.attachment}>
                <label>
                    <AttachmentIcon/>
                    <input
                        type="file"
                        style={{display: 'none'}}
                        onChange={handleUpload}
                        accept={'.' + ACCEPTABLE_FILE_TYPE.join(', .')}
                        multiple
                    />
                </label>
            </span>*/}
        </div>
        <div className={classes.container}>
            <textarea
                ref={textareaRef}
                className={classes.textarea}
                rows={1}
                value={message.text}
                onChange={textChanged}
                onKeyPress={handleKeyPress}
                placeholder="Scrivi un messaggio..."
            >
            </textarea>
            {(filesToSend.length > 0) && (
                <div className={classes.filePreviewsContainer}>
                    {filesToSend.map((file, i) => (handleFilePreview(file, file.url)))}
                </div>
            )}
        </div>
        <span className={classes.send} onClick={handleSend}>
            <PaperPlaneIcon/>
        </span>
    </div>
}

export default TextMessageEditor;