import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import Spinner from "../../Commons/Spinner.js";
import ContextMenu from "../../Commons/ContextMenu.js";
import {httpCarDeleteRepair} from "../../HttpRequests/reconditioning.http.js";
import {alertToggle} from "../../store/actions/alertActions.js";
import {errorHandler, repairTypeResolver} from "../../helpers/utils.js";
import {deleteRepairAction} from "../../store/actions/repairsActions.js";
import Popover from "../../Commons/Popover.js";
import AddRepairRowModal from "./AddRepairRowModal.js";
import AddRepairNoteModal from "./AddRepairNoteModal.js";
import AddRepairInvoiceModal from "./AddRepairInvoiceModal.js";

export const RestoreWorkerTableActions = ({items, metadata,title}) => {
    const {id: carID} = useParams();
    const dispatch = useDispatch();
    const [loading,setLoading] = useState(false);
    const [onAddRowModal, setOnAddRowModal] = useState(false);
    const [onAddNoteModal, setOnAddNoteModal] = useState(false);
    const [onAddInvoiceModal, setOnAddInvoiceModal] = useState(false);

    const deleteRepair = async (repair) => {
        setLoading(true);
        try{
            const {data} = await httpCarDeleteRepair(carID,repair.id);
            dispatch(deleteRepairAction(repair, repairTypeResolver(repair.type)));
            dispatch(alertToggle( 'Riparazione correttamente eliminata', 'success'));
        }catch (e) {
            errorHandler(e,dispatch);
        }finally {
            setLoading(false)
        }
    };

    const actions = [
        {to:null, text: 'Aggiungi deroga', callback: () => (setOnAddRowModal(!onAddRowModal)), type: 'normal'},
        {to:null, text: items.invoice_amount ? 'Modifica fattura' : 'Aggiungi fattura', callback: () => setOnAddInvoiceModal(!onAddInvoiceModal), type: 'normal'},
        {to:null, text: items.note ? 'Modifica nota' : 'Aggiungi nota', callback: () => setOnAddNoteModal(!onAddNoteModal), type: 'normal'},
        {to: null, text: 'Elimina', callback: () => deleteRepair(items), type: 'danger'}];

    return <>
        {loading && <Spinner overlayFullscreen={true}/>}
        {/*popover upload deroga */}
        {onAddRowModal && <Popover withHeader={true} onClose={() => setOnAddRowModal(!onAddRowModal)} title={'Agiunta Deroga'}>
            <AddRepairRowModal onCloseCb={() => setOnAddRowModal(!onAddRowModal)} carId={carID} repair={items}/>
        </Popover>}
        {/*popover upload fattura */}
        {onAddInvoiceModal && <Popover withHeader={true} onClose={() => setOnAddInvoiceModal(!onAddInvoiceModal)} title={items.invoice_amount ? 'Modifica fattura' : 'Aggiungi fattura'}>
            <AddRepairInvoiceModal onCloseCb={() => setOnAddInvoiceModal(!onAddInvoiceModal)} carId={carID} repair={items}
                                   attachment={items?.attachments.filter((attach) => attach.type === 'invoice')[0]}/>
        </Popover>}
        {/*popover upload nota */}
        {onAddNoteModal && <Popover withHeader={true} onClose={() => setOnAddNoteModal(!onAddNoteModal)} title={items.note ? 'Modifica nota' : 'Aggiungi nota'}>
            <AddRepairNoteModal onCloseCb={() => setOnAddNoteModal(!onAddRowModal)} carId={carID} repair={items}/>
        </Popover>}
        <ContextMenu actions={actions} id={`${metadata.rowIndex}-${title}`}/>
    </>
};

export default RestoreWorkerTableActions;
