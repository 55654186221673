import {createSelector} from "reselect";
import * as actionTypes from '../actions/userActions';
//check if token is in LS
const initialState = {
	user: JSON.parse(localStorage.getItem('user')) || null,
    operations: JSON.parse(localStorage.getItem('user'))?.operations || null,
	access_token: localStorage.getItem('access_token') || null,
    refresh_token: localStorage.getItem('refresh_token') || null,
};


const userReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.SET_LOGGED_USER:
            localStorage.setItem('user', JSON.stringify(action.user));
            localStorage.setItem('access_token', action.access_token);
            localStorage.setItem('refresh_token',action.refresh_token);
            return {
                ...state,
                refresh_token: action.refresh_token,
                access_token: action.access_token,
                user: action.user,
                operations: action.user?.operations
            };
        case actionTypes.UPDATE_USER:
            localStorage.setItem('user', JSON.stringify(action.user));
            return {
                ...state,
                user: action.user,
                operations: action.user?.operations
            };
        case actionTypes.LOGOUT:
            localStorage.removeItem('user');
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('lastOpenChat');
            return {
                ...state,
                refresh_token: null,
                access_token: null,
                user: null,
                operations: null
            };
        default:
            return state;
    }
};

/**
 *
 * Selectors
 *
 * */


const getLoggedInUser = state => state.users.user;

export const getLoggedInUserChannelGroups = createSelector(
    [getLoggedInUser],
    user => {
        return user?.channel_groups;
    }
);

export const getLoggedInUserId = createSelector(
    [getLoggedInUser],
    userState => {
        return userState?.id;
    }
);

export const getLoggedInUserName = createSelector(
    [getLoggedInUser],
    userState => {
        return userState?.full_name;
    }
);

export const getLoggedInUserUuid = createSelector(
    [getLoggedInUser],
    userState => {
        return userState?.pubnub_uuid;
    }
);

export default userReducer;
