import React from 'react';
import {createUseStyles} from "react-jss";
import Card from "../../Commons/Card.js";
import {toMoney} from "../../helpers/utils.js";
import eurotaxLogo from "../../assets/images/eurotax.jpg";
import indicataLogo from "../../assets/images/indicata.jpg";
import datLogo from "../../assets/images/datLogo.svg";
import SelectedVersion from "./SelectedVersion.js";
import {useSelector} from "react-redux";

const useStyles = createUseStyles(theme => ({
    root: {
        display: 'grid',
        width: '100%',
        justifyItems: 'center',
        gridRowGap: theme.spacing,
    },
    divisor: {
        width: '100%',
        height: 1,
        backgroundColor: theme.colors.lightGrey
    },
    cardsContainer: {
        display: "grid",
        alignItems: "start",
        width: "100%",
        [theme.mUp]: {
            gridTemplateColumns: "1fr 1fr 1fr 1fr",
        },
        [theme.s]: {
            gridTemplateColumns: "1fr",
        },
    },
    card: {
        display: 'grid',
        textAlign: 'center',
        padding: [theme.spacing * 2, theme.spacing],
        gridRowGap: theme.spacing,
        minHeight: 105,
        '& span': {
            fontWeight: 600,
            fontSize: 24,
        },
        '& p': {
            fontSize: 12,
        },
        '& img': {
            width: 56,
            margin: "0 auto"
        },
    },
    service: {
        display: "grid",
        width: "100%",
        gridColumnGap: theme.spacing * 4,
        alignItems: "center",
        padding: [0, theme.spacing],
        [theme.mUp]: {
            gridTemplateColumns: "200px 1fr auto auto",
        },
        [theme.s]: {
            gridTemplateColumns: "1fr",
            gridRowGap: theme.spacing,
        },
    },
    serviceData: {
        display: "grid", fontSize: "14px",
        '& span': {
            textAlign: 'left'
        }
    }
}));

const MarketValues = ({car, isRecap}) => {
    const classes = useStyles();
    const isAdmin = useSelector(state => state.users.user.operations?.admin);
    const isSalesApproval = useSelector(state => state.users.operations?.sales_approval);
    const indicataLabel = `${car.model?.name} ${car.indicata_label ? car?.indicata_label : ''}`;
    const eurotaxLabel = `${car.model?.name} ${car.eurotax_label ? car.eurotax_label : ''}`;

    return <div className={classes.root}>
        <div className={classes.cardsContainer}>
            <Card bodyCustomClass={classes.card}>
                <span> {car.average_rotation ? car.average_rotation : 0} </span>
                <p>Indice di rotazione del venduto</p>
            </Card>
            <Card bodyCustomClass={classes.card}>
                <span> {car.average_national_rotation ? car.average_national_rotation : 0} </span>
                <p>Indice di rotazione</p>
                <img src={indicataLogo} alt={'indicata logo'}/>
            </Card>
            <Card bodyCustomClass={classes.card}>
                <span> {car.same_model_amount ? car.same_model_amount : 0} </span>
                <p>Presenti sul parco auto</p>
            </Card>
            <Card bodyCustomClass={classes.card}>
                <span> {car.average_stock_rotation ? car.average_stock_rotation : 0} </span>
                <p>Giorni di giacenza delle auto a stock</p>
            </Card>
        </div>
        <div className={classes.divisor}/>
        <div className={classes.service}>
            <img src={eurotaxLogo} alt={'eurotax logo'}/>
            <SelectedVersion brand={car.model?.brand.name} model={eurotaxLabel} productionDate={null}
                             doors={car.doors} service={'eurotax'} id={car.id} version={car.eurotax_version}
                             isRecap={isRecap}/>
            <div className={classes.serviceData}>
                <strong>Blu</strong>
                <span>{toMoney(car.eurotax_market_price)}</span>
            </div>
            <div className={classes.serviceData}>
                <strong>Blu con km effettivo</strong>
                <span>{toMoney(car.eurotax_blu_km)}</span>
            </div>
        </div>
        {(isSalesApproval || isAdmin) && <>
            <div className={classes.divisor}/>
            <div className={classes.service}>
                <img src={datLogo} alt={'dat logo'}/>
                <SelectedVersion brand={car.model?.brand.name} model={car.model?.name} productionDate={null}
                                 doors={car.doors} service={'dat'} id={car.id} version={car.dat_europa_code}
                                 isRecap={isRecap}/>
                <div className={classes.serviceData}/>
                <div className={classes.serviceData}>
                    <span>{toMoney(car.dat_market_price)}</span>
                </div>
            </div>
        </>}
        {(isSalesApproval || isAdmin)  && <>
            <div className={classes.divisor}/>
            <div className={classes.service}>
                <img src={indicataLogo} alt={'indicata logo'}/>
                <SelectedVersion brand={car.model?.brand.name} model={indicataLabel} productionDate={null}
                                 doors={car.doors} service={'indicata'} id={car.id} version={car.indicata_version}
                                 isRecap={isRecap}/>
                <div className={classes.serviceData}/>
                <div className={classes.serviceData}>
                    <span>{toMoney(car.indicata_market_price)}</span>
                </div>
            </div>
        </>}
    </div>
};

export default MarketValues;