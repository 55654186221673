import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {createUseStyles} from 'react-jss';
import {useDispatch, useSelector} from 'react-redux';
import Header from "../Commons/Header";
import Nav from "../Commons/Nav";
import Snackbar from "../Commons/Snackbar.js";
import {useWindowWidth} from "@react-hook/window-size";
import {useLocation} from "react-router-dom";
import {me} from "../store/actions/userActions.js";
import FloatingBtns from "../Commons/FloatingBtns.js";
import ChatButton from "../Pages/messageCenter/ChatButton";

const useStyles = createUseStyles(theme => ({
	root: {
		display: "grid",
		gridTemplateRows: "60px auto",
		[theme.s]: {
			'align-items': 'start',
			'grid-template-areas': '"header" "main"',
			'grid-template-columns': '1fr',
		},
		[theme.mUp]: {
			'align-items': 'start',
			'grid-template-areas': '"nav header" "nav main"',
			'grid-template-columns': ({collapsedNav}) => collapsedNav ? '80px 1fr' : '248px 1fr',
		},
	},
	main: {
		gridArea: "main",
		//backgroundColor: theme.colors.body
	},
	header: {
		'grid-area': 'header',
	},
	nav: {
		'grid-area': 'nav',
	},
}));

const PrivateLayout = ({children,sidebar}) => {
	const [collapsedNav, setCollapsedNav] = useState(useWindowWidth() < 1200);
	const alert = useSelector(state => state.alerts);
	const classes = useStyles({collapsedNav});
	const dispatch = useDispatch();
	const {pathname} = useLocation();

	useEffect(() => {
		dispatch(me());
	},[pathname])

	return (
		<main className={classes.root}>
			<Snackbar content={alert?.message} level={alert?.level} right={true}/>
			<Header authenticated={true} className={classes.header}/>
			<Nav setCollapsedNav={() => setCollapsedNav(!collapsedNav)} collapsedNav={collapsedNav}
				 sidebar={sidebar}
				 className={classes.nav}/>
			<div className={classes.main}>
				{children}
			</div>
			<FloatingBtns/>
			<ChatButton/>
		</main>
	);
};

PrivateLayout.propTypes = {
	children: PropTypes.any,
};

export default PrivateLayout;
