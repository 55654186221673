import {createUseStyles} from "react-jss";
import React, {useEffect, useState} from "react";
import {toMoney} from "../../helpers/utils.js";

const useStylesExpander = createUseStyles(theme => ({
    root: {
        display: "grid",
        gridTemplateColumns: "16% 24% 22% 10%",
        fontSize: "14px",
        color: theme.colors.white,
        fontWeight: 600,
    }
}));

export const RestoreWorkerTableFooter = ({data}) => {
    const classes = useStylesExpander();
    const [totalAmounts, setTotalAmounts] = useState(0);
    const [totalNegotiatedAmount, setTotalNegotiatedAmount] = useState(0)
    const [totalInvoices, setTotalInvoices] = useState(0)

    useEffect(() => {
        setTotalAmounts(data.reduce((accum, item) => accum + item.total_amount, 0));
        setTotalNegotiatedAmount(data.reduce((accum, item) => accum + item.negotiated_amount, 0));
        setTotalInvoices(data.reduce((accum, item) => accum + item.invoice_amount, 0));
    }, [data || data?.repair_rows.length]);

    return (
        <div className={classes.root}>
            <span>Totali</span>
            <span>{toMoney(totalAmounts)}</span>
            <span>{toMoney(totalNegotiatedAmount)}</span>
            <span>{toMoney(totalInvoices)}</span>
        </div>
    );
};

export default RestoreWorkerTableFooter;