import React, {useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useDispatch} from 'react-redux';
import {useForm} from 'react-hook-form';
import Spinner from "../../Commons/Spinner";
import Text from "../../Commons/Text";
import {setCurrentCar} from "../../store/actions/carsActions.js";
import {alertToggle} from "../../store/actions/alertActions.js";
import {errorHandler} from "../../helpers/utils.js";
import {httpEditCarInfo} from "../../HttpRequests/barters.http.js";

const useStyles = createUseStyles(theme => ({
    root: {
        position: 'relative',
        'display': 'grid',
        'grid-auto-flow': 'row',
        'grid-row-gap': theme.spacing * 3,
        'grid-column-gap': theme.spacing * 5,
        [theme.s]: {
            width: 320,
            gridTemplateColumns: '1fr',
            justifyContent: "center",
        },
        [theme.mUp]: {
            'width': '100%',
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            justifyContent: "center",
        },
    },

    sectionTitle: {
        fontWeight: 600,
        textAlign: "left",
        gridColumnStart: 1,
        gridColumnEnd: 5,
        fontSize: 16,
        padding: [theme.spacing * 2, 0, 0],
        borderTop: `1px solid ${theme.colors.lightGrey}`
    }
}));


const WithdrawalPricingForm = ({car, canEditField, isStock = false,isNewCar= false}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const {register, watch, control, reset} = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            ...car,
            withdrawal_price: car?.withdrawal_price,
        },
    });

    const editCarInfo = async (fieldName, value, label) => {
        if ((value === null || value === '') || car[fieldName]?.toString() === value.toString()) return;

        const dataToSend = {
            car: {
                ...car,
                [fieldName]: value,
                ...(isNewCar && {is_draft: true}),
            },
        };
        try {
            const {data} = await httpEditCarInfo(dataToSend);
            dispatch(setCurrentCar(data));
            dispatch(alertToggle(`Il campo ${label} è stato modificato correttamente.`, 'success'))
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setIsLoading(false);
        }
    };

    return <form className={classes.root} /*onSubmit={handleSubmit(onSubmit)}*/>
        {isLoading && <Spinner/>}

        <h4 className={classes.sectionTitle} style={{padding: 0}}/>

        <Text type={'number'} disabled={!canEditField} label={'Prezzo d\'acquisto*'} name={'withdrawal_price'}
              ref={register}
              onBlur={() => editCarInfo('withdrawal_price', watch('withdrawal_price'), 'Prezzo d\'acquisto')}/>
    </form>
};

export default WithdrawalPricingForm;
