import React from 'react';
import {createUseStyles} from 'react-jss';
import {toMoney} from "../../helpers/utils";
import eurotaxLogo from "../../assets/images/eurotax.jpg";
import indicataLogo from "../../assets/images/indicata.jpg";
import datLogo from "../../assets/images/datLogo.svg";

const useStyles = createUseStyles(theme => ({
    eurotax: {
        display: "grid",
        alignItems: "center",
        gridTemplateColumns: "auto 1fr",
        padding: [theme.spacing, theme.spacing * 2],
        borderBottom: `1px solid ${theme.colors.lightGrey}`,
        gridColumnGap: theme.spacing * 2,
    },
    eurotaxRows:{
        display: "grid",
        gridColumnStart: 2,
        gridRowGap: theme.spacing * 2
    },
    eurotaxRow:{
        gridTemplateColumns: "1fr auto",
        display: "grid",
        alignItems: 'center',
        '& p':{
            fontSize: 12
        },
        '& span':{
            fontSize: 14
        }
    },
    data: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        borderBottom: `1px solid ${theme.colors.lightGrey}`,
        padding: [theme.spacing, theme.spacing * 2],
        alignItems:"center",
        justifyItems:"center",
    },
    provider:{
        "& span": {
            fontSize: "18px", fontWeight: 500, color: theme.colors.darkGrey
        },
    },
    row: {
        'grid-row-gap': theme.spacing,
        display: "grid",
        position: "relative",
        alignItems: "center",
        gridRowGap: "8px",
        gridTemplateColumns: "1fr auto",
        padding: [10, theme.spacing * 2],
        borderBottom: `1px solid ${theme.colors.lightGrey}`,
        '& span': {
            fontSize:12
        },
        '& strong': {
            fontSize:14
        }
    },
    total:{
        display: 'grid',
        'grid-row-gap': theme.spacing,
        gridTemplateColumns: '1fr auto',
        alignItems: 'center',
        fontWeight: '600',
        fontSize: 16,
        marginTop: 16,
        '& span':{
            textTransform: 'uppercase',
            color: theme.colors.black
        }
    }

}));

const MarketValues = ({car }) => {
    const classes = useStyles();
    // console.log('MARKET VALUES car',car);
    return <>
        <div className={classes.eurotax}>
            <img src={eurotaxLogo} alt={'eurotax logo'}/>
            <div className={classes.eurotaxRows}>
                <div className={classes.eurotaxRow}>
                    <p> Giallo </p>
                    <span> € {car?.eurotax_yellow?toMoney(car.eurotax_yellow) : '-'}</span>
                </div>
                <div className={classes.eurotaxRow}>
                    <p> Giallo con Km effettivi </p>
                    <span> € {car?.eurotax_yellow_km?toMoney(car.eurotax_yellow_km) : '-'}</span>
                </div>
            </div>
        </div>

        <div className={classes.data}>
            <div className={classes.provider}>
                <img src={datLogo} alt={'dat logo'} style={{height: 30, margin: '0 auto'}}/>
                <span>{car?.dat_market_price?toMoney(car.dat_market_price) : '-'}</span>
            </div>
            <div className={classes.provider}>
                <img src={indicataLogo} alt={'indicata logo'} style={{height: 18, margin: '0 auto 10px'}}/>
                <span>{car?.indicata_market_price?toMoney(car.indicata_market_price) : '-'}</span>
            </div>
        </div>
        
        <div className={classes.row}>
            <span> VALORE MEDIO DELLE VETTURE NELLO STOCK </span>
            <strong>{ car?.average_stock_value ? toMoney(car.average_stock_value) : '-' } </strong>
        </div>
        <div className={classes.row} style={{borderBottom: '0'}}>
            <span> PREZZO DI VENDITA CONSIGLIATO DAL VENDITORE </span>
            <span><strong> € { car?.ideal_price ? toMoney(car.ideal_price) : '-' } </strong></span>
        </div>
    </>
};

export default MarketValues;
