import {REMOVE_TYPING_INDICATOR, REMOVE_TYPING_INDICATOR_ALL} from "../../actions/chat/typingIndicatorActions";
import {MessageActionType, sendSignal} from "pubnub-redux";
import { SignalActionType, PresenceCategory } from "pubnub-redux";
import { createSignalReducer } from "pubnub-redux";
import {createSelector} from "reselect";
import {SHOW_TYPING_INDICATOR} from "../../../helpers/constants/chat";
import {getCurrentConversationId} from "./currentConversation";


const initialState = { byId: {} };

const signalReducer = createSignalReducer();

const removeTypingIndicator = (
    state,
    channel,
    userId,
    timetoken
) => {
    let newState = {
        byId: { ...state.byId }
    };

    if (newState.byId[channel]) {
        newState.byId[channel] = newState.byId[channel].filter(signal =>
            timetoken
                ? !(signal.publisher === userId && signal.timetoken === timetoken)
                : !(signal.publisher === userId)
        );
    }

    return newState;
};

/**
 * create a reducer which holds all typing indicator signal objects in a normalized form
 */
export const TypingIndicatorStateReducer = (state, action) => {
    switch (action.type) {
        case SignalActionType.SIGNAL_RECEIVED:
            if (
                action.payload.message.type === SHOW_TYPING_INDICATOR
            ) {
                // we only want to store the show typing indicator signals
                // the hide signal is handled by the listener below
                return signalReducer(state, action);
            }
            return state || initialState;
        case REMOVE_TYPING_INDICATOR:
            return removeTypingIndicator(
                state,
                action.payload.channel,
                action.payload.userId,
                action.payload.timetoken
            );
        case REMOVE_TYPING_INDICATOR_ALL:
            return removeTypingIndicator(
                state,
                action.payload.channel,
                action.payload.userId
            );
        case MessageActionType.MESSAGE_RECEIVED:
            return removeTypingIndicator(
                state,
                action.payload.channel,
                action.payload.message.senderId
            );
        default:
            return state || initialState;
    }
};


/**
 * Send a typing indicator to the current conversation
 *
 * This command does not handle failure and leaves the error to the caller
 */
export const sendTypingIndicator = (typingIndicatorType) => {
    return (dispatch, getState) => {
        const state = getState();
        return dispatch(
            sendSignal({
                channel: getCurrentConversationId(state),
                message: { type: typingIndicatorType }
            })
        );
    };
};

/* SELECTORS */

/**
 * This Slice selector is used internally to access the state of the reducer,
 * primarily as the base selector function for creating other selectors.
 */
const getTypingIndicatorsSlice = (state) => state.chats.typingIndicators;

/**
 * Returns an index which can be used to find signal objects
 */
export const getTypingIndicatorsById = createSelector(
    [getTypingIndicatorsSlice],
    typingIndicators => {
        return typingIndicators.byId;
    }
);