import React from 'react';

const PresenceIndicator = ({title, active = false}) => {
    return <svg width={12} height={12}  viewBox="0 0 12 12">
        <title>{title}</title>
        <circle
            cx={6}
            cy={6}
            r={6}
            fill={active ? "#4DCC4E" : "#E9EEF4"}
            /*stroke="#FFF"
            strokeWidth={3}*/
            fillRule="evenodd"
        />
    </svg>
}

export default PresenceIndicator;