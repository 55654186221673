import React, {useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useHistory} from 'react-router-dom';
import SubHeading from "../../Commons/SubHeading.js";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {httpUpdateUser} from "../../HttpRequests/settings.http.js";
import {errorHandler, formattedDateIso} from "../../helpers/utils.js";
import {alertToggle} from "../../store/actions/alertActions.js";
import Spinner from "../../Commons/Spinner.js";
import Text from "../../Commons/Text.js";
import DayPickerInput from "../../Commons/DayPickerInput.js";
import Button from "../../Commons/Button.js";
import * as Yup from "yup";
import ProfileImagesUpload from "../../Components/Settings/UserAccount/ProfileImageUpload";
import {meSuccess} from "../../store/actions/userActions.js";

const useStyles = createUseStyles(theme => ({
    gridContainer: {
        'position': 'relative',
        // with big/small subheader like permute page
        height: ({bigSubHeader}) => `calc(100vh - ${bigSubHeader ? theme.bigSubHeader + theme.headerHeight : theme.smallSubHeader + theme.headerHeight}px)`,
        // without subheader
        //height: `calc(100vh - ${theme.headerHeight}px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        [theme.mUp]: {
            padding: [theme.spacing * 4],
        },
        [theme.s]: {
            padding: [theme.spacing],
        }
    },
    formRoot: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gridColumnGap: theme.spacing * 5,
        padding: theme.spacing,
        borderRadius: 20,
        ...theme.paper
    },
    imageContent: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto 1fr',
        gridColumnGap: theme.spacing * 2,
        gridRowGap: theme.spacing * 2,
        padding: theme.spacing,
        alignItems: 'start',
        textAlign: 'center'
    },
    gridContent: {
        display: 'grid',
        gridTemplateColumns: 400,
        alignItems: 'start',
        gridColumnGap: theme.spacing * 2,
        gridRowGap: theme.spacing * 2,
        padding: theme.spacing
    },
    title: {
        display: 'grid',
        gridColumnStart: 1,
        gridColumnEnd: 1,
        fontWeight: 'bold',
        margin: [theme.spacing * 2, 0, theme.spacing * 2, 0]
    },
    avatar: {
        width: "160px",
        height: "160px",
        display: "grid",
        position: "relative",
        borderRadius: "50%",
        alignItems: "center",
        justifyItems: "center",
        boxSizing: 'border-box',
        background: theme.colors.grey,
        alignSelf: 'start',
        margin: theme.spacing * 2
    },
    btns: {
        marginTop: theme.spacing * 2,
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: theme.spacing,
        width: 300,
        justifySelf: 'end',
        gridColumn: 2,
    }
}));

const validationSchema = Yup.object().shape({
    first_name: Yup.string().required(`Inserisci il nome.`).min(3, 'Il nome deve contenere almeno 3 caratteri'),
    last_name: Yup.string().required(`Inserisci il cognome.`).min(3, 'Il cognome deve contenere almeno 3 caratteri'),
    birth_date: Yup.string().required(`Inserisci la data di nascita.`).nullable(),
    email: Yup.string()
        .email(`L'email inserita non è un email valida`)
        .required(`Inserisci l'indirizzo email aziendale.`),
    phone: Yup.string().required(`Inserisci il numero telefonico.`),
    /*company_id: Yup.string().required(`Inserisci la compagnia.`),*/
});

const Profile = () => {
    const classes = useStyles({bigSubHeader: false});
    const user = useSelector((state) => state?.users?.user);
    const [loading, setLoading] = useState(false);
    const [photoLink, setPhotoLink] = useState(null);
    const dispatch = useDispatch();

    const {register, handleSubmit, control, errors, reset} = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            ...user,
            birth_date: user?.birth_date ? new Date(user?.birth_date.toString().replace(/-/g, "/")) : '',
        },
        validationSchema
    });

    const onSubmit = async (values) => {
        const dataToSend = {
            ...values,
            id: user.id,
            birth_date: values?.birth_date ? formattedDateIso(values.birth_date) : null,
        };
        // Add photo if exists
        if (photoLink) dataToSend.photo_link = photoLink;

        try {
            setLoading(true);
            const {data: user} = await httpUpdateUser(dataToSend.id, dataToSend);
            //console.log('data',data)
            dispatch(meSuccess(user));
            dispatch(alertToggle('Dati correttamente aggiornati'), 'success')
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    };

    return <>
        <SubHeading title={'Il mio profilo'} type={'small'}/>
        <div className={classes.gridContainer}>
            <form onSubmit={handleSubmit(onSubmit)} className={classes.formRoot}>
                {loading && <Spinner/>}
                <div className={classes.imageContent}>
                    <p className={classes.title}>Immagine del profilo</p>
                    {!loading && <ProfileImagesUpload userPhoto={user?.photo_link} setPhotoLink={setPhotoLink}/>}
                </div>
                <div className={classes.gridContent}>
                    <Text type={'text'} label={'Nome'} name={'first_name'} ref={register} errors={errors.first_name} width={'200px'}/>
                    <Text type={'text'} label={'Cognome'} name={'last_name'} ref={register} errors={errors.last_name}/>
                    <DayPickerInput label={'Data di Nascita'} name={'birth_date'} inForm={true} ref={register}
                                    control={control} errors={errors.birth_date} showYearDropdown={true}
                                    closeOnSelect={true}/>
                    <Text type={'text'} label={'Email Aziendale'} name={'email'} ref={register} errors={errors.email}/>
                    <Text type={'text'} label={'Telefono'} name={'phone'} ref={register} errors={errors.phone}/>
                </div>
                <div className={classes.btns}>
                    <Button type="button" onClick={() => reset({
                        ...user,
                        birth_date: user?.birth_date ? new Date(user?.birth_date) : '',
                    })} data-small>Annulla</Button>
                    <Button type={'submit'} data-primary data-small>Salva</Button>
                </div>
            </form>
        </div>
    </>
};

export default Profile;
