import axios from 'axios';

/**
 * fetch stock data
 * @param page
 * @param limit
 * @param sorters
 * @param filters
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpFetchStock = (page, limit,sorters, filters, uuid = null) => {
    return axios.post(`/cars/search?page=${page}&limit=${limit}${sorters ? '&'+ sorters : ''}`, {...filters,uuid:uuid});
};

/**
 * fetch stock's revision data
 * @param page
 * @param limit
 * @param filters
 * @param sorters
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpFetchStocksRevision = (page, limit,sorters, filters) => {
    return axios.post(`/cars/search?page=${page}&limit=${limit}&no_price='true'${sorters ? '&'+ sorters : ''}`, {...filters});
};

/**
 * fetch stock car filters
 * @param filters
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpGetStockFilters = (filters) => {
    return axios.post(`/cars/stock/autocomplete`, {...filters});
};

/**
 * fetch stock car filters
 * @param filters
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpGetStockRevisionFilters = (filters) => {
    return axios.post(`/cars/stock/autocomplete`, {...filters});
};

/**
 * fetch campaign car filters
 * @param filters
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpGetCampaignFilters = (filters) => {
    return axios.post(`/cars/campaigns/autocomplete`, {...filters});
};

/**
 * edit a car field
 */
export const httpEditStockInfo = (data, isNewVehicle = false) => {
    return axios.put(isNewVehicle ? `/cars/${data.car.id}?direct_acquisition=true` : `/cars/${data.car.id}`, {...data});
};

/**
 * fetch car current offers
 * @param plate
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpFetchOffers = (plate) => {
    return axios.get(`/cars/current_offers?plate=${plate}`);
};

/**
 * fetch car price history
 * @param id
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpFetchPriceHistory = (id) => {
    return axios.get(`/cars/${id}/price_history`);
};

/**
 * edit car price history
 * @param id
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpEditPriceHistory = (id, data) => {
    return axios.post(`/cars/${id}/price_history`, {...data});
};

export const httpCreatePhotoset = (carId, photoset) => {
    const url = `/cars/${carId}/photosets`;
    return axios.post(url, photoset)
};

export const httpUpdatePhotoset = (carId, photosetId, data) => {
    const url = `/cars/${carId}/photosets/${photosetId}`;
    return axios.put(url, data)
};

export const httpDeletePhotoset = (carId, photosetId) => {
    return axios.delete(`/cars/${carId}/photosets/${photosetId}`)
};

/**
 * download report
 * **/
export const httpDownloadStockReport = (type,search_id) => {
    return axios.get(`/cars/export`,{params:{ type: type, search_id }});
};

/**
 * approve car price
 */
export const httpApproveCarPrice = (id) => {
    return axios.post(`/cars/${id}/approve_price`);
};

/**
 * deny car price
 */
export const httpDenyCarPrice = (id) => {
    return axios.post(`/cars/${id}/reject_price`);
};


/**
 * get warranty autocomplete
 */
export const httpGetWarranties= () => {
    return axios.get(`/warranties/autocomplete`);
};