import React, {useState} from 'react';
import {createUseStyles} from 'react-jss';
import SidebarNavLink from "../../Commons/SidebarNavLink";
import NewVehicleStepsModal from "../Stock/NewVehicleStepsModal";
import Popover from "../../Commons/Popover.js";
import Button from "../../Commons/Button.js";
import {AddIcon} from "../../assets/icons.js";
import MultipleShiftsRequestModal from "../Shifts/MultipleShiftsRequestModal.js";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";

const useStyles = createUseStyles((theme) => ({
	voices: {
		zIndex:2,
		display: ({collapsedNav}) => collapsedNav ? "none" : "grid",
		color: theme.colors.white,
		fontWeight: 500,
	},
	btns: {
		margin: '16px 0'
	}
}));


const StockNavigation = ({collapsedNav}) => {
	const classes = useStyles({collapsedNav});
	const {pathname} = useLocation();
	const userOperations = useSelector(state => state.users.operations);
	const isAdmin = useSelector(state => state.users.user.operations?.admin);
	const [showNewPopover, setShowNewPopover] = useState(false);
	const [showTransportPopover, setShowTransportPopover] = useState(false);

	return <>
		{showNewPopover && <Popover withHeader={false} minWidth={550} onClose={() => setShowNewPopover(!showNewPopover)} contextMode={false}>
			<NewVehicleStepsModal onClose={() => setShowNewPopover(!showNewPopover)}/>
		</Popover>}
		{showTransportPopover && <Popover withHeader={false} minWidth={450} onClose={() => setShowTransportPopover(!showTransportPopover)} contextMode={false}>
			<MultipleShiftsRequestModal onClose={() => setShowTransportPopover(!showTransportPopover)}/>
		</Popover>}

		<div className={classes.voices}>
			<SidebarNavLink to={'/stock'}>Lista stock</SidebarNavLink>
			{(isAdmin || userOperations?.pricing_access) &&<SidebarNavLink to={'/stock/pricing'}>Pricing</SidebarNavLink>}
			{(isAdmin || userOperations?.price_access) && <SidebarNavLink to={'/stock/reprice'}>Reprice</SidebarNavLink>}
			{(isAdmin || userOperations?.price_access) && <SidebarNavLink to={'/stock/trade-policies'}>Politiche commerciali</SidebarNavLink>}
			{pathname === '/stock' && <Button data-transparent className={classes.btns} icon={<AddIcon/>} onClick={() => setShowNewPopover(!showNewPopover)}>Nuovo veicolo</Button>}
			{(isAdmin || userOperations?.transfer_access) && <Button data-transparent className={classes.btns} onClick={() => setShowTransportPopover(!showNewPopover)}>Movimenta veicoli</Button>}
		</div>
	</>
};

export default StockNavigation;
