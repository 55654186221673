import React from 'react';
import {createUseStyles} from 'react-jss';
import {useHistory} from "react-router-dom";
import {matchPath} from "react-router";
import SidebarNavLink from "../../Commons/SidebarNavLink";
import {useSelector} from "react-redux";

const useStyles = createUseStyles((theme) => ({
    voices: {
        zIndex:2,
        display: ({collapsedNav}) => collapsedNav ? "none" : "grid",
        color: theme.colors.white,
        fontWeight: 500,
        '& h4':{
            display: 'block',
            marginBottom: theme.spacing * 2,
            textTransform: 'uppercase',
            fontSize: 18,
        }
    },
}));


const CarNavigation = ({collapsedNav}) => {
    const classes = useStyles({collapsedNav});
    const userOperations = useSelector(state => state.users.operations);
    const isAdmin = useSelector(state => state.users.user.operations?.admin);
    const canReadOrEdit = (userOperations?.can_read_car || userOperations?.can_edit_car);

    const {location: {pathname}} = useHistory();
    const {params: {id}} = matchPath(pathname, {
        path: "/stock/:id",
        exact: false,
        strict: false
    });

    return <>
        <div className={classes.voices}>
            <h4>Scheda veicolo</h4>
            {(isAdmin || canReadOrEdit) && <SidebarNavLink to={`/stock/${id}`}>Task</SidebarNavLink>}
            {(isAdmin || canReadOrEdit) && <SidebarNavLink to={`/stock/${id}/info`}>Info vettura</SidebarNavLink>}
            {(isAdmin || userOperations?.reconditioning_access) &&
            <SidebarNavLink to={`/stock/${id}/restore`}>
                Ricondizionamenti
            </SidebarNavLink>}
            {(isAdmin || userOperations?.photo_access) &&
            <SidebarNavLink to={`/stock/${id}/photo`}>
                Foto
            </SidebarNavLink>}
            {(isAdmin || userOperations?.transfer_access) &&
            <SidebarNavLink to={`/stock/${id}/shifts`}>
                Spostamenti
            </SidebarNavLink>}
            {(isAdmin || userOperations?.backoffice_access || userOperations?.ACI_access) &&
            <SidebarNavLink to={`/stock/${id}/documents`}>
                Documenti e ACI
            </SidebarNavLink>}
            {(isAdmin || canReadOrEdit) && <SidebarNavLink to={`/stock/${id}/economics`}> Dati economici </SidebarNavLink>}
        </div>
    </>
};

export default CarNavigation;
