import React, {useState} from 'react';
import {createUseStyles} from 'react-jss';
import Popover from './Popover';
import useDimensions from 'react-use-dimensions';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {toMoney} from "../helpers/utils.js";
import {ArrowDown} from "../assets/icons.js";

const useStyles = createUseStyles((theme) => ({
    StepInfoContainer: {
        maxHeight: 130,
        display: "grid",
        alignItems: "center",
        borderRadius: 20,
        padding: theme.spacing * 2,
        background: theme.colors.white,
        ...theme.shadow,
    },
    StepWrapper: {
        display: "grid",
        gridColumnGap: "24px",
        gridTemplateRows: "auto",
        gridTemplateColumns: "0.3fr 1fr"
    },
    StepImg: {
        position: 'relative',
        borderRadius: 20,
        width: '100%',
        backgroundImage: ({image}) => `url(${image})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        minWidth: 130,
        maxHeight: 90
    },
    StepTitle: {
        '& small': {
            color: theme.colors.darkGrey
        },
        '& p': {
            fontSize: 14
        }
    },
    /* DROPDOWN */
    StepStatus: {
        textTransform: 'uppercase',
        position: 'absolute',
        display: 'grid',
        padding: [theme.spacing / 2, theme.spacing],
        background: theme.colors.black,
        color: theme.colors.white,
        borderRadius: 16,
        zIndex: 4,
        marginTop: -6,
        fontSize: 10,
        right: "3%",
        bottom: "-12px",
        cursor: ({isExternal,toRetrieve}) => (!isExternal || toRetrieve) ? 'pointer' : 'not-allowed',
        gridTemplateColumns: "1fr auto", gridColumnGap: 4,
        minWidth:124,
        '& svg':{
            fill: theme.colors.white,
            width: '12px!important'
        }
    },
    shadow: {
        ...theme.shadow
    },
    dropDownMenu: {
        justifyContent: 'end',
        padding: [theme.spacing, 0],
        '& div': {
            display: 'grid',
            justifyContent: 'end',
            padding: theme.spacing / 2,
            '&:hover': {
                background: theme.colors.xLightGrey
            },
            '& :hover': {
                background: theme.colors.grey
            }
        }
    },
    menuItem: {
        textTransform: 'uppercase',
        display: 'inline-block',
        padding: [theme.spacing / 2, theme.spacing],
        background: theme.colors.lightGrey,
        color: theme.colors.black,
        borderRadius: 16,
        zIndex: 4,
        cursor: 'pointer',
        fontSize: 10,
        '&:hover': {
            backgroundColor: theme.colors.lightGrey
        }
    },
    infos:{
        display: "grid",
        gridTemplateColumns: "1fr auto",
        gridColumnGap: "16px",
        marginTop: theme.spacing*2,
        '& p':{
            '& small':{
                fontSize: 12,
                fontWeight: 600,
                color: theme.colors.black
            },
            '& strong':{
                fontSize: 18
            },
            '& span':{
                fontSize: 12,
            },
            '& b':{
               textTransform: 'uppercase'
            },
        }
    }
}));

const CarCardInfo = ({image, car, statuses, changeCarStatusCb}) => {
    const [open, setOpen] = useState(false);
    const [ref, {x, y}] = useDimensions();

    //permission control
    const isExternal = useSelector(state => (state.users.operations.workshop || state.users.operations.tire_center || state.users.operations.body_shop));
    const classes = useStyles({isExternal,image, toRetrieve: car?.status === 'to_retrieve'});
    const carBrand = car?.model?.brand?.name ? car.model.brand.name : '';
    const carModel = car?.model?.name ? car.model.name : '';

    return (
        <div className={classes.StepInfoContainer}>
            <div className={classes.StepWrapper}>
                <div className={classes.StepImg} ref={ref}>
                    {!isExternal && car?.status && <div className={classes.StepStatus} onClick={() => (!isExternal && car?.status !== 'to_retrieve' ) ? setOpen(!open) : Function.prototype}>
                        {statuses.filter(item => item.value === car.status)[0]?.label} <ArrowDown data-color/>
                    </div>}
                    {open && (
                        <Popover onClose={() => setOpen(!open)} x={x + 3} y={y + 105}
                                 className={classes.shadow} withHeader={false} contextMode={true}>
                            <div className={classes.dropDownMenu} onClick={() => setOpen(!open)}>
                                {statuses.map((status, index) => {
                                    return ((car.status !== status.value) && <div key={index}>
											<span className={classes.menuItem}
                                                  onClick={() => changeCarStatusCb(status.value)}>
												{status.label}
											</span>
                                    </div>)
                                })}
                            </div>
                        </Popover>)}
                </div>

                <div className={classes.StepTitle}>
                    <p>
                        <b>{carBrand}</b> <br/>
                        <span>{carModel}</span>
                    </p>
                    <div className={classes.infos}>
                        <p>
                            <small>Prezzo di vendita</small><br/>
                            <strong>{toMoney(car?.price)}</strong>
                        </p>
                        <p>
                            <b>{car?.plate}</b><br/>
                            <span>{car?.mileage}km</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

CarCardInfo.propTypes = {
    image: PropTypes.string,
    carStatus: PropTypes.string,
    carTitle: PropTypes.string,
    carPlate: PropTypes.string,
    carKm: PropTypes.string,
    activeStep: PropTypes.number,
    percentCompleted: PropTypes.number
};

export default CarCardInfo;
