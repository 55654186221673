import React, {useState, useCallback, useMemo, useEffect} from 'react';
import {createUseStyles} from "react-jss";
import ConversationItem from "./ConversationItem/ConversationItem";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentConversationId} from "../../../../store/reducers/chat/currentConversation";
import {getLatestMessageTimetokenByChannelId, getUsersById} from "../../../../store/reducers/chat/chats";
import {getLoggedInUserUuid} from "../../../../store/reducers/user";
import {fetchMemberships} from "pubnub-redux";
import {usePagination} from "../../../../hooks/usePagination";
import {setMembershipsPagination} from "../../../../store/actions/chat/pagnationActions";
import {getMembershipsPaginationStateById} from "../../../../store/reducers/chat/pagination";
import JoinConversationDialog from "../../JoinConversationDialog/JoinConversationDialog";
import {getTargetUserUuid, isEmptyObj} from "../../chatUtils";
import Text from "../../../../Commons/Text";
import {
    joinConversation,
    updateUnreadMessageCounter
} from "../../../../store/actions/chat/joinedConversationsActions";
import {focusOnConversation} from "../../../../store/actions/chat/currentConversationActions";
import {getChatStatusOpened} from "../../../../store/reducers/chat/status";
import {DIRECT_CHANNEL_TYPE} from "../../../../helpers/constants/chat";
import {
    getJoinedConversationsByUserId
} from "../../../../store/reducers/chat/joinedConversations";
import {getExcludedUsersSlice} from "../../../../store/reducers/chat/excludedUsers";
import {fetchMessageHistory} from "../../../../store/actions/chat/chatActions";

const useStyles = createUseStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        minWidth: 416
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        color: '#C1C1C1',
        fontSize: 18
    },
    addIcon: {
        cursor: 'pointer'
    },
    list: {
        overflowY: 'scroll',
        display: 'flex',
        flexDirection: 'column',
    },
    empty: {
        padding: 32,
        color: '#C1C1C1'
    },
    searchBar: {
        padding: [40, 32]
    },
    searchBarInput: {
        color: '#C1C1C1',
        backgroundColor: 'rgba(255,255,255,0.1)'
    },
    channels: {
        color: '#C1C1C1',
        fontWeight: 700,
        padding: [8, 24, 8, 32],
        fontSize: '18px'
    },
    contactTitle: {
        padding: [8, 24, 8 ,32],
        color: '#C1C1C1',
        fontSize: 18,
        fontWeight: 700
    }
}))


const MyConversations = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [joinConversationModalOpened, setJoinConversationModalOpened] = useState(false)
    const currentConversationId = useSelector(getCurrentConversationId);
    const joinedConversations = useSelector(getJoinedConversationsByUserId);
    const userUUID = useSelector(getLoggedInUserUuid);
    const users = useSelector(getUsersById);
    const [searchTerm, setSearchTerm] = useState('');
    const isChatOpened = useSelector(getChatStatusOpened)
    const storedPaginationState = useSelector(getMembershipsPaginationStateById)[userUUID];
    const latestMessages = useSelector(getLatestMessageTimetokenByChannelId);
    const excludedUsers = useSelector(getExcludedUsersSlice)

    useEffect(() => {
        dispatch(updateUnreadMessageCounter(currentConversationId))
    }, [isChatOpened])

    useEffect(() => {
        joinedConversations[userUUID].forEach(ch => {
            fetchLatestMessage(ch.id)
        });
    }, [])


    const fetchLatestMessage = async (channel) => {
        const action = await dispatch(
            fetchMessageHistory({
                count: 1,
                channel,
                start: undefined,
                stringifiedTimeToken: true,
                includeMessageActions: true,
            })
        );
    }

    const handleConversationOpen = (userId) => {
        dispatch(joinConversation(userId, joinedConversations[userUUID]));
    }

    const restorePaginationState = useCallback(() => {
        return storedPaginationState;
    }, [storedPaginationState]);

    const savePaginationState = useCallback(
        (channel, pagination, count, pagesRemain) => {
            dispatch(
                setMembershipsPagination(channel, { pagination, count, pagesRemain })
            );
        },
        [dispatch]
    );

    const getNextPage = useCallback(
        async (next, total, uuid) => {
            const pageSize = 20;
            const action = await dispatch(
                fetchMemberships({
                    uuid,
                    limit: pageSize,
                    include: {
                        channelFields: true,
                        customChannelFields: true,
                        customFields: true,
                        totalCount: true
                    },
                    page: {
                        next: next || undefined
                    }
                })
            )
            const response = action.payload.response;
            return {
                results: response.data,
                pagination: response.next,
                pagesRemain:
                    response.data.length > 0 && response.totalCount && total
                        ? total + response.data.length < response.totalCount
                        : response.data.length === pageSize
            };
        },
        [dispatch]
    );

    const { containerRef, endRef } = usePagination(
        getNextPage,
        userUUID,
        savePaginationState,
        restorePaginationState
    );

    const directChannels = useMemo(
        () => {
            return !isEmptyObj(joinedConversations) ?
                joinedConversations[userUUID]
                    .filter(ch => ch.channelMeta?.custom?.type === DIRECT_CHANNEL_TYPE)
                    .sort((a,b)=> latestMessages[b.id] - latestMessages[a.id] )
                :
                []
        },
        [joinedConversations, latestMessages]
    );

    const directChannelsFilter = useMemo(
        () => {
            return directChannels
                .filter(ch => {
                    const targetUUID = getTargetUserUuid(userUUID, ch.id);
                    const name = users[targetUUID]?.name || ch.id;
                    return name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    }
                )
        },
        [joinedConversations, directChannels,  searchTerm]
    );

    const usersFiltered = useMemo(
        () => {
            const usersArr = Object.values(users);
            return usersArr
                .filter(user => {
                        return user.name
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase()) && (!excludedUsers.includes(user.id))
                    }
                )
        },
        [joinedConversations, users, searchTerm]
    );

    const generalChannels = useMemo(
        () =>
            !isEmptyObj(joinedConversations) ?
                joinedConversations[userUUID]
                    .filter(ch => ch.channelMeta?.custom?.type !== DIRECT_CHANNEL_TYPE):
                [],
        [joinedConversations]
    );

    const handleFocusOnConversation = (targetChannel) => {
        dispatch(focusOnConversation(targetChannel))
        dispatch(updateUnreadMessageCounter(targetChannel))
    }

    return <>
        {joinConversationModalOpened && <JoinConversationDialog closeCb={() => setJoinConversationModalOpened(false)}/>}
        <div className={classes.root} >
            <div className={classes.list} ref={containerRef}>
                <div className={classes.searchBar}>
                    <Text
                        inputClassName={classes.searchBarInput}
                        type="text"
                        placeholder={'Ricerca utenti...'}
                        value={searchTerm}
                        onChange={event => setSearchTerm(event.target.value)}
                    />
                </div>
                { (generalChannels.length > 0 || directChannelsFilter.length > 0) ?
                    <>
                        <div className={classes.channels}>Messaggi</div>
                        {
                            generalChannels
                                .map(({
                                          id,
                                          channelMeta
                                      }) => {
                                        return <ConversationItem
                                            id={id}
                                            onClick={() => handleFocusOnConversation(id)}
                                            selected={id === currentConversationId}
                                            key={id}
                                            name={`# ${channelMeta?.name ?? 'Canale senza nome'}`}
                                            onLeave={() => console.log('leave conversation')}
                                        />
                                    }
                                )
                        }
                        {
                            directChannelsFilter
                                .map(({
                                          id,
                                          channelMeta
                                      }) => {
                                    const targetUUID = getTargetUserUuid(userUUID, id)
                                    const name = channelMeta?.name ?? users[targetUUID]?.name
                                    return <ConversationItem
                                        id={id}
                                        isDirect={true}
                                        onClick={() => handleFocusOnConversation(id)}
                                        selected={id === currentConversationId}
                                        key={id}
                                        name={name ?? id}
                                        onLeave={() => console.log('leave conversation')}
                                    />
                                })
                        }
                    </>
                    :
                    <div className={classes.empty}>
                        Nessuna conversazione
                    </div>
                }
                {
                    searchTerm && <>
                        <div className={classes.contactTitle}>Contatti</div>
                        {usersFiltered.map((user, i) => <ConversationItem
                            isDirect={true}
                            onClick={() => handleConversationOpen(user.id)}
                            key={i}
                            name={user.name}
                        />)}
                    </>
                }
                <div ref={endRef} />
            </div>
        </div>
    </>
}

export default MyConversations;



