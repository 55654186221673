import React from 'react';
import {createUseStyles} from "react-jss";
import {ChatIcon} from "../../assets/icons";
import {getChatStatusOpened} from "../../store/reducers/chat/status";
import {useDispatch, useSelector} from "react-redux";
import MessageCenter from "./MessageCenter";
import {setChatOpened} from "../../store/actions/chat/statusActions";
import {getTotalUnreadMessages} from "../../store/reducers/chat/unreadMessage";

const useStyles = createUseStyles(theme => ({
    floatingButton: {
        position: "fixed",
        height: 40,
        width: 40,
        borderRadius: 40,
        bottom: 56,
        zIndex: 1034,
        right: 16,
        display: "grid",
        alignItems: "center",
        justifyItems: "center",
        backgroundColor: '#353535',
        padding: 8,
        cursor: 'pointer',
        '& svg': {
            fill: theme.colors.white
        }
    },
    frame: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1034,
        display: "flex",
        flexDirection: "column"
    },
    unreadMessagesBadge: {
        position: "absolute",
        top: -8,
        right: -4,
        backgroundColor: theme.colors.red,
        color: theme.colors.white,
        padding: '2px 4px',
        fontSize: 10,
        borderRadius: 24,
        fontWeight: 700,
        border: `2px solid ${theme.colors.body}`,
        minWidth: 20,
        textAlign: 'center',
    }
}));

const ChatFrame = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isOpen = useSelector(getChatStatusOpened);
    const totalUnread = useSelector(getTotalUnreadMessages);

    return isOpen ?
        <div className={classes.frame}>
           <MessageCenter/>
        </div>
        :
        <div className={classes.floatingButton}>
            <ChatIcon data-color onClick={() => dispatch(setChatOpened(true))}/>
            {totalUnread >0 && <span className={classes.unreadMessagesBadge}>{totalUnread}</span>}
        </div>
};

export default ChatFrame;