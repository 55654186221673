import React, {useEffect, useState} from 'react';
import {createUseStyles} from "react-jss";
import Badge from "../../Commons/Badge.js";
import {filterNameResolver, removeUnsettedKeyInObj} from "../../helpers/utils.js";
import Checkbox from "../../Commons/Checkbox.js";
import Button from "../../Commons/Button.js";
import {FilterIcon, RefreshIcon} from "../../assets/icons.js";


const useStyles = createUseStyles(theme => ({
    root: {

    },
    activeFilters: {
        display: 'grid',
        justifyContent: "start",
        alignContent: "center",
        gap: theme.spacing,
        gridAutoFlow: "row",
        marginBottom: theme.spacing * 2,
    },
    filtersList: {
        gridColumnGap: theme.spacing,
        display: "grid",
        gridAutoFlow: "column",
        margin: [theme.spacing, 0]
    },
    filtersButtons: {
        display: "grid",
        gridTemplateColumns: "1fr 80px auto",
        gridColumnGap: theme.spacing * 2,
        alignItems: "center"
    },
    reset: {
        color: theme.colors.red,
        fontSize: 16,
        fontWeight: 400,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            marginRight: "4px",
            fill: theme.colors.red
        }
    },
}));


const AdvancedFiltersController = ({structure,filters, removeSpecificFilterCb,showFilterModalCb,resetAdvancedFiltersCb,handleHideBookedCb}) => {
    const classes = useStyles();

    return <>
        <div className={classes.activeFilters}>
            {(!!Object.keys(filters).length && structure) && <>
                <strong>Filtri attivi</strong>
                <div className={classes.filtersList}>
                    {Object.keys(filters).map((activeFilter, index) => {
                        return filters[activeFilter] && <Badge key={index} statusColor={'light'} isRemovable={true}
                                      title={filterNameResolver(structure, activeFilter)}
                                      badgeSize={'small'}
                                      badgeRadius={16}
                                      removableCallback={() => removeSpecificFilterCb(activeFilter)}/>
                    })}
                </div>
            </>}
        </div>
        <div className={classes.filtersButtons}>
            <Checkbox name={'hide_booked'} isSmall={true} onChange={handleHideBookedCb}
                      label={'Escludi prenotate'} checked={filters.hide_booked}/>
            <Button data-primary data-small icon={<FilterIcon/>}
                    onClick={showFilterModalCb} disabled={!structure}> Filtri </Button>
            <span className={classes.reset} onClick={resetAdvancedFiltersCb}> <RefreshIcon data-color/> Resetta </span>
        </div>
    </>
};

export default AdvancedFiltersController;