import React, {useState, useMemo} from 'react';
import Popover from "../../Commons/Popover";
import {createUseStyles} from "react-jss";
import Text from "../../Commons/Text";
import userPlaceholderPhoto from "../../assets/images/noAvatar.png";
import Avatar from "../../Commons/Avatar";
import AutoLink from "../../Commons/AutoLink";

const useStyles = createUseStyles(theme => ({
    root: {
        width: 547,
        borderRadius: 28
    },
    body: {
        padding: [0, 50, 36, 50]
    },
    searchUser: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridColumnGap: 16,
        margin: [60, 0, 32, 0]
    },
    searchBarInput: {

    },
    textarea: {
        margin: [16, 0, 32, 0],
        color: '#5B5B5B',
        borderRadius: 4,
        fontSize: 16,
        padding: [8, 12]
    },
    communicationText: {

    },
    title: {
        fontSize: 20,
        color: '#000',
        fontWeight: 600,
        marginBottom: 8
    },
    subtitle: {
        extend: 'title',
        fontSize: 18
    },
    message: {
        color: '#626262',
        maxHeight: 120,
        overflow: 'auto',
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        wordBreak: 'break-word',
    },
    usersSelection: {
        margin: [24, 0, 0, 0],
        boxShadow: '0px 0px 8px 1px rgb(0 0 0 / 5%)',
        borderRadius: 16,
        height: 364,
        overflow: 'hidden'
    },
    usersSelectionHeader: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        gridTemplateRows: 48,
        gridColumnGap: 16,
        alignItems: "center",
        fontWeight: 700,
        fontSize: 16,
        padding: [0, 24],
        borderBottom: '2px solid #E3E3E3',
    },
    userCounter: {
        color: '#626262',
        fontWeight: 400
    },
    userList: {
        overflow: "auto",
        maxHeight: 304
    },
    userOption: {
        cursor: 'pointer',
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gridTemplateRows: 48,
        gridColumnGap: 16,
        alignItems: "center",
        fontWeight: 500,
        padding: [0, 24]
    }
}));


const CommunicationDetails = ({message, receivers, onCloseCb}) => {

    const [searchForUser, setSearchForUser] = useState(null);
    const classes = useStyles();

    const filteredUsers = useMemo(() => {
        return receivers
            .filter(user => searchForUser ? user.full_name
                .toLowerCase()
                .includes(searchForUser.toLowerCase()) : true
            )
    }, [searchForUser])

    return <Popover onClose={onCloseCb}
                    className={classes.root}
                    bodyClassName={classes.body}
    >
        <div className={classes.title}>Dettagli</div>
        <div>
            <div className={classes.message}><AutoLink text={message}/></div>
        </div>
        <div className={classes.searchUser}>
            <Text
                inputClassName={classes.searchBarInput}
                type="text"
                placeholder={'Cerca utente...'}
                value={searchForUser}
                onChange={event => setSearchForUser(event.target.value)}
            />
        </div>
        <div className={classes.usersSelection}>
            <div className={classes.usersSelectionHeader}>
                <span>Utenti</span>
                <span className={classes.userCounter}>Inviato a {receivers.length} utent{receivers.length > 1 ? 'i': 'e'}</span>
            </div>
            <div className={classes.userList}>
                {filteredUsers.map(user => {
                    return <div className={classes.userOption} key={user.id}>
                        <Avatar size={'small'} img={user.photo_link}/>
                        <div>{user.full_name}</div>
                    </div>
                })}
            </div>
        </div>
    </Popover>
}


export default CommunicationDetails;