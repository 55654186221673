import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useParams} from 'react-router-dom';
import Spinner from '../../Commons/Spinner';
import CarHeading from "../../Components/Cars/CarHeading";
import {useDispatch, useSelector} from "react-redux";
import {setCarDocuments, setCurrentCar, updateCarDocument, deleteCarDocument} from "../../store/actions/carsActions";
import {httpGetCar} from "../../HttpRequests/commons.http.js";
import {errorHandler} from "../../helpers/utils.js";
import Task from "../../Commons/Task.js";
import TabButton from "../../Commons/TabButton";
import CarDocument from "../../Components/Cars/CarDocument.js";
import {httpGetCarDocuments, httpGetCarDocumentsStructure,} from "../../HttpRequests/documents.http.js";

const useStyles = createUseStyles(theme => ({

    gridContainer: {
        'position': 'relative',
        // with big/small subheader like permute page
        height: `calc(100vh - ${theme.carSubHeader}px)`,
        // without subheader
        //height: `calc(100vh - ${theme.headerHeight}px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        [theme.mUp]: {
            padding: [theme.spacing * 10, theme.spacing * 4, theme.spacing * 4]
        },
        [theme.s]: {
            padding: [theme.spacing],
        },
    },
    gridHeading: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        gridColumnGap: theme.spacing,
        padding: theme.spacing * 2,
        margin: theme.spacing * 2,
        borderBottom: `1px solid ${theme.colors.darkGrey}`,
        '& h3': {
            fontWeight: 600,
            lineHeight: 1,
            display: 'inline-block',
            fontSize: '1em'
        }
    },
    taskTitlesStyles: {
        display: "grid",
        fontSize: 14,
        boxSizing: "border-box",
        marginTop: theme.spacing,
        alignItems: "center",
        fontWeight: 600,
        gridTemplateColumns: "minmax(200px, 3fr) minmax(120px, 1fr) minmax(220px, 4fr) minmax(120px, 1fr) minmax(200px, 3fr)",
        '& div': {
            padding: [0, theme.spacing * 2],
        }
    },
    actionsBaseStyles: {
        width: '100%',
        display: 'inline-grid',
        // gridTemplateColumns: 'minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) 1fr 1fr 1fr',
        gridTemplateColumns: 'repeat(10, 1fr)',
        gridColumnGap: theme.spacing,
        backgroundColor: theme.colors.white,
        borderRadius: 10,
        // paddingLeft: theme.spacing,
        // paddingRight: theme.spacing,
        padding: [theme.spacing * 2, theme.spacing],
        marginTop: theme.spacing * 4,
        marginBottom: theme.spacing * 2,
        boxSizing: 'border-box',
        alignItems: 'center',
        justifyItems: 'center',
    },
    tabsContainer: {
        display: "grid",
        gridColumnGap: "24px",
        gridTemplateColumns: "repeat(auto-fill, minmax(150px, 190px) ) ;",
        gridRowGap: "24px"
    }
}));

const CarDocuments = () => {
    const {id} = useParams();
    const currentCar = useSelector(state => state.cars.currentCar);
    const carTasks = useSelector(state => state.cars.carTasks);
    const classes = useStyles({bigSubHeader: false});
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('vehicle');
    const usersAutocomplete = useSelector(state => state.autocompletes.usersAutocomplete);
    const documents = useSelector(state => state.cars.carDocuments);
    const dispatch = useDispatch();

    const getCarData = async () => {
        setLoading(true);
        try {
            const {data: car} = await httpGetCar(id);
            dispatch(setCurrentCar(car));
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    };

    const getDocuments = async () => {
        setLoading(true);
        try {
            const {data: structure} = await httpGetCarDocumentsStructure();
            const {data: documentsLoaded} = await httpGetCarDocuments(id);
            let newDocumentList = [...structure];
            structure.map((item, index) => {
                documentsLoaded.map(document => {
                    if (document.type === item.value) {
                        newDocumentList[index] = document
                    }
                })
            });

            dispatch(setCarDocuments(newDocumentList))
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    };

    const deleteDocument = (data) => {
        dispatch(deleteCarDocument(data))
    };

    const addNewDocument = (docId, newDocument) => {
      dispatch(updateCarDocument(docId,newDocument))
    };

    useEffect(() => {
        if (+currentCar?.id !== +id) {
            //if the requested car is different from the stored one
            dispatch(setCurrentCar(null));
            getCarData();
        }
        getDocuments(id);
        // clean up car documents
        return () => {
            dispatch(setCarDocuments([]))
        };
    }, []);

    const taskTitles = (
        <div className={classes.taskTitlesStyles}>
            <div>OPERAZIONE</div>
            <div>Data</div>
            <div>Note</div>
            <div>Stato</div>
            <div>Operatore</div>
        </div>
    );

    return (<>
        <CarHeading headingTitle={' Documenti e ACI'} car={currentCar}/>
        {loading && <Spinner overlayFullscreen={true}/>}
        <section className={classes.gridContainer}>
            {carTasks && taskTitles}
            {carTasks && carTasks.filter(e => e.physical === false).map((task, index) =>
                <Task key={index} task={task} taskUsers={usersAutocomplete} isSmall={true}/>
            )}

            <div className={classes.actionsBaseStyles}>
                <TabButton data-small active={activeTab === 'vehicle'} notification={false}
                           onClick={() => setActiveTab('vehicle')}>Vettura</TabButton>
                <TabButton data-small active={activeTab === 'purchase'}
                           onClick={() => setActiveTab('purchase')}>Acquisto</TabButton>
                <TabButton data-small active={activeTab === 'sale'}
                           onClick={() => setActiveTab('sale')}>Vendita</TabButton>
            </div>
            {!!documents.length && <div className={classes.tabsContainer}>
                {documents.map((document, index) => (
                    (document?.category === activeTab) && <CarDocument doc={document} key={index} label={document.label}
                                 type={document?.value ? document.value : document.type}
                                 setDocumentsCb={addNewDocument} onDeleteDocumentCb={deleteDocument}/>)
                )}
            </div>}
        </section>
    </>);
};

export default CarDocuments
