import React, {useState} from 'react';
import {createUseStyles} from "react-jss";
import Spinner from "../../Commons/Spinner.js";
import Button from "../../Commons/Button.js";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import Select from "../../Commons/Select.js";
import {errorHandler, formattedDateIso, shiftsTypes,} from "../../helpers/utils.js";
import * as Yup from "yup";
import DayPickerInput from "../../Commons/DayPickerInput.js";
import Textarea from "../../Commons/Textarea.js";
import {alertToggle} from "../../store/actions/alertActions.js";
import {httpSetMultipleShiftsRequest} from "../../HttpRequests/shifts.http.js";

const useStyles = createUseStyles(theme => ({
    root: {
        display: 'grid',
        width: '100%',
        gridRowGap: theme.spacing * 3,
        justifyItems: 'center',
    },
    title: {
        fontWeight: 600,
        margin: [0,theme.spacing * 2,theme.spacing * 2, 0],
        display: 'grid',
        alignItems: 'center',
        gridColumnGap: theme.spacing,
        gridTemplateColumns: '1fr auto',
        '& img': {
            width: 72
        }
    },
    body: {
        display: 'grid',
        gridTemplateColumns: "1fr 1fr",
        gridRowGap: theme.spacing * 2,
        gridColumnGap: theme.spacing * 4,
        width: '100%',
        maxWidth:450,
    },
    full:{gridColumn: '1 / span 2'},
    btns: {
        display: 'grid',
        gridColumnStart: 1,
        gridColumnEnd: 3,
        justifyItems: 'center',
        marginTop: 32,
        gridRowGap: 8,
        '& small': {},
        '& p': {
            textDecoration: 'underline',
            cursor: 'pointer'
        }
    }
}));

const validationSchema = Yup.object().shape({
    plates: Yup.string().nullable()
        .required(`Inserisci almeno una targa.`),
    type: Yup.string().nullable()
        .required(`Inserisci il tipo.`),
    location_id_to: Yup.string().nullable()
        .required(`Inserisci la posizione di partenza.`),
    location_id_from: Yup.string().nullable()
        .required(`Inserisci la destinazione di arrivo.`),
    preferred_date: Yup.string().nullable()
        .required(`Inserisci una data.`),
});

const MultipleShiftsRequestModal = ({onClose}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const allLocations = useSelector( state => state.autocompletes.locations);
    const {register, handleSubmit, formState:{isValid,touched}, control, errors, watch} = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        validationSchema
    });

    const onSubmit = async (values) => {
        const dataToSend = {
            cars: watch('plates').map(plate => ({plate:plate.value})),
            type: watch('type')?.value,
            location_id_to: watch('location_id_to')?.value,
            location_id_from: watch('location_id_from')?.value,
            preferred_date: values?.preferred_date ? formattedDateIso(values.preferred_date) : null,
            notes: values.notes
        };

        try {
            setIsLoading(true);
            await httpSetMultipleShiftsRequest(dataToSend);
            dispatch(alertToggle('Richiesta di spostamento multiplo, creata con successo.', 'success'));
            onClose();
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setIsLoading(false);
        }
    };

    return <div className={classes.root}>
        {isLoading && <Spinner/>}
        <h4 className={classes.title}> Richiesta di Spostamento multiplo </h4>

        <form className={classes.body} onSubmit={handleSubmit(onSubmit)}>
            <Select name={'plates'} options={[]} ref={register} control={control}
                    disabled={false} label={`Targhe*`} isClearable={true} isCreatableSelect={true} isMulti={true}
                    errors={errors.plates} className={classes.full} placeholder={'Scrivi qui la targa'}/>

            <Select name={'type'} options={shiftsTypes} ref={register} control={control} className={classes.full}
                    disabled={false} label={`Tipologia*`} isClearable={false} errors={errors.type}/>

            <Select name={'location_id_from'} options={allLocations} ref={register} control={control} disabled={false}
                    label={`Da*`} isClearable={false} errors={errors.location_id_from}/>
            <Select name={'location_id_to'} options={allLocations} ref={register} control={control} disabled={false}
                    label={`A*`} isClearable={false} errors={errors.location_id_to}/>

            <DayPickerInput label={'Data*'} name={'preferred_date'} inForm={true} ref={register} className={classes.full}
                            control={control} errors={errors.preferred_date} showYearDropdown={true} closeOnSelect={true}
            />
            <Textarea rows={"4"} label={'Note'} name={'notes'} ref={register} className={classes.full} />

            <div className={classes.btns}>
                <Button type={'submit'} width={250} data-primary data-small disabled={(!isValid && touched)}> Invia richiesta </Button>
            </div>
        </form>
    </div>
};

export default MultipleShiftsRequestModal;