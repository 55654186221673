import React, {useEffect, useState} from 'react';
import {createUseStyles} from "react-jss";
import Spinner from "../../Commons/Spinner.js";
import Button from "../../Commons/Button.js";
import {useDispatch} from "react-redux";
import {httpCarsVersions, httpSetCarServiceVersion} from "../../HttpRequests/barters.http.js";
import CarVersion from "./CarVersion.js";
import eurotaxLogo from '../../assets/images/eurotax.jpg';
import {errorHandler} from "../../helpers/utils.js";
import PropTypes from "prop-types";

const useStyles = createUseStyles(theme => ({
    root: {
        display: 'grid',
        width: '100%',
        gridRowGap: theme.spacing * 3,
        justifyItems: 'center'
    },
    listContainer: {
        maxHeight: 400,
        padding: [0, theme.spacing * 2],
        marginBottom: theme.spacing * 2,
        scrollX: "hidden",
        scrollY: "scroll",
        width: "100%",
        overflow: "scroll"
    },
    title: {
        fontWeight: 600,
        margin: [theme.spacing * 2, 0],
        display: 'grid',
        alignItems: 'center',
        gridColumnGap: theme.spacing,
        gridTemplateColumns: '1fr auto',
        '& img': {
            width: 72
        }
    },
    results: {
        fontSize: 12,
        marginTop: -theme.spacing * 4
    }
}));

const EurotaxStep = ({carId, onVersionChoiceCb}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [versions, setVersions] = useState([]);
    const [currentVersion, setCurrentVersion] = useState(false);

    useEffect(() => {
        getVersions()
        /*console.log(carId)*/
    }, []);

    const getVersions = async () => {
        try {
            setIsLoading(true);
            const {data: versions} = await httpCarsVersions(carId, 'eurotax');
            versions.sort((a, b) => (a?.selected === true) ? -1 : 1);
            setCurrentVersion(versions.filter(item => item.selected === true)[0]);
            setVersions(versions);
            /* dispatch(alertToggle('bravo','success'))*/
        } catch (e) {
            errorHandler(e,dispatch)
        } finally {
            setIsLoading(false);
        }
    };

    const setSelectedVersion = async () => {
        try {
            setIsLoading(true);
            const {data} = await httpSetCarServiceVersion(carId, 'eurotax', currentVersion?.value);
            onVersionChoiceCb(data);
        } catch (e) {
            errorHandler(e,dispatch)
        } finally {
            setIsLoading(false);
        }
    };

    //console.log(!currentVersion, !currentVersion?.value);

    return <div className={classes.root}>
        {isLoading && <Spinner/>}
        <h4 className={classes.title}> Risultati Della ricerca <img src={eurotaxLogo} alt={'eurotax logo'}/></h4>
        <span className={classes.results}> <strong>{versions.length}</strong> Versioni trovate </span>
        <div className={classes.listContainer}>
            {versions.map((item, index) => {
                return <CarVersion setCurrentVersion={setCurrentVersion} currentVersion={currentVersion} version={item}
                                   key={index}/>
            })}
        </div>
        <Button width={250} type={'button'} data-primary
                onClick={() => setSelectedVersion()} disabled={!currentVersion?.value && !!versions.length}> Avanti </Button>
    </div>
};

export default EurotaxStep;

EurotaxStep.propTypes = {
    carId: PropTypes.any.isRequired,
    onVersionChoiceCb: PropTypes.func,
};