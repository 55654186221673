import {convertTimestampToTime} from "../../../../chatUtils";
import React from "react";
import {createUseStyles} from "react-jss";
import AutoLink from "../../../../../../Commons/AutoLink";

const useStyles = createUseStyles(theme => ({
    textMessage: {
        padding: '16px 24px',
        borderRadius: ({isMine}) => isMine ? '28px 0 28px 28px' : '0 28px 28px 28px',
        textAlign: 'left',
        backgroundColor: ({isMine}) => isMine ? '#2F2F2F' : '#FFF',
        color: ({isMine}) => isMine ? '#FFF' : '#2F2F2F',
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        wordBreak: 'break-word',
        width: 'fit-content',
        fontSize: 12,
        '& a': {
            color: ({isMine}) => isMine ? '#6296FF' : '#195FED'
        },
        [theme.mUp]:{
            fontSize: 14
        }
    },
    imageMessage: {
        extend: 'textMessage'
    },
    timeSent: {
        marginTop: 8,
        fontSize: 11,
        fontWeight: 300,
        color: ({isMine}) => isMine ? '#FFF' : '#2F2F2F',
    },
    deleted: {
        fontStyle: 'italic'
    }
}))

const TextMessageDisplay = ({message, time, isMine, isDeleted, channel}) => {
    const classes = useStyles({isMine});
    return isDeleted ?
        <div className={classes.textMessage}>
            <div className={classes.deleted}>Il messaggio è stato eliminato.</div>
        </div> :
        <div className={classes.textMessage}>
            <div><AutoLink text={message.text}/></div>
            <span className={classes.timeSent}>{convertTimestampToTime(time)}</span>
        </div>
}

export default TextMessageDisplay;