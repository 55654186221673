import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {createUseStyles} from 'react-jss';
import {map, orderBy} from 'lodash-es';
import {ArrowDown, ArrowUp, CanOrderIcon} from '../assets/icons.js';
import TableLoader from "./TableLoader.js";
import {Link} from "react-router-dom";
import {useWindowWidth} from "@react-hook/window-size";

const useStyles = createUseStyles((theme) => ({
    table: {
        height: ({noHeightLimit}) => noHeightLimit ? 'auto' : 'max-content',
    },
    tbody: {
        width: '100%',
        height: ({tbodyHeight, noHeightLimit}) => noHeightLimit ? 'auto' : tbodyHeight,
        maxHeight: ({noHeightLimit}) => noHeightLimit ? '600px' : 'none',
        minHeight: ({tbodyAvailableHeight, noHeightLimit}) => noHeightLimit ? 'auto' : tbodyAvailableHeight,
        overflowY: "scroll",
        overflowX: "hidden",
        '&::-webkit-scrollbar': {
            width: 0,//(props) => props.scrollbarWidth,
            background: 'transparent'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#cbcbcb'
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#909090'
        }
    },
    rowContainer: {
        width: '100%',
        height: 'auto',
        minHeight: ({rowMinHeight}) => rowMinHeight ? rowMinHeight : theme.spacing * 6,
        background: theme.colors.white,
        display: 'grid',
        alignItems: 'center',
        padding: [theme.spacing, theme.spacing * 2],
        marginBottom: theme.spacing / 2,
        position: 'relative',
        zIndex: 0,
        '&:not(:last-child)': {
            //for the last
        },
        '& a': {
            textDecoration: 'none'
        }
    },
    rowInner: {
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateColumns: ({columnsWidths}) => Object.values(columnsWidths).join(' '),
        gridColumnGap: 4,
        alignItems: 'center',
        fontSize: ({isTablet}) => isTablet ? 12 : 14,
        zIndex: 1
    },
    rowSubComponent: {
        display: 'grid',
        gridRowEnd: '2',
        gridRowStart: '2',
        gridColumnStart: '1',
        gridColumnEnd: '1'
    },
    expandedComponent: {
        display: 'grid',
        gridRowEnd: '3',
        gridRowStart: '3',
        gridColumnStart: '1',
        gridColumnEnd: '1',
        zIndex: 200
    },

    thead: {
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateColumns: ({columnsWidths}) => Object.values(columnsWidths).join(' '),
        gridColumnGap: 4,
        alignItems: 'center',
        width: ({hasScroll, scrollbarWidth}) => (hasScroll ? `calc(100% - ${scrollbarWidth}px)` : '100%'),
        background: theme.colors.white,
        height: 'auto',
        padding: [theme.spacing, theme.spacing * 2],
        borderTopLeftRadius: theme.spacing * 2,
        borderTopRightRadius: theme.spacing * 2,
        marginBottom: 2,
        fontSize: ({isTablet}) => isTablet ? 14 : 16,
        fontWeight: 600,
        zIndex: 1,
        position: 'relative'
    },
    td: {
        display: 'flex',
        zIndex: 2,
        '&:not(:last-child)': {
            'padding-right': (props) => props.columnPadding
        },
        '& > span': {
            //truncate with ellipsis
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        '& > small': {
            //truncate with ellipsis
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    },
    th: {
        extend: 'td',
        cursor: (props) => (props.backEndSortable ? 'pointer' : 'default'),
        userSelect: 'none',
        '& > small': {
            display: 'grid',
            alignItems: 'start',
            '& svg': {
                fill: theme.colors.black,
                width: 16
            },
            marginLeft: 3 //spacing between heading and up/down arrow
        },
        //align th and sorter arrow
        display: 'flex',
        alignItems: 'center'
    },
    columnsName:{
       '&::first-letter':{
           textTransform: 'capitalize'
       }
    },
    rowClickable: {
        cursor: 'pointer',
        transition: 'ease-in 300ms',
        zIndex: 1,
        textDecoration: 'none',
        '&:hover': {
            boxShadow: '0 0 6px rgba(0,0,0,0.16), 0 0 6px rgba(0,0,0,0.23)'
        }
    },
    columnClickable: {
        cursor: 'pointer'
    },
    left: {
        'justify-items': 'start'
    },
    center: {
        'justify-content': 'center'
    },
    right: {
        'justify-items': 'end'
    },
    emptyRow: {
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
        height: 60,
        width: '100%',
        background: theme.colors.white,
        '& span': {
            fontSize: 16,
            color: theme.colors.grey,
            fontWeight: 500
        }
    },
    tableFooter: {
        background: theme.colors.black,
        padding: [theme.spacing, theme.spacing * 2],
        marginTop: "-4px",
        borderRadius: "0 0 16px 16px"
    }
}));

const Table = ({
                   className,
                   classNames = {},
                   rowExpandedId,
                   setRowExpandedId,
                   tableFooterComponent: FooterComponent,
                   subRowComponent: SubRowComponent,
                   expandedComponent: ExpandedComponent,
                   expandedComponentCb = Function.prototype,
                   id,
                   data,
                   addedCars,
                   setData = Function.prototype,
                   overscan = 100,
                   rowMinHeight,
                   theadHeight,
                   backEndSortable = false,
                   customSorter,
                   sortable = false,
                   defaultSorters = {},
                   columns,
                   columnsWidths = {},
                   columnsNames = {},
                   columnsAlignments = {},
                   columnsRenderers = {},
                   columnsAlts = {},
                   columnsClasses = {},
                   columnsClick = {},
                   headersSorters = false,
                   headersRenderers = {},
                   onRowClickPath,
                   scrollbarWidth = 5,
                   autoTbodyHeight = true,
                   inLoading,
                   filters,
                   //infinite
                   onSetPageCb,
                   onSortingDataCb,
                   bodyMinHeight,
                   customEmptyRow: CustomEmptyRow,
                   //tBody
                   noHeightLimit = false,
               }) => {
    const ref = useRef();
    const [availableHeight, setAvailableHeight] = useState(400);
    const [sorters, setSorters] = useState(defaultSorters);
    const isTablet = (useWindowWidth() < 1200);

    /**
     * sorters and filters code
     */
    const head = useRef(null);
    const scrollToTop = () => {
        //console.log('heyyyyyyyy scrolled')
        head.current.scrollIntoView({behavior: "smooth"})
    };
    useEffect(() => {
            if (!!Object.keys(sorters).length) {
                if (backEndSortable) {
                    onSortingDataCb({order_by: Object.keys(sorters)[0], order_direction: Object.values(sorters)[0]})
                } else {
                    const sortedData = orderBy(data, Object.keys(sorters), Object.values(sorters));
                    setData
                        ? setData(sortedData)
                        : console.error('sorters will not have effect unless setData is provided');
                }
            }
        },
        [sorters]
    );
    useEffect(() => {
        //TODO RESETTARE GLI ORDINAMENTE QUANDO FILTRO PER ALTRI CAMPI SPEC
        if (filters && !!Object.keys(filters).length) {
            scrollToTop()
        }
    }, [filters]);
    //used for handle sorting in the front way with virtual list
    const setSorter = (key) => (event) => {
        const previousSorters = {...sorters};
        const keep = event.shiftKey;

        const nextSorters = keep ? {...previousSorters} : {[key]: previousSorters[key]};
        //old sorter logics
        if (!nextSorters[key]) nextSorters[key] = 'desc';
        else if (nextSorters[key] === 'desc') nextSorters[key] = 'asc';
        else delete nextSorters[key];

        return setSorters(nextSorters);
    };

    /**
     * Infinite scroll code and Effects
     */
    const loader = useRef(null);
    const tbody = useRef(null);
    const handleObserver = (entities) => {
        const target = entities[0];
        if (target.isIntersecting && !inLoading) {
            //console.log('intersection');
            onSetPageCb()
        }
    };
    useEffect(() => {
        let options = {
            root: tbody.current,
            rootMargin: "0px 0px 100px 0px",
            threshold: 0
        };
        // initialize IntersectionObserver
        const observer = new IntersectionObserver(handleObserver, options);
        if (loader.current) {
            observer.observe(loader.current)
        }
        return () => observer.disconnect()
    });

    /**
     * Grid row rendering and table dimensions code
     */
    const rowsVisible = Math.floor(availableHeight / rowMinHeight);
    const tbodyAvailableHeight = bodyMinHeight ? bodyMinHeight : (availableHeight - theadHeight); //inner section padding;
    const tbodyHeight = (data.length * rowMinHeight >= tbodyAvailableHeight ? tbodyAvailableHeight : data.length * rowMinHeight);
    const tbodyInnerHeight = (data.length * rowMinHeight);
    const hasScroll = tbodyInnerHeight > tbodyHeight;
    //console.log(tbodyInnerHeight,tbodyHeight)
    //console.log(`availableHeight=${availableHeight} tbodyAvailableHeight=${tbodyAvailableHeight} rowsVisible=${rowsVisible} rowsExisting=${data.length} => tbodyHeight=${tbodyHeight} hasScroll=${hasScroll}`);
    const classes = useStyles({
        rowMinHeight,
        theadHeight,
        backEndSortable,
        columnsWidths,
        scrollbarWidth,
        tbodyHeight,
        tbodyAvailableHeight,
        columns: columns.length,
        bodyMinHeight,
        isTablet,
        noHeightLimit
    });
    useEffect(() => {
        if (autoTbodyHeight) {
            const y = ref.current ? ref.current.getBoundingClientRect().y : 0;
            const marginBottom = ref.current ? parseInt(window.getComputedStyle(ref.current)['margin-bottom']) : 0;
            const availableHeight = window.innerHeight - y - marginBottom;
            // console.log('box', window.innerHeight, y, marginBottom)
            setAvailableHeight(availableHeight);
        }
    }, []);

    const renderRow = ({index, style}) => {
        // eslint-disable-line
        const item = data[index];
        const rowClassName = typeof classNames.row === 'function' ? classNames.row(item) : classNames.row;
        const rowBody = <div data-row key={index} className={cx(classes.rowInner, rowClassName)} style={style}>
            {map(columns, (column) => {
                const rowMetadata = {rowIndex: index, rowVirtualIndex: index % rowsVisible, rowsVisible};
                const value = columnsRenderers[column]
                    ? columnsRenderers[column](item[column], item, rowMetadata)
                    : item[column];
                const onClick = columnsClick[column]
                    ? columnsClick[column](item[column], item)
                    : Function.prototype;
                const title = item[column]
                const className = cx(
                    classes.td,
                    classNames.td,
                    columnsClick[column] && classes.columnClickable,
                    classes[columnsAlignments[column]],
                    columnsClasses[column] && columnsClasses[column](item[column], item)
                );
                const primitive = typeof value === 'string' || typeof value === 'number';

                return (
                    <div
                        key={column}
                        className={cx(classes.td, classNames.td, className)}
                        title={title}
                    >
                        {primitive ? <span>{value}</span> : value}
                    </div>
                );
            })}
        </div>;

        return (onRowClickPath ? <Link to={onRowClickPath(item)}
                                       className={cx(classes.rowContainer, onRowClickPath ? classes.rowClickable : null)}
                                       key={index}>
                {rowBody}
                {SubRowComponent && <div className={classes.rowSubComponent}>
                    <SubRowComponent data={item}/>
                </div>}
                {index === rowExpandedId && <div className={classes.expandedComponent}>
                    <ExpandedComponent data={item} callback={expandedComponentCb}/>
                </div>}
            </Link>
            :
            <div className={cx(classes.rowContainer)} key={index}>
                {rowBody}
                {SubRowComponent && <div className={classes.rowSubComponent}>
                    <SubRowComponent data={item}/>
                </div>}
                {index === rowExpandedId && <div className={classes.expandedComponent}>
                    <ExpandedComponent data={item} callback={expandedComponentCb}/>
                </div>}
            </div>);
    };

    return (
        <div id={id} className={cx(classes.table, className, classNames.table)} ref={ref}>
            <div className={cx(classes.thead, classNames.thead, classes.row, classNames.row)}>
                {map(columns, (column) => {
                    const isSortable =
                        headersSorters === true || (Array.isArray(headersSorters) && headersSorters.includes(column));
                    return (
                        <div
                            key={column}
                            className={cx(classes.th, classNames.th, classes[columnsAlignments[column]])}
                            onClick={isSortable ? setSorter(column) : Function.prototype}
                        >
                            {headersRenderers[column] || (
                                <span className={classes.columnsName}>{columnsNames[column] !== undefined ? columnsNames[column] : column}</span>
                            )}
                            {isSortable && (
                                <small>
                                    {isSortable && !sorters[column] && <CanOrderIcon data-color/>}
                                    {sorters[column] === 'desc' && <ArrowDown data-color/>}
                                    {sorters[column] === 'asc' && <ArrowUp data-color/>}
                                </small>
                            )}
                        </div>
                    );
                })}
            </div>
            <div className={cx(classes.tbody, classNames.tbody)}>
                <div ref={head}/>
                {data?.map((item, index) => renderRow({index}))}
                {inLoading && <TableLoader overlay={false}/>}
                {(hasScroll) && <div ref={loader}/>}

                {!(!!data.length) && !inLoading && (
                    CustomEmptyRow ?
                        <CustomEmptyRow title={'Nessun veicolo inserito'}
                                        subtitle={'Seleziona dalla tabella in alto i veicoli che vuoi inserire nella campagna'}/>
                        : <div className={classes.emptyRow}>
                            <span> Non ci sono risultati. </span>
                        </div>
                )}
            </div>
            {FooterComponent && <div className={classes.tableFooter}>
                <FooterComponent data={data}/>
            </div>}
        </div>
    );
};

Table.propTypes = {
    className: PropTypes.string,
    classNames: PropTypes.shape({
        table: PropTypes.string,
        thead: PropTypes.string,
        row: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        tbody: PropTypes.string,
        th: PropTypes.string,
        td: PropTypes.string
    }),

    id: PropTypes.string,
    data: PropTypes.array.isRequired,
    setData: PropTypes.func,
    overscan: PropTypes.number,
    rowMinHeight: PropTypes.number.isRequired, //minimum height
    theadHeight: PropTypes.number,
    //sorter
    backEndSortable: PropTypes.bool,
    customSorter: PropTypes.func,
    defaultSorters: PropTypes.object,
    filters: PropTypes.object,
    sortable: PropTypes.bool,

    columns: PropTypes.array.isRequired,
    columnsWidths: PropTypes.object.isRequired,
    columnsNames: PropTypes.object,
    columnsAlignments: PropTypes.object,
    columnsRenderers: PropTypes.object,
    columnsAlts: PropTypes.object,
    columnsClasses: PropTypes.object,
    columnsClick: PropTypes.object,

    headersRenderers: PropTypes.object,
    headersSorters: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
    onRowClick: PropTypes.func,
    onRowClickPath: PropTypes.func,
    scrollbarWidth: PropTypes.number,
    rowExpandedId: PropTypes.number,
    expandedComponent: PropTypes.any,
    subRowComponent: PropTypes.any,
    tableFooterComponent: PropTypes.any,
    inLoading: PropTypes.bool,
    onSetPageCb: PropTypes.func.isRequired,
    onSortingDataCb: PropTypes.func.isRequired,
    noHeightLimit: PropTypes.bool
};

export default Table;
