import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useParams} from 'react-router-dom';
import Spinner from '../../Commons/Spinner';
import CarHeading from "../../Components/Cars/CarHeading";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentCar} from "../../store/actions/carsActions";
import {httpGetCar} from "../../HttpRequests/commons.http.js";
import {errorHandler} from "../../helpers/utils.js";
import {
    httpCarAssignRepair,
    httpCarDiscardRepair,
    httpCarRestoreRepair,
    httpGetCarEstimates,
    httpGetCarRepairs
} from "../../HttpRequests/reconditioning.http.js";
import {AddIcon, AlertWarningIcon} from "../../assets/icons.js";
import RestoreSummaryFooter from "../../Components/Cars/RestoreSummaryFooter.js";
import {restoreDiscardedRepair, SetCarEstimates, SetCarRepairs} from "../../store/actions/repairsActions.js";
import EstimatesTable from "../../Components/Cars/EstimatesTable.js";
import RepairsToAssign from "../../Components/Cars/RepairsToAssign.js";
import {alertToggle} from "../../store/actions/alertActions.js";
import DiscardedRepairs from "../../Components/Cars/DiscardedRepairs.js";
import RestoreWorkerTable from "../../Components/Cars/RestoreWorkerTable.js";
import RestoreChangeDelivery from "../../Components/Cars/RestoreChangeDelivery.js";
import Button from "../../Commons/Button.js";
import Popover from "../../Commons/Popover.js";
import RestoreCreateModal from "../../Components/Cars/RestoreCreateModal.js";

const useStyles = createUseStyles(theme => ({
    gridContainer: {
        'position': 'relative',
        // with big/small subheader like permute page
        height: `calc(100vh - ${theme.carSubHeader}px)`,
        // without subheader
        //height: `calc(100vh - ${theme.headerHeight}px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        [theme.mUp]: {
            padding: [theme.spacing * 10, theme.spacing * 4, theme.spacing * 24]
        },
        [theme.s]: {
            padding: [theme.spacing],
        },
    },
    addRecondition: {
        margin: '0 0 24px auto'
    },
    addBtn: {
        'width': 180,
        'min-width': theme.spacing * 4,
        'height': 40,
        'border': 0,
        borderRadius: 12,
        'cursor': 'pointer',
        'font-size': 14,
        'font-weight': 400,
        'text-transform': 'uppercase',
        'align-items': 'center',
        'justify-content': 'center',
        'padding': [0, theme.spacing * 2],
        position: 'relative',
        transition: 'ease-in-out 300ms',
        background: theme.colors.black,
        color: theme.colors.white,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "16px 0 16px auto",
        textDecoration: 'none',
        '& svg': {
            width: 18,
            fill: theme.colors.white
        }
    },
    customEstimate:{
        display: "grid",
        alignItems: "center",
        gridTemplateColumns: '1fr auto',
        '& p':{
            display: "grid",
            alignItems: "center",
            gridTemplateColumns: 'auto 1fr',
            gridColumnGap: theme.spacing,
            '& svg':{
                fill: theme.colors.yellow,
                cursor: 'unset'
            },
            '& span':{
                fontSize: 13,
                color: theme.colors.yellow,
                fontWeight: 600
            },
        },
        '& button':{

        }
    }
}));

const CarRestore = () => {
    const {id} = useParams();
    const currentCar = useSelector(state => state.cars.currentCar);
    const estimates = useSelector(state => state.repairs.estimates);
    const classes = useStyles({bigSubHeader: false});
    const [loading, setLoading] = useState(false);
    const [firstEstimate, setFirstEstimate] = useState({
        total_amount: 0,
        updated_at: '',
        title: "Stato d'uso"
    });
    //repairs
    const toAssign = useSelector(state => state.repairs.toAssign);
    const discarded = useSelector(state => state.repairs.discarded);
    const tireCenter = useSelector(state => state.repairs.tireCenter);
    const bodyShop = useSelector(state => state.repairs.bodyShop);
    const workshop = useSelector(state => state.repairs.workshop);
    const dispatch = useDispatch();
    //permission
    const canAddEstimate = (currentCar?.status === 'in_stock' || currentCar?.status === 'booked');
    const [showPopover, setShowPopover] = useState(false);

    useEffect(() => {
        if (+currentCar?.id !== +id) {
            //if the requested car is different from the stored one
            dispatch(setCurrentCar(null));
            dispatch(SetCarRepairs([]));
            getCarData();
        }
        getCarEstimates();
        getCarRepairs();
        //Reset all
        return () => {
            dispatch(SetCarRepairs([]));
        };
    }, []);

    const getCarData = async () => {
        setLoading(true);
        try {
            const {data: car} = await httpGetCar(id);
            dispatch(setCurrentCar(car));
            const firstEstimate = {
                total_amount: car?.examination?.total_amount,
                updated_at: car?.examination?.updated_at,
                title: "Stato d'uso"
            };
            setFirstEstimate(firstEstimate);
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    };

    const getCarEstimates = async () => {
        setLoading(true);
        try {
            const {data: newEstimates} = await httpGetCarEstimates(id);
            dispatch(SetCarEstimates([...newEstimates]));
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    };

    const getCarRepairs = async () => {
        setLoading(true);
        try {
            const {data: repairs} = await httpGetCarRepairs(id);
            dispatch(SetCarRepairs(repairs));
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    };

    const assignOrDiscardRepair = async (actionType, repairs, selectedUserId = null) => {
        setLoading(true);
        /*console.log(actionType, repairs);
        console.log(actionType, repairs.map(item => item.id));*/
        try {
            const {data} = (actionType === 'discard') ? await httpCarDiscardRepair(id, repairs.map(item => item.id)) : await httpCarAssignRepair(id, repairs.map(item => item.id), selectedUserId);
            dispatch(alertToggle('Operazione completata con successo', 'success'));
            await getCarRepairs();
        } catch (e) {
            errorHandler(e, dispatch);
        } finally {
            setLoading(false)
        }
    };

    const restoreRepair = async (item) => {
        setLoading(true);
        try {
            await httpCarRestoreRepair(id, [item.id]);
            dispatch(restoreDiscardedRepair(item));
            dispatch(alertToggle('Riparazione ripristinata con successo', 'success'));
            //await getCarRepairs();
        } catch (e) {
            errorHandler(e, dispatch);
        } finally {
            setLoading(false)
        }
    };

    return (<>
        <CarHeading headingTitle={'Ricondizionamenti'} car={currentCar}/>

        {showPopover && <Popover withHeader={false} minWidth={700} onClose={() => setShowPopover(!showPopover)}
                                 contextMode={false}>
            <RestoreCreateModal onClose={() => setShowPopover(!showPopover)} getCarRepairsCb={getCarRepairs}/>
        </Popover>}
        {loading && <Spinner overlayFullscreen={true}/>}
        <section className={classes.gridContainer}>
            <RestoreChangeDelivery carId={id}/>
            <div style={{marginTop: 96}}>
                {canAddEstimate && <>
                    {currentCar?.dat_europa_code ?
                        <a href={process.env.REACT_APP_DAT_PREV_LINK} className={classes.addBtn}>
                            <AddIcon data-color/> Aggiungi perizia
                        </a> : <div className={classes.customEstimate}>
                            <p> <AlertWarningIcon data-color/>
                                <span>Dato che l’auto non è stata generata tramite il database DAT, la perizia dovrà essere generata manualmente.</span>
                            </p>
                            <Button data-primary className={classes.addBtn} icon={<AddIcon data-color/>}
                                    onClick={() => setShowPopover(!showPopover)}>
                                Aggiungi perizia
                            </Button>
                        </div>}
                </>}

                <EstimatesTable data={[firstEstimate, ...estimates]} setDataCallback={getCarEstimates}/>

                <RepairsToAssign repairsToAssign={toAssign} assignOrDiscardRepairCb={assignOrDiscardRepair}/>

                <DiscardedRepairs list={discarded} rollbackCb={restoreRepair}/>

                {!!workshop.length &&
                <RestoreWorkerTable data={workshop} setDataCallback={getCarRepairs} title={"Officina"}/>}

                {!!bodyShop.length &&
                <RestoreWorkerTable data={bodyShop} setDataCallback={getCarRepairs} title={"Carrozzeria"}/>}

                {!!tireCenter.length &&
                <RestoreWorkerTable data={tireCenter} setDataCallback={getCarRepairs} title={"Pneumatici"}/>}
            </div>

            <RestoreSummaryFooter firstEstimate={firstEstimate}/>
        </section>
    </>);
};


export default CarRestore
