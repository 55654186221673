import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {MoreVert} from '../assets/icons.js';
import Popover from './Popover.js';
import useDimensions from 'react-use-dimensions';

const useStyles = createUseStyles((theme) => ({
    root: {},
    shadow: {
        position: 'fixed',
        ...theme.shadow,
        overflow: 'hidden'
    },
    dropDownMenu: {
        display: 'grid'
    },
    danger: {
        color: theme.colors.red
    },
    menuItem: {
        fontSize: 14,
        transition: 'ease-in 300ms',
        textDecoration: 'none',
        padding: [theme.spacing, theme.spacing * 2],
        width: '100%',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.colors.lightGrey
        }
    },
    overlay: {
        // fullscreen
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: 'rgba(0,0,0,0.8)',
        display: 'grid',
        alignContent: 'center',
        justifyContent: 'center',
        zIndex: 10000
    },
    icon: {
        display: 'grid',
        alignItems: 'center',
        justifyItems: 'center',
        cursor: 'pointer',
    },
    customOpen:{
        display: 'grid',
        alignItems: 'center',
        justifyItems: 'start',
        cursor: 'pointer',
    }
}));

const ContextMenu = ({icon = <MoreVert/>, actions, id, type = 'normal', customOpenComponent,customTranslateToTopY = 0}) => {
    const [open, setOpen] = useState(false);
    const classes = useStyles({bigSubHeader: false});
    const [ref, {x}] = useDimensions();
    const [y, setY] = useState(0);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, true);
        setY(document.getElementById(id).getBoundingClientRect().y - customTranslateToTopY);
        return () => {
            window.removeEventListener('scroll', handleScroll, true);
        };
    }, []);

    const handleScroll = () => {
        setY(document.getElementById(id).getBoundingClientRect().y - customTranslateToTopY)
    };

    return <div className={classes.root} ref={ref} id={id}>
        {type === 'normal' ? <div className={classes.icon} onClick={() => setOpen(!open)}>
                {icon}
            </div> :
            <div className={classes.customOpen} onClick={() => setOpen(!open)}>
                {customOpenComponent}
            </div>}
        {open && <Popover onClose={() => setOpen(!open)} width={200} x={x - 200} y={y} className={classes.shadow}
                          withHeader={false} contextMode={true}>
            <div className={classes.dropDownMenu}>
                {actions.map((action, index) => action.to ? <Link to={action.to} key={index}
                                                                  className={cx(classes.menuItem, action.type === 'danger' ? classes.danger : '')}>
                    {action.text}
                </Link> : <span onClick={() => {
                    action.callback();
                    setOpen(false);
                }}
                                className={cx(classes.menuItem, action.type === 'danger' ? classes.danger : '')}
                                key={index}>
                        {action.text}
                    </span>)}
            </div>
        </Popover>}
    </div>;
};

export default ContextMenu;

ContextMenu.propTypes = {
    icon: PropTypes.any,
    actions: PropTypes.array,
    id: PropTypes.any.isRequired
};
