import {createSelector} from "reselect";
import {
    FOCUS_ON_CONVERSATION,
    RESET_CURRENT_CONVERSATION,
    SHOW_MEMBERS
} from "../../actions/chat/currentConversationActions";

const initialState = {
    currentConversationId: localStorage.getItem('lastOpenChat') || "",
    showMembers: false
};

const currentConversationStateReducer = (state = initialState, action) => {
    switch (action.type) {
        case FOCUS_ON_CONVERSATION:
            localStorage.setItem('lastOpenChat', action.payload)
            return { ...state, currentConversationId: action.payload };
        case RESET_CURRENT_CONVERSATION:
            return { ...state, currentConversationId: action.payload };
        case SHOW_MEMBERS:
            return { ...state, showMembers: action.payload };
        default:
            return state;
    }
};

export { currentConversationStateReducer };


const getCurrentConversationSlice = state => state.chats.currentConversation;

export const getCurrentConversationId = createSelector(
    [getCurrentConversationSlice],
    currentConversation => {
        return currentConversation.currentConversationId;
    }
);

