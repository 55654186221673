import React from 'react';
import { ResponsivePie } from '@nivo/pie';
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.

const PieChart = ({data}) => (
    <ResponsivePie
        theme={{ legends: { text: { fill: '#fff' } } }}
        data={data}
        margin={{ top: 8, right: 48, bottom: 16, left: 0 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        colors={{ scheme: 'greys' }}
        borderWidth={1}
        borderColor={{ theme: 'grid.line.stroke' }}
        enableSliceLabels={true}
        enableRadialLabels={false}
        tooltip={(input) => {
            return <div style={{color: '#000', background:'#fff', padding:'6px 2px', fontSize: 13, fontWeight: 600, borderRadius: 4}}>
                {input?.datum?.label} : {input?.datum?.value}
            </div>
            }}
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: '#ccc',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: '#ddd',
                rotation: -45,
                lineWidth: 2,
                spacing: 6
            }
        ]}
        fill={[
            {
                match: {
                    label: 'b2b'
                },
                id: 'dots'
            },
            {
                match: {
                    label: 'b2c'
                },
                id: 'lines'
            },
        ]}
        legends={[
            {
                anchor: 'right',
                direction: 'column',
                justify: true,
                translateX: 0,
                translateY: 0,
                itemsSpacing: 16,
                itemWidth: 64,
                itemHeight: 18,
                itemTextColor: 'rgb(255 255 255)!important',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 16,
                symbolShape: 'circle',
            }
        ]}
    />
)

export default PieChart