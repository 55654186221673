import React, {useState} from 'react';
import SearchStep from "../Barters/SearchStep.js";
import EurotaxStep from "../Barters/EurotaxStep.js";
import IndicataStep from "../Barters/IndicataStep.js";
import NotFoundMultiStep from "../Barters/NotFoundMultiStep.js";
import {useHistory} from 'react-router-dom';
import ManualInsertStep from "../Barters/ManualInsertStep.js";
import AlreadySet from "../Barters/AlreadySet.js";

const NewVehicleStepsModal = ({onClose}) => {

    const history = useHistory();
    const [step, setStep] = useState('search');
    const [carId, setCarsId] = useState(null);
    const [plate, setPlate] = useState(null);
    const [km,setKm] = useState(null);
    const [alreadySetMessage, setAlreadySetMessage] = useState('');

    const renderStep = () => {
        switch (step) {
            case 'successEurotax':
                return <EurotaxStep carId={carId} onVersionChoiceCb={() => setStep('successIndicata')}/>;
            case 'successIndicata':
                return <IndicataStep carId={carId} onVersionChoiceCb={() => history.push(`/stock/${carId}/new`)}/>;
            case 'manualInsert':
                return <ManualInsertStep setStep={setStep} plate={plate} mileage={km} onClose={onClose} isNewVehicle={true}/>;
            case 'datStep':
                return <NotFoundMultiStep setStep={setStep} setCarsId={setCarsId} plate={plate} mileage={km} isNewVehicle={true}/>;
            case 'alreadySet':
                return <AlreadySet onClose={onClose} plate={plate} km={km} message={alreadySetMessage} isNewVehicle={true}/>;
            default:
                return <SearchStep setStep={setStep} setCarsId={setCarsId} setKm={setKm} setAlreadySetMessage={setAlreadySetMessage} setPlate={setPlate} isNewVehicle={true}/>
        }
    };

    return renderStep()
};

export default NewVehicleStepsModal;