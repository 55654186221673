import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

const useStyles = createUseStyles((theme) => ({
	root: {
		color: theme.colors.black,
		width: ({ width }) => width,
		'min-width': theme.spacing * 10,
		border: 0,
		cursor: 'pointer',
		fontWeight: 'bold',
		display: 'grid',
		alignItems: 'center',
		justifyContent: 'center',
		padding: [ 0, theme.spacing * 2 ],
		position: 'relative',
		transition: 'ease-in-out 300ms',
		background: theme.colors.white,
		height: 32,
		'font-size': 12,
        borderRadius: 8,
        '& span': {
            display: 'none'
        }
	},
	active: {
        background: `${theme.colors.lightGrey} !important`,
        '& span': {
            display: 'block'
        }
    },
    dot: {
        position:'absolute',
        top:-6,
        right:-6,
        width:12,
        height:12,
        borderRadius:50,
        background: theme.colors.red
    }
}));

const TabButton = ({ className, children, width = 'auto', active, notification = false, ...props }) => {
	const classes = useStyles({ width });

	return <button className={cx(classes.root, className, active && classes.active)} {...props} type={'button'}>
                    {children}
                    { notification && <span className={classes.dot}></span> }
            </button>
};

export default TabButton;
