import * as actionTypes from '../actions/repairsActions.js';

const initialState = {
    estimates: [],
    toAssign: [],
    discarded: [],
    tireCenter: [],
    bodyShop: [],
    workshop: [],
    allRepairsTotalAmount: 0,
    allRepairsNegotiatedAmount: 0,
    allRepairsInvoiceAmount: 0
};

const retrieveByType = (repairs, type) => {
    if (!repairs) return;
    let allRepairs = [];
    repairs.filter((repair) => repair.type === type).map((item) => allRepairs.push(...item.repair_rows));
    return allRepairs;
};

const addAttachmentToEstimate = (estimates, newAttachment) => {
    return estimates.map(estimate => {
        if (estimate.id === newAttachment.estimate_id) {
            if (!!estimate.attachments.length) {
                estimate.attachments.map((attachment, index) => {
                    if (attachment.id === newAttachment.id) {
                        estimate.attachments[index] = {...newAttachment}
                    } else {
                        estimate.attachments = [newAttachment, ...estimate.attachments]
                    }
                    return attachment
                })
            } else {
                estimate.attachments = [newAttachment]
            }
        }
        return estimate
    });
};

const addAttachmentToRepair = (repairs, newAttachment) => {
    return repairs.map(repair => {
        if (repair.id === newAttachment.repair_id) {
            if(!newAttachment.repair_row_id){
                if (!!repair.attachments.length) {
                    repair.attachments.map((attachment, index) => {
                        if (attachment.id === newAttachment.id) {
                            repair.attachments[index] = {...newAttachment}
                        } else {
                            repair.attachments = [newAttachment, ...repair.attachments]
                        }
                        return attachment
                    })
                } else {
                    repair.attachments = [newAttachment]
                }
            }else{
                repair.repair_rows.map((repair_row) => {
                    if (repair_row.id === newAttachment.repair_row_id) {
                        if (!!repair.attachments.length) {
                            repair.attachments.map((attachment, index) => {
                                if (attachment.id === newAttachment.id) {
                                    repair.attachments[index] = {...newAttachment}
                                } else {
                                    repair.attachments = [newAttachment, ...repair.attachments]
                                }
                                return attachment
                            })
                        } else {
                            repair.attachments = [newAttachment]
                        }
                        return repair_row
                    }
                });
            }
        }
        return repair
    });
};

const addUnexpectedRepairRow = (repairs, unexpectedRow) => {
    return repairs.map(repair => {
        if (repair.id === unexpectedRow.repair_id) {
            repair.repair_rows = [...repair.repair_rows, unexpectedRow]
        }
        return repair;
    })
};

const recalculateTotals = (repairs) => (
    {
        allRepairsNegotiatedAmount: repairs.reduce((accum, item) => accum + item.negotiated_amount, 0),
        allRepairsTotalAmount: repairs.reduce((accum, item) => accum + item.total_amount, 0),
        allRepairsInvoiceAmount: repairs.reduce((accum, item) => accum + item.invoice_amount, 0)
    });

const repairsReducer = (state = initialState, action) => {
    let allRepairs = [...state.tireCenter,...state.bodyShop,...state.workshop];
    switch (action.type) {
        case actionTypes.SET_REPAIRS:
            return {
                ...state,
                toAssign: retrieveByType(action.repairs, 'to_assign'),
                discarded: retrieveByType(action.repairs, 'discarded'),
                tireCenter: action.repairs.filter((repair) => repair.type === 'tire_center'),
                bodyShop: action.repairs.filter((repair) => repair.type === 'body_shop'),
                workshop: action.repairs.filter((repair) => repair.type === 'workshop'),
                ...recalculateTotals([...action.repairs.filter((repair) => repair.type === 'tire_center'),
                    ...action.repairs.filter((repair) => repair.type === 'body_shop'),
                    ...action.repairs.filter((repair) => repair.type === 'workshop')
                    ]),
            };
        case actionTypes.SET_REPAIR_ATTACHMENT:
            /*console.log(allRepairs)
            allRepairs = addAttachmentToRepair(allRepairs, action.attachment);
            console.log(allRepairs)*/
            return {
                ...state,
                tireCenter: addAttachmentToRepair(state.tireCenter, action.attachment),
                bodyShop: addAttachmentToRepair(state.bodyShop, action.attachment),
                workshop: addAttachmentToRepair(state.workshop, action.attachment),
            };
        case actionTypes.SET_ESTIMATES:
            return {
                ...state,
                estimates: action.estimates,
            };
        case actionTypes.SET_ESTIMATES_ATTACHMENT:
            return {
                ...state,
                estimates: addAttachmentToEstimate(state.estimates, action.attachment)
            };
        case actionTypes.RESTORE_DISCARDED_REPAIR:
            return {
                ...state,
                toAssign: [...state.toAssign, action.repair],
                discarded: state.discarded.filter(item => item.id !== action.repair.id)
            };
        case actionTypes.ADD_UNEXPECTED_REPAIR:
            return {
                ...state,
                [action.repairType]: addUnexpectedRepairRow(state[action.repairType], action.unexpectedRow)
            };
        case actionTypes.UPDATE_REPAIR:
            allRepairs = allRepairs.map(item => item.id === action.repair.id ? action.repair : item)
            return {
                ...state,
                tireCenter: allRepairs.filter((repair) => repair.type === 'tire_center'),
                bodyShop: allRepairs.filter((repair) => repair.type === 'body_shop'),
                workshop: allRepairs.filter((repair) => repair.type === 'workshop'),
                ...recalculateTotals(allRepairs)
               /* [action.repairType]: state[action.repairType].map(item => item.id === action.repair.id ? action.repair : item),*/
            };
        case actionTypes.DELETE_REPAIR:
            return {
                ...state,
                toAssign: [...state.toAssign, ...action.repair?.repair_rows],
                [action.repairType]: state[action.repairType].filter(item => item.id !== action.repair.id),
                ...recalculateTotals([...state.tireCenter,...state.bodyShop,...state.workshop].filter(item => item.id !== action.repair.id)),
            };
        default:
            return state;
    }
};

export default repairsReducer;
