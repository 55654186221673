import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useDispatch, useSelector} from "react-redux";
import {Pointer} from "../assets/icons.js";
import Button from "./Button.js";
import {httpGetAllCarLocations, httpGetCarUbicationLocations, httpSetQuickShifts} from "../HttpRequests/shifts.http.js";
import {errorHandler} from "../helpers/utils.js";
import Spinner from "./Spinner.js";
import Select from "react-select";
import theme from '../theme';
import {useParams} from "react-router-dom";
import {alertToggle} from "../store/actions/alertActions.js";
import {setCurrentCar} from "../store/actions/carsActions.js";

const useStyles = createUseStyles((theme) => ({
    root: {
        maxHeight: 130,
        display: "grid",
        alignItems: "start",
        gridTemplateRows: '1fr auto',
        borderRadius: 20,
        padding: [theme.spacing * 2, theme.spacing * 2, theme.spacing],
        background: theme.colors.white,
        position: 'relative',
        ...theme.shadow,
    },
    positionAction: {
        display: 'grid',
        justifySelf: 'end',
    },
    mainInfos: {
        display: 'grid',
        alignItems: "start",
        '& b': {
            lineHeight: `14px`
        },
        '& label': theme.control.label
    },
    positionStyles: {
        display: 'grid',
        gridTemplateColumns: '16px 1fr',
        gridColumnGap: theme.spacing + 4,
    },
    icon: {
        width: `16px!important`,
        fill: theme.colors.black,
    },
}));

const CarCardPositionInfo = ({car}) => {
    const {id} = useParams(); //get id of car from url params react-router-dom
    const [inEdit, setInEdit] = useState(false);
    const [locations, setLocations] = useState([]);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isMoving, setIsMoving] = useState(false);
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        if (car?.id) {
            setIsMoving(car?.is_moving);
        }
    }, [car]);

    const getCarLocations = async (UbicationId) => {
        setLoading(true)
        try {
            const {data} = (car?.location) ? await httpGetCarUbicationLocations(UbicationId) : await httpGetAllCarLocations();
            setLocations(data.map(location => ({label: location.name, value: location.id})));
            setCurrentLocation(car?.location_id);
            setIsMoving(car?.is_moving);
        } catch (e) {
            errorHandler(e, dispatch);
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (inEdit) {
            getCarLocations(car?.location?.ubication?.id)
        }
    }, [inEdit]);

    const onChangePosition = async (location) => {
        setLoading(true)
        try {
            await httpSetQuickShifts(id, location);
            setCurrentLocation(location.value)
            setInEdit(!inEdit);
            dispatch(setCurrentCar({...car,location:{...car?.location, name: location?.label.split('-')[0], id:location?.value}}))
            dispatch(alertToggle('Spostamento creato con successo.', 'success'));
        } catch (e) {
            errorHandler(e, dispatch);
        } finally {
            setLoading(false)
        }
    }

    return <div className={classes.root}>
        {loading && <Spinner size={24}/>}
        <div className={classes.positionStyles}>
            <Pointer className={classes.icon}/>
            <div className={classes.mainInfos}>
                <b><small>Posizione attuale vettura</small></b>
                {inEdit && <>
                    <label>{car?.location?.ubication?.name}</label>
                    <Select options={locations} styles={theme.reactSelectsStyleXs} isSearchable={true}
                            onChange={(item) => {
                                onChangePosition(item)
                            }}
                            value={locations.filter(obj => {
                                return obj.value === currentLocation
                            })}
                    />
                </>}
                {(!isMoving && !inEdit) &&
                <p style={{fontSize: 12}}><b>{car?.location?.ubication?.name}</b> - {car?.location?.name}</p>}
                {(isMoving && !inEdit) && <p style={{fontSize: 12}}>
                    <b>{car?.location?.ubication?.name} - <span style={{color: theme.colors.yellow}}>
                        In spostamento
                    </span>
                    </b>
                </p>}
            </div>
        </div>
        <div className={classes.positionAction}>
            <Button data-secondary data-xs-small width={160}
                    onClick={() => setInEdit(!inEdit)}> {inEdit ? 'Annulla' : 'Modifica posizione'} </Button>
        </div>
    </div>
};

export default CarCardPositionInfo;