import React, {useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import Spinner from "../../Commons/Spinner";
import {alertToggle} from "../../store/actions/alertActions.js";
import {httpEditCarInfo} from "../../HttpRequests/barters.http.js";
import {clearDamagesForBackend, errorHandler,} from "../../helpers/utils.js";
import Radio from "../../Commons/Radio.js";
import {setCurrentBarter} from "../../store/actions/bartersActions.js";

const useStyles = createUseStyles(theme => ({
    root: {
        position: 'relative',
        'display': 'grid',
        'grid-auto-flow': 'row',
        'grid-row-gap': theme.spacing * 3,
        'grid-column-gap': theme.spacing * 5,
        [theme.s]: {
            width: 320,
            gridTemplateColumns: '1fr',
            justifyContent: "center",
        },
        [theme.mUp]: {
            'width': '100%',
            gridTemplateColumns: '1fr',
            justifyContent: "center",
        },
    },

    sectionTitle: {
        fontWeight: 600,
        textAlign: "left",
        fontSize: 16,
        padding: [theme.spacing * 2, 0, 0],
        borderTop: `1px solid ${theme.colors.lightGrey}`
    },

    label:{
        ...theme.control.label,
        gridColumnStart: '1',
        gridColumnEnd: '3',
        fontSize: 16
    },
    radioGroupContainer:{
        display: "grid",
        gridTemplateColumns: "200px 200px 200px 200px 200px",
        alignItems: "center",
    },
    radioGroup:{
        display: "grid",
        gridTemplateColumns: "60px 60px",
        alignItems: "center",
        gridRowGap: theme.spacing
    }
}));

//car obj in sycn with bartersStore
const OtherInfoForm = ({car}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const damages = useSelector(state => state.barters.damages);
    const {register, watch} = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            ...car,
            //default behavior for react hook form
            used_by_smoker: car?.used_by_smoker?.toString(),
            used_by_animal_owner: car?.used_by_animal_owner?.toString(),
            second_key: car?.second_key?.toString(),
            sd_card: car?.sd_card?.toString(),
            polished: car?.polished?.toString()
        },
    });

    const editCarInfo = async (fieldName, value, label) => {
        if ((value === null || value === '') || car[fieldName]?.toString() === value?.toString()) return;
        const dataToSend = {
            car:{
                id:car.id,
                [fieldName]: !!value,
                is_draft: true
            },
            damages: clearDamagesForBackend(damages),
        };
        try {
            const {data} = await httpEditCarInfo(dataToSend);
            dispatch(setCurrentBarter(data));
            dispatch(alertToggle(`Il campo ${label} è stato modificato correttamente.`, 'success'))
        } catch (e) {
            errorHandler(e,dispatch)
        } finally {
            setIsLoading(false);
        }
    };

    return <form className={classes.root} /*onSubmit={handleSubmit(onSubmit)}*/>
        {isLoading && <Spinner/>}
        <div className={classes.sectionTitle} style={{padding:0}}/>
        <div className={classes.radioGroupContainer}>
            <div className={classes.radioGroup}>
                <label className={classes.label}>Fumatore</label>
                <Radio ref={register} name={'used_by_smoker'} value={true} labelOn={'right'} isSmall={true} label={'si'} onChange={() => editCarInfo('used_by_smoker', watch('used_by_smoker'), 'Fumatore')}/>
                <Radio ref={register} name={'used_by_smoker'} value={false} labelOn={'right'} isSmall={true} label={'no'} onChange={() => editCarInfo('used_by_smoker', watch('used_by_smoker'), 'Fumatore')}/>
            </div>
            <div className={classes.radioGroup}>
                <label className={classes.label}>Animali</label>
                <Radio ref={register} name={'used_by_animal_owner'} value={true} labelOn={'right'} isSmall={true} label={'si'} onChange={() => editCarInfo('used_by_animal_owner', watch('used_by_animal_owner'), 'Animali')}/>
                <Radio ref={register} name={'used_by_animal_owner'} value={false} labelOn={'right'} isSmall={true} label={'no'} onChange={() => editCarInfo('used_by_animal_owner', watch('used_by_animal_owner'), 'Animali')}/>
            </div>
            <div className={classes.radioGroup}>
                <label className={classes.label}>Seconda chiave</label>
                <Radio ref={register} name={'second_key'} value={true} labelOn={'right'} isSmall={true} label={'si'} onChange={() => editCarInfo('second_key', watch('second_key'), 'Seconda chiave')}/>
                <Radio ref={register} name={'second_key'} value={false} labelOn={'right'} isSmall={true} label={'no'} onChange={() => editCarInfo('second_key', watch('second_key'), 'Seconda chiave')}/>
            </div>
            <div className={classes.radioGroup}>
                <label className={classes.label}>Scheda sd</label>
                <Radio ref={register} name={'sd_card'} value={true} labelOn={'right'} isSmall={true} label={'si'} onChange={() => editCarInfo('sd_card', watch('sd_card'), 'Secheda sd')}/>
                <Radio ref={register} name={'sd_card'} value={false} labelOn={'right'} isSmall={true} label={'no'} onChange={() => editCarInfo('sd_card', watch('sd_card'), 'Scheda sd')}/>
            </div>
            <div className={classes.radioGroup}>
                <label className={classes.label}>Lucidatura</label>
                <Radio ref={register} name={'polished'} value={true} labelOn={'right'} isSmall={true} label={'si'} onChange={() => editCarInfo('polished', watch('polished'), 'Lucidatura')}/>
                <Radio ref={register} name={'polished'} value={false} labelOn={'right'} isSmall={true} label={'no'} onChange={() => editCarInfo('polished', watch('polished'), 'Lucidatura')}/>
            </div>
        </div>
    </form>
};

export default OtherInfoForm;
