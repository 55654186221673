import React, {useState} from 'react';
import {createUseStyles} from "react-jss";
import {useForm} from "react-hook-form";
import Button from "../../Commons/Button.js";
import * as Yup from "yup";
import {useDispatch} from "react-redux";
import {httpCarEditRepair} from "../../HttpRequests/reconditioning.http.js";
import {updateRepair} from "../../store/actions/repairsActions.js";
import {errorHandler, repairTypeResolver} from "../../helpers/utils.js";
import {alertToggle} from "../../store/actions/alertActions.js";
import Textarea from "../../Commons/Textarea.js";
import Spinner from "../../Commons/Spinner.js";

const useStyles = createUseStyles(theme => ({
    root: {
        display: 'grid',
        width: '100%',
        gridRowGap: theme.spacing * 3,
        justifyItems: 'center',
        '& button':{
            marginTop: theme.spacing * 3
        }
    },
    title:{
        fontWeight: 600,
        margin: [theme.spacing * 2, 0]
    },
    btns: {
        width: "300px",
        display: "grid",
        alignItems: "center",
        gridColumnGap: theme.spacing,
        gridTemplateColumns: "1fr 1fr"
    }
}));

const validationSchema = Yup.object().shape({
    note: Yup.string().required(`Inserisci la nota.`),
});

const AddRepairNoteModal = ({onCloseCb,repair, carId}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const {register, handleSubmit, formState,reset, errors} = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            note: repair.note,
        },
        validationSchema
    });

    const onSubmit = async (values) => {
        setLoading(true);
        try{
            const {data: newRepair} = await httpCarEditRepair(carId,{...repair,...values});
            dispatch(updateRepair(newRepair, repairTypeResolver(newRepair.type)));
            dispatch(alertToggle( 'Nota correttamente aggiunta', 'success'));
            onCloseCb();
        }catch (e) {
            errorHandler(e,dispatch);
        }finally {
            setLoading(false)
        }
    };

    return <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        {loading && <Spinner/>}
        <Textarea type={'text'} label={'nota*'} name={'note'} ref={register} errors={errors.note}/>
        <div className={classes.btns}>
            <Button type="reset" onClick={onCloseCb} data-secondary data-small>Annulla</Button>
            <Button type={'submit'} data-primary data-small disabled={!formState.isValid || formState.isSubmitting}>Salva</Button>
        </div>
    </form>
};

export default AddRepairNoteModal;