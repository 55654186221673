import React from 'react';
import {createUseStyles} from "react-jss";
import Avatar from "../../Commons/Avatar.js";
import {formattedDate, formattedDateHours, toMoney} from "../../helpers/utils.js";

const useStyles = createUseStyles(theme => ({
    root: {
        display: 'grid',
        width: '100%',
        justifyItems: 'center'
    },
    listContainer: {
        maxHeight: 550,
        marginBottom: theme.spacing * 2,
        scrollX: "hidden",
        scrollY: "scroll",
        width: "100%",
        overflow: "scroll",
    },
    heading: {
        display: 'grid',
        width: '100%',
        gridTemplateColumns: ({historyType}) => historyType === 'actions' ? '0.6fr 1fr 1fr' : '0.5fr 0.6fr 0.5fr 1fr 1fr',
        fontWeight: 600,
        padding: '8px 16px',
        gridColumnGap: theme.spacing,
    },
    rows:{
        display: 'grid',
        alignItems: 'center',
        gridColumnGap: theme.spacing,
        padding: '8px 16px',
        fontSize: 14,
        borderTop: `1px solid ${theme.colors.lightGrey}`,
        gridTemplateColumns: ({historyType}) => historyType === 'actions' ? '0.6fr 1fr 1fr' : '0.5fr 0.7fr 0.5fr 1fr 1fr',
    },
    notes: {
        display: 'grid',
        gridColumn: '1/5',
        fontSize: 12
    },
    title: {
        fontWeight: 600,
        margin: [theme.spacing * 2, 0],
        display: 'grid',
        alignItems: 'center',
        gridColumnGap: theme.spacing,
        gridTemplateColumns: '1fr auto',
        '& img': {
            width: 72
        }
    },
    doubleRow:{
        ...theme.grid.doubleRow,
    }
}));

const BarterHistoryLogs = ({historyType,logs}) => {
    const classes = useStyles({historyType});

    return <div className={classes.root}>
        <h4 className={classes.title}> {historyType === 'actions' ? 'Storico azioni' : 'Storico'} </h4>
        <div className={classes.heading}>
            <span> Data </span>
            <span> Operatore </span>
            {historyType === 'actions' && <span> Azione </span>}
            {historyType === 'proposals' && <span> km </span>}
            {historyType === 'proposals' && <span> Ritiro proposto </span>}
            {historyType === 'proposals' && <span> Controproposta </span>}
        </div>
        <div className={classes.listContainer}>
            {logs.map((log,index) => <div key={index} className={classes.rows}>
                <div className={classes.doubleRow}>
                    <span> {formattedDate(log?.created_at)}</span>
                    <small>{formattedDateHours(log.created_at)}</small>
                </div>
                <span>  <Avatar size={'small'} img={log?.user.photo_link} fullName={log?.user.full_name}/> </span>
                {historyType === 'actions' && <span> {log.note} </span>}
                {historyType === 'proposals' && <span> {log.mileage}  </span>}
                {historyType === 'proposals' && <span>  {toMoney(log.price)} </span>}
                {historyType === 'proposals' && <span> {log?.counteroffer ? toMoney(log.counteroffer) : '-'} </span>}
                {historyType === 'proposals' && <span className={classes.notes}> {log.notes} </span>}
            </div>)}
        </div>
    </div>
};

export default BarterHistoryLogs;
