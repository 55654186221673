import React, {useState} from 'react';
import {createUseStyles} from "react-jss";
import Spinner from "../../Commons/Spinner.js";
import Button from "../../Commons/Button.js";
import {useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import Select from "../../Commons/Select.js";
import {formattedDateHoursIso, shiftsReasons, shiftsTypes,} from "../../helpers/utils.js";
import * as Yup from "yup";
import DayPickerInput from "../../Commons/DayPickerInput.js";
import Textarea from "../../Commons/Textarea.js";
import Radio from "../../Commons/Radio.js";
import Text from "../../Commons/Text.js";
import qs from "query-string";
import {useHistory} from 'react-router-dom';

const useStyles = createUseStyles(theme => ({
    root: {
        display: 'grid',
        width: '100%',
        gridRowGap: theme.spacing * 3,
        justifyItems: 'center',
    },
    title: {
        fontWeight: 600,
        margin: [0,theme.spacing * 2,theme.spacing * 2, 0],
        display: 'grid',
        alignItems: 'center',
        gridColumnGap: theme.spacing,
        gridTemplateColumns: '1fr auto',
        '& img': {
            width: 72
        }
    },
    body: {
        display: 'grid',
        gridTemplateColumns: "1fr 1fr",
        gridRowGap: theme.spacing * 2,
        gridColumnGap: theme.spacing * 4,
        padding: [0, theme.spacing * 2],
        width: '100%',
        maxWidth: 450,
    },
    full: {gridColumn: '1 / span 2'},
    btns: {
        display: 'grid',
        gridColumnStart: 1,
        gridColumnEnd: 3,
        justifyItems: 'center',
        marginTop: 32,
        gridRowGap: 8,
        '& small': {},
        '& p': {
            textDecoration: 'underline',
            cursor: 'pointer'
        }
    }
}));

const validationSchema = Yup.object().shape({
    plate: Yup.string().nullable()
        .required(`Inserisci almeno una targa.`),
    location_id_to: Yup.string().nullable()
        .required(`Inserisci la posizione di partenza.`),
    location_id_from: Yup.string().nullable()
        .required(`Inserisci la destinazione di arrivo.`),
    transfer_date: Yup.string().nullable()
        .required(`Inserisci una data.`),
    reason: Yup.string().nullable()
        .required(`Inserisci la causale.`),
    type: Yup.string().nullable()
        .required(`Inserisci il tipo dello spostamento.`),
    user_id: Yup
        .string().nullable()
        .when("vector", {
            is: 'internal',
            then: Yup.string().required("Inserisci l'autista")
        })
});

const ShiftPlanningModal = ({}) => {
    const history = useHistory();
    const classes = useStyles();
   /* const [isLoading, setIsLoading] = useState(false);*/
    const allLocations = useSelector(state => state.autocompletes.locations);
    const usersAutocomplete = useSelector(state => state.autocompletes.usersAutocomplete);
    const {register, handleSubmit, formState: {isSubmitted, isValid}, control, errors, watch} =
        useForm({
            mode: 'onChange',
            reValidateMode: 'onChange',
            nativeValidation: false,
            defaultValues: {
                vector:'internal'
            },
            validationSchema
        });

    const onSubmit = (values) => {
        const params = {
            ...values,
            reason: watch('reason')?.value,
            type:watch('type')?.value,
            location_id_to: watch('location_id_to')?.value,
            location_id_from: watch('location_id_from')?.value,
            transfer_date: values?.transfer_date ? formattedDateHoursIso(values.transfer_date) : null,
            preferred_date: values?.preferred_date ? formattedDateHoursIso(values.preferred_date) : null,
            user_id:  watch('user_id')?.value,
        };

        history.push({
            pathname: '/list/shift-requests/planning', search: qs.stringify({...params,plate:null}, {
                arrayFormat: 'comma',
                skipEmptyString: true,
                skipNull: true
            }),
        });
    };

    return <div className={classes.root}>
        {/*{isLoading && <Spinner/>}*/}
        <h4 className={classes.title}>
            Impostazioni trasporto
        </h4>

        <form className={classes.body} onSubmit={handleSubmit(onSubmit)}>
            <DayPickerInput label={'Data*'} name={'transfer_date'} inForm={true} ref={register} className={classes.full}
                            control={control} errors={errors.transfer_date} showYearDropdown={true}
                            closeOnSelect={true}
            />
            <Select name={'location_id_from'} options={allLocations} ref={register} control={control} disabled={false}
                    label={`Posizione di partenza*`} isClearable={false} errors={errors.location_id_from}
                    className={classes.full}/>
            <Select name={'location_id_to'} options={allLocations} ref={register} control={control} disabled={false}
                    label={`Posizione di arrivo*`} isClearable={false} errors={errors.location_id_to}
                    className={classes.full}/>
            <Select name={'type'} options={shiftsTypes} ref={register} control={control} label={`Tipo spostamento*`}
                    errors={errors.type}/>
            <Select name={'reason'} options={shiftsReasons} ref={register} control={control} label={`Causale*`}
                    errors={errors.reason}/>

            <Radio ref={register} name={'vector'} value={'internal'} labelOn={'right'} isSmall={true}
                   label={'Bisarca interna'}/>
            <Radio ref={register} name={'vector'} value={'external'} labelOn={'right'} isSmall={true}
                   label={'Bisarca esterna'}/>

            <Text name={'plate'} type={'text'} ref={register} label={`Targa*`} disabled={false}
                  errors={errors.plate} placeholder={'Scrivi qui la targa'}/>
            {watch('vector') === 'internal' && <Select name={'user_id'} options={usersAutocomplete} ref={register} control={control} disabled={false}
                    label={`Autista*`} isClearable={false} errors={errors.user_id}/>}
            <Textarea rows={"4"} label={'Note'} name={'notes'} ref={register} className={classes.full}/>

            <div className={classes.btns}>
                <Button type={'submit'} width={250} data-primary data-small
                        disabled={isSubmitted || (!isValid)}> Avanti </Button>
            </div>
        </form>
    </div>
};

export default ShiftPlanningModal;