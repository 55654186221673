import {createUseStyles} from "react-jss";
import React, {useState} from "react";
import {MoreVert, PdfIcon} from "../../assets/icons.js";

const useStyles = createUseStyles(theme => ({
    root: {
        position: "relative",
        display: "grid",
        gridTemplateColumns: 'auto 1fr auto',
        gridColumnGap: theme.spacing,
        padding: theme.spacing,
        background: `linear-gradient(180deg, #fff 0%, #d6d6d6 100%)`,
        maxWidth: 148,
        borderRadius: 16,
        alignItems: 'center',
        ...theme.shadow,
        '& svg': {
            fontSize: 12,
            width: '16px!important',
        },
        '& p': {
            fontSize: 14,
        },
        '& ul':{
            position: "absolute",
            top: "-4px",
            right: "-74px",
            padding: "4px 8px",
            borderRadius: 4,
            background: theme.colors.white,
            display: "grid",
            gridRowGap: theme.spacing,
            fontSize: "12px",
            zIndex: 1,
            width: 80,
            '& li':{
                fontWeight: 500,
                cursor: 'pointer'
            }
        }
    },
    actions:{
        background: theme.colors.lightGrey,
        width:16,
        height: 16,
        display: 'grid',
        alignItems: 'center',
        cursor: 'pointer',
        borderRadius: '50%'
    }
}));

export const ShiftAttachment = ({data}) => {
    const classes = useStyles();
    const [actionsToggle, setActionsToggle] = useState(false);
    const handlePrint = (event,file) => {
        event.preventDefault();
        window.open(file, "PRINT", "height=768,width=1366");
        setActionsToggle(false);
    };

    return <div className={classes.root}>
        <PdfIcon/>
        <p> Bolla {data?.id} </p>
        <span className={classes.actions} onClick={() => setActionsToggle(!actionsToggle)}><MoreVert data-color/></span>
        {actionsToggle && <ul>
            <li onClick={() => setActionsToggle(false)}><a style={{textDecoration: 'none'}} href={`${data?.link}`} target="_blank" download>Scarica</a></li>
            <li onClick={(e) => handlePrint(e,data?.link)}>Stampa</li>
        </ul>}
    </div>
};

export default ShiftAttachment;