import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import Table from "../../Commons/Table.js";
import {
    allShiftsTypes,
    clearFilters,
    errorHandler,
    formattedDate,
    formattedDateHours,
    retrieveValueForRs,
    shiftsReasons,
} from "../../helpers/utils.js";
import SubHeading from "../../Commons/SubHeading.js";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from 'react-router-dom';
import SimpleSearch from "../../Commons/SimpleSearch.js";
import qs from "query-string";
import {useWindowWidth} from "@react-hook/window-size";
import {setShiftRequestsList} from "../../store/actions/shiftsActions.js";
import Button from "../../Commons/Button.js";
import {httpConfirmShiftRequests, httpGetShiftRequests} from "../../HttpRequests/shifts.http.js";
import Checkbox from "../../Commons/Checkbox.js";
import {alertToggle} from "../../store/actions/alertActions.js";
import Spinner from "../../Commons/Spinner.js";

const useStyles = createUseStyles(theme => ({
    gridContainer: {
        'position': 'relative',
        // with big/small subheader like permute page
        height: ({bigSubHeader}) => `calc(100vh - ${bigSubHeader ? theme.bigSubHeader + theme.headerHeight + 10 : theme.smallSubHeader + theme.headerHeight}px)`,
        // without subheader
        //height: `calc(100vh - ${theme.headerHeight}px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        padding: ({isTablet}) => isTablet ? "32px 16px 0" : "32px 32px 0",
        [theme.s]: {
            padding: [theme.spacing],
        },
    },
    doubleRow: {
        ...theme.grid.doubleRow,
    },
    headingBtns: {
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '1fr auto',
        gridColumnGap: theme.spacing
    },
    infos: {
        display: 'grid',
        alignItems: 'center',
        '& strong': {
            fontSize: 14,
        },
        '& span': {
            fontSize: 14,
            color: theme.colors.grey
        }
    }
}));

const ShiftRequestsPlanning = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    // change autocomplete with simpleLocations composed with ubicaiton_name
    const allLocations = useSelector(state => state.autocompletes.simpleLocations);
    const shiftRequests = useSelector(state => state.shifts.shiftRequestsList);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState(location.search ? {
        ...qs.parse(location.search, {
            arrayFormat: 'comma',
            parseBooleans: true
        })
    } : {order_by: 'preferred_date', order_direction: 'desc'});
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(null);
    const classes = useStyles({bigSubHeader: true, isTablet: useWindowWidth() < 1200});// or false if type is small

    //clean up
    useEffect(() => {
        //fetch params from route and add to filters with setFilters
        // chek number of api calls
        return () => {
            dispatch(setShiftRequestsList([]))
        };
    }, []);

    //fetch when filters change
    useEffect(() => {
        if (!!Object.keys(filters).length) {
            setPage(1);
            setLastPage(null);
            getShiftRequests(1);
            history.replace({
                pathname: '/list/shift-requests/planning', search: qs.stringify({...filters}, {
                    arrayFormat: 'comma',
                    skipEmptyString: true,
                    skipNull: true
                }),
            });
        }
    }, [filters]);

    //fetch when page change
    useEffect(() => {
        if (!!shiftRequests.length && page !== 1) {
            getShiftRequests(page)
        }
    }, [page]);

    const getShiftRequests = async (actualPage = 1) => {
        if (lastPage && actualPage > lastPage) return;
        if (actualPage === 1) {
            dispatch(setShiftRequestsList([]))
        }
        setLoading(true);
        clearFilters(filters);
        try {
            const {data: {data, last_page}} = await httpGetShiftRequests({limit: 20, ...filters, page: actualPage});
            if (actualPage === 1) {
                dispatch(setShiftRequestsList(data));
            } else {
                dispatch(setShiftRequestsList(shiftRequests.concat(data)));
            }
            setLastPage(last_page)
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    };

    const onChangePage = (isFilter = false) => {
        if (isFilter) {
            setPage(1)
        } else {
            setPage(page + 1)
        }
    };

    const onDataSorting = (newSort) => {
        setFilters({
            ...filters,
            ...newSort,
        });
        setPage(1);
    };

    const resolveLocationFromParams = (id) => {
        return allLocations?.filter(item => +item.value === +id)[0]?.label
    }

    /**
     * Selections code
     * */
    const selectionIsNotEmpty = shiftRequests.some(item => item.selected);

    const selectAll = () => {
        shiftRequests.forEach(item => item.selected = (!selectionIsNotEmpty));
        dispatch(setShiftRequestsList([...shiftRequests]));
    };

    const selectRow = ({id}) => event => {
        if (event.target.hasAttribute('data-onclick')) return;
        const item = shiftRequests.find(item => +item.id === +id);
        item.selected = !item.selected;
        dispatch(setShiftRequestsList([...shiftRequests]));
    };

    /**
     * shift confirmation
     * @returns {Promise<void>}
     */
    const setShiftsConfirmed = async () => {
        let filteredItems = shiftRequests.filter(item => item.selected)?.map(item => item.id);
        setLoading(true);
        try {
            await httpConfirmShiftRequests({transportation_draft_id: [...filteredItems], vector: filters?.vector, user_id: filters?.user_id, reason: filters?.reason});
            dispatch(alertToggle('Spostamento creato e confermato con successo.', 'success'));
            history.push(`/list/shifts`)
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    };

    return <>
        {loading && <Spinner overlayFullscreen={true}/>}
        <SubHeading title={"Seleziona Veicoli"} type={'big'} justifySelf={'unset'} rowGap={16}
                    filtersComponent={<div className={classes.infos}>
                        <span>{filters?.preferred_date}</span>
                        <strong>{resolveLocationFromParams(filters?.location_id_from)} - {resolveLocationFromParams(filters?.location_id_to)}</strong>
                        <span>{shiftsReasons.filter(item => item.value = filters?.reason)[0]?.label} - {filters?.vector === 'internal' ? 'Bisarca interna' : 'Bisarca esterna'}</span>
                    </div>}
                    rightSearchComponent={
                        <div className={classes.headingBtns}>
                            <SimpleSearch filters={filters} setFilters={setFilters} name={'plate'}
                                          placeholder={'cerca targa'} setPage={setPage}/>
                            <Button data-primary onClick={setShiftsConfirmed} disabled={!selectionIsNotEmpty}>Crea
                                bolla</Button>
                        </div>}
        />

        <section className={classes.gridContainer}>
            <Table
                id={'table'}
                data={shiftRequests}
                inLoading={loading}
                filters={filters}
                onSetPageCb={onChangePage}
                onSortingDataCb={onDataSorting}
                theadHeight={40}
                rowMinHeight={40}
                classNames={{
                    table: classes.table,
                    thead: classes.thead,
                    row: classes.row,
                }}
                columns={['selected', 'car_plate', 'type', 'preferred_date', 'location_from', 'location_to', 'document']}
                columnsNames={{
                    selected: '',
                    car_plate: "Targa",
                    type: "Tipo di spostamento",
                    preferred_date: "Data e ora",
                    location_from: "Da",
                    location_to: "A",
                    document: "Bolla",
                }}
                columnsWidths={{
                    selected: "3%",
                    car_plate: "10%",
                    type: "17%",
                    preferred_date: "10%",
                    location_from: "21%",
                    location_to: "21%",
                    document: "15%",
                }}
                columnsAlignments={{
                    status: 'center',
                }}
                backEndSortable={true}
                sortable={true}
                //headersSorters={['assign_date', 'user', 'status']}
                headersRenderers={{
                    selected: <Checkbox className={classes.selected} data-onclick data-id={0} isSelectAll={true}
                                        checked={selectionIsNotEmpty} isSmall={true} onChange={selectAll}/>,
                }}
                columnsRenderers={{
                    selected: (value, {id}) => <Checkbox data-checkbox data-id={id} checked={value} isSmall={true}
                                                         onClick={selectRow({id})}/>,
                    car_plate: (value, items) => <span style={{textTransform: 'uppercase'}}>
                        {items?.car_identifiers?.plates ? items.car_identifiers.plates[0] :
                            items?.car_identifiers?.VIN ? items?.car_identifiers?.VIN : '-'}
                    </span>,
                    type: (value) => <span>{retrieveValueForRs(allShiftsTypes, value)[0]?.label}</span>,
                    preferred_date: (value, items) => <div className={classes.doubleRow}>
                        <span> {formattedDate(items?.preferred_date ? items?.preferred_date : items?.transfer_date)}</span>
                        <small>{formattedDateHours(items?.preferred_date ? items?.preferred_date : items?.transfer_date)}</small>
                    </div>,
                    location_from: (value, items) => <span><b>{items?.location_from?.ubication_name}</b></span>,
                    location_to: (value, items) => <span><b>{items?.location_to?.ubication_name}</b></span>,
                    document: (value, items) => <span className={classes.doc}>
                        {items?.attachment ? <a href={items?.attachment?.link}
                                                target={'_blank'}>{items?.attachment?.filename} </a> : 'N.d'}
                    </span>,
                }}
            />
        </section>
    </>
};

export default ShiftRequestsPlanning;
