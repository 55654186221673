import React, {forwardRef, useEffect, useRef, useState} from 'react';
import {DateRange} from 'react-date-range';
import {formattedDate, formattedDateIso} from "../helpers/utils.js";
import * as locale from 'react-date-range/dist/locale';
import {createUseStyles} from 'react-jss';
import Text from './Text';

const useStyles = createUseStyles(theme => ({
    overlay: {
        'z-index': 9,
        'position': 'fixed',
        'width': '100%',
        'height': '100%',
        'top': 0,
        'left': 0,
        'bottom': 0,
        'right': 0,
        cursor: "pointer"
    },
    calendarInput: {
        position: 'relative',
        cursor: 'pointer',
        ...theme.control.input,
        borderColor: theme.colors.lightGrey,
        color: theme.colors.darkGrey,
        fontWeight: 500,
        width: '100%',
        height: ({isSmall}) => isSmall ? 32 : 40,
        fontSize: ({isSmall}) => isSmall ? 13 : 15,
        display: "flex",
        alignItems: "center"
    },
    calendarContainer: {
        width: "100%",
        position: "relative",
    },
    container: {
        width: "100%",
        position: "relative"
    },
    rangeContainer:{
        position: 'absolute',
        'z-index': 11,
        right: ({alignRight}) => alignRight ? 0 : 'none',
        cursor: "pointer",
        top: ({openTo}) => openTo === 'top' ? -470 : 'unsetted',
        boxShadow: "rgb(23 29 41 / 8%) 0px 0px 12px 0px"
    },
    label: theme.control.label,

}));


const RangePicker = forwardRef(({
                                    inForm,
                                    openTo,
                                    label,
                                    control,
                                    name,
                                    callback,
                                    filters,
                                    setFilters,
                                    isSmall,
                                    startDate,
                                    endDate,
                                    notInFilters,
                                    alignRight,
                                    ...props
                                }, ref) => {
    const classes = useStyles({isSmall, openTo, alignRight})
    const [showDate, setShowDate] = useState()
    const [state, setRange] = useState({
        startDate: startDate ? new Date(startDate) : new Date(),
        endDate: endDate ? new Date(endDate) : new Date(),
        key: "selection"
    })

    const prevRangeRef = useRef();

    useEffect(() => {
        prevRangeRef.current = state.startDate;
    }, []);

    const prevRange = prevRangeRef.current;

    const handleToggle = () => {
        setShowDate(!showDate);
    }

    const handleChange = (item, filters = null) => {
        setRange({
            ...item.selection
        })
    }

    const handleRange = (item) => {
        setRange({
            ...item.selection
        })
        if (prevRange !== state.startDate) {
            if(!notInFilters){
                setFilters({
                    ...filters,
                    startDate: formattedDateIso(item.selection.startDate),
                    endDate: formattedDateIso(item.selection.endDate)
                })
            }
        }
    }

    if (inForm) {
        return (
            <>
                <div className={classes.calendarContainer}>
                    {showDate && <div className={classes.overlay} onClick={() => {
                        setShowDate(!showDate);
                    }}/>}
                    <div className={classes.container}>
                        <label className={classes.label}>{label}</label>
                        <div onClick={handleToggle} className={classes.calendarInput}
                        >{`${formattedDate(state.startDate)} - ${formattedDate(state.endDate)}`}</div>
                        <Text type="text" hidden ref={ref} name="start_date" value={state.startDate} readOnly/>
                        <Text type="text" hidden ref={ref} name="end_date" value={state.endDate} readOnly/>
                        {showDate &&
                        <div className={classes.rangeContainer}>
                            <DateRange
                                dateDisplayFormat={'dd-MM-yyyy'}
                                ranges={[state]}
                                moveRangeOnFirstSelection={false}
                                direction="horizontal"
                                months={1}
                                rangeColors={['#404040']}
                                onChange={(item) => handleChange(item)}
                                locale={locale.it}
                            />
                        </div>
                        }

                    </div>
                </div>
            </>
        )
    }
    return (
        <>
            <div className={classes.calendarContainer}>
                {showDate && <div className={classes.overlay} onClick={() => {
                    setShowDate(!showDate);
                    if (notInFilters) {
                        callback({
                            startDate: formattedDateIso(state.startDate),
                            endDate: formattedDateIso(state.endDate)
                        })
                    }
                }}/>}
                <div className={classes.container}>
                    <label className={classes.label}>{label}</label>
                    <div onClick={handleToggle} className={classes.calendarInput}
                    >{`${formattedDate(state.startDate)} - ${formattedDate(state.endDate)}`}</div>
                    {showDate && <div className={classes.rangeContainer}>
                        <DateRange
                            dateDisplayFormat={'dd-MM-yyyy'}
                            ranges={[state]}
                            moveRangeOnFirstSelection={false}
                            direction="horizontal"
                            months={1}
                            rangeColors={['#404040']}
                            onChange={(item) => {
                                handleRange(item)
                            }}
                            locale={locale.it}
                        />
                    </div>}
                </div>
            </div>
        </>

    )
})

export default RangePicker



