import React from 'react';
import {createUseStyles} from "react-jss";
import {useSelector} from "react-redux";
import MyUserDetails from "./MyUserDetails/MyUserDetails";
import MyConversations from "./MyConversations/MyConversations";


const useStyles = createUseStyles(theme => ({
    root: {
        display: ({isOpen}) => isOpen ? 'flex' : 'none',
        flexDirection: 'column',
        maxWidth: '420px',
        width: ({isSmall, isOpen}) => isOpen ? '100%' : isSmall ? '100%' : '24px',
        backgroundColor: '#1F1F1F',
        color: '#fff',
        [theme.s]: {
            maxWidth: 'none',
            position: 'absolute',
            zIndex: 100,
        }
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        color: '#565656',
        fontSize: '15px',
        margin: '10px 24px 10px 24px',
    },
    addIcon: {
        cursor: 'pointer'
    },
    list: {
        overflowY: 'scroll',
        display: 'flex',
        flexDirection: 'column',
    }

}))


const Menu = ({
                  isOpen,
                  isSmall = false,
                  ...rest
}) => {

    const classes = useStyles({isOpen, isSmall});
    //const channels = useSelector(state => state.chats.channels);
    //const currentConversationId = useSelector(getCurrentConversationId);

    return <section className={classes.root}>
        <MyUserDetails />
        <MyConversations/>
    </section>
}

export default Menu;