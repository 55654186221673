import React, {useEffect, useState} from 'react';
import {createUseStyles} from "react-jss";
import Spinner from "../../Commons/Spinner.js";
import Button from "../../Commons/Button.js";
import {useDispatch} from "react-redux";
import {useHistory} from 'react-router-dom';
import {useForm} from "react-hook-form";
import Select from "../../Commons/Select.js";
import {
    carStatus,
    errorHandler, formattedDateIso,
    gearBoxTypes,
    salesChannels,
    simplifiedCarColors,
    simplifiedInteriors
} from "../../helpers/utils.js";
import Text from "../../Commons/Text.js";
import {
    httpGetBrandsAutocomplete,
    httpGetFuelMethodAutocomplete,
    httpGetStructuresAutocomplete
} from "../../HttpRequests/commons.http.js";
import * as Yup from "yup";
import {httpCreateCar} from "../../HttpRequests/barters.http.js";
import {setCurrentBarter} from "../../store/actions/bartersActions.js";
import DayPickerInput from "../../Commons/DayPickerInput.js";
import {setCurrentCar} from "../../store/actions/carsActions.js";

const useStyles = createUseStyles(theme => ({
    root: {
        display: 'grid',
        width: '100%',
        gridRowGap: theme.spacing * 3,
        justifyItems: 'center'
    },
    title: {
        fontWeight: 600,
        margin: [theme.spacing * 2, 0],
        display: 'grid',
        alignItems: 'center',
        gridColumnGap: theme.spacing,
        gridTemplateColumns: '1fr auto',
        '& img': {
            width: 72
        }
    },
    body: {
        minWidth: 800,
        display: 'grid',
        gridTemplateColumns: "1fr 1fr",
        gridRowGap: theme.spacing * 2,
        gridColumnGap: theme.spacing * 4,
    },
    btns: {
        display: 'grid',
        gridColumnStart: 1,
        gridColumnEnd: 3,
        justifyItems: 'center',
        marginTop: 32,
        gridRowGap: 8,
        '& small': {},
        '& p': {
            textDecoration: 'underline',
            cursor: 'pointer'
        }
    }
}));

const validationSchema = Yup.object().shape({
    model: Yup.string()
        .required(`Inserisci il modello.`),
    doors: Yup.number()
        .required(`Inserisci il numero di porte.`),
    seats: Yup.number()
        .required(`Inserisci il numero di posti.`),
    structure: Yup.string().nullable()
        .required(`Inserisci la carrozzeria.`),
    fuel_method: Yup.string().nullable()
        .required(`Inserisci l'alimentazione.`),
    gearbox: Yup.string().nullable()
        .required(`Inserisci il cambio.`),
    sales_channel: Yup.string().nullable()
        .required(`Inserisci il canale di acquisto.`),
    simplified_color: Yup.string().nullable()
        .required(`Inserisci il colore ( semplificato ).`),
    simplified_interiors: Yup.string().nullable()
        .required(`Inserisci gli interni ( semplificati).`),
    type: Yup.string().nullable()
        .required(`Inserisci il tipo.`),
    matriculation: Yup.string().nullable()
        .required(`Inserisci la data di immatricolazione.`),
});

const ManualInsertStep = ({plate, mileage,onClose,isNewVehicle = false}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [autocompletes, setAutocompletes] = useState(null);
    const {register, handleSubmit, control, errors, watch} = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        validationSchema
    });

    useEffect(() => {
        getAllUsedAutocomplete();
    }, []);

    const getAllUsedAutocomplete = async () => {
        setIsLoading(true);
        try {
            const {data: fuelMethods} = await httpGetFuelMethodAutocomplete();
            const {data: brands} = await httpGetBrandsAutocomplete();
            const {data: structures} = await httpGetStructuresAutocomplete();
            setAutocompletes({fuelMethods, brands, structures});
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setIsLoading(false);
        }
    };

    const onSubmit = async (values) => {
        const dataToSend = {
            model: values.model,
            doors: values.doors,
            seats: values.seats,
            brand: watch('brand')?.value,
            structure: watch('structure')?.value,
            fuel_method: watch('fuel_method')?.value,
            gearbox:watch('gearbox')?.value,
            sales_channel: watch('sales_channel')?.value,
            simplified_color:watch('simplified_color')?.value,
            simplified_interiors: watch('simplified_interiors')?.value,
            type: watch('type')?.value,
            matriculation: values?.matriculation ? formattedDateIso(values.matriculation) : null,
            plate,
            mileage
        };

        try {
            setIsLoading(true);
           /* console.log(dataToSend);*/
            const {data: car} = await httpCreateCar(dataToSend);
            if(isNewVehicle){
                dispatch(setCurrentCar(car));
                onClose();
                history.push(`/stock/${car?.id}/new`);
            }else{
                dispatch(setCurrentBarter(car));
                onClose();
                history.push(`/barters/${car?.id}`);
            }
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setIsLoading(false);
        }
    };

    return <div className={classes.root}>
        {isLoading && <Spinner/>}
        <h4 className={classes.title}> Inserimento manuale </h4>
        <span className={classes.results}> Inserisci i campi richiesti </span>

        <form className={classes.body} onSubmit={handleSubmit(onSubmit)}>
            <Select name={'brand'} options={autocompletes?.brands} ref={register} control={control} disabled={false}
                    label={`Marca*`} isClearable={false} errors={errors.brand}/>
            <Text type={'text'} label={'Modello*'} name={'model'} ref={register} disabled={false}
                  errors={errors.model}/>
            <Select name={'structure'} options={autocompletes?.structures} ref={register} control={control}
                    disabled={false}
                    label={`Carrozzeria*`} isClearable={false} errors={errors.structure}/>
            <DayPickerInput label={'Data immatricolazione*'} name={'matriculation'} inForm={true} ref={register}
                control={control} errors={errors.matriculation} showYearDropdown={true} closeOnSelect={true}
            />
            <Text type={'number'} label={'Porte*'} name={'doors'} ref={register} disabled={false}
                  errors={errors.doors}/>
            <Text type={'number'} label={'Posti*'} name={'seats'} ref={register} disabled={false}
                  errors={errors.seats}/>
            <Select name={'fuel_method'} options={autocompletes?.fuelMethods} ref={register} control={control}
                    disabled={false}
                    label={`Alimentazione*`} isClearable={false} errors={errors.fuel_method}/>
            <Select name={'gearbox'} options={gearBoxTypes} ref={register} control={control} disabled={false}
                    label={`Cambio*`} isClearable={false} errors={errors.gearbox}/>
            <Select name={'sales_channel'} options={salesChannels} ref={register} control={control} disabled={false}
                    label={`Canale di vendita*`} isClearable={false} errors={errors.sales_channel}/>
            <Select name={'simplified_color'} options={simplifiedCarColors} ref={register} control={control}
                    disabled={false} label={`Colore esterno ( semplificato )`} isClearable={false}
                    errors={errors.simplified_color}/>
            <Select name={'simplified_interiors'} options={simplifiedInteriors} ref={register} control={control}
                    disabled={false} label={`Tipo Interni ( semplificato )`} isClearable={false}
                    errors={errors.simplified_interiors}/>
            <Select name={'type'} options={carStatus} ref={register} control={control} disabled={false}
                    label={`Stato vettura all'acquisto`} isClearable={false} errors={errors.type}/>

            <div className={classes.btns}>
                <Button type={'submit'} width={250} data-primary data-small > Crea Veicolo </Button>
            </div>
        </form>
    </div>
};

export default ManualInsertStep;