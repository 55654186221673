import React, {useEffect, useCallback} from 'react';
import {createUseStyles} from "react-jss";
import {ArrowLeft, CloseIcon} from "../../../assets/icons";
import {usePubNub} from "pubnub-react";
import {useDispatch, useSelector} from "react-redux";
import MemberDescription from "./MemberDescription/MemberDescription";
import {getCurrentConversationMembers} from "../../../store/reducers/chat/chats";
import {getCurrentConversationId} from "../../../store/reducers/chat/currentConversation";
import {getChannelMembersPaginationStateById} from "../../../store/reducers/chat/pagination";
import {setChannelMembersPagination} from "../../../store/actions/chat/pagnationActions";
import {fetchChannelMembers, fetchHereNow} from "pubnub-redux";
import {usePagination} from "../../../hooks/usePagination";
import {getLoggedInUserUuid} from "../../../store/reducers/user";
import {showMembers} from "../../../store/actions/chat/currentConversationActions";

const useStyles = createUseStyles(theme => ({
    root: {
        height: '100%',
        display: ({isOpen}) => isOpen ? 'flex' : 'none',
        flexDirection: 'column',
        maxWidth: '290px',
        width: ({isOpen}) => isOpen ? '100%' : 0,
        backgroundColor: '#fff',
        boxShadow: '0px 4px 21px 6px rgba(0, 0, 0, 0.05)',
        [theme.s]: {
            marginLeft: 0,
            maxWidth: 'none',
            position: 'fixed',
            zIndex: '300',
            backgroundColor: '#ffffff',
        },
        //transform: ({isOpen}) => isOpen ? `translateX(0)` : `translateX(-100)`,
        transition: 'ease: "linear", duration: .15 ',
    },
    header: {
        color: '#585858',
        lineHeight: '20px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: 24,
    },
    backIcon: {
        cursor: 'pointer',
        display: 'flex',
        marginRight: '25px',
        [theme.mUp]: {
            display: 'none'
        }
    },
    closeIcon: {
        cursor: 'pointer',
        display: 'none',
        [theme.mUp]: {
            display: 'block',
        }
    },
    list: {
        overflowY: 'scroll',
    }
}))


const orderByPresence = (members) => {
    return members.sort((userA, userB) =>
        userA.presence === userB.presence ? 0 : userA.presence ? -1 : 1
    );
};

const ConversationMembers = ({
                                 isOpen = false
                             }) => {

    const members = useSelector(getCurrentConversationMembers);
    const currentConversationId = useSelector(getCurrentConversationId);
    const userUuid = useSelector(getLoggedInUserUuid);

    const dispatch = useDispatch();
    const pubnub = usePubNub();
    const classes = useStyles({isOpen});


    const storedPaginationState = useSelector(
        getChannelMembersPaginationStateById
    )[currentConversationId];

    const restorePaginationState = useCallback(() => {
        return storedPaginationState;
    }, [storedPaginationState]);

    const savePaginationState = useCallback(
        (channel, pagination, count, pagesRemain) => {
            dispatch(
                setChannelMembersPagination(channel, { pagination, count, pagesRemain })
            );
        },
        [dispatch]
    );

    const getNextPage = useCallback(
        async (next, total, channel) => {
            const pageSize = 100;
            const action = await dispatch(
                fetchChannelMembers({
                    limit: pageSize,
                    channel,
                    include: {
                        UUIDFields: true,
                        customUUIDFields: true,
                        totalCount: true
                    },
                    page: {
                        next: next || undefined
                    }
                })
            );
            const response = action.payload.response;
            return {
                results: response.data,
                pagination: response.next,
                pagesRemain:
                    response.totalCount && total
                        ? total + response.data.length < response.totalCount
                        : response.data.length === pageSize
            };
        },
        [dispatch]
    );

    const { containerRef, endRef } = usePagination(
        getNextPage,
        currentConversationId,
        savePaginationState,
        restorePaginationState
    );

    // update hereNow when the conversationId changes
    useEffect(() => {
        dispatch(
            fetchHereNow({
                channels: [currentConversationId]
            })
        );
    }, [currentConversationId, pubnub, dispatch]);

    return <div className={classes.root}>
        <div className={classes.header}>
            <span className={classes.title}>Utenti in questo canale</span>
            <span className={classes.backIcon}><ArrowLeft/></span>
            <span className={classes.closeIcon} onClick={() => dispatch(showMembers(false))}><CloseIcon/></span>
        </div>
        <span className={classes.list} ref={containerRef}>
            {(members.length > 0) && orderByPresence(members).map(user => (
                <MemberDescription
                    user={user}
                    key={user.id}
                    you={user.id === userUuid}
                />
            ))}
            <div ref={endRef} />
        </span>
    </div>
}

export default ConversationMembers;