import React, {useState} from 'react';
import {createUseStyles} from "react-jss";
import Spinner from "../../Commons/Spinner.js";
import Button from "../../Commons/Button.js";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from 'react-router-dom';
import {useForm} from "react-hook-form";
import Select from "../../Commons/Select.js";
import {
    errorHandler,
    externalShiftsReasons,
    formattedDateHoursIso,
    InternalShiftsReasons,
} from "../../helpers/utils.js";
import * as Yup from "yup";
import DayPickerInput from "../../Commons/DayPickerInput.js";
import Textarea from "../../Commons/Textarea.js";
import Text from "../../Commons/Text.js";
import {httpSetShiftRequest} from "../../HttpRequests/shifts.http.js";
import {alertToggle} from "../../store/actions/alertActions.js";
import {setCarShifts, setCarTasks} from "../../store/actions/carsActions.js";

const useStyles = createUseStyles(theme => ({
    root: {
        display: 'grid',
        width: '100%',
        gridRowGap: theme.spacing * 3,
        justifyItems: 'center',
    },
    title: {
        fontWeight: 600,
        margin: [theme.spacing * 2, 0],
        display: 'grid',
        alignItems: 'center',
        gridColumnGap: theme.spacing,
        gridTemplateColumns: '1fr auto',
        '& img': {
            width: 72
        }
    },
    body: {
        display: 'grid',
        gridTemplateColumns: "1fr 1fr",
        gridRowGap: theme.spacing * 2,
        gridColumnGap: theme.spacing * 4,
        padding: [0, theme.spacing * 2],
        width: '100%',
        maxWidth:450,
    },
    full:{gridColumn: '1 / span 2'},
    btns: {
        display: 'grid',
        gridColumnStart: 1,
        gridColumnEnd: 3,
        justifyItems: 'center',
        marginTop: 32,
        gridRowGap: 8,
        '& small': {},
        '& p': {
            textDecoration: 'underline',
            cursor: 'pointer'
        }
    }
}));

const validationSchema = Yup.object().shape({
    plate: Yup.string().nullable()
        .required(`Inserisci almeno una targa.`),
    location_id_to: Yup.string().nullable()
        .required(`Inserisci la posizione di partenza.`),
    location_id_from: Yup.string().nullable()
        .required(`Inserisci la destinazione di arrivo.`),
    preferred_date: Yup.string().nullable()
        .required(`Inserisci una data.`),
    reason: Yup.string().nullable()
        .required(`Inserisci la causale.`),
});

const InternalOrExternalShiftModal = ({onClose,type}) => {
    const dispatch = useDispatch();
    const {id} = useParams();
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const allLocations = useSelector( state => state.autocompletes.simpleLocations);
    const car = useSelector(state => state.cars.currentCar);
    const carTasks = useSelector(state => state.cars.carTasks);
    const shifts = useSelector(state => state.cars.carShifts);
    const user = useSelector(state => state.users.user)
    const {register, handleSubmit, formState:{isSubmitted,isValid,touched}, control, errors, watch} = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues:{
            plate: car?.plate,
            location_id_from: {label: car?.location?.name, value: car?.location_id}
        },
        validationSchema
    });

    const onSubmit = async (values) => {
        const dataToSend = {
            ...values,
            reason: watch('reason')?.value,
            location_id_to: watch('location_id_to')?.value,
            location_id_from: watch('location_id_from')?.value,
            transfer_date: values?.transfer_date ? formattedDateHoursIso(values.transfer_date) : null,
            preferred_date: values?.preferred_date ? formattedDateHoursIso(values.preferred_date) : null,
            user_id: user?.id,
            type: type,
        };
        try {
            setIsLoading(true);
            const {data: shift} = await httpSetShiftRequest(id,dataToSend);
            dispatch(setCarTasks([...shift?.tasks,...carTasks]));
            dispatch(setCarShifts([shift,...shifts]));
            dispatch(alertToggle('Richiesta di spostamento creata con successo.', 'success'));
            onClose();
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setIsLoading(false);
        }
    };

    console.log(type)
    return <div className={classes.root}>
        {isLoading && <Spinner/>}
        <h4 className={classes.title}>
            {type === 'internal_transport' ? 'Richiesta spostamento sedi Interne' : 'Richiesta spostamento sedi Esterne'}
        </h4>

        <form className={classes.body} onSubmit={handleSubmit(onSubmit)}>
            <Text name={'plate'} type={'text'} ref={register} label={`Targa*`} disabled={true}
                  errors={errors.plate}  placeholder={'Scrivi qui la targa'}/>

            <Select name={'reason'} options={type === 'internal_transport'  ? InternalShiftsReasons :  externalShiftsReasons} ref={register} control={control} label={`Causale*`}
                    errors={errors.reason} />

            <Select name={'location_id_from'} options={allLocations} ref={register} control={control} disabled={true}
                    label={`Da*`} isClearable={false} errors={errors.location_id_from}/>
            <Select name={'location_id_to'} options={allLocations} ref={register} control={control} disabled={false}
                    label={`A*`} isClearable={false} errors={errors.location_id_to}/>

            <DayPickerInput label={'Data*'} name={'preferred_date'} inForm={true} ref={register} className={classes.full}
                            control={control} errors={errors.preferred_date} showYearDropdown={true} closeOnSelect={true}
            />
            <Textarea rows={"4"} label={'Note'} name={'notes'} ref={register} className={classes.full} />

            <div className={classes.btns}>
                <Button type={'submit'} width={250} data-primary data-small disabled={isSubmitted || (!isValid && touched)}> Richiedi </Button>
            </div>
        </form>
    </div>
};

export default InternalOrExternalShiftModal;