import React from 'react';
import {createUseStyles} from 'react-jss';
import {useDispatch} from 'react-redux';
import {useForm} from 'react-hook-form';
import Radio from "../../Commons/Radio.js";
import cx from 'classnames';
import {setCurrentBarter, setDamages} from "../../store/actions/bartersActions.js";
import {
    clearDamagesForBackend,
    errorHandler,
    retrieveDamagesForRhf
} from "../../helpers/utils.js";
import {httpEditCarInfo} from "../../HttpRequests/barters.http.js";

const useStyles = createUseStyles(theme => ({
    root: {
        position: 'relative',
        'display': 'grid',
        'grid-auto-flow': 'row',
        'grid-row-gap': theme.spacing,
        'grid-column-gap': theme.spacing * 5,
        [theme.s]: {
            width: 320,
            gridTemplateColumns: '1fr',
            justifyContent: "center",
        },
        [theme.mUp]: {
            'width': '100%',
            gridTemplateColumns: '0.3fr 1fr',
            justifyContent: "center",
        },
    },

    sectionTitle: {
        display: 'grid',
        gridColumn: '1 / span 3',
        fontWeight: 600,
        textAlign: "left",
        fontSize: 16,
        padding: [theme.spacing * 2, 0, 0],
        borderTop: `1px solid ${theme.colors.lightGrey}`
    },

    head: {
        display: 'grid',
        marginBottom: theme.spacing * 2,
        gridTemplateColumns: '0.3fr 1fr',
        alignItems: 'center',
        gridColumn: '1 / span 3',
        fontSize: 16,
        fontWeight: 600
    },

    row: {
        display: 'grid',
        'grid-row-gap': theme.spacing,
        gridTemplateColumns: '0.3fr 1fr',
        alignItems: 'center',
        gridColumn: '1 / span 3',
        fontSize: 14
    },

    radioGroup: {
        display: "grid",
        alignItems: "center",
        gridAutoFlow: "column",
        gridAutoColumns: "max-content",
        gridColumnGap: theme.spacing * 3
    },

    isEven: {
        backgroundColor: '#f9f9f9',
        borderTop: `1px solid ${theme.colors.lightGrey}`,
        borderBottom: `1px solid ${theme.colors.lightGrey}`,
        padding: "12px 2px"
    }
}));

const DamagesGroupList = ({damagesGroup, damages,car}) => {
    const classes = useStyles();

    const dispatch = useDispatch();
   /* const [isLoading, setIsLoading] = useState(false);*/
    const {register, watch} = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            ...retrieveDamagesForRhf(damages),
        },
    });

    const editCarInfo = async (newDamages) => {
        const dataToSend = {
                car: {
                    id: car.id,
                    is_draft: true,
                },
                damages: clearDamagesForBackend(newDamages),
            };
        try {
            const {data} = await httpEditCarInfo(dataToSend,false);
            dispatch(setCurrentBarter(data));
        } catch (e) {
            errorHandler(e, dispatch)
        }
    };

    const setDamage = async (fieldName, value, label,price) => {
        let newDamages = damages;
        const damageToInsert = {
            id: value,
            car_part: fieldName,
            label: label,
            price:price,
        };
        //if already exist a value for the damage name replace it with the new one or create a new element and push it
        if (!!newDamages.length) {
            const foundIndex = newDamages.findIndex((item) => item.car_part === damageToInsert.car_part);
            if (foundIndex !== -1) {
                newDamages[foundIndex] = {
                    ...damageToInsert
                }
            } else {
                newDamages.push({id: value, car_part: fieldName, label: label,price:price})
            }
        } else {
            newDamages.push({id: value, car_part: fieldName, label: label, price:price})
        }
        //set damages
        dispatch(setDamages(newDamages));
        await editCarInfo(newDamages)
    };

    return <form className={classes.root}>
       {/* {isLoading && <Spinner/>}*/}
        <h4 className={classes.sectionTitle} style={{padding: 0}}/>
        <div className={classes.head}>
            <span> Pezzo </span>
            <span> Danno </span>
        </div>
        {damagesGroup.map((damage, index) => {
            return <div className={cx(classes.row, (index % 2 === 0) ? classes.isEven : '')} key={index}>
                <label> {damage.title} </label>
                <div className={classes.radioGroup}>
                    <Radio ref={register} name={damage.car_part} value={''} defaultChecked={true} labelOn={'right'}
                           isSmall={true} label={'Nessun danno'}
                           onChange={() => setDamage(damage.car_part, watch(damage.car_part), 'Nessun danno',0)}/>
                    {damage.damage_sets[0].options.map(({id, label,price}, radioIndex) => {
                        return <Radio ref={register} name={damage.car_part} value={id} labelOn={'right'} isSmall={true}
                                      label={label} key={radioIndex}
                                      onChange={() => setDamage(damage.car_part, watch(damage.car_part), label, price)}/>
                    })}
                </div>
            </div>
        })}
    </form>
};

export default DamagesGroupList;
