import React, {useCallback} from 'react';
import {createUseStyles} from "react-jss";
import useHover from "../../../../../hooks/useHover";
import {OchIcon} from "../../../../../assets/icons";
import UserInitialsAvatar from "../../../CurrentConversation/UserInitialsAvatar/UserInitialsAvatar";
import {useSelector} from "react-redux";
import {getUnreadMessagesById} from "../../../../../store/reducers/chat/unreadMessage";
import {getCurrentConversationMembers, getPresenceByConversationId} from "../../../../../store/reducers/chat/chats";
import {getLoggedInUserUuid} from "../../../../../store/reducers/user";
import PresenceIndicator from "../../MyUserDetails/PresenceIndicator/PresenceIndicator";

const useStyles = createUseStyles(theme => ({
    root: {
        color: '#C1C1C1',
        height: '56px',
        padding: [8, 32],
        fontSize: '14px',
        textTransform: 'capitalize',
        cursor: 'pointer',
        backgroundColor: ({selected}) => `${selected ? "#424EB9" : "transparent"}`,
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: ({isHovering, selected}) => selected ? '#424EB9' : `${isHovering ? "#333333" : "transparent"}`,
        }
    },
    body: {
        display: "grid",
        gridTemplateColumns: 'auto 1fr auto',
        alignItems: 'center'
    },
    icon: {
        width: 40,
        height: 40,
        borderRadius: '12px',
        padding: 6,
        fontSize: '12px',
        backgroundColor: '#3A3A3A',

},
    name: {
        margin: '10px 20px',
        //width:' 123px',
        maxWidth: '280px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    unreadBadge: {
        backgroundColor: theme.colors.red,
        padding: [2,4],
        fontSize: 11,
        borderRadius: 24,
        height: 16,
        fontWeight: 700,
        minWidth: 28,
        textAlign: 'center'
    },
    userStatus: {
        position: 'relative',
        '& > svg': {
            position: 'absolute',
            top: 0,
            right: 0
        }
    }
}));

const ConversationItem = ({
                              selected,
                              id,
                              name,
                              onClick,
                              onLeave,
                              isDirect
}) => {

    const [isHovering, hoverProps] = useHover({ mouseEnterDelayMS: 0 });
    const canLeave = false; // TODO: inserire regola per uscire
    const classes = useStyles({selected, isHovering});
    const unreadMessageCount = useSelector(getUnreadMessagesById)?.[id];
    const presence =  useSelector(getPresenceByConversationId);
    const userUuid = useSelector(getLoggedInUserUuid);

    const getMemberPresence = useCallback(() => {
        const online = presence && presence[id] ?
            presence[id].occupants?.filter(occupant => occupant.uuid !== userUuid).length > 0
            : false
        return <PresenceIndicator title={online ? "connesso" : "inattivo"} active={online}/>
    }, [presence])

    return (
        <>
        <div className={classes.root} onClick={onClick} {...hoverProps}>
            <div className={classes.body}>
                {isDirect ?
                    <div className={classes.userStatus}>
                        <UserInitialsAvatar size={40} name={name} userId={id} />
                        {getMemberPresence()}
                    </div>
                    :
                    <span className={classes.icon}><OchIcon width={28} height={28}/></span>
                }
                <span className={classes.name}>{name}</span>
                {unreadMessageCount?.totalCount > 0 && (
                    <span className={classes.unreadBadge}>{unreadMessageCount.totalCount}</span>
                )}
            </div>

            {canLeave && (
                <span
                    onClick={e => {
                        e.stopPropagation();
                        onLeave();
                    }}
                    style={{fontSize:12, color: '#ff0034'}}
                >
                    Lascia
                </span>
            )}
        </div>
        </>
    );
};


export default ConversationItem;
