import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useSelector} from "react-redux";
import {BusinessIcon} from "../../assets/icons.js";
import cx from 'classnames';
import Dashboard from "../../Components/Home/Dashboard.js";

const useStyles = createUseStyles(theme => ({
    gridContainer: {
        height: `calc(100vh - 180px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        [theme.mUp]: {
            padding: [theme.spacing * 3]
        },
        [theme.s]: {
            padding: [theme.spacing],
        },
        'position': 'relative',
    },
    tabs:{
        display: "grid",
        alignItems: "center",
        gridTemplateColumns: 'repeat( auto-fit, minmax(100px, 1fr) )',
        height: 120,
        background: theme.colors.white,
    },
    tab:{
        transition: 'ease-in 400ms',
        display: "grid",
        padding: theme.spacing,
        alignItems: "center",
        gridRowGap: theme.spacing,
        justifyItems: 'center',
        height: "100%",
        borderRadius: "16px 16px 0 0",
        opacity: 0.6,
        cursor: 'pointer',
        '& img':{
            width: 68,
            height: 68,
        },
        '& svg':{
            width: `64px!important`,
        },
        '& p':{
            textAlign: 'center',
            fontSize:12,
            fontWeight: 600
        },
        '&:hover':{
            background: theme.colors.body +'99',
        }
    },
    tabSelected:{
        background: theme.colors.body,
        opacity: 1
    }
}));

const Home = () => {
    const classes = useStyles({bigSubHeader: false});
    const companiesAutocomplete = useSelector(state => state.autocompletes.companiesAutocomplete);
    const [selectedDash,setSelectedDash] = useState(null);

    useEffect(() => {
        setSelectedDash(companiesAutocomplete[0]?.value)
    },[companiesAutocomplete])

    return <>
        <div className={classes.tabs}>
            {companiesAutocomplete?.map(company => <div className={cx(classes.tab, (+selectedDash === +company?.value) ? classes.tabSelected : '')}
                                                        onClick={() => setSelectedDash(company?.value)}
                                                        key={company?.value}>
                {company?.img ? <img src={company.img} alt={'logo'}/> : <BusinessIcon data-color/>}
                <p>{company?.label}</p>
            </div>)}
        </div>
        <div className={classes.gridContainer}>
            <Dashboard selected={selectedDash}/>
        </div>
    </>
};

export default Home;
