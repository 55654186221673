import axios from 'axios';

/**
 * @returns {Promise<AxiosResponse<T>>}
 */
export const httpGetDashGeneralData = (companyId) => {
    return axios.get(`/dashboard/${companyId}/stock_stats`);
};

/**
 *
 * @param companyID
 * @param filters
 * @returns {Promise<AxiosResponse<any>>}
 */
export const httpGetSalesChartData = (companyID,filters = null) => {
    return axios.get(`/dashboard/${companyID}/sales`, {params: { ...filters }});
};

/**
 *
 * @param companyID
 * @param filters
 * @returns {Promise<AxiosResponse<any>>}
 */
export const httpGetProfitChartData = (companyID,filters = null) => {
    return axios.get(`/dashboard/${companyID}/income_and_expenses`, {params: { ...filters }});
};

/**
 *
 * @param companyID
 * @param filters
 * @returns {Promise<AxiosResponse<any>>}
 */
export const httpGetTasksChartData = (companyID,filters = null) => {
    return axios.get(`/dashboard/${companyID}/tasks`, {params: { ...filters }});
};


