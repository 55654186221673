import React, {useState} from 'react';
import {createUseStyles} from "react-jss";
import cx from 'classnames';
import Button from "../../Commons/Button.js";
import {BodyShopIcon, TireCenterIcon, WorkshopIcon} from "../../assets/icons.js";

const useStyles = createUseStyles(theme => ({
    root: {
        display: 'grid',
        width: '100%',
        '& button': {
            marginTop: theme.spacing * 3
        },
        '& h4':{
            textAlign: 'center',
            fontWeight: 600,
            marginTop: 32
        }
    },
    title: {
        fontWeight: 600,
        margin: [theme.spacing * 2, 0]
    },
    item: {
        display: "grid",
        width: "100%",
        gridRowGap: theme.spacing,
        padding: theme.spacing,
        border: `1px solid ${theme.colors.smokeWhite}`,
        cursor: "pointer",
        borderRadius: 8,
        gridTemplateColumns: "auto 1fr",
        color: theme.colors.black,
        transition: 'ease-in-out 300ms',
        alignItems: 'center',
        gridColumnGap: theme.spacing,
        margin: [theme.spacing , 0],
        '& svg':{
            fill: theme.colors.black,
        },
        '&:hover': {
            border: `1px solid ${theme.colors.lightBlack}`,
            background: theme.colors.lightBlack,
            color: theme.colors.white,
            '& svg':{
                fill: theme.colors.white,
            },
        }
    },
    selected: {
        border: `1px solid ${theme.colors.black}`,
        background: theme.colors.black,
        color: theme.colors.white,
        '& svg':{
            fill: theme.colors.white,
        },
    },
    btns: {
        width: "300px",
        margin: '24px auto 0',
        display: "grid",
        alignItems: "center",
        gridColumnGap: theme.spacing,
        gridTemplateColumns: "1fr 1fr"
    }
}));

const AssignableUserPopover = ({onChoseUserCb, users, onCloseCb}) => {
    const classes = useStyles();
    const [selectedUser, setSelectedUser] = useState(null);

    const onAssign = async () => {
        await onChoseUserCb('assign', selectedUser);
        onCloseCb();
    };

    const onSelectUser = (user) => {
        user.selected = !user.selected;
        setSelectedUser(user);
    };

    return <div className={classes.root}>
        {!!users.workShop.length && <strong style={{margin: '0 0 4px'}}> Officina </strong>}
        {users.workShop.map(user => <div onClick={() => onSelectUser(user)} key={user.id}>
            <p className={cx(classes.item, ((selectedUser?.id === user.id) && classes.selected))}>
                <WorkshopIcon data-color/> <span>{user.full_name}</span>
            </p>
        </div>)}
        {!!users.bodyShop.length && <strong style={{margin: '16px 0 4px'}}> Carrozzeria </strong>}
        {users.bodyShop.map(user => <div onClick={() => onSelectUser(user)} key={user.id}>
            <p className={cx(classes.item, ((selectedUser?.id === user.id) && classes.selected))}>
                <BodyShopIcon data-color/> <span>{user.full_name}</span>
            </p>
        </div>)}
        {!!users.tireCenter.length && <strong style={{margin: '16px 0 4px'}}> Gommista </strong>}
        {users.tireCenter.map(user => <div onClick={() => onSelectUser(user)} key={user.id}>
            <p className={cx(classes.item, ((selectedUser?.id === user.id) && classes.selected))}>
                <TireCenterIcon data-color/> <span>{user.full_name}</span>
            </p>
        </div>)}

        <div className={classes.btns}>
            <Button type={'button'} onClick={onCloseCb} data-secondary data-small>Annulla</Button>
            <Button type={'button'} onClick={onAssign} data-primary data-small disabled={!selectedUser}>Conferma</Button>
        </div>
    </div>
};

export default AssignableUserPopover;