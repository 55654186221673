import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useDispatch} from 'react-redux';
import Button from "../../Commons/Button.js";
import {errorHandler} from "../../helpers/utils.js";
import {httpGetUsers} from "../../HttpRequests/settings.http.js";
import Popover from "../../Commons/Popover.js";
import PropTypes from "prop-types";
import AssignableUserPopover from "./AssignableUserPopover.js";
import SimpleRepairList from "./SimpleRepairList.js";

const useStyles = createUseStyles((theme) => ({
    tableContainer: {},
    actions: {
        margin: [theme.spacing * 6, 0, theme.spacing * 2],
        display: 'grid',
        gridTemplateColumns: '1fr auto auto',
        gridColumnGap: theme.spacing * 2,
        alignItems: 'center',
        '& h4': {
            color: theme.colors.black,
            fontSize: 20,
        },
    },
    btns: {
        marginTop: theme.spacing * 2,
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: theme.spacing,
        width: 300,
    }
}));

const RepairsToAssign = ({repairsToAssign, assignOrDiscardRepairCb}) => {
    const dispatch = useDispatch();
    const classes = useStyles({});
    const [assignableUsers, setAssignableUsers] = useState(null);
    const [toAssign, setToAssign] = useState([]);
    const [toWork, setToWork] = useState([]);
    const [selectAllToAssignActive, setSelectAllToAssignActive] = useState(false);
    const [selectAllToWorkActive, setSelectAllToWorkActive] = useState(false);
    const [toAssignTotal, setToAssignTotal] = useState(0);
    const [toWorkTotal, setToWorkTotal] = useState(0);
    //users list
    const [usersModalOpened, setUsersModalOpened] = useState(false);

    useEffect(() => {
        setToAssign(repairsToAssign)
    }, [repairsToAssign]);

    useEffect(() => {
        getAssignableUsers();
    }, []);

    const getAssignableUsers = async () => {
        try {
            const {data: users} = await httpGetUsers({limit: 250});
            //dispatch(setCurrentCar(car));
            setAssignableUsers({
                bodyShop: users.data.filter(user => (user.operations.body_shop)),
                workShop: users.data.filter(user => (user.operations.workshop)),
                tireCenter: users.data.filter(user => (user.operations.tire_center))
            });
        } catch (e) {
            errorHandler(e, dispatch)
        }
    };

    //elementi nella tabella Periziato selezionati
    const toAssignSelectionIsNotEmpty = toAssign.some(item => item.toAssignSelected);
    //elementi nella tabella Da lavorare selezionati
    const toWorkSelectionIsNotEmpty = toWork.some(item => item.toWorkSelected);

    //Seleziona tutti gli elementi nella tabella Periziato
    const selectAllToAssign = () => {
        setSelectAllToAssignActive(!selectAllToAssignActive);
        toAssign.forEach(item => item.toAssignSelected = (!selectAllToAssignActive));
        setToAssignTotal(toAssign.filter(item => item.toAssignSelected).reduce((a, b) => a + (b.price || 0), 0));
        setToAssign([...toAssign]);
    };

    //Seleziona tutti gli elementi nella tabella Da lavorare
    const selectAllToWork = () => {
        setSelectAllToWorkActive(!selectAllToWorkActive);
        toWork.forEach(item => item.toWorkSelected = (!selectAllToWorkActive));
        setToWorkTotal(toWork.filter(item => item.toWorkSelected).reduce((a, b) => a + (b.price || 0), 0));
        setToWork([...toWork]);
    };

    //seleziona o deseleziona una riga della tabaella Periziato
    const selectToAssignRow = (id) => {
        const item = toAssign.find(item => +item.id === +id);
        item.toAssignSelected = !item.toAssignSelected;
        //Ricalcolo il totale delle operazioni selezionate
        setToAssignTotal(toAssign.filter(item => item.toAssignSelected).reduce((a, b) => a + (b.price || 0), 0));
        setToAssign([...toAssign]);
    };

    //seleziona o deseleziona una riga della tabaella Da lavorare
    const selectToWorkRow = (id) => {
        const item = toWork.find(item => +item.id === +id);
        item.toWorkSelected = !item.toWorkSelected;
        //Ricalcolo il totale delle operazioni selezionate
        setToWorkTotal(toWork.filter(item => item.toWorkSelected).reduce((a, b) => a + (b.price || 0), 0));
        setToWork([...toWork]);
    };

    //sposta le rghe selezionate da Periziato cancellandole da li ed inserendole in Da lavorare
    const moveIntoWork = () => {
        //prendo gli elementi
        let itemsToMove = toAssign.filter(item => item.toAssignSelected);
        //elimino questi elementi dal vecchio array
        setToAssign(toAssign.filter(item => !item.toAssignSelected));
        //faccio un reset delle select
        itemsToMove.map(item => item.toAssignSelected = false);
        //Ricalcolo il totale delle operazioni selezionate
        setToAssignTotal(toAssign.filter(item => item.toAssignSelected).reduce((a, b) => a + (b.price || 0), 0));
        //li sposto in to work
        setToWork([...toWork, ...itemsToMove]);
    };

    //sposta le rghe selezionate rimettendole in da lavorare
    const rollbackIntoAssign = (selected) => {
        //elimino questi elementi dal vecchio array
        setToWork(toWork.filter(item => item.id !== selected.id));
        //faccio un reset delle select
        selected.toWorkSelected = false;
        //Ricalcolo il totale delle operazioni selezionate
        setToWorkTotal(toWork.filter(item => item.toWorkSelected).reduce((a, b) => a + (b.price || 0), 0));
        //li sposto in to assign
        setToAssign([...toAssign, selected]);
    };

    //sposta le rghe selezionate da Periziato cancellandole da li ed inserendole in Da lavorare
    const assignOrDiscardSelectedRepairs = async (actionType, selectedUser = null) => {
        if (actionType === 'discard') {
            await assignOrDiscardRepairCb(actionType, toAssign.filter(item => item.toAssignSelected))
        } else {
            await assignOrDiscardRepairCb(actionType, toWork.filter(item => item.toWorkSelected), selectedUser.id);
            //rimuovo le assegnate da to work
            setToWork(toWork.filter(item => !item.toWorkSelected));
            //operazioni selezionate 0
            setToWorkTotal(0);
            //resetto per sicurezza la select all
            setSelectAllToWorkActive(false)
        }
    };

    return <>
        {usersModalOpened &&
        <Popover withHeader={true} title={'Seleziona un utenza'} minWidth={400} width={400} onClose={() => setUsersModalOpened(!usersModalOpened)}>
            <AssignableUserPopover users={assignableUsers} onCloseCb={() => setUsersModalOpened(!usersModalOpened)}
                                   onChoseUserCb={assignOrDiscardSelectedRepairs}/>
        </Popover>}
        <div className={classes.tableContainer}>
            <SimpleRepairList list={toAssign} selectAllCb={selectAllToAssign} title={'Periziato'} type={'assign'}
                              allSelected={selectAllToAssignActive} total={toAssignTotal}
                              selectCb={selectToAssignRow}/>
            {toAssignSelectionIsNotEmpty && <div className={classes.btns}>
                <Button data-primary onClick={moveIntoWork}>Da lavorare</Button>
                <Button onClick={() => assignOrDiscardSelectedRepairs('discard')}>Da scartare</Button>
            </div>}

            {/* TO work list */}
            <SimpleRepairList list={toWork} selectAllCb={selectAllToWork} title={'Da lavorare'} type={'work'}
                              allSelected={selectAllToWorkActive} rollbackCb={rollbackIntoAssign}
                              selectCb={selectToWorkRow} total={toWorkTotal}/>
            {toWorkSelectionIsNotEmpty && <div className={classes.btns}>
                <Button data-primary onClick={() => setUsersModalOpened(!usersModalOpened)}>Assegna</Button>
            </div>}
        </div>
    </>

};

RepairsToAssign.propTypes = {
    repairsToAssign: PropTypes.array,
    assignOrDiscardRepairCb: PropTypes.func,
};

export default RepairsToAssign;
