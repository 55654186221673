import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import Spinner from "../../Commons/Spinner";
import Text from "../../Commons/Text";
import {alertToggle} from "../../store/actions/alertActions.js";
import {httpBuyBack, httpEditCarInfo} from "../../HttpRequests/barters.http.js";
import Select from "../../Commons/Select.js";
import {
    buybackFiscality, clearDamagesForBackend,
    errorHandler,
    financialServices,
    fiscality,
    retrieveValueForRs,
    simpleYesOrNo
} from "../../helpers/utils.js";
import {setCurrentBarter} from "../../store/actions/bartersActions.js";
import {setCurrentCar} from "../../store/actions/carsActions";

const useStyles = createUseStyles(theme => ({
    root: {
        position: 'relative',
        'display': 'grid',
        'grid-auto-flow': 'row',
        'grid-row-gap': theme.spacing * 3,
        'grid-column-gap': theme.spacing * 5,
        [theme.s]: {
            width: 320,
            gridTemplateColumns: '1fr',
            justifyContent: "center",
        },
        [theme.mUp]: {
            'width': '100%',
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            justifyContent: "center",
        },
    },

    sectionTitle: {
        fontWeight: 600,
        textAlign: "left",
        gridColumnStart: 1,
        gridColumnEnd: 5,
        fontSize: 16,
        padding: [theme.spacing * 2, 0, 0],
        borderTop: `1px solid ${theme.colors.lightGrey}`
    },

    buyBack: {
        display: 'grid',
        gridColumnStart: 1,
        gridColumnEnd: 5,
        paddingTop: theme.spacing * 2,
        '& h4': {
            fontSize: 16,
            textTransform: 'uppercase',
            justifySelf: 'end',
            '& span': {
                fontSize: 18,
                fontWeight: 600,
                marginLeft: theme.spacing * 3
            }
        }
    }
}));

//car obj in sycn with bartersStore
const FiscalityForm = ({car, isStock = false,isNewCar= false}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [buyback, setBuyBack] = useState(null);
    const damages = useSelector(state => state.barters.damages);
    const {register, watch, control, setValue} = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            ...car,
            fiscality: retrieveValueForRs(fiscality, car?.fiscality),
            buyback_required: retrieveValueForRs(simpleYesOrNo, car?.buyback_required),
            buyback_fiscality: retrieveValueForRs(buybackFiscality, car?.buyback_fiscality),
            finance: retrieveValueForRs(financialServices, car?.finance),
            leasing_buyback_value: car?.buyback_required ? car?.buyback_amount : car?.leasing_buyback_value
        },
    });
    const hideAll = watch('fiscality') && (watch('fiscality')[0]?.value === 'iva_40_60');
    const isLeasing = watch('buyback_required') && (watch('buyback_required')[0]?.value);
    const isMargin = watch('fiscality') && watch('fiscality')[0]?.value === 'margine';

    useEffect(() => {
        getBuyBackValue();
        if(isLeasing){
            setValue('leasing_buyback_value', watch(`buyback_amount`), )
        }
    }, []);

    const getBuyBackValue = async () => {
        try {
            const {data: {price}} = await httpBuyBack(car.id);
            setBuyBack(price);
        } catch (e) {
            errorHandler(e, dispatch)
        }
    };

    const editCarInfo = async (fieldName, value, label) => {
        if ((value === null || value === '') || car[fieldName]?.toString() === value.toString()) return;
        const dataToSend = {
            car: {
                id: car.id,
                leasing_buyback_value: watch(`leasing_buyback_value`),
                ...(isStock && car),
                [fieldName]: value,
                ...(!isNewCar && { is_draft: true }),
            },
            damages: clearDamagesForBackend(damages),
        };
        try {
            const {data: currentCar} = await httpEditCarInfo(dataToSend,isNewCar);
            isStock ? dispatch(setCurrentCar(currentCar)) : dispatch(setCurrentBarter(currentCar));
            dispatch(alertToggle(`Il campo ${label} è stato modificato correttamente.`, 'success'))
            const {data: {price}} = await httpBuyBack(car.id);
            setBuyBack(price);
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setIsLoading(false);
        }
    };

    return <form className={classes.root}>
        {isLoading && <Spinner/>}
        <div className={classes.sectionTitle} style={{padding: 0}}/>
        <Select name={'fiscality'} options={fiscality} ref={register} control={control}
                label={`Regime IVA*`} isClearable={false} onChange={(value) => {
            editCarInfo('fiscality', value[0].value, `Regime IVA`);
            return value
        }}/>
        {(!hideAll && (!isMargin)) && <Select name={'buyback_required'} options={simpleYesOrNo} ref={register} control={control}
                             label={`Riscatto leasing*`} isClearable={false} onChange={(value) => {
            editCarInfo('buyback_required', value[0].value, `Riscatto leasing`);
            return value
        }}/>}
        {(!hideAll && isLeasing && !isMargin) &&
        <Text type={'number'} label={'Importo riscatto leasing*'} name={'buyback_amount'} ref={register}
              onBlur={() => editCarInfo('buyback_amount', watch('buyback_amount'), 'Importo riscatto leasing')}/>}
        {(!hideAll && isLeasing && !isMargin) &&
        <Select name={'buyback_fiscality'} options={buybackFiscality} ref={register} control={control}
                label={`Fiscalità diritto di riscatto*`} isClearable={false} onChange={(value) => {
            editCarInfo('buyback_fiscality', value[0].value, `Fiscalità diritto di riscatto`);
            return value
        }}/>}

        <div className={classes.buyBack}>
            <h4>Valore dopo la fiscalità <span>€ {buyback ? buyback : '-'}</span></h4>
        </div>

        {(!hideAll) && <h4 className={classes.sectionTitle}> Estinzione Finanziaria </h4>}
        {(!hideAll && (!isLeasing || isMargin)) &&
        <Text type={'number'} label={'Valore di estinzione'} name={'extinguishment_value'} ref={register}
              onBlur={() => editCarInfo('extinguishment_value', watch('extinguishment_value'), 'Valore di estinzione')}/>}
        {(!hideAll && isLeasing) &&
        <Text type={'number'} label={'Valore di riscatto leasing*'} name={'leasing_buyback_value'} ref={register} disabled={true} value={watch('buyback_amount')}/>}

        {(!hideAll) && <Select name={'finance'} options={financialServices} ref={register} control={control}
                               label={`Finanziaria`} isClearable={false} onChange={(value) => {
            editCarInfo('finance', value[0].value, `Finanziaria`);
            return value
        }}/>}
    </form>
};

export default FiscalityForm;
