import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import SubHeading from "../../Commons/SubHeading.js";
import {useHistory, useParams} from 'react-router-dom';
import Spinner from "../../Commons/Spinner.js";
import {
    httpGetCar,
    httpGetCarDamages,
} from "../../HttpRequests/commons.http.js";
import {useDispatch, useSelector} from "react-redux";
import Button from "../../Commons/Button.js";
import {
    CarIcon,
    FiscalityIcon, MarketValuesIcon, OtherCarInfosIcon,
    PhotoCameraIcon,
    VehicleConditionsIcon
} from "../../assets/icons.js";
import TabCard from "../../Commons/TabCard.js";
import CarInfoForm from "../../Components/Barter/CarInfoForm.js";
import FiscalityForm from "../../Components/Barter/FiscalityForm.js";
import OtherInfoForm from "../../Components/Barter/OtherInfoForm.js";
import BarterImagesUpload from "../../Components/Barter/BarterImagesUpload";
import DamagesFastSummary from "../../Components/Barter/DamagesFastSummary.js";
import DamagesGroupList from "../../Components/Barter/DamagesGroupList.js";
import {setCurrentBarter, setDamages} from "../../store/actions/bartersActions.js";
import {httpEditCarInfo} from "../../HttpRequests/barters.http.js";
import {
    clearDamagesForBackend,
    damagesIconsResolver,
    errorHandler,
    normalizeDamagesName,
} from "../../helpers/utils.js";
import Popover from "../../Commons/Popover.js";
import SavedBarter from "../../Components/Barter/SavedBarter.js";
import MarketValues from "../../Components/Barter/MarketValues.js";
import RecapSection from "../../Components/Barter/RecapSection.js";
import {alertToggle} from "../../store/actions/alertActions.js";
import eurotaxLogo from "../../assets/images/eurotax.jpg";
import ForceEurotaxVersion from "../../Components/Barter/ForceEurotaxVersion.js";
import BarterHistories from "../../Components/Barter/BarterHistories.js";

const useStyles = createUseStyles(theme => ({
    gridContainer: {
        'position': 'relative',
        // with big/small subheader like permute page
        height:`calc(100vh - ${theme.barterSubHeader + + theme.headerHeight}px)`,
        // without subheader
        //height: `calc(100vh - ${theme.headerHeight}px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        padding: ({isTablet}) => isTablet ? "32px 16px 0" : "32px 32px",
        [theme.s]: {
            padding: [theme.spacing],
        },
    },
    btns: {
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridColumnGap: theme.spacing,
        width: 600,
        justifySelf: 'end'
    },
    bottomHeading:{
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'auto 1fr',
    }
}));

const Barter = () => {
    const classes = useStyles({bigSubHeader: true});
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [seeAll, setSeeAll] = useState(false);
    const [damagesGroups, setDamagesGroup] = useState(null);
    const [showPopover, setShowPopover] = useState(false);
    const [eurotaxPopover, setEurotaxPopover] = useState(false);
    const [isDraft, setIsDraft] = useState(false);
    const barter = useSelector(state => state.barters.currentBarter);
    const damages = useSelector(state => state.barters.damages);
    //to check if we should show or not damages cards
    const canSeeDamagesDetail = barter?.examination?.summary;

    useEffect(() => {
        if (+barter?.id !== +params?.id) {
            //if the requested barter is different from the stored one
            dispatch(setCurrentBarter(null));
            //fetch data
            getBarter();
        }
        getDamages();
    }, []);

    const getBarter = async () => {
        setLoading(true);
        try {
            const {data: barter} = await httpGetCar(params?.id);
            //set barter and damages
            dispatch(setCurrentBarter(barter));
            dispatch(setDamages(barter?.examination ? barter?.examination.damages : []));
            //check if redirect is needed
            if (barter.status !== 'trade_in') {
                history.replace(`/barters-recap/${barter.id}`)
            }
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    };

    const getDamages = async () => {
        try {
            const {data: damagesGroup} = await httpGetCarDamages(params?.id);
            //console.log(damagesGroup);
            setDamagesGroup(damagesGroup)
        } catch (e) {
            errorHandler(e, dispatch)
        }
    };

    const saveBarter = async (isDraft) => {
        setLoading(true);
        setIsDraft(isDraft);
        const dataToSend = {
            car: {
                ...barter,
                is_draft: isDraft,
                withdrawal_price: isDraft ? barter.withdrawal_price : barter.withdrawal_price ? barter.withdrawal_price : (barter?.average_market_value - (barter?.total_costs))
            },
            damages: clearDamagesForBackend(damages),
            photosets: barter?.photosets
        };
        try {
            const {data: barter} = await httpEditCarInfo(dataToSend);
            //set barter and damages
            dispatch(setCurrentBarter(barter));
            dispatch(setDamages(barter?.examination ? barter?.examination.damages : []));
            //show popover
            setShowPopover(true);
            /*history.push('barters-recap/')*/
        } catch (e) {
            let message = "Sembra ci sia un errore di sistema. Riprova o contatta il supporto.";
            if (e.response.status === 500) return dispatch(alertToggle(message, 'error'));
            const errorObj = e.response.data;
            //horrible response from b-end here
            if (errorObj[0].photosets) {
                message = `Completa l'inserimento delle foto prima di concludere la permuta.`
            }
            if (errorObj[0].car) {
                if (Object.keys(errorObj[0].car).indexOf('fiscality') !== -1) {
                    message = `Completa l'inserimento dei dati relativi alla fiscalità.`
                } else if( Object.keys(errorObj[0].car).indexOf('family') !== -1) {
                    message = `L'inserimento del campo famiglia è obbligatorio.`
                } else {
                    message = `Ci risultano dati mancanti nel veicolo. Assicurati che tutti i campi contrassegnati con "*" siano inseriti e riprova.`
                }
            }
            dispatch(alertToggle(message, 'error'));
        } finally {
            setLoading(false);
        }
    };

    return <>
        {eurotaxPopover &&
        <Popover withHeader={false} minWidth={300} width={500} onClose={() => setEurotaxPopover(!eurotaxPopover)}
                 contextMode={false}>
            <ForceEurotaxVersion onClose={() => setEurotaxPopover(!eurotaxPopover)} car={barter}/>
        </Popover>}
        {showPopover &&
        <Popover withHeader={false} minWidth={300} width={500} onClose={() => setShowPopover(!showPopover)}
                 contextMode={false}>
            <SavedBarter onClose={() => setShowPopover(!showPopover)} isDraft={isDraft} barterId={barter?.id}/>
        </Popover>}

        <SubHeading
            title={barter ? `Valutazione ${barter?.model?.brand?.name} - ${barter?.model?.name}` : 'Valutazione -'}
            type={'barter'} justifySelf={'auto'}
            rightSearchComponent={!barter?.eurotax_version &&
            <Button data-small onClick={() => setEurotaxPopover(!eurotaxPopover)}>
                <img src={eurotaxLogo} width={50} alt={'logo eurotax'}/> force mode
            </Button>}
            filtersComponent={<div className={classes.bottomHeading}>
                {barter && <BarterHistories id={barter.id} plate={barter?.plate} status={barter?.status}/>}
                <div className={classes.btns}>
                    <Button data-secondary data-small
                            onClick={() => setSeeAll(!seeAll)}> {seeAll ? 'chiudi tutto' : 'apri tutto'} </Button>
                    <Button data-primary data-small onClick={(e) => saveBarter(true, e)}> salva come
                        bozza </Button>
                    <Button data-primary data-small onClick={(e) => saveBarter(false, e)}
                            disabled={barter?.status !== 'trade_in'}>
                        Concludi permuta
                    </Button>
                </div>
            </div>
            }/>

        <div className={classes.gridContainer}>
            {loading && <Spinner overlayFullscreen={true}/>}
            {barter && <>
                <TabCard cardTitle={'Dati veicolo *'} cardIcon={<CarIcon data-color/>} cardTitleSize={'medium'}
                         showContent={seeAll}>
                    <CarInfoForm car={barter} canEditField isBarter={true}/>
                </TabCard>
                <TabCard cardTitle={'Fiscalità'} cardIcon={<FiscalityIcon data-color/>} cardTitleSize={'medium'}
                         showContent={seeAll}>
                    <FiscalityForm car={barter}/>
                </TabCard>
                <TabCard cardTitle={'Condizioni veicolo'} cardIcon={<VehicleConditionsIcon data-color/>} cardTitleSize={'medium'}
                         showContent={seeAll}>
                    <DamagesFastSummary car={barter}/>
                </TabCard>
                {damagesGroups && Object.keys(damagesGroups).map((damagesGroup, index) => {
                    return <TabCard cardTitle={normalizeDamagesName(damagesGroup)} cardIcon={damagesIconsResolver(damagesGroup)}
                                    cardTitleSize={'medium'} showContent={seeAll} key={index}
                                    isDisabled={canSeeDamagesDetail}>
                        <DamagesGroupList damagesGroup={damagesGroups[damagesGroup]} damages={damages} car={barter}/>
                    </TabCard>
                })}
                <TabCard cardTitle={'Info aggiuntive'} cardIcon={<OtherCarInfosIcon data-color/>} cardTitleSize={'medium'}
                         showContent={seeAll}>
                    <OtherInfoForm car={barter}/>
                </TabCard>
                <TabCard cardTitle={'Immagini *'} cardIcon={<PhotoCameraIcon data-color/>} cardTitleSize={'medium'}
                         showContent={seeAll}>
                    <BarterImagesUpload car={barter}/>
                </TabCard>
                <TabCard cardTitle={'Valori di mercato *'} cardIcon={<MarketValuesIcon data-color/>} cardTitleSize={'medium'}
                         showContent={seeAll}>
                    <MarketValues car={barter} isRecap={false}/>
                </TabCard>
                <RecapSection barter={barter} isRecap={false} callback={saveBarter}/>
            </>}
        </div>
    </>
};

export default Barter;
