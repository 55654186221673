import React, {useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useDispatch} from 'react-redux';
import {useForm} from 'react-hook-form';
import Spinner from "../../Commons/Spinner";
import Text from "../../Commons/Text";
import Select from "../../Commons/Select.js";
import { retrieveValueForRs, salesChannels} from "../../helpers/utils.js";
import {alertToggle} from "../../store/actions/alertActions";
import {errorHandler} from "../../helpers/utils";
import {httpEditStockInfo} from "../../HttpRequests/stock.http";
import {setCurrentCar} from "../../store/actions/carsActions";

const useStyles = createUseStyles(theme => ({
    root: {
        position: 'relative',
        'display': 'grid',
        'grid-auto-flow': 'row',
        'grid-row-gap': theme.spacing * 3,
        'grid-column-gap': theme.spacing * 5,
        [theme.s]: {
            width: 320,
            gridTemplateColumns: '1fr',
            justifyContent: "center",
            justifyItems: "center",
            alignItems: "end"
        },
        [theme.mUp]: {
            'width': '100%',
            gridTemplateColumns: 'repeat(4, 1fr)',
            justifyContent: "center",
            justifyItems: "center",
            alignItems: "end"
        },
    },
    sectionTitle: {
        fontWeight: 600,
        textAlign: "left",
        gridColumnStart: 1,
        gridColumnEnd: 5,
        fontSize: 16,
        padding: [theme.spacing * 2, 0, 0],
        borderTop: `1px solid ${theme.colors.lightGrey}`
    }
}));

const SellPricingForm = ({car, isStock = false,isNewCar=false}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const {register, watch, control} = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            ...car,
            sales_channel: retrieveValueForRs(salesChannels, car?.sales_channel),
        },
    });
    const watchSalesChannels = watch("sales_channel") ? watch("sales_channel")[0]?.value : null;

    const editCarInfo = async (fieldName, value, label) => {
        if ((value === null || value === '') || car[fieldName]?.toString() === value.toString()) return;
        setIsLoading(true);
        const dataToSend = {
            car:{
                ...car,
                [fieldName]: value,
                ...(isNewCar && {is_draft: true}),
            },
        }

        try {
            const {data : currentCar} = await httpEditStockInfo(dataToSend,true);
            dispatch(setCurrentCar(currentCar));
            dispatch(alertToggle(`Il campo ${label} è stato modificato correttamente.`, 'success'))
        } catch (e) {
            errorHandler(e,dispatch)
        }finally {
            setIsLoading(false);
        }
    };

    return <form className={classes.root}>
        {isLoading && <Spinner/>}

        <div className={classes.sectionTitle} style={{padding:0}}/>

        <Select name={'sales_channel'} options={salesChannels} ref={register} control={control}
                label={`Destinazione`} isClearable={false} onChange={(value) => {
            editCarInfo('sales_channel', value[0].value, `Destinazione`);
            return value
        }}/>

        {(watchSalesChannels === 'b2c') && <Text disabled type={'text'} name={'warranty_type'} label={'Garanzia'} ref={register}/>}

        <Text type={'number'} label={'Prezzo di vendita'} name={'price'} ref={register}
              onBlur={() => editCarInfo('price', watch('price'), 'Prezzo di vendita')}/>
    </form>
};

export default SellPricingForm;
