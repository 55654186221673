import React, { useState, useCallback, useEffect } from 'react';
import {createUseStyles} from 'react-jss';
import {useDispatch} from 'react-redux';
import {useDropzone} from 'react-dropzone';
import Button from "../../Commons/Button";
import ProgressBar from "../../Commons/ProgressBar";
import {httpGetBarterPhotosAutocomplete, httpGetSignedS3Url, httpPostS3} from "../../HttpRequests/commons.http";
import {alertToggle} from "../../store/actions/alertActions";
import PropTypes from "prop-types";
import {errorHandler, filterBarterPhotoset} from "../../helpers/utils.js";
import {addBarterPhoto, deleteBarterPhoto} from "../../store/actions/bartersActions";
import Spinner from "../../Commons/Spinner";
import {RemoveIcon} from "../../assets/icons";
import {httpConfirmPhotoUpload, httpDeletePhoto} from "../../HttpRequests/barters.http";

const useStyles = createUseStyles(theme => ({
	root: {
		position: 'relative',
		'display': 'grid',
		'grid-auto-flow': 'row',
		'grid-row-gap': theme.spacing * 3,
		'grid-column-gap': theme.spacing * 3,
		[theme.s]: {
			width: 320,
			gridTemplateColumns: 'repeat(2,1fr)',
			justifyContent: "center",
		},
		[theme.mUp]: {
			'width': '100%',
			gridTemplateColumns: 'repeat(6,1fr)',
			justifyContent: "center",
		},
	},
	uploadBox: {

	},
	uploadArea: {
		position: 'relative',
		display: 'grid',
		backgroundColor: 'transparent',
		borderRadius: '16px',
		border: '1px dashed #000',
		minHeight: '160px',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		padding: '8px',
		alignItems: 'center',
		justifyItems: 'center'
	},
	contextMenu: {
		position: 'absolute',
		top: '8px',
		right: '8px',
		'&:first-child': {
			backgroundColor: theme.colors.body,
			borderRadius: '50%',
			transform: 'rotate(90deg)'
		}
	},
	captionBox: {
		margin: '8px 0 0 0'
	},
	label: {
		...theme.control.label,
		fontSize: 14,
		fontWeight: '500'
	},
	overlay: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		/*padding: theme.spacing * 4,*/
		borderRadius: "16px",
		zIndex: 1,
		backgroundColor: theme.colors.overlay,
		display: 'grid',
		alignItems: 'center',
		'& div': {
			textAlign: 'center',
			'& span':{
				padding: [theme.spacing, theme.spacing * 2],
				'&:not(:last-child)': {
					borderRight: '1px solid #fff'
				}
			}
		}
	},
	icon: {
		fill: `${theme.colors.white} !important`,
		'&:hover': {
			animation: '$zoominout .5s',
			cursor: 'pointer'
		}
	}

}));

// Remove photoTypes not included for barter
export const excludePhotoTypes = (options) => {
	return options.filter((item) => (!item.label.includes('360') && !['Dettaglio 1','Dettaglio 2','Dettaglio 3'].includes(item.label)))
};

const BarterImagesUpload = ({car, readOnly = false}) => {

	const classes = useStyles();
	const dispatch = useDispatch();
	//filter only photoset with is_barter = 1
	const barterPhotoset = filterBarterPhotoset(car?.photosets);

	const [autocompletes, setAutocompletes] = useState({
		photoTypes: []
	});
	const [loading, setLoading] = useState(false);

	// Fetch autocomplete and photoset
	useEffect(() => {
		getAutocompletes();
	}, []);

	const getAutocompletes = async () => {
		setLoading(true);
		try {
			const {data: photoTypes} = await httpGetBarterPhotosAutocomplete();
			setAutocompletes( { photoTypes: excludePhotoTypes(photoTypes) })
		} catch (e) {
			errorHandler(e,dispatch)
		}finally{
			setLoading(false);
		}
	};

	return <div className={classes.root}>
		{loading && <Spinner size={24}/>}
		{car?.id && barterPhotoset && autocompletes?.photoTypes.map( ({label, value}, i) => {
			return <ImageDropzone key={i}
								  caption={label}
								  photoType={value}
								  car={car}
								  photoset={barterPhotoset}
								  readOnly={readOnly}/>
		})}
	</div>
};

export default BarterImagesUpload;

BarterImagesUpload.propTypes = {
	car: PropTypes.object.isRequired,
	readOnly: PropTypes.bool
};

const ImageDropzone = ({car, readOnly, photoType, caption, photoset})  => {

	const MAX_SIZE = 1048576
	const SIGNED_URL_TYPE = 'photo'

	const classes = useStyles();
	const dispatch = useDispatch();
	const [progress, setProgress] = useState(0);
	const [inUpload, setInUpload] = useState(false);
	const [photo, setPhoto] = useState(null);
	const [showOverlay, setShowOverlay] = useState(false);

	// Fetch initial photo
	useEffect(() => {
		const currentPhoto = photoset?.photos?.filter( photo => photo.type === photoType)?.sort((a, b) => Date.parse(b.updated_at) - Date.parse(a.updated_at))[0]
		setPhoto(currentPhoto);
	}, []);


	// upload only on aws
	const handleUpload = async (file) => {
		setInUpload(true);
		try {
			const {data: { attributes : { action, enctype }, inputs}} = await httpGetSignedS3Url(file.name, SIGNED_URL_TYPE);
			// set header
			const options = {
				headers: {
					'Content-Type': enctype,
				},
				onUploadProgress: progressEvent => {
					const status = parseInt((progressEvent.loaded / progressEvent.total) * 100)
					if (status > 1) {
						setProgress(parseInt((progressEvent.loaded / progressEvent.total) * 100))
					}
				}
			};
			// Append inputs to form
			let formData = new FormData();
			for (const key in inputs) {
				formData.append(key, inputs[key]);
			}
			formData.append('file', file);
			await httpPostS3(action, formData, options);

			const updatedPhoto = {
				type: photoType,
				url: `${action}/${inputs.key}`
			}

			const { data: uploadedPhoto } = await httpConfirmPhotoUpload(car.id, updatedPhoto.url, updatedPhoto.type,  photoset.id);
			setPhoto(uploadedPhoto);
			dispatch(addBarterPhoto({
				...uploadedPhoto,
				photoset_id: photoset.id
			}))
			dispatch(alertToggle('Foto caricata con successo','success'))

		} catch(e) {
			dispatch(alertToggle(e.message,'error'))
		}finally {
			setInUpload(false);
		}
	}

	//remove from store only
	const deletePhoto = async (photoId) => {
		try {
			await httpDeletePhoto(car.id, photoset.id, photoId);
			dispatch(deleteBarterPhoto({
				id: photoId,
				photoset_id: photoset.id
			}))
			setPhoto(null);
			dispatch(alertToggle('Foto eliminata con successo','success'))
		} catch (e) {
			dispatch(alertToggle(e.message,'error'))
		}
	}

	const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
		// if photo exists
		if(photo?.id) {
			dispatch(alertToggle('Cancella la foto esistente per caricarne una nuova.','warning'));
			return;
		}
		acceptedFiles.map(file => {
			const uploadResult = handleUpload(file);
		})
	}, [photo, photoset]);


	const {getRootProps, getInputProps, isDragActive, isDragReject, open} = useDropzone({
		accept: 'image/*',
		noClick: true,
		multiple: false,
		minSize: 0,
		MAX_SIZE,
		onDrop
	});

	const handleMouseHover = (show = false) => {
		setShowOverlay(show);
	}

	return (
		<div className={classes.uploadBox}>
			{!readOnly ?
				<div {...getRootProps({className: 'dropzone'})} className={classes.uploadArea}
					 onMouseEnter={() => handleMouseHover(true)}
					 onMouseLeave={() => handleMouseHover(false)}
							 style={photo?.url && {'backgroundImage': `url("${photo?.url}")`, 'border': 'none'}}>
					{showOverlay && photo?.url && <div className={classes.overlay}>
						<div>
							<span>
								<RemoveIcon className={classes.icon} onClick={() => deletePhoto(photo?.id)}/>
							</span>
						</div>
					</div>}
					{inUpload && <ProgressBar small percentCompleted={progress} showPercent={false}/>}
					{!photo?.url && !inUpload  && <div>
						<input {...getInputProps()} />
						{!isDragActive &&
						<div>
							<Button data-primary data-small onClick={open}>Carica</Button>
						</div>}
						{isDragActive && !isDragReject && <p>Rilascia qui...</p>}
						{isDragReject && <small>File non accettato</small>}
					</div>}
				</div>
				:
				<div className={classes.uploadArea}
					 style={photo?.url && {'backgroundImage': `url(${photo?.url})`, 'border': 'none'}}>
				</div>}
			{caption && <div className={classes.captionBox}>
				<label className={classes.label}>{caption}</label>
			</div>}
		</div>
	)
}

ImageDropzone.propTypes = {
	readOnly:PropTypes.bool,
	photoType: PropTypes.string.isRequired,
	caption: PropTypes.string,
	car: PropTypes.object.isRequired,
	photoset: PropTypes.object.isRequired
};