import React, {useState} from 'react';
import {createUseStyles} from 'react-jss';
import SidebarNavLink from "../../Commons/SidebarNavLink";
import Popover from "../../Commons/Popover.js";
import NewBarterStepsModal from "../Barters/NewBarterStepsModal.js";
import {AddIcon} from "../../assets/icons.js";
import Button from "../../Commons/Button.js";

const useStyles = createUseStyles((theme) => ({
	voices: {
		zIndex:2,
		display: ({collapsedNav}) => collapsedNav ? "none" : "grid",
		color: theme.colors.white,
		fontWeight: 500
	},
	btns: {
		margin: '16px 0'
	}
}));


const BarterNavigation = ({collapsedNav}) => {
	const classes = useStyles({collapsedNav});
	const [showPopover, setShowPopover] = useState(false);

	return <>
		{showPopover &&
		<Popover withHeader={false} minWidth={550} onClose={() => setShowPopover(!showPopover)} contextMode={false}>
			<NewBarterStepsModal onClose={() => setShowPopover(!showPopover)}/>
		</Popover>}
		<div className={classes.voices}>
			<SidebarNavLink to={'/barters'}>Lista permute</SidebarNavLink>
			<Button className={classes.btns} data-transparent icon={<AddIcon/>} onClick={() => setShowPopover(!showPopover)}> Inserisci Permuta </Button>
		</div>
	</>
};

export default BarterNavigation;
