import React from 'react';
import {useSelector} from 'react-redux';
import {createUseStyles} from 'react-jss';
import {Checked} from '../assets/icons';
import theme from '../theme';
import PropTypes from 'prop-types';

const useStyles = createUseStyles((theme) => ({
    stepBar: {
        width: "100%",
        maxWidth: "310px",
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr auto",
        margin: "0 auto"
    },
    itemWithBar: {
        position: "relative",
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        alignItems: 'center',
        justifyItems: 'stretch',
    },
    dot: {
        zIndex: 1,
        display: 'grid',
        height: theme.spacing * 4,
        alignItems: 'center',
        justifyItems: 'center'
    },
    stepDivider: {
        height: 3,
        width: '100%',
        minWidth: theme.spacing * 5,
        backgroundColor: theme.colors.lightGrey,
        borderColor: theme.colors.lightGrey,
        marginRight: 0
    },
    spacer: {
        width: theme.spacing * 5
    },
    itemTitle: {
        marginTop: theme.spacing,
        gridColumn: '1/4',
        '& p': {
            fontSize: 11,
            position: 'absolute',
            bottom: -10,
            left: -4
        }
    },
    icon: {
        width: `16px!important`,
        fill: theme.colors.white
    },
    repair: {
        position: 'absolute',
        bottom: -4,
        left: `-32px!important`,
    },
    docs: {
        position: 'absolute',
        bottom: -4,
        left: `-14px!important`,
    },
    photos:{
        position: 'absolute',
        bottom: -4,
        left: `-2px!important`,
    },
    photosClosed:{
        position: 'absolute',
        bottom: -4,
        left: `8px!important`,
    }
}));

const getDotStyle = (status) => ({
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
    textAlign: 'center',
    width: status === 'closed' ? 28 : 10,
    height: status === 'closed' ? 28 : 10,
    backgroundColor:
        status === 'closed' ? theme.colors.green : status === 'active' ? theme.colors.yellow : theme.colors.lightGrey,
    color: 'white',
    borderRadius: 100,
    margin: 2
});

const getDotOutsideStyle = (status) => ({
    verticalAlign: 'center',
    width: status === 'closed' ? 34 : status === 'active' ? 16 : 15,
    height: status === 'closed' ? 34 : status === 'active' ? 16 : 15,
    backgroundColor: 'white',
    color: 'white',
    borderRadius: 100,
    border: status === 'closed' || status === 'active' ? '1px solid' : '0 none',
    borderColor:
        status === 'closed' ? theme.colors.green : status === 'active' ? theme.colors.yellow : theme.colors.lightGrey,
});

const StepProgress = () => {
    //add onSelectStep to params to pass to parent clicking action
    const classes = useStyles({});
    const tasks = useSelector((state) => state.cars.carTasks); //tasks loading from Redux
    //filtering essential tasks or givin empty array vals:
    const essentialTasks = tasks.filter((el) => el.physical && el.essential && el.type !== 'estimate');
    const emptyEssentialSelected = [
        {label: 'Lavaggio', status: 'open'},
        {label: 'Fotografie', status: 'open'},
        {label: 'Ricondizionamento', status: 'open'}
    ];
    const essentialCarTasks = essentialTasks.length ? essentialTasks : emptyEssentialSelected;
    const backofficeCarTasks = tasks.filter((el) => !el.physical);
    //console.log('backofficeCarTasks', backofficeCarTasks);
    const someDocumentationCheck = backofficeCarTasks.length
        ? Object.values(backofficeCarTasks).some((el) => el.status === 'closed' || el.status === 'active')
        : false; //if at least one is closed
    const allDocumentationCheck = backofficeCarTasks.length
        ? Object.values(backofficeCarTasks).every((el) => el.status === 'closed')
        : false; //all are closed

    return (
        <div className={classes.stepBar}>
            {essentialCarTasks.map(({type, status, label}, index) => {
                if (type !== 'assign_transport' && type !== 'internal_transport' && type !== 'external_transport' && type !== 'estimate') {
                    return (
                        <div className={classes.itemWithBar} key={index}>
                            <div className={classes.dot}>
                                <div style={getDotOutsideStyle(status)}>
                                    <div style={getDotStyle(status)}>
                                        {status === 'closed' ? <Checked className={classes.icon}/> : ''}
                                    </div>
                                </div>
                            </div>

                            {index !== 4 && <div className={classes.stepDivider}/>}
                            <div className={classes.itemTitle}>
                                <p className={label === 'Ricondizionamento' ? classes.repair :
                                    (label === 'Fotografie' && status === 'closed') ? classes.photosClosed :
                                        (label === 'Fotografie' && status !== 'closed') ? classes.photos : ''}>
                                    {label === 'Fotografie' ? 'Foto' : label}
                                </p>
                            </div>
                        </div>
                    );
                }
            })}
            <div className={classes.itemWithBar}>
                <div className={classes.dot}>
                    <div style={getDotOutsideStyle(
                        allDocumentationCheck ? 'closed' : someDocumentationCheck ? 'active' : 'open')}>
                        <div style={getDotStyle(
                            allDocumentationCheck ? 'closed' : someDocumentationCheck ? 'active' : 'open')}>
                            {allDocumentationCheck && <Checked className={classes.icon}/>}
                        </div>
                    </div>
                </div>
                <div className={classes.itemTitle}>
                    <p className={classes.docs}>Documenti</p>
                </div>
            </div>
        </div>
    );
};

StepProgress.propTypes = {
    selectedArr: PropTypes.array
};

export default StepProgress;
