import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {createUseStyles} from 'react-jss';
import {useDispatch, useSelector} from 'react-redux';
import Snackbar from "../Commons/Snackbar.js";
import HeaderForExternals from "../Commons/HeaderForExternals";
import {me} from "../store/actions/userActions.js";
import {useLocation} from "react-router-dom";
import FloatingBtns from "../Commons/FloatingBtns.js";
import ChatButton from "../Pages/messageCenter/ChatButton";

const useStyles = createUseStyles(theme => ({
	root: {
		display: "grid",
		gridTemplateRows: "60px auto",
		'align-items': 'start',
		'grid-template-areas': '"header" "main"',
		'grid-template-columns': '1fr',
	},
	main: {
		backgroundColor: theme.colors.smokeWhite
	},
}));

const PrivateExternalLayout = ({children}) => {
	const alert = useSelector(state => state.alerts);
	const classes = useStyles();
	const dispatch = useDispatch();
	const {pathname} = useLocation();

	useEffect(() => {
		dispatch(me());
	},[pathname])

	return (
		<main className={classes.root}>
			<Snackbar content={alert?.message} level={alert?.level} right={true}/>
			<HeaderForExternals className={classes.header}/>
			<div className={classes.main}>
				{children}
			</div>
			<FloatingBtns/>
			<ChatButton/>
		</main>
	);
};

PrivateExternalLayout.propTypes = {
	children: PropTypes.any,
};

export default PrivateExternalLayout;
