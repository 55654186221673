import React, {useCallback, useState} from 'react';
import {createUseStyles} from "react-jss";
import {useForm} from "react-hook-form";
import {useParams} from 'react-router-dom';
import Button from "../../Commons/Button.js";
import * as Yup from "yup";
import {httpGetSignedS3Url, httpPostS3} from "../../HttpRequests/commons.http.js";
import {alertToggle} from "../../store/actions/alertActions.js";
import {useDropzone} from "react-dropzone";
import {useDispatch} from "react-redux";
import Spinner from "../../Commons/Spinner.js";
import {errorHandler, formattedDate} from "../../helpers/utils.js";
import {
    httpSaveEstimateDocument,
    httpSaveRepairDocument,
    httpUpdateEstimateDocument, httpUpdateRepairDocument
} from "../../HttpRequests/reconditioning.http.js";
import {EditIcon} from "../../assets/icons.js";
import {setEstimateAttachment, setRepairAttachment} from "../../store/actions/repairsActions.js";

const useStyles = createUseStyles(theme => ({
    root: {
        display: 'grid',
        width: '100%',
        gridRowGap: theme.spacing * 3,
        justifyItems: 'center',
        '& button': {
            marginTop: theme.spacing * 3
        }
    },
    title: {
        fontWeight: 600,
        margin: [theme.spacing * 2, 0]
    },
    btns: {
        width: "300px",
        display: "grid",
        alignItems: "center",
        gridColumnGap: theme.spacing,
        gridTemplateColumns: "1fr 1fr"
    },
    uploadArea: {
        width: 200,
        display: "grid",
        alignItems: "center",
        gridRowGap: theme.spacing,
        justifyContent: "start"
    },
    doc:{
        display: "grid",
        alignItems: "center",
        gridTemplateColumns: 'auto 24px',
        gridColumnGap: 4,
        '& a':{
            fontSize: 12,
            color: theme.colors.darkGrey,
            textDecoration: 'underline'
        },
        '& svg':{
            width: '18px!important'
        }
    }
}));

const InlineDocumentUpload = ({type, data, list, attachment,repairRowId = null,isExternal}) => {
    const MAX_SIZE = 1048576;
    const SIGNED_URL_TYPE = 'document';
    const {id: carId} = useParams();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [inUpload, setInUpload] = useState(false);

    // carica solo su aws
    const handleUpload = async (file) => {
        setInUpload(true);
        try {
            const {data: {attributes: {action, enctype}, inputs}} = await httpGetSignedS3Url(file.name, SIGNED_URL_TYPE);
            // set header
            const options = {
                headers: {
                    'Content-Type': enctype,
                },
                onUploadProgress: progressEvent => {
                    //todo add progressbar
                    /*console.log(progressEvent.loaded / file.size * 100)
                     const status = parseInt((progressEvent.loaded / progressEvent.total) * 100);
                     if (status > 1) {
                         setProgress(parseInt((progressEvent.loaded / progressEvent.total) * 100))
                     }*/
                }
            };
            // Append inputs to form
            let formData = new FormData();
            for (const key in inputs) {
                formData.append(key, inputs[key]);
            }
            formData.append('file', file);
            const postS3 = await httpPostS3(action, formData, options);
            // set updatedDocument to store and component state
            const updatedDocument = `${action}/${inputs.key}`;
            //todo: create doc obj and add to array to send wiht save action
            //console.log(updatedDocument,type,file,data);
            await saveDocument({
                url: updatedDocument,
                type: type,
                filename: file.name,
                id: data.id,
                attachment_id: attachment ? attachment.id : null,
            })
        } catch (e) {
            dispatch(alertToggle(e.message, 'error'))
        } finally {
            setInUpload(false);
        }
    };

    const saveDocument = async (dataToSave) => {
        /*console.log(attachment)
        console.log(dataToSave)*/
        try {
            if (list === 'estimates') {
                const {data: documentBack} = attachment?.id ? await httpUpdateEstimateDocument(carId, dataToSave) : await httpSaveEstimateDocument(carId, dataToSave);
                dispatch(setEstimateAttachment(documentBack));
            }
            if (type === 'unexpected_repairs' || type === 'invoice') {
                const {data: document} = attachment?.id ? await httpUpdateRepairDocument(carId, {...dataToSave,repair_row_id: repairRowId}) : await httpSaveRepairDocument(carId, {...dataToSave,repair_row_id: repairRowId});
                dispatch(setRepairAttachment(document));
            }

            //setDocumentsCb(data?.id,document);
            dispatch(alertToggle(`Documento ${attachment?.id ? "modificato" : "caricato"} con successo`, 'success'));
        } catch (e) {
            errorHandler(e, dispatch)
        }
    };

    /*const deleteDocument = async () => {
        setLoading(true);
        setActionsToggle(false);
        try {
            await httpDeleteCarDocuments(id, {...doc});
            onDeleteDocumentCb({...doc});
            dispatch(alertToggle('Documento cancellato con successo', 'success'));
        } catch (e) {
            errorHandler(e, dispatch)
        }finally {
            setLoading(false);
        }
    };*/

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        // upload new document on AWS
        acceptedFiles.map(file => {
            const uploadResult = handleUpload(file);
        })
    }, [attachment]);

    const {getRootProps, getInputProps, isDragActive, isDragReject, open} = useDropzone({
        accept: '/image* , .pdf',
        noClick: true,
        minSize: 0,
        multiple: false,
        MAX_SIZE,
        onDrop
    });

    return <div {...getRootProps({className: 'dropzone'})} className={classes.uploadArea}>
        <>
            <input {...getInputProps()} />
            {(!inUpload && !attachment && !isExternal) && <Button data-primary data-xs-small onClick={open}>Carica Documento</Button>}
            {inUpload && <Spinner size={14}/>}
            {(attachment && !inUpload) &&
            <div className={classes.doc}>
                <a href={attachment?.url} target={'_blank'}>{attachment?.filename ? attachment.filename : 'Documento'}</a>
                <EditIcon data-color onClick={open}/>
            </div>}
        </>
    </div>
};

export default InlineDocumentUpload;