import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useForm } from 'react-hook-form';
import Button from "../../Commons/Button.js";
import Text from "../../Commons/Text.js";
import cx from "classnames";
import Select from "../../Commons/Select.js";
import {retrieveValueForRs} from "../../helpers/utils.js";
import * as Yup from "yup";

const useStyles = createUseStyles(theme => ({
    root: {
        position: 'relative',
        'display': 'grid',
        'grid-column-gap': theme.spacing * 2,
        marginBottom: theme.spacing * 4,
        alignItems: "center",
        padding: [0],
        [theme.s]: {
            width: 320,
            gridTemplateColumns: '1fr',
            alignItems: "center"
        },
        [theme.mUp]: {
            'width': '100%',
            gridTemplateColumns: ({subtractOnly}) => subtractOnly ? 'auto auto 180px 120px auto' : 'auto auto 180px 120px 1fr',
            alignItems: "center"
        },
        '& button':{
            height: `40px!important`
        }
    },
    confirmButton: {
        justifySelf: "end",
        marginLeft:  ({subtractOnly}) => subtractOnly ? 0 : theme.spacing * 3,
    },
    disabled: {
        backgroundColor: `${theme.colors.grey}!important`,
        color: `${theme.colors.white}!important`,
    }
}));

const operations = [{label:'Aggiungi',value: 'add'},{label:'Diminuisci',value: 'subtract'}];
const validationSchema = Yup.object().shape({
    operation: Yup.string()
        .nullable()
        .required(`Scegli un tipo di operazione.`),
    base_price: Yup.number()
        .integer('Only intergers are accepted.')
        .typeError('Sono accettati solo valori numerici.')
        .min(1,'Inserisci un valore maggiore di 0')
        .transform((value, originalValue) => originalValue.trim() === "" ? null: value)
});

const RepriceForm = ({ repriceCb, subtractOnly, className,selectionIsNotEmpty }) => {
    const [currentType, setCurrentType] = useState('fixed');
    const classes = useStyles({ subtractOnly, currentType });
    const { handleSubmit, register, control, formState:{isValid, touched} ,watch, reset, errors } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            base_price: 0,
            operation: subtractOnly ? retrieveValueForRs(operations,'subtract') : '',
        },
        validationSchema
    });

    return <>
        <form className={cx(classes.root, className)} onSubmit={handleSubmit(() => repriceCb({...watch(),currentType}))}>
            <Button className={(currentType === 'percentage' || !selectionIsNotEmpty) ? classes.disabled : ''}
                data-primary data-small onClick={() => {setCurrentType('fixed'); reset()}}> <strong style={{fontSize: 18}}>€</strong> </Button>
            <Button data-primary data-small className={(currentType === 'fixed' || !selectionIsNotEmpty) ? classes.disabled : ''}
                    onClick={() => {setCurrentType('percentage'); reset()}}> <strong style={{fontSize: 18}}> % </strong> </Button>
            <Select name={'operation'} options={operations} ref={register} control={control}
                    errors={errors.operation} placeholder={'Operazione'}
                    disabled={subtractOnly || !selectionIsNotEmpty} label={``} isClearable={false}/>
            <Text type='number' min={0} name={'base_price'} ref={register} errors={errors.base_price} disabled={!selectionIsNotEmpty}/>
            <Button type={'submit'} data-primary className={classes.confirmButton}
                    disabled={(!isValid && touched) || (!selectionIsNotEmpty)}> Conferma Reprice </Button>
        </form>
    </>
};

export default RepriceForm;
