import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {clearFilters, errorHandler, formattedDate, getLabelByValue, taskStatuses} from "../../helpers/utils";
import Table from "../../Commons/Table";
import {useDispatch} from "react-redux";
import {httpGetTaskType} from "../../HttpRequests/tasks.http.js";
import Badge from "../../Commons/Badge.js";
import ExternalSubHeading from "../../Commons/ExternalSubHeading.js";
import moment from 'moment'

const useStyles = createUseStyles(theme => ({
    gridContainer: {
        'position': 'relative',
        overflowY: 'scroll',
        overflowX: 'hidden',
        display: 'grid',
        gridTemplateColumns: '1fr',
        [theme.mUp]: {
            padding: [theme.spacing * 4, theme.spacing * 4, 0],
        },
        [theme.s]: {
            padding: [theme.spacing],
        },
    },
}));

const taskStatusColors = {
    open: 'primary',
    active: 'warning',
    closed: 'success'
};

//open, active, closed
const array = [];

for (let x = 1; x <= 50; x++) {
    array.push({
        brand: 'Toyota',
        model: 'Vios',
        plate: 'AG877VH',
        repair_assign_date: new Date(),
        status: 'open',
        id: x
    })
}

const ExternalSuppliers = () => {
    const dispatch = useDispatch();
    const [suppliesrsTasks, setSuppliesrsTasks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({});
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(null);
    const classes = useStyles();

    const getWorks = async (actualPage = 1) => {
        if (lastPage && actualPage > lastPage) return;
        setLoading(true);
        clearFilters(filters);
        try {
            const {data: {data: tasks, last_page}} = await httpGetTaskType('my_tasks', {
                limit: 20, ...filters,
                page: actualPage
            });
            if (actualPage === 1) {
                setSuppliesrsTasks(tasks);
            } else {
                setSuppliesrsTasks(suppliesrsTasks.concat(tasks));
            }
            setLastPage(last_page)
        } catch (e) {
            errorHandler(e, dispatch);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getWorks(1);
    }, []);

    //fetch when filters change
    useEffect(() => {
        if (!!Object.keys(filters).length) {
            setPage(1);
            setLastPage(null);
            getWorks(1);
        }
    }, [filters]);

    //fetch when page change
    useEffect(() => {
        if (!!suppliesrsTasks.length && page !== 1) {
            getWorks(page)
        }
    }, [page]);

    const onChangePage = (isFilter = false) => {
        if (isFilter) {
            setPage(1)
        } else {
            setPage(page + 1)
        }
    };

    const onDataSorting = (newSort) => {
        setFilters({
            ...filters,
            ...newSort,
        });
        setPage(1);
    };

    const onRowClickPath = (item) => {
        return `/external-workers/${item?.car_id}`;
    };

    return <>
        <ExternalSubHeading title={"Elenco lavorazioni"} justifySelf={'unset'}/>
        <div className={classes.gridContainer}>
            <Table
                id={'table'}
                data={suppliesrsTasks}
                inLoading={loading}
                filters={filters}
                onSetPageCb={onChangePage}
                onSortingDataCb={onDataSorting}
                theadHeight={40}
                rowMinHeight={40}
                classNames={{
                    table: classes.table,
                    thead: classes.thead,
                    row: classes.row,
                }}
                //real name is created_at not date
                columns={['brand_name', 'model_name', 'plate', 'deadline', 'status']}
                columnsNames={{
                    brand_name: 'Marca',
                    model_name: "Modello",
                    plate: "Targa",
					deadline: 'Data scadenza',
                    status: "Stato",
                }}
                columnsWidths={{
                    brand_name: '15%',
                    model_name: "15%",
                    plate: "15%",
					deadline: "45%",
                    status: "10%",
                }}
                backEndSortable={true}
                sortable={true}
                headersSorters={['deadline', 'status']}
                columnsRenderers={{
					deadline: (value) => {
						let expired = false;
                    	if(value){
							expired = moment(value)?.isSameOrAfter(new Date());
						}
                        return <div className={classes.doubleRow}>
                            <span style={{color: expired ? 'red' : 'inherit'}}> {formattedDate(value)} </span>
                        </div>
                    },
                    status: (value) => <Badge title={value && getLabelByValue(taskStatuses, value)}
                                              statusColor={taskStatusColors[value]} badgeSize={'small'}/>,
                    plate: (value) => <span style={{textTransform: 'uppercase'}}>{value}</span>
                }}
                onRowClickPath={onRowClickPath}
            />
        </div>
    </>
};

export default ExternalSuppliers;

