import {
    HIDE_TYPING_INDICATOR,
    SHOW_TYPING_INDICATOR,
    TYPING_INDICATOR_DURATION_SECONDS
} from "../../../helpers/constants/chat";
import {PresenceCategory} from "pubnub-redux";

export const REMOVE_TYPING_INDICATOR = "REMOVE_TYPING_INDICATOR";
export const REMOVE_TYPING_INDICATOR_ALL = "REMOVE_TYPING_INDICATOR_ALL";


export const typingIndicatorRemoved = (payload) => ({
    type: REMOVE_TYPING_INDICATOR,
    payload
});

export const typingIndicatorRemovedAll = (payload) => ({
    type: REMOVE_TYPING_INDICATOR_ALL,
    payload
});


/* Listener */
export const createTypingIndicatorsListener = (dispatch) => ({
    signal: (payload) => {
        if (payload.message.type === SHOW_TYPING_INDICATOR) {
            // hide indicator after display seconds
            setTimeout(() => {
                dispatch(
                    typingIndicatorRemoved({
                        userId: payload.publisher,
                        channel: payload.channel,
                        timetoken: payload.timetoken
                    })
                );
            }, TYPING_INDICATOR_DURATION_SECONDS * 1000);
        } else if (
            payload.message.type === HIDE_TYPING_INDICATOR
        ) {
            // hide indicator now, removes all for user regardless of time token
            dispatch(
                typingIndicatorRemovedAll({
                    userId: payload.publisher,
                    channel: payload.channel
                })
            );
        }
    },
    presence: (payload) => {
        if (
            payload.action === PresenceCategory.LEAVE ||
            payload.action === PresenceCategory.TIMEOUT
        ) {
            dispatch(
                typingIndicatorRemovedAll({
                    userId: payload.uuid,
                    channel: payload.channel
                })
            );
        }
    }
});