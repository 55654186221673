import React, {forwardRef} from 'react';
import {createUseStyles, useTheme} from 'react-jss';
import cx from 'classnames';
import {Controller} from 'react-hook-form';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import {retrieveValueForRs} from '../helpers/utils.js';
import CreatableSelect from 'react-select/creatable';

const useStyles = createUseStyles((theme) => ({
    root: {width: '100%',position:'relative'},
    label: theme.control.label,
    select: {
        pointerEvents: 'auto',
        cursor: ({disabled}) => (disabled ? 'not-allowed' : 'pointer')
    },
    error: {
        color: theme.colors.red,
        position: 'absolute',
        top: ({label}) => label ? 64 : 44,
        left: 0,
    },
}));

// option type accepted
/*const options = [{ value: 'chocolate', label: 'Chocolate' },...];*/

const Select = forwardRef(({
                               className, label, name, options, control, defaultValue, placeholder = 'Scegli...',
                               isCreatableSelect = false, errors, fetched, callback, isSearcheable,
                               isMulti = false, isClearable = true, closeMenuOnSelect, styles, rootProps,
                               ...props},ref) => {
        const theme = useTheme();
        const classes = useStyles({errors: false, disabled: props.disabled,label});

        //React select styles - https://react-select.com/styles
        const reactSelectsStyle = {
            control: (defaults, {isDisabled, isFocused}) => ({
                ...defaults,
                fontSize: 15,
                borderRadius: 8,
                boxSizing: 'border-box',
                boxShadow: 'unset',
                cursor: isDisabled ? 'not-allowed' : 'pointer',
                width: '100%',
                minHeight: 40,
                height: isMulti ? 'auto' : 40,
                border: isDisabled
                    ? `1px solid ${theme.colors.lightGrey}`
                    : !!errors
                        ? `1px solid ${theme.colors.red}`
                        : isFocused ? `1px solid ${theme.colors.blue}` : `1px solid ${theme.colors.darkGrey}`,
                backgroundColor: isDisabled ? theme.colors.lightGrey : !!errors ? theme.colors.redLight : theme.colors.white
            }),
            indicatorsContainer: (defaults) => ({
                ...defaults,
                display: isCreatableSelect ? 'none': 'flex',
                backgroundColor: 'transparent',
                '& svg': {
                    fill: theme.colors.darkGrey
                }
            }),
            indicatorSeparator: (defaults) => ({
                ...defaults,
                width: 0,
                padding: 0
            }),
            dropdownIndicator: (defaults) => ({
                ...defaults,
                padding: '2px'
            }),
            clearIndicator: (defaults) => ({
                ...defaults,
                borderRadius: '50px',
                marginRight: 4,
                padding: 0,
                backgroundColor: 'transparent'
            }),
            menu: (defaults) => ({
                ...defaults,
                zIndex: 20
            }),
            multiValueLabel: (defaults) => ({
                ...defaults,
                textTransform: isCreatableSelect ? 'uppercase' : 'none'
            }),
        };

        let defaultValueComputed;
        // convert defaultValue from [1] to [{label=a,value=1}]
        if (defaultValue) {
            defaultValueComputed = retrieveValueForRs(options,defaultValue)
        }

        return <label data-control className={cx(classes.root, className)} {...rootProps}>
            {!!label && <span className={classes.label}>{label}</span>}
            <Controller
                name={name}
                menuPlacement={'auto'}
                as={ isCreatableSelect ?
                    <CreatableSelect formatCreateLabel={(value) => <span style={{fontSize:14}}>Aggiungi la targa: <span style={{textTransform: 'uppercase'}}>{value}</span></span>}
                                     noOptionsMessage={() => 'Scrivi una targa e premi invio'}
                    /> :
                    <ReactSelect/>}
                options={options}
                control={control}
                defaultValue={defaultValueComputed}
                closeMenuOnSelect={true}
                isMulti={isMulti}
                isSearcheable={true}
                isClearable={isClearable}
                isDisabled={props.readOnly || props.disabled}
                className={classes.select}
                classNamePrefix={'select'}
                styles={reactSelectsStyle}
                placeholder={placeholder}
                {...props}
            />
            {!!errors && !props.disabled && <small className={classes.error}>{errors.message}</small>}
        </label>
    }
);

export default Select;

Select.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    control: PropTypes.any, // required with react-hookform
    defaultValue: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    errors: PropTypes.object,
    fetched: PropTypes.bool,
    isSearcheable: PropTypes.bool,
    isMulti: PropTypes.bool,
    isClearable: PropTypes.bool,
    isCreatableSelect: PropTypes.bool,
    closeMenuOnSelect: PropTypes.bool
};
