import React, {useEffect} from 'react';
import {createUseStyles} from 'react-jss';
import PieChart from "./PieChart.js";
import {BusinessIcon} from "../../assets/icons.js";

const useStyles = createUseStyles(theme => ({
    root: {
        position: 'relative',
    },
    heading:{
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        height: 48,
        alignItems: "center",
        padding: theme.spacing,
        gridColumnGap: theme.spacing,
        '& svg':{
            fill: theme.colors.white
        },
        '& b':{
            color:  theme.colors.white,
            fontSize: 14
        }
    },
    chart:{
        position: "absolute",
        top: "46px",
        bottom: "0px",
        width: "100%"
    }
}));

const SalesChannels = ({saleChannelData}) => {
    const classes = useStyles();

    useEffect(() => {

    },[])

    return <div className={classes.root}>
        <div className={classes.heading}>
            <BusinessIcon data-color/>
            <b>Canalizzazione</b>
        </div>
        <div className={classes.chart}>
            <PieChart data={saleChannelData}/>
        </div>
    </div>
};

export default SalesChannels;
