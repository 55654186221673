import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import Table from "../../Commons/Table.js";
import {
    bartersStatuses,
    barterStatusLabelRenderer,
    clearFilters,
    errorHandler,
    formattedDate,
    formattedDateHours,
    toMoney
} from "../../helpers/utils.js";
import {httpGetBarters} from "../../HttpRequests/barters.http";
import SubHeading from "../../Commons/SubHeading.js";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from 'react-router-dom';
import SimpleSearch from "../../Commons/SimpleSearch.js";
import BarterFilters from "../../Components/Barters/BarterFilters.js";
import {httpGetBrandsAutocomplete, httpGetUsersAutocomplete} from "../../HttpRequests/commons.http.js";
import {setBartersList} from "../../store/actions/bartersActions.js";
import qs from "query-string";
import {useWindowWidth} from "@react-hook/window-size";
import Avatar from "../../Commons/Avatar.js";

const useStyles = createUseStyles(theme => ({
    gridContainer: {
        'position': 'relative',
        // with big/small subheader like permute page
        height: ({bigSubHeader}) => `calc(100vh - ${bigSubHeader ? theme.bigSubHeader + theme.headerHeight : theme.smallSubHeader + theme.headerHeight}px)`,
        // without subheader
        //height: `calc(100vh - ${theme.headerHeight}px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        padding: ({isTablet}) => isTablet ? "32px 16px 0" : "32px 32px 0",
        [theme.s]: {
            padding: [theme.spacing],
        },
    },
    doubleRow: {
        ...theme.grid.doubleRow,
    },
    greenField: {
        backgroundColor: theme.colors.greenLight,
        height: "auto",
        width: "90%",
        margin: [-theme.spacing, 0],
        alignItems: "center",
        display: "grid",
        padding: [theme.spacing * 2, 4],
        borderLeft: `1px solid ${theme.colors.lightGrey}`,
        borderRight: `1px solid ${theme.colors.lightGrey}`,
    }
}));

const Barters = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const user = useSelector(state => state.users.user);
    const barters = useSelector(state => state.barters.bartersList);
    const [loading, setLoading] = useState(false);
    const [autocompletes, setAutocompletes] = useState(null);
    const [filters, setFilters] = useState(location.search ? {
        ...qs.parse(location.search, {
            arrayFormat: 'comma',
            parseBooleans: true
        })
    } : {user_id_toggle: null, order_by: 'date', order_direction: 'desc'});
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(null);
    //based on the type {"big","small"} property of subHeader component
    const classes = useStyles({bigSubHeader: true, isTablet: useWindowWidth() < 1200}); // or false if type is small

    //fetch when filters change
    useEffect(() => {
        if (!!Object.keys(filters).length) {
            setPage(1);
            setLastPage(null);
            getBarters(1);
            history.replace({
                pathname: '/barters', search: qs.stringify({...filters}, {
                    arrayFormat: 'comma',
                    skipEmptyString: true,
                    skipNull: true
                }),
            });
        }
    }, [filters]);

    //fetch when page change
    useEffect(() => {
        if (!!barters.length && page !== 1) {
            getBarters(page)
        }
    }, [page]);

    //clean up
    useEffect(() => {
        return () => {
            dispatch(setBartersList([]))
        };
    }, []);

    //fetch autocompletes
    useEffect(() => {
        getAutocompletes();
    }, []);

    const getAutocompletes = async () => {
        try {
            const {data: brands} = await httpGetBrandsAutocomplete();
            const {data: operators} = await httpGetUsersAutocomplete();
            //console.log(brands);
            setAutocompletes({
                brands: brands,
                operators: operators,
                barterStatuses: bartersStatuses
            })
        } catch (e) {
            errorHandler(e, dispatch)
        }
    };

    const getBarters = async (actualPage = 1) => {
        if (lastPage && actualPage > lastPage) return;
        if (actualPage === 1) {
            dispatch(setBartersList([]))
        }
        setLoading(true);
        clearFilters(filters);
        try {
            const {data: {data, last_page}} = await httpGetBarters({limit: 20, ...filters, page: actualPage});
            if (actualPage === 1) {
                dispatch(setBartersList(data));
            } else {
                dispatch(setBartersList(barters.concat(data)));
            }
            setLastPage(last_page)
        } catch (e) {
            errorHandler(e, dispatch)
        } finally {
            setLoading(false);
        }
    };

    const showCurrentUserBarters = () => {
        const filtersToSet = {
            ...filters,
            user_id_toggle: !filters.user_id_toggle,
            user_id: filters.user_id_toggle ? null : user?.id,
        };
        setFilters({...filtersToSet});
        history.replace({
            pathname: '/barters', search: qs.stringify({...filtersToSet}, {
                arrayFormat: 'comma',
                skipEmptyString: true,
                skipNull: true
            }),
        });
    };

    const onRowClickPath = (item) => {
        if (item.status !== 'trade_in') {
            return `/barters-recap/${item?.id}`;
        } else {
            return `/barters/${item?.id}`;
        }
    };

    const onChangePage = (isFilter = false) => {
        if (isFilter) {
            setPage(1)
        } else {
            setPage(page + 1)
        }
    };

    const onDataSorting = (newSort) => {
        setFilters({
            ...filters,
            ...newSort,
        });
        setPage(1);
    };

    return <>
        <SubHeading title={"Permute"} type={'big'} justifySelf={'unset'}
                    filtersComponent={
                        <BarterFilters setFilters={setFilters} filters={filters} showDate={true}
                                       setPage={setPage}
                                       selectsProps={[{
                                           name: 'brand_id',
                                           placeholder: 'Modello',
                                           label: 'modello',
                                           options: (autocompletes ? autocompletes.brands : [])
                                       }, {
                                           name: 'user_id',
                                           placeholder: 'Operatore',
                                           label: 'operatore',
                                           options: (autocompletes ? autocompletes.operators : [])
                                       }, {
                                           name: 'status',
                                           placeholder: 'Stato',
                                           label: 'stato',
                                           options: (autocompletes ? autocompletes.barterStatuses : [])
                                       }]}
                                       toggles={[{
                                           name: 'user_id_toggle',
                                           label: 'le mie permute',
                                           defaultChecked: filters['user_id_toggle'],
                                           callback: showCurrentUserBarters
                                       }]}
                                       searchComponent={<SimpleSearch filters={filters} setFilters={setFilters}
                                                                      name={'plate'} fullWidth={true}
                                                                      placeholder={'cerca targa'} setPage={setPage}/>}
                                       searchProps={[{
                                           name: 'business',
                                           label: 'Search by brand',
                                           placeholder: 'Search...',
                                           defaultValue: '',
                                       }]}
                        />}
        />

        <section className={classes.gridContainer}>
            <Table
                id={'table'}
                data={barters}
                inLoading={loading}
                filters={filters}
                onSetPageCb={onChangePage}
                onSortingDataCb={onDataSorting}
                theadHeight={40}
                rowMinHeight={40}
                classNames={{
                    table: classes.table,
                    thead: classes.thead,
                    row: classes.row,
                }}
                //real name is created_at not date
                columns={['date', 'model', 'plate', 'mileage', 'withdrawal_price', 'withdrawal_user', 'status']}
                columnsNames={{
                    date: 'data',
                    model: "Marca & modello",
                    plate: "Targa",
                    mileage: 'Km',
                    withdrawal_price: "Valutazione",
                    withdrawal_user: "Operatore",
                    status: "Stato",
                    /* withdrawal_price: ''
                     fiscality: 'Fiscalità',
                     ideal_price: "Ritiro indicato",
                     first_damage_estimate: "Ripristino",
                     withdrawal_price: "Proposto",
                     user: "Operatore",
                     status: "Stato",*/
                }}
                columnsWidths={{
                    date: '10%',
                    model: "25%",
                    plate: "12%",
                    mileage: "10%",
                    withdrawal_price: "15%",
                    withdrawal_user: "18%",
                    status: "10%",
                    /*fiscality: '10%',
                    ideal_price: "10%",
                    first_damage_estimate: "10%",
                    withdrawal_price: "10%",
                    user: "10%",
                    status: "10%",*/
                }}
                columnsAlignments={{
                    status: 'center',
                    mileage: 'right',
                }}
                backEndSortable={true}
                sortable={true}
                headersSorters={['date', 'withdrawal_user', 'status']}
                columnsRenderers={{
                    date: (value, items) => <div className={classes.doubleRow}>
                        <span> {formattedDate(items?.created_at)}</span>
                        <small>{formattedDateHours(items.created_at)}</small>
                    </div>,
                    model: (value) => <div className={classes.doubleRow}>
                        <span> {value?.brand?.name}</span>
                        <small>{value?.name}</small>
                    </div>,
                    /* fiscality: (value) => <span>{value ? value : '-'}</span>,*/
                    /*  ideal_price: (value) => toMoney(value),
                      first_damage_estimate: (value) => toMoney(value),*/
                    withdrawal_price: (value, items) => <span>
                        {items?.status === 'trade_in' ? "-" :
                            <span className={classes.doubleRow}>
                                <b style={{color: items?.status === 'negotiating' ? '#FF9F00' : 'black'}}> {toMoney(value)}</b>
                                <small>{toMoney(items?.ideal_price)} (indicato)</small>
                            </span>}
                    </span>,
                    withdrawal_user: (value) => <span>{value ?
                        <Avatar size={'small'} fullName={value?.full_name} img={value?.photo_link}/> : "-"}</span>,
                    status: (value) => barterStatusLabelRenderer(value),
                }}
                onRowClickPath={onRowClickPath}
            />
        </section>
    </>
};

export default Barters;
