import * as actionTypes from '../actions/campaignActions.js';

const initialState = {
    campaignsList: [],
    campaignCars: [],
    currentCampaign: null,
};

const campaignsReducer = (state = initialState, action) => {
    let newCampaignsList = state.campaignsList;
    switch (action.type) {
        case actionTypes.SET_CAMPAIGNS_LIST:
            return {
                ...state,
                campaignsList: action.campaignsList,
            };
        case actionTypes.SET_CAMPAIGN_CARS:
            return {
                ...state,
                campaignCars: action.campaignCars,
            };
        case actionTypes.EDIT_CAMPAIGNS_LIST:{
            newCampaignsList =  newCampaignsList.map(item => item.id === action.campaign.id ? {...action.campaign,edited:'true'} : item );
            return {
                ...state,
                campaignsList: [...newCampaignsList]
            };
        }
        default:
            return state;
    }
};


export default campaignsReducer;
