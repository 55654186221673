import React from 'react';
import {createUseStyles} from "react-jss";
import UserInitialsAvatar from "../../CurrentConversation/UserInitialsAvatar/UserInitialsAvatar";
import useHover from "../../../../hooks/useHover";

const useStyles = createUseStyles(theme => ({
    root: {
        padding: [12, 24],
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: '#f0f3f7',
        }
    },
    avatar: {
        width: 36,
        height: 36,
    },
    about: {
        paddingLeft: 20,
        flexGrow: 1
    },
    userName: {
        fontWeight: 500,
        fontSize: 16,
        textTransform: 'capitalize',
        color: ({muted}) => `${muted ? "#DDDDDD" : "#585858"}`,
        lineHeight: '20px',
    },
    userTitle: {
        fontSize: 12,
        color:  ({muted}) => `${muted ? "#EBEBEB" : "#9b9b9b"}`,
        fontWeight: 400,
        lineHeight: '20px',
    },
    presenceDot: {
        fontSize: 0,
        border: '5px solid',
        borderRadius: '50%',
        height: 0,
        width: 0,
        borderColor: ({muted}) => `${!muted ? "#B8E986" : "#E9EEF4"}`,
        marginLeft: 10,
        //verticalAlign: 6,
    }
}))


const MemberDescription = ({ user, you }) => {

    const [isHovering, hoverProps] = useHover({ mouseEnterDelayMS: 0 });
    const nameWithYou = `${user.name}${you ? " (you)" : ""}`;
    const classes = useStyles({muted: !user.presence});

    return <div className={classes.root}>
        <div className={classes.avatar}>
            <UserInitialsAvatar
                size={36}
                name={user.name}
                userId={user.id}
                muted={!user.presence}
            />
        </div>
        <div className={classes.about}>
            <div className={classes.userName}>
                {nameWithYou}{" "}
                {user.presence && <span className={classes.presenceDot}/>}
            </div>
            <div className={classes.userTitle}>{user?.custom?.title}</div>
        </div>
    </div>
}

export default MemberDescription;