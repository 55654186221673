export const SET_CURRENT_CAR = 'SET_CURRENT_CAR';
export const SET_CAR_TASKS = 'SET_CAR_TASKS';
export const REMOVE_CAR_TASK = 'REMOVE_CAR_TASK';
export const SET_CARS = 'SET_CARS';
export const ADD_PHOTOSET = 'ADD_PHOTOSET';
export const UPDATE_PHOTOSET = 'UPDATE_PHOTOSET';
export const DELETE_PHOTOSET = 'DELETE_PHOTOSET';
export const ADD_PHOTO = 'ADD_PHOTO';
export const UPDATE_PHOTO = 'UPDATE_PHOTO';
export const DELETE_PHOTO = 'DELETE_PHOTO';
export const SET_FAVORITE_PHOTO = 'SET_FAVORITE_PHOTO';
export const SET_CAR_SHIFTS = 'SET_CAR_SHIFTS';
export const SET_CARS_REVISION = 'SET_CARS_REVISION';
export const UPDATE_CAR_TASKS = 'UPDATE_CAR_TASKS';
export const UPDATE_CAR_SHIFT =  'UPDATE_CAR_SHIFT';
export const SET_CAR_DOCUMENTS = 'SET_CAR_DOCUMENTS';
export const UPDATE_CAR_DOCUMENTS = 'UPDATE_CAR_DOCUMENTS';
export const DELETE_CAR_DOCUMENTS = 'DELETE_CAR_DOCUMENTS';
export const RESET_CAR_PROFILE_PIC = 'RESET_CAR_PROFILE_PIC';

/**
 * How to use setCarsRevision:
 * dispatch(setCars(cars));
 **/
export const setCarsRevision = (carsRevision) => {
	return {
		type: SET_CARS_REVISION,
		carsRevision: carsRevision
	};
};

/**
 * How to use setCars:
 * dispatch(setCars(cars));
 **/
export const setCars = (cars) => {
	return {
		type: SET_CARS,
		cars: cars
	};
};

/**
 * How to use setCurrentCar:
 * dispatch(setCurrentCar(car));
 **/
export const setCurrentCar = (car) => {
	return {
		type: SET_CURRENT_CAR,
		car: car
	};
};

/**
 * How to use setCarTasks:
 * dispatch(setCarTasks(tasks));
 **/
export const setCarTasks = (tasks) => {
	return {
		type: SET_CAR_TASKS,
		tasks: tasks
	};
};

/**
 * How to use updateCarTasks:
 * dispatch(updateCarTasks(tasks));
 **/
export const updateCarTasks = (newTask) => {
	return {
		type: UPDATE_CAR_TASKS,
		newTask: newTask
	};
};

/**
 * How to use removeCarTask:
 * dispatch(removeCarTask(id));
 **/
export const removeCarTask = (id) => {
	return {
		type: REMOVE_CAR_TASK,
		id: id
	};
};

export const addPhotoset = (photoset) => {
	return {
		type: ADD_PHOTOSET,
		photoset
	};
};

export const updatePhotoset = (photoset, definitive = false) => {
	return {
		type: UPDATE_PHOTOSET,
		photoset,
		definitive
	};
};

export const deletePhotoset = (photosetId) => {
	return {
		type: DELETE_PHOTOSET,
		photosetId
	};
};

export const addPhoto = (photo) => {
	return {
		type: ADD_PHOTO,
		photo
	};
};

export const updatePhoto = (photo) => {
	return {
		type: UPDATE_PHOTO,
		photo
	};
};

export const deletePhoto = (photo) => {
	return {
		type: DELETE_PHOTO,
		photo
	};
};

export const setFavoritePhoto = (photo) => {
	return {
		type: SET_FAVORITE_PHOTO,
		photo
	};
};

export const resetCarProfilePic = () => {
	return {
		type: RESET_CAR_PROFILE_PIC,
	};
};

/**
 * How to use setCarShifts:
 * dispatch(setCarShifts(shifts));
 **/
export const setCarShifts = (shifts) => {
	return {
		type: SET_CAR_SHIFTS,
		shifts: shifts
	};
};

/**
 * How to use updateShift:
 * dispatch(updateShift(shift));
 **/
export const updateCarShift = (shift) => {
	return {
		type: UPDATE_CAR_SHIFT,
		shift: shift,
	};
};

/**
 * How to use setCarDocument:
 * dispatch(setCarDocument(documents));
 **/
export const setCarDocuments = (documents) => {
	return {
		type: SET_CAR_DOCUMENTS,
		documents: documents
	};
};

/**
 * How to use updateCarDocument:
 * dispatch(updateCarDocument(document));
 **/
export const updateCarDocument = (id,document) => {
	return {
		type: UPDATE_CAR_DOCUMENTS,
		id:id,
		document: document,
	};
};

/**
 * How to use deleteCarDocument:
 * dispatch(deleteCarDocument(document));
 **/
export const deleteCarDocument = (document) => {
	return {
		type: DELETE_CAR_DOCUMENTS,
		document: document,
	};
};