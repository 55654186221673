import React from 'react';
import {createUseStyles} from 'react-jss';
import SidebarNavLink from "../../Commons/SidebarNavLink";

const useStyles = createUseStyles((theme) => ({
    voices: {
        zIndex:2,
        display: ({collapsedNav}) => collapsedNav ? "none" : "grid",
        color: theme.colors.white,
        fontWeight: 500,
        '& h4':{
            display: 'block',
            marginBottom: theme.spacing * 2,
            textTransform: 'uppercase',
            fontSize: 18,
        }
    },
}));


const ProfileNavigation = ({collapsedNav}) => {
    const classes = useStyles({collapsedNav});

    return <>
        <div className={classes.voices}>
            <h4>Profilo</h4>
            <SidebarNavLink to={'/profile'}>Informazioni</SidebarNavLink>
            <SidebarNavLink to={'/edit-password'}>Modifica password</SidebarNavLink>
        </div>
    </>
};

export default ProfileNavigation;
